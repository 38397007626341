import React, { Fragment } from 'react';
import {
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Checkbox,
  Box,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import colors from '../../../../../styles/colors';
import { ReactComponent as MessageIconSvg } from '../../../../../assets/img/message-icon.svg';
import { useSeekersFilters } from '../../../sidebar/filters/SeekersFiltersContext';

export type MessageAferHiringModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const MessageAfterHiringModal = ({ isOpen, onClose }: MessageAferHiringModalProps): JSX.Element => {
  const { t } = useTranslation('notification');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(true);

  const { updateWarningMessage } = useSeekersFilters();

  const checkPreferenceHandler = () => {
    setIsChecked(!isChecked);
  };

  const preferenceSubmitHandler = () => {
    try {
      setIsLoading(true);
      updateWarningMessage(!isChecked);
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent px={5} py={3} data-testid="PreferenceModal">
          <ModalHeader textAlign="center" alignSelf="center">
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MessageIconSvg />
            </Box>
            <Text mt={6} color={colors.blue.default} fontSize="1.125rem" fontWeight="bold">
              <p>{t('messaging:actions.hire.messageAfterHiringModal.modalText')}</p>
            </Text>
          </ModalHeader>
          <ModalBody textAlign="center">
            <Text fontSize="1rem" lineHeight="24px" mb={3} color={colors.gray[700]}>
              <p>{t('messaging:actions.hire.messageAfterHiringModal.headerText')}</p>
            </Text>
            <Checkbox isChecked={isChecked} onChange={checkPreferenceHandler} data-testid="PreferenceCheckBox">
              {t('messaging:actions.hire.messageAfterHiringModal.dismissText')}
            </Checkbox>
          </ModalBody>

          <ModalFooter mt={2} display="flex" justifyContent="center" alignItems="center">
            <Button
              disabled={isLoading}
              className="super-centered"
              colorScheme="blue"
              data-testid="PreferenceSubmitButton"
              onClick={preferenceSubmitHandler}
            >
              {t('messaging:actions.hire.messageAfterHiringModal.buttonText')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
