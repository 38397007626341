import React from 'react';
import { Box, Stack, Text, Stat, StatLabel, StatNumber, Flex } from '@chakra-ui/core';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { BsArrowDownShort } from 'react-icons/bs';
import { useEmployerPicture } from '../../../../../firebase/storage/hooks/useEmployerPicture';
import { useRecruiterManagementContext } from '../../RecruiterManagementContext';
import { ProfilePicture } from '../../../../common';
import { recruiterDetailsWrapper } from './RecruiterDetails.styles';
import { RecruiterProfileDataType } from '../../RecruiterType';
import colors from '../../../../../styles/colors';

export type RecruiterDetailsViewProps = {
  recruiterMetricsData: RecruiterProfileDataType;
};

export const RecruiterDetailsView = ({ recruiterMetricsData }: RecruiterDetailsViewProps): JSX.Element => {
  const { t } = useTranslation('recruiterManagement');
  const { email, lastLogin, totalInterviews, interviewCompleted, recruiterNoShow, candidateNoShow } = recruiterMetricsData;
  const { selectedRecruiter } = useRecruiterManagementContext();
  const picture = useEmployerPicture(selectedRecruiter?.id);

  const renderStatWithLabelValue = (label: string, valueCurr: number, valuePrev: number) => {
    const isComparisonPositive = valueCurr > valuePrev;
    const showComparison = valueCurr !== valuePrev;
    return (
      <Stat className="wrapperStyles" color="black" pt={4} pb={3} px={3} width="160px">
        <StatNumber>
          <Flex alignItems="center" data-testid="statValueFlex">
            <Text fontSize={16}>{valueCurr}</Text>

            {showComparison && (
              <BsArrowDownShort
                data-testid={`comparisonIcon-${isComparisonPositive ? 'positive' : 'negative'}`}
                fontSize={18}
                color={isComparisonPositive ? 'green' : colors.customRed}
                style={{ transform: isComparisonPositive ? 'rotate(180deg)' : '' }}
              />
            )}
          </Flex>
        </StatNumber>
        <StatLabel color="#000000" opacity="0.5" data-testid="statHeadingLabel" whiteSpace="nowrap" fontSize={13}>
          <Text whiteSpace="break-spaces">{label}</Text>
        </StatLabel>
      </Stat>
    );
  };

  return (
    <Box my={7} color="black">
      <Box css={recruiterDetailsWrapper}>
        <ProfilePicture
          data-testid="recruiterProfilePicture"
          name={selectedRecruiter?.label}
          style={{ backgroundColor: '#1F3CBA', color: 'white' }}
          src={picture}
          width="80px"
          height="80px"
          size="xl"
        />
        <Text fontSize={16} mt={4} color="black">
          {selectedRecruiter?.label}
        </Text>
        <Box background="cyan.lightCyan" py={1} px={2} borderRadius={3} mt={1}>
          <Text fontSize={14} color="blue.500">
            {email}
          </Text>
        </Box>

        <Box mt={5}>
          <Text fontSize={14}>{t('recruiterDataVisualisation.videoInterviewDetails')}</Text>
        </Box>

        <Stack direction="row" mt={4} style={{ gap: '15px' }}>
          {renderStatWithLabelValue(
            t('recruiterDataVisualisation.totalAppointments'),
            totalInterviews.current,
            totalInterviews.previous,
          )}

          {renderStatWithLabelValue(
            t('recruiterManagement:recruiterDataVisualisation.successfulInterviews'),
            interviewCompleted.current,
            interviewCompleted.previous,
          )}
        </Stack>

        <Stack direction="row" mt={4} style={{ gap: '15px' }}>
          {renderStatWithLabelValue(
            t('recruiterManagement:recruiterTable.tableHeader.recruiterNoShow'),
            recruiterNoShow.current,
            recruiterNoShow.previous,
          )}

          {renderStatWithLabelValue(
            t('recruiterManagement:recruiterTable.tableHeader.candidateNoShow'),
            candidateNoShow.current,
            candidateNoShow.previous,
          )}
        </Stack>

        <Box display="flex" mt={9} width="100%" fontSize={15}>
          <Text>
            {t('recruiterDataVisualisation.lastLogin')}
            &nbsp;:&nbsp;
          </Text>
          <Text color="gray.500">{moment(lastLogin).format('lll')}</Text>
        </Box>
      </Box>
    </Box>
  );
};
