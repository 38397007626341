import { Box } from '@chakra-ui/core';
import React, { useState, useRef, useEffect, MouseEventHandler } from 'react';

/**
 * DraggableScroll is a component that makes a scrollable container
 * draggable. It also accepts a scrollToIndex prop that will be used to
 * scroll to that index on mount.
 *
 * @example
 * const items = [1, 2, 3, 4, 5];
 * const Item = ({ value }) => <div>{value}</div>;
 * const MyComponent = () => (
 *   <DraggableScroll scrollToIndex={2}>
 *     {items.map((value) => (
 *       <Item key={value} value={value} />
 *     ))}
 *   </DraggableScroll>
 * );
 *
 * @param {JSX.Element} children The children to be rendered inside the
 * scrollable container.
 * @param {number} [scrollToIndex=0] The index to scroll to on mount.
 */
const DraggableScroll = ({ children, scrollToIndex }: { children: JSX.Element; scrollToIndex?: number }) => {
  const [isDragging, setIsDragging] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    if (scrollRef.current && scrollToIndex! >= 0) {
      const itemWidth = 80; // Item width + margin-right
      const targetPosition = itemWidth * scrollToIndex!;
      scrollRef.current?.scrollTo?.({
        left: targetPosition,
        behavior: 'smooth',
      });
    }
  }, [scrollToIndex]);

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    const target = e.currentTarget;
    setIsDragging(true);
    setStartX(e.pageX || 0 - target?.offsetLeft);
    setScrollLeft(target.scrollLeft);
    target.style.cursor = 'grabbing'; // Change cursor to grabbing
  };

  const handleMouseLeave: MouseEventHandler<HTMLDivElement> = (e) => {
    const target = e.currentTarget;
    setIsDragging(false);
    target.style.cursor = 'grab'; // Revert cursor when mouse leaves
  };

  const handleMouseUp: MouseEventHandler<HTMLDivElement> = (e) => {
    const target = e.currentTarget;
    setIsDragging(false);
    target.style.cursor = 'grab'; // Revert cursor when mouse is released
  };

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isDragging) return;
    const target = e.currentTarget;
    e.preventDefault();
    const x = (e?.pageX || 0) - target.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the speed of dragging
    target.scrollLeft = scrollLeft - walk;
  };

  return (
    <Box
      ref={scrollRef}
      width="100%"
      overflowX="auto"
      cursor="grab"
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      data-testid="draggable-scroll"
    >
      {children}
    </Box>
  );
};

DraggableScroll.defaultProps = {
  scrollToIndex: 0,
};

export default DraggableScroll;
