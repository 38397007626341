import React, { useCallback, useEffect, useState } from 'react';
import { Box, Stack, Center, Spinner, Text } from '@chakra-ui/core';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import _isNil from 'lodash/isNil';
import { RecruiterProfileDataType, RecruitersProfileDataResponse, SingleRecruitersCandidateType } from '../RecruiterType';
import RecruitersCandidateTableView from './recruitersCandidateTable/RecruitersCandidateTableView';
import { SearchInput } from '../../../common';
import { useRecruiterManagementContext } from '../RecruiterManagementContext';
import { useStoreActions } from '../../../../models/hooks';
import { RecruiterDetailsView } from './recruiterDetails/RecruiterDetailsView';
import { InterviewAndAvailabilityDetailsView } from './interviewAndAvailabilityDetails/InterviewAndAvailabilityDetailsView';
import { recruiterProfileWrapper } from './RecruiterProfile.styles';

export const defaultRecruiterMetricsData: RecruiterProfileDataType = {
  interviewCompleted: {
    previous: 0,
    current: 0,
  },
  recruiterNoShow: {
    previous: 0,
    current: 0,
  },
  candidateNoShow: {
    previous: 0,
    current: 0,
  },
  totalInterviews: {
    previous: 0,
    current: 0,
  },
  email: 'defaultEmail@gmail.com',
  lastLogin: '2022-01-03T04:30:54.212Z',
};

export const defaultCandidateListComumns = (t: TFunction): Column<SingleRecruitersCandidateType>[] => {
  return [
    {
      Header: `${t('recruitersCandidateTable.tableHeader.candidateName')}`,
      accessor: 'candidateName',
    },
    {
      Header: `${t('recruitersCandidateTable.tableHeader.businessPosition')}`,
      accessor: 'businessPosition',
    },
    {
      Header: `${t('recruitersCandidateTable.tableHeader.businessLocation')}`,
      accessor: 'businessLocation',
    },
    {
      Header: `${t('recruitersCandidateTable.tableHeader.notes')}`,
      accessor: 'notes',
      disableSortBy: true,
    },
    {
      Header: `${t('recruitersCandidateTable.tableHeader.status')}`,
      accessor: 'status',
    },
  ];
};

const defaultCandidateList: SingleRecruitersCandidateType[] = [];

export type RecruiterProfileViewProps = {
  accountId?: string;
};

const RecruiterProfileView = ({ accountId }: RecruiterProfileViewProps): JSX.Element => {
  const { t } = useTranslation('recruiterManagement');

  const [candidateTableSearchText, setCandidateTableSearchText] = useState<string>('');
  const { selectedRecruiter, startDate, endDate } = useRecruiterManagementContext();
  const recruiterId = selectedRecruiter && selectedRecruiter?.id;

  const { getRecruitersProfileData } = useStoreActions((actions) => actions.recruiterManagement);

  const [candidateListData, setCandidateListData] = useState<SingleRecruitersCandidateType[]>(defaultCandidateList);
  const [recruiterMetricsData, setRecruiterMetricsData] = useState<RecruiterProfileDataType>();
  const [isLoadingRecruiterProfileData, setIsLoadingRecruiterProfileData] = useState<boolean>(false);

  const onGetRecruitersProfileData: (selectedRecruiterId: string, selectedAccountId: string) => void = useCallback(
    async (selectedRecruiterId: string, selectedAccountId: string) => {
      const data: RecruitersProfileDataResponse = await getRecruitersProfileData({
        params: { recruiterId: selectedRecruiterId, accountId: selectedAccountId, startDate, endDate },
      });
      /* istanbul ignore else */
      if (data) {
        setCandidateListData(data.candidatesTableData);
        setRecruiterMetricsData(data.recruiterProfileData);
      }
      setIsLoadingRecruiterProfileData(false);
    },
    [getRecruitersProfileData, startDate, endDate],
  );

  useEffect(() => {
    setIsLoadingRecruiterProfileData(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !_isNil(accountId) && !_isNil(recruiterId) && onGetRecruitersProfileData(recruiterId, accountId);
  }, [recruiterId, accountId, onGetRecruitersProfileData]);

  return (
    <Stack css={recruiterProfileWrapper} p={4}>
      {!isLoadingRecruiterProfileData ? (
        <>
          <Stack direction="column">
            <Stack direction="row" data-testid="chartsAndInfoView" spacing={4} mb={6} minH="60vh">
              <Box w="30%" className="wrapperStyles">
                {recruiterMetricsData && <RecruiterDetailsView recruiterMetricsData={recruiterMetricsData} />}
              </Box>
              <Box w="70%" className="wrapperStyles">
                {recruiterMetricsData && <InterviewAndAvailabilityDetailsView recruiterMetricsData={recruiterMetricsData} />}
              </Box>
            </Stack>
          </Stack>
          <Box p={5} border="1px solid #ECEFF1">
            <Text color="black" fontWeight={700}>
              {t('tabHeader.candidateDetails')}
            </Text>
          </Box>
          <Box p={5} border="1px solid #ECEFF1" borderTop="none">
            <SearchInput
              placeholder={t('dashboardSearchInputs.searchCandidatePlaceholder')}
              onChange={(evt: React.FormEvent) => {
                const target = evt.target as HTMLInputElement;
                setCandidateTableSearchText(target.value);
              }}
              _styleOnFocus={{ boxShadow: ' 0 0 0 2px #1f3cba' }}
              inputBoxStyle={{ background: '#F3F8FA', color: '#86A0AC', borderRadius: '6px', borderColor: '#F3F8FA' }}
              value={candidateTableSearchText}
            />
          </Box>

          <Stack backgroundColor="white" mt={0} data-testid="tableView" style={{ height: '52vh', overflowY: 'auto' }}>
            {!_isNil(recruiterId) && (
              <RecruitersCandidateTableView
                columns={defaultCandidateListComumns(t)}
                recruiterId={recruiterId}
                data={candidateListData.filter((value) =>
                  value.candidateName?.toLocaleLowerCase().includes(candidateTableSearchText.toLocaleLowerCase()),
                )}
              />
            )}
          </Stack>
        </>
      ) : (
        <Center style={{ display: 'flex', flexDirection: 'column' }} p={10}>
          <Spinner color="blue.500" size="lg" mb="6" />
          <Box>{t('loadingMessages.loadingRecruiterMetrics')}</Box>
        </Center>
      )}
    </Stack>
  );
};

export default RecruiterProfileView;
