import { Box, Text } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../styles/customTheme';

interface ButtonPreviewProps {
  label: string;
}
export default function ButtonPreview({ label }: ButtonPreviewProps) {
  return (
    <Box border="1px solid" borderColor={theme.colors.blue[400]} borderRadius={theme.radii['3xl']} paddingX={3} paddingY={1}>
      <Text fontSize={theme.fontSizes.sm} color={theme.colors.blue[400]} fontWeight={'bold'}>
        {label}
      </Text>
    </Box>
  );
}
