import { Stack } from '@chakra-ui/core';
import React from 'react';
import { SchemaBuilderProps, FieldSchema } from './formBuilderSchema';
import TextField from './TextField';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';

export default function LinkSchemaBuilder(props: SchemaBuilderProps) {
  const { onChange, defaultValue } = props;
  const handleChange = (key: keyof FieldSchema | keyof FieldSchema['validations'], value: any) => {
    onChange({ ...defaultValue, [key]: value });
  };

  return (
    <Stack>
      <LabelSchemaBuilder {...props} />
      <FormElementTextBuilder {...props} placeholder="Enter text here for link input..." />
      <TextField
        type="url"
        placeholder="Paste your link here"
        value={defaultValue?.link!}
        onChange={(e) => handleChange('link', e)}
      />
    </Stack>
  );
}
