import React, { Fragment, useRef, useState } from 'react';
import { Box, Divider, Flex, FormControl, Heading, Select, Text } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { CorrectAnswerDeterminationType, FreeFormQuestion, JobPosition, MultipleChoiceQuestion, Question } from '../PositionType';
import { SpecificQuestionsCard } from './SpecificQuestionsCard';
import { QuestionsTypes } from './QuestionsTypes';
import { FreeFormQuestionsCard } from './FreeFormQuestionsCard';
import { MultipleChoiceQuestionsCard } from './MultipleChoiceQuestion';
import { QuestionsListView } from './QuestionsListView';

const initialQuestion = {
  text: '',
  preferredAnswer: true,
  knockout: false,
};

const initialFreeFormQuestion = {
  text: '',
  isActive: true,
};

const initialMultipleChoiceQuestion = {
  question: '',
  images: [],
  knockout: false,
  totalAnswer: 1,
  options: [
    {
      id: '1',
      priority: 0,
      text: '',
    },
    {
      id: '2',
      priority: 1,
      text: '',
    },
  ],

  preferredAnswers: [],
  shouldMatchAtLeast: 1,
  correctAnswerIf: CorrectAnswerDeterminationType.IS_EQUAL,
};

export type QuestionsViewProps = {
  jobPositionData: JobPosition;
  deleteQuestion: (questionId: number) => void;
  deleteFreeFormQuestion: (questionId: number) => void;
  addQuestion: (question: Question, questionEditMode: number | undefined) => void;
  showQuestionForm: boolean;
  addFreeFormQuestion: (question: FreeFormQuestion, questionEditMode: number | undefined) => void;
  addMultipleChoiceQuestion: (question: MultipleChoiceQuestion, questionEditMode: number | undefined) => void;
  deleteMultipleQuestionsQuestion: (questionId: number) => void;
  reOrderedMultipleChoiceQuestions: (reOrderedMultipleChoiceQuestion: MultipleChoiceQuestion[]) => void;
  reOrderSpecificQuestions: (reOrderSpecificQuestions: Question[]) => void;
  reOrderFreeFormQuestions: (reOrderFreeFormQuestions: FreeFormQuestion[]) => void;
};

export const QuestionsView = ({
  jobPositionData,
  deleteQuestion,
  addQuestion,
  showQuestionForm,
  addFreeFormQuestion,
  addMultipleChoiceQuestion,
  deleteFreeFormQuestion,
  deleteMultipleQuestionsQuestion,
  reOrderedMultipleChoiceQuestions,
  reOrderSpecificQuestions,
  reOrderFreeFormQuestions,
}: QuestionsViewProps): JSX.Element => {
  const { t } = useTranslation();
  const [questionTypes, setQuestionTypes] = useState<QuestionsTypes>(QuestionsTypes.SPECIFIC);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [question, setQuestion] = useState<Question>(initialQuestion);
  const [questionEditMode, setQuestionEditMode] = useState<number | undefined>(undefined);
  const [freeFormQuestion, setFreeFormQuestion] = useState<FreeFormQuestion>(initialFreeFormQuestion);
  const [freeFormQuestionEditMode, setFreeFormQuestionEditMode] = useState<number | undefined>(undefined);
  const [multipleChoiceQuestion, setMultipleChoiceQuestion] = useState<MultipleChoiceQuestion>(initialMultipleChoiceQuestion);
  const [multipleChoiceQuestionEditMode, setMultipleQuestionQuestionEditMode] = useState<number | undefined>(undefined);
  /* istanbul ignore next */
  const onRefreshState = () => {
    setQuestion(initialQuestion);
    setQuestionEditMode(undefined);
    setFreeFormQuestionEditMode(undefined);
    setFreeFormQuestion(initialFreeFormQuestion);
    setMultipleChoiceQuestion(initialMultipleChoiceQuestion);
    setMultipleQuestionQuestionEditMode(undefined);
  };

  const onGetQuestionViewByTypes = () => {
    if (questionTypes === QuestionsTypes.SPECIFIC) {
      return (
        <SpecificQuestionsCard
          jobPositionData={jobPositionData}
          deleteQuestion={deleteQuestion}
          addQuestion={addQuestion}
          showQuestionForm={false}
          questionToEdit={question}
          questionToEditMode={questionEditMode}
          onRefreshState={onRefreshState}
        />
      );
    }
    if (questionTypes === QuestionsTypes.FREEFORM && showQuestionForm) {
      return (
        <FreeFormQuestionsCard
          jobPositionData={jobPositionData}
          deleteFreeFormQuestion={deleteFreeFormQuestion}
          addFreeFormQuestion={addFreeFormQuestion}
          showQuestionForm={showQuestionForm}
          questionToEdit={freeFormQuestion}
          questionToEditMode={freeFormQuestionEditMode}
          onRefreshState={onRefreshState}
        />
      );
    }
    return (
      <MultipleChoiceQuestionsCard
        jobPositionData={jobPositionData}
        addMultipleChoiceQuestion={addMultipleChoiceQuestion}
        deleteMultipleQuestionsQuestion={deleteMultipleQuestionsQuestion}
        questionToEdit={multipleChoiceQuestion}
        questionToEditMode={multipleChoiceQuestionEditMode}
        onRefreshState={onRefreshState}
      />
    );
  };

  return (
    <Box ref={scrollRef}>
      <Fragment>
        <Flex alignItems="center" justifyContent="space-between">
          <Box w="60%">
            <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
              {t('createJobPosition:newQuestionHeader')}
            </Heading>
            <Text fontSize="xs" color="gray.500">
              {t('createJobPosition:questionsHint')}
            </Text>
          </Box>
          <Box w="30%" pr="6">
            <FormControl>
              <Select
                className="filled"
                name="question"
                id="questionType"
                value={questionTypes}
                data-testid="selectQuestionType"
                onChange={(e) => setQuestionTypes(e.target.value as QuestionsTypes)}
              >
                {showQuestionForm && <option value={QuestionsTypes.FREEFORM}>{t('createJobPosition:freeform')}</option>}
                <option value={QuestionsTypes.SPECIFIC}>{t('createJobPosition:specific')}</option>
                <option value={QuestionsTypes.MULTIPLE_CHOICE}>{t('createJobPosition:multipleChoice')}</option>
              </Select>
            </FormControl>
          </Box>
        </Flex>
        <Divider pt="12px" pb="12px" />
        {onGetQuestionViewByTypes()}
      </Fragment>
      <Fragment>
        <QuestionsListView
          jobPositionData={jobPositionData}
          editQuestion={(index: number, que: Question) => {
            setQuestionTypes(QuestionsTypes.SPECIFIC);
            setQuestion(que);
            setQuestionEditMode(index);
            /* istanbul ignore else */
            if (scrollRef.current) {
              scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          editFreeFormQuestion={(index: number, que: FreeFormQuestion) => {
            setQuestionTypes(QuestionsTypes.FREEFORM);
            setFreeFormQuestion(que);
            setFreeFormQuestionEditMode(index);
            /* istanbul ignore else */
            if (scrollRef.current) {
              scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          deleteQuestion={deleteQuestion}
          deleteFreeFormQuestion={deleteFreeFormQuestion}
          editMultipleChoiceQuestion={(index: number, que: MultipleChoiceQuestion) => {
            setQuestionTypes(QuestionsTypes.MULTIPLE_CHOICE);
            setMultipleChoiceQuestion({ ...que });
            setMultipleQuestionQuestionEditMode(index);
            /* istanbul ignore else */
            if (scrollRef.current) {
              scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
          deleteMultipleQuestionsQuestion={deleteMultipleQuestionsQuestion}
          reOrderMultiChoiceQuestions={reOrderedMultipleChoiceQuestions}
          reOrderSpecificQuestions={reOrderSpecificQuestions}
          reOrderFreeFormQuestions={reOrderFreeFormQuestions}
        />
      </Fragment>
    </Box>
  );
};
