import { Input, InputGroup, InputGroupProps, InputLeftElement } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoSearch } from 'react-icons/io5';
import debounce from 'lodash/debounce';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { extractFilenameFromPath } from './extractFilenameFromPath';

interface ImageGallerySearchProps extends Pick<InputGroupProps, 'size'> {
  iconSize?: number;
}

export default function ImageGallerySearch({ size, iconSize }: ImageGallerySearchProps) {
  const { setFilteredImages } = useStoreActions((actions) => actions.imageGallery);
  const { images, isGalleryRefetching, isGalleryLoading } = useStoreState((state) => state.imageGallery);

  const { t } = useTranslation();

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const searchQuery = e.target.value;
    if (searchQuery) {
      const filteredImages = images.filter((image) => {
        const fileName = extractFilenameFromPath(image.path)?.toLowerCase();
        return fileName?.startsWith(searchQuery.toLowerCase());
      });
      setFilteredImages(filteredImages);
    } else {
      setFilteredImages(images);
    }
  }

  return (
    <InputGroup size={size}>
      <InputLeftElement paddingX={1} pointerEvents="none">
        <IoSearch size={iconSize} color="gray.300" />
      </InputLeftElement>
      <Input
        type="search"
        placeholder={t('administration:imageGalleryModal:searchInputPlaceholder')}
        onChange={debounce(handleSearch, 200)}
        disabled={isGalleryLoading || isGalleryRefetching || images.length === 0}
        data-testid="searchInput"
      />
    </InputGroup>
  );
}

ImageGallerySearch.defaultProps = {
  iconSize: 24,
  // eslint-disable-next-line
  size: 'lg',
};
