import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { Stack } from '@chakra-ui/core';
import InputPreview from './InputPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';

export default function PhoneSchemaBuilder({ ...props }: SchemaBuilderProps) {
  return (
    <Stack>
      <LabelSchemaBuilder {...props} />
      <FormElementTextBuilder {...props} placeholder="Enter text here for phone number input..." />
      <InputPreview value="000-000-0000" />
    </Stack>
  );
}
