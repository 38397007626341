import React from 'react';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { PositionState } from '../../../../common';
import { Candidate } from '../../../../../firebase/firestore/documents/candidate';
import colors from '../../../../../styles/colors';
import { getTranslatedValue } from '../../../../../utils/localizedString';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { ViewRequisitionDetail } from '../history/ViewRequisitionDetail';
import useSingleRequisitionByPosition from './components/useSingleRequisitionByPosition';

export type CandidateWithStatusViewProps = {
  candidate: Candidate;
};

export const CandidateWithStatusView = ({ candidate }: CandidateWithStatusViewProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { selectedCandidate } = useSeekerContainerContext();
  const { isOpen: isOpenRequisition, onOpen: onOpenRequisition, onClose: onCloseRequisition } = useDisclosure();
  const singleRequisition = useSingleRequisitionByPosition(candidate.position, candidate.requisitionId ?? '');

  return (
    <Flex
      cursor="pointer"
      w="100%"
      alignItems="center"
      py={1}
      data-testid={`CandidateItemBlock-${candidate.id}`}
      onClick={() => singleRequisition && singleRequisition.length > 0 && onOpenRequisition()}
    >
      <Flex
        flex={1}
        direction="column"
        minW={64}
        alignItems="flex-start"
        p={3}
        bg="#F3F4F6"
        borderRadius={4}
        border={selectedCandidate?.id === candidate.id ? `1px solid #c7c6e7` : '0'}
      >
        <Box>
          <Flex alignItems="center" pb={1}>
            {candidate.computedStatus?.status && (
              <PositionState status={candidate.computedStatus?.status} style={{ margin: '0 0.1rem 0 0' }} />
            )}
            <Text fontSize="xs" color={colors.gray['500']} lineHeight="1.1">
              {t(`candidateStatus:${candidate.computedStatus?.status}`)}
            </Text>
          </Flex>
          <Flex>
            <Text fontSize="sm" lineHeight="1" fontWeight="bold" color={colors.black}>
              {getTranslatedValue(candidate.jobTitle, i18n.language)}
            </Text>
          </Flex>
          <Text fontSize="xs" textTransform="uppercase" pt={1} mb="2px">
            {candidate.businessName}
          </Text>
          <Text fontSize="xs" textTransform="uppercase">
            {candidate.businessCompleteAddress}
          </Text>
        </Box>
      </Flex>
      {isOpenRequisition && (
        <ViewRequisitionDetail requisition={singleRequisition?.[0]} isOpen={isOpenRequisition} onClose={onCloseRequisition} />
      )}
    </Flex>
  );
};
