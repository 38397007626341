import React, { useState, useRef, useEffect } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { Box, VStack, Heading, Button, Text, Flex, Image, useDisclosure, useToast, Tooltip } from '@chakra-ui/core';
import { RiPencilFill, RiCloseFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { LogoDeleteConfirmationModal } from './LogoDeleteConfirmationModal';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import colors from '../../../styles/colors';

const FileUploadView = (): JSX.Element => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const toast = useToast();

  const [images, setImages] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user } = useStoreState((s) => s.app);

  const { saveCompanyLogo, getCompanyLogo } = useStoreActions((actions) => actions.administration);
  const { t } = useTranslation();

  const onChange = (imageList: ImageListType) => {
    // istanbul ignore else
    if (imageList.length > 0) {
      // istanbul ignore else
      if (imageList[0].dataURL && canvasRef.current) {
        const c = canvasRef.current;
        const ctx = c.getContext('2d');

        // istanbul ignore next
        if (ctx) {
          const image = new window.Image();
          image.src = imageList[0].dataURL;
          image.onload = async () => {
            c.width = image.naturalWidth;
            c.height = image.naturalHeight;
            ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
            if (user) {
              const data = {
                accountId: user.account,
                companyLogo: c.toDataURL(),
              };

              await saveCompanyLogo(data)
                .then(function (response) {
                  toast({
                    title: 'Company Logo',
                    description: response.message,
                    duration: 3000,
                    isClosable: true,
                  });
                })
                .catch(function () {
                  toast({
                    title: 'Company Logo',
                    description: 'Failed to upload company logo',
                    duration: 3000,
                    isClosable: true,
                  });
                });
            }
          };
        }
      }
    }

    setImages(imageList as never[]);
    onClose();
  };

  const loadCompanyLogo = async () => {
    /* istanbul ignore else */
    if (user?.account) {
      const result = await getCompanyLogo({ accountId: user.account });
      if (result !== '') {
        setImages([{ dataURL: result }] as never[]);
      }
    }
  };

  useEffect(() => {
    loadCompanyLogo().catch(/* istanbul ignore next */ (err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <VStack align="left" backgroundColor="white" p={4}>
        <Box py={2} px={4} borderBottom="0.5px solid #ECEFF1">
          <Heading as="h4" fontSize="lg" lineHeight="1.3">
            {t('uploadCompanyLogo:uploadLogoCard.headerText')}
          </Heading>
          <Text m="3px 0" color="gray.500" fontSize="12px">
            {t('uploadCompanyLogo:uploadLogoCard.hintText')}
          </Text>
        </Box>

        <Box py={4} px={6} textAlign="center">
          <Box style={{ display: 'none' }}>
            Canvas:
            <canvas ref={canvasRef} width={200} height={200} />
          </Box>
          <Box>
            <ImageUploading
              multiple={false}
              value={images}
              onChange={onChange}
              maxFileSize={5242880}
              acceptType={['jpg', 'jpeg', 'png']}
              inputProps={{ id: 'inputImages', alt: 'Hidden Image Input' }}
            >
              {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps, errors }) => (
                <Box>
                  {errors ? (
                    <Box data-testid="ValidationError">
                      {errors.acceptType && (
                        <h4 data-testid="FileTypeError" className="error-section" style={{ color: 'red' }}>
                          {t('uploadCompanyLogo:error.fileTypeError')}
                        </h4>
                      )}
                      {errors.maxFileSize && (
                        <h4 className="error-section" style={{ color: 'red' }}>
                          {t('uploadCompanyLogo:error.sizeError')}
                        </h4>
                      )}
                    </Box>
                  ) : (
                    imageList.map((image, index) => (
                      <Box data-testid="ImgListId" key={image.dataURL}>
                        <Flex m="1rem 0" justify="center" align="center">
                          <Image
                            src={`${image.dataURL}`}
                            alt="company logo"
                            boxSize="200px"
                            style={{
                              objectFit: 'contain',
                              background: '#F1F1F1',
                              filter: 'drop-shadow(0px 9px 16px rgba(24, 28, 50, 0.25))',
                            }}
                          />
                          <Tooltip label={t('uploadCompanyLogo:editHintText')} fontSize="md">
                            <Box
                              onClick={/* istanbul ignore next */ () => onImageUpdate(index)}
                              fontSize="1rem"
                              mb="12rem"
                              ml="-1rem"
                              borderRadius="50%"
                              padding="0.3rem"
                              border="0.5px solid #d9d5d4"
                              background="white"
                              _hover={{ cursor: 'pointer' }}
                              zIndex="9"
                            >
                              <RiPencilFill />
                            </Box>
                          </Tooltip>
                          <Tooltip label={t('uploadCompanyLogo:removeHintText')} fontSize="md">
                            <Box
                              data-testid="CrossBtn"
                              onClick={/* istanbul ignore next */ () => onOpen()}
                              fontSize="1rem"
                              padding="0.3rem"
                              mt="11.5rem"
                              ml="-1.8rem"
                              borderRadius="50%"
                              border="0.5px solid #d9d5d4"
                              background="white"
                              _hover={{ cursor: 'pointer' }}
                              zIndex="9"
                            >
                              <RiCloseFill />
                            </Box>
                          </Tooltip>
                        </Flex>

                        <LogoDeleteConfirmationModal
                          isOpen={isOpen}
                          onImageRemove={onImageRemove}
                          imageIndex={index}
                          onClose={onClose}
                        />
                      </Box>
                    ))
                  )}

                  <Text m="1rem 0" color="#90A4AE" fontSize="sm">
                    {t('uploadCompanyLogo:uploadNoteText')}
                  </Text>
                  <Box
                    style={isDragging ? /* istanbul ignore next */ { color: 'red' } : undefined}
                    {...dragProps}
                    w="100%"
                    h="300px"
                    border="1px dashed #1F3CBA"
                    background="#f1f1f1"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Flex fontSize="4rem" color={colors.blue.default} m="1rem 0">
                      {/* <RiUploadCloud2Line /> */}
                      <img src="/upload-logo.svg" alt="upload-logo" />
                    </Flex>

                    <Text fontSize="2xl" color={colors.blue.default}>
                      {t('uploadCompanyLogo:dragAndDropCard.mainText')}
                    </Text>
                    <Text color={colors.blue.default}>{t('uploadCompanyLogo:dragAndDropCard.uploadSize')}</Text>

                    <Button
                      data-testid="imageUploadingBtn"
                      mt="0.5rem"
                      background={colors.blue.default}
                      color="white"
                      onClick={onImageUpload}
                    >
                      {t('uploadCompanyLogo:dragAndDropCard.buttonText')}
                    </Button>
                  </Box>
                </Box>
              )}
            </ImageUploading>
          </Box>
        </Box>
      </VStack>
    </div>
  );
};

export default FileUploadView;
