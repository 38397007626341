/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Fragment } from 'react';
import { Box } from '@chakra-ui/core';
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  RemoteTrackPublication,
  Track,
  VideoTrack as IVideoTrack,
} from 'twilio-video';
import { useTrack } from './hooks/useTrack';
import { AudioTrack } from './AudioTrack';
import { MediaTypeEnum } from '../MediaTypeEnum';
import { VideoTrack } from './VideoTrack';

type PublicationProps = {
  publication: LocalTrackPublication | RemoteTrackPublication;
  mediaType: MediaTypeEnum;
  videoPriority: Track.Priority | null;
  shouldOffVideoTrack: boolean;
};

export const Publication = ({ publication, mediaType, videoPriority, shouldOffVideoTrack }: PublicationProps): JSX.Element => {
  const track = useTrack({ publication });
  const hasVideo = mediaType === MediaTypeEnum.VIDEO_ONLY || mediaType === MediaTypeEnum.BOTH;
  const hasAudio = mediaType === MediaTypeEnum.AUDIO_ONLY || mediaType === MediaTypeEnum.BOTH;

  const getVideoTrack = () => {
    if (shouldOffVideoTrack) {
      return null;
    }
    if (track?.kind === 'video') {
      return (
        <Box data-testid="PublicationVideo" className="publication video-publication">
          <VideoTrack track={track as IVideoTrack} priority={videoPriority} />
        </Box>
      );
    }
    return null;
  };

  const getAudioTrack = () => {
    if (track?.kind === 'audio') {
      return (
        <Box data-testid="PublicationAudio" className="publication audio-publication">
          <AudioTrack track={track as IAudioTrack} />
        </Box>
      );
    }
    return null;
  };

  return (
    <Fragment>
      {hasVideo && getVideoTrack()}
      {hasAudio && getAudioTrack()}
    </Fragment>
  );
};
