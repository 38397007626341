import React from 'react';
import usePosition from '../../../../sidebar/seekerList/usePosition';
import CandidatePositionRequisitions from './CandidatePositionRequisitions';

type CandidatePositionRequisitionsProps = {
  positionId: string;
};

const CandidatePosition = ({ positionId }: CandidatePositionRequisitionsProps): JSX.Element | null => {
  const positionDetail = usePosition(positionId);

  if (!positionDetail) {
    return null;
  }

  return <div>{positionDetail && <CandidatePositionRequisitions positionId={positionId} />}</div>;
};

export default CandidatePosition;
