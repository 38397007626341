export default {
  VIDEO: 'vidéo',
  PHONE: 'téléphone',
  INPERSON: 'en personne',
  INTEREST_REVOKED: 'Le candidat n’est pas intéressé',
  APPOINTMENT_REQUEST_CREATED_NO_TYPE: 'Entrevue demandée',
  APPOINTMENT_REQUEST_CREATED: 'Entrevue $t({{ appointmentType }}) demandé',
  APPOINTMENT_REQUEST_CREATED_plural: 'Entrevue $t({{ appointmentType }}) de {{count}} min. demandé',
  APPOINTMENT_REQUEST_CANCELLED: 'Entrevue $t({{ appointmentType }}) annulée',
  APPOINTMENT_REQUEST_CANCELLED_plural: 'Entrevue $t({{ appointmentType }}) de {{count}} min annulée',
  APPOINTMENT_REQUEST_DECLINED: 'Demande d’entrevue $t({{ appointmentType }}) refusée',
  APPOINTMENT_REQUEST_DECLINED_plural: 'Demande d’entrevue $t({{ appointmentType }}) de {{count}} min refusée',
  APPOINTMENT_REQUEST_CANCELLED_BY_DISMISS: 'Entrevue $t({{ appointmentType }}) annulé',
  APPOINTMENT_REQUEST_CANCELLED_BY_DISMISS_plural: 'Entrevue $t({{ appointmentType }}) de {{count}} min. annulé',
  APPOINTMENT_REQUEST_ACCEPTED: 'Entrevue $t({{ appointmentType }}) accepté',
  APPOINTMENT_REQUEST_ACCEPTED_plural: 'Entrevue $t({{ appointmentType }}) de {{count}} min. accepté',
  APPOINTMENT_PERIOD_CANCELLED: 'Plage horaire pour entrevue $t({{ appointmentType }}) annulée',
  APPOINTMENT_PERIOD_CANCELLED_plural: 'Plage horaire pour entrevue $t({{ appointmentType }}) de {{count}} min. annulée',
  APPOINTMENT_MESSAGE: { APPOINTMENT_DATE: '{{date, dddd D MMM---upperFirst}} à {{date, h:mm A}}' },
};
