import firebase from 'firebase/app';
import { Action, action, thunk, Thunk, ThunkOn, thunkOn } from 'easy-peasy';
import Bugsnag from '@bugsnag/js';
import _isNil from 'lodash/isNil';
import { AuthStoreModel } from '../../modules/auth/models/authModel';
import { onSnapshotById } from '../../firebase/firestore/firestore';
import { Collections } from '../../firebase/firestore/collections';
import { User } from '../../firebase/firestore/documents/user';
import { snapshotsManager } from '../../firebase/firestore/snapshots';
import { RemoteConfigKey, RemoteConfigStoreModel } from '../../firebase/remoteConfig';
import { Accounts } from '../../firebase/firestore/documents/accounts';

const isInvalidUser = (user: User) => {
  return !user.isEmployer;
};

export type AppStoreModel = {
  app: AppModel;
};

export enum AuthenticationStatus {
  AUTHENTICATED = 'Authenticated',
  NOT_AUTHENTICATED = 'NotAuthenticated',
  INVALID_USER = 'InvalidUser',
  NEW_USER = 'NewUser',
}

export interface AppModel {
  initialized: boolean;
  authenticationStatus: AuthenticationStatus;
  user?: User;
  accounts?: Accounts;
  userSnapshotListenerId?: string;
  setInitialized: Action<AppModel, boolean>;
  setAuthenticationStatus: Action<AppModel, AuthenticationStatus>;
  setUser: Action<AppModel, User | undefined>;
  setUserSnapshotListenerId: Action<AppModel, string | undefined>;
  onSetFirebaseUser: ThunkOn<AppModel, void, AuthStoreModel & RemoteConfigStoreModel>;
  initialize: Thunk<AppModel, void, void, AuthStoreModel & RemoteConfigStoreModel>;
  finalize: Thunk<AppModel, void, void, AuthStoreModel>;
  setAccounts: Action<AppModel, Accounts>;
  recruiterProfileImage: string;
  setRecruiterProfileImage: Action<AppModel, string>;
}

const appModel: AppModel = {
  initialized: false,
  authenticationStatus: AuthenticationStatus.NOT_AUTHENTICATED,
  user: undefined,
  userSnapshotListenerId: undefined,
  accounts: undefined,
  recruiterProfileImage: '',

  setUser: action((state, payload) => {
    state.user = payload;
    if (payload) {
      Bugsnag.setUser(payload.id, payload.email, `${payload.firstName} ${payload.lastName}`);
      firebase.analytics().setUserId(payload.id);
    }
  }),

  setInitialized: action((state, payload) => {
    state.initialized = payload;
  }),

  setAuthenticationStatus: action((state, payload) => {
    state.authenticationStatus = payload;
  }),

  setUserSnapshotListenerId: action((state, payload) => {
    snapshotsManager.unregisterSnapshot(state.userSnapshotListenerId);
    state.userSnapshotListenerId = payload;
  }),

  setAccounts: action((state, payload) => {
    state.accounts = payload;
  }),

  setRecruiterProfileImage: action((state, payload) => {
    state.recruiterProfileImage = payload;
  }),

  onSetFirebaseUser: thunkOn(
    (actions, storeActions) => storeActions.auth.setFirebaseUser,
    (actions, target, helpers) => {
      const initWithNoUser = () => {
        actions.setUserSnapshotListenerId(undefined);
        actions.setUser(undefined);
        actions.setInitialized(true);
        actions.setAuthenticationStatus(AuthenticationStatus.NOT_AUTHENTICATED);
      };

      const initWithInvalidUser = () => {
        actions.setUser(undefined);
        actions.setInitialized(true);
        actions.setAuthenticationStatus(AuthenticationStatus.INVALID_USER);
      };

      const initWithNewUser = () => {
        actions.setUser(undefined);
        actions.setInitialized(true);
        actions.setAuthenticationStatus(AuthenticationStatus.NEW_USER);
      };

      const initWithUser = (user: User) => {
        actions.setUser(user);
        actions.setInitialized(true);
        actions.setAuthenticationStatus(AuthenticationStatus.AUTHENTICATED);
      };

      const initWithSameUser = () => {
        actions.setInitialized(true);
      };

      if (_isNil(target.payload)) {
        initWithNoUser();
      } else if (target.payload.uid !== helpers.getState().user?.id) {
        const snapshotListenerId = onSnapshotById<User>(Collections.USERS, target.payload.uid, (user: User) => {
          const enableRegisterFeature = helpers.getStoreState().remoteConfig[RemoteConfigKey.ENABLE_REGISTER_FEATURE];
          if (!user && enableRegisterFeature) {
            initWithNewUser();
            return;
          }
          if (!user && !enableRegisterFeature) {
            initWithInvalidUser();
            return;
          }
          if (isInvalidUser(user)) {
            initWithInvalidUser();
            return;
          }
          initWithUser(user);
        });
        actions.setUserSnapshotListenerId(snapshotListenerId);
      } else {
        initWithSameUser();
      }
    },
  ),

  initialize: thunk((actions, payload, helpers) => {
    helpers.getStoreActions().auth.initialize();
    helpers.getStoreActions().remoteConfig.initialize();
  }),

  finalize: thunk((actions, payload, helpers) => {
    actions.setUserSnapshotListenerId(undefined);
    helpers.getStoreActions().auth.finalize();
  }),
};

export default appModel;
