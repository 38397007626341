import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';
import { Collections } from '../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Query } from '../../firebase/firestore/query/query';
import { LanguageEvaluationPhrases } from '../../firebase/firestore/documents/languageEvaluationPhrases';

const useLanguagePhrasesByAccountId = (accountId: string): LanguageEvaluationPhrases[] => {
  const phrases = useQuerySnapshot<LanguageEvaluationPhrases>(Collections.LANGUAGE_EVALUATION_PHRASES, [
    Query.field('account').equals(accountId),
  ]);
  if (_isNil(phrases)) return [];
  return _sortBy(phrases, 'phraseId');
};

export default useLanguagePhrasesByAccountId;
