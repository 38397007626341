import { Collections } from '../../firebase/firestore/collections';
import { useDocumentSnapshot } from '../../firebase/firestore/hooks';
import { Accounts } from '../../firebase/firestore/documents/accounts';
import { useCurrentUserProfile } from './useCurrentUserProfile';

const useAccounts = (): Accounts | undefined => {
  const { currentUserProfile } = useCurrentUserProfile();
  return useDocumentSnapshot<Readonly<Accounts>>(Collections.ACCOUNT, currentUserProfile?.account);
};

export default useAccounts;
