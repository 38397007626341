import _orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import CandidatePickerItemPresenter from './item/candidatePickerItemPresenter';
import CandidatesPickerPresenter from './candidatesPickerPresenter';
import { getTranslatedValue } from '../../../../../utils/localizedString';
import { CandidateStatus } from '../../../../../firebase/firestore/documents/candidate';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';

const useCandidatesPicker = (): CandidatesPickerPresenter => {
  const { i18n } = useTranslation();
  const { candidates } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const candidateToDisplay = candidates && candidates.length > 0 ? candidates : [selectedEngagedCandidate];

  return useMemo((): CandidatesPickerPresenter => {
    const candidateItems: CandidatePickerItemPresenter[] = [];
    /* istanbul ignore else */
    if (candidateToDisplay) {
      const orderedCandidates = _orderBy(
        candidateToDisplay,
        [(candidate) => candidate?.computedStatus?.lastUpdate || 0],
        ['desc'],
      );
      orderedCandidates?.forEach((candidate) => {
        if (candidate) {
          candidateItems.push(
            new CandidatePickerItemPresenter(
              candidate.id,
              candidate.computedStatus?.status || CandidateStatus.Unknown,
              getTranslatedValue(candidate.jobTitle, i18n.language),
              candidate.businessName,
              candidate.businessCompleteAddress || '',
              candidate,
            ),
          );
        }
      });
    }
    return new CandidatesPickerPresenter(candidateItems);
  }, [i18n.language, candidateToDisplay]);
};

export default useCandidatesPicker;
