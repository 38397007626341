import _isUndefined from 'lodash/isUndefined';
import { JobPosition } from '../../modules/administration/positionManagement/PositionType';
import { Collections } from '../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Query } from '../../firebase/firestore/query/query';
import { Position } from '../../firebase/firestore/documents/position';
import { useCurrentUserProfile } from './useCurrentUserProfile';

const usePositionsImpacted = (recruiterId: string): JobPosition[] | [] | undefined => {
  const { currentUserProfile } = useCurrentUserProfile();

  const positions: JobPosition[] | undefined = useQuerySnapshot<JobPosition>(Collections.POSITIONS, [
    Query.field<Position>('account').equals(currentUserProfile?.account),
    Query.field<Position>('open').equals(true),
    Query.field<Position>('automated').equals(true),
    Query.field<Position>('automationType').equals('scheduling'),
    Query.field<Position>('assignedRecruiters').arrayContains(recruiterId),
  ]);

  if (!currentUserProfile) return [];
  if (_isUndefined(positions)) return undefined;
  return positions;
};

export default usePositionsImpacted;
