/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { BoxProps, Stack } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { NotesViewWrapper } from './Notes.styles';
import { NoteViewsEnum } from './NotesViewsEnum';
import { NoteList } from './listNotes/NoteList';
import { CreateNotes } from './createNotes/CreateNotes';
import { ViewNote } from './viewNote/ViewNote';
import useSeekerNotes from './useSeekerNotes';
import { PositionNote } from './positionNote';
import useCandidateNote from './useCandidateNote';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { useStoreState } from '../../../../../models/hooks';
import { RemoteConfigKey } from '../../../../../firebase/remoteConfig';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';

const MotionStack = motion.custom(Stack);
const parentVariants = {
  visible: {
    transition: { staggerChildren: 0.05, delayChildren: 0.05 },
  },
  hidden: {
    transition: { staggerChildren: 0.01, staggerDirection: -1 },
  },
};

export type NotesProps = BoxProps;

export const Notes = ({ className = '' }: NotesProps): JSX.Element | null => {
  const [activeNoteParams, setActiveNoteParams] = useState(NoteViewsEnum.NOTE_LIST);
  const [activeNote, setActiveNote] = useState<PositionNote>();
  const [activeNoteIndex, setActiveNoteIndex] = useState<number | undefined>(-1);
  const [activeNoteId, setActiveNoteId] = useState<string | undefined>();

  const { selectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();

  const candidate = selectedCandidate || /* istanbul ignore next */ selectedEngagedCandidate;

  const seekerNotes = useSeekerNotes();

  const candidateNote = useCandidateNote();

  const isNoteDeleteFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_NOTE_DELETE_FEATURE];
  });

  const setActiveView = (page: NoteViewsEnum, note?: PositionNote, noteId?: string, index = -1) => {
    setActiveNoteParams(page);
    // istanbul ignore next
    if (note) setActiveNote(note);
    else setActiveNote(candidateNote);
    setActiveNoteIndex(index);
    // istanbul ignore next
    if (noteId) setActiveNoteId(noteId);
  };

  const prevCandidate = useRef(candidate);
  useEffect(() => {
    // istanbul ignore next
    if (candidate && prevCandidate.current && candidate.id !== prevCandidate.current.id) {
      prevCandidate.current = candidate;
      setActiveNoteParams(NoteViewsEnum.NOTE_LIST);
    }
  }, [candidate]);

  return (
    <MotionStack
      data-testid="Notes"
      initial="hidden"
      animate="visible"
      variants={parentVariants}
      spacing={8}
      css={NotesViewWrapper}
      className={['user-selectable', className].join(' ')}
    >
      {candidate && (
        <Fragment>
          {(!activeNote || activeNoteParams === NoteViewsEnum.NOTE_LIST) && (
            <NoteList
              notes={seekerNotes.presenter.positionNotes}
              state={seekerNotes.state}
              setActiveView={setActiveView}
              selectedCandidate={candidate}
              isNoteDeleteFeatureEnabled={isNoteDeleteFeatureEnabled}
            />
          )}

          {
            // istanbul ignore next
            activeNoteParams === NoteViewsEnum.NOTE_DETAIL && activeNote && activeNoteId && (
              <ViewNote
                activeNoteIndex={activeNoteIndex}
                activeNote={activeNote}
                noteId={activeNoteId}
                candidate={candidate}
                setActiveView={setActiveNoteParams}
                isNoteDeleteFeatureEnabled={isNoteDeleteFeatureEnabled}
              />
            )
          }

          {activeNoteParams === NoteViewsEnum.NOTE_FORM && activeNote && (
            <CreateNotes
              activeNoteIndex={activeNoteIndex}
              candidate={candidate}
              activeNote={activeNote}
              setActiveView={setActiveNoteParams}
              isNoteDeleteFeatureEnabled={isNoteDeleteFeatureEnabled}
              noteId={activeNoteId}
            />
          )}
        </Fragment>
      )}
    </MotionStack>
  );
};
