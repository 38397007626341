import React, { useEffect, useRef } from 'react';
import { Box, Flex, MenuItem, MenuItemProps, Text, useColorMode, Tooltip } from '@chakra-ui/core';
import { CheckCircleIcon } from '@chakra-ui/icons';

import { useTranslation } from 'react-i18next';
import { FaChevronCircleUp } from 'react-icons/fa';
import colors from '../../../../../../styles/colors';
import theme from '../../../../../../styles/customTheme';
import CandidatePickerItemPresenter from './candidatePickerItemPresenter';
import { PositionState } from '../../../../../common';
import { Candidate } from '../../../../../../firebase/firestore/documents/candidate';
import { useStoreState } from '../../../../../../models/hooks';
import { isCandidateFromFollowedPosition } from '../../../../../../services/CandidateService';
import { useSeekerContainerContext } from '../../../SeekerContainerContext';
import { RemoteConfigKey } from '../../../../../../firebase/remoteConfig';
import KisokIcon from '../../../../../../assets/img/Kiosk.svg';
import AppyHereIcon from '../../../../../../assets/img/icon-appyhere.svg';

export type CandidatesDropdownItemProps = MenuItemProps & {
  candidate: CandidatePickerItemPresenter;
  onPickItem: (candidate: Candidate) => void;
  candidateLength: number;
};

const CandidatesDropdownItem = ({
  candidate,
  onPickItem,
  candidateLength,
  ...rest
}: CandidatesDropdownItemProps): JSX.Element => {
  const { t } = useTranslation();

  const { colorMode } = useColorMode();
  const appUserId = useStoreState((s) => s.app.user?.id);

  const mainTextColor = colorMode === 'dark' ? colors.white : colors.black;
  const hoveredBgColor = colorMode === 'dark' ? theme.colors.whiteAlpha['300'] : colors.neutralGray['100'];
  const secondaryTextColor = colorMode === 'dark' ? colors.gray['300'] : colors.gray['500'];
  const { selectedCandidate } = useSeekerContainerContext();

  const renderFollowedIcon = () => {
    if (isCandidateFromFollowedPosition(candidate.candidate, appUserId)) {
      return (
        <Tooltip label={t('common:tooltip.followedPosition')} fontSize="sm">
          <CheckCircleIcon w={3} h={3} color={colors.green['600']} />
        </Tooltip>
      );
    }
    return null;
  };

  const didMount = useRef(false);

  const isPriorityFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_PRIORITY_CANDIDATE_FILTER];
  });

  useEffect(() => {
    if (didMount.current) onPickItem(candidate.candidate);
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateLength]);

  return (
    <MenuItem
      minH="48px"
      borderBottomWidth={1}
      borderBottomColor={colors.dividerGray}
      background={selectedCandidate?.id === candidate.id ? hoveredBgColor : ''}
      onClick={() => onPickItem(candidate.candidate)}
      _focus={{ background: hoveredBgColor }}
      {...rest}
    >
      <Flex w="100%" alignItems="center" py={2} test-id="CandidateDropdownItem">
        <Box mr={2} w="10px">
          {renderFollowedIcon()}
        </Box>
        <Flex flex={1} direction="column" minW={64} alignItems="flex-start" py={1} marginRight="4px">
          <Box>
            <Text fontSize="xs" textTransform="uppercase">
              {candidate.businessName}
            </Text>
            <Flex>
              <Text fontSize="sm" lineHeight="shorter" fontWeight="bold" color={mainTextColor}>
                {candidate.positionName}
              </Text>
            </Flex>
            <Text fontSize="xs" textTransform="uppercase" isTruncated maxW={{ base: '11rem', md: '12rem', lg: '16rem' }}>
              {candidate.businessCompleteAddress}
            </Text>
            <Flex alignItems="center" pt={1}>
              <PositionState status={candidate.status} style={{ margin: '0 0.25rem 0 0' }} />
              <Text fontSize="xs" color={secondaryTextColor} lineHeight="1">
                {t(`candidateStatus:${candidate.status}`)}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex justifyContent="flex-end" alignItems="center">
          {candidate.candidate.kioskEnabled && (
            <Tooltip hasArrow label={t('notification:seekerHeader.kioskTooltip')}>
              <span
                style={{
                  background: colors.blue['500'],
                  display: 'inline-block',
                  padding: '4px',
                  borderRadius: '50%',
                  marginRight: '6px',
                }}
              >
                <img alt="Kisko mode" src={KisokIcon} width="10px" />
              </span>
            </Tooltip>
          )}
          {candidate.candidate.interventionEnabled && (
            <Tooltip hasArrow label={t('notification:seekerHeader.interventionTooltip')}>
              <span
                style={{
                  background: colors.blue['500'],
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '4px',
                  borderRadius: '50%',
                  marginRight: '6px',
                  height: '18px',
                  width: '18px',
                }}
              >
                <img alt="intervene" src={AppyHereIcon} width="auto" />
              </span>
            </Tooltip>
          )}
          {isPriorityFeatureEnabled && candidate.candidate.priority && (
            <Tooltip label={t('notification:seekerHeader.priorityTooltip')} shouldWrapChildren>
              <FaChevronCircleUp color={colors.green[600]} />
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </MenuItem>
  );
};

export default CandidatesDropdownItem;
