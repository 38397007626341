import { thunk, Thunk } from 'easy-peasy';
import { OfferAndHireProcessController } from '../controllers/offerAndHireProcessController';
import { OfferAndHireDetails } from '../firebase/firestore/documents/offerAndHireProcess';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { HttpStoreModel } from '../http/models/httpModel';

export type OfferAndHireProcessStoreModel = {
  offerAndHireProcess: OfferAndHireProcessModel;
};

export interface OfferAndHireProcessModel {
  getOfferAndHireDetails: Thunk<
    OfferAndHireProcessModel,
    { accountId: string },
    void,
    HttpStoreModel,
    Promise<OfferAndHireDetails>
  >;
}

export const offerAndHireProcessStoreModel: OfferAndHireProcessModel = {
  getOfferAndHireDetails: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new OfferAndHireProcessController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getOfferAndHireDetails(payload.accountId);
    return response?.data as OfferAndHireDetails;
  }),
};
