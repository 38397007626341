import React, { ChangeEvent } from 'react';
import { Box, Checkbox, Stack } from '@chakra-ui/core';
import Star5 from '../../../../assets/img/star5.png';
import Star4 from '../../../../assets/img/star4.png';
import Star3 from '../../../../assets/img/star3.png';
import Star2 from '../../../../assets/img/star2.png';
import Star1 from '../../../../assets/img/star1.png';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';
import { SearchAndSortTypes } from '../filters/seekersFiltersContextValueType';

export const RatingsView = (): JSX.Element => {
  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();

  const onHandleRate = (searchAndSort: SearchAndSortTypes) => {
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...searchAndSort });
  };

  return (
    <Box>
      <Stack direction="column">
        <Checkbox
          data-testid="ratingCheckBox1"
          isChecked={searchAndSortTypes.rating[0] === 5}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.rating[0] = 5;
              onHandleRate(searchAndSortTypes);
            } else {
              searchAndSortTypes.rating[0] = 0;
              onHandleRate(searchAndSortTypes);
            }
          }}
        >
          <img src={Star5} alt="star5" />
        </Checkbox>
        <Checkbox
          data-testid="ratingCheckBox2"
          isChecked={searchAndSortTypes.rating[1] === 4}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.rating[1] = 4;
              onHandleRate(searchAndSortTypes);
            } else {
              searchAndSortTypes.rating[1] = 0;
              onHandleRate(searchAndSortTypes);
            }
          }}
        >
          <img src={Star4} alt="star4" />
        </Checkbox>
        <Checkbox
          data-testid="ratingCheckBox3"
          isChecked={searchAndSortTypes.rating[2] === 3}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.rating[2] = 3;
              onHandleRate(searchAndSortTypes);
            } else {
              searchAndSortTypes.rating[2] = 0;
              onHandleRate(searchAndSortTypes);
            }
          }}
        >
          <img src={Star3} alt="star3" />
        </Checkbox>
        <Checkbox
          data-testid="ratingCheckBox4"
          isChecked={searchAndSortTypes.rating[3] === 2}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.rating[3] = 2;
              onHandleRate(searchAndSortTypes);
            } else {
              searchAndSortTypes.rating[3] = 0;
              onHandleRate(searchAndSortTypes);
            }
          }}
        >
          <img src={Star2} alt="star2" />
        </Checkbox>
        <Checkbox
          data-testid="ratingCheckBox5"
          isChecked={searchAndSortTypes.rating[4] === 1}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.rating[4] = 1;
              onHandleRate(searchAndSortTypes);
            } else {
              searchAndSortTypes.rating[4] = 0;
              onHandleRate(searchAndSortTypes);
            }
          }}
        >
          <img src={Star1} alt="star1" />
        </Checkbox>
      </Stack>
    </Box>
  );
};
