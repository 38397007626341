export default {
  audioEvaluationSubmission: {
    successful: 'Évaluation de la langue réussie',
    unsuccessful: 'Évaluation de la langue non réussie',
  },
  audioRating: {
    accuracy: 'Justesse',
    fluency: 'Aisance',
    pronunciation: 'Prononciation',
  },
  phrase: 'Phrase',
  evaluateAudio: "Évaluer l'enregistrement",
  submit: 'Submit',
  sendInterviewRequest: "Envoyer une demande d'entrevue",
  languageEvaluation: 'Évaluation de la langue',
  status: {
    EXPERT: 'Expert',
    COMPETENT: 'Compétent',
    LIMITED: 'Limité',
    OCCASIONAL: 'Minimale',
    NEEDS_REVIEW: "Besoin d'entrevue",
    TO_BE_ACCESSED: 'À évaluer',
    REQUESTED: 'Demandé',
    RE_REQUESTED: 'Redemandée',
  },
  account: {
    requisitionDoesNotExist: "Il n'y a actuellement aucune réquisition active.",
  },
  resubmitAudio: `Redemander l'évaluation de la langue`,
  resubmitAudioTooltip: `L'ancien enregistrement de l'évaluation sera supprimé, et le candidat sera demandé de reprendre l'évaluation de la langue.`,
  resubmissionMsg: `Vous avez demandé au candidat de reprendre son évaluation de la langue.`,
  audioEvaluationReSubmission: {
    successful: 'Évaluation de la langue redemandée avec succès',
    unsuccessful: 'Évaluation de la langue ne pouvait pas être redemandée',
  },
  reRequestAudio: {
    title: ` Redemander l'évaluation de la langue`,
    subTitle: `Voulez-vous que le candidat reprenne leur évaluation de la langue?`,
    cancel: 'Annuler',
    reRequest: 'Redemander',
  },
  ratingInfoPass: 'Ce candidat a réussi son évaluation de la langue.',
  ratingInfoFail: 'Ce candidat a échoué son évaluation de la langue.',
  ratingInfoToastMessage: 'Ce candidat a échoué son évaluation de la langue. Il sera rejeté dans {{hours}} heures.',
};
