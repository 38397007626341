import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useStoreActions, useStoreState } from '../models/hooks';
import Routes from '../routes/Routes';
import { AppLoadingSpinner } from './components';
import BrowserDetectAlertDialog from './components/BrowserDetectAlertDialog';
import DeviceDetectAlertDialog from './components/DeviceDetectAlertDialog';
import { InterviewReminderAlert } from './components/InterviewReminderAlert';
import { AvailabilityReminderModal, AvailabilityNotificationType } from './components/AvailabilityReminderModal';
import { MaintenanceView } from '../error/MaintenanceView';
import useConfiguration, { IsUnderMaintenanceEnum } from './hooks/useConfiguration';
import { VacancyStatusReminderModal } from './components/VacancyStatusReminderModal';
import { PositionInactiveNotificationModal } from './components/PositionInactiveNotificationModal';
import { useCurrentUserProfile } from './hooks/useCurrentUserProfile';
import { useEmployerPicture } from '../firebase/storage/hooks/useEmployerPicture';

const App: React.FC = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDeviceOpen, setIsDeviceOpen] = React.useState(false);

  const useConfig = useConfiguration();
  const appInitialize = useStoreActions((actions) => actions.app.initialize);
  const appFinalize = useStoreActions((actions) => actions.app.finalize);
  const appInitialized = useStoreState((state) => state.app.initialized);
  const videoInterviewReminder = useStoreState((state) => state.notifications.videoInterviewReminder);
  const lowAvailabilityReminder = useStoreState((state) => state.notifications.lowAvailabilityReminder);
  const noAvailabilityReminder = useStoreState((state) => state.notifications.noAvailabilityReminder);
  const positionOpeningsFilledReminder = useStoreState((state) => state.notifications.positionOpeningsFilledReminder);
  const positionClosingReminder = useStoreState((state) => state.notifications.positionClosingReminder);
  const positionClosedReminder = useStoreState((state) => state.notifications.positionClosedReminder);
  const positionInactiveReminder = useStoreState((state) => state.notifications.positionInactiveReminder);
  const inactivePositionClosed = useStoreState((state) => state.notifications.inactivePositionClosed);
  const isRequisitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);
  const location = window.location.href;
  const groupEventIndex = location.indexOf('groupEvent/invitation');
  const guestMeetIndex = location.indexOf('guestMeet');
  const nav = window.navigator.userAgent;
  const chromeIndex = nav.indexOf('Chrome');
  const fireIndex = nav.indexOf('Firefox');
  const setRecruiterProfileImage = useStoreActions((a) => a.app.setRecruiterProfileImage);
  const { currentUserProfile, initialized } = useCurrentUserProfile();
  const picture = useEmployerPicture(currentUserProfile?.id);

  useEffect(() => {
    appInitialize();
    if (chromeIndex === -1 && fireIndex === -1 && !(groupEventIndex > -1) && !(guestMeetIndex > -1)) {
      setIsOpen(true);
    }
    if (isMobile && !(groupEventIndex > -1) && !(guestMeetIndex > -1)) {
      setIsDeviceOpen(true);
    }
    return () => {
      appFinalize();
    };
  }, [appInitialize, appFinalize, chromeIndex, fireIndex, groupEventIndex, guestMeetIndex]);

  useEffect(() => {
    if (initialized && picture) {
      setRecruiterProfileImage(picture);
    }
  }, [setRecruiterProfileImage, picture, initialized]);

  if (!appInitialized) {
    return <AppLoadingSpinner />;
  }

  const returnInterviewAlert = () => {
    if (videoInterviewReminder) {
      return <InterviewReminderAlert data={videoInterviewReminder.data} notification={videoInterviewReminder.notification} />;
    }
    return null;
  };
  const returnAvailabilityModal = () => {
    if (noAvailabilityReminder) {
      return <AvailabilityReminderModal type={AvailabilityNotificationType.NO_AVAILABILITY} />;
    }
    if (lowAvailabilityReminder) {
      return <AvailabilityReminderModal type={AvailabilityNotificationType.LOW_AVAILABILITY} />;
    }
    return null;
  };

  const returnPositionModal = () => {
    if (positionOpeningsFilledReminder) {
      return <VacancyStatusReminderModal notificationPayload={positionOpeningsFilledReminder} />;
    }
    if (positionClosingReminder) {
      return <VacancyStatusReminderModal notificationPayload={positionClosingReminder} />;
    }
    if (positionClosedReminder) {
      return <VacancyStatusReminderModal notificationPayload={positionClosedReminder} />;
    }
    if (positionInactiveReminder) {
      return <PositionInactiveNotificationModal notificationPayload={positionInactiveReminder} />;
    }
    if (inactivePositionClosed) {
      return <PositionInactiveNotificationModal notificationPayload={inactivePositionClosed} />;
    }
    return null;
  };

  if (useConfig === IsUnderMaintenanceEnum.MAINTENANCE) {
    return <MaintenanceView />;
  }

  return (
    <>
      {!isRequisitionEnabled && returnPositionModal()}
      {returnAvailabilityModal()}
      {returnInterviewAlert()}
      <BrowserDetectAlertDialog open={isOpen} setIsOpen={setIsOpen} />
      <Routes />
      <DeviceDetectAlertDialog isDeviceOpen={isDeviceOpen} setIsDeviceOpen={setIsDeviceOpen} />
    </>
  );
};

export default App;
