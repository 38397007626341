import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/core';
import React, { useState } from 'react';
import _reject from 'lodash/reject';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { FollowedRecruiters } from '../PositionType';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';

export type PositionFollowButtonProps = {
  recruiterId: string;
  assignedRecruiters: FollowedRecruiters[];
  positionId: string;
  callbackAssignedRecruiters: (recruiters: FollowedRecruiters[], positionId: string) => void;
  notifyUpdatingStateToParent: (status: boolean, positionId: string) => void;
};

export const PositionFollowButton = ({
  recruiterId,
  assignedRecruiters,
  positionId,
  callbackAssignedRecruiters,
  notifyUpdatingStateToParent,
}: PositionFollowButtonProps): JSX.Element => {
  const [isUpdatingFollow, setUpdatingFollow] = useState<boolean>(false);
  const { updatePositionByFollow, updatePositionByUnFollow } = useStoreActions((actions) => actions.positionManagement);
  const isWarnModalWhileUnFollowPositionEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_WARN_MODAL_WHILE_UNFOLLOW_POSITION];
  });
  const { t } = useTranslation();
  const { isOpen: isOpenWarnLastRecruiter, onClose: onCloseWarnLastRecruiter, onOpen: onOpenWarnLastRecruiter } = useDisclosure();
  const {
    isOpen: isOpenWarnToUnFollowPosition,
    onClose: onCloseWarnToUnFollowPosition,
    onOpen: onOpenWarnToUnFollowPosition,
  } = useDisclosure();

  // const isFollowed = _includes(assignedRecruiters, recruiterId);
  const isFollowed =
    assignedRecruiters.filter(function (value) {
      return value.id === recruiterId;
    }).length > 0;
  const updatePositionFollow = async () => {
    setUpdatingFollow(true);
    notifyUpdatingStateToParent(true, positionId);
    /* istanbul ignore else  */
    if (!isFollowed) {
      await updatePositionByFollow({ positionId });
      setUpdatingFollow(false);
      notifyUpdatingStateToParent(false, positionId);
      callbackAssignedRecruiters([...assignedRecruiters, { id: recruiterId, name: '' }], positionId);
    } else {
      await updatePositionByUnFollow({ positionId, recruiterId });
      setUpdatingFollow(false);
      notifyUpdatingStateToParent(false, positionId);
      callbackAssignedRecruiters(_reject(assignedRecruiters, { id: recruiterId }), positionId);
    }
  };

  return (
    <>
      <Button
        size="sm"
        variant="ghost"
        width="100%"
        style={{ borderRadius: 'unset', background: 'unset' }}
        isLoading={isUpdatingFollow}
        onClick={() => {
          if (isWarnModalWhileUnFollowPositionEnabled) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises,@typescript-eslint/no-unused-expressions
            isFollowed ? onOpenWarnToUnFollowPosition() : updatePositionFollow();
          } else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises,@typescript-eslint/no-unused-expressions
            assignedRecruiters.length < 2 && isFollowed ? onOpenWarnLastRecruiter() : updatePositionFollow();
          }
        }}
        data-testid={`UpdateFollowBtn-${positionId}`}
      >
        {isFollowed ? t('jobPosition:unFollow') : t('jobPosition:follow')}
      </Button>
      <Modal isOpen={isOpenWarnLastRecruiter} onClose={onCloseWarnLastRecruiter} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('jobPosition:unfollowModal.header')}</ModalHeader>
          <ModalBody>{t('jobPosition:unfollowModal.body')}</ModalBody>
          <ModalFooter mt={2}>
            <Button
              data-testid="unfollowModalConfirmBtn"
              colorScheme="blue"
              mr={3}
              onClick={async () => {
                await updatePositionFollow();
                onCloseWarnToUnFollowPosition();
                onCloseWarnLastRecruiter();
              }}
            >
              {t(`common:button.okay`)}
            </Button>
            <Button data-testid="unfollowModalCloseBtn" colorScheme="red" onClick={onCloseWarnLastRecruiter}>
              {t('common:button.cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenWarnToUnFollowPosition} onClose={onCloseWarnToUnFollowPosition} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('jobPosition:unfollowWarningModal.header')}</ModalHeader>
          <ModalBody>{t('jobPosition:unfollowWarningModal.body')}</ModalBody>
          <ModalFooter mt={2}>
            <Button
              data-testid="warnedUnfollowModalConfirmBtn"
              colorScheme="blue"
              mr={3}
              onClick={async () => {
                if (assignedRecruiters.length < 2) {
                  onOpenWarnLastRecruiter();
                } else {
                  await updatePositionFollow();
                  onCloseWarnToUnFollowPosition();
                  onCloseWarnLastRecruiter();
                }
              }}
            >
              {t(`common:button.okay`)}
            </Button>
            <Button data-testid="warnedUnfollowModalCloseBtn" colorScheme="red" onClick={onCloseWarnToUnFollowPosition}>
              {t('common:button.cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
