import React from 'react';
import { BoxProps, Text, useColorMode } from '@chakra-ui/core';

type FontSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl';

export type LabelProps = BoxProps & {
  children?: React.ReactNode;
  size?: FontSize;
  bold?: boolean;
  colorDarkMode?: string;
  dataTestId?: string;
};

const Label = ({
  children,
  size,
  bold,
  fontSize,
  color = 'typographyBlack',
  colorDarkMode = 'gray.200',
  whiteSpace = 'nowrap',
  fontWeight,
  lineHeight = 'shorter',
  paddingRight = 1,
  dataTestId = 'defaultLabelTestId',
  ...rest
}: LabelProps): JSX.Element => {
  const { colorMode } = useColorMode();
  const finalColor = colorMode === 'dark' ? colorDarkMode : color;
  return (
    <Text
      fontSize={size ?? (fontSize || 'sm')}
      fontWeight={bold ? 'bold' : fontWeight || 'normal'}
      whiteSpace={whiteSpace}
      lineHeight={lineHeight}
      paddingRight={paddingRight}
      color={finalColor}
      {...rest}
      data-testid={dataTestId}
    >
      {children}
    </Text>
  );
};

export default Label;
