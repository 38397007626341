import { Badge, Box, Flex, Text } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment-timezone';
import colors from '../../../../../styles/colors';
import { useCandidatesWithOfferAlreadyMade } from '../../../../../app/hooks/useCandidatesWithOfferAlreadyMade';
import { useMessagingViewContext } from '../../../MessagingViewContext';
import { getTranslatedValue } from '../../../../../utils/localizedString';
import { Candidate } from '../../../../../firebase/firestore/documents/candidate';

export const OfferMadePositionsView = (): JSX.Element => {
  const { t } = useTranslation('messaging');
  const { selectedEngagedCandidate } = useMessagingViewContext();
  const candidatesWithOfferAlreadyMade = useCandidatesWithOfferAlreadyMade(selectedEngagedCandidate?.seeker);

  const getOfferDate = (candidate: Candidate) => {
    if (candidate.hiredAt) {
      return moment(candidate?.hiredAt).format('lll');
    }
    if (candidate?.offerMadeAt) {
      return moment(candidate?.offerMadeAt).format('lll');
    }
    return '-';
  };

  const getRecruiterName = (candidate: Candidate) => {
    if (candidate.hiredByFormApproval) {
      return t('messaging:offerMadewarning.hireThroughAPI');
    }
    if (candidate.hiredAt && candidate.hiredByFullName) {
      return candidate.hiredByFullName;
    }
    if (candidate?.offerMadeAt && candidate.offerMadeByFullName) {
      return candidate.offerMadeByFullName;
    }
    return '-';
  };
  return (
    <Box>
      {candidatesWithOfferAlreadyMade && candidatesWithOfferAlreadyMade.length > 0 && (
        <Box mt="12px">
          <Flex
            mt="8px"
            justifyContent="space-between"
            px={1}
            py={1}
            fontSize="13.5px"
            bg="white"
            border="1px solid"
            borderColor="#E5E7EB"
          >
            <Box textTransform="capitalize" fontWeight="bold" w="25%" fontSize="16px">
              {t('messaging:offerMadewarning.position')}
            </Box>
            <Box w="20%" textTransform="capitalize" fontWeight="bold" fontSize="16px">
              {t('messaging:offerMadewarning.recruiter')}
            </Box>
            <Box w="20%" textTransform="capitalize" fontWeight="bold" fontSize="16px">
              {t('messaging:offerMadewarning.date')}
            </Box>
            <Box w="20%" textTransform="capitalize" fontWeight="bold" fontSize="16px">
              {t('messaging:offerMadewarning.offerStatus')}
            </Box>
            <Box w="15%" textTransform="capitalize" fontWeight="bold" fontSize="16px">
              {t('messaging:offerMadewarning.currentStatus')}
            </Box>
          </Flex>
          {candidatesWithOfferAlreadyMade?.map((c, index) => (
            <Flex
              justifyContent="space-between"
              key={c.id}
              px={1}
              py={1}
              fontSize="13.5px"
              bg={index % 2 === 0 ? '#EDF2F7' : 'white'}
              borderRight="1px solid"
              borderLeft="1px solid"
              borderColor="#E5E7EB"
              borderBottom={candidatesWithOfferAlreadyMade?.length - 1 === index ? '1px solid #E5E7EB' : 'unset'}
            >
              <Box w="25%" fontSize="16px">
                <Text>{getTranslatedValue(c?.newHireData?.jobTitle ? c?.newHireData?.jobTitle : c.jobTitle, i18n.language)}</Text>
                <Text fontSize="12px" color={colors.gray[400]}>
                  {c?.newHireData?.businessName ? c?.newHireData?.businessName : c.businessName}
                </Text>
              </Box>
              <Box w="20%" fontSize="16px">
                {getRecruiterName(c)}
              </Box>
              <Box w="20%" fontSize="16px">
                {getOfferDate(c)}
              </Box>
              <Box w="20%" fontSize="16px">
                <Badge variant="outline" colorScheme={c?.hiredAt ? 'green' : 'blue'}>
                  {c?.hiredAt ? t(`candidateStatus:Hired`) : t(`candidateStatus:OFFER_MADE`)}
                </Badge>
              </Box>
              <Box w="15%" fontSize="16px">
                {t(`candidateStatus:${c?.computedStatus?.status}`)}
              </Box>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
};
