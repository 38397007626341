import React, { ReactText } from 'react';
import {
  Box,
  Flex,
  Heading,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  HStack,
  Stack,
  Radio,
  RadioGroup,
  InputGroup,
  Checkbox,
  InputRightAddon,
  CheckboxGroup,
  Text,
  NumberInputField,
  NumberInput,
} from '@chakra-ui/core';
import { RiFilter3Line, RiCloseFill } from 'react-icons/ri';
import { MdOutlineUpgrade } from 'react-icons/md';
import {
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/slider';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import { SelectedTab, useTopCandidatesContext } from './TopCandidatesContext';

export enum FilterTypes {
  SALARY = 'salary',
  SALARY_RANGE = 'salaryRange',
  OTHER_PARAMS = 'otherFilterParams',
  EXPERIENCE_RANGE = 'experienceRange',
  LAST_ACTIVE = 'lastActiveDays',
}

export const TopCandidatesSidebarFilter = (): JSX.Element => {
  const { setIsFilterOpened, filterParams, setFilterParams, appliedFilter, setAppliedFilter, tab } = useTopCandidatesContext();
  const { t } = useTranslation('businessManagement');

  const experienceFilterLabels = [
    { label: t('topCandidate.lessThanSixMonth'), value: 0.5 },
    { label: t('topCandidate.oneYear'), value: 1 },
    { label: t('topCandidate.sixYears'), value: 6 },
  ];

  const otherParamsFilterLabels = [
    // { label: 'Has applied', value: 'hasApplied' },
    { label: t('topCandidate.flexTransport'), value: 'hasFlexibleTransportation' },
    // { label: 'Has answered questions', value: 'hasAnsweredQuestions' },
    // { label: 'Availability match', value: 'availabilityMatch' },
    // { label: 'Has been rated', value: 'hasBeenRated' },
  ];

  const lastActiveFilterLabels = [
    { label: t('topCandidate.lessThanAWeek'), value: 0.5 },
    { label: t('topCandidate.oneMonth'), value: 1 },
    { label: t('topCandidate.sixMonths'), value: 6 },
  ];

  const handleFilterItemChange = (value: ReactText | ReactText[] | number | number[], filterType: FilterTypes) => {
    setFilterParams((prevState) => ({
      ...prevState,
      [filterType]: value,
    }));
    if (!appliedFilter.includes(filterType)) {
      setAppliedFilter([...appliedFilter, filterType]);
    }
  };

  // function to change value for salary inputs
  const handleInputChange = (index: number, value: string, filterType: FilterTypes) => {
    setFilterParams((prevState) => {
      const updatedRange = [...(prevState.salaryRange ?? [0, 0])];
      updatedRange[index] = Number(value);

      return { ...prevState, salaryRange: updatedRange };
    });
    if (!appliedFilter.includes(filterType)) {
      setAppliedFilter([...appliedFilter, filterType]);
    }
  };

  // function to change value for experience input
  const handleExperienceInput = (index: number, value: string, filterType: FilterTypes) => {
    setFilterParams((prevState) => {
      const updatedExperience = [...(prevState.experienceRange ?? [0])];
      updatedExperience[index] = Number(value);

      return { ...prevState, experienceRange: updatedExperience };
    });
    if (!appliedFilter.includes(filterType)) {
      setAppliedFilter([...appliedFilter, filterType]);
    }
  };

  const getDefaultValue = (filterType: FilterTypes) => {
    switch (filterType) {
      case FilterTypes.SALARY:
        return '';
      case FilterTypes.SALARY_RANGE:
        return [0, 100];
      case FilterTypes.EXPERIENCE_RANGE:
        return [0];
      case FilterTypes.OTHER_PARAMS:
        return [];
      case FilterTypes.LAST_ACTIVE:
        return 0;
      default:
        return undefined;
    }
  };

  const handleRemoveFilterItem = (item: FilterTypes) => {
    const index = appliedFilter.findIndex((i) => i === item);
    appliedFilter.splice(index, 1);
    setAppliedFilter([...appliedFilter]);

    setFilterParams((prevState) => ({
      ...prevState,
      [item]: getDefaultValue(item),
    }));
  };

  // for formatting the value in input field with $
  const format = (val: number) => `$ ${val}`;

  return (
    <Box w="18%" borderRight="1px solid #E5E7EB" overflowY="auto">
      <Flex px="16px" py="8px" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" justifyContent="space-between">
          <RiFilter3Line fontSize="24px" />
          <Heading as="h4" fontSize="md" ml={2}>
            {t('topCandidate.filters')}
          </Heading>
        </Flex>
        <Box>
          <IconButton
            onClick={() => setIsFilterOpened(false)}
            bg="#EDF2F7"
            h="32px"
            w="32px"
            minWidth="32px"
            fontSize="20px"
            aria-label="close filter"
            p={0}
            icon={<MdOutlineUpgrade style={{ transform: 'rotate(-90deg)' }} />}
          />
        </Box>
      </Flex>
      <Box>
        <Flex px="16px" py="8px" alignItems="center" justifyContent="space-between">
          <Heading as="h6" lineHeight={1.1} fontWeight={700} fontSize="sm">
            {t('topCandidate.appliedFilters')}
          </Heading>
          {appliedFilter.length > 0 && (
            <Button
              onClick={() => {
                setAppliedFilter([]);
                setFilterParams({});
              }}
              size="sm"
              fontWeight="normal"
              rightIcon={<RiCloseFill />}
              colorScheme="blue"
              variant="ghost"
            >
              {t('topCandidate.resetFilters')}
            </Button>
          )}
        </Flex>
        <Box px="16px" py="8px" paddingTop={0}>
          <HStack spacing={2} flexWrap="wrap">
            {appliedFilter.map((filterItem) => (
              <Button
                key={filterItem}
                size="xs"
                fontWeight="normal"
                mb="12px"
                ml={2}
                rightIcon={<RiCloseFill onClick={() => handleRemoveFilterItem(filterItem as FilterTypes)} />}
                colorScheme="blue"
                variant="solid"
              >
                {t(`topCandidate.appliedFilterLabels.${filterItem}`)}
              </Button>
            ))}
          </HStack>
        </Box>
      </Box>
      <Box>
        <Accordion pr={4} allowMultiple defaultIndex={[0, 1, 2, 3, 4]}>
          {tab !== SelectedTab.BEST_MATCH && (
            <AccordionItem borderColor="#E5E7EB">
              <h2>
                <AccordionButton _focus={{ outline: 'none' }}>
                  <Box as="span" flex="1" textAlign="left" fontWeight={700} fontSize="sm">
                    {t('topCandidate.salary')}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack>
                  <RadioGroup
                    value={filterParams.salary || ''}
                    onChange={(data) => handleFilterItemChange(data, FilterTypes.SALARY)}
                  >
                    <Stack>
                      <Radio isChecked={filterParams.salary === 'yes'} size="md" value="yes" colorScheme="blue">
                        <Text fontSize={{ base: '12px', md: '14px', lg: '16px' }}>{t('topCandidate.withInRange')}</Text>
                      </Radio>
                      <Radio isChecked={filterParams.salary === 'no'} size="md" value="no" colorScheme="blue">
                        <Text fontSize={{ base: '12px', md: '14px', lg: '16px' }}>{t('topCandidate.aboveRange')}</Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          )}
          {tab === SelectedTab.BEST_MATCH && (
            <AccordionItem borderColor="#E5E7EB">
              <h2>
                <AccordionButton _focus={{ outline: 'none' }}>
                  <Box as="span" flex="1" textAlign="left" fontWeight={700} fontSize="sm">
                    {t('topCandidate.salaryRange')}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Flex alignItems="center" mb={4}>
                  <Box>
                    <InputGroup>
                      <NumberInput
                        value={filterParams?.salaryRange ? format(filterParams?.salaryRange?.[0]) : '$'}
                        onChange={(e) => handleInputChange(0, e, FilterTypes.SALARY_RANGE)}
                        min={0}
                        max={filterParams?.salaryRange ? filterParams.salaryRange[1] : 0}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </InputGroup>
                  </Box>
                  <Box px={4}>-</Box>
                  <Box>
                    <InputGroup>
                      <NumberInput
                        value={filterParams?.salaryRange ? format(filterParams.salaryRange[1]) : '$'}
                        onChange={(e) => handleInputChange(1, e, FilterTypes.SALARY_RANGE)}
                        min={filterParams?.salaryRange ? filterParams.salaryRange[0] : 0}
                        max={100}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </InputGroup>
                  </Box>
                </Flex>
                <RangeSlider
                  onChange={(data) => handleFilterItemChange(data, FilterTypes.SALARY_RANGE)}
                  ariaLabel={['min', 'max']}
                  value={
                    !isNil(filterParams.salaryRange) && filterParams.salaryRange?.[0] < filterParams.salaryRange?.[1]
                      ? filterParams.salaryRange
                      : [0, 0]
                  }
                >
                  <RangeSliderTrack bg="#E2E8F0">
                    <RangeSliderFilledTrack height="100%" backgroundColor="blue.500" />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} borderColor="blue.500" />
                  <RangeSliderThumb index={1} borderColor="blue.500" />
                </RangeSlider>
              </AccordionPanel>
            </AccordionItem>
          )}
          <AccordionItem borderColor="#E5E7EB">
            <h2>
              <AccordionButton _focus={{ outline: 'none' }}>
                <Box as="span" flex="1" textAlign="left" fontWeight={700} fontSize="sm">
                  {t('topCandidate.filters')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <CheckboxGroup
                colorScheme="blue"
                onChange={(data) => handleFilterItemChange(data, FilterTypes.OTHER_PARAMS)}
                value={filterParams.otherFilterParams || []}
              >
                <Stack spacing={3} direction="column">
                  {otherParamsFilterLabels.map((param) => (
                    <Checkbox key={param.value} value={param.value}>
                      <Text fontSize={{ base: '12px', md: '14px', lg: '16px' }}>{param.label}</Text>
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </AccordionPanel>
          </AccordionItem>

          {tab !== SelectedTab.BEST_MATCH && (
            <AccordionItem borderColor="#E5E7EB">
              <h2>
                <AccordionButton _focus={{ outline: 'none' }}>
                  <Box as="span" flex="1" textAlign="left" fontWeight={700} fontSize="sm">
                    {t('topCandidate.minExperience')}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={1} direction="row" flexWrap="wrap">
                  {experienceFilterLabels.map((exp) => (
                    <Button
                      key={exp.value}
                      mb={2}
                      ml={1}
                      size="sm"
                      fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                      style={{ whiteSpace: 'pre-wrap' }}
                      bg={filterParams?.experienceRange && filterParams?.experienceRange[0] === exp.value ? 'blue.500' : 'white'}
                      color={filterParams?.experienceRange && filterParams?.experienceRange[0] === exp.value ? 'white' : '#333'}
                      border="1px solid #E5E7EB"
                      onClick={() => handleFilterItemChange([exp.value], FilterTypes.EXPERIENCE_RANGE)}
                      _focus={{ outline: 'none' }}
                    >
                      {exp.label}
                    </Button>
                  ))}
                </Stack>
                <InputGroup mb={3} ml={1}>
                  <NumberInput
                    value={filterParams.experienceRange ? filterParams.experienceRange[0] : ''}
                    onChange={(e) => {
                      handleExperienceInput(0, e, FilterTypes.EXPERIENCE_RANGE);
                    }}
                    max={10}
                  >
                    <NumberInputField />
                  </NumberInput>
                  <InputRightAddon bg="#fff" borderColor="#dfdfdf" fontSize={{ base: '10px', md: '12px', lg: '15px' }}>
                    {t('topCandidate.years')}
                  </InputRightAddon>
                </InputGroup>

                {/* <RangeSlider
                  min={0}
                  max={10}
                  onChange={(data) => handleFilterItemChange(data, FilterTypes.EXPERIENCE_RANGE)}
                  value={filterParams.experienceRange}
                >
                  <RangeSliderTrack bg="#E2E8F0">
                    <RangeSliderFilledTrack height="100%" backgroundColor="blue.500" />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} borderColor="blue.500" />
                </RangeSlider> */}
                <Slider
                  min={0}
                  max={10}
                  value={filterParams.experienceRange ? filterParams.experienceRange[0] : 0}
                  onChange={(value) => handleFilterItemChange([value], FilterTypes.EXPERIENCE_RANGE)}
                >
                  <SliderTrack bg="#E2E8F0">
                    <SliderFilledTrack height="100%" backgroundColor="blue.500" />
                  </SliderTrack>
                  <SliderThumb borderColor="blue.500" />
                </Slider>
              </AccordionPanel>
            </AccordionItem>
          )}
          <AccordionItem borderColor="#E5E7EB">
            <h2>
              <AccordionButton _focus={{ outline: 'none' }}>
                <Box as="span" flex="1" textAlign="left" fontWeight={700} fontSize="sm">
                  {t('topCandidate.lastActive')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Stack spacing={1} direction="row" flexWrap="wrap">
                {lastActiveFilterLabels.map((lastActive) => (
                  <Button
                    key={lastActive.value}
                    bg={filterParams.lastActiveDays === lastActive.value ? 'blue.500' : 'white'}
                    color={filterParams.lastActiveDays === lastActive.value ? 'white' : '#333'}
                    mb={2}
                    ml={1}
                    size="sm"
                    fontSize={{ base: '10px', md: '12px', lg: '14px' }}
                    style={{ whiteSpace: 'pre-wrap' }}
                    border="1px solid #E5E7EB"
                    _focus={{ outline: 'none' }}
                    onClick={() => handleFilterItemChange(lastActive.value, FilterTypes.LAST_ACTIVE)}
                  >
                    {lastActive.label}
                  </Button>
                ))}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Box>
          <Flex alignItems="center" justifyContent="space-between" px="16px" py="10px">
            {appliedFilter.length > 0 && (
              <Button
                onClick={() => {
                  setAppliedFilter([]);
                  setFilterParams({});
                }}
                colorScheme="blue"
                variant="ghost"
              >
                {t('topCandidate.reset')}
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
