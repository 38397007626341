import React from 'react';
import Select, { components } from 'react-select';
import { Tooltip, Flex, Text } from '@chakra-ui/core';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import HireFromLogo from '../../../assets/img/Hire_Form_logo.svg';
import { useStoreState } from '../../../models/hooks';

export type OptionType = {
  id: string;
  label: string;
  value: string;
};

export type MultiSelectDropdownProps = {
  id?: string;
  placeholder?: string;
  options: OptionType[];
  value: OptionType | null;
  isDisabled?: boolean;
  showIcon?: boolean;
  handleChange: (data: OptionType | null) => void;
};

export const MultiSelectDropdown = ({
  placeholder,
  id,
  options,
  value,
  handleChange,
  showIcon,
  isDisabled,
}: MultiSelectDropdownProps): JSX.Element => {
  // istanbul ignore next
  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName('react-select__option--is-selected')[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 5);
  };
  const isHireFormEnabled = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabled);

  const hireFormEnabledPositions = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabledPositions);

  const { Option } = components;
  // istanbul ignore next
  const CustomOption = (props: any) => (
    <Option {...props}>
      <Flex justifyContent="space-between">
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */}
        <Text color={props.data.color}>{props.data.label}</Text>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
        {isHireFormEnabled && _includes(hireFormEnabledPositions, props.data.id) && showIcon && (
          <img src={HireFromLogo} alt="App Logo" width={22} />
        )}
      </Flex>
    </Option>
  );

  return (
    <Tooltip
      placement="top"
      size="xl"
      label={_find(options, { value: value?.label })?.label}
      aria-label={value?.label}
      shouldWrapChildren
    >
      <Select
        components={{ Option: CustomOption }}
        onMenuOpen={onMenuOpen}
        placeholder={placeholder}
        variant="filled"
        hideSelectedOptions={false}
        value={value}
        id={id}
        onChange={handleChange}
        isDisabled={isDisabled}
        options={options}
        classNamePrefix="react-select"
        maxMenuHeight={200}
      />
    </Tooltip>
  );
};
