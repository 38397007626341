/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { Redirect } from 'react-router-dom';
import { useCurrentUserProfile } from '../../app/hooks/useCurrentUserProfile';
import { Header } from '../../app/topBar/Header';
import { ANIMATED_VARIANTS, PATH_MAIN } from '../../routes/constants';
import { useAnalytics } from './useAnalytics';
import { AppLoadingSpinner } from '../../app/components';
import useConfiguration, { IsUnderMaintenanceEnum } from '../../app/hooks/useConfiguration';
import { MaintenanceView } from '../../error/MaintenanceView';
import useAccounts from '../../app/hooks/useAccounts';

const MotionBox = motion.custom(Box);

export const AnalyticsView = (): JSX.Element => {
  const [showAnalytics, setShowAnalytics] = React.useState<boolean>(true);
  const [url, setUrl] = React.useState<string>('');

  const { currentUserProfile } = useCurrentUserProfile();
  const accounts = useAccounts();
  const analyticsUrl = useAnalytics();
  const useConfig = useConfiguration();

  useEffect(() => {
    if (showAnalytics && analyticsUrl && currentUserProfile) {
      const encodedURL = analyticsUrl
        .replace('{{USER_ID}}', currentUserProfile.id)
        .replace('{{ACCOUNT_ID}}', currentUserProfile.account);
      setUrl(encodedURL);
    }
  }, [showAnalytics, analyticsUrl, currentUserProfile]);

  useEffect(() => {
    if (accounts) {
      setShowAnalytics(accounts.analyticsEnabled === true);
    }
  }, [accounts]);

  if (!showAnalytics) {
    return <Redirect to={PATH_MAIN} />;
  }

  return (
    <React.Fragment>
      {accounts && (
        <React.Fragment>
          {useConfig === IsUnderMaintenanceEnum.MAINTENANCE && <MaintenanceView />}
          {useConfig === IsUnderMaintenanceEnum.READY && (
            <MotionBox data-testid="AnalyticsView" initial="initial" animate="in" exit="out" variants={ANIMATED_VARIANTS}>
              <Header data-testid="Header" showMenu />
              {url.length > 0 && (
                <iframe
                  src={url}
                  data-testid="iframe"
                  title="analytics"
                  style={{ width: '100vw', height: `calc(100vh - 68px)` }}
                />
              )}
              {!url && <AppLoadingSpinner />}
            </MotionBox>
          )}
          {useConfig === IsUnderMaintenanceEnum.LOADING && <AppLoadingSpinner />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
