/* istanbul ignore file */
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
  updateObject,
} from '../../../utils/FormErrorUtils';
import { useStoreActions, useStoreState } from '../../../models/hooks';

export type TeamMemberInviteModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const TeamMemberInviteModal = ({ isOpen, onClose }: TeamMemberInviteModalProps): JSX.Element => {
  const { t } = useTranslation('team');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teamInviteInfo, setTeamInviteInfo] = useState({
    email: '',
  });
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);

  const { invite } = useStoreActions((actions) => actions.profileManagement);
  const { user } = useStoreState((state) => state.app);

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      email: Joi.string()
        .required()
        .email({ tlds: { allow: false } })
        .messages({
          'string.empty': t('createTeamModal.validationMessage.empty'),
          'string.email': t('createTeamModal.validationMessage.validation'),
        }),
    });

  const formValidation = (formData: { email: string }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = schema.validate(formData);

    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const handleChange = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    updateObject(teamInviteInfo, fieldName, event.target.value);

    setFormErrors(clearErrorMessage(formErrors, fieldName));
    setTeamInviteInfo({ ...teamInviteInfo });
  };

  const sendTeamInvites = async () => {
    if (formValidation(teamInviteInfo) && user) {
      setIsLoading(true);
      const teamMemberInfo = {
        user: user.id,
        inviteeEmail: teamInviteInfo.email,
      };
      await invite({ accountId: user.account, teamMemberInfo });
      setTeamInviteInfo({ email: '' });
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal id="TeamCreateModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="md">
      <ModalOverlay>
        <ModalContent pt={4} px={3} pb={8}>
          <ModalBody>
            <FormControl isInvalid={hasErrorMessage(formErrors, 'email')}>
              <FormLabel fontWeight="bold">{t('inviteTeamModal.inviteLabel')}</FormLabel>
              <Input
                variant="filled"
                id="email"
                type="email"
                placeholder={t('inviteTeamModal.inviteEmailPlaceholder')}
                size="lg"
                bg="gray.150"
                _hover={{ bg: 'gray.150' }}
                value={teamInviteInfo.email}
                onChange={(e) => handleChange('email', e)}
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'email')}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              data-testid="CancelButton"
              colorScheme="red"
              mr={3}
              onClick={() => {
                onClose();
                setTeamInviteInfo({ email: '' });
                setFormErrors([]);
              }}
              disabled={isLoading}
            >
              {t('inviteTeamModal.cancelBtn')}
            </Button>
            <Button data-testid="CreateButton" colorScheme="blue" isLoading={isLoading} onClick={() => sendTeamInvites()}>
              {t('inviteTeamModal.saveBtn')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
