import { css } from '@emotion/core';

export const seekerInfoPositions = css`
  max-height: 2rem;
  min-height: 14px;
  overflow-y: hidden;

  svg {
    @media only screen and (max-width: 60rem) {
      height: 9px;
      width: 9px;
    }
  }
`;
