export default {
  teamInfoTitle: "Gestion de l'entreprise",
  createTeamLabel: "Créez votre entreprise pour gérer vos sites et vos postes avec d'autres utilisateurs!",
  createTeamBtn: 'Créer une entreprise',
  editTeamLabel: "Modifier le nom de l'entreprise",
  addTeamBtn: 'Inviter un nouveau membre',
  teamSearchPlaceholder: 'Rechercher des membres',
  teamInfoTableHeader: {
    name: 'Nom',
    status: 'Statut',
    phoneNumber: 'Numéro de téléphone',
    action: 'Options',
  },
  teamInviteTableHeader: {
    email: 'Courriel',
    status: 'Statut',
    action: 'Options',
  },
  accountInviteInfo: "Invitations en attente d'une réponse",
  confirmationMessage: {
    promote: `Êtes-vous sûr de vouloir donner des droits administratifs à {{teamMemberName}}?`,
    demote: `Êtes-vous sûr de vouloir enlever les droits administratifs de {{teamMemberName}}?`,
    delete: `Êtes-vous sûr de vouloir retirer {{teamMemberName}} de la société ?`,
    cancel: `Êtes-vous sûr de vouloir annuler l'invitation envoyée à {{inviteEmail}}?`,
    reInvite: `Êtes-vous sûr de vouloir renvoyer une invitation à {{inviteEmail}}?`,
  },
  successMessage: {
    promote: `{{teamMemberName}} a maintenant des droits administratifs.`,
    demote: `{{teamMemberName}} n'a plus de droits administratifs.`,
    delete: `{{teamMemberName}} a été supprimé du compte.`,
    cancel: `L'invitation destinée à {{inviteEmail}} a été annulée avec succès.`,
    reInvite: `L'invitation destinée à {{inviteEmail}} a été renvoyée avec succès.`,
  },
  createTeamModal: {
    createLabel: 'Nommez votre entreprise',
    editLabel: "Modifier le nom de l'entreprise",
    cancelBtn: 'Annuler',
    saveBtn: 'Confirmer',
    updateBtn: 'Mettre à jour',
    successMessage: {
      create: "Nom de l'entreprise sauvegardé avec succès",
      update: "Nom de l'entreprise modifié avec succès",
    },
    validationMessage: {
      name: "Le nom de l'entreprise est requis",
      empty: 'Le courriel est requis',
      validation: 'Le courriel doit être une adresse valide',
    },
  },
  inviteTeamModal: {
    inviteLabel: 'Inviter un nouveau membre',
    inviteEmailPlaceholder: 'Courriel',
    cancelBtn: 'Annuler',
    saveBtn: 'Envoyer',
    validationMessage: {
      empty: 'Le courriel est requis',
      validation: 'Le courriel doit être une adresse valide',
    },
  },
  noData: 'Aucune donnée trouvée.',
  noPendingInvitation: 'Aucune invitation en attente trouvée',
  cancelInvite: "Annuler l'invitation",
  resend: 'Renvoyer',
  confirmBtn: 'Confirmer',
  cancelBtn: 'Annuler',
  admin: 'Administrateur',
  active: 'Actif',
  pending: 'En attente',
  expired: 'Expiré',
};
