import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Text, Box, Flex, Spinner } from '@chakra-ui/core';
import { GiPlainCircle } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import colors from '../../../../styles/colors';
import { RiskRequisiitonCountDefault, RiskRequisitionCount } from '../../../../firebase/firestore/documents/requisition';
import { RequisitionFilterTabNames } from '../requisitionInsightsTable/RequisitionInsightsTableType';

export type RequisitionCountViewTopBarProps = {
  requisitionsOverallCount: RiskRequisitionCount;
  isCountLoading: boolean;
  sendCurrentRequisitionToParent: (currentActiveTabName: RequisitionFilterTabNames) => void;
};

export const RequisitionCountViewTopBar = ({
  requisitionsOverallCount,
  isCountLoading,
  sendCurrentRequisitionToParent,
}: RequisitionCountViewTopBarProps): JSX.Element => {
  const { t } = useTranslation('requisition');
  const [currentActiveTabName, setCurrentActiveTabName] = useState(RequisitionFilterTabNames.UNFILLED);

  const formatRequisitionCountData = useCallback(
    (dataForCountFromApi: RiskRequisitionCount) => {
      return [
        {
          label: t('requisitionCount.unfilled'),
          currentTabName: RequisitionFilterTabNames.UNFILLED,
          value: dataForCountFromApi?.unfilled,
          iconColor: colors.persianBlue,
          isClickable: true,
        },
        {
          label: t('requisitionCount.atRisk'),
          currentTabName: RequisitionFilterTabNames.AT_RISK,
          value: dataForCountFromApi?.atRisk,
          iconColor: colors.yellow[500],
          isClickable: true,
        },
        {
          label: t('requisitionCount.overdue'),
          currentTabName: RequisitionFilterTabNames.OVERDUE,
          value: dataForCountFromApi?.overDue,
          iconColor: colors.red[500],
          isClickable: true,
        },
        { label: t('requisitionCount.locationImpacted'), value: dataForCountFromApi?.locationImpacted },
        { label: t('requisitionCount.positionImpacted'), value: dataForCountFromApi?.positionImpacted },
      ];
    },
    [t],
  );

  const [requisitionRiskCount, setRequisitionRiskCount] = useState(formatRequisitionCountData(RiskRequisiitonCountDefault));

  useEffect(() => {
    setRequisitionRiskCount(formatRequisitionCountData(requisitionsOverallCount));
  }, [requisitionsOverallCount, formatRequisitionCountData]);

  const setCurrentTab = (givenCurrentActiveTabName: RequisitionFilterTabNames) => {
    setCurrentActiveTabName(givenCurrentActiveTabName);
    sendCurrentRequisitionToParent(givenCurrentActiveTabName);
  };

  return (
    <Box bg="#fff" display="flex" alignItems="center">
      {requisitionRiskCount.map(({ isClickable, currentTabName, iconColor, label, value }, index) => (
        <Fragment key={`${label}-${value}`}>
          <Flex
            py="8px"
            px="15px"
            alignItems="center"
            justifyContent="space-between"
            background="#fff"
            borderRadius="8px"
            border=" 2px solid"
            mr="20px"
            borderColor={currentActiveTabName === currentTabName ? `blue.500` : `blue.30`}
            sx={{
              transition: 'transform 0.1s ease-in-out',
              _hover: isClickable ? { transform: 'scale(1.1)' } : {},
            }}
            onClick={() => {
              // istanbul ignore else
              if (!isNil(currentTabName)) setCurrentTab(currentTabName);
            }}
            data-testid={`requisition-tab-${currentTabName}`}
          >
            {iconColor && <GiPlainCircle style={{ marginRight: '4px' }} color={iconColor} size="14px" />}
            <Text fontSize="15px" fontWeight="bold" color={colors.gray[600]} ml="6px">
              {label}
            </Text>
            <Text
              fontSize="20px"
              color={colors.gray[900]}
              fontWeight={700}
              ml="10px"
              borderLeft="1px solid"
              borderColor="blue.30"
              pl="10px"
              lineHeight={1}
            >
              {!isCountLoading ? value : <Spinner size="sm" color={colors.blue[500]} padding="10px" />}
            </Text>
          </Flex>
          {index === 2 && <Box border="1px solid #E5E7EB" height="80%" mr="20px" />}
        </Fragment>
      ))}
    </Box>
  );
};
