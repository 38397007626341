import { Stack, HStack, Button, Text, Flex, Checkbox, Box } from '@chakra-ui/core';
import React from 'react';
import { IoClose } from 'react-icons/io5';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { SortEndType } from '../../positionManagement/createJobs/QuestionsListView';
import { MdDragIndicator } from 'react-icons/md';
import theme from '../../../../styles/customTheme';
import { FieldSchema, Language, OptionSchema } from './formBuilderSchema';
import LabelSchemaBuilder from './TextSchemaBuilder';
import { v4 as uuidv4 } from 'uuid';

const MAX_OPTIONS_COUNT = 6;
export function swap<T>(array: T[], indexA: number, indexB: number): T[] {
  const newArray = [...array];
  [newArray[indexA], newArray[indexB]] = [newArray[indexB], newArray[indexA]];
  return newArray;
}
export function move<T>(array: T[], fromIndex: number, toIndex: number): T[] {
  const newArray = [...array];
  const [removedItem] = newArray.splice(fromIndex, 1); // Remove the item
  newArray.splice(toIndex, 0, removedItem); // Insert it at the new position
  return newArray;
}

interface OptionSchemaBuilderProps {
  options: FieldSchema['optionList'];
  handleChange: (options: FieldSchema['optionList']) => void;
  language: Language;
  hideSelection?: boolean;
}

export default function OptionSchemaBuilder({ options, handleChange, hideSelection, language }: OptionSchemaBuilderProps) {
  const beforeHandleChange: OptionSchemaBuilderProps['handleChange'] = (options) => {
    handleChange(options?.map((o, i) => ({ ...o, id: uuidv4() })));
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEndType) => {
    beforeHandleChange(swap(options!, oldIndex, newIndex));
  };
  return (
    <Stack align="flex-start" spacing={4}>
      <SortableOptions
        options={options}
        handleChange={beforeHandleChange}
        language={language}
        onSortEnd={onSortEnd}
        distance={20}
        axis="y"
        lockAxis="y"
        hideSortableGhost={true}
        hideSelection={hideSelection}
        lockToContainerEdges
        useDragHandle
      />
      <Button
        variant="link"
        fontSize={12}
        fontWeight={'normal'}
        onClick={() => {
          beforeHandleChange([...options!, { id: uuidv4(), text: { [language]: '' }, isSelected: false }] as OptionSchema[]);
        }}
      >
        Add Option
      </Button>
    </Stack>
  );
}

interface SortableMCQOptionsProps extends Omit<OptionProps, 'value' | 'optionIndex'> {}

const SortableOptions = SortableContainer(function (props: SortableMCQOptionsProps) {
  return (
    <Stack align="flex-start" spacing={2} w="100%">
      {props.options?.map((value, index) => {
        return <Option {...props} key={index} optionIndex={index} value={value} index={index} />;
      })}
    </Stack>
  );
});

interface OptionProps extends Pick<OptionSchemaBuilderProps, 'handleChange' | 'options' | 'hideSelection' | 'language'> {
  value: OptionSchema;
  optionIndex: number;
}

const Option = SortableElement(function ({
  value,
  handleChange,
  options,
  optionIndex: index,
  hideSelection,
  language,
}: OptionProps) {
  const onGetOptionTypeByPriority = (priority: number) => {
    const maxPriority = Math.max(MAX_OPTIONS_COUNT);
    return String.fromCharCode(65 + (priority % maxPriority)).toUpperCase();
  };

  const SortableAnchor = SortableHandle(() => <MdDragIndicator cursor="grab" color={theme.colors.gray[300]} />);
  return (
    <HStack
      w="100%"
      backgroundColor={theme.colors.white}
      sx={{ '&.is-dragging': { opacity: 0.5, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' } }}
    >
      <Flex h="inherit" alignItems="center">
        <SortableAnchor />
      </Flex>
      <HStack spacing={2} backgroundColor={theme.colors.blue[30]} borderRadius={theme.radii.md} paddingX={2} paddingY={1}>
        <Text fontSize={theme.fontSizes.sm}>{onGetOptionTypeByPriority(index)}</Text>
        {!hideSelection && (
          <Checkbox
            isChecked={value.isSelected}
            onChange={(e) => handleChange(options?.map((o, i) => (i === index ? { ...o, isSelected: e.target.checked } : o)))}
          ></Checkbox>
        )}
      </HStack>
      <LabelSchemaBuilder
        flex={1}
        label={value.text[language]}
        onLabelChange={(label) =>
          handleChange(options?.map((o, i) => (i === index ? { ...o, text: { [language]: label! } } : o)) as OptionSchema[])
        }
        startWithEditView={false}
        language={language}
      />
      <Box flexBasis={'10%'}>
        {index >= 2 && (
          <Button px={1} onClick={() => handleChange(options?.filter((_, i) => i !== index))} variant="ghost" colorScheme="red">
            <IoClose />
          </Button>
        )}
      </Box>
    </HStack>
  );
});
