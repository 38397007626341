/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, BoxProps, Text, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { Label } from '../../../../../common';
import colors from '../../../../../../styles/colors';
import SeekerExperiencePresenter from '../seekerExperiencePresenter';

type ExperienceProps = BoxProps & {
  experience: SeekerExperiencePresenter;
  dataIndex: number;
  totalItems: number;
  displayBusiness?: boolean;
};

export const Experience = ({
  experience,
  dataIndex,
  totalItems,
  paddingTop,
  width,
  displayBusiness,
}: ExperienceProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box
      key={experience.id}
      paddingTop={paddingTop}
      width={width}
      borderBottomWidth={dataIndex + 1 === totalItems ? /* istanbul ignore next */ 0 : '1px'}
      borderStyle="solid"
      borderColor="gray.50"
      py={2}
      px={2}
      bg={dataIndex % 2 === 1 ? 'gray.150' : /* istanbul ignore next */ 'white'}
    >
      {experience.current && <Label bold color={colors.orange['500']}>{`(${t('profile.experience.current')})`}</Label>}
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Box spacing={0} direction="row">
          <Text fontWeight={700} fontSize="14px" noOfLines={2} isTruncated>
            {experience.jobTitle}
          </Text>
          {displayBusiness && (
            <Text fontSize="14px" mt="4px" fontWeight={400} color="#6B7280">
              {experience.business && experience.business}
            </Text>
          )}
        </Box>

        <Box
          fontSize="14px"
          background="gray.50"
          color="#4A5568"
          border="1px solid"
          borderColor="gray.100"
          borderRadius="4px"
          px={2}
          whiteSpace="nowrap"
          width="150px"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Text fontSize="xs">
            {experience.startMonth && experience.startYear
              ? `${t(`months.${experience.startMonth}`)}  ${experience.startYear} - `
              : /* istanbul ignore next */ null}
          </Text>
          <Text fontSize="xs">{experience.getDuration(t)}</Text>
        </Box>
      </Flex>
    </Box>
  );
};

Experience.defaultProps = {
  displayBusiness: false,
};
