import { css } from '@emotion/core';

export const searchAndSortWrapper = css`
  display: contents;

  .searchSidebar {
    grid-column: search / leftSide;
    grid-row: header / bottom;
    border-right: 1px solid rgba(151, 151, 151, 0.32);
    overflow: auto;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
  input {
    outline: 0;
  }
`;

export const searchByExperience = css`
  .infoBox {
    width: 76px;
    border: 1px solid #1f3cba;
    border-radius: 20px;
    background: #ffffff;
    color: #1f3cba;
    padding: 4px 10px;
    position: absolute;
    top: 20px;
    left: -15px;
    right: 0px;
    font-size: 14px;
  }
  .infoBox:before {
    transform: rotate(180deg);
    content: '';
    position: absolute;
    left: 0;
    right: 20px;
    top: -6px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 6px solid #1f3cba;
    -webkit-transform: rotate();
    -ms-transform: rotate();
    transform: rotate();
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  .infoBoxAged {
    width: 82px;
    border: 1px solid #1f3cba;
    border-radius: 20px;
    background: #ffffff;
    color: #1f3cba;
    padding: 4px 10px;
    position: absolute;
    top: 20px;
    left: -35px;
    right: 0px;
    font-size: 14px;
  }
  .infoBoxAged:before {
    transform: rotate(180deg);
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -6px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 6px solid #1f3cba;
    -webkit-transform: rotate();
    -ms-transform: rotate();
    transform: rotate();
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`;
