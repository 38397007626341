import { Box, Stack, theme, Divider, HStack, Text, Editable, EditableInput, EditablePreview } from '@chakra-ui/core';
import React from 'react';
import { IoMoveSharp } from 'react-icons/io5';
import { EmptyComponent } from './EmptyComponent';
import FormBuilderElement from './FormBuilderElement';
import { formBuilderSchemas, pageElements } from './formBuilderSchema';
import { FormBuilderProps } from './FormBuilder';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { swap } from './OptionSchemaBuilder';
import { MdDragIndicator } from 'react-icons/md';
import { SectionSchema } from '../HireformView';
import { v4 as uuidv4 } from 'uuid';

interface FormBuilderSectionsProps extends Omit<FormBuilderProps, 'templateName' | 'setTemplateName'> {}

export default function FormBuilderSections(props: FormBuilderSectionsProps) {
  const { formSchema, setFormSchema } = props;

  const handleDropSection = (event: React.DragEvent<HTMLDivElement>) => {
    const type = event.dataTransfer.getData('type');
    if (type === pageElements.section) {
      const newFormSchema = [...formSchema];
      newFormSchema.push({ section: 'section_' + (formSchema.length + 1), fields: [] });
      setFormSchema(newFormSchema);
    }
  };
  return (
    <Box flexGrow={1} onDrop={handleDropSection} onDragOver={(e) => e.preventDefault()}>
      <SortableSectionContainer
        {...props}
        onSortEnd={({ oldIndex, newIndex }) => {
          setFormSchema(swap(formSchema, oldIndex, newIndex));
        }}
        distance={20}
        axis="y"
        lockAxis="y"
        hideSortableGhost={true}
        lockToContainerEdges={true}
        useDragHandle
      />
    </Box>
  );
}

const SortableSectionContainer = SortableContainer(function ({ formSchema, ...props }: FormBuilderSectionsProps) {
  return (
    <Stack spacing={8} flexGrow={1}>
      {formSchema.map((section, sectionIndex) => {
        if (!section) return null;
        return (
          <SortableSectionElement
            formSchema={formSchema}
            section={section}
            sectionIndex={sectionIndex}
            index={sectionIndex}
            {...props}
          />
        );
      })}
    </Stack>
  );
});

interface SortableSectionElementProps extends FormBuilderSectionsProps {
  section: SectionSchema;
  sectionIndex: number;
}

const SortableSectionElement = SortableElement(function ({
  section,
  sectionIndex,
  setFormSchema,
  formSchema,
  language,
}: SortableSectionElementProps) {
  return (
    <SectionElementContainer
      section={section}
      sectionIndex={sectionIndex}
      setFormSchema={setFormSchema}
      formSchema={formSchema}
      language={language}
      key={sectionIndex}
      onSortEnd={({ oldIndex, newIndex }) => {
        const swappedFields = swap(section.fields, oldIndex, newIndex);
        const newSection = { ...section, fields: swappedFields };
        setFormSchema(
          formSchema.map((item, index) => {
            if (index === sectionIndex) {
              return newSection;
            }
            return item;
          }),
        );
      }}
      distance={20}
      axis="y"
      lockAxis="y"
      hideSortableGhost={true}
      useDragHandle
      lockToContainerEdges
    />
  );
});

interface SectionElementContainerProps extends Pick<FormBuilderSectionsProps, 'setFormSchema' | 'formSchema' | 'language'> {
  section: SectionSchema;
  sectionIndex: number;
}

const SectionElementContainer = SortableContainer(function ({
  section,
  setFormSchema,
  formSchema,
  language,
  sectionIndex,
}: SectionElementContainerProps) {
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const type = event.dataTransfer.getData('type');
    if (type?.length > 0) {
      if (type !== pageElements.section) {
        const { defaultValue } = formBuilderSchemas[type as keyof typeof formBuilderSchemas];
        const newSchema = formSchema?.map<SectionSchema>((item, index) => {
          if (index === sectionIndex) {
            return {
              ...item,
              fields: [...item.fields, { ...defaultValue, timestamp: Date.now(), id: uuidv4(), section: item.section }],
            };
          }
          return item;
        });

        setFormSchema(newSchema);
      }
    }
  };

  const SortableAnchor = SortableHandle(() => (
    <Box
      borderRadius="50%"
      cursor="grab"
      color={theme.colors.gray[300]}
      style={{ aspectRatio: '1/1' }}
      padding={1}
      size="sm"
      _groupHover={{ color: theme.colors.gray[500] }}
    >
      <MdDragIndicator />
    </Box>
  ));

  return (
    <Box role="group" backgroundColor={theme.colors.white} borderRadius={theme.radii.md} p={theme.space[4]} position="relative">
      <Box position="absolute" bottom="100%" left={0} width="100%">
        <HStack justify="space-between">
          <Editable
            backgroundColor={theme.colors.white}
            value={section?.section!}
            size="sm"
            fontSize={'xs'}
            padding={0}
            background={'none'}
          >
            <EditablePreview />
            <EditableInput
              onChange={(e) => {
                setFormSchema(
                  formSchema.map((item, index) => {
                    if (index === sectionIndex) {
                      return { ...item, section: e.target.value };
                    }
                    return item;
                  }),
                );
              }}
            />
          </Editable>
          <SortableAnchor />
        </HStack>
      </Box>
      <Stack spacing={4} divider={<Divider />}>
        {section?.fields?.length === 0 && (
          <EmptyComponent
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onDrop={(e) => {
              handleDrop(e);
            }}
          >
            <HStack color="#586073" align="center" justify="center" flexGrow={1}>
              <IoMoveSharp size={24} />
              <Text fontSize="sm" fontWeight="bold">
                Drag or Click on the items on your left to start building your form.
              </Text>
            </HStack>
          </EmptyComponent>
        )}
        {section?.fields?.map((item, index) => {
          return (
            <FormBuilderElement
              key={item.id}
              formSchema={formSchema}
              setFormSchema={setFormSchema}
              language={language}
              item={item}
              elementIndex={index}
              index={index}
              sectionIndex={sectionIndex}
            />
          );
        })}
      </Stack>
    </Box>
  );
});
