import { useEffect, useState } from 'react';
import { Participant, RemoteParticipant } from 'twilio-video';
import { useMeetContext } from '../../MeetContext';

export const useDominantSpeaker = (): Participant | null => {
  const { room } = useMeetContext();
  const [dominantSpeaker, setDominantSpeaker] = useState(room?.dominantSpeaker ?? null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      const onDominantSpeakerChanged = (newSpeaker: RemoteParticipant) => {
        if (newSpeaker !== null) {
          setDominantSpeaker(newSpeaker);
        }
      };

      const onParticipantDisconnected = (participant: RemoteParticipant) => {
        setDominantSpeaker((prevDominantSpeaker) => {
          return prevDominantSpeaker === participant ? null : prevDominantSpeaker;
        });
      };
      room.on('dominantSpeakerChanged', onDominantSpeakerChanged);
      room.on('participantDisconnected', onParticipantDisconnected);

      return () => {
        room.off('dominantSpeakerChanged', onDominantSpeakerChanged);
        room.off('participantDisconnected', onParticipantDisconnected);
      };
    }
  }, [room]);

  return dominantSpeaker;
};
