import React, { ChangeEventHandler } from 'react';
import { Box, BoxProps, Select, useColorMode } from '@chakra-ui/core';
import _isArray from 'lodash/isArray';

export type DropDownValueType = string | ReadonlyArray<string> | number;
export type DropDownOptionType = {
  label: string;
  value: DropDownValueType;
  disabled?: boolean;
  selected?: boolean;
};
export type DropDownOptionsType = DropDownOptionType[];

export type DropDownProps = BoxProps & {
  options: DropDownOptionsType;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  value?: DropDownValueType;
  disabled?: boolean;
  form?: string;
  colorScheme?: string;
};

/**
 * @param {DropDownValueType} value
 * @param {number} index
 * @return {string}
 */
const getValueAsString = (value: DropDownValueType, index: number): string => {
  if (_isArray(value)) {
    return value.join(',').concat(`-${index}`);
  }
  const strVal = String(value);
  return `${strVal}-${index}`;
};

const DropDown = ({
  options,
  onChange,
  value,
  fontSize,
  color = 'gray.600',
  colorScheme = 'gray.400',
  ...rest
}: DropDownProps): JSX.Element => {
  const { colorMode } = useColorMode();

  return (
    <Box as="div" {...rest}>
      <Select
        variant="unstyled"
        onChange={onChange}
        value={value}
        data-testid="DropDownSelect"
        fontSize={fontSize}
        color={colorMode === 'dark' ? colorScheme : color}
      >
        {options.map((option, index) => (
          <option key={getValueAsString(option.value, index)} value={option.value} selected={option?.selected}>
            {option.label}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default DropDown;
