import Bugsnag from '@bugsnag/js';
import { action, Action, Thunk, thunk } from 'easy-peasy';
import firebase from 'firebase/app';

import { RemoteConfigKey } from './keys';

const SETTINGS: firebase.remoteConfig.Settings = {
  minimumFetchIntervalMillis: 3600,
  fetchTimeoutMillis: 30000,
};

const DEFAULT_VALUES = {
  [RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]: 1440,
  [RemoteConfigKey.JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE]: 1,
  [RemoteConfigKey.ENABLE_MEET_FEATURE]: false,
  [RemoteConfigKey.ENABLE_CERTN_FEATURE]: false,
  [RemoteConfigKey.ENABLE_NOTIFICATION_FEATURE]: false,
  [RemoteConfigKey.ENABLE_BACKGROUND_CHECK_NOTIFICATION_FEATURE]: false,
  [RemoteConfigKey.ENABLE_GROUP_EVENT_NOTIFICATION_FEATURE]: false,
  [RemoteConfigKey.ENABLE_UNRESPONSIVE_CANDIDATES]: false,
  [RemoteConfigKey.ENABLE_POSITION_MANAGEMENT_FEATURE]: true,
  [RemoteConfigKey.ENABLE_TEAM_AVAILABILITY]: true,
  [RemoteConfigKey.ENABLE_SEARCH_AND_SORT_FEATURE]: false,
  [RemoteConfigKey.ENABLE_INITIALS_FEATURE]: false,
  [RemoteConfigKey.SHOW_WARNING_ON_HIRING_CANDIDATE_FEATURE]: false,
  [RemoteConfigKey.SHOW_FAVORITE_STATUS]: false,
  [RemoteConfigKey.SHOW_ARCHIVE_LIST]: false,
  [RemoteConfigKey.ENABLE_OVERVIEW_VIDEO]: false,
  [RemoteConfigKey.ENABLE_RECRUITER_MANAGEMENT_FEATURE]: false,
  [RemoteConfigKey.ENABLE_NOTE_DELETE_FEATURE]: false,
  [RemoteConfigKey.ENABLE_POSITION_FILTER_ON_SIDEBAR_OF_ADMINISTRATION_APPOINTMENT_CALENDAR]: false,
  [RemoteConfigKey.ENABLE_SEARCH_AND_SORT_FEATURE]: false,
  [RemoteConfigKey.ENABLE_POSITION_SEARCH]: false,
  [RemoteConfigKey.DASHBOARD_ENABLE_RECRUITER_SEARCH]: false,
  [RemoteConfigKey.RECRUITER_MAX_SELECT]: 10,
  [RemoteConfigKey.ENABLE_DISMISSED_CANDIDATE_LIST]: false,
  [RemoteConfigKey.ENABLE_REGISTER_FEATURE]: false,
  [RemoteConfigKey.ENABLE_UPDATE_PROFILE_FEATURE_WEB_RECRUITER]: false,
  [RemoteConfigKey.ENABLE_AVAILABILITY_FEATURE_IN_GLOBAL_SEARCH]: false,
  [RemoteConfigKey.ENABLE_INTEGRATE_GOOGLE_CALENDAR_FEATURE]: false,
  [RemoteConfigKey.ENABLE_INTEGRATE_MICROSOFT_CALENDAR_FEATURE]: false,
  [RemoteConfigKey.ENABLE_TEAM_MANAGEMENT_FEATURE]: false,
  [RemoteConfigKey.ENABLE_UN_DISMISSED_FEATURE]: false,
  [RemoteConfigKey.ENABLE_COPY_POSITION]: false,
  [RemoteConfigKey.ENABLE_NEW_UI_FOR_CREATE_POSITION]: false,
  [RemoteConfigKey.ENABLE_DISMISSAL_REASON]: false,
  [RemoteConfigKey.ENABLE_SUPPORT_DOCUMENTATION]: false,
  [RemoteConfigKey.ENABLE_HIRE_FORM_NOTIFICATION_FEATURE]: false,
  [RemoteConfigKey.ALLOW_ONLY_BUSINESS_AND_POSITION_WITH_REQUISITION]: false,
  [RemoteConfigKey.ENABLE_WARN_MODAL_WHILE_UNFOLLOW_POSITION]: false,
  [RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE]: false,
  [RemoteConfigKey.ENABLE_GROUP_IN_BUSINESS_LOCATION]: false,
  [RemoteConfigKey.ALLOW_SUPER_USERS_TO_DELETE_AVAILABILITY]: false,
  [RemoteConfigKey.ENABLE_VIDEO_GALLERY]: false,
  [RemoteConfigKey.ENABLE_COLLABORATOR_FEATURE]: true,
  [RemoteConfigKey.ENABLE_TOGGLE_OF_BACKGROUND_CHECK_ON_POSITION_LEVEL]: false,
  [RemoteConfigKey.ENABLE_PRIORITY_CANDIDATE_FILTER]: false,
  [RemoteConfigKey.ENABLE_TOP_CANDIDATES_OF_POSITION]: false,
  [RemoteConfigKey.ENABLE_NEW_ANALYTIC_VIEW]: false,
};

export type RemoteConfigStoreModel = {
  remoteConfig: RemoteConfigModel;
};

export interface RemoteConfigModel {
  [RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]: number;
  [RemoteConfigKey.ENABLE_MEET_FEATURE]: boolean;
  [RemoteConfigKey.JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE]: number;
  [RemoteConfigKey.ENABLE_CERTN_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_NOTIFICATION_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_BACKGROUND_CHECK_NOTIFICATION_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_GROUP_EVENT_NOTIFICATION_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_POSITION_MANAGEMENT_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_UNRESPONSIVE_CANDIDATES]: boolean;
  [RemoteConfigKey.ENABLE_TEAM_AVAILABILITY]: boolean;
  [RemoteConfigKey.ENABLE_SEARCH_AND_SORT_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_INITIALS_FEATURE]: boolean;
  [RemoteConfigKey.SHOW_WARNING_ON_HIRING_CANDIDATE_FEATURE]: boolean;
  [RemoteConfigKey.SHOW_FAVORITE_STATUS]: boolean;
  [RemoteConfigKey.SHOW_ARCHIVE_LIST]: boolean;
  [RemoteConfigKey.ENABLE_OVERVIEW_VIDEO]: boolean;
  [RemoteConfigKey.ENABLE_RECRUITER_MANAGEMENT_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_NOTE_DELETE_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_POSITION_FILTER_ON_SIDEBAR_OF_ADMINISTRATION_APPOINTMENT_CALENDAR]: boolean;
  [RemoteConfigKey.ENABLE_SEARCH_AND_SORT_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_POSITION_SEARCH]: boolean;
  [RemoteConfigKey.DASHBOARD_ENABLE_RECRUITER_SEARCH]: boolean;
  [RemoteConfigKey.RECRUITER_MAX_SELECT]: number;
  [RemoteConfigKey.ENABLE_DISMISSED_CANDIDATE_LIST]: boolean;
  [RemoteConfigKey.ENABLE_REGISTER_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_UPDATE_PROFILE_FEATURE_WEB_RECRUITER]: boolean;
  [RemoteConfigKey.ENABLE_AVAILABILITY_FEATURE_IN_GLOBAL_SEARCH]: boolean;
  [RemoteConfigKey.ENABLE_INTEGRATE_GOOGLE_CALENDAR_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_INTEGRATE_MICROSOFT_CALENDAR_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_TEAM_MANAGEMENT_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_UN_DISMISSED_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_COPY_POSITION]: boolean;
  [RemoteConfigKey.ENABLE_NEW_UI_FOR_CREATE_POSITION]: boolean;
  [RemoteConfigKey.ENABLE_DISMISSAL_REASON]: boolean;
  [RemoteConfigKey.ENABLE_SUPPORT_DOCUMENTATION]: boolean;
  [RemoteConfigKey.ENABLE_HIRE_FORM_NOTIFICATION_FEATURE]: boolean;
  [RemoteConfigKey.ALLOW_ONLY_BUSINESS_AND_POSITION_WITH_REQUISITION]: boolean;
  [RemoteConfigKey.ENABLE_WARN_MODAL_WHILE_UNFOLLOW_POSITION]: boolean;
  [RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_GROUP_IN_BUSINESS_LOCATION]: boolean;
  [RemoteConfigKey.ALLOW_SUPER_USERS_TO_DELETE_AVAILABILITY]: boolean;
  [RemoteConfigKey.ENABLE_VIDEO_GALLERY]: boolean;
  [RemoteConfigKey.ENABLE_COLLABORATOR_FEATURE]: boolean;
  [RemoteConfigKey.ENABLE_TOGGLE_OF_BACKGROUND_CHECK_ON_POSITION_LEVEL]: boolean;
  [RemoteConfigKey.ENABLE_PRIORITY_CANDIDATE_FILTER]: boolean;
  [RemoteConfigKey.ENABLE_TOP_CANDIDATES_OF_POSITION]: boolean;
  [RemoteConfigKey.ENABLE_NEW_ANALYTIC_VIEW]: boolean;

  setMinutesBeforeInactivity: Action<RemoteConfigModel>;
  setJoinVideoGracePeriodInMinute: Action<RemoteConfigModel>;
  setEnableMeetFeature: Action<RemoteConfigModel>;
  setEnableCertnFeature: Action<RemoteConfigModel>;
  setEnableNotificationFeature: Action<RemoteConfigModel>;
  setEnableBackgroundCheckNotificationFeature: Action<RemoteConfigModel>;
  setEnableGroupEventNotificationFeature: Action<RemoteConfigModel>;
  setEnablePositionManagementFeature: Action<RemoteConfigModel>;
  setEnableUnresponsiveCandidates: Action<RemoteConfigModel>;
  setEnableTeamAvailability: Action<RemoteConfigModel>;
  setEnableSearchAndSortFeature: Action<RemoteConfigModel>;
  setEnableInitialsFeature: Action<RemoteConfigModel>;
  setShowWarningOnHiringCandidateFeature: Action<RemoteConfigModel>;
  setShowFavoriteStatus: Action<RemoteConfigModel>;
  setShowArchiveList: Action<RemoteConfigModel>;
  setEnableOverviewVideo: Action<RemoteConfigModel>;
  setEnableRecruiterManagementFeature: Action<RemoteConfigModel>;
  setEnableNoteDeleteFeature: Action<RemoteConfigModel>;
  setEnablePositionFilterOnAdministrationAppointmentCalendar: Action<RemoteConfigModel>;
  setEnablePositionSearch: Action<RemoteConfigModel>;
  setDashboardEnableRecruiterSearch: Action<RemoteConfigModel>;
  setRecruiterMaxSelect: Action<RemoteConfigModel>;
  setEnableDismissedCandidatesList: Action<RemoteConfigModel>;
  setEnableRegisterFeature: Action<RemoteConfigModel>;
  setEnableUpdateProfileFeature: Action<RemoteConfigModel>;
  setEnableAvailabilityInGlobalSearch: Action<RemoteConfigModel>;
  setEnableIntegrateGoogleCalendarFeature: Action<RemoteConfigModel>;
  setEnableIntegrateMicrosoftCalendarFeature: Action<RemoteConfigModel>;
  setEnableTeamManagementFeature: Action<RemoteConfigModel>;
  setEnableUndismissedFeature: Action<RemoteConfigModel>;
  setEnableCopyPosition: Action<RemoteConfigModel>;
  setEnableSwitchNewUIForCreatePosition: Action<RemoteConfigModel>;
  setEnableDismissalReason: Action<RemoteConfigModel>;
  setEnableSupportDocumentation: Action<RemoteConfigModel>;
  setEnableEnableHireFormNotificationFeature: Action<RemoteConfigModel>;
  setAllowOnlyBusinessAndPositionWithRequisition: Action<RemoteConfigModel>;
  setEnableWarnModalWhileUnFollowPosition: Action<RemoteConfigModel>;
  setEnableMultipleChoiceFeature: Action<RemoteConfigModel>;
  setEnableGroupInBusinessLocation: Action<RemoteConfigModel>;
  setSuperUsersDeleteAvailability: Action<RemoteConfigModel>;
  setEnableVideoGallery: Action<RemoteConfigModel>;
  setEnableCollaborators: Action<RemoteConfigModel>;
  setEnableToggleOfBackgroundCheckOnPositionLevel: Action<RemoteConfigModel>;
  setEnablePriorityCandidateFilter: Action<RemoteConfigModel>;
  setEnableTopCandidatesOfPosition: Action<RemoteConfigModel>;
  setEnableNewAnalyticalView: Action<RemoteConfigModel>;

  initialize: Thunk<RemoteConfigModel, void>;
}

export const remoteConfigModel: RemoteConfigModel = {
  ...DEFAULT_VALUES,

  setEnableTeamAvailability: action((state) => {
    let isUnresponsiveCandidatesEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_TEAM_AVAILABILITY];
    try {
      isUnresponsiveCandidatesEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_TEAM_AVAILABILITY);
    } finally {
      state[RemoteConfigKey.ENABLE_TEAM_AVAILABILITY] = isUnresponsiveCandidatesEnabled;
    }
  }),
  setEnableSearchAndSortFeature: action((state) => {
    let isUnresponsiveCandidatesEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_SEARCH_AND_SORT_FEATURE];
    try {
      isUnresponsiveCandidatesEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_SEARCH_AND_SORT_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_SEARCH_AND_SORT_FEATURE] = isUnresponsiveCandidatesEnabled;
    }
  }),

  setEnableNoteDeleteFeature: action((state) => {
    let isNoteDeleteFeatureEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_NOTE_DELETE_FEATURE];
    try {
      isNoteDeleteFeatureEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_NOTE_DELETE_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_NOTE_DELETE_FEATURE] = isNoteDeleteFeatureEnabled;
    }
  }),

  setShowArchiveList: action((state) => {
    let isShowArchiveListEnabled = DEFAULT_VALUES[RemoteConfigKey.SHOW_ARCHIVE_LIST];
    try {
      isShowArchiveListEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.SHOW_ARCHIVE_LIST);
    } finally {
      state[RemoteConfigKey.SHOW_ARCHIVE_LIST] = isShowArchiveListEnabled;
    }
  }),

  setShowFavoriteStatus: action((state) => {
    let isShowFavoriteStatusEnabled = DEFAULT_VALUES[RemoteConfigKey.SHOW_FAVORITE_STATUS];
    try {
      isShowFavoriteStatusEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.SHOW_FAVORITE_STATUS);
    } finally {
      state[RemoteConfigKey.SHOW_FAVORITE_STATUS] = isShowFavoriteStatusEnabled;
    }
  }),

  setShowWarningOnHiringCandidateFeature: action((state) => {
    let isShowWarningOnHiringCandidateFeatureEnabled = DEFAULT_VALUES[RemoteConfigKey.SHOW_WARNING_ON_HIRING_CANDIDATE_FEATURE];
    try {
      isShowWarningOnHiringCandidateFeatureEnabled = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.SHOW_WARNING_ON_HIRING_CANDIDATE_FEATURE);
    } finally {
      state[RemoteConfigKey.SHOW_WARNING_ON_HIRING_CANDIDATE_FEATURE] = isShowWarningOnHiringCandidateFeatureEnabled;
    }
  }),

  setEnableInitialsFeature: action((state) => {
    let isInitialsFeatureEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_INITIALS_FEATURE];
    try {
      isInitialsFeatureEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_INITIALS_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_INITIALS_FEATURE] = isInitialsFeatureEnabled;
    }
  }),

  setEnableAvailabilityInGlobalSearch: action((state) => {
    let isEnabledAvailabilityInGlobalSearch = DEFAULT_VALUES[RemoteConfigKey.ENABLE_AVAILABILITY_FEATURE_IN_GLOBAL_SEARCH];
    try {
      isEnabledAvailabilityInGlobalSearch = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ENABLE_AVAILABILITY_FEATURE_IN_GLOBAL_SEARCH);
    } finally {
      state[RemoteConfigKey.ENABLE_AVAILABILITY_FEATURE_IN_GLOBAL_SEARCH] = isEnabledAvailabilityInGlobalSearch;
    }
  }),
  setEnableUnresponsiveCandidates: action((state) => {
    let isUnresponsiveCandidatesEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_UNRESPONSIVE_CANDIDATES];
    try {
      isUnresponsiveCandidatesEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_UNRESPONSIVE_CANDIDATES);
    } finally {
      state[RemoteConfigKey.ENABLE_UNRESPONSIVE_CANDIDATES] = isUnresponsiveCandidatesEnabled;
    }
  }),

  setMinutesBeforeInactivity: action((state) => {
    let minutes = DEFAULT_VALUES[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY];
    try {
      minutes = firebase.remoteConfig().getNumber(RemoteConfigKey.MINUTES_BEFORE_INACTIVITY);
    } finally {
      state[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY] = minutes;
    }
  }),

  setJoinVideoGracePeriodInMinute: action((state) => {
    let minutes = DEFAULT_VALUES[RemoteConfigKey.JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE];
    try {
      minutes = firebase.remoteConfig().getNumber(RemoteConfigKey.JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE);
    } finally {
      state[RemoteConfigKey.JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE] = minutes;
    }
  }),

  setEnableMeetFeature: action((state) => {
    let isMeetEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_MEET_FEATURE];
    try {
      isMeetEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_MEET_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_MEET_FEATURE] = isMeetEnabled;
    }
  }),

  setEnableCertnFeature: action((state) => {
    let isCertnFeatureEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_CERTN_FEATURE];
    try {
      isCertnFeatureEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_CERTN_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_CERTN_FEATURE] = isCertnFeatureEnabled;
    }
  }),

  setEnableNotificationFeature: action((state) => {
    let isNotificationFeatureEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_NOTIFICATION_FEATURE];
    try {
      isNotificationFeatureEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_NOTIFICATION_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_NOTIFICATION_FEATURE] = isNotificationFeatureEnabled;
    }
  }),

  setEnableBackgroundCheckNotificationFeature: action((state) => {
    let isBgNotificationFeatureEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_BACKGROUND_CHECK_NOTIFICATION_FEATURE];
    try {
      isBgNotificationFeatureEnabled = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ENABLE_BACKGROUND_CHECK_NOTIFICATION_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_BACKGROUND_CHECK_NOTIFICATION_FEATURE] = isBgNotificationFeatureEnabled;
    }
  }),

  setEnableGroupEventNotificationFeature: action((state) => {
    let isGroupNotificationFeatureEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_GROUP_EVENT_NOTIFICATION_FEATURE];
    try {
      isGroupNotificationFeatureEnabled = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ENABLE_GROUP_EVENT_NOTIFICATION_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_GROUP_EVENT_NOTIFICATION_FEATURE] = isGroupNotificationFeatureEnabled;
    }
  }),

  setEnablePositionManagementFeature: action((state) => {
    let isPositionManagementFeatureEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_POSITION_MANAGEMENT_FEATURE];
    try {
      isPositionManagementFeatureEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_POSITION_MANAGEMENT_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_POSITION_MANAGEMENT_FEATURE] = isPositionManagementFeatureEnabled;
    }
  }),

  setEnablePositionSearch: action((state) => {
    let isPositionSearchEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_POSITION_SEARCH];
    try {
      isPositionSearchEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_POSITION_SEARCH);
    } finally {
      state[RemoteConfigKey.ENABLE_POSITION_SEARCH] = isPositionSearchEnabled;
    }
  }),

  setEnableOverviewVideo: action((state) => {
    let isOverviewVideoFeatureEnabled = DEFAULT_VALUES[RemoteConfigKey.ENABLE_OVERVIEW_VIDEO];
    try {
      isOverviewVideoFeatureEnabled = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_OVERVIEW_VIDEO);
    } finally {
      state[RemoteConfigKey.ENABLE_OVERVIEW_VIDEO] = isOverviewVideoFeatureEnabled;
    }
  }),
  setEnableRecruiterManagementFeature: action((state) => {
    let isEnableRecruiterManagementFeature = DEFAULT_VALUES[RemoteConfigKey.ENABLE_RECRUITER_MANAGEMENT_FEATURE];
    try {
      isEnableRecruiterManagementFeature = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ENABLE_RECRUITER_MANAGEMENT_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_RECRUITER_MANAGEMENT_FEATURE] = isEnableRecruiterManagementFeature;
    }
  }),

  setDashboardEnableRecruiterSearch: action((state) => {
    let isDashboardEnableRecruiterSearch = DEFAULT_VALUES[RemoteConfigKey.DASHBOARD_ENABLE_RECRUITER_SEARCH];
    try {
      isDashboardEnableRecruiterSearch = firebase.remoteConfig().getBoolean(RemoteConfigKey.DASHBOARD_ENABLE_RECRUITER_SEARCH);
    } finally {
      state[RemoteConfigKey.DASHBOARD_ENABLE_RECRUITER_SEARCH] = isDashboardEnableRecruiterSearch;
    }
  }),

  setEnablePositionFilterOnAdministrationAppointmentCalendar: action((state) => {
    let isEnablePositionFilterOnAdministrationAppointmentCalendarFeature =
      DEFAULT_VALUES[RemoteConfigKey.ENABLE_POSITION_FILTER_ON_SIDEBAR_OF_ADMINISTRATION_APPOINTMENT_CALENDAR];
    try {
      isEnablePositionFilterOnAdministrationAppointmentCalendarFeature = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ENABLE_POSITION_FILTER_ON_SIDEBAR_OF_ADMINISTRATION_APPOINTMENT_CALENDAR);
    } finally {
      state[
        RemoteConfigKey.ENABLE_POSITION_FILTER_ON_SIDEBAR_OF_ADMINISTRATION_APPOINTMENT_CALENDAR
      ] = isEnablePositionFilterOnAdministrationAppointmentCalendarFeature;
    }
  }),

  setRecruiterMaxSelect: action((state) => {
    let recruiterMaxSelect = DEFAULT_VALUES[RemoteConfigKey.RECRUITER_MAX_SELECT];
    try {
      recruiterMaxSelect = firebase.remoteConfig().getNumber(RemoteConfigKey.RECRUITER_MAX_SELECT);
    } finally {
      state[RemoteConfigKey.RECRUITER_MAX_SELECT] = recruiterMaxSelect;
    }
  }),

  setEnableDismissedCandidatesList: action((state) => {
    let enableDismissedCandaiateList = DEFAULT_VALUES[RemoteConfigKey.ENABLE_DISMISSED_CANDIDATE_LIST];
    try {
      enableDismissedCandaiateList = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_DISMISSED_CANDIDATE_LIST);
    } finally {
      state[RemoteConfigKey.ENABLE_DISMISSED_CANDIDATE_LIST] = enableDismissedCandaiateList;
    }
  }),
  setEnableUpdateProfileFeature: action((state) => {
    let enableUpdateProfile = DEFAULT_VALUES[RemoteConfigKey.ENABLE_UPDATE_PROFILE_FEATURE_WEB_RECRUITER];
    try {
      enableUpdateProfile = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_UPDATE_PROFILE_FEATURE_WEB_RECRUITER);
    } finally {
      state[RemoteConfigKey.ENABLE_UPDATE_PROFILE_FEATURE_WEB_RECRUITER] = enableUpdateProfile;
    }
  }),

  setEnableRegisterFeature: action((state) => {
    let enableRegisterFeature = DEFAULT_VALUES[RemoteConfigKey.ENABLE_REGISTER_FEATURE];
    try {
      enableRegisterFeature = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_REGISTER_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_REGISTER_FEATURE] = enableRegisterFeature;
    }
  }),

  setEnableIntegrateGoogleCalendarFeature: action((state) => {
    let enableIntegrateGoogleCalendarFeature = DEFAULT_VALUES[RemoteConfigKey.ENABLE_INTEGRATE_GOOGLE_CALENDAR_FEATURE];
    try {
      enableIntegrateGoogleCalendarFeature = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ENABLE_INTEGRATE_GOOGLE_CALENDAR_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_INTEGRATE_GOOGLE_CALENDAR_FEATURE] = enableIntegrateGoogleCalendarFeature;
    }
  }),

  setEnableIntegrateMicrosoftCalendarFeature: action((state) => {
    let enableIntegrateMicrosoftCalendarFeature = DEFAULT_VALUES[RemoteConfigKey.ENABLE_INTEGRATE_MICROSOFT_CALENDAR_FEATURE];
    try {
      enableIntegrateMicrosoftCalendarFeature = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ENABLE_INTEGRATE_MICROSOFT_CALENDAR_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_INTEGRATE_MICROSOFT_CALENDAR_FEATURE] = enableIntegrateMicrosoftCalendarFeature;
    }
  }),

  setEnableTeamManagementFeature: action((state) => {
    let enableTeamManagementFeature = DEFAULT_VALUES[RemoteConfigKey.ENABLE_TEAM_MANAGEMENT_FEATURE];
    try {
      enableTeamManagementFeature = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_TEAM_MANAGEMENT_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_TEAM_MANAGEMENT_FEATURE] = enableTeamManagementFeature;
    }
  }),
  setEnableUndismissedFeature: action((state) => {
    let enableUnDismissedFeature = DEFAULT_VALUES[RemoteConfigKey.ENABLE_UN_DISMISSED_FEATURE];
    try {
      enableUnDismissedFeature = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_UN_DISMISSED_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_UN_DISMISSED_FEATURE] = enableUnDismissedFeature;
    }
  }),

  setEnableCopyPosition: action((state) => {
    let enableCopyPosition = DEFAULT_VALUES[RemoteConfigKey.ENABLE_COPY_POSITION];
    try {
      enableCopyPosition = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_COPY_POSITION);
    } finally {
      state[RemoteConfigKey.ENABLE_COPY_POSITION] = enableCopyPosition;
    }
  }),

  setEnableSwitchNewUIForCreatePosition: action((state) => {
    let enableNewUiForCreatePosition = DEFAULT_VALUES[RemoteConfigKey.ENABLE_NEW_UI_FOR_CREATE_POSITION];
    try {
      enableNewUiForCreatePosition = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_NEW_UI_FOR_CREATE_POSITION);
    } finally {
      state[RemoteConfigKey.ENABLE_NEW_UI_FOR_CREATE_POSITION] = enableNewUiForCreatePosition;
    }
  }),

  setEnableDismissalReason: action((state) => {
    let enableDismissalReason = DEFAULT_VALUES[RemoteConfigKey.ENABLE_DISMISSAL_REASON];
    try {
      enableDismissalReason = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_DISMISSAL_REASON);
    } finally {
      state[RemoteConfigKey.ENABLE_DISMISSAL_REASON] = enableDismissalReason;
    }
  }),

  setEnableSupportDocumentation: action((state) => {
    let enableSupportDocumentation = DEFAULT_VALUES[RemoteConfigKey.ENABLE_SUPPORT_DOCUMENTATION];
    try {
      enableSupportDocumentation = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_SUPPORT_DOCUMENTATION);
    } finally {
      state[RemoteConfigKey.ENABLE_SUPPORT_DOCUMENTATION] = enableSupportDocumentation;
    }
  }),

  setEnableEnableHireFormNotificationFeature: action((state) => {
    let enableHireFormNotificationFeature = DEFAULT_VALUES[RemoteConfigKey.ENABLE_HIRE_FORM_NOTIFICATION_FEATURE];
    try {
      enableHireFormNotificationFeature = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ENABLE_HIRE_FORM_NOTIFICATION_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_HIRE_FORM_NOTIFICATION_FEATURE] = enableHireFormNotificationFeature;
    }
  }),
  setAllowOnlyBusinessAndPositionWithRequisition: action((state) => {
    let allowOnlyBusinessAndPositionwithRequisition =
      DEFAULT_VALUES[RemoteConfigKey.ALLOW_ONLY_BUSINESS_AND_POSITION_WITH_REQUISITION];
    try {
      allowOnlyBusinessAndPositionwithRequisition = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ALLOW_ONLY_BUSINESS_AND_POSITION_WITH_REQUISITION);
    } finally {
      state[RemoteConfigKey.ALLOW_ONLY_BUSINESS_AND_POSITION_WITH_REQUISITION] = allowOnlyBusinessAndPositionwithRequisition;
    }
  }),

  setEnableWarnModalWhileUnFollowPosition: action((state) => {
    let enableWarnModalWhileUnFollowPosition = DEFAULT_VALUES[RemoteConfigKey.ENABLE_WARN_MODAL_WHILE_UNFOLLOW_POSITION];
    try {
      enableWarnModalWhileUnFollowPosition = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ENABLE_WARN_MODAL_WHILE_UNFOLLOW_POSITION);
    } finally {
      state[RemoteConfigKey.ENABLE_WARN_MODAL_WHILE_UNFOLLOW_POSITION] = enableWarnModalWhileUnFollowPosition;
    }
  }),

  setEnableMultipleChoiceFeature: action((state) => {
    let enableMultipleChoiceFeature = DEFAULT_VALUES[RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE];
    try {
      enableMultipleChoiceFeature = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE] = enableMultipleChoiceFeature;
    }
  }),

  setEnableGroupInBusinessLocation: action((state) => {
    let enableGroupInBusinessLocation = DEFAULT_VALUES[RemoteConfigKey.ENABLE_GROUP_IN_BUSINESS_LOCATION];
    try {
      enableGroupInBusinessLocation = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_GROUP_IN_BUSINESS_LOCATION);
    } finally {
      state[RemoteConfigKey.ENABLE_GROUP_IN_BUSINESS_LOCATION] = enableGroupInBusinessLocation;
    }
  }),

  setSuperUsersDeleteAvailability: action((state) => {
    let allowSuperUsersToDeleteAvailability = DEFAULT_VALUES[RemoteConfigKey.ALLOW_SUPER_USERS_TO_DELETE_AVAILABILITY];
    try {
      allowSuperUsersToDeleteAvailability = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ALLOW_SUPER_USERS_TO_DELETE_AVAILABILITY);
    } finally {
      state[RemoteConfigKey.ALLOW_SUPER_USERS_TO_DELETE_AVAILABILITY] = allowSuperUsersToDeleteAvailability;
    }
  }),

  setEnableVideoGallery: action((state) => {
    let enableVideoGallery = DEFAULT_VALUES[RemoteConfigKey.ENABLE_VIDEO_GALLERY];
    try {
      enableVideoGallery = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_VIDEO_GALLERY);
    } finally {
      state[RemoteConfigKey.ENABLE_VIDEO_GALLERY] = enableVideoGallery;
    }
  }),
  setEnableCollaborators: action((state) => {
    let enableCollaborators = DEFAULT_VALUES[RemoteConfigKey.ENABLE_COLLABORATOR_FEATURE];
    try {
      enableCollaborators = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_COLLABORATOR_FEATURE);
    } finally {
      state[RemoteConfigKey.ENABLE_COLLABORATOR_FEATURE] = enableCollaborators;
    }
  }),

  setEnableToggleOfBackgroundCheckOnPositionLevel: action((state) => {
    let enableToggleOfBackgroundCheckOnPositionLevel =
      DEFAULT_VALUES[RemoteConfigKey.ENABLE_TOGGLE_OF_BACKGROUND_CHECK_ON_POSITION_LEVEL];
    try {
      enableToggleOfBackgroundCheckOnPositionLevel = firebase
        .remoteConfig()
        .getBoolean(RemoteConfigKey.ENABLE_TOGGLE_OF_BACKGROUND_CHECK_ON_POSITION_LEVEL);
    } finally {
      state[RemoteConfigKey.ENABLE_TOGGLE_OF_BACKGROUND_CHECK_ON_POSITION_LEVEL] = enableToggleOfBackgroundCheckOnPositionLevel;
    }
  }),

  setEnablePriorityCandidateFilter: action((state) => {
    let enablePriorityCandidateFilter = DEFAULT_VALUES[RemoteConfigKey.ENABLE_PRIORITY_CANDIDATE_FILTER];
    try {
      enablePriorityCandidateFilter = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_PRIORITY_CANDIDATE_FILTER);
    } finally {
      state[RemoteConfigKey.ENABLE_PRIORITY_CANDIDATE_FILTER] = enablePriorityCandidateFilter;
    }
  }),

  setEnableTopCandidatesOfPosition: action((state) => {
    let enableTopCandidatesOfPosition = DEFAULT_VALUES[RemoteConfigKey.ENABLE_TOP_CANDIDATES_OF_POSITION];
    try {
      enableTopCandidatesOfPosition = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_TOP_CANDIDATES_OF_POSITION);
    } finally {
      state[RemoteConfigKey.ENABLE_TOP_CANDIDATES_OF_POSITION] = enableTopCandidatesOfPosition;
    }
  }),

  setEnableNewAnalyticalView: action((state) => {
    let enableAnalyticalView = DEFAULT_VALUES[RemoteConfigKey.ENABLE_NEW_ANALYTIC_VIEW];
    try {
      enableAnalyticalView = firebase.remoteConfig().getBoolean(RemoteConfigKey.ENABLE_NEW_ANALYTIC_VIEW);
    } finally {
      state[RemoteConfigKey.ENABLE_NEW_ANALYTIC_VIEW] = enableAnalyticalView;
    }
  }),

  initialize: thunk(async (actions) => {
    firebase.remoteConfig().defaultConfig = DEFAULT_VALUES;
    firebase.remoteConfig().settings = SETTINGS;

    try {
      await firebase.remoteConfig().fetchAndActivate();
    } catch (error) {
      Bugsnag.notify(error);
    }

    actions.setMinutesBeforeInactivity();
    actions.setEnableMeetFeature();
    actions.setJoinVideoGracePeriodInMinute();
    actions.setEnableCertnFeature();
    actions.setEnableNotificationFeature();
    actions.setEnableBackgroundCheckNotificationFeature();
    actions.setEnableGroupEventNotificationFeature();
    actions.setEnablePositionManagementFeature();
    actions.setEnableUnresponsiveCandidates();
    actions.setEnableTeamAvailability();
    actions.setEnableSearchAndSortFeature();
    actions.setEnableInitialsFeature();
    actions.setShowWarningOnHiringCandidateFeature();
    actions.setShowFavoriteStatus();
    actions.setShowArchiveList();
    actions.setEnableOverviewVideo();
    actions.setEnableRecruiterManagementFeature();
    actions.setEnableNoteDeleteFeature();
    actions.setEnablePositionFilterOnAdministrationAppointmentCalendar();
    actions.setEnablePositionSearch();
    actions.setDashboardEnableRecruiterSearch();
    actions.setRecruiterMaxSelect();
    actions.setEnableDismissedCandidatesList();
    actions.setEnableRegisterFeature();
    actions.setEnableUpdateProfileFeature();
    actions.setEnableAvailabilityInGlobalSearch();
    actions.setEnableIntegrateGoogleCalendarFeature();
    actions.setEnableIntegrateMicrosoftCalendarFeature();
    actions.setEnableTeamManagementFeature();
    actions.setEnableUndismissedFeature();
    actions.setEnableCopyPosition();
    actions.setEnableSwitchNewUIForCreatePosition();
    actions.setEnableDismissalReason();
    actions.setEnableSupportDocumentation();
    actions.setEnableEnableHireFormNotificationFeature();
    actions.setAllowOnlyBusinessAndPositionWithRequisition();
    actions.setEnableWarnModalWhileUnFollowPosition();
    actions.setEnableMultipleChoiceFeature();
    actions.setEnableGroupInBusinessLocation();
    actions.setSuperUsersDeleteAvailability();
    actions.setEnableVideoGallery();
    actions.setEnableCollaborators();
    actions.setEnableToggleOfBackgroundCheckOnPositionLevel();
    actions.setEnablePriorityCandidateFilter();
    actions.setEnableTopCandidatesOfPosition();
    actions.setEnableNewAnalyticalView();
  }),
};
