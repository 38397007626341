/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import {
  Heading,
  Avatar,
  Text,
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  useToast,
} from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import _omit from 'lodash/omit';
import Joi from 'joi';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { hintBoxStyle, meetViewStyle } from './MeetVide.styles';
import { OnBoardingInput, ParticipantData, UserInfoInput, ValidMeetTokenResponse } from './MeetTokenResponse';
import { phoneFormat } from '../../utils/PhoneUtils';
import colors from '../../styles/colors';

import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
  updateObject,
} from '../../utils/FormErrorUtils';

export type MeetParticipantVerificationProps = {
  isLoading: boolean;
  groupEventInfo: ValidMeetTokenResponse | null;
  joinGroupEventRoom: (participantInfo: ParticipantData) => void;
};

export const MeetParticipantVerification = ({
  isLoading,
  groupEventInfo,
  joinGroupEventRoom,
}: MeetParticipantVerificationProps): JSX.Element => {
  const currentUser = useStoreState((state) => state.app.user);
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [participantInfoForObBoarding, setParticipantInfoForObBoarding] = useState<OnBoardingInput>({
    groupEventId: '',
    isCandidate: false,
    email: /* istanbul ignore next */ currentUser ? currentUser.email : '',
    newUserInfo: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
    } as UserInfoInput,
  } as OnBoardingInput);

  const toast = useToast();

  const { meetOnBoarding } = useStoreActions((actions) => actions.meet);

  let isNewUser = true;

  if (!_isNil(groupEventInfo)) {
    isNewUser = groupEventInfo.isNewUser;
  }

  const nestedSchema = Joi.object().keys({
    firstName: isNewUser
      ? Joi.string().max(30).required().messages({
          'string.empty': `Full Name is required`,
        })
      : Joi.string().allow('').allow(null).optional(),
    lastName: isNewUser
      ? Joi.string().max(30).required().messages({
          'string.empty': `Last Name is required`,
        })
      : Joi.string().allow('').allow(null).optional(),
    phoneNumber: Joi.string().allow('').allow(null).optional(),
  });

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      groupEventId: Joi.string().allow('').allow(null).optional(),
      isCandidate: Joi.boolean().optional(),
      email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } }),
      newUserInfo: nestedSchema,
    });

  const formValidation = (formData: OnBoardingInput) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = schema.validate(formData);

    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const handleChange = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    updateObject(participantInfoForObBoarding, fieldName, event.target.value);
    if (fieldName === 'newUserInfo.phoneNumber' && participantInfoForObBoarding.newUserInfo) {
      participantInfoForObBoarding.newUserInfo.phoneNumber = phoneFormat(event.target.value);
    }
    setFormErrors(clearErrorMessage(formErrors, fieldName));
    setParticipantInfoForObBoarding({ ...participantInfoForObBoarding });
  };

  const submitParticipantVerification = async () => {
    const { groupEvent, isCandidate } = groupEventInfo as ValidMeetTokenResponse;

    if (formValidation(participantInfoForObBoarding)) {
      participantInfoForObBoarding.groupEventId = groupEvent.id;
      participantInfoForObBoarding.isCandidate = isCandidate;

      try {
        setIsLoadingBtn(true);
        const onBoardingResponse = await meetOnBoarding(
          isNewUser ? participantInfoForObBoarding : _omit(participantInfoForObBoarding, ['newUserInfo']),
        );
        /* istanbul ignore next */
        if (!_isNil(onBoardingResponse) && onBoardingResponse.verified) {
          joinGroupEventRoom(onBoardingResponse as ParticipantData);
        } else {
          toast({
            title: 'Not Verified',
            status: 'error',
            duration: 1000,
            isClosable: true,
          });
        }
      } finally {
        setIsLoadingBtn(false);
      }
    }
  };

  return (
    <Box css={meetViewStyle}>
      <Box boxShadow="lg" mb={2} style={{ display: /* istanbul ignore next */ currentUser?.email ? '' : 'none' }}>
        <Box className="meet-card-header">
          <Heading as="h3" size="md">
            You are already logged in as
          </Heading>
        </Box>
        <Box className="meet-card-body" spacing={4}>
          <Flex>
            <Avatar
              style={{ backgroundColor: colors.blue['500'], color: colors.white }}
              size="sm"
              name={`${currentUser?.firstName} ${currentUser?.lastName}`}
              mr={3}
            />
            <Box>
              <Text>{`${currentUser?.firstName} ${currentUser?.lastName}`}</Text>
              <Text mb={5}>{`${currentUser?.email}`}</Text>
              <Button
                mb={2}
                size="sm"
                bg="blue.500"
                color="white"
                _hover={{
                  bg: 'blue.600',
                }}
                data-testid="joinBtn"
                isLoading={isLoadingBtn || isLoading}
                disabled={!(groupEventInfo && groupEventInfo.verified) || isLoadingBtn || isLoading}
                onClick={/* istanbul ignore next */ () => submitParticipantVerification()}
                style={{ display: /* istanbul ignore next */ currentUser?.email ? '' : 'none' }}
              >
                Join
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box boxShadow="lg" mb={2} style={{ display: /* istanbul ignore next */ currentUser?.email ? 'none' : '' }}>
        <Box className="meet-card-header">
          <Heading as="h3" size="md">
            Ready to join?
          </Heading>
          <Text>Please enter the information below to joining the event</Text>
        </Box>
        <Box className="meet-card-body" spacing={4}>
          {groupEventInfo && groupEventInfo.isNewUser && (
            <Flex mb={4}>
              <FormControl id="firstName" pr={3} isInvalid={hasErrorMessage(formErrors, 'newUserInfo.firstName')} isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  value={participantInfoForObBoarding.newUserInfo?.firstName}
                  data-testid="FirstNameInput"
                  onChange={(event) => handleChange('newUserInfo.firstName', event)}
                />
                <FormErrorMessage>{getErrorMessage(formErrors, 'newUserInfo.firstName')}</FormErrorMessage>
              </FormControl>
              <FormControl id="lastName" isInvalid={hasErrorMessage(formErrors, 'newUserInfo.lastName')} isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  value={participantInfoForObBoarding.newUserInfo?.lastName}
                  data-testid="LastNameInput"
                  onChange={(event) => handleChange('newUserInfo.lastName', event)}
                />
                <FormErrorMessage>{getErrorMessage(formErrors, 'newUserInfo.lastName')}</FormErrorMessage>
              </FormControl>
            </Flex>
          )}
          <Flex mb={4}>
            {groupEventInfo && groupEventInfo.isNewUser && (
              <FormControl pr={3} id="phoneNumber">
                <FormLabel>Phone Number</FormLabel>
                <Input
                  size="sm"
                  type="tel"
                  value={participantInfoForObBoarding.newUserInfo?.phoneNumber}
                  data-testid="PhoneNumberInput"
                  onChange={(event) => handleChange('newUserInfo.phoneNumber', event)}
                  maxLength={14}
                />
              </FormControl>
            )}
            <FormControl id="email" isInvalid={hasErrorMessage(formErrors, 'email')} isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                size="sm"
                type="email"
                value={participantInfoForObBoarding.email}
                data-testid="EmailInput"
                onChange={(event) => handleChange('email', event)}
                required
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'email')}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Button
            mb={2}
            size="sm"
            bg="blue.500"
            color="white"
            _hover={{
              bg: 'blue.600',
            }}
            data-testid="askToJoinBtn"
            isLoading={isLoadingBtn || isLoading}
            disabled={!(groupEventInfo && groupEventInfo.verified) || isLoadingBtn || isLoading}
            onClick={() => submitParticipantVerification()}
          >
            Ask to join
          </Button>
        </Box>
      </Box>
      <Box css={hintBoxStyle} p={3}>
        <Text fontSize="sm">
          In preparation for the meeting, please make sure you are using the headphone or the device that you will use for the
          event.
        </Text>
      </Box>
    </Box>
  );
};
