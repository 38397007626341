import React from 'react';
import { Box, Image, Tooltip } from '@chakra-ui/core';
import moment from 'moment';
import { FiUser } from 'react-icons/fi';

import colors from '../../../styles/colors';
import { AppointmentType } from '../../../firebase/firestore/documents/appointmentAvaiability';
import {
  Candidate,
  CandidateStatus,
  ContactRequestStatus,
  Appointment,
  CandidateAutomation,
} from '../../../firebase/firestore/documents/candidate';
import { AppointmentStatus } from '../../../firebase/firestore/documents/appointmentStatus';

// images import
import appointment_accepted_in_the_past from '../../../assets/img/candidateStatus/interview_notifications/appointment_accepted_in_the_past.svg';
import appointment_accepted from '../../../assets/img/candidateStatus/interview_notifications/appointment_accepted.svg';
import appointment_cancelled from '../../../assets/img/candidateStatus/interview_notifications/appointment_cancelled.svg';
import appointment_declined from '../../../assets/img/candidateStatus/interview_notifications/appointment_declined.svg';
import appointment_requested, {
  ReactComponent as AppointmentReqIcon,
} from '../../../assets/img/candidateStatus/interview_notifications/appointment_requested.svg';

import contact_accepted from '../../../assets/img/candidateStatus/interview_notifications/contact_accepted.svg';
import contact_declined from '../../../assets/img/candidateStatus/interview_notifications/contact_declined.svg';
import contact_requested, {
  ReactComponent as ContactReqIcon,
} from '../../../assets/img/candidateStatus/interview_notifications/contact_requested.svg';

import going_dismissed from '../../../assets/img/candidateStatus/interview_notifications/going_dismissed.svg';
import status_hired from '../../../assets/img/candidateStatus/interview_notifications/status_hired.svg';
import interest_revoked from '../../../assets/img/candidateStatus/interview_notifications/interest_revoked.svg';
import not_looking_for_work from '../../../assets/img/candidateStatus/interview_notifications/not_looking_for_work.svg';
import position_closed from '../../../assets/img/candidateStatus/interview_notifications/position_closed.svg';
import rescheduling_needed from '../../../assets/img/candidateStatus/interview_notifications/rescheduling_needed.svg';
import { automatedSvgCSS } from '../Header.styles';

type AUDIENCEPROP = 'Seeker' | 'Employer';
const AUDIENCE_EMPLOYER: AUDIENCEPROP = 'Employer';

const styles = {
  container: {
    width: 25,
    alignItems: 'center',
    textAlign: 'center' as const,
    display: 'flex',
    flexFlow: 'column',
  },
  textStyle: {
    marginTop: 3,
  },
  hiredImageIcon: {
    width: 30,
    height: 30,
    margin: 3,
    backgroundColor: colors.brandColor,
  },
  imageIcon: {
    width: 30,
    height: 30,
    margin: 3,
  },
  videoButton: {
    backgroundColor: colors.brandColor,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
  },
  videoButtonDisabled: {
    backgroundColor: colors.lightBlue[900],
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    padding: 10,
  },
};

const isCandidateGoingToBeDismissed = (candidate: Candidate) =>
  candidate.dismissed && !candidate.system?.shouldBeDismissed && !candidate.monetized;

const getCandidateAppointmentType = (appointment?: Appointment) => {
  /* istanbul ignore next */
  if (!appointment) {
    return false;
  }
  const { type, inPerson } = appointment;

  if (type) {
    return type;
  }
  // istanbul ignore next
  if (inPerson) {
    return AppointmentType.inPerson;
  }
  /* istanbul ignore next */
  return AppointmentType.phone;
};

const PAST = '-in-the-past';

const CANDIDATE_STATUS_HIRED = 'hired';

const CANDIDATE_IS_GOING_TO_BE_DISMISSED = 'candidateIsGoingToBeDismissed';

const CANDIDATE_STATUS_POSITION_CLOSED = 'positionClosed';
const CANDIDATE_STATUS_SEEKER_NOT_LOOKING_FOR_WORK = 'notLookingForWork';
const CANDIDATE_STATUS_APPOINTMENT_ACCEPTED = `appointment-${AppointmentStatus.accepted}`;
const CANDIDATE_STATUS_APPOINTMENT_RESCHEDULING_NEEDED = `appointment-${AppointmentStatus.acceptedWithMessage}`;
const CANDIDATE_STATUS_APPOINTMENT_REQUESTED = `appointment-${AppointmentStatus.requested}`;
const CANDIDATE_STATUS_APPOINTMENT_DECLINED = `appointment-${AppointmentStatus.declined}`;
const CANDIDATE_STATUS_APPOINTMENT_CANCELLED = `appointment-${AppointmentStatus.cancelled}`;
const CANDIDATE_STATUS_CONTACT_REQUESTED = `contact-${ContactRequestStatus.requested}`;
const CANDIDATE_STATUS_CONTACT_ACCEPTED = `contact-${ContactRequestStatus.accepted}`;
const CANDIDATE_STATUS_CONTACT_DECLINED = `contact-${ContactRequestStatus.declined}`;
const CANDIDATE_STATUS_INTEREST_REVOKED = `contact-${CandidateStatus.InterestRevoked}`;

const CANDIDATE_STATUS_APPOINTMENT_ACCEPTED_IN_THE_PAST = `${CANDIDATE_STATUS_APPOINTMENT_ACCEPTED}${PAST}`;
const CANDIDATE_STATUS_APPOINTMENT_RESCHEDULING_NEEDED_IN_THE_PAST = `${CANDIDATE_STATUS_APPOINTMENT_RESCHEDULING_NEEDED}${PAST}`;
const CANDIDATE_STATUS_APPOINTMENT_REQUESTED_IN_THE_PAST = `${CANDIDATE_STATUS_APPOINTMENT_REQUESTED}${PAST}`;
const CANDIDATE_STATUS_APPOINTMENT_DECLINED_IN_THE_PAST = `${CANDIDATE_STATUS_APPOINTMENT_DECLINED}${PAST}`;
const CANDIDATE_STATUS_APPOINTMENT_CANCELLED_IN_THE_PAST = `${CANDIDATE_STATUS_APPOINTMENT_CANCELLED}${PAST}`;

const isPast = (appointment: Appointment | undefined) => moment(appointment?.toDate).isBefore(moment());

export function candidateStatus(candidate: Candidate, audience: string, displayClosedStatus: boolean): string {
  /* istanbul ignore next */
  const { hired = false, positionOpen = true, appointment, contactStatus, seekerLookingForWork = true } = candidate;
  const isGoingToBeDismissed = isCandidateGoingToBeDismissed(candidate);
  const interestRevoked = contactStatus?.status === ContactRequestStatus.revoked;
  const contactRequestDeclined = contactStatus?.status === ContactRequestStatus.declined;

  switch (true) {
    case hired && audience === AUDIENCE_EMPLOYER:
      return CANDIDATE_STATUS_HIRED;
    case interestRevoked:
      return CANDIDATE_STATUS_INTEREST_REVOKED;
    case contactRequestDeclined:
      return CANDIDATE_STATUS_CONTACT_DECLINED;
    case isGoingToBeDismissed && audience === AUDIENCE_EMPLOYER:
      return CANDIDATE_IS_GOING_TO_BE_DISMISSED;
    case positionOpen === false && displayClosedStatus:
      return CANDIDATE_STATUS_POSITION_CLOSED;
    case seekerLookingForWork === false && audience === AUDIENCE_EMPLOYER:
      return CANDIDATE_STATUS_SEEKER_NOT_LOOKING_FOR_WORK;
    case !!appointment:
      return `appointment-${appointment?.status}${isPast(appointment) ? PAST : ''}`;
    case !!contactStatus:
      return `contact-${contactStatus?.status}`;
    default:
      return '';
  }
}

function candidateStatusMessage(
  status: string,
  message: MessageType,
  { appointment }: Candidate,
  isAbleToJoinVideoInterview: boolean,
) {
  const getAppointmentAcceptedStatusMessage = () => {
    /* istanbul ignore next */
    if (!appointment) {
      return { value: '', color: colors.brandColor };
    }
    if (getCandidateAppointmentType(appointment) === AppointmentType.video && isAbleToJoinVideoInterview) {
      return { value: message.joinVideo, color: colors.brandColor };
    }
    if (getCandidateAppointmentType(appointment) === AppointmentType.video && !isAbleToJoinVideoInterview) {
      return { value: message.videoInterviewWillBeAvailable, color: colors.brandColor };
    }
    return { value: moment(appointment.fromDate).format(message.appointmentDateFormat), color: colors.lightBlue };
  };

  switch (status) {
    case CANDIDATE_STATUS_INTEREST_REVOKED:
      return { value: message.contactRevoked, color: colors.lightBlue };
    case CANDIDATE_STATUS_HIRED:
      return { value: message.seekerIsHired, color: colors.lightBlue };
    case CANDIDATE_IS_GOING_TO_BE_DISMISSED:
      return { value: message.isGoingToBeDismissed, color: colors.lightBlue };
    case CANDIDATE_STATUS_POSITION_CLOSED:
      return { value: message.positionClosed, color: colors.lightBlue };
    case CANDIDATE_STATUS_SEEKER_NOT_LOOKING_FOR_WORK:
      return { value: message.seekerIsNotLookingForWork, color: colors.lightBlue };
    case CANDIDATE_STATUS_APPOINTMENT_ACCEPTED_IN_THE_PAST:
      return { value: message.appointmentInThePast, color: colors.lightBlue };
    case CANDIDATE_STATUS_APPOINTMENT_RESCHEDULING_NEEDED_IN_THE_PAST:
    case CANDIDATE_STATUS_APPOINTMENT_REQUESTED_IN_THE_PAST:
    case CANDIDATE_STATUS_APPOINTMENT_DECLINED_IN_THE_PAST:
    case CANDIDATE_STATUS_APPOINTMENT_CANCELLED_IN_THE_PAST:
      return { value: message.appointmentInThePast, color: colors.lightBlue };
    case CANDIDATE_STATUS_APPOINTMENT_ACCEPTED:
      return getAppointmentAcceptedStatusMessage();
    case CANDIDATE_STATUS_APPOINTMENT_RESCHEDULING_NEEDED:
      return { value: message.appointmentReschedulingNeeded, color: colors.lightBlue };
    case CANDIDATE_STATUS_APPOINTMENT_REQUESTED:
      return { value: message.appointmentRequested, color: colors.lightBlue };
    case CANDIDATE_STATUS_APPOINTMENT_DECLINED:
      return { value: message.appointmentDeclined, color: colors.lightBlue };
    case CANDIDATE_STATUS_APPOINTMENT_CANCELLED:
      return { value: message.appointmentCancelled, color: colors.lightBlue };
    case CANDIDATE_STATUS_CONTACT_REQUESTED:
      return { value: message.contactRequested, color: colors.lightBlue };
    case CANDIDATE_STATUS_CONTACT_ACCEPTED:
      return { value: message.contactAccepted, color: colors.lightBlue };
    case CANDIDATE_STATUS_CONTACT_DECLINED:
      return { value: message.contactDeclined, color: colors.lightBlue };
    default:
      return { value: '', color: colors.brandColor };
  }
}

const isAutomated = (status: string, automation: CandidateAutomation | undefined) => {
  switch (status) {
    case CANDIDATE_STATUS_APPOINTMENT_REQUESTED:
    case CANDIDATE_STATUS_APPOINTMENT_REQUESTED_IN_THE_PAST:
      return automation?.automatedAppointmentRequest;
    case CANDIDATE_STATUS_CONTACT_REQUESTED:
      return automation?.automatedContactRequest;
    default:
  }
  return false;
};

function renderIcon(status: string, { automation }: Candidate) {
  const automated = isAutomated(status, automation);

  switch (status) {
    case CANDIDATE_STATUS_HIRED:
      return <Image src={status_hired} />;
    case CANDIDATE_IS_GOING_TO_BE_DISMISSED:
      return <Image src={going_dismissed} />;
    case CANDIDATE_STATUS_POSITION_CLOSED:
      return <Image src={position_closed} />;
    case CANDIDATE_STATUS_SEEKER_NOT_LOOKING_FOR_WORK:
      return <Image src={not_looking_for_work} />;
    case CANDIDATE_STATUS_APPOINTMENT_ACCEPTED:
      return <Image src={appointment_accepted} />;
    case CANDIDATE_STATUS_APPOINTMENT_ACCEPTED_IN_THE_PAST:
      return <Image src={appointment_accepted_in_the_past} />;
    case CANDIDATE_STATUS_APPOINTMENT_RESCHEDULING_NEEDED:
      return <Image src={rescheduling_needed} />;
    case CANDIDATE_STATUS_APPOINTMENT_RESCHEDULING_NEEDED_IN_THE_PAST:
      return <Image src={rescheduling_needed} />;
    case CANDIDATE_STATUS_APPOINTMENT_REQUESTED:
      return <Image src={appointment_requested} />;
    case CANDIDATE_STATUS_APPOINTMENT_REQUESTED_IN_THE_PAST:
      if (automated) {
        return (
          <Box css={automatedSvgCSS}>
            <AppointmentReqIcon className="automated-icon" />
          </Box>
        );
      }
      return <Image src={appointment_requested} />;

    case CANDIDATE_STATUS_APPOINTMENT_DECLINED:
      return <Image src={appointment_declined} />;
    case CANDIDATE_STATUS_APPOINTMENT_CANCELLED:
      return <Image src={appointment_cancelled} />;
    case CANDIDATE_STATUS_APPOINTMENT_DECLINED_IN_THE_PAST:
      return <Image src={appointment_declined} />;
    case CANDIDATE_STATUS_APPOINTMENT_CANCELLED_IN_THE_PAST:
      return <Image src={appointment_cancelled} />;
    case CANDIDATE_STATUS_CONTACT_REQUESTED:
      return automated ? (
        <Box css={automatedSvgCSS}>
          <ContactReqIcon fill={colors.brandColor} className="automated-icon" />
        </Box>
      ) : (
        <Image src={contact_requested} />
      );
    case CANDIDATE_STATUS_CONTACT_ACCEPTED:
      return <Image src={contact_accepted} />;
    case CANDIDATE_STATUS_CONTACT_DECLINED:
      return <Image src={contact_declined} />;
    case CANDIDATE_STATUS_INTEREST_REVOKED:
      return <Image src={interest_revoked} />;
    default:
      return <FiUser />;
  }
}

interface ICandidateStatusIcon {
  candidate: Candidate;
  audience: AUDIENCEPROP;
  messages: MessageType;
  displayClosedStatus?: boolean;
  isAbleToJoinVideoInterview?: boolean;
}

export type MessageType = {
  seekerIsHired: string;
  positionClosed: string;
  seekerIsNotLookingForWork: string;
  appointmentInThePast: string;
  appointmentAccepted: string;
  appointmentReschedulingNeeded: string;
  appointmentRequested: string;
  appointmentDeclined: string;
  appointmentCancelled: string;
  contactRevoked: string;
  contactRequested: string;
  contactAccepted: string;
  contactDeclined: string;
  appointmentDateFormat: string;
  isGoingToBeDismissed: string;
  videoInterviewWillBeAvailable: string;
  joinVideo: string;
};

export const CandidateStatusIcon = (props: ICandidateStatusIcon): JSX.Element => {
  /* istanbul ignore next */
  const { candidate, audience, messages, displayClosedStatus = false, isAbleToJoinVideoInterview = false } = props;
  const status = candidateStatus(candidate, audience, displayClosedStatus);
  const message = candidateStatusMessage(status, messages, candidate, isAbleToJoinVideoInterview);

  return (
    <Box style={styles.container} mt={1}>
      <Tooltip label={message.value} fontSize="sm">
        {renderIcon(status, candidate)}
      </Tooltip>
    </Box>
  );
};

CandidateStatusIcon.defaultProps = {
  displayClosedStatus: true,
  isAbleToJoinVideoInterview: false,
};
