import _isNil from 'lodash/isNil';
import { CustomerSupportDetail } from './supportData';
import { useQueryOnce } from '../../firebase/firestore/hooks';
import { Collections } from '../../firebase/firestore/collections';
import { Query } from '../../firebase/firestore/query/query';

const useCustomerSupportData = (): CustomerSupportDetail[] => {
  const customerSupportList: CustomerSupportDetail[] | undefined = useQueryOnce<CustomerSupportDetail>(
    Collections.CUSTOMER_SUPPORT_DATA,
    [Query.field<CustomerSupportDetail>('active').equals(true)],
  );

  if (_isNil(customerSupportList)) return [];

  return customerSupportList;
};

export default useCustomerSupportData;
