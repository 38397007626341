import React from 'react';
import { Box } from '@chakra-ui/core';
import GaugeComponent from 'react-gauge-component';
import { SubArc } from 'react-gauge-component/dist/lib/GaugeComponent/types/Arc';
import { gaugeNeedleCss } from './GaugeNeedle.style';

interface GaugeChartProps {
  value: number;
  subArcs: SubArc[] | undefined;
  minValue: number;
  maxValue: number;
  isDarkMode: boolean;
}

const GaugeChart: React.FC<GaugeChartProps> = ({ minValue, maxValue, value, subArcs, isDarkMode }) => {
  return (
    <Box css={gaugeNeedleCss}>
      <Box className={isDarkMode ? 'dark' : 'light'}>
        <GaugeComponent
          maxValue={maxValue}
          minValue={minValue}
          arc={{
            subArcs,
          }}
          labels={{
            valueLabel: {
              formatTextValue: (givenValue) => givenValue as string,
            },
            tickLabels: {
              type: 'outer',
              defaultTickValueConfig: { formatTextValue: (givenValue) => givenValue as string },
            },
          }}
          value={value}
        />
      </Box>
    </Box>
  );
};

export default GaugeChart;
