import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import React, { useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';
import colors from '../../../../styles/colors';
import { Question } from '../PositionType';

export type SortableSpecificQuestionItemType = {
  que: Question;
  itemIndex: number;
  editQuestion: (index: number, item: Question) => void;
  deleteQuestion: (index: number) => void;
};

export const SortableSpecificQuestionItem = SortableElement(
  ({ que, itemIndex, editQuestion, deleteQuestion }: SortableSpecificQuestionItemType) => {
    const { t } = useTranslation();
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

    return (
      <li style={{ zIndex: 9999, cursor: 'move', marginTop: '15px', listStyle: 'none' }}>
        <Flex justifyContent="space-between">
          <Text>
            <Text as="span" mr={1} fontWeight={que.knockout ? 'bold' : 'normal'}>{`${que.text}`}</Text>
            <Text as="span" fontSize="xs" fontStyle="italic">
              {`(${que.preferredAnswer ? t('common:questions.answerPositive') : t('common:questions.answerNegative')})`}
            </Text>
          </Text>
          <Flex>
            <IconButton
              backgroundColor="#ECEFF1"
              aria-label="Edit Question"
              icon={<RiEdit2Fill style={{ color: colors.blue[500] }} />}
              data-testid={`EditQuestionBtnSpecific-${itemIndex}`}
              onClick={() => {
                editQuestion(itemIndex, que);
              }}
            />
            <Box>
              <Popover
                returnFocusOnClose={false}
                isOpen={isOpen && deleteIndex === itemIndex}
                onClose={onClose}
                closeOnBlur
                isLazy
              >
                <PopoverTrigger>
                  <IconButton
                    backgroundColor="#ECEFF1"
                    ml={3}
                    aria-label="Delete Question"
                    icon={<RiDeleteBin5Fill style={{ color: colors.blue[500] }} />}
                    data-testid={`DeleteQuestionSpecificBtn-${itemIndex}`}
                    onClick={() => {
                      onOpen();
                      setDeleteIndex(itemIndex);
                    }}
                  />
                </PopoverTrigger>
                {isOpen && (
                  <PopoverContent rootProps={{ style: { right: 0 } }}>
                    <PopoverBody fontWeight="semibold">{t('createJobPosition:deleteQuestionConfirmation')}</PopoverBody>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverFooter d="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm">
                        <Button variant="outline" onClick={onClose}>
                          {t('common:questions.answerNegative')}
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            setDeleteIndex(null);
                            deleteQuestion(itemIndex);
                            onClose();
                          }}
                          data-testid={`deleteQuestionConfirmSpecificBtn-${itemIndex}`}
                        >
                          {t('common:questions.answerPositive')}
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                )}
              </Popover>
            </Box>
          </Flex>
        </Flex>
      </li>
    );
  },
);
