export default {
  readyToJoin: `Prêt à joindre?`,
  join: 'Joindre!',
  waitingForCandidate: `En attente du candidat`,
  alreadyJoinedHeader: "Un recruteur s'est déjà joint à l'entrevue",
  alreadyJoinedBy: "est présentement dans l'entrevue.",
  okay: 'Okay',
  ratingDisabledTooltip: "Vous pouvez évaluer ce candidat pour jusqu'à 24 heures suivant l'entrevue.",
  testSpeaker: 'Vérifier sortie audio',
  yourDevices: `Choisir le périphérique que vous allez utiliser pour écouter l'entrevue`,
  done: 'Terminer',
  checkYourDevice: 'Vérifier sortie audio',
  guestMeet: {
    readyToJoin: `Vous êtes quasiment arrivé!`,
    enterInfoToJoin: `Remplissez les champs ci-dessous pour pouvoir joindre l'entrevue.`,
    name: 'Votre nom',
    namePlaceholder: 'Entrez votre nom complet',
    nameRequired: 'Your name is required',
    codeRequired: 'Code is required',
    code: 'Code de vérification',
    codePlaceholder: 'Entrez le code de vérification que vous avez reçu',
    join: 'Joindre',
    hintMessageBeforeJoin: `Pour vous préparer à l'entretien, assurez-vous d'utiliser le casque ou l'appareil que vous utiliserez lors de l'événement.`,
    joinAsLabel: `Vous êtes connecté comme l'utilisateur  {{userName}}.`,
    joinMessage: `Vous pouvez maintenant joindre l'entrevue.`,
    joinMessagePreInterview: `Cette entrevue commencera à {{time}}.`,
    joinMessagePreInterviewOnAnotherDay: `Cette entrevue commencera le {{day}} à {{time}}.`,
    joinMessagePastInterview: `Cette entrevue est complétée.`,

    invalidCode: 'Code invalide',
    leaveCall: `Quitter l'entrevue`,
  },
  errorCode: {
    '1005': `Vous ne pouvez pas joindre l'entrevue avant qu'elle commence.`,
    '1006': `Vous ne pouvez pas joindre l'entrevue après qu'elle soit terminée.`,
  },
  collaboratorFeatureNotAvailable: `Notre fonctionnalité de co-intervieweur est actuellement non disponible.`,
  timeEndingAlert: {
    giveAssessment: "N'oubliez pas d'évaluer ce candidat!",
    interviewTimeEnd: 'Cette entrevue termine bientôt.',
    timeRemaining: "Cette entrevue termine bientôt, donc assurez-vous d'évaluer le candidat si ce n'est pas déjà fait.",
    interviewEndAfter: 'Cette entrevue termine automatiquement dans',
    okay: `J'ai compris`,
  },
};
