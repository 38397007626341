export const IS_TYPING_DEFAULT_DOCUMENT_ID = '1';

export enum Collections {
  ACCOUNT = 'accounts',
  ACCOUNT_INVITES = 'accountInvites',
  CANDIDATE_AUDIT = 'candidateAudit',
  CANDIDATES = 'candidates',
  POSITIONS = 'positions',
  SEEKERS = 'seekers',
  USERS = 'users',
  CONFIGURATION = 'configuration',
  APPOINTMENT_AVAILABILITY = 'appointmentAvailability',
  GROUP_EVENTS = 'groupEvents',
  LOOKUPS = 'lookups',
  NOTIFICATIONS = 'notifications',
  BUSINESSES = 'businesses',
  NEWS = 'news',
  JOBS = 'jobs',
  CUSTOMER_SUPPORT_DATA = 'customerSupportData',
  CONTACT_REQUEST_DECLINED_REASONS = 'contactRequestDeclinedReasons',
  LANGUAGE_EVALUATION_PHRASES = 'languageEvaluationPhrases',
  VIDEO_GALLERY = 'videoGallery',
  VIDEO_INTERVIEW_INVITES = 'videoInterviewInvites',
  VIDEO_ROOMS = 'videoRooms',
  CANDIDATE_RECRUITER_ACTION = 'candidateRecruiterAction',
}

export enum SubCollections {
  SEEKER_POSITION_STATUS = 'seekerPositionStatus',
  SEEKER_IS_TYPING = 'seekerIsTyping',
  RECRUITER_IS_TYPING = 'recruiterIsTyping',
  SEEKER_NOTES = 'seekerNotes',
  CANDIDATES = 'candidates',
  RECRUITERS = 'recruiters',
  REQUISITIONS = 'requisitions',
}
