/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo, useCallback, useEffect } from 'react';
import { Box, Button, Flex, Spacer, Badge, HStack, useDisclosure } from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import { Room } from 'twilio-video';
import { ParticipantList } from './ParticipantList';
import { MainParticipant } from './MainParticipant';
import { AudioToggleButton } from './twilio/AudioToggleButton';
import { VideoToggleButton } from './twilio/VideoToggleButton';

import { MeetSessionDetail } from './MeetSessionDetail';
import { useMeetParticipants } from './useMeetParticipants';
import { useScreenShareParticipant } from './twilio/hooks/useScreenShareParticipant';

import { ValidMeetTokenResponse } from './MeetTokenResponse';
import { MediaTypeEnum } from './MediaTypeEnum';

import { ReactComponent as _IconPresentation } from '../../assets/img/icon-presentation.svg';
import { ReactComponent as _IconPresentationOff } from '../../assets/img/icon-presentation-off.svg';
import { ReactComponent as _IconCall } from '../../assets/img/icon-call.svg';
import { ReactComponent as _IconUsers } from '../../assets/img/icon-users.svg';
import { ReactComponent as _IconAbsent } from '../../assets/img/icon-absent.svg';

import {
  interviewRoomActionBar,
  interviewRoomBlock,
  mainParticipantBlockStyle,
  participantBlockStyle,
  videosWrapper,
} from './MeetViewStyle';

const IconPresentation = memo(_IconPresentation);
const IconPresentationOff = memo(_IconPresentationOff);
const IconCall = memo(_IconCall);
const IconUsers = memo(_IconUsers);
const IconAbsents = memo(_IconAbsent);

type InterviewRoomProps = {
  room: Room;
  quitGroupEventRoom: () => void;
  isConnecting: boolean;
  isLoadingLocalTracks: boolean;
  isSharingScreen: boolean;
  toggleScreenSharing: () => void;
  groupEventInfo: ValidMeetTokenResponse | null;
};

export const InterviewRoom = ({
  room,
  quitGroupEventRoom,
  isConnecting,
  isLoadingLocalTracks,
  isSharingScreen,
  toggleScreenSharing,
  groupEventInfo,
}: InterviewRoomProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { filteredMeetParticipant, allMeetParticipants } = useMeetParticipants(room);

  const isLoading = (): boolean => {
    return isLoadingLocalTracks || isConnecting;
  };

  const screenShareParticipant = useScreenShareParticipant();
  const { localParticipant } = room;

  const showScreenSharing = (): boolean => {
    if (_isNil(screenShareParticipant) || screenShareParticipant === localParticipant) {
      return isSharingScreen;
    }
    return true;
  };

  return (
    <Box css={interviewRoomBlock} className="interview-room-block">
      <Box data-testid="InterviewWindow">
        <Flex data-testid="InterviewWindowHeader" className="interviewRoomHeader" align="center" p={[1, 2]}>
          <HStack spacing="24px">
            <Box>
              <IconUsers />
              <span className="badge-label">Invited to the call:</span>
              <Badge colorScheme="blue">{allMeetParticipants.length}</Badge>
            </Box>
            <Box>
              <IconAbsents />
              <span className="badge-label">Absent candidate:</span>
              <Badge colorScheme="blue">{allMeetParticipants.length - filteredMeetParticipant.length}</Badge>
            </Box>
          </HStack>
          <Spacer />
          <Box>
            <Button colorScheme="blue" size="sm" fontWeight={500} onClick={onOpen}>
              {`Show Everyone (${filteredMeetParticipant.length})`}
            </Button>
          </Box>
        </Flex>
        <Flex css={videosWrapper} className={`video-wrapper ${showScreenSharing() ? 'presentation-layout' : 'grid-layout'}`}>
          {showScreenSharing() && (
            <Box css={mainParticipantBlockStyle} className="main-participant-wrapper">
              <MainParticipant mediaType={MediaTypeEnum.VIDEO_ONLY} screenShareParticipant={screenShareParticipant} />
            </Box>
          )}
          <Box css={participantBlockStyle} className="participants-blocks">
            {filteredMeetParticipant.length && <ParticipantList filteredParticipant={filteredMeetParticipant} />}
          </Box>
        </Flex>

        <Flex css={interviewRoomActionBar} align="center" className="interview-room-action-bar">
          <Box m={2}>
            <VideoToggleButton isLoading={isLoading()} />
          </Box>
          <Box m={2}>
            <AudioToggleButton isLoading={isLoading()} />
          </Box>
          <Spacer />
          {groupEventInfo && !groupEventInfo.isCandidate && (
            <Button onClick={toggleScreenSharing} isLoading={isLoading()} isDisabled={isLoading()}>
              {isSharingScreen ? <IconPresentationOff /> : <IconPresentation />}
            </Button>
          )}
          <Spacer />
          <Button className="btn-call-end" onClick={quitGroupEventRoom} isLoading={isLoading()} isDisabled={isLoading()}>
            <IconCall />
          </Button>
        </Flex>
      </Box>

      <MeetSessionDetail
        isOpen={isOpen}
        onClose={onClose}
        totalParticipant={allMeetParticipants.length}
        filteredParticipant={filteredMeetParticipant}
      />
    </Box>
  );
};
