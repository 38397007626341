/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, Heading, List, Grid, GridItem, Button } from '@chakra-ui/core';
import { ArrowBackIcon } from '@chakra-ui/icons';
import _filter from 'lodash/filter';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';
import { SupportDetailCSS } from './SupportDetailView.styles';
import { CustomerSupportDetail } from './supportData';
import { useSupportViewContext } from './SupportViewContext';
import { SupportLinkItem } from './SupportLinkItem';
import { getTranslatedValue } from '../../utils/localizedString';
import useCustomerSupportData from './useCustomerSupportData';

export type SupportDetailViewProps = {
  question: CustomerSupportDetail;
};

export const SupportDetailView = ({ question }: SupportDetailViewProps): JSX.Element => {
  const { setSelectedSupport } = useSupportViewContext();
  const { t, i18n } = useTranslation();
  const supportData = useCustomerSupportData();

  return (
    <Grid
      templateColumns="repeat(4, 1fr)"
      data-testid="support-detail-page"
      gap={8}
      paddingTop="20px"
      borderTop={`1px solid ${colors.gray[50]}`}
    >
      <GridItem colSpan={4}>
        <Button
          leftIcon={<ArrowBackIcon />}
          variant="link"
          data-testid="back-to-main-support-page"
          onClick={() => setSelectedSupport(null)}
        >
          {t('support:back')}
        </Button>
      </GridItem>
      <GridItem colSpan={3}>
        <Box css={SupportDetailCSS} className="feature-detail-block" mb={4}>
          <h3>{getTranslatedValue(question.title, i18n.language)}</h3>
          <Box mb={3}>
            {question.video && getTranslatedValue(question.video, i18n.language) !== '' && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video width="auto" height="auto" key={question.id} loop autoPlay>
                <source src={getTranslatedValue(question.video, i18n.language)} type="video/ogg" />
                {t('support:browserDoesNotSupport')}
              </video>
            )}
          </Box>
          {question && <div dangerouslySetInnerHTML={{ __html: getTranslatedValue(question.description, i18n.language) }} />}
        </Box>
      </GridItem>
      <GridItem colSpan={1}>
        <Box className="feature-block" mb={4}>
          <Heading fontSize="md" as="h6" mb={4}>
            {t('support:relatedQuestions')}
          </Heading>
          <List spacing={2} fontSize="sm">
            {_filter(supportData, { category: question.category }).map((que) => (
              <SupportLinkItem question={que} key={que.id} />
            ))}
          </List>
        </Box>
      </GridItem>
    </Grid>
  );
};
