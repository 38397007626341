import { AxiosError } from 'axios';
import _cloneDeep from 'lodash/cloneDeep';

export default class HttpClientErrorLogger {
  handleError(error: AxiosError): void {
    if (error.response) {
      // eslint-disable-next-line no-console
      console.error(
        `Request was made and server responded with an error: ${error.response.status}`,
        error.response.data,
        error.response.headers,
      );
    } else {
      // eslint-disable-next-line no-console
      console.error(`Request was made but no response was received: ${error.message}`);
    }

    const errorConfiguration = _cloneDeep(error.config);
    delete errorConfiguration.data;
    // eslint-disable-next-line no-console
    console.error('Request configuration', errorConfiguration);
  }
}
