import { useEffect } from 'react';
import { Room, TwilioError } from 'twilio-video';

export const useRoomDisconnect = (
  room: Room | null,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingEnabled: boolean,
  toggleSharing: () => void,
): void => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const onDisconnect = (_: Room, error: TwilioError) => {
        if (error) console.log('Disconnected', error);

        removeLocalAudioTrack();
        removeLocalVideoTrack();

        if (isSharingEnabled) {
          toggleSharing();
        }
      };

      room.on('disconnected', onDisconnect);

      return () => {
        room.off('disconnected', onDisconnect);
      };
    }
  }, [isSharingEnabled, removeLocalAudioTrack, removeLocalVideoTrack, room, toggleSharing]);
};
