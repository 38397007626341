import { css } from '@emotion/core';

export const mainTable = css`
  &.mainTable {
    width: 100%;
  }

  .nameFixedWidth {
    width: 15%;
  }

  .fixBorder {
    border: 3px solid #e1f4fd;
  }

  td,
  th {
    padding-left: 1rem;
  }
`;

export const tableHeading = css`
  background: #e1f4fd;
  height: 45px;
  padding-left: 23px;
  padding-right: 23px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #3a498e;
  position: sticky;
  top: -3px;
  z-index: 2;
`;

export const recruiterNameCell = css`
  color: #607d8b;
  font-size: 14px;

  &.name {
    margin-right: 20px;
  }

  &.low {
    color: #b33636;
  }

  &.medium {
    color: #938511;
  }

  &.high {
    color: #5c995e;
  }
`;

export const sortIconWrapper = css`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;
