import type { AxiosError } from 'axios';
import _isNil from 'lodash/isNil';
import { useToast } from '@chakra-ui/core';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { ImageGalleryResponse } from '../ImageGalleryType';

type UseFetchImageGalleryProps = {
  disableInitialFetch?: boolean;
};

// Type guard for AxiosError
function isAxiosError(error: unknown): error is AxiosError<{ message: string }> {
  return (error as AxiosError).isAxiosError !== undefined;
}

/**
 * Fetches the image gallery.
 *
 * @param {UseFetchImageGalleryProps} options
 * @param {boolean} [options.disableInitialFetch=false] Whether to disable the initial fetch of the gallery.
 * @returns {Object} An object with a `fetchGallery` function that fetches the image gallery.
 *
 * The `fetchGallery` function takes an optional `refetch` boolean parameter. If `refetch` is `true`, the gallery will be refetched.
 * If `refetch` is `false` or not provided, the gallery will only be fetched if it has not been fetched before.
 *
 * The `fetchGallery` function returns a promise that resolves to the image gallery response.
 * If the fetch fails, it will throw an error.
 *
 * The hook also fetches the gallery when the component mounts if `disableInitialFetch` is `false` and the gallery has not been fetched before.
 */
export const useFetchImageGallery = ({ disableInitialFetch = false }: UseFetchImageGalleryProps) => {
  const toast = useToast();
  const { t } = useTranslation();

  const { getImageGallery } = useStoreActions((actions) => actions.imageGallery);
  const { images, isGalleryLoading } = useStoreState((state) => state.imageGallery);

  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account;

  const hasFetched = useRef(false);

  /**
   * Fetches the image gallery.
   *
   * @param {boolean} [refetch=false] Whether to refetch the gallery.
   * @returns {Promise<ImageGalleryResponse>}
   */

  const fetchGallery = useCallback<(refetch?: boolean) => Promise<ImageGalleryResponse | void>>(
    async (refetch = false) => {
      if (_isNil(accountId)) return Promise.resolve();
      try {
        return await getImageGallery({ accountId, refetch });
      } catch (error) {
        let errorMessage = t('administration:imageGalleryModal:loadFailedToastMessage');
        if (isAxiosError(error)) {
          const errorResponse = error.response;

          if (errorResponse?.data?.message) {
            errorMessage = errorResponse.data.message;
          }
        }
        toast({
          title: t('administration:imageGalleryModal:loadFailedToastTitle'),
          description: errorMessage,
          duration: 5000,
          isClosable: true,
          status: 'error',
        });
        throw error;
      }
    },
    [getImageGallery, accountId, toast, t],
  );

  useEffect(() => {
    if (!disableInitialFetch && images?.length === 0 && !isGalleryLoading && !hasFetched.current) {
      hasFetched.current = true;
      fetchGallery().catch(() => {
        // eslint-disable-next-line no-console
      });
    }
  }, [disableInitialFetch, images, isGalleryLoading, fetchGallery]);

  return { fetchGallery };
};
