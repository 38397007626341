/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import {
  IoMdBookmark,
  IoMdCheckboxOutline,
  IoMdMailUnread,
  IoMdNotifications,
  IoMdShareAlt,
  IoMdSync,
  IoMdThumbsDown,
  IoMdThumbsUp,
  IoMdTimer,
  IoMdUnlock,
  IoMdCalendar,
} from 'react-icons/io';
import { MdNotInterested, MdCallEnd, MdCancel, MdReplayCircleFilled, MdOutlineHelp, MdOutlineWorkOff } from 'react-icons/md';

import {
  RiHandCoinLine,
  // RiQuestionnaireFill,
  RiMessageFill,
  RiGitPullRequestFill,
  RiSpamLine,
} from 'react-icons/ri';
import { FaUserFriends, FaUserMinus } from 'react-icons/fa';
import { BsCheckCircleFill, BsChatSquareDotsFill } from 'react-icons/bs';
import { IoCheckmarkDoneCircleSharp } from 'react-icons/io5';

import { AiFillAudio } from 'react-icons/ai';
import { VscFeedback } from 'react-icons/vsc';
import {
  CandidateAuditActionNames,
  CandidateHistoryActionName,
} from '../../../../../firebase/firestore/documents/candidateAuditActionNames';
import Question_Icon from '../../../../../assets/img/automated_icon/questions.png';
import Monitized_Icon from '../../../../../assets/img/automated_icon/monetized.png';
import Dismised_Icon from '../../../../../assets/img/automated_icon/dismissed.png';
import Contact_Request_Icon from '../../../../../assets/img/automated_icon/contact-request.png';
import Bookmarked_Icon from '../../../../../assets/img/automated_icon/bookmarked.png';
import Appointment_Request_Video_Icon from '../../../../../assets/img/automated_icon/bookmarked.png';
import Appointment_Period_Cancelled_Icon from '../../../../../assets/img/automated_icon/appointment-period-cancelled.svg';
import Appointment_Request_Cancelled_Icon from '../../../../../assets/img/automated_icon/appointment-request-cancelled.svg';
import Candidate_Hired_Icon from '../../../../../assets/img/automated_icon/candidate-hired.svg';
import Hired_Form_Completed_Icon from '../../../../../assets/img/automated_icon/hire-form-completed.svg';
import Hired_Form_Requested_Icon from '../../../../../assets/img/automated_icon/hire-form-requested.svg';
import Offer_Made_Icon from '../../../../../assets/img/automated_icon/offer-made.svg';
import ModernHire from '../../../../../assets/img/modernHire.svg';
import AppyHereIcon from '../../../../../assets/img/appy_logo.svg';
import SeekerHiredFailed from '../../../../../assets/img/seekerHiredFailed.svg';
import ModernHireInprogress from '../../../../../assets/img/modernHireInprogress.svg';
import ModernHirePassed from '../../../../../assets/img/modernHirePassed.svg';
import CertnLogoSuccess from '../../../../../assets/img/certn-logo-success.svg';
import CertnLogoInvitated from '../../../../../assets/img/certn-logo-invitation.svg';

export type CandidateAuditStatusIconProps = {
  status: CandidateAuditActionNames;
};

export const CandidateAuditStatusIcon = ({ status }: CandidateAuditStatusIconProps): JSX.Element | null => {
  const auditStatusIcon = () => {
    switch (status) {
      case CandidateHistoryActionName.APPOINTMENT_ASSESSMENT_POSITIVE:
        return <IoMdShareAlt size={24} className="success reverse" />;
      case CandidateHistoryActionName.APPOINTMENT_ASSESSMENT_NEGATIVE:
        return <IoMdShareAlt size={24} className="danger reverse" />;
      case CandidateHistoryActionName.SEEKER_APPOINTMENT_REMINDER_SENT:
        return <IoMdTimer size={24} className="info" />;
      case CandidateHistoryActionName.CANDIDATE_BOOKMARKED:
        return <IoMdBookmark size={24} className="info" />;
      case CandidateHistoryActionName.CANDIDATE_CALLED:
        return <MdCallEnd size={24} className="info" />;
      case CandidateHistoryActionName.OFFER_MADE:
        return <RiHandCoinLine size={24} className="info" />;
      case CandidateHistoryActionName.OFFER_REJECTED:
        return <MdCancel size={24} className="danger" />;
      case CandidateHistoryActionName.CANDIDATE_DISMISSED:
        return <MdNotInterested size={24} className="danger" />;
      case CandidateHistoryActionName.CANDIDATE_HIRED:
        return <IoMdCheckboxOutline size={24} className="info" />;
      case CandidateHistoryActionName.CANDIDATE_UNHIRED:
        return <FaUserFriends size={24} className="danger" />;
      case CandidateHistoryActionName.CANDIDATE_UNLOCKED:
        return <IoMdUnlock size={24} className="info" />;
      case CandidateHistoryActionName.CONTACT_REQUEST_SENT:
        return <IoMdShareAlt size={24} className="success reverse" />;
      case CandidateHistoryActionName.CONTACT_REQUEST_ACCEPTED:
        return <IoMdMailUnread size={24} className="success" />;
      case CandidateHistoryActionName.CONTACT_REQUEST_DECLINED:
        return <IoMdMailUnread size={24} className="danger" />;
      case CandidateHistoryActionName.POSITION_LIKED:
        return <IoMdThumbsUp size={24} className="success" />;
      case CandidateHistoryActionName.POSITION_UNLIKED:
        return <IoMdThumbsDown size={24} className="danger" />;
      // case CandidateHistoryActionName.QUESTION_ANSWERED:
      //   return <RiQuestionnaireFill size={24} className="info" />;
      case CandidateHistoryActionName.INTEREST_REVOKED:
        return <IoMdNotifications size={24} className="info" />;
      case CandidateHistoryActionName.SAVED_FROM_AUTO_DISMISSAL:
        return <IoMdSync size={24} className="info" />;
      case CandidateHistoryActionName.INVITED_FOR_SCREENING:
        return <img src={CertnLogoInvitated} width={22} alt="morden hire icon" />;
      case CandidateHistoryActionName.SCREENING_COMPLETED:
        return <img src={CertnLogoSuccess} width={22} alt="morden hire icon" />;
      // case CandidateHistoryActionName.VIDEO_INTERVIEW_JOIN_FAILED:
      //   return <MdError size={24} className="danger" />;
      // case CandidateHistoryActionName.VIDEO_INTERVIEW_JOINED:
      //   return <MdVideocam size={24} className="success" />;
      // case CandidateHistoryActionName.FREE_FORM_QUESTION_ANSWERED:
      //   return <RiQuestionnaireFill size={24} className="info" />;

      case CandidateHistoryActionName.APPOINTMENT_REQUEST_ACCEPTED:
        return <BsCheckCircleFill size={24} className="success" />;
      case CandidateHistoryActionName.APPOINTMENT_REQUEST_CANCELLED:
        return <MdCancel size={24} className="danger" />;
      case CandidateHistoryActionName.APPOINTMENT_REQUEST_CREATED:
        return <BsChatSquareDotsFill size={24} className="info" />;
      case CandidateHistoryActionName.APPOINTMENT_PERIOD_CANCELLED:
        return <RiMessageFill size={24} className="danger" />;

      case CandidateHistoryActionName.OFFER_ACCEPETED_BY_SEEKER:
        return <BsCheckCircleFill size={24} className="success" />;
      case CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER:
        return <MdCancel size={24} className="danger" />;
      case CandidateHistoryActionName.HIRE_FORM_REQUESTED:
        return <RiGitPullRequestFill size={24} className="info" />;
      case CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_REQUESTED:
        return <MdReplayCircleFilled size={24} className="info" />;
      case CandidateHistoryActionName.CANDIDATE_FORM_HELP_REQUESTED:
        return <MdOutlineHelp size={24} className="info" />;
      case CandidateHistoryActionName.HIRE_FORM_COMPLETED:
        return <IoCheckmarkDoneCircleSharp size={24} className="success" />;
      case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_FAILED:
        return <img src={SeekerHiredFailed} width={22} alt="morden hire icon" />;
      case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_IN_PROGRESS:
        return <img src={ModernHireInprogress} width={22} alt="morden hire icon" />;
      case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_PASSED:
        return <img src={ModernHirePassed} width={22} alt="morden hire icon" />;
      case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_STARTED:
        return <img src={ModernHire} width={22} alt="morden hire icon" />;
      case CandidateHistoryActionName.CANDIDATE_APPROVED:
        return <IoMdCheckboxOutline size={24} className="info" />;
      case CandidateHistoryActionName.CANDIDATE_REJECTED:
        return <RiSpamLine size={24} className="danger" />;
      case CandidateHistoryActionName.CANDIDATE_RECORDING_UPLOADED:
      case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_EXPERT:
      case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_COMPETENT:
      case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_LIMITED:
      case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_OCCASIONAL:
      case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_NEEDS_REVIEW:
      case CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_EXPERT:
      case CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_COMPETENT:
      case CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_LIMITED:
      case CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_OCCASIONAL:
      case CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_NEEDS_REVIEW:
      case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_REQUESTED:
      case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_RE_REQUESTED:
        return <AiFillAudio size={24} className="info" />;
      case CandidateHistoryActionName.HIRE_FORM_IN_PROGRESS:
        return <RiGitPullRequestFill size={24} className="info" />;
      case CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_IN_PROGRESS:
        return <RiGitPullRequestFill size={24} className="info" />;
      case CandidateHistoryActionName.COLLABORATOR_INTERVIEW_REVIEW:
        return <VscFeedback size={24} className="info" />;
      case CandidateHistoryActionName.CANDIDATE_APPOINTMENT_EXPIRED:
        return <IoMdCalendar size={24} className="danger" />;
      case CandidateHistoryActionName.NOT_LOOKING_FOR_WORK:
        return <MdOutlineWorkOff size={24} className="danger" />;
      case CandidateHistoryActionName.DEACTIVATED:
        return <FaUserMinus size={24} className="danger" />;

      default:
        return <img src={AppyHereIcon} width={22} alt="Appy Here Icon" />;
    }
  };

  return auditStatusIcon();
};

export const CandidateAuditStatusIconAutomated = ({ status }: CandidateAuditStatusIconProps): JSX.Element | null => {
  const auditStatusIcon = () => {
    switch (status) {
      case CandidateHistoryActionName.FREE_FORM_QUESTION_ANSWERED:
      case CandidateHistoryActionName.QUESTION_ANSWERED:
        return <img src={Question_Icon} style={{ width: 24 }} alt="" />;
      case CandidateHistoryActionName.CANDIDATE_UNLOCKED:
        return <img src={Monitized_Icon} style={{ width: 24 }} alt="" />;
      case CandidateHistoryActionName.CANDIDATE_DISMISSED:
        return <img src={Dismised_Icon} style={{ width: 24 }} alt="" />;
      case CandidateHistoryActionName.CONTACT_REQUEST_ACCEPTED:
      case CandidateHistoryActionName.CONTACT_REQUEST_SENT:
        return <img src={Contact_Request_Icon} style={{ width: 24 }} alt="" />;
      case CandidateHistoryActionName.CANDIDATE_BOOKMARKED:
        return <img src={Bookmarked_Icon} style={{ width: 24 }} alt="" />;
      case CandidateHistoryActionName.APPOINTMENT_REQUEST_CREATED:
      case CandidateHistoryActionName.APPOINTMENT_REQUEST_ACCEPTED:
        return <img src={Appointment_Request_Video_Icon} style={{ width: 24 }} alt="" />;
      // SVG Icons start
      case CandidateHistoryActionName.APPOINTMENT_REQUEST_CANCELLED:
        return <img src={Appointment_Request_Cancelled_Icon} style={{ width: 30 }} alt="" />;
      case CandidateHistoryActionName.APPOINTMENT_PERIOD_CANCELLED:
        return <img src={Appointment_Period_Cancelled_Icon} style={{ width: 30 }} alt="" />;
      case CandidateHistoryActionName.OFFER_MADE:
        return <img src={Offer_Made_Icon} style={{ width: 30 }} alt="" />;
      case CandidateHistoryActionName.CANDIDATE_HIRED:
        return <img src={Candidate_Hired_Icon} style={{ width: 30 }} alt="" />;
      case CandidateHistoryActionName.HIRE_FORM_REQUESTED:
        return <img src={Hired_Form_Requested_Icon} style={{ width: 30 }} alt="" />;
      case CandidateHistoryActionName.HIRE_FORM_COMPLETED:
        return <img src={Hired_Form_Completed_Icon} style={{ width: 30 }} alt="" />;
      // SVG Icons ends
      case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_FAILED:
        return <img src={SeekerHiredFailed} width={22} alt="morden hire icon" />;
      case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_IN_PROGRESS:
        return <img src={ModernHireInprogress} width={22} alt="morden hire icon" />;
      case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_PASSED:
        return <img src={ModernHirePassed} width={22} alt="morden hire icon" />;
      case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_STARTED:
        return <img src={ModernHire} width={22} alt="morden hire icon" />;
      case CandidateHistoryActionName.SCREENING_COMPLETED:
        return <img src={CertnLogoSuccess} width={22} alt="morden hire icon" />;
      case CandidateHistoryActionName.INVITED_FOR_SCREENING:
        return <img src={CertnLogoInvitated} width={22} alt="morden hire icon" />;

      case CandidateHistoryActionName.OFFER_REVOKE:
        return <img src={AppyHereIcon} width={22} alt="offer revoked" />;
      default:
        return <img src={AppyHereIcon} width={22} alt="Appy Here Icon" />;
    }
  };

  return auditStatusIcon();
};
