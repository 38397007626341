import { setVideoRef, setVideoDeleteRef, setVideoThumbnailDeleteRef } from '../firebase/firestore/storage';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';

export class VideoManagementController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async uploadOverviewVideo(accountId: string, video: File, fileName: string, folderName: string): Promise<void> {
    await setVideoRef(accountId, video, fileName, folderName);
  }

  async uploadOverviewThumbnail(accountId: string, thumbnail: File, fileName: string, folderName: string): Promise<void> {
    await setVideoRef(accountId, thumbnail, fileName, folderName);
  }

  async deleteVideoFromStorage(accountId: string, folderName: string): Promise<void> {
    await setVideoDeleteRef(accountId, folderName);
  }

  async deleteVideoThumbnailFromStorage(accountId: string, folderName: string): Promise<void> {
    await setVideoThumbnailDeleteRef(accountId, folderName);
  }
}
