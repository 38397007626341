import {
  Button,
  Box,
  Image,
  Text,
  Heading,
  Flex,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogBody,
} from '@chakra-ui/core';
import React, { useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import useCandidateBooking from './useCandidateBooking';
import { INTERVIEW_MESSAGING_TABS } from '../../routes/constants';
import { SeekerContainerTabItems } from '../../modules/messaging/seeker/SeekerContainerContext';
import { useStoreActions } from '../../models/hooks';
import { AvailableBookingWithPosition } from '../../firebase/firestore/documents/appointmentAvaiability';
import { SESSION_KEY_CANDIDATE_LIST_TYPE } from '../../modules/messaging/sidebar/filters/SeekersFiltersContext';
import { CandidateListType } from '../../modules/messaging/sidebar/filters/seekersFiltersContextValueType';

interface Data {
  id?: string;
  code?: string;
  positionId?: string;
  candidateId?: string;
}

interface Notif {
  title: string;
  body: string;
}

interface Payload {
  data: Data;
  notification: Notif;
}

export const InterviewReminderAlert = ({ data, notification }: Payload): JSX.Element => {
  const { t } = useTranslation();
  const setInterviewEvent = useStoreActions((state) => state.interview.setInterviewEvent);
  const history = useHistory();

  const cancelRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [title, setTitle] = React.useState<string | undefined>();
  const onClose = () => setIsOpen(false);
  const candidateAndBookingDetails = useCandidateBooking(data.candidateId ?? '');
  const candidateDetails = candidateAndBookingDetails?.candidate;

  const storeEventDetailForInterview = () => {
    setIsOpen(false);
    setInterviewEvent(candidateAndBookingDetails as AvailableBookingWithPosition);
    sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, CandidateListType.UNKNOWN);
    history.push(INTERVIEW_MESSAGING_TABS(data.candidateId ?? '', SeekerContainerTabItems.Messaging));
  };

  useEffect(() => {
    // istanbul ignore next
    if (data && notification) {
      setIsOpen(true);
      setTitle(notification.body);
    }
  }, [data, notification]);

  return (
    <AlertDialog motionPreset="slideInBottom" onClose={onClose} leastDestructiveRef={cancelRef} isOpen={isOpen} isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent data-testid="VideoInterviewReminderAlert" borderRadius={0}>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Flex my={5} flexDirection="column" justifyContent="center" alignItems="center">
            <Image src="/interview-notification.png" alt="" mt={5} />
            <Text as="h2" fontWeight="bold" mt={10} data-testid="VideoReminderTitle">
              {title}
            </Text>
          </Flex>
          <Box bg="#EAF8FE" fontSize="sm">
            <Heading size="sm" pl={5} py={3} bg="#ffffff" border="4px solid #EAF8FE">
              {t('common:videoInterviewReminder.candidateInfo')}
            </Heading>
            <Box p={6}>
              <Flex flexDirection="row" mb={2}>
                <Text w="35%">{t('common:videoInterviewReminder.name')}</Text>
                <Text>{candidateDetails?.firstName}</Text>
                <Text ml={1}>{candidateDetails?.lastName}</Text>
              </Flex>
              <Flex flexDirection="row" mb={2}>
                <Text w="35%">{t('common:videoInterviewReminder.position')}</Text>
                <Text>{candidateDetails?.jobTitle.en}</Text>
              </Flex>
              <Flex flexDirection="row" mb={2}>
                <Text w="35%">{t('common:videoInterviewReminder.location')}</Text>
                <Text w="65%">{candidateDetails?.businessName}</Text>
              </Flex>
              <Flex flexDirection="row">
                <Text w="35%">{t('common:videoInterviewReminder.email')}</Text>
                <Text>{candidateDetails?.email}</Text>
              </Flex>
            </Box>
          </Box>
        </AlertDialogBody>
        <Box p={7}>
          <Flex flexDirection="row" justifyContent="space-around">
            <Button
              colorScheme="blackAlpha"
              color="#29B6F6"
              borderRadius={2}
              width={155}
              onClick={onClose}
              data-testid="CloseInterviewAlertBtn"
            >
              {t('common:videoInterviewReminder.close')}
            </Button>
            <Button
              isLoading={_isEmpty(candidateAndBookingDetails)}
              data-testid="JoinInterviewBtn"
              colorScheme="twitter"
              borderRadius={2}
              width={181}
              onClick={() => storeEventDetailForInterview()}
            >
              {t('common:videoInterviewReminder.joinInterview')}
            </Button>
          </Flex>
        </Box>
      </AlertDialogContent>
    </AlertDialog>
  );
};
