import React from 'react';
import { Box, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalHeader, Heading, Text } from '@chakra-ui/core';

import { useTranslation } from 'react-i18next';
import { MyProfileView } from './myProfile/MyProfileView';

type ProfileViewProps = {
  open: boolean;
  onCloseProfile: () => void;
  picture: string | undefined;
};
export const ProfileView = ({ open, onCloseProfile, picture }: ProfileViewProps): JSX.Element => {
  const { t } = useTranslation('profile');

  return (
    <Modal onClose={() => onCloseProfile()} isOpen={open} scrollBehavior="inside" size="xl">
      <ModalOverlay />
      <ModalContent maxW="45rem">
        <ModalHeader paddingLeft={0} paddingRight={0}>
          <Box borderBottom="0.5px solid #ECEFF1" paddingLeft="1rem" paddingRight="1rem" paddingBottom="1rem">
            <Heading as="h4" fontSize="lg" lineHeight="1.3">
              {t('myProfile')}
            </Heading>
            <Text m="3px 0" color="gray.500" fontSize="12px">
              {t('editProfile')}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton data-testid="closeModelBtn" />
        <ModalBody>
          <MyProfileView picture={picture} onClose={onCloseProfile} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
