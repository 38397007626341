/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Flex, Box } from '@chakra-ui/core';
import { Chat } from './messages/Chat';
import { Notes } from './notes/Notes';
import { SeekerContainerTabItems, useSeekerContainerContext, useSelectedTab } from '../SeekerContainerContext';
import VerticalSeekerTab from './accordion/VerticalSeekerTab';
import { chatMessage, columnHeight } from '../SeekerContainer.styles';
import { useSelectedEngagedCandidate } from '../../MessagingViewContext';

export type CandidateContainerProps = {
  isCertnFeatureEnabled: boolean;
  isCertnEnabled: boolean;
  isMessagingModal?: boolean;
};

const CandidateContainer = ({
  isCertnFeatureEnabled,
  isCertnEnabled,
  isMessagingModal = false,
}: CandidateContainerProps): JSX.Element => {
  const { selectedTab } = useSelectedTab();
  const [messageText, setMessageText] = useState('');
  const { selectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();

  const candidate = selectedCandidate || /* istanbul ignore next */ selectedEngagedCandidate;

  return (
    <Flex style={{ overflow: 'hidden' }} className="chatMessage">
      <Box css={[columnHeight, chatMessage]}>
        <AnimatePresence>
          {candidate && (
            <Fragment>
              {selectedTab === SeekerContainerTabItems.Messaging && (
                <Chat candidate={candidate} messageText={messageText} onMessageChange={setMessageText} />
              )}
              {selectedTab === SeekerContainerTabItems.Notes && <Notes />}
            </Fragment>
          )}
        </AnimatePresence>
      </Box>
      {!isMessagingModal && (
        <Box css={[columnHeight]} w="30%" minW="400px" style={{ borderLeft: '1px solid #F5F5F5' }}>
          <VerticalSeekerTab isCertnFeatureEnabled={isCertnFeatureEnabled} isCertnEnabled={isCertnEnabled} />
        </Box>
      )}
    </Flex>
  );
};

export default CandidateContainer;
