import React, { ChangeEvent } from 'react';
import { Box, Text, Stack, Checkbox } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchAndSortTypes } from '../filters/seekersFiltersContextValueType';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';

export const BackgroundCheck = (): JSX.Element => {
  const { t } = useTranslation();

  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();

  const onHandleBackgroundCheck = (searchAndSort: SearchAndSortTypes) => {
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...searchAndSort });
  };

  return (
    <Box>
      <Stack direction="column">
        <Checkbox
          data-testid="completedCheckBox"
          isChecked={searchAndSortTypes.backgroundCheck.includes('COMPLETE')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.backgroundCheck.push('COMPLETE');
            } else {
              const index = searchAndSortTypes.backgroundCheck.indexOf('COMPLETE');
              searchAndSortTypes.backgroundCheck.splice(index, 1);
            }
            onHandleBackgroundCheck(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('backgroundCheck.done')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="sentCheckBox"
          isChecked={searchAndSortTypes.backgroundCheck.includes('SENT')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.backgroundCheck.push('SENT');
            } else {
              const index = searchAndSortTypes.backgroundCheck.indexOf('SENT');
              searchAndSortTypes.backgroundCheck.splice(index, 1);
            }
            onHandleBackgroundCheck(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('backgroundCheck.Sent')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="inprogressCheckBox"
          isChecked={searchAndSortTypes.backgroundCheck.includes('IN_PROGRESS')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.backgroundCheck.push('IN_PROGRESS');
            } else {
              const index = searchAndSortTypes.backgroundCheck.indexOf('IN_PROGRESS');
              searchAndSortTypes.backgroundCheck.splice(index, 1);
            }
            onHandleBackgroundCheck(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('backgroundCheck.IN_PROGRESS')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="partialCheckBox"
          isChecked={searchAndSortTypes.backgroundCheck.includes('PARTIAL')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.backgroundCheck.push('PARTIAL');
            } else {
              const index = searchAndSortTypes.backgroundCheck.indexOf('PARTIAL');
              searchAndSortTypes.backgroundCheck.splice(index, 1);
            }
            onHandleBackgroundCheck(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('backgroundCheck.partiallyDone')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="disputeCheckbox"
          isChecked={searchAndSortTypes.backgroundCheck.includes('DISPUTE')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.backgroundCheck.push('DISPUTE');
            } else {
              const index = searchAndSortTypes.backgroundCheck.indexOf('DISPUTE');
              searchAndSortTypes.backgroundCheck.splice(index, 1);
            }
            onHandleBackgroundCheck(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('backgroundCheck.DISPUTE')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="noneCheckbox"
          isChecked={searchAndSortTypes.backgroundCheck.includes('NONE')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.backgroundCheck.push('NONE');
            } else {
              const index = searchAndSortTypes.backgroundCheck.indexOf('NONE');
              searchAndSortTypes.backgroundCheck.splice(index, 1);
            }
            onHandleBackgroundCheck(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('backgroundCheck.notDone')}
          </Text>
        </Checkbox>
      </Stack>
    </Box>
  );
};
