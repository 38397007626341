/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import _isNil from 'lodash/isNil';
import { css } from '@emotion/core';
import { Flex, Heading, Text, Box, Menu, MenuButton, MenuList, MenuItem, Button, IconButton } from '@chakra-ui/core';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MdVolumeUp, MdVolumeOff } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { meetViewStyle } from './MeetVide.styles';
import { TestAudioTrack } from './twilio/TestAudioTrack';
import { useDevices } from './twilio/hooks/useDevices';
import { useMeetContext } from './MeetContext';

const menuBtnStyle = css`
  display: block;
  width: 100%;
  text-align: left;
  margin-right: 10px;
  justify-content: space-between;
  padding-left: 1rem !important;
  padding-right: 1rem !important;

  span:first-of-type {
    width: 90%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: normal;
  }

  .chakra-button__icon {
    float: right;
  }
`;

const playBtn = css`
  padding: 0 !important;
  border: 0;
  min-width: 0;
  font-size: 24px;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    box-shadow: none;
    background-color: transparent;
  }
`;

export type GreenRoomDeviceTestProps = {
  onClose: () => void;
};

export const GreenRoomTestOutputDevice = ({ onClose }: GreenRoomDeviceTestProps): JSX.Element => {
  const { t } = useTranslation('meet');
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const { setActiveSinkId, activeSinkId } = useMeetContext();
  const { audioOutputDevices } = useDevices();
  const setActiveDevice = (device: MediaDeviceInfo) => {
    setActiveSinkId(device.deviceId);
  };

  const activeDeviceLabel = () => {
    const activeDevice = audioOutputDevices.find((device) => device.deviceId === activeSinkId);

    return !_isNil(activeDevice) ? activeDevice.label : 'Select device';
  };

  return (
    <Box css={meetViewStyle}>
      <Box boxShadow="lg" mb={2}>
        <Box className="meet-card-header">
          <Heading as="h3" size="md">
            {t('testSpeaker')}
          </Heading>
          <Text>{t('yourDevices')}</Text>
        </Box>
        <Box className="meet-card-body" spacing={4}>
          <TestAudioTrack isPlaying={isPlaying} />
          <Flex w="100%" mb={4}>
            <Menu>
              <MenuButton css={menuBtnStyle} as={Button} variant="outline" rightIcon={<ChevronDownIcon />}>
                {activeDeviceLabel()}
              </MenuButton>
              <MenuList>
                {audioOutputDevices.map((device) => {
                  return (
                    <MenuItem
                      fontSize="sm"
                      data-testid={device.deviceId}
                      key={device.deviceId}
                      onClick={() => setActiveDevice(device)}
                    >
                      {device.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
            <IconButton
              css={playBtn}
              aria-label="Play"
              variant="outline"
              colorScheme="blue"
              p={0}
              onClick={() => setIsPlaying(!isPlaying)}
              icon={isPlaying ? <MdVolumeOff /> : <MdVolumeUp />}
              data-testid="playButton"
            />
          </Flex>
          <Button
            mb={2}
            size="sm"
            bg="blue.500"
            color="white"
            _hover={{
              bg: 'blue.600',
            }}
            data-testid="doneBtn"
            onClick={() => onClose()}
          >
            {t('done')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
