import { useEffect, useState } from 'react';
import { VideoTrack as IVideoTrack } from 'twilio-video';

type VideoDimensions = {
  height: number | null;
  width: number | null;
};
type TrackType = IVideoTrack;
export const useVideoTrackDimensions = (track: TrackType): VideoDimensions => {
  const [dimensions, setDimensions] = useState<VideoDimensions>(track.dimensions);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setDimensions(track.dimensions);

    const handleDimensionChange = (newTrack: TrackType) => {
      setDimensions({
        width: newTrack.dimensions.width,
        height: newTrack.dimensions.height,
      });
    };

    track.on('dimensionsChanged', handleDimensionChange);

    return () => {
      track.off('dimensionsChanged', handleDimensionChange);
    };
  }, [track]);

  return dimensions;
};
