import React from 'react';

import { TopCandidatesContextProvider } from './TopCandidatesContext';
import { TopCandidates } from './TopCandidates';

export const TopCandidateHome = (): JSX.Element => {
  return (
    <TopCandidatesContextProvider>
      <TopCandidates />
    </TopCandidatesContextProvider>
  );
};
