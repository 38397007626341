import { LocalAudioTrack } from 'twilio-video';
import { useCallback } from 'react';
import { useTrackEnabled } from './useTrackEnabled';
import { useMeetContext } from '../../MeetContext';

export type LocalAudioTrackInfo = {
  isAudioEnabled: boolean;
  toggleAudio: () => void;
};

export const useLocalAudioToggleGreenRoom = (): LocalAudioTrackInfo => {
  const { localTracks } = useMeetContext();
  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;

  const isEnabled = useTrackEnabled(audioTrack);

  const toggleAudio = useCallback(() => {
    if (audioTrack) {
      if (audioTrack.isEnabled) audioTrack.disable();
      else audioTrack.enable();
    }
  }, [audioTrack]);

  return { isAudioEnabled: isEnabled, toggleAudio };
};
