/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Spacer } from '@chakra-ui/core';
import { Room } from 'twilio-video';
import { useTranslation } from 'react-i18next';
import _isNil from 'lodash/isNil';
import { InterviewParticipantList } from '../messaging/sidebar/meetInterview/InterviewParticipantList';
import { VideoToggleButton } from '../meet/twilio/VideoToggleButton';
import { AudioToggleButton } from '../meet/twilio/AudioToggleButton';
import { guestInterviewStyle } from './GuestInterviewStyle';
import useInterviewRoomInformation from './useInterviewRoomInformation';
import { MediaTypeEnum } from '../meet/MediaTypeEnum';
import { MainParticipant } from '../meet/MainParticipant';
import MeetExpirationAlertModal from '../meet/MeetExpirationAlertModal';
import { useStoreActions } from '../../models/hooks';

type GuestInterviewingRoomProps = {
  room: Room;
  quitRoom: () => void;
  isConnecting: boolean;
  isLoadingLocalTracks: boolean;
};

export const GuestInterviewingRoom = ({
  room,
  quitRoom,
  isConnecting,
  isLoadingLocalTracks,
}: GuestInterviewingRoomProps): JSX.Element => {
  const { t } = useTranslation('meet');
  const [expiresAt, setExpiresAt] = useState<Date | null>(null);
  const isLoading = (): boolean => {
    return isLoadingLocalTracks || isConnecting;
  };
  // istanbul ignore next
  const { getInterviewBookingDetail } = useStoreActions((actions) => actions.meet);

  const roomInfo = useInterviewRoomInformation(room.name);

  useEffect(() => {
    if (roomInfo && roomInfo.bookingId) {
      getInterviewBookingDetail({ bookingId: roomInfo?.bookingId })
        .then((response) => {
          if (!_isNil(response)) {
            setExpiresAt(response.videoInterviewInvite.expiresAt);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [getInterviewBookingDetail, roomInfo]);

  return (
    <Box className="guest-interview-room-block">
      {!_isNil(expiresAt) && <MeetExpirationAlertModal expireTime={expiresAt} quitRoom={quitRoom} />}
      <Box data-testid="GuestInterviewWindow" css={guestInterviewStyle}>
        <Flex className="video-wrapper interview-with-guest" height="calc(100vh - 65px - 80px)">
          <MainParticipant mediaType={MediaTypeEnum.VIDEO_ONLY} roomInfo={roomInfo} />
          <Box className="participants-blocks">
            <InterviewParticipantList room={room} roomInfo={roomInfo} />
          </Box>
        </Flex>
        <Flex align="center" justifyContent="center" borderTop="1px solid #E5E7EB" py={4}>
          <Flex maxW="320px" align="center" justifyContent="center">
            <Spacer />
            <Box mr={3}>
              <VideoToggleButton isLoading={isLoading()} />
            </Box>
            <Spacer />
            <Button
              colorScheme="red"
              h="45px"
              borderRadius="20px"
              className="btn-call-end"
              alignItems="center"
              onClick={quitRoom}
              data-testid="QuitCall"
              isLoading={isLoading()}
              isDisabled={isLoading()}
            >
              {t('guestMeet.leaveCall')}
            </Button>
            <Spacer />
            <Box ml={3}>
              <AudioToggleButton isLoading={isLoading()} />
            </Box>
            <Spacer />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
