/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box } from '@chakra-ui/core';
import { Participant } from 'twilio-video';
import { css } from '@emotion/core';
import { ParticipantInfo } from './ParticipantInfo';
import { ParticipantTrack } from './ParticipantTrack';
import { MediaTypeEnum } from './MediaTypeEnum';
import colors from '../../styles/colors';

export type ParticipantProps = {
  participant: Participant;
  enableScreenShare?: boolean;
  isSelected?: boolean;
  hideParticipant?: boolean;
  participantName?: string;
  isRecruiter?: boolean;
  isVideoEnabled?: boolean;
  isMicEnabled?: boolean;
};

const participantStyle = css`
  height: 13rem;
  max-width: 100%;
  overflow: hidden;
  background: #000;

  &.selected {
    box-shadow: 0 0 0 2px ${colors.blue[500]};
  }
`;

export const ParticipantView = ({
  participant,
  enableScreenShare,
  isSelected,
  hideParticipant,
  participantName,
  isRecruiter,
  isVideoEnabled,
  isMicEnabled,
}: ParticipantProps): JSX.Element => {
  if (hideParticipant) {
    return <Box />;
  }

  return (
    <Box className={`participant-view ${isSelected ? 'selected' : ''}`} css={participantStyle}>
      <ParticipantInfo />
      <ParticipantTrack
        participant={participant}
        mediaType={MediaTypeEnum.BOTH}
        videoPriority={null}
        showScreenShare={enableScreenShare}
        participantName={participantName}
        isRecruiter={isRecruiter}
        isVideoEnabled={isVideoEnabled}
        isMicEnabled={isMicEnabled}
      />
    </Box>
  );
};

ParticipantView.defaultProps = {
  enableScreenShare: false,
  isSelected: false,
  hideParticipant: false,
};
