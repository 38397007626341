export default {
  recruiterHelpCenter: `Recruiter Help Center`,
  howCanWeHelpYou: `How can we help today?`,
  iAmLookingFor: `I am looking for ... `,
  search: `Search`,
  noResultFound: `No Result found`,
  positionManagement: 'Position Management',
  recruiterManagement: 'Recruiter Management',
  teamManagement: 'Team Management',
  back: 'Back',
  browserDoesNotSupport: 'Your browser does not support the video tag.',
  relatedQuestions: 'Related Questions',
};
