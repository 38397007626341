import React, { useCallback, useEffect, useState } from 'react';
import _isNil from 'lodash/isNil';
import { Box, Flex, Spinner, useDisclosure } from '@chakra-ui/core';
import { useParams } from 'react-router';
import { Header } from '../../../app/topBar/Header';
import { CandidatesList } from './CandidatesList';
import { useTopCandidatesContext } from './TopCandidatesContext';
import { SeekerProfile } from './SeekerProfile';
import { TopCandidatesSidebarFilter } from './TopCandidatesSidebarFilter';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { TopCandidateDataType } from './TopCandidateDataType';
import { TopCandidateParams } from '../../../routes/constants';
import { DismissConfirmationModal } from './DismissConfirmationModal';
import { EngagedConfirmationModal } from './EngagedConfirmationModal';
import { TokenView } from '../../tokens/TokenView';

export const TopCandidates = (): JSX.Element => {
  const { positionId } = useParams<TopCandidateParams>();
  const { isOpen: isDismissModalOpen, onOpen: onDismissModalOpen, onClose: onDismissModalClose } = useDisclosure();
  const { isOpen: isEnagageModalOpen, onOpen: onEnagageModalOpen, onClose: onEnagageModalClose } = useDisclosure();
  const [isRefreshClicked, setIsRefreshClicked] = useState<boolean>(false);
  const {
    isOpen: isRechargeTokenModalOpen,
    onOpen: onRechargeTokenModalOpen,
    onClose: onRechargeTokenModalClose,
  } = useDisclosure();
  const accountStore = useStoreState((s) => s.app.accounts);
  const { isFilterOpened, tab, setSelectedTopCandidate, selectedTopCandidate, positionDocument } = useTopCandidatesContext();
  const [topCandidates, setTopCandidates] = useState<TopCandidateDataType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getTopCandidatesOfPosition } = useStoreActions((action) => action.positionManagement);

  const getTopCandidateList = useCallback(
    async (positionID: string) => {
      const topCandidateBodyData = {
        communityCandidatesCount: 0,
        excludedSeekerIds: [],
        maxItems: 50,
        startFromId: '',
        startFromIndex: 0,
        searchType: tab,
      };
      return getTopCandidatesOfPosition({ positionId: positionID, topCandidateBodyData });
    },
    [getTopCandidatesOfPosition, tab],
  );

  useEffect(() => {
    setIsLoading(true);
    if (positionDocument && positionDocument.id) {
      getTopCandidateList(positionDocument.id)
        .then((data) => {
          setTopCandidates(data);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (data.length > 0) {
            setSelectedTopCandidate(data[0]);
          } else {
            setSelectedTopCandidate(undefined);
          }
        })
        .catch(() => {
          setTopCandidates([]);
          setSelectedTopCandidate(undefined);
        })
        .finally(() => {
          setIsLoading(false);
          setIsRefreshClicked(false);
        });
    }
  }, [getTopCandidateList, positionDocument, setSelectedTopCandidate, isRefreshClicked]);

  const handleEngageCandidate = () => {
    if (
      !_isNil(accountStore) &&
      accountStore.monetizationEnabled === true &&
      accountStore?.remainingCredits !== undefined &&
      accountStore?.remainingCredits === 0
    ) {
      onRechargeTokenModalOpen();
    } else if (accountStore?.remainingCredits && accountStore?.remainingCredits >= 1) {
      onEnagageModalOpen();
    }
  };

  const handleActionAfterDismiss = (seekerId: string) => {
    const filteredCandidates = topCandidates.filter((c) => c.seeker.id !== seekerId);
    setTopCandidates(filteredCandidates);
  };

  return (
    <Box position="absolute" height="100vh" width="100vw" margin="0">
      <Header showMenu />
      <Flex height="calc(100vh - 64px)">
        {isFilterOpened && <TopCandidatesSidebarFilter />}
        <Box
          w={isFilterOpened ? '63%' : '78%'}
          position="relative"
          borderRight="1px solid #E5E7EB"
          borderLeft="1px solid #E5E7EB"
        >
          <CandidatesList topCandidates={topCandidates} isLoading={isLoading} setIsRefreshClicked={setIsRefreshClicked} />
        </Box>
        <Box w="28%">
          {isLoading ? (
            <Flex alignItems="center" justifyContent="center" h="100%">
              <Spinner size="md" />
            </Flex>
          ) : (
            <SeekerProfile onDismissModalOpen={onDismissModalOpen} onEnagageModalOpen={handleEngageCandidate} />
          )}

          {isDismissModalOpen && selectedTopCandidate && positionId && (
            <DismissConfirmationModal
              isOpen={isDismissModalOpen}
              onClose={onDismissModalClose}
              positionId={positionId}
              seekerId={selectedTopCandidate.seeker.id}
              handleActionAfterDismiss={handleActionAfterDismiss}
            />
          )}

          {isEnagageModalOpen && selectedTopCandidate && positionId && (
            <EngagedConfirmationModal
              isOpen={isEnagageModalOpen}
              onClose={onEnagageModalClose}
              positionId={positionId}
              seekerId={selectedTopCandidate.seeker.id}
              handleActionAfterEngage={handleActionAfterDismiss}
            />
          )}

          {isRechargeTokenModalOpen && positionId && (
            <TokenView open={isRechargeTokenModalOpen} onCloseModal={() => onRechargeTokenModalClose()} positionId={positionId} />
          )}
        </Box>
      </Flex>
    </Box>
  );
};
