import { FormControl, FormLabel } from '@chakra-ui/core';
import moment from 'moment';
import React from 'react';
import i18n from '../../../../locales/i18n';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from './TextField';

type DatePickerProps = React.ComponentProps<typeof DatePicker>;

interface DatePickerFieldProps
  extends Pick<DatePickerProps, 'onChange' | 'startDate' | 'endDate' | 'selectsStart' | 'selectsEnd' | 'minDate' | 'maxDate'> {
  value: DatePickerProps['selected'];
  label?: string;
}
export default function DatePickerField({ value, label, startDate, endDate, ...props }: DatePickerFieldProps) {
  return (
    <FormControl flex={0}>
      {label && (
        <FormLabel margin={0} fontSize="sm">
          {label}
        </FormLabel>
      )}
      <DatePicker
        locale={i18n.language}
        selected={value}
        startDate={moment(startDate).toDate()}
        endDate={moment(endDate).toDate()}
        dateFormat="yyyy-MM-dd"
        customInput={<TextField w="full" type="date" value={moment(value).format('YYYY/MM/DD').toString()} isReadOnly />}
        {...props}
      />
    </FormControl>
  );
}
