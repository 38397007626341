export enum AutomationTypes {
  SCREENING = 'screening',
  SCHEDULING = 'scheduling',
}

export enum PositionAutomationTypes {
  NONE = 'null',
  SCREENING = 'screening',
  SCHEDULING = 'scheduling',
  VIRTUAL_RECRUITER_ENABLED = 'virtual_recruiter_enabled',
}
