import moment from 'moment-timezone';
import { SeekerNote, SeekerNoteV2Item } from '../../../../../firebase/firestore/documents/candidate';
import { SeekerNoteType } from '../../../../../firebase/firestore/documents/candidate';

export class Note {
  public id: string;

  public note: string | SeekerNoteV2Item[] | undefined;

  public updatedDate: Date | undefined;

  public recruiterName: string;

  public isEditable: boolean;

  public type: SeekerNoteType | undefined;

  public recruiterIdInNote: string;

  public recruiterRating: string | undefined;

  constructor(
    seekerNote: SeekerNote,
    recruiterName: string,
    isEditable: boolean,
    recruiterIdInNote: string,
    recruiterRating: string | undefined,
    type: SeekerNoteType | undefined,
  ) {
    this.id = seekerNote.id;
    /* eslint-disable */
    this.note = seekerNote.notes
      ? seekerNote.notes.filter((note, index) => {
          //istanbul ignore else
          if (note.isRemoved !== true) {
            note.originalIndex = index;
            return note;
          }
        })
      : seekerNote.note;
    /* eslint-enable */
    this.updatedDate = seekerNote.lastEdited;
    this.recruiterName = recruiterName;
    this.isEditable = isEditable;
    this.recruiterIdInNote = recruiterIdInNote;
    this.recruiterRating = recruiterRating;
    this.type = type;
  }

  getUpdatedDate(): string {
    return moment(this.updatedDate).format('MMM DD, YYYY');
  }
}
