import React from 'react';
import { Avatar, AvatarProps } from '@chakra-ui/core';
import { useStoreState } from '../../../models/hooks';
import DefaultProfilePicture from '../../../assets/img/default-profile-picture.png';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';

export const ProfilePicture = ({ children, name, style, ...rest }: AvatarProps): React.ReactElement => {
  /* eslint-disable*/
  const isInitialsFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_INITIALS_FEATURE];
  });
  /* eslint-enable */

  return (
    <Avatar
      style={{ color: 'white', ...style }}
      name={isInitialsFeatureEnabled ? /* istanbul ignore next */ name?.trim() : ''}
      {...rest}
      icon={<img src={DefaultProfilePicture} alt="" style={{ borderRadius: 100, position: 'relative' }} />}
    >
      {children}
    </Avatar>
  );
};
