import React from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/core';

export type RenderSubmenuWithTreeProps = {
  children: React.ReactNode;
  parentTabIcon?: React.ReactNode;
  parentTabHeading: React.ReactNode;
  isSubMenuItemSelected: boolean;
};

const RenderSubmenuWithTree = ({
  children,
  parentTabIcon,
  parentTabHeading,
  isSubMenuItemSelected,
}: RenderSubmenuWithTreeProps): JSX.Element => {
  return (
    <Accordion defaultIndex={[isSubMenuItemSelected ? 1 : 0]} reduceMotion data-testid="accordionWrapper" allowToggle>
      <AccordionItem border="none">
        <AccordionButton
          _hover={{ outline: 'none', boxShadow: 'none' }}
          _active={{ outline: 'none', boxShadow: 'none' }}
          _focus={{ outline: 'none', boxShadow: 'none' }}
        >
          {parentTabIcon}
          {parentTabHeading}
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel ml={5} alignItems="left">
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default RenderSubmenuWithTree;
