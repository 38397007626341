export type RequisitionInsightsTableDataType = {
  riskStatus: RequisitionStatusEnum[];
  requisitionId: string;
  createdAt: {
    _seconds: number;
  };
  positionId: string;
  effectiveDate: {
    _seconds: number;
  };
  status: string;
  updatedAt?: {
    _seconds: number;
  };
  businessName: string;
  positionName: string;
  riskDays: number;
};

export enum RequisitionStatusEnum {
  SAFE = 'safe',
  AT_RISK = 'atRisk',
  OVERDUE = 'overdue',
}

export enum RequisitionFilterTabNames {
  UNFILLED,
  AT_RISK,
  OVERDUE,
}
