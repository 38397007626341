import React from 'react';
import ReactDOM from 'react-dom';
import { setDefaultLocale } from 'react-datepicker';
import { ChakraProvider } from '@chakra-ui/core';
import { createStore, StoreProvider } from 'easy-peasy';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import firebase from 'firebase/app';
import { BrowserRouter } from 'react-router-dom';
import 'firebase/analytics';

import './index.css';
import 'react-quill/dist/quill.snow.css';

import TagManager from 'react-gtm-module';
import moment from 'moment-timezone';
import storeModel from './models/storeModel';
import * as serviceWorker from './serviceWorker';
import App from './app/App';
import i18n from './locales/i18n';

import 'moment/locale/fr-ca';
import 'moment/locale/en-ca';

import customTheme from './styles/customTheme';
import Config from './firebase/Config';
import { ErrorView } from './error/ErrorView';

i18n.on('languageChanged', (locale) => {
  moment.locale(locale);
  setDefaultLocale(locale);
});

moment.locale(i18n.language);

// prevent user to refresh page
// window.onbeforeunload = () => '';

Bugsnag.start({
  apiKey: Config.bugsnagApiKey,
  releaseStage: Config.env,
  plugins: [new BugsnagPluginReact()],
  appVersion: String(process.env.REACT_APP_PIPELINE_NUMBER ?? 1),
  enabledReleaseStages: ['qa', 'beta', 'production'],
  onError: (error) => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
});

firebase.initializeApp(Config);

const store = createStore(storeModel);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

TagManager.initialize({
  gtmId: Config.gtmId ?? '',
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={customTheme} portalZIndex={40} resetCSS>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <StoreProvider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StoreProvider>
      </ErrorBoundary>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
