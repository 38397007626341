import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/core';
import React from 'react';
import { MdEventBusy } from 'react-icons/md';

export type InterviewAlreadyOngoingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  message: {
    headerMessage: string;
    bodyMessage: string;
    confirmText: string;
  };
};

export const InterviewAlreadyOngoingModal = ({ isOpen, onClose, message }: InterviewAlreadyOngoingModalProps): JSX.Element => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            <MdEventBusy />
            <Text ml={3}>{message.headerMessage}</Text>
          </ModalHeader>
          <ModalBody>{message.bodyMessage}</ModalBody>
          <ModalFooter mt={2}>
            <Button data-testid="okayBtn" colorScheme="blue" mr={3} onClick={onClose}>
              {message.confirmText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
