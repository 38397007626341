import { css } from '@emotion/core';
import colors from '../../../../styles/colors';

export const seekersListFilters = css`
  grid-column: leftSide / midSide;
  grid-row: header / sub2;
  background-color: ${colors.white};
  border-right: 1px solid ${colors.gray['100']};

  .chakra-ui-dark & {
    background-color: ${colors.black};
    border-right: 1px solid ${colors.gray['700']};
  }

  .groupby-select {
    select {
      height: 1.5rem;
      padding-right: 1.5rem;
    }
    .chakra-select__icon-wrapper {
      right: 0;
    }
  }
`;
