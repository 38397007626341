import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../../http/models/httpModel';
import HttpClientWithRetry from '../../../../http/client/HttpClientWithRetry';
import { GroupEventController } from '../../../../controllers/groupEventController';

export type TopBarNotificationStoreModel = {
  topBarNotification: TopBarNotificationModel;
};

export interface TopBarNotificationModel {
  notificationAcceptInvitation: Thunk<TopBarNotificationModel, { groupEventId: string }, void, HttpStoreModel>;
  notificationDeclineInvitation: Thunk<TopBarNotificationModel, { groupEventId: string }, void, HttpStoreModel>;
  readNotification: Thunk<TopBarNotificationModel, { notificationId: string }, void, HttpStoreModel>;
}

export const topBarNotificationStoreModel: TopBarNotificationModel = {
  notificationAcceptInvitation: thunk(
    async (_actions, payload, { getStoreState }): Promise<any> => {
      try {
        const controller = new GroupEventController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.notificationAcceptInvitation(payload.groupEventId);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response.data;
      } catch (e) {
        return null;
      }
    },
  ),

  notificationDeclineInvitation: thunk(
    async (_actions, payload, { getStoreState }): Promise<any> => {
      try {
        const controller = new GroupEventController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.notificationDeclineInvitation(payload.groupEventId);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response.data;
      } catch (e) {
        return null;
      }
    },
  ),

  readNotification: thunk(
    async (_actions, payload, { getStoreState }): Promise<any> => {
      try {
        const controller = new GroupEventController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.readNotification(payload.notificationId);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response.data;
      } catch (e) {
        return null;
      }
    },
  ),
};
