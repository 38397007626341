import React, { Dispatch, ReactText, SetStateAction, useContext, useState } from 'react';
import _noop from 'lodash/noop';
import { useParams } from 'react-router';
import { Seeker } from '../../../firebase/firestore/documents/seeker';
import { TopCandidateDataType } from './TopCandidateDataType';
import { TopCandidateParams } from '../../../routes/constants';
import { usePositionDocumentSnapshot } from '../../messaging/seeker/header/actionsDropdown/usePositionDocumentSnapshot';
import { Position } from '../../../firebase/firestore/documents/position';

export enum SelectedTab {
  BEST_MATCH = 'best_match',
  BOOKMARKED = 'bookmarked',
  ACTIVE = 'active',
}

export type FilterParamsType = {
  salaryRange?: number[];
  experienceRange?: number[];
  lastActiveDays?: number;
  salary?: ReactText | undefined;
  otherFilterParams?: ReactText[];
};

const defaultFilterParams = {
  // salaryRange: [0, 1000],
  // experienceRange: [0],
  // lastActiveDays: 0,
  // salary: undefined,
  // otherFilterParams: [],
} as FilterParamsType;

export type TopCandidatesContextValueType = {
  isFilterOpened: boolean;
  setIsFilterOpened: Dispatch<SetStateAction<boolean>>;
  tab: SelectedTab;
  setTab: Dispatch<SetStateAction<SelectedTab>>;
  selectedSeeker?: Seeker;
  setSelectedSeeker: Dispatch<SetStateAction<Seeker | undefined>>;
  selectedTopCandidate?: TopCandidateDataType;
  setSelectedTopCandidate: Dispatch<SetStateAction<TopCandidateDataType | undefined>>;
  filterParams: FilterParamsType;
  setFilterParams: Dispatch<SetStateAction<FilterParamsType>>;
  appliedFilter: string[];
  setAppliedFilter: Dispatch<SetStateAction<string[]>>;
  positionDocument: Position | undefined;
};

export const DEFAULT_VALUE: TopCandidatesContextValueType = {
  isFilterOpened: true,
  setIsFilterOpened: _noop,
  tab: SelectedTab.BEST_MATCH,
  setTab: _noop,
  selectedSeeker: undefined,
  setSelectedSeeker: _noop,
  selectedTopCandidate: undefined,
  setSelectedTopCandidate: _noop,
  filterParams: defaultFilterParams,
  setFilterParams: _noop,
  appliedFilter: [],
  setAppliedFilter: _noop,
  positionDocument: undefined,
};

const TopCandidatesContext = React.createContext<TopCandidatesContextValueType>(DEFAULT_VALUE);

export const TopCandidatesContextProvider: React.FC = ({ children }) => {
  const { positionId } = useParams<TopCandidateParams>();
  const positionDocument = usePositionDocumentSnapshot(positionId);
  const [isFilterOpened, setIsFilterOpened] = useState<boolean>(DEFAULT_VALUE.isFilterOpened);
  const [tab, setTab] = useState<SelectedTab>(DEFAULT_VALUE.tab);
  const [selectedTopCandidate, setSelectedTopCandidate] = useState<TopCandidateDataType | undefined>();
  const [selectedSeeker, setSelectedSeeker] = useState<Seeker | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParamsType>(DEFAULT_VALUE.filterParams);
  const [appliedFilter, setAppliedFilter] = useState<string[]>(DEFAULT_VALUE.appliedFilter);

  return (
    <TopCandidatesContext.Provider
      value={{
        isFilterOpened,
        setIsFilterOpened,
        tab,
        setTab,
        selectedSeeker,
        setSelectedSeeker,
        selectedTopCandidate,
        setSelectedTopCandidate,
        filterParams,
        setFilterParams,
        appliedFilter,
        positionDocument,
        setAppliedFilter,
      }}
    >
      {children}
    </TopCandidatesContext.Provider>
  );
};

export const useTopCandidatesContext = (): TopCandidatesContextValueType => useContext(TopCandidatesContext);
