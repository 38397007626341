export const hireFormContractWording = (locale: string | undefined): string => {
  if (locale !== 'fr') {
    return `The information submitted is currently being verified.

The position you applied for is a seasonal/casual position. You understand that the position offered is a contract with a start date and an end date.

The work schedule is established according to the needs of the store and according to the availability information you provided.

Please note that during the period of the contract you will not be eligible to take vacation time.`;
  }
  return `Les informations soumises sont actuellement en cours de vérification.

Le poste pour lequel vous avez candidaté est un poste saisonnier/occasionnel. Vous comprenez que le poste offert est un contrat avec une date de début et de fin d'emploi.

L'horaire de travail est établi selon les besoins du magasin et selon les plages horaires que vous nous avez fournies.

Veuillez noter que pendant la période du contrat, vous ne serez pas éligible à prendre des vacances.`;
};
