import _map from 'lodash/map';
import { useSubCollectionQuerySnapshot } from '../../../firebase/firestore/hooks';
import { Collections, SubCollections } from '../../../firebase/firestore/collections';
import { CalendarGroupEventType } from '../CalendarGroupEventType';
import { CalendarParticipantType } from './CalendarParticipantType';
import { EventCandidates } from '../../../firebase/firestore/documents/EventCandidates';
import { Query } from '../../../firebase/firestore/query/query';

const useEventCandidates = (groupEvent?: CalendarGroupEventType): CalendarParticipantType[] => {
  const invitedCandidates = useSubCollectionQuerySnapshot<EventCandidates>(
    Collections.GROUP_EVENTS,
    groupEvent?.groupEvent.id ?? '',
    SubCollections.CANDIDATES,
    [Query.field('invitationStatus').in(['PENDING', 'ACCEPTED'])],
  );

  if (groupEvent && groupEvent.groupEvent.id && invitedCandidates) {
    return _map(invitedCandidates, (c) => {
      return {
        label: c.email,
        value: c.email,
      } as CalendarParticipantType;
    });
  }

  return [];
};

export default useEventCandidates;
