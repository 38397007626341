/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo, useEffect, useState } from 'react';
import { Box, Flex, Heading, Divider, Input, InputGroup, InputLeftElement, Button, Badge, Tooltip } from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';
import { motion } from 'framer-motion';
import moment from 'moment-timezone';
import { Calendar as BigCalendar, EventProps, momentLocalizer, NavigateAction, View } from 'react-big-calendar';
import _isString from 'lodash/isString';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { RiUserStarLine, RiSearchLine, RiStackLine } from 'react-icons/ri';
import { ArrowBackIcon } from '@chakra-ui/icons';
import _uniqBy from 'lodash/uniqBy';
import _union from 'lodash/union';
import { Header } from '../../app/topBar/Header';
import { calendarCSS, calendarGridCSS } from './CalendarView.Style';
import { reactBigCalendarCSS } from './ReactBigCalendar.Style';
import { CalendarCreateEvent } from './events/CalendarCreateEvent';
import { BookingSchedule } from './scheduleSidebar/BookingSchedule';
import { ScheduleSidebarView } from './scheduleSidebar/ScheduleSidebarView';
import {
  ActiveAppointmentAvailabilitySlot,
  AppointmentAvailability,
  AvailableBookingWithPosition,
} from '../../firebase/firestore/documents/appointmentAvaiability';
import { CalendarEventType, OnSelectCalendarEventType } from './CalendarEventType';
import useEventDetail from './events/useEventDetail';
import { getUndefinedOrEventType } from './getUndefinedOrEventType';
import { CalendarScheduleTypeEnum } from './CalendarScheduleTypeEnum';
import { CalendarAvailabilityType } from './CalendarAvailabilityType';
import { ReactComponent as _IconEditAvailability } from '../../assets/img/edit-box-fill.svg';
import { CalendarEventDetail } from './events/CalendarEventDetail';
import { useStoreState, useStoreActions } from '../../models/hooks';
import { AppLoadingSpinner } from '../../app/components';
import { CalendarGroupEventType } from './CalendarGroupEventType';
import { CalendarDeleteEvent } from './events/CalendarDeleteEvent';
import { EventActionType } from './scheduleList/EventActionTypeEnum';
import { Recruiter } from '../../firebase/firestore/documents/recruiter';
import theme from '../../styles/customTheme';
import { Position } from '../../firebase/firestore/documents/position';
import { RecruiterList } from '../administration/recruiterCalendar/RecruiterList';
import { RecruiterCalendarPositionList } from '../administration/recruiterCalendar/RecruiterCalendarPositionList';
import { CalendarSidebarTabTypeEnum, RecruiterCalendarSidebarTabTypeEnum } from './CalendarSidebarTab';
import { RemoteConfigKey } from '../../firebase/remoteConfig';
import { useCurrentUserProfile } from '../../app/hooks/useCurrentUserProfile';
import colors from '../../styles/colors';
import { OtherCalendarEventDetailDrawer } from './otherCalendarEvents/OtherCalendarEventDetailDrawer';
import { CalendarAvailabilityDetail } from './availabilityDetail/CalendarAvailabilityDetail';
import useAccessibleAccounts from '../messaging/seeker/candidate/notes/useAccessibleAccounts';

require('moment/locale/en-gb.js');
require('moment/locale/fr.js');

const MotionBox = motion.custom(Box);
const IconEditAvailability = memo(_IconEditAvailability);

export type CalendarPanelProps = {
  todayDate: Date;
  recruiters: Recruiter[];
  administrationCalendar: boolean;
  handleCheckedRecruiters?: (recruiterIds: any) => void;
  checkedRecruiters?: string[];
  handleCheckedPositions?: (positionIds: any) => void;
  checkedPositions?: string[];
  positions?: Position[];
  activeTab?: CalendarSidebarTabTypeEnum | RecruiterCalendarSidebarTabTypeEnum;
  setActiveTab?: (activeTab: CalendarSidebarTabTypeEnum | RecruiterCalendarSidebarTabTypeEnum) => void;
  setActiveDateRange: (fromDate: Date, toDate: Date) => void;
};

export const CalendarPanel = ({
  todayDate,
  recruiters,
  administrationCalendar,
  handleCheckedRecruiters,
  checkedRecruiters,
  positions,
  handleCheckedPositions,
  checkedPositions,
  activeTab,
  setActiveTab,
  setActiveDateRange,
}: CalendarPanelProps): JSX.Element => {
  const { t, i18n } = useTranslation('calendar');
  const location = useLocation();
  const history: any = useHistory();
  const accessibleAccount = useAccessibleAccounts();

  const mergedRecruiters = _uniqBy(_union(recruiters, accessibleAccount), 'id');

  const {
    calendarPresenter,
    groupEventPresenter: calendarGroupEventsPresenter,
    calendarloading,
    groupEventsloading,
  } = useStoreState((s) => s.calendar);
  const accountStore = useStoreState((s) => s.app.accounts);

  // istanbul ignore next
  if (i18n.language === 'en') {
    moment.locale('en', {
      week: {
        dow: 1,
        doy: 1,
      },
    });
  }

  // istanbul ignore next
  if (i18n.language === 'fr') {
    moment.locale('fr');
  }

  const localizer = momentLocalizer(moment);

  const formats = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    dayFormat: (date: Date, culture: any, localizers: any) => `${localizers.format(date, 'ddd DD', culture)}`,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-shadow
    dayRangeHeaderFormat(_ref3: any, culture: any, local: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { start, end } = _ref3;

      // istanbul ignore next
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      return `${local.format(start, `${i18n.language === 'fr' ? 'DD MMMM' : 'MMM DD'}`, culture)} - ${local.format(
        end,
        `${i18n.language === 'fr' ? 'DD MMMM' : 'MMM DD'}`,
        culture,
      )}`;
    },
  };

  const [calendarCurrentView, setCalendarCurrentView] = React.useState<string>('week-view');
  const [selectedSchedule, setSelectedSchedule] = React.useState<AppointmentAvailability>();
  const [selectedEventForDetail, setSelectedEventForDetail] = React.useState<CalendarEventType | undefined>(undefined);
  const [activeDate, setActiveDate] = React.useState<Date>(todayDate);
  const [activeAvailability, setActiveAvailability] = React.useState<AppointmentAvailability | undefined>();

  const [isAppointmentDrawerOpen, setIsAppointmentDrawerOpen] = React.useState<boolean>(!!location.state);
  const [activeAppointmentSlot, setActiveAppointmentSlot] = React.useState<ActiveAppointmentAvailabilitySlot | undefined>();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isAvailabilityDetailDrawerOpen, setIsAvailabilityDetailDrawerOpen] = React.useState<boolean>(false);
  const [isEventDetailDrawerOpen, setIsEventDetailDrawerOpen] = React.useState<boolean>(false);
  const [isGoogleEventDetailDrawerOpen, setIsGoogleEventDetailDrawerOpen] = React.useState<boolean>(false);
  const [isEventDeleteAlertDialogOpen, setIsEventDeleteAlertDialogOpen] = React.useState<boolean>(false);
  const [groupEventEnabled, setGroupEvenEnabled] = useState<boolean | undefined>(false);
  const [searchRecruiter, setSearchRecruiter] = useState<string>('');
  const [searchPosition, setSearchPosition] = useState<string>('');
  const defaultAccount = useStoreState((s) => s.app.user?.account);
  const isOperator = useStoreState((s) => s.app.user?.operator?.enabled);

  const isPositionFilterOnSidebarEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_POSITION_FILTER_ON_SIDEBAR_OF_ADMINISTRATION_APPOINTMENT_CALENDAR];
  });

  const { googleCalendarEvent, microsoftCalendarEvent, currentQuarterOnCalendar } = useStoreState((state) => state.calendar);
  const { setCurrentQuarterOnCalendar } = useStoreActions((action) => action.calendar);

  const { currentUserProfile } = useCurrentUserProfile();

  // to reset location.state value
  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, [history]);

  useEffect(() => {
    // istanbul ignore next
    if (accountStore) {
      setGroupEvenEnabled(accountStore?.configuration?.groupEventEnabled);
    } else {
      setGroupEvenEnabled(false);
    }
  }, [accountStore]);

  const recruitersColors = ['blue', 'red', 'yellow', 'orange', 'green', 'teal', 'cyan', 'purple', 'pink', 'gray'];
  const gmailColors = ['green', 'orange', 'pink', 'cyan', 'purple'];
  const microscopeColors = ['red', 'teal', 'yellow', 'gray', 'blue'];

  const selectedEvent = useEventDetail(getUndefinedOrEventType(selectedEventForDetail));

  const bookings: AvailableBookingWithPosition[] = calendarPresenter.listBooking(activeAvailability?.id ?? activeDate);
  const groupEvents = calendarGroupEventsPresenter.listGroupEventByDate(activeDate);

  const listFormattedDayEvents: CalendarEventType[] | CalendarGroupEventType[] =
    groupEventEnabled && !administrationCalendar
      ? [...calendarPresenter.listFormattedBookingForCalendar(), ...calendarGroupEventsPresenter.listGroupEventByDate(activeDate)]
      : [...calendarPresenter.listFormattedBookingForCalendar()];

  const listFormattedWeekEvents: CalendarEventType[] | CalendarGroupEventType[] =
    groupEventEnabled && !administrationCalendar
      ? [
          ...calendarPresenter.listFormattedBookingForCalendar(),
          ...calendarGroupEventsPresenter.listFormattedGroupEventsForCalendarWeekView(),
        ]
      : [...calendarPresenter.listFormattedBookingForCalendar()];

  const listFormattedMonthEvents: CalendarEventType[] | CalendarGroupEventType[] =
    groupEventEnabled && !administrationCalendar
      ? [
          ...calendarPresenter.listFormattedBookingForCalendar(),
          ...calendarGroupEventsPresenter.listFormattedGroupEventsForCalendarMonthView(),
        ]
      : [...calendarPresenter.listFormattedBookingForCalendar()];

  // cannot mock calendar view click.
  // istanbul ignore next
  const handleSelect = ({ start, end }: OnSelectCalendarEventType) => {
    let date: Date;
    setActiveAvailability(undefined);
    if (_isString(end)) date = moment(end).toDate();
    else date = end;
    setActiveDate(date);
    if (moment(start).diff(new Date(), 'minutes') > 1) {
      setIsAppointmentDrawerOpen(true);
      setActiveAppointmentSlot({ fromDate: moment(start).toDate(), toDate: moment(end).toDate() });
    }
  };

  // Cannot mock calender booking event click,
  // istanbul ignore next
  const handleEventOfDate = (event: CalendarEventType) => {
    let date: Date;
    if (_isString(event.start)) date = moment(event.start).toDate();
    else date = event.start;
    if (activeTab === RecruiterCalendarSidebarTabTypeEnum.SCHEDULE) {
      setActiveDate(date);
      if (event.resource.type === CalendarScheduleTypeEnum.AVAILABILITY) {
        const { availability } = event.resource as CalendarAvailabilityType;
        setActiveAvailability(availability);
        setIsAvailabilityDetailDrawerOpen(true);
      }
    }
    if (
      !administrationCalendar &&
      (event.resource.type === CalendarScheduleTypeEnum.GOOGLE_EVENT ||
        event.resource.type === CalendarScheduleTypeEnum.OUTLOOK_EVENT)
    ) {
      setSelectedEventForDetail(event);
      setIsGoogleEventDetailDrawerOpen(true);
    }
  };

  const moveCurrentUserIdToFirstIndex = (arr: Recruiter[], fromIndex: number, toIndex: number): Recruiter[] => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  };

  const getStyleForAvailabilityByUser = (userId: string) => {
    if (administrationCalendar && mergedRecruiters.length > 0) {
      const indexOfCurrentUser = mergedRecruiters.findIndex((r) => r.id === currentUserProfile?.id) % 10;
      if (indexOfCurrentUser < 0) {
        return recruitersColors[0];
      }
      const reOrderedRecruiter = moveCurrentUserIdToFirstIndex(mergedRecruiters, indexOfCurrentUser, 0);
      const indexOfRecruiter = reOrderedRecruiter.findIndex((r) => r.id === userId) % 10;
      return recruitersColors[indexOfRecruiter];
    }
    return recruitersColors[0];
  };

  const getStyleForGmail = (email: string | undefined) => {
    const googleConnectedAccount = _sortBy(currentUserProfile?.googleCalendar, 'gmail');
    const indexOfEmail = googleConnectedAccount.findIndex((r) => r.gmail === email) % 5;
    return gmailColors[indexOfEmail > 0 ? indexOfEmail : 0];
  };

  const getStyleForOutLook = (email: string | undefined) => {
    const microsoftConnectedAccount = _sortBy(currentUserProfile?.microsoftCalendar, 'microsoftMail');
    const indexOfEmail = microsoftConnectedAccount.findIndex((r) => r.microsoftMail === email) % 5;
    return microscopeColors[indexOfEmail > 0 ? indexOfEmail : 0];
  };

  const handleEventType = (event: CalendarEventType) => {
    if (event.resource.type === CalendarScheduleTypeEnum.AVAILABILITY) {
      const { availability } = event.resource as CalendarAvailabilityType;
      const colorCode = getStyleForAvailabilityByUser(availability.userId);
      const xyz = (theme.colors as unknown) as { [key: string]: string };
      let actBorderColor;
      let gradinetBackground;
      if (!administrationCalendar) {
        actBorderColor =
          // eslint-disable-next-line no-nested-ternary
          availability.userId === currentUserProfile?.id ? colors.blue[500] : colors.green[500];
        if (isOperator) {
          // eslint-disable-next-line prefer-destructuring
          actBorderColor = colors.blue[500];
          gradinetBackground =
            defaultAccount === availability.accountId
              ? colors.blue[50]
              : `repeating-linear-gradient(    45deg,    ${colors.gray[50]} 0px,    #ffffff 10px,     ${colors.gray[50]} 10px,     ${colors.gray[50]} 10px  )`;
        }
      } else {
        // istanbul ignore else
        // eslint-disable-next-line no-lonely-if
        if (event.resource.userId) {
          gradinetBackground =
            // eslint-disable-next-line no-nested-ternary
            activeTab === CalendarSidebarTabTypeEnum.RECRUITERS
              ? defaultAccount !== availability.accountId
                ? `repeating-linear-gradient(    45deg,    ${colors.gray[50]} 0px,    #ffffff 10px,     ${colors.gray[50]} 10px,     ${colors.gray[50]} 10px  )`
                : // istanbul ignore next
                  xyz[colorCode][50]
              : colors.green[50];

          actBorderColor =
            // eslint-disable-next-line no-nested-ternary
            activeTab === CalendarSidebarTabTypeEnum.RECRUITERS
              ? xyz[colorCode][500]
              : // eslint-disable-next-line no-nested-ternary
              availability.userId === currentUserProfile?.id
              ? defaultAccount === availability.accountId
                ? colors.blue[500]
                : // istanbul ignore next
                  colors.gray[500]
              : // istanbul ignore next
                colors.green[500];
        }
      }

      const classNameForAvailabilitySlot =
        // eslint-disable-next-line no-nested-ternary
        administrationCalendar && activeTab === CalendarSidebarTabTypeEnum.RECRUITERS
          ? colorCode
          : availability.userId === currentUserProfile?.id
          ? 'blue'
          : 'green';

      return {
        className: `availability-slot admin-view ${classNameForAvailabilitySlot}`,
        style: {
          marginBottom: '2px',
          borderLeft: `3px solid ${actBorderColor}`,
          background: gradinetBackground,
        },
      };
    }
    if (event.resource.type === CalendarScheduleTypeEnum.GROUP_EVENT) {
      return { className: 'groupevent-slot' };
    }
    if (
      event.resource.type === CalendarScheduleTypeEnum.GOOGLE_EVENT ||
      event.resource.type === CalendarScheduleTypeEnum.OUTLOOK_EVENT
    ) {
      const colorCode =
        event.resource.type === CalendarScheduleTypeEnum.GOOGLE_EVENT
          ? getStyleForGmail(event.email)
          : getStyleForOutLook(event.email);
      const xyz = (theme.colors as unknown) as { [key: string]: string };
      const isOwnEvent = event.email === event.creator?.email;

      return {
        className: 'google-event-slot',
        style: {
          backgroundColor: isOwnEvent ? xyz[colorCode][50] : colors.white,
          marginBottom: '2px',
          fontSize: '13px',
          lineHeight: '1.5',
          minHeight: '45px',
          border: `1px solid ${isOwnEvent ? xyz[colorCode][200] : xyz[colorCode][300]}`,
          color: isOwnEvent ? colors.gray[600] : xyz[colorCode][300],
        },
      };
    }
    return { className: `booking-slot ${administrationCalendar ? 'admin-booking-slot' : ''}` };
  };

  // istanbul ignore next
  const handleEvent = (e: CalendarEventType, action: string) => {
    setSelectedEventForDetail(e);
    if (action === EventActionType.edit) {
      setIsOpen(true);
    }
    if (action === EventActionType.delete) {
      setIsEventDeleteAlertDialogOpen(true);
    }
    if (action === EventActionType.view) {
      setIsEventDetailDrawerOpen(true);
    }
  };

  const handleCreateNewEvent = () => {
    setSelectedEventForDetail(undefined);
    setIsOpen(true);
  };

  const openAppointmentDrawerUpdateBooking = (availability: AppointmentAvailability) => {
    setSelectedSchedule(availability);
    setIsAppointmentDrawerOpen(true);
  };

  /* istanbul ignore next */
  const openAppointmentDrawerCreateBooking = () => {
    setSelectedSchedule(undefined);
    setIsAppointmentDrawerOpen(true);
  };

  const closeAppointmentDrawer = () => {
    setIsAppointmentDrawerOpen(false);
  };

  const getMeetingCount = (availability: AppointmentAvailability): number => {
    return activeTab !== CalendarSidebarTabTypeEnum.RECRUITERS
      ? calendarPresenter.listBookingByAppointmentIdAndPositionsId(availability?.id, checkedPositions).length
      : calendarPresenter.listBookingByAppointmentId(availability?.id).length;
  };

  const DayEvent = ({ event }: EventProps<CalendarEventType>) => {
    const { availability } = event.resource as CalendarAvailabilityType;
    return (
      <span data-testid="DayCell">
        {event.title === 'Availability' ? t('calendar.availability') : event.title}
        {event.bookingCapacity ? ` | ${event.bookingCapacity}` : ''}
        <br />
        {event.title === 'Availability' && getMeetingCount(availability) > 0 && (
          <Badge
            position="absolute"
            top="4px"
            right="5px"
            colorScheme="blue"
            variant="solid"
            fontSize="sm"
            textAlign="center"
            lineHeight="20px"
            borderRadius="50%"
            width="20px"
            height="20px"
          >
            {getMeetingCount(availability)}
          </Badge>
        )}
      </span>
    );
  };

  const WeekEvent = ({ event }: EventProps<CalendarEventType>) => {
    const { availability } = event.resource as CalendarAvailabilityType;
    return (
      <span data-testid="WeekCell">
        {event.resource.type === CalendarScheduleTypeEnum.AVAILABILITY ? t('calendar.availability') : event.title}
        {event.bookingCapacity ? ` | ${event.bookingCapacity}` : ''}
        <br />
        {event.resource.type === CalendarScheduleTypeEnum.AVAILABILITY && getMeetingCount(availability) > 0 && (
          <Badge
            position="absolute"
            top="4px"
            right="5px"
            colorScheme="blue"
            variant="solid"
            fontSize="sm"
            textAlign="center"
            lineHeight="20px"
            borderRadius="50%"
            width="20px"
            height="20px"
          >
            {getMeetingCount(availability)}
          </Badge>
        )}
      </span>
    );
  };

  const MonthEvent = ({ event }: EventProps<CalendarEventType>) => {
    if (event.resource.type === CalendarScheduleTypeEnum.AVAILABILITY) {
      const { availability, bookedTime, availabilityTime } = event.resource as CalendarAvailabilityType;
      return (
        <Flex className="calendar-event-cell" data-testid="MonthCell">
          <Box width="calc(100% - 25px)" mr={2}>
            <span className="booked-time">{`${t('calendar.totalBooked')} - ${bookedTime} ${t('calendar.hrs')}`}</span>
            <span className="available-time">{`${t('calendar.availability')} - ${availabilityTime} ${t('calendar.hrs')}`}</span>
          </Box>
          <Box>
            {event.title === 'Availability' && getMeetingCount(availability) > 0 && (
              <Badge
                colorScheme="blue"
                variant="solid"
                fontSize="sm"
                textAlign="center"
                lineHeight="20px"
                borderRadius="50%"
                width="20px"
                height="20px"
              >
                {getMeetingCount(availability)}
              </Badge>
            )}
            <span className="meeting-number">
              {!administrationCalendar && availability.userId === currentUserProfile?.id && (
                <Box
                  cursor="pointer"
                  className="action-btns"
                  onClick={() => openAppointmentDrawerUpdateBooking(availability)}
                  data-testid="EditScheduleBtn"
                  position="absolute"
                  right="5px"
                  bottom="10px"
                >
                  <IconEditAvailability width={15} height={15} />
                </Box>
              )}
            </span>
          </Box>
        </Flex>
      );
    }

    if (event.resource.type === CalendarScheduleTypeEnum.GROUP_EVENT) {
      return (
        <Box className="calendar-event-cell">
          <span className="meeting-number">View Group Event</span>
        </Box>
      );
    }

    if (
      event.resource.type === CalendarScheduleTypeEnum.GOOGLE_EVENT ||
      event.resource.type === CalendarScheduleTypeEnum.OUTLOOK_EVENT
    ) {
      return (
        <Box className="calendar-event-cell">
          <span className="event title">{event.title}</span>
        </Box>
      );
    }

    return <span />;
  };

  /* istanbul ignore next */
  const setCurrentDateRangeForViews = (givenDate: Date, view: View): void => {
    let fromDate;
    let toDate;
    switch (view) {
      case 'month':
        fromDate = moment(moment(givenDate).startOf('month').subtract(6, 'days').format('YYYY-MM-DDT00:00:00')).toDate();
        toDate = moment(moment(givenDate).endOf('month').add(6, 'days').format('YYYY-MM-DDT23:59:59')).toDate();
        setActiveDateRange(fromDate, toDate);
        break;
      case 'day':
        fromDate = moment(moment(givenDate).startOf('day').format('YYYY-MM-DDT00:00:00')).toDate();
        toDate = moment(moment(givenDate).endOf('day').format('YYYY-MM-DDT23:59:59')).toDate();
        setActiveDateRange(fromDate, toDate);
        break;
      case 'week':
        fromDate = moment(moment(givenDate).startOf('week').format('YYYY-MM-DDT00:00:00')).toDate();
        toDate = moment(moment(givenDate).endOf('week').format('YYYY-MM-DDT23:59:59')).toDate();
        setActiveDateRange(fromDate, toDate);
        break;

      default:
        fromDate = givenDate;
        toDate = givenDate;
        setActiveDateRange(fromDate, toDate);
    }
  };

  /* istanbul ignore next */
  const handleNavigation = (newDate: Date, view: View, action: NavigateAction) => {
    setCurrentDateRangeForViews(newDate, view);
    if (moment(newDate).quarter() !== currentQuarterOnCalendar.quarter) {
      setCurrentQuarterOnCalendar({ quarter: moment(newDate).quarter(), date: newDate });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (action === 'TODAY') {
      setActiveDate(newDate);
    }
  };

  const handleCalendarView = (view: View) => {
    setCalendarCurrentView(`${view}-view`);
  };

  // istanbul ignore next
  const displayCurrentEventsViaView = () => {
    if (calendarCurrentView === 'day-view') {
      return listFormattedDayEvents;
    }
    if (calendarCurrentView === 'week-view') {
      return listFormattedWeekEvents;
    }
    if (calendarCurrentView === 'month-view') {
      return listFormattedMonthEvents;
    }
    return listFormattedWeekEvents;
  };

  const calendarHeaderBtnTranslations = () => {
    // istanbul ignore next
    if (i18n.language === 'fr') {
      return {
        today: `Aujourd'hui`,
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        showMore: (total: number) => `+${total} de plus`,
      };
    }
    return {
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
    };
  };

  // istanbul ignore next
  /* eslint-disable */
  const redirectToPreviousPageHandler = () => {
    if (location.state) {
      history.goBack();
    }
  };
  /* eslint-enable */

  const showEvents = () => {
    if (!_isNil(activeTab)) {
      if (activeTab === CalendarSidebarTabTypeEnum.RECRUITERS) {
        return displayCurrentEventsViaView();
      }
      if (!_isNil(checkedPositions)) {
        return calendarPresenter.listFormattedAvailableBookingWithPositionForCalendar(checkedPositions);
      }
      return [];
    }
    return displayCurrentEventsViaView();
  };

  return (
    <MotionBox css={[calendarGridCSS, reactBigCalendarCSS]} initial="initial" data-testid="CalendarPanel">
      {isOpen && (
        <CalendarCreateEvent
          calendarEvent={selectedEventForDetail}
          selectedEvent={selectedEvent}
          isOpen={isOpen}
          onClose={
            // istanbul ignore next
            () => setIsOpen(false)
          }
          allRecruiters={mergedRecruiters}
        />
      )}
      {
        // istanbul ignore next
        isEventDetailDrawerOpen && (
          <CalendarEventDetail
            selectedEvent={selectedEvent}
            isOpen={isEventDetailDrawerOpen}
            onClose={() => setIsEventDetailDrawerOpen(false)}
          />
        )
      }
      {
        // istanbul ignore next
        isAvailabilityDetailDrawerOpen && activeAvailability && (
          <CalendarAvailabilityDetail
            selectedAvailability={activeAvailability}
            bookingList={calendarPresenter.listBookingByAppointmentId(activeAvailability?.id)}
            recruiterInfo={mergedRecruiters.filter((rec) => rec.id === activeAvailability?.userId)[0]}
            isOpen={isAvailabilityDetailDrawerOpen}
            onClose={() => setIsAvailabilityDetailDrawerOpen(false)}
          />
        )
      }
      {
        // istanbul ignore next
        isGoogleEventDetailDrawerOpen && selectedEventForDetail && (
          <OtherCalendarEventDetailDrawer
            eventDetail={selectedEventForDetail}
            isOpen={isGoogleEventDetailDrawerOpen}
            onClose={() => setIsGoogleEventDetailDrawerOpen(false)}
          />
        )
      }
      {
        // istanbul ignore next
        isEventDeleteAlertDialogOpen && (
          <CalendarDeleteEvent
            isOpen={isEventDeleteAlertDialogOpen}
            onClose={() => setIsEventDeleteAlertDialogOpen(false)}
            selectedEvent={selectedEvent}
          />
        )
      }
      {isAppointmentDrawerOpen && (
        <BookingSchedule
          isOpen={isAppointmentDrawerOpen}
          onClose={() => {
            closeAppointmentDrawer();
            setActiveAppointmentSlot(undefined);
          }}
          redirectToPreviousPageHandler={redirectToPreviousPageHandler}
          activeAppointmentSlot={activeAppointmentSlot}
          scheduleDetail={selectedSchedule}
        />
      )}
      <Header data-testid="Header" showMenu showSearchSort={false} />
      <Box css={calendarCSS}>
        <Flex w="100%" h="100%">
          <Box className="calendar-sidebar" w="33%" maxW="325px" minW="225px" borderWidth="1px" borderColor="gray.50">
            {administrationCalendar && handleCheckedRecruiters && handleCheckedPositions && setActiveTab ? (
              <Box>
                <Flex px={4} py={3} justifyContent="space-between" borderBottomWidth="1px" borderBottomColor="gray.50">
                  <Button
                    _focus={{ boxShadow: 'none' }}
                    colorScheme="blue.default"
                    color={colors.blue.default}
                    variant="ghost"
                    fontSize="sm"
                    data-testid="BackBtn"
                    pl={0}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                      history.push({ pathname: '/administration' });
                    }}
                    leftIcon={<ArrowBackIcon />}
                  >
                    {t('administration:calendar.backBtnText')}
                  </Button>
                  <Button
                    _focus={{ boxShadow: 'none' }}
                    colorScheme="blue.default"
                    color={colors.blue.default}
                    fontSize="sm"
                    variant="outline"
                    borderRadius={3}
                    onClick={openAppointmentDrawerCreateBooking}
                    data-testid="OpenDrawerBtn"
                  >
                    {t('availabilityForm.myAvailabilityLabel')}
                  </Button>
                </Flex>
                <Flex>
                  <Heading
                    cursor="pointer"
                    d="flex"
                    flex={1}
                    alignItems="flex-start"
                    as="h4"
                    size="sm"
                    px={4}
                    py={3}
                    borderBottomWidth="2px"
                    borderColor={
                      activeTab === CalendarSidebarTabTypeEnum.RECRUITERS && isPositionFilterOnSidebarEnabled
                        ? 'blue.500'
                        : 'gray.50'
                    }
                    color={
                      activeTab === CalendarSidebarTabTypeEnum.RECRUITERS && isPositionFilterOnSidebarEnabled
                        ? 'blue.500'
                        : 'gray.600'
                    }
                    data-testid="RecruiterTabHeader"
                    onClick={() => {
                      setActiveTab(CalendarSidebarTabTypeEnum.RECRUITERS);
                      handleCheckedRecruiters([currentUserProfile?.id]);
                    }}
                  >
                    <Box as="span" mr={2}>
                      <RiUserStarLine style={{ fontSize: '18px' }} />
                    </Box>
                    <Box as="span" lineHeight="1.1">
                      {t('administration:calendar.sidebarTitle')}
                    </Box>
                  </Heading>
                  {isPositionFilterOnSidebarEnabled && (
                    <Tooltip label={t('calendar:availabilityForm.myPositionsToolTip')} placement="right-end">
                      <Heading
                        cursor="pointer"
                        d="flex"
                        flex={1}
                        alignItems="flex-start"
                        as="h4"
                        size="sm"
                        px={4}
                        py={3}
                        borderBottomWidth="2px"
                        borderColor={
                          activeTab !== CalendarSidebarTabTypeEnum.RECRUITERS && isPositionFilterOnSidebarEnabled
                            ? 'blue.500'
                            : 'gray.50'
                        }
                        color={
                          activeTab !== CalendarSidebarTabTypeEnum.RECRUITERS && isPositionFilterOnSidebarEnabled
                            ? 'blue.500'
                            : 'gray.600'
                        }
                        onClick={() => {
                          setActiveTab(CalendarSidebarTabTypeEnum.FOLLOWED_POSITIONS);
                          handleCheckedRecruiters([currentUserProfile?.id]);
                        }}
                        data-testid="PositionTabHeader"
                      >
                        <Box alignItems="center" lineHeight="1.1">
                          <Flex>
                            <Box as="span" mr={2}>
                              <RiStackLine style={{ fontSize: '18px' }} />
                            </Box>
                            <Box as="span" fontWeight="bold">
                              {t('administration:calendar.positionTitle')}
                            </Box>
                          </Flex>
                        </Box>
                      </Heading>
                    </Tooltip>
                  )}
                </Flex>
                {activeTab !== CalendarSidebarTabTypeEnum.RECRUITERS && isPositionFilterOnSidebarEnabled && (
                  <Box textAlign="right">
                    <Flex d="inline-flex" width="50%">
                      <Box
                        px={5}
                        py={1}
                        flex={1}
                        cursor="pointer"
                        fontSize="sm"
                        onClick={() => {
                          setActiveTab(CalendarSidebarTabTypeEnum.FOLLOWED_POSITIONS);
                        }}
                        color={activeTab === CalendarSidebarTabTypeEnum.FOLLOWED_POSITIONS ? 'blue.500' : ''}
                        bg={activeTab === CalendarSidebarTabTypeEnum.FOLLOWED_POSITIONS ? 'blue.40' : ''}
                        borderLeft="1px solid"
                        borderColor="gray.100"
                        data-testid="FollowedPositionMenuBtn"
                      >
                        {t('administration:calendar.followed')}
                      </Box>
                      <Box
                        px={5}
                        py={1}
                        flex={1}
                        cursor="pointer"
                        fontSize="sm"
                        onClick={() => {
                          setActiveTab(CalendarSidebarTabTypeEnum.ALL_POSITIONS);
                        }}
                        color={activeTab === CalendarSidebarTabTypeEnum.ALL_POSITIONS ? 'blue.500' : ''}
                        bg={activeTab === CalendarSidebarTabTypeEnum.ALL_POSITIONS ? 'blue.40' : ''}
                        borderLeft="1px solid"
                        borderColor="gray.100"
                        data-testid="AllPositionMenuBtn"
                      >
                        {t('administration:calendar.all')}
                      </Box>
                    </Flex>
                    <Divider borderColor="gray.100" />
                  </Box>
                )}
                <Box p={[5, 4]}>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" p={0} style={{ color: '#CFD8DC', width: '1.8rem' }}>
                      <RiSearchLine />
                    </InputLeftElement>
                    <Input
                      data-testid="SearchRecruiterInput"
                      value={activeTab === CalendarSidebarTabTypeEnum.RECRUITERS ? searchRecruiter : searchPosition}
                      placeholder={
                        activeTab === CalendarSidebarTabTypeEnum.RECRUITERS
                          ? t('administration:calendar.sidebarRecruiterSearchPlaceholder')
                          : t('administration:calendar.sidebarPositionSearchPlaceholder')
                      }
                      onChange={(e) =>
                        activeTab === CalendarSidebarTabTypeEnum.RECRUITERS
                          ? setSearchRecruiter(e.target.value)
                          : setSearchPosition(e.target.value)
                      }
                      style={{ paddingLeft: '1.8rem', paddingRight: '0.5rem' }}
                    />
                  </InputGroup>
                </Box>
                <Divider borderColor="gray.100" />
                <Flex justifyContent="end" px={4} py={2}>
                  <Button
                    colorScheme="blue"
                    variant="link"
                    size="xs"
                    data-testid="ClearBtn"
                    onClick={() =>
                      activeTab === CalendarSidebarTabTypeEnum.RECRUITERS && handleCheckedRecruiters && handleCheckedPositions
                        ? handleCheckedRecruiters([currentUserProfile?.id])
                        : handleCheckedPositions([])
                    }
                    _focus={{
                      boxShadow: 'none',
                    }}
                  >
                    {t('administration:calendar.sidebarPositionClearAll')}
                  </Button>
                </Flex>
                <Divider borderColor="gray.100" />
                {activeTab !== CalendarSidebarTabTypeEnum.RECRUITERS && handleCheckedPositions && (
                  <RecruiterCalendarPositionList
                    positions={positions}
                    searchText={searchPosition}
                    handleCheckedPositions={handleCheckedPositions}
                    checkedPositions={checkedPositions}
                  />
                )}
                {activeTab === CalendarSidebarTabTypeEnum.RECRUITERS && handleCheckedRecruiters && (
                  <RecruiterList
                    recruiters={mergedRecruiters}
                    getStyleForAvailabilityByUser={getStyleForAvailabilityByUser}
                    searchText={searchRecruiter}
                    handleCheckedRecruiters={handleCheckedRecruiters}
                    checkedRecruiters={checkedRecruiters}
                  />
                )}
              </Box>
            ) : (
              setActiveTab && (
                <ScheduleSidebarView
                  eventList={{ bookings, groupEvents, activeDate }}
                  createNewEvent={handleCreateNewEvent}
                  onOpen={openAppointmentDrawerCreateBooking}
                  handleEvent={handleEvent}
                  positions={positions}
                  handleCheckedPositions={handleCheckedPositions}
                  checkedPositions={checkedPositions}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab as RecruiterCalendarSidebarTabTypeEnum}
                  data-testid="SidebarDrawerTest"
                />
              )
            )}
          </Box>
          <Box className="calendar-content" w="66%" flex="1">
            {calendarloading || groupEventsloading ? (
              <AppLoadingSpinner />
            ) : (
              <Box p={2} className="calendar-wrapper" data-testid="CalendarView">
                <BigCalendar
                  formats={formats}
                  localizer={localizer}
                  selectable
                  events={
                    !administrationCalendar ? [...showEvents(), ...googleCalendarEvent, ...microsoftCalendarEvent] : showEvents()
                  }
                  step={30}
                  timeslots={2}
                  scrollToTime={moment('8:00 AM', 'h:mm A').toDate()}
                  defaultView="week"
                  views={['month', 'week', 'day']}
                  onSelectEvent={handleEventOfDate}
                  onSelectSlot={handleSelect}
                  eventPropGetter={handleEventType}
                  onNavigate={handleNavigation}
                  components={{
                    day: {
                      event: DayEvent,
                    },
                    week: {
                      event: WeekEvent,
                    },
                    month: {
                      event: MonthEvent,
                    },
                  }}
                  onView={handleCalendarView}
                  className={calendarCurrentView}
                  messages={calendarHeaderBtnTranslations()}
                />
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
    </MotionBox>
  );
};
