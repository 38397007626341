import { Notification } from '../../../firebase/firestore/documents/notification';
import { Collections } from '../../../firebase/firestore/collections';
import { Query } from '../../../firebase/firestore/query/query';
import { useQuerySnapshot } from '../../../firebase/firestore/hooks';
import { useCurrentUserProfile } from '../../hooks/useCurrentUserProfile';
import { NotificationTypes } from '../../../firebase/firestore/documents/notificationTypes';
import { NotificationTypeEnum } from './NotificationTypeEnum';
import { OrderByDirection } from '../../../firebase/firestore/query/criteria';

const useNotifications = (tabType: NotificationTypeEnum, notificationsLimit?: number): Notification[] | undefined => {
  const { currentUserProfile } = useCurrentUserProfile();

  const groupEventFilterItems = [
    NotificationTypes.GROUP_EVENT_INVITATION,
    NotificationTypes.GROUP_EVENT_DELETED,
    NotificationTypes.GROUP_EVENT_REMINDER,
  ];

  const interviewFilterItems = [
    NotificationTypes.EMPLOYERS_VIDEO_INTERVIEW_REMINDER,
    NotificationTypes.SEEKER_VIDEO_INTERVIEW_REMINDER,
  ];

  const backgroundCheckFilterItems = [
    NotificationTypes.BACKGROUND_CHECK_INITIATED,
    NotificationTypes.BACKGROUND_CHECKED,
    NotificationTypes.BACKGROUND_CHECKED_REMINDER,
  ];

  const otherNotificationFilterItems = [
    NotificationTypes.POSITION_INACTIVE_REMINDER_NOTIFICATION,
    NotificationTypes.INACTIVE_POSITION_CLOSED_NOTIFICATION,
  ];
  const languageEvaluationFilterItems = [NotificationTypes.NOTIFICATION_AUDIO_LANGUAGE_EVALUATION];

  const lowAvailabilityFilterItems = [NotificationTypes.RECRUITER_LOW_AVAILABILITY_FOR_ADMIN];

  const hireFormFilterItems = [
    NotificationTypes.SEEKER_HIRE_DETAILS_HELP_REQUESTED,
    NotificationTypes.SEEKER_HIRE_DETAILS_REQUEST_DECLINED,
    NotificationTypes.SEEKER_HIRE_DETAILS_RESUBMISSION_REQUESTED,
    NotificationTypes.SEEKER_HIRE_DETAILS_APPROVED,
    NotificationTypes.SEEKER_HIRE_DETAILS_REJECTED,
    NotificationTypes.SEEKER_HIRE_DETAILS_FILLED,
  ];

  const modernHireFormFilterItems = [
    NotificationTypes.MODERN_HIRE_ASSESSMENT_FAILED,
    NotificationTypes.MODERN_HIRE_ASSESSMENT_IN_PROGRESS,
    NotificationTypes.MODERN_HIRE_ASSESSMENT_PASSED,
    NotificationTypes.MODERN_HIRE_ASSESSMENT_STARTED,
  ];

  const requisitionIdAddedFilterItems = [
    NotificationTypes.REQUISITION_ID_ADDED_NOTIFICATION,
    NotificationTypes.REQUISITION_UNAVAILABLE,
    NotificationTypes.REQUISITION_POSITION_OPENED,
  ];

  const getFilterItems = (tab: NotificationTypeEnum) => {
    switch (tab) {
      case NotificationTypeEnum.GROUP_EVENT:
        return groupEventFilterItems;
      case NotificationTypeEnum.INTERVIEWS:
        return interviewFilterItems;
      case NotificationTypeEnum.BACKGROUND_CHECK:
        return backgroundCheckFilterItems;
      case NotificationTypeEnum.LOW_AVAILABILITY:
        return lowAvailabilityFilterItems;
      case NotificationTypeEnum.HIRE_FORM:
        return hireFormFilterItems;
      case NotificationTypeEnum.MODERN_HIRE_FORM:
        return modernHireFormFilterItems;
      case NotificationTypeEnum.REQUISITION:
        return requisitionIdAddedFilterItems;
      case NotificationTypeEnum.OTHERS:
        return otherNotificationFilterItems;
      case NotificationTypeEnum.AUDIO_LANGUAGE_EVALUATION:
        return languageEvaluationFilterItems;
      default:
        return groupEventFilterItems;
    }
  };

  const notifications: Notification[] | undefined = useQuerySnapshot<Notification>(Collections.NOTIFICATIONS, [
    Query.field<Notification>('user').equals(currentUserProfile?.id),
    Query.field<Notification>('notificationType').in(getFilterItems(tabType)),
    Query.orderBy<Notification>('sentAt', OrderByDirection.desc),
    Query.limit(notificationsLimit || 100),
  ]);

  return notifications;
};

export default useNotifications;
