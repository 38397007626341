/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ChangeEvent } from 'react';
import {
  Textarea,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Input,
  NumberInput,
  NumberInputField,
  Text,
  Box,
  Select,
  Checkbox,
  useDisclosure,
  Flex,
  Tooltip,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from '../../../../../models/hooks';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { confirmationModalHeaderCSS } from './ConfirmationModal.styles';
import { ProposedSalary } from '../../../../../firebase/firestore/documents/position';
import { getTranslatedNumber, getTranslatedStringValue } from '../../../../../utils/localizedNumber';
import { SelectRequisition } from '../SelectRequisition';
import { BusinessSelectType, PositionSelectType } from '../../../../administration/positionManagement/PositionType';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';

export type HireConfirmationModalProps = {
  isOpen: boolean;
  disabled: boolean;
  positionSalaryDetails: ProposedSalary | undefined;
  onClose: () => void;
  requisitionEnabled?: boolean;
};

export const HireConfirmationModal = ({
  positionSalaryDetails,
  isOpen,
  disabled,
  onClose,
  requisitionEnabled,
}: HireConfirmationModalProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedCandidate: selectedCandidateFromContext } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const selectedCandidate = selectedCandidateFromContext || selectedEngagedCandidate;

  const [noteContent, setNoteContent] = React.useState('');
  const [salary, setSalary] = React.useState('0');
  const [prevData, setPrevData] = React.useState<string>('');
  const [unit, setUnit] = React.useState('hourly');
  const [customUnit, setCustomUnit] = React.useState('');
  const [disabledButton, setButtonDisabled] = React.useState(true);
  const [sendMessageToCandidates, setSendMessageToCandidates] = React.useState(true);
  const [autoDismiss, setAutoDismiss] = React.useState(true);
  const [updatedPositionId, setUpdatedPositionId] = React.useState<string | undefined>(
    selectedCandidate?.newHireData?.positionId,
  );
  const [updatedBusinessId, setUpdatedBusinessId] = React.useState<string | undefined>(
    selectedCandidate?.newHireData?.businessId,
  );
  const { isOpen: isOpenConformationModal, onOpen: onOpenConformationModal, onClose: onCloseConformationModal } = useDisclosure();

  /* istanbul ignore next */
  const saveOfferMadeNote = useStoreActions((actions) => actions.account.saveOfferMadeNote);
  const toast = useToast();
  const MAXIMUM_SALARY = Number(positionSalaryDetails?.maxValue) / 100 || 999999;
  const MINIMUM_SALARY = Number(positionSalaryDetails?.minValue) / 100 || 0;

  React.useEffect(() => {
    return () => {
      setIsLoading(false);
      setNoteContent('');
      setSalary('0');
      setUnit('hourly');
      setCustomUnit('');
      setButtonDisabled(true);
    };
  }, []);

  React.useEffect(() => {
    setSalary(
      positionSalaryDetails?.minValue && (positionSalaryDetails?.unit || positionSalaryDetails?.customUnit)
        ? (getTranslatedNumber(Number((positionSalaryDetails?.minValue as any) / 100).toFixed(2), i18n.language) as any)
        : '',
    );
    setUnit(positionSalaryDetails?.unit ?? 'hourly');
    setCustomUnit(positionSalaryDetails?.customUnit ?? '');
    setNoteContent(
      positionSalaryDetails?.minValue && (positionSalaryDetails?.unit || positionSalaryDetails?.customUnit)
        ? `${t('messaging:actions.hire.confirmationModal.text1')}$${getTranslatedNumber(
            (positionSalaryDetails?.minValue as any) / 100,
            i18n.language,
          )} (${t(
            `messaging:actions.hire.confirmationModal.${positionSalaryDetails?.unit ?? positionSalaryDetails?.customUnit}`,
          )})`
        : '',
    );
  }, [positionSalaryDetails, i18n.language, t]);

  React.useEffect(() => {
    if (noteContent.replace(/<(.|\n)*?>/g, '').trim().length === 0 || unit === '') {
      setButtonDisabled(true);
    } else if (!/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(salary) || salary === '' || (salary as any) <= -1) {
      setButtonDisabled(true);
    } else if (unit === 'custom' && customUnit === '') {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [noteContent, unit, salary, customUnit]);

  const checkLengthOfStringSeparatedByDotOrComma = (givenString: string, stringLength: number) => {
    let allowInput = true;

    const spliterator = givenString.includes('.') ? givenString.split('.') : givenString.split(',');
    if (spliterator[1]) {
      if (spliterator[1].length > stringLength) {
        allowInput = false;
      }
    }

    return allowInput;
  };

  const updateNoteContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteContent(e.target.value);
  };

  const handleCustomUnitChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCustomUnit(e.target.value);
  };

  const handleSalaryChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!checkLengthOfStringSeparatedByDotOrComma(e.target.value, 2)) {
      return;
    }
    setSalary(e.target.value);
  };

  const handleUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUnit(e.target.value);
  };

  const submitNote = async () => {
    onCloseConformationModal();
    if (!selectedCandidate) return;
    let updatedSalary: string;
    if (salary.includes(',')) {
      updatedSalary = salary.toString().replace(',', '.');
    } else {
      updatedSalary = salary.toString();
    }

    try {
      setIsLoading(true);
      await saveOfferMadeNote({
        positionId: selectedCandidate.position,
        seekerId: selectedCandidate.seeker,
        offerMade: noteContent,
        salary: updatedSalary,
        salaryUnit: unit,
        customSalaryUnit: customUnit,
        sendMessageToCandidates,
        updatedPositionId,
        updatedBusinessId,
        autoDismiss,
      });
      onClose();
    } finally {
      setIsLoading(false);
      toast({
        title: t('notes:noteSuccessMsg.title'),
        description: t('notes:noteSuccessMsg.description'),
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      id="HireConfirmationModal"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      scrollBehavior="inside"
      size={requisitionEnabled ? '4xl' : '3xl'}
    >
      <ModalOverlay>
        <ModalContent>
          <ModalHeader css={confirmationModalHeaderCSS} className="modal-header" style={{ color: 'black' }}>
            {t('messaging:actions.hire.confirmationModal.header')}
          </ModalHeader>
          <ModalBody>
            <SelectRequisition
              positionId={selectedCandidate?.position || ''}
              onRequisitionChanged={() => {}}
              handleJobLocationChange={(businessLocationItem: BusinessSelectType | null) => {
                setUpdatedBusinessId(businessLocationItem?.id);
                setUpdatedPositionId(undefined);
              }}
              handleJobChange={(jobPositionItem: PositionSelectType | null) => setUpdatedPositionId(jobPositionItem?.id)}
              isOpenConformationModal={isOpenConformationModal}
              onHandleConformationModal={async (conformation: boolean) => {
                if (conformation) {
                  await submitNote();
                } else {
                  onCloseConformationModal();
                }
              }}
            />
            <Box display="flex" mt="26px" mr="16px">
              <Text mb="8px" style={{ width: '23%' }}>
                {` ${t('messaging:actions.hire.confirmationModal.salary')} ($) : `}
              </Text>
              <Box display="flex" justifyContent="space-between" style={{ marginTop: '-6px', marginLeft: '12px' }}>
                <Select
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => handleUnitChange(e)}
                  data-testid="UnitValue"
                  size="sm"
                  style={{ height: '4.8vh' }}
                  value={unit}
                >
                  <option value="hourly">{t('messaging:actions.hire.confirmationModal.hourly')}</option>
                  <option value="annual">{t('messaging:actions.hire.confirmationModal.annually')}</option>
                  <option value="custom">{t('messaging:actions.hire.confirmationModal.custom')}</option>
                </Select>
                {unit === 'custom' ? (
                  <Input
                    style={{ marginLeft: '15px', height: '4.8vh' }}
                    value={customUnit}
                    data-testid="CustomUnitValue"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleCustomUnitChange(e)}
                    placeholder={t('messaging:actions.hire.confirmationModal.customUnit')}
                    size="sm"
                    mb="1.3rem"
                  />
                ) : (
                  ''
                )}

                <NumberInput
                  max={
                    // istanbul ignore next
                    (i18n.language === 'en' && MAXIMUM_SALARY) || undefined
                  }
                  precision={2}
                  value={getTranslatedStringValue(salary, i18n.language)}
                >
                  <NumberInputField
                    style={{ marginLeft: '15px', height: '4.8vh' }}
                    data-testid="SalaryValue"
                    type="text"
                    value={getTranslatedStringValue(salary, i18n.language)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleSalaryChange(e)}
                    placeholder={t('messaging:actions.hire.confirmationModal.salaryValue')}
                    mb="1.3rem"
                    onClick={() => {
                      setPrevData(salary);
                      setSalary('');
                    }}
                    onBlur={() => {
                      if (salary === '') {
                        setSalary(prevData);
                      }
                      // istanbul ignore next
                      setSalary((salaryVal) => {
                        if (Number(salaryVal.replace(',', '.')) > MAXIMUM_SALARY) {
                          return MAXIMUM_SALARY.toFixed(2).toString();
                        }
                        if (Number(salaryVal.replace(',', '.')) <= MINIMUM_SALARY) {
                          return MINIMUM_SALARY.toFixed(2).toString();
                        }
                        return Number(salaryVal.replace(',', '.')).toFixed(2).toString();
                      });
                    }}
                  />
                </NumberInput>
              </Box>
            </Box>
            <Textarea
              value={noteContent}
              data-testid="NoteContent"
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => updateNoteContent(e)}
              focusBorderColor="gray"
              style={{ height: '10vh' }}
              placeholder={t('messaging:actions.hire.confirmationModal.body')}
            />
            <Flex mt={6}>
              <Tooltip label={t('messaging:actions.hire.sendMessageToCandidatesToolTip')} shouldWrapChildren placement="top">
                <Checkbox
                  mr={6}
                  isChecked={sendMessageToCandidates}
                  isDisabled={!!(updatedBusinessId || updatedPositionId)}
                  data-testid="sendMessageToCandidates"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setSendMessageToCandidates(e.target.checked)}
                >
                  {t('messaging:actions.hire.sendMessageToCandidates')}
                </Checkbox>
              </Tooltip>
              <Tooltip label={t('messaging:actions.hire.autoDismissCandidatesToolTip')} shouldWrapChildren placement="top">
                <Checkbox
                  mr={2}
                  isChecked={autoDismiss}
                  isDisabled={!sendMessageToCandidates}
                  data-testid="autoDismissCandidates"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setAutoDismiss(e.target.checked)}
                >
                  {t('messaging:actions.hire.autoDismissCandidates')}
                </Checkbox>
              </Tooltip>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={onClose} disabled={isLoading}>
              {t('messaging:actions.hire.confirmationModal.cancelButton')}
            </Button>
            <Button
              data-testid="ConfirmButton"
              colorScheme="blue"
              onClick={updatedBusinessId || updatedPositionId ? onOpenConformationModal : submitNote}
              isLoading={isLoading}
              disabled={disabledButton || disabled || isLoading || (updatedBusinessId ? !updatedPositionId : false)}
            >
              {t('messaging:actions.hire.confirmationModal.saveButton')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
