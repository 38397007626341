/* istanbul ignore file */
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Box,
  FormControl,
  FormLabel,
  Input,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  InputGroup,
  InputLeftAddon,
  FormErrorMessage,
  useToast,
  useDisclosure,
  Spinner,
} from '@chakra-ui/core';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import _omit from 'lodash/omit';

import _isNil from 'lodash/isNil';
import { BiInfoCircle } from 'react-icons/bi';
import Joi from 'joi';
import {
  FreeFormQuestion,
  JobPosition,
  PositionFromPositionList,
  Question,
  Schedule,
  BusinessSelectType,
  OccupationType,
  JobType,
  IndustryType,
  JobPositionToCopy,
  MultipleChoiceQuestion,
  CorrectAnswerDeterminationType,
} from '../PositionType';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { AutomationTypes } from '../createJobs/AutomationTypes';
import { getTranslatedStringValue } from '../../../../utils/localizedNumber';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
  updateObject,
} from '../../../../utils/FormErrorUtils';
import { createJobPositionStyle, ReactCustomSelectStyles } from '../createJobs/CreateJobPositionStyle';
import { AutomationCard } from '../createJobs/AutomationCard';
import { MultiSelectDropdown } from '../../../common/MultiSelectDropDown/MultiSelectDropDown';
import useBusinessLocations from '../useBusinessLocations';
import { Business } from '../BusinessType';
import { ReviewCreateJobView } from '../createJobs/ReviewCreateJobView';
import { getTranslatedValue } from '../../../../utils/localizedString';
import { QuestionsView } from '../createJobs/QuestionsView';
import { SpecificQuestionsCard } from '../createJobs/SpecificQuestionsCard';
import { FreeFormQuestionsCard } from '../createJobs/FreeFormQuestionsCard';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import { CertnBackgroundCheckCard } from '../createJobs/CertnBackgroundCheckCard';
import { BackgroundChecks } from '../../../../firebase/firestore/documents/candidate';
import { PositionAvailabilityCard } from '../createJobs/PositionAvailabilityCard';

export type CopyPositionButtonProps = {
  positionFromPositionList: PositionFromPositionList;
  isOpen: boolean;
  onClose: (business: Business | null) => void;
};

const initialQuestion = {
  text: '',
  preferredAnswer: true,
  knockout: false,
};
const initialFreeFormQuestion = {
  text: '',
  isActive: true,
};

const initialSalaryData = {
  customUnit: '',
  maxValue: '',
  minValue: '',
  unit: 'hourly',
};

const initialInterviewData = {
  interviewType: 'video',
  interviewDuration: 30,
  interviewMessage: '',
  interviewLocation: '',
};

const initialWorkSchedule = {
  sun: { day: '', evening: '', night: '' },
  mon: { day: '', evening: '', night: '' },
  tue: { day: '', evening: '', night: '' },
  wed: { day: '', evening: '', night: '' },
  thu: { day: '', evening: '', night: '' },
  fri: { day: '', evening: '', night: '' },
  sat: { day: '', evening: '', night: '' },
} as { [index: string]: Schedule };

const initialJobPositionData = {
  automated: false,
  automationType: null,
  industryName: '',
  industry: '',
  occupation: '',
  job: '',
  jobCustomName: '',
  business: '',
  businessName: '',
  businessAddress: '',
  noOfOpenings: null,
  proposedSalary: initialSalaryData,
  experienceFilterType: null,
  requiredExperience: 0,
  jobDescription: '',
  fullTime: true,
  interview: initialInterviewData,
  specificQuestions: [{ id: 2, text: '', preferredAnswer: true, knockout: false }],
  freeFormQuestions: [{ id: 2, text: '', isActive: true }],
  multipleChoiceQuestions: ([] as unknown) as MultipleChoiceQuestion[],
  schedule: initialWorkSchedule,
  skills: [],
  enableScreeningOptions: false,
  resumeEnabled: false,
};

export const CopyPositionButton = ({ positionFromPositionList, isOpen, onClose }: CopyPositionButtonProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const { isOpen: isReviewOpened, onOpen: onOpenReview, onClose: onCloseReview } = useDisclosure();
  const [industries, setIndustries] = useState<IndustryType[]>([]);
  const [jobList, setJobList] = useState<JobType[]>([]);
  const [occupationList, setOccupationList] = useState<OccupationType[]>([]);

  const { getPositionDetail } = useStoreActions((actions) => actions.positionManagement);
  const [jobPositionData, setJobPositionData] = useState<JobPosition>(initialJobPositionData);
  const screeningOptions = useStoreState((state) => state.app.accounts?.screeningOptions);
  const enableToggleOfBackgroundCheckOnPositionLevel: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_TOGGLE_OF_BACKGROUND_CHECK_ON_POSITION_LEVEL];
  });
  const { user, accounts } = useStoreState((s) => s.app);
  const isCertnBackgroundEnabled = useStoreState((s) => s.app.accounts?.configuration?.certnEnabled);
  const [salaryError, setSalaryError] = useState<FormattedError[]>([]);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [showFreeFormQuestion, setFreeFormQuestion] = useState<boolean>(false);
  const [copying, setCopying] = useState<boolean>(false);
  const [dublicating, setDublicating] = useState<boolean>(true);
  const [isInterviewAndBusinessLocationSame, setIsInterviewAndBusinessLocationSame] = useState<boolean>(false);
  const { getIndustries, getJobList, getOccupationList, copyJobPosition } = useStoreActions(
    (actions) => actions.positionManagement,
  );
  const toast = useToast();
  const initialRef = React.useRef(null);
  const loadIndustriesJobsOccupation = useCallback(async () => {
    const industriesResponse = await getIndustries(null);
    const jobListResponse = await getJobList(null);
    const occupationListResponse = await getOccupationList(null);
    setIndustries(industriesResponse.industries);
    setOccupationList(occupationListResponse.occupations);
    setJobList(jobListResponse.jobs);
  }, [getIndustries, getJobList, getOccupationList]);
  useEffect(() => {
    loadIndustriesJobsOccupation().catch(/* istanbul ignore next */ (err) => console.log(err));
  }, [loadIndustriesJobsOccupation]);
  useEffect(() => {
    const isFreeFormQuestionEnabled = accounts?.configuration?.freeFormQuestionEnabled === true;
    setFreeFormQuestion(isFreeFormQuestionEnabled);
  }, [accounts]);

  const handlePositionToCopy = async () => {
    setDublicating(true);
    const positionDataToEdit = await getPositionDetail({
      accountId: positionFromPositionList.account,
      businessId: positionFromPositionList.business,
      positionId: positionFromPositionList.id,
    });

    if (!_isNil(positionDataToEdit)) {
      const proposedSalary = positionDataToEdit?.proposedSalary
        ? {
            ...positionDataToEdit?.proposedSalary,
            minValue: positionDataToEdit?.proposedSalary.minValue
              ? (positionDataToEdit?.proposedSalary.minValue / 100).toString()
              : '',
            maxValue: positionDataToEdit?.proposedSalary.maxValue
              ? (positionDataToEdit?.proposedSalary.maxValue / 100).toString()
              : '',
          }
        : initialSalaryData;

      const specificQuestions = positionDataToEdit?.specificQuestions;
      const specificQuestionsWithOutID = specificQuestions?.map((obj) => _omit(obj, ['id'])) || [];

      const freeFormQuestions = positionDataToEdit?.freeFormQuestions;
      const freeFormQuestionsWithOutID = freeFormQuestions?.map((obj) => _omit(obj, ['id'])) || [];
      const jobTypeId = positionDataToEdit?.job;
      const industryId = jobList.filter((jobItem) => jobItem.id.toString() === jobTypeId)[0].industry;
      const occupationId = jobList.filter((jobItem) => jobItem.id.toString() === jobTypeId)[0].occupation;

      const multipleChoiceQuestions: MultipleChoiceQuestion[] = [];
      if (positionDataToEdit.multipleChoiceQuestions) {
        positionDataToEdit?.multipleChoiceQuestions.forEach((que) => {
          const newQues = que;
          const newOptions: {
            text: string;
            isPreferredAnswer?: boolean | undefined;
            priority: number;
            id?: string | undefined;
          }[] = [];
          newQues.shouldMatchAtLeast =
            newQues.correctAnswerIf === CorrectAnswerDeterminationType.AT_LEAST ? newQues.shouldMatchAtLeast : undefined;
          que.options.forEach((opt) => {
            // eslint-disable-next-line no-param-reassign
            opt.isPreferredAnswer = que.preferredAnswers?.includes(opt.id ? opt.id : '');
            newOptions.push(_omit(opt, 'id'));
          });
          newQues.options = newOptions;
          multipleChoiceQuestions.push(_omit(newQues, 'preferredAnswers'));
        });
      }

      setJobPositionData((prevData) => ({
        ...prevData,
        industryName: getTranslatedValue(industries.filter((industry) => industry.id === industryId)[0].name, i18n.language),
        industry: industryId.toString(),
        occupation: occupationId.toString(),
        job: positionDataToEdit?.job,
        jobCustomName: '',
        business: positionDataToEdit?.business,
        businessName: positionDataToEdit?.businessName,
        businessAddress: positionDataToEdit.businessName,
        noOfOpenings: positionDataToEdit.noOfOpenings ? positionDataToEdit.noOfOpenings : null,
        proposedSalary,
        experienceFilterType: positionDataToEdit.experienceFilterType ? positionDataToEdit.experienceFilterType : null,
        requiredExperience: positionDataToEdit.requiredExperience ? positionDataToEdit.requiredExperience : null,
        jobDescription: positionDataToEdit.description ? positionDataToEdit.description : '',
        fullTime: positionDataToEdit.fullTime ? positionDataToEdit?.fullTime : false,
        interview: positionDataToEdit.interview || [],
        specificQuestions: specificQuestionsWithOutID,
        freeFormQuestions: freeFormQuestionsWithOutID,
        multipleChoiceQuestions,
        schedule: positionDataToEdit?.schedule,
        skills: positionDataToEdit?.skills,
        automated: positionDataToEdit.automated ? positionDataToEdit.automated : false,
        automationType: positionDataToEdit.automationType ? positionDataToEdit.automationType : null,
        automationConfiguration:
          positionDataToEdit.automationType === 'screening' && positionDataToEdit.automationConfiguration
            ? { target: positionDataToEdit.automationConfiguration.target }
            : undefined,
        languageEvaluationTemplates: undefined,
        screeningOptions:
          positionDataToEdit?.screeningOptions &&
          Object.keys(Object.fromEntries(Object.entries(positionDataToEdit?.screeningOptions).filter(([, v]) => v))).length
            ? positionDataToEdit?.screeningOptions
            : screeningOptions,
        enableScreeningOptions: positionDataToEdit?.enableScreeningOptions ?? true,
        newAvailabilities: positionDataToEdit?.newAvailabilities,
        resumeEnabled: Boolean(positionDataToEdit?.resumeEnabled),
      }));
    } else {
      setJobPositionData(initialJobPositionData);
    }
    setDublicating(false);
  };
  useEffect(() => {
    if (isOpen && industries.length && jobList.length && occupationList.length) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handlePositionToCopy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, industries, jobList, occupationList]);

  const handleSalaryChange = (value: string, fieldName: string) => {
    const translated = !/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(value);
    let allowInput = true;

    const spliterator = value.includes('.') ? value.split('.') : value.split(',');
    if (spliterator[1]) {
      if (spliterator[1].length > 2) {
        allowInput = false;
      }
    }
    if (allowInput) {
      setJobPositionData((prevData) => ({
        ...prevData,
        proposedSalary: {
          ...prevData.proposedSalary,
          [fieldName]: value.toString().replace(',', '.'),
        },
      }));

      if (value === '' || !translated) {
        setSalaryError([]);
      } else {
        const hasAlreadyError = salaryError.filter((err) => err.label === fieldName);
        /* istanbul ignore else */
        if (hasAlreadyError.length < 1) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:invalidNumberFormat'),
            },
          ]);
        }
      }
      /* istanbul ignore next */

      if (fieldName === 'maxValue') {
        if (
          jobPositionData.proposedSalary.minValue !== '' &&
          Number(jobPositionData.proposedSalary.minValue) > parseFloat(value)
        ) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:maxValueShouldBeGreaterThanMinValue', {
                minValue: jobPositionData.proposedSalary.minValue,
              }),
            },
          ]);
        }
        if (jobPositionData.proposedSalary.minValue === '') {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: 'minValue',
              message: t('createJobPosition:minValueRequired'),
            },
          ]);
        }

        if (!/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(jobPositionData.proposedSalary.minValue.toString())) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: 'minValue',
              message: t('createJobPosition:invalidNumberFormat'),
            },
          ]);
        }
        if (!_isNil(jobPositionData.proposedSalary.minValue) && value === '') {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:maxValueRequired'),
            },
          ]);
        }
        if (value === '') {
          setSalaryError([]);
        }
        if (jobPositionData.proposedSalary.minValue === '' && value === '') {
          setSalaryError([]);
        }
      }
      /* istanbul ignore next */
      if (fieldName === 'minValue') {
        if (
          jobPositionData.proposedSalary.maxValue !== '' &&
          Number(jobPositionData.proposedSalary.maxValue) < parseFloat(value)
        ) {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:minValueShouldBeLowerThanMaxValue', {
                maxValue: jobPositionData.proposedSalary.maxValue,
              }),
            },
          ]);
        }
        if (jobPositionData.proposedSalary.maxValue !== '') {
          if (!/^(?:\d*[.|,]\d{1,2}|\d+)$/.exec(jobPositionData.proposedSalary.maxValue.toString())) {
            setSalaryError((prevData) => [
              ...prevData,
              {
                label: 'maxValue',
                message: t('createJobPosition:invalidNumberFormat'),
              },
            ]);
          }
        }

        if (!_isNil(jobPositionData.proposedSalary.maxValue) && value === '') {
          setSalaryError((prevData) => [
            ...prevData,
            {
              label: fieldName,
              message: t('createJobPosition:minValueRequired'),
            },
          ]);
        }
        if (jobPositionData.proposedSalary.maxValue === '' && value === '') {
          setSalaryError([]);
        }
      }
    }
  };

  const handleChange = (value: string | Date | number | boolean | null, fieldName: string, dataType: boolean) => {
    updateObject(jobPositionData, fieldName, value, dataType);
    setFormErrors(clearErrorMessage(formErrors, fieldName));
    setJobPositionData({ ...jobPositionData });
  };

  const deleteQuestion = (questionId: number | undefined) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      specificQuestions: jobPositionData.specificQuestions.filter((sQue, index) => index !== questionId),
    }));
  };

  // eslint-disable-next-line consistent-return
  const addQuestion = (question: Question, questionEditMode: number | undefined) => {
    if (_isNil(questionEditMode)) {
      setJobPositionData((prevData) => ({
        ...prevData,
        specificQuestions: [...prevData.specificQuestions, { ...question }],
      }));
    } else {
      Object.assign(jobPositionData.specificQuestions.find((ques, index) => index === questionEditMode)!, { ...question });
      setJobPositionData((prevData) => ({
        ...prevData,
        specificQuestions: jobPositionData.specificQuestions,
      }));
    }
  };

  const deleteFreeFormQuestion = (questionId: number | undefined) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      freeFormQuestions: jobPositionData.freeFormQuestions.filter((sQue, index) => index !== questionId),
    }));
  };

  const deleteMultipleQuestionsQuestion = (questionId: number | null) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      multipleChoiceQuestions: jobPositionData.multipleChoiceQuestions.filter((sQue, index) => index !== questionId),
    }));
  };

  // eslint-disable-next-line consistent-return
  const addFreeFormQuestion = (freeFormQuestion: FreeFormQuestion, freeFormQuestionEditMode: number | undefined) => {
    if (_isNil(freeFormQuestionEditMode)) {
      setJobPositionData((prevData) => ({
        ...prevData,
        freeFormQuestions: [...prevData.freeFormQuestions, { ...freeFormQuestion }],
      }));
    } else {
      Object.assign(jobPositionData.freeFormQuestions.find((ques, index) => index === freeFormQuestionEditMode)!, {
        ...freeFormQuestion,
      });
      setJobPositionData((prevData) => ({
        ...prevData,
        freeFormQuestions: jobPositionData.freeFormQuestions,
      }));
    }
  };

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      jobCustomName: Joi.string()
        .trim(true)
        .required()
        .messages({
          'string.empty': t('createJobPosition:jobTitleRequired'),
        }),
    });

  const formValidation = (position: JobPosition) => {
    const obj = { jobCustomName: position.jobCustomName };
    const { error } = schema.validate(obj);
    /* istanbul ignore next */
    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const onHandleReviewPositon = () => {
    const validate = formValidation(jobPositionData);
    if (validate) {
      onOpenReview();
    }
  };

  const onHandleSavePosition = async () => {
    try {
      onCloseReview();

      if (user) {
        const positionToCopy: JobPositionToCopy = {
          positionId: positionFromPositionList.id,
          automated: jobPositionData.automated,
          automationType: jobPositionData.automationType,
          customName: jobPositionData.jobCustomName,
          businessName: jobPositionData.businessName,
          business: jobPositionData.business,
          noOfOpenings: jobPositionData.noOfOpenings ? jobPositionData.noOfOpenings : 0,
          proposedSalary: {
            customUnit: jobPositionData.proposedSalary.customUnit,
            maxValue: jobPositionData.proposedSalary.maxValue,
            minValue: jobPositionData.proposedSalary.minValue,
            unit: jobPositionData.proposedSalary.unit,
          },
          description: jobPositionData.jobDescription,
          specificQuestions: jobPositionData.specificQuestions,
          freeFormQuestions: jobPositionData.freeFormQuestions,
          multipleChoiceQuestions: jobPositionData.multipleChoiceQuestions,
          languageEvaluationTemplates: jobPositionData.languageEvaluationTemplates,
          screeningOptions: jobPositionData.enableScreeningOptions ? jobPositionData.screeningOptions : undefined,
          enableScreeningOptions: enableToggleOfBackgroundCheckOnPositionLevel
            ? jobPositionData.enableScreeningOptions ?? true
            : undefined,
          automationConfiguration: jobPositionData.automationConfiguration,
          newAvailabilities: jobPositionData?.newAvailabilities,
        };
        positionToCopy.positionId = positionFromPositionList.id;
        setCopying(true);
        await copyJobPosition({ jobPositionToCopy: positionToCopy });
        setCopying(false);
        const defaultBusinessData: Business = {
          id: jobPositionData.business,
          name: jobPositionData.businessName,
          cityName: '',
          address: '',
          country: 'Canada',
          latitude: 0,
          longitude: 0,
          zip: '',
          state: '',
          phoneNumber: '',
          webSite: '',
          completeAddress: '',
          placeId: '',
          positions: 0,
        };

        onClose(defaultBusinessData);
      }
    } catch (e) {
      setCopying(false);
      toast({
        title: t('createJobPosition:copyPositionError'),
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };
  const addMultipleChoiceQuestion = (
    multipleChoiceQuestions: MultipleChoiceQuestion,
    multipleQuestionEditMode: number | undefined,
  ) => {
    if (_isNil(multipleQuestionEditMode)) {
      setJobPositionData((prevData) => ({
        ...prevData,
        multipleChoiceQuestions: [...prevData.multipleChoiceQuestions, { ...multipleChoiceQuestions }],
      }));
    } else {
      Object.assign(jobPositionData.multipleChoiceQuestions.find((ques, index) => index === multipleQuestionEditMode)!, {
        ...multipleChoiceQuestions,
      });
      setJobPositionData((prevData) => ({
        ...prevData,
        multipleChoiceQuestions: jobPositionData.multipleChoiceQuestions,
      }));
    }
  };

  const handleAutomationChange = (value: string | null, fieldName: string, dataType = false) => {
    updateObject(jobPositionData, fieldName, value, dataType);
    if (value === AutomationTypes.SCREENING) {
      jobPositionData.automationType = AutomationTypes.SCREENING;
      jobPositionData.automated = true;
      jobPositionData.automationConfiguration = { target: null };
    } else if (value === AutomationTypes.SCHEDULING) {
      jobPositionData.automationType = AutomationTypes.SCHEDULING;
      jobPositionData.automated = true;
      delete jobPositionData.automationConfiguration;
    } else {
      jobPositionData.automationType = null;
      jobPositionData.automated = false;
      delete jobPositionData.automationConfiguration;
    }
    setFormErrors(clearErrorMessage(formErrors, fieldName));
    setJobPositionData({ ...jobPositionData });
  };

  const handleChangeInterviewLocation = (isSameLocation: boolean) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      interview: {
        ...prevData.interview,
        interviewLocation: isSameLocation ? jobPositionData.businessAddress : '',
      },
    }));
    setIsInterviewAndBusinessLocationSame(isSameLocation);
    setFormErrors(clearErrorMessage(formErrors, 'interview.interviewLocation'));
  };
  /* istanbul ignore next */
  const handleJobLocationChange = (businessLocation: BusinessSelectType | null) => {
    /* istanbul ignore next */
    if (!_isNil(businessLocation)) {
      setJobPositionData((prevData) => ({
        ...prevData,
        business: businessLocation.id,
        businessName: businessLocation.value,
        businessAddress: businessLocation.label,
      }));
    }
    /* istanbul ignore next */
    setFormErrors(clearErrorMessage(formErrors, 'business'));
  };
  const businessLocations = useBusinessLocations();

  const reOrderedMultipleChoiceQuestions = (orderedMultipleChoiceQuestions: MultipleChoiceQuestion[]) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      multipleChoiceQuestions: orderedMultipleChoiceQuestions,
    }));
  };

  const reOrderSpecificQuestions = (orderSpecificQuestions: Question[]) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      specificQuestions: orderSpecificQuestions,
    }));
  };

  const reOrderFreeFormQuestions = (orderFreeFormQuestions: FreeFormQuestion[]) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      freeFormQuestions: orderFreeFormQuestions,
    }));
  };
  const enableMultipleChoiceFeature: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE];
  });

  const handleEnableCertnBackgroundCheck = (checked: boolean) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      enableScreeningOptions: checked,
    }));
  };

  const setBackgroundCheckValue = (checked: boolean, field: string) => {
    if (!_isNil(jobPositionData.screeningOptions)) {
      let selectedValues: BackgroundChecks = { ...jobPositionData.screeningOptions };

      if (field === 'request_criminal_record_check') {
        selectedValues = {
          ...selectedValues,
          request_enhanced_identity_verification: true,
          request_enhanced_criminal_record_check: false,
        };
      } else if (field === 'request_enhanced_criminal_record_check') {
        selectedValues = {
          ...selectedValues,
          request_enhanced_identity_verification: true,
          request_criminal_record_check: false,
        };
      } else if (field === 'request_enhanced_identity_verification') {
        selectedValues = {
          ...selectedValues,
          request_criminal_record_check: false,
          request_enhanced_criminal_record_check: false,
        };
      } else if (field === 'request_us_criminal_record_check_tier_1') {
        selectedValues = {
          ...selectedValues,
          request_us_criminal_record_check_tier_2: false,
        };
      } else if (field === 'request_us_criminal_record_check_tier_2') {
        selectedValues = {
          ...selectedValues,
          request_us_criminal_record_check_tier_1: false,
        };
      }

      selectedValues = {
        ...selectedValues,
        [field]: checked,
      };

      setJobPositionData((prevData) => ({
        ...prevData,
        screeningOptions: selectedValues,
      }));
    }
  };

  const handleAvailabilityChange = (availability: { [key: string]: { start_1: string; end_1: string } }) => {
    setJobPositionData((prevData) => ({
      ...prevData,
      newAvailabilities: availability,
    }));
  };
  const handleFullTimeChange = (fieldName: string, value: boolean) => {
    updateObject(jobPositionData, fieldName, value, false);
  };

  return (
    <>
      {isReviewOpened ? (
        <ReviewCreateJobView
          isNewPosition
          isCopyPostion
          onClose={() => onCloseReview()}
          onSave={() => onHandleSavePosition()}
          reviewPosition={isReviewOpened}
          jobPositionData={jobPositionData}
          occupationList={occupationList}
          jobList={jobList}
        />
      ) : (
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          size="5xl"
          scrollBehavior="inside"
          initialFocusRef={initialRef}
          onClose={/* istanbul ignore next */ () => onClose(null)}
        >
          <ModalOverlay />
          <ModalContent position="relative">
            {copying ||
              (dublicating && (
                <Flex
                  position="absolute"
                  w="100%"
                  h="100%"
                  backgroundColor="rgba(255, 255, 255, 0.8)"
                  zIndex={1}
                  left="0"
                  top="0"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner color="#1F3CBA" data-testid="Spinner" />
                </Flex>
              ))}
            <ModalBody>
              <Box css={createJobPositionStyle}>
                <Text fontWeight="bold" mb="1rem" py="1" fontSize="20px">
                  {t('createJobPosition:jobDetails')}
                </Text>

                <Flex w="100%" mt={6}>
                  <FormControl isInvalid={hasErrorMessage(formErrors, 'business')} isRequired>
                    <FormLabel htmlFor="jobLocation" fontWeight="bold">
                      {t('createJobPosition:businessLocation')}
                    </FormLabel>
                    <Box css={ReactCustomSelectStyles} data-testid="JobLocationChangeBtn">
                      <MultiSelectDropdown
                        placeholder={t('createJobPosition:businessLocationPlaceholder')}
                        id="jobLocation"
                        handleChange={handleJobLocationChange}
                        options={businessLocations}
                        value={
                          /* istanbul ignore next */ jobPositionData.businessAddress
                            ? {
                                id: jobPositionData.business,
                                label: jobPositionData.businessName,
                                value: jobPositionData.businessAddress,
                              }
                            : null
                        }
                        data-testid="JobLocationField"
                      />
                    </Box>
                    <FormErrorMessage>{getErrorMessage(formErrors, 'business')}</FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex w="100%" marginTop="12px">
                  <Box flex={1} mr={3}>
                    <FormControl isInvalid={hasErrorMessage(formErrors, 'jobCustomName')} isRequired>
                      <FormLabel htmlFor="jobTitle" fontWeight="bold">
                        {t('createJobPosition:jobTitle')}
                      </FormLabel>
                      <Input
                        ref={initialRef}
                        variant="filled"
                        type="text"
                        value={jobPositionData.jobCustomName}
                        onChange={(e) => handleChange(e.target.value, 'jobCustomName', false)}
                        data-testid="JobCustomNameField"
                      />
                      <FormErrorMessage>{getErrorMessage(formErrors, 'jobCustomName')}</FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <Flex marginLeft="10px">
                      <Box w="40%" mr={4}>
                        <FormControl>
                          <FormLabel htmlFor="experience" fontWeight="bold">
                            {t('createJobPosition:experience')}
                          </FormLabel>
                          <Select
                            className="filled"
                            id="experience"
                            value={
                              !_isNil(jobPositionData.experienceFilterType) ? jobPositionData.experienceFilterType : undefined
                            }
                            onChange={(e) => handleChange(e.target.value, 'experienceFilterType', false)}
                            data-testid="ExperienceFilterTypeField"
                          >
                            <option value="" disabled selected>
                              {t('createJobPosition:selectFilterByType')}
                            </option>
                            <option value="preferred">{t('createJobPosition:experienceFilterTypeOptions.preferred')}</option>
                            <option value="maximum">{t('createJobPosition:experienceFilterTypeOptions.maximum')}</option>
                            <option value="minimum">{t('createJobPosition:experienceFilterTypeOptions.minimum')}</option>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box w="60%">
                        <FormControl>
                          <FormLabel>&nbsp;</FormLabel>
                          <Flex alignItems="center">
                            <NumberInput
                              min={0}
                              max={100}
                              variant="filled"
                              id="experienceYears"
                              type="number"
                              w="40%"
                              value={jobPositionData.requiredExperience ? jobPositionData.requiredExperience : ''}
                              onChange={(value) => handleChange(value, 'requiredExperience', true)}
                              data-testid="RequiredExperienceField"
                            >
                              <NumberInputField />
                              <NumberInputStepper borderColor="#dee1e3">
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <Text ml={4} w="60%">
                              {t('createJobPosition:yearsOfExperience')}
                            </Text>
                          </Flex>
                        </FormControl>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>

                <Flex mb={6} marginTop="12px">
                  <Flex w="30%" pr="6">
                    <Box w="100%">
                      <FormControl>
                        <FormLabel htmlFor="numberOfOpening" fontWeight="bold">
                          {t('createJobPosition:noOfOpenings')}
                        </FormLabel>
                        <NumberInput
                          variant="filled"
                          id="numberOfOpening"
                          min={0}
                          max={999}
                          value={jobPositionData.noOfOpenings ? jobPositionData.noOfOpenings : ''}
                          onChange={(value) => handleChange(value, 'noOfOpenings', true)}
                          data-testid="NumberOfOpeningField"
                        >
                          <NumberInputField />
                          <NumberInputStepper borderColor="#dee1e3">
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    </Box>
                  </Flex>
                  <Box w="20%" mr={4}>
                    <FormControl>
                      <FormLabel htmlFor="salary" fontWeight="bold">
                        {t('createJobPosition:salary')}
                      </FormLabel>
                      <Select
                        className="filled"
                        id="salary"
                        value={jobPositionData.proposedSalary.unit}
                        onChange={(e) => handleChange(e.target.value, 'proposedSalary.unit', false)}
                        data-testid="SalaryUnitField"
                      >
                        <option value="annual">{t('createJobPosition:proposedSalaryUnitOptions.annual')}</option>
                        <option value="hourly">{t('createJobPosition:proposedSalaryUnitOptions.hourly')}</option>
                        <option value="custom">{t('createJobPosition:proposedSalaryUnitOptions.custom')}</option>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box w="20%" mr={4}>
                    <FormControl>
                      <FormLabel>&nbsp;</FormLabel>
                      <InputGroup>
                        <InputLeftAddon>$</InputLeftAddon>
                        <Input
                          type="text"
                          variant="filled"
                          placeholder={jobPositionData.proposedSalary.unit === 'annual' ? '2000' : '20'}
                          value={
                            /* istanbul ignore next */
                            !_isNil(jobPositionData.proposedSalary.minValue)
                              ? getTranslatedStringValue(jobPositionData.proposedSalary.minValue, i18n.language)
                              : ''
                          }
                          onChange={(e) => handleSalaryChange(e.target.value, 'minValue')}
                          data-testid="MinValueField"
                        />
                      </InputGroup>
                      <FormErrorMessage>{getErrorMessage(salaryError, 'minValue')}</FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box mr={4}>
                    <FormControl>
                      <FormLabel>&nbsp;</FormLabel>
                      <Text mt={5}>{t('createJobPosition:to')}</Text>
                    </FormControl>
                  </Box>
                  <Box w="20%" mr={4}>
                    <FormControl>
                      <FormLabel>&nbsp;</FormLabel>
                      <InputGroup>
                        <InputLeftAddon>$</InputLeftAddon>
                        <Input
                          type="text"
                          variant="filled"
                          placeholder={jobPositionData.proposedSalary.unit === 'annual' ? '5000' : '50'}
                          value={
                            /* istanbul ignore next */
                            !_isNil(jobPositionData.proposedSalary.maxValue)
                              ? getTranslatedStringValue(jobPositionData.proposedSalary.maxValue, i18n.language)
                              : ''
                          }
                          onChange={(e) => handleSalaryChange(e.target.value, 'maxValue')}
                          data-testid="MaxValueField"
                        />
                      </InputGroup>
                      <FormErrorMessage>{getErrorMessage(salaryError, 'maxValue')}</FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box w="15%">
                    {jobPositionData.proposedSalary.unit === 'custom' && (
                      <FormControl isInvalid={hasErrorMessage(formErrors, 'proposedSalary.customUnit')}>
                        <FormLabel htmlFor="per" fontWeight="bold">
                          {t('createJobPosition:per')}
                        </FormLabel>
                        <Input
                          variant="filled"
                          id="per"
                          type="text"
                          placeholder={t('createJobPosition:unit')}
                          value={
                            /* istanbul ignore next */ !_isNil(jobPositionData.proposedSalary.customUnit)
                              ? jobPositionData.proposedSalary.customUnit
                              : undefined
                          }
                          onChange={(e) => handleChange(e.target.value, 'proposedSalary.customUnit', false)}
                          data-testid="CustomUnitTypeField"
                        />
                        {}
                        <FormErrorMessage>{getErrorMessage(formErrors, 'proposedSalary.customUnit')}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Box>
                </Flex>
                {enableMultipleChoiceFeature ? (
                  <Fragment>
                    <QuestionsView
                      jobPositionData={jobPositionData}
                      deleteQuestion={deleteQuestion}
                      addQuestion={addQuestion}
                      showQuestionForm={showFreeFormQuestion}
                      addFreeFormQuestion={addFreeFormQuestion}
                      addMultipleChoiceQuestion={addMultipleChoiceQuestion}
                      deleteFreeFormQuestion={deleteFreeFormQuestion}
                      deleteMultipleQuestionsQuestion={deleteMultipleQuestionsQuestion}
                      reOrderedMultipleChoiceQuestions={reOrderedMultipleChoiceQuestions}
                      reOrderSpecificQuestions={reOrderSpecificQuestions}
                      reOrderFreeFormQuestions={reOrderFreeFormQuestions}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Box mb={showFreeFormQuestion ? 0 : 6}>
                      <SpecificQuestionsCard
                        jobPositionData={jobPositionData}
                        deleteQuestion={deleteQuestion}
                        addQuestion={addQuestion}
                        showQuestionForm={false}
                        questionToEdit={initialQuestion}
                        questionToEditMode={undefined}
                        onRefreshState={() => {}}
                      />
                    </Box>
                    {showFreeFormQuestion && (
                      <FreeFormQuestionsCard
                        jobPositionData={jobPositionData}
                        deleteFreeFormQuestion={deleteFreeFormQuestion}
                        addFreeFormQuestion={addFreeFormQuestion}
                        showQuestionForm={false}
                        questionToEdit={initialFreeFormQuestion}
                        questionToEditMode={undefined}
                        onRefreshState={() => {}}
                      />
                    )}
                  </Fragment>
                )}

                <Fragment>
                  <AutomationCard
                    jobPositionData={jobPositionData}
                    formErrors={formErrors}
                    isInterviewAndBusinessLocationSame={isInterviewAndBusinessLocationSame}
                    handleChange={handleChange}
                    handleAutomationChange={handleAutomationChange}
                    handleChangeInterviewLocation={handleChangeInterviewLocation}
                    removePadding
                  />
                </Fragment>

                {isCertnBackgroundEnabled && (
                  <Fragment>
                    <CertnBackgroundCheckCard
                      jobPositionData={jobPositionData}
                      formErrors={formErrors}
                      setBackgroundCheckValue={setBackgroundCheckValue}
                      setEnableCertnBackgroundCheck={handleEnableCertnBackgroundCheck}
                    />
                  </Fragment>
                )}
                <PositionAvailabilityCard
                  jobPositionData={jobPositionData}
                  handleAvailabilityChange={handleAvailabilityChange}
                  handleChange={(value: boolean, fieldName: string) => handleFullTimeChange(fieldName, value)}
                />
              </Box>
            </ModalBody>

            <ModalFooter justifyContent="space-between">
              <Text fontWeight="normal" mb="1rem" py="1" fontSize="14px" fontStyle="italic" display="flex" alignItems="center">
                <BiInfoCircle />
                {dublicating ? t('createJobPosition:dublicationInfo') : t('createJobPosition:copyInfo')}
              </Text>

              <Box>
                <Button colorScheme="red" mr={3} onClick={() => onClose(null)} data-testid="cancelBtn">
                  {t('common:button.cancel')}
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={onHandleReviewPositon}
                  isLoading={copying || dublicating}
                  data-testid="saveBtn"
                >
                  {t('createJobPosition:reviewPositionBtn')}
                </Button>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
