import _isString from 'lodash/isString';

export type LocalizedString = {
  en?: string;
  es?: string;
  fr?: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const instanceOfLocalizedString = (toCheck: any): toCheck is LocalizedString => {
  if (_isString(toCheck)) return false;
  return 'en' in toCheck;
};
