/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ChangeEvent } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons/lib';
import { IoIosRemoveCircle } from 'react-icons/io';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { confirmationModalHeaderCSS } from './ConfirmationModal.styles';
import colors from '../../../../../styles/colors';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import useCandidatesPicker from '../candidatesDropdown/useCandidatesPicker';
import { RemoteConfigKey } from '../../../../../firebase/remoteConfig';
import { getTranslatedValue } from '../../../../../utils/localizedString';
import { DISMISSAL_REASONS } from '../../../../../firebase/firestore/constants';
import useLookupsByType from '../../../../../app/hooks/useLookupsByType';
import { useSeekersFilters } from '../../../sidebar/filters/SeekersFiltersContext';
import { PATH_MAIN } from '../../../../../routes/constants';
import { isPositionCandidatesPage } from '../../../../../utils/candidatesUtils';
import { useMessagingViewContext, useSelectedEngagedCandidate } from '../../../MessagingViewContext';
import { SeekersPresenter } from '../../../sidebar/seekerList/seekersPresenter';
import { EngagedCandidate } from '../../../../../firebase/firestore/documents/candidate';

export type DismissConfirmationModalProps = {
  isOpen: boolean;
  disabled: boolean;
  onClose: () => void;
  isMessagingView: boolean;
};

export const DismissConfirmationModal = ({
  isOpen,
  disabled,
  onClose,
  isMessagingView,
}: DismissConfirmationModalProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedCandidate, setSelectedCandidate } = useSeekerContainerContext();
  const { currentUserProfile } = useCurrentUserProfile();
  // istanbul ignore next
  const dismissCandidate = useStoreActions((actions) => actions.messaging.dismissCandidate);
  const candidates = useCandidatesPicker();
  const history = useHistory();
  const [noteContent, setNoteContent] = React.useState('');
  const [noteContentTextArea, setNoteContentTextArea] = React.useState('');
  const [showTextArea, setShowTextArea] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const isDismissalReasonEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_DISMISSAL_REASON];
  });
  const { searchAndSortTypes } = useSeekersFilters();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const positionSeekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allPositionCandidates);
  const { setSelectedEngagedCandidate, setSelectedEngagedSeeker } = useMessagingViewContext();

  // istanbul ignore next
  const hasAdvanceFilterCount = (): boolean => {
    let count = 0;
    if (searchAndSortTypes.isFavouriteApplied) {
      count += 1;
    }
    if (
      searchAndSortTypes.candidateEngagement.apointmentRequested !== '' ||
      searchAndSortTypes.candidateEngagement.offerMade !== '' ||
      searchAndSortTypes.candidateEngagement.Hired !== '' ||
      searchAndSortTypes.candidateEngagement.offerRejected !== '' ||
      searchAndSortTypes.candidateEngagement.offerAccepted !== '' ||
      searchAndSortTypes.candidateEngagement.appoinmentAccepted !== '' ||
      searchAndSortTypes.candidateEngagement.appoinmentDeclined !== '' ||
      searchAndSortTypes.candidateEngagement.appoinmentCancled !== '' ||
      searchAndSortTypes.candidateEngagement.noAppointmentRequest !== ''
    ) {
      count += 1;
    }
    if (searchAndSortTypes.backgroundCheck.length > 0) {
      count += 1;
    }
    if (searchAndSortTypes.hireStatus.length > 0) {
      count += 1;
    }
    if (searchAndSortTypes.languageEvaluation.length > 0) {
      count += 1;
    }
    if (searchAndSortTypes.availabilityFit.length > 0) {
      count += 1;
    }
    if (searchAndSortTypes.modernHireStatus.length > 0) {
      count += 1;
    }
    if (
      searchAndSortTypes.rating.includes(1) ||
      searchAndSortTypes.rating.includes(2) ||
      searchAndSortTypes.rating.includes(3) ||
      searchAndSortTypes.rating.includes(4) ||
      searchAndSortTypes.rating.includes(5)
    ) {
      count += 1;
    }
    if (searchAndSortTypes.availability.day || searchAndSortTypes.availability.evening || searchAndSortTypes.availability.night) {
      count += 1;
    }

    return count > 0;
  };

  const dismissalReaons = useLookupsByType(DISMISSAL_REASONS);

  const handleAfterDismissModal = () => {
    // istanbul ignore next
    const candidate = selectedCandidate || selectedEngagedCandidate;

    /* istanbul ignore next */
    if (isPositionCandidatesPage()) {
      const allSeekers = positionSeekerList?.getSeekersList(i18n.language);
      const nextFoundSeeker = allSeekers?.find((item) => item.key !== candidate?.seeker);
      const foundCandidate: EngagedCandidate = nextFoundSeeker?.item?.candidate as EngagedCandidate;

      if (nextFoundSeeker && foundCandidate) {
        setSelectedCandidate(foundCandidate);
        setSelectedEngagedCandidate(foundCandidate);
        setSelectedEngagedSeeker({ seekerId: foundCandidate.seeker, candidateId: foundCandidate.id });
      } else {
        window.location.reload();
      }

      return;
    }
    /* istanbul ignore else */
    if (isMessagingView) {
      /* istanbul ignore else */
      /*  eslint no-lonely-if: "error"  */
      if (candidates && candidates.candidatePickerItems && candidates.candidatePickerItems.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const indexOfCurrentSelected =
          candidates && candidates.candidatePickerItems.findIndex((item) => item.id === selectedCandidate?.id);

        if (indexOfCurrentSelected === 0 && candidates.candidatePickerItems.length > 1) {
          setSelectedCandidate(candidates?.candidatePickerItems[1]?.candidate);
        } /* istanbul ignore next */ else if (candidates.candidatePickerItems.length === 1) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          hasAdvanceFilterCount() ? '' : history.push(PATH_MAIN);
        } else {
          setSelectedCandidate(candidates?.candidatePickerItems[indexOfCurrentSelected - 1]?.candidate);
        }
      }
    }
  };

  const handleDismissCandidate = async (positionId: string, seekerId: string, recruiterId: string, dismissalReason?: string) => {
    try {
      setIsLoading(true);
      await dismissCandidate({
        positionId,
        seekerId,
        recruiterId,
        dismissalReason,
      });
      setNoteContent('');
      setNoteContentTextArea('');
      handleAfterDismissModal();
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateAppointmentRequest = async () => {
    const candidate = selectedCandidate || selectedEngagedCandidate;

    if (!candidate) return;
    if (!currentUserProfile) return;

    if (isDismissalReasonEnabled) {
      if (noteContent === 'Others' && noteContentTextArea === '') {
        setErrorMessage(true);
      } else if (noteContent) {
        setErrorMessage(false);
        await handleDismissCandidate(
          candidate.position,
          candidate.seeker,
          currentUserProfile.id,
          showTextArea ? noteContentTextArea : noteContent,
        );
      } else {
        setErrorMessage(true);
      }
    } else {
      await handleDismissCandidate(candidate.position, candidate.seeker, currentUserProfile.id);
    }
  };

  const updateNoteContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteContentTextArea(e.target.value);
    setErrorMessage(false);
  };

  const handleClose = () => {
    setNoteContent('');
    setNoteContentTextArea('');
    setErrorMessage(false);
    onClose();
  };

  return (
    <Modal id="DismissConfirmationModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader css={confirmationModalHeaderCSS} className="modal-header">
            <Flex direction="row" alignItems="center">
              <Flex alignItems="center" direction="column" justify="center" className="blue-circle-icon">
                <IconContext.Provider value={{ color: colors.white }}>
                  <IoIosRemoveCircle size={14} />
                </IconContext.Provider>
              </Flex>
              <Box width={2} />
              {t('messaging:actions.dismiss.confirmationModal.header')}
            </Flex>
          </ModalHeader>
          <ModalBody>
            {isDismissalReasonEnabled
              ? t('messaging:actions.dismiss.confirmationModal.dismissalReasonEnabledBody')
              : t('messaging:actions.dismiss.confirmationModal.body')}
            {isDismissalReasonEnabled && (
              <Box mt={4}>
                <RadioGroup
                  value={noteContent}
                  onChange={(rsn) => {
                    if (rsn === 'Others') {
                      setNoteContent('Others');
                      setShowTextArea(true);
                    } else {
                      setErrorMessage(false);
                      setShowTextArea(false);
                      setNoteContent(rsn as string);
                      setNoteContentTextArea('');
                    }
                  }}
                >
                  <Stack>
                    {dismissalReaons.map((item) => (
                      <Radio
                        key={item.id}
                        value={getTranslatedValue(item.label, i18n.language)}
                        isDisabled={isLoading}
                        data-testid="dismissalReason"
                      >
                        {getTranslatedValue(item.label, i18n.language)}
                      </Radio>
                    ))}
                    <Radio value="Others" isDisabled={isLoading}>
                      {t('messaging:actions.dismiss.confirmationModal.others')}
                    </Radio>
                  </Stack>
                </RadioGroup>
                {showTextArea && (
                  <Textarea
                    value={noteContentTextArea}
                    data-testid="reasonForDismissal"
                    placeholder={t('messaging:actions.dismiss.confirmationModal.reasonForDismissal')}
                    mt={4}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => updateNoteContent(e)}
                  />
                )}
              </Box>
            )}
            {errorMessage && (
              <Box fontStyle="italic" mt={3} mb={3} color={colors.red[500]}>
                {t('messaging:actions.dismiss.confirmationModal.errorMsg')}
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={handleClose} disabled={isLoading}>
              {t('messaging:actions.dismiss.confirmationModal.cancelButton')}
            </Button>
            <Button
              data-testid="ConfirmButton"
              colorScheme="blue"
              onClick={handleCreateAppointmentRequest}
              disabled={disabled}
              isLoading={isLoading}
            >
              {t('messaging:actions.dismiss.confirmationModal.sendButton')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
