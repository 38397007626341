import React, { Fragment } from 'react';
import {
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  useDisclosure,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from '../../models/hooks';
import { Payload } from '../helper/foregroundNotifcation';
import { ReactComponent as PositionInactiveReminderSvg } from '../../assets/img/position-inactive.svg';
import { ReactComponent as InactivePositionClosedSvg } from '../../assets/img/inactive-position.svg';
import { NotificationTypes } from '../../firebase/firestore/documents/notificationTypes';

export type PositionInactiveNotificationModalProps = {
  notificationPayload: Payload | undefined;
};

export const PositionInactiveNotificationModal = ({
  notificationPayload,
}: PositionInactiveNotificationModalProps): JSX.Element => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { t } = useTranslation('notification');

  const clearPositionInactiveReminderNotification = useStoreActions(
    (state) => state.notifications.clearPositionInactiveReminderNotification,
  );

  const clearInactivePositionClosedNotification = useStoreActions(
    (state) => state.notifications.clearInactivePositionClosedNotification,
  );

  const clearAndClose = () => {
    if (notificationPayload?.data.code === NotificationTypes.POSITION_INACTIVE_REMINDER) {
      clearPositionInactiveReminderNotification();
    } else if (notificationPayload?.data.code === NotificationTypes.INACTIVE_POSITION_CLOSED) {
      clearInactivePositionClosedNotification();
    }
    onClose();
  };

  const iconRenderer = () => {
    let icon;
    if (notificationPayload?.data.code === NotificationTypes.POSITION_INACTIVE_REMINDER) {
      icon = <PositionInactiveReminderSvg />;
    } else if (notificationPayload?.data.code === NotificationTypes.INACTIVE_POSITION_CLOSED) {
      icon = <InactivePositionClosedSvg />;
    }
    return icon;
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={clearAndClose} isCentered>
        <ModalOverlay />
        <ModalContent px={5} py={3} data-testid="PositionInactiveReminderModal">
          <ModalHeader textAlign="center" alignSelf="center">
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{iconRenderer()}</Box>
            <Text mt={6} color="black" fontSize="1.125rem" fontWeight="bold.700">
              <p>{notificationPayload?.notification.title}</p>
            </Text>
          </ModalHeader>
          <ModalBody textAlign="center">
            <Text fontSize="1rem" style={{ lineHeight: '1.625rem' }}>
              {notificationPayload?.notification.body}
            </Text>
          </ModalBody>

          <ModalFooter mt={2} display="flex" justifyContent="center" alignItems="center">
            <Button colorScheme="blue" data-testid="PositionInactiveReminderModalSubmitButton" onClick={clearAndClose}>
              {t('messaging:actions.hire.messageAfterHiringModal.buttonText')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
