import { Box } from '@chakra-ui/core';
import React from 'react';
import { LabelSchema, Language } from './formBuilderSchema';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import theme from '../../../../styles/customTheme';

import Quill from 'quill';

const Inline = Quill.import('blots/inline');

class BoldTag extends Inline {
  static blotName = 'tag';
  static tagName = 'strong';

  static create(value: string) {
    const node = super.create();
    node.setAttribute('data-tag', value);
    return node;
  }

  static formats(node: HTMLElement) {
    return node.getAttribute('data-tag');
  }
}

Quill.register(BoldTag);

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  ],
};

const tags = ['first_name', 'last_name', 'email_address', 'business_name', 'position_name'];

interface TextSchemaBuilderProps extends React.ComponentProps<typeof Box> {
  label: LabelSchema[keyof LabelSchema];
  onLabelChange: (label: LabelSchema[keyof LabelSchema]) => void;
  startWithEditView?: boolean;
  language: Language;
}

/**
 * A component to render and edit a label schema for a form builder.
 *
 * This component renders a ReactQuill editor when in editing mode, and a static
 * label or placeholder when not in editing mode. It handles clicks outside the
 * editor to toggle editing mode, and sanitizes the changed value before passing
 * it to the `onLabelChange` handler.
 *
 * @prop {LabelSchema[keyof LabelSchema]} label - The current label value.
 * @prop {(label: LabelSchema[keyof LabelSchema]) => void} onLabelChange - The
 *   handler to call when the label value changes.
 * @prop {string} [placeholder='Enter text here...'] - The placeholder text to
 *   display when the label is empty.
 * @prop {boolean} [startWithEditView=true] - Whether to start in editing mode.
 * @prop {Language} language - The current language.
 * @prop {React.ComponentProps<typeof Box>} ...props - Additional props to
 *   pass to the Box container.
 * @returns A JSX element.
 */
export default function TextSchemaBuilder({
  label,
  onLabelChange,
  placeholder = 'Enter text here...',
  startWithEditView = true,
  language,
  ...props
}: TextSchemaBuilderProps): JSX.Element {
  // References for the ReactQuill editor and the Box container
  const quillRef = React.useRef<ReactQuill>(null);
  const boxRef = React.useRef<HTMLDivElement>(null);

  // State to track if the editor is in editing mode
  const [isEditing, setIsEditing] = React.useState<boolean>(startWithEditView);
  // const [showSuggestions, setShowSuggestions] = React.useState<boolean>(false); // Show/hide dropdown
  // const [filteredTags, setFilteredTags] = React.useState<string[]>([]); // Filtered tags
  // const [cursorPosition, setCursorPosition] = React.useState<{ top: number; left: number } | null>(null); // Dropdown position

  // Reference to track if the label has been clicked
  const hasClickedRef = React.useRef<boolean>(!!label);

  // Function to focus the cursor at the end of the text in the editor
  const focusCursorToEnd = React.useCallback(() => {
    if (quillRef.current && isEditing) {
      const editor = quillRef.current.getEditor();
      const length = editor.getLength();
      editor.setSelection(length, length); // Set cursor position to the end
    }
  }, [isEditing]);

  // Effect to ensure cursor is focused at the end when component mounts or editing mode changes
  React.useEffect(() => {
    focusCursorToEnd();
  }, [focusCursorToEnd]);

  // Effect to handle clicks outside the editor, toggling editing mode
  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Check if the click is outside the boxRef
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        if (!hasClickedRef.current) {
          setIsEditing(false); // Disable editing if unclicked
        } else {
          hasClickedRef.current = false; // Reset click state
        }
      }
    };

    // Add event listener for clicks
    document.addEventListener('click', handleOutsideClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [boxRef, hasClickedRef]);

  const handleChange = (value: string, _delta: any, source: string, editor: any) => {
    onLabelChange(DOMPurify.sanitize(value));

    if (source === 'user') {
      const quill = quillRef.current?.getEditor();
      const text = editor.getText(); // Get plain text

      const regex = /\[([^\]]+)\]/g; // Matches "[text]" format
      const matches = [];
      let match;
      while ((match = regex.exec(text)) !== null) {
        if (!tags.includes(match[1])) continue;
        matches.push({ match: match[1], index: match.index, length: match[0].length });
      }

      if (quill) {
        // Clear bold formatting for the entire content
        quill.formatText(0, text.length, 'tag', false);

        // Apply bold formatting only to matched tags
        matches.forEach(({ index, length }) => {
          quill.formatText(index, length, 'tag', true); // Apply the boldTag format
        });
      }
    }
  };

  // const handleTagSelection = (tag: string) => {
  //   const quill = quillRef.current?.getEditor();
  //   if (quill) {
  //     const cursorPos = quill.getSelection()?.index || 0;
  //     const textBeforeCursor = quill.getText(0, cursorPos);
  //     const tagStart = textBeforeCursor.lastIndexOf('['); // Find the starting `[`

  //     // Replace partial tag with the selected tag
  //     quill.deleteText(tagStart, cursorPos - tagStart);
  //     quill.insertText(tagStart, `[${tag}]`, { boldTag: true }); // Insert tag with bold format
  //     quill.focus();
  //     quill.setSelection(tagStart + tag.length + 2, tagStart + tag.length + 2); // Place cursor after the tag

  //     setShowSuggestions(false); // Hide suggestions
  //   }
  // };

  return (
    <Box w="100%" {...props} ref={boxRef}>
      {isEditing ? (
        // Render the ReactQuill editor when in editing mode
        <ReactQuill
          theme="snow"
          value={label || ''}
          placeholder={placeholder}
          modules={modules}
          onChange={handleChange}
          onBlur={(_, source) => {
            if (source === 'silent') {
              return;
            }
            setIsEditing(false);
          }} // Exit editing mode on blur
          ref={quillRef}
          style={{ borderRadius: theme.radii.md }}
        />
      ) : (
        // Render the static label or placeholder when not in editing mode
        <Box
          dangerouslySetInnerHTML={{ __html: label?.length ? label : placeholder }} // Render content as HTML
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(true); // Enter editing mode on click
          }}
          sx={{
            '& ol, & ul': {
              margin: 'revert',
              padding: 'revert',
            },
          }}
          color={label?.length ? theme.colors.black : theme.colors.gray[400]}
          cursor="text"
          fontSize="sm"
          _hover={{
            borderRadius: theme.radii.sm,
            outline: '1px solid',
            outlineColor: theme.colors.gray[300],
            outlineOffset: '4px',
          }}
        />
      )}

      {/* {showSuggestions && cursorPosition && (
        <Box
          style={{
            position: 'absolute',
            top: `${cursorPosition.top + 60}px`, // Adjust dropdown position
            left: `${cursorPosition.left}px`,
            background: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            zIndex: 1000,
            maxWidth: '200px',
            overflow: 'hidden',
          }}
        >
          <Stack divider={<Divider />} spacing={0}>
            {filteredTags.map((tag) => (
              <Box
                key={tag}
                padding={'4px 8px'}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#f9f9f9',
                }}
                onClick={() => handleTagSelection(tag)}
              >
                {tag}
              </Box>
            ))}
          </Stack>
        </Box>
      )} */}
    </Box>
  );
}

TextSchemaBuilder.defaultProps = {
  placeholder: 'Enter text here...',
  startWithEditView: true,
};
