import { AppointmentType, Position } from '../firebase/firestore/documents/position';

const adjustAppointmentDurationToInterviewDuration = (appointmentDuration?: number): number | undefined => {
  if (!appointmentDuration) {
    return undefined;
  }
  if (appointmentDuration <= 15) {
    return 15;
  }
  if (appointmentDuration <= 30) {
    return 30;
  }
  return 60;
};

export const getInterviewDuration = (position: Position): number => {
  return (
    position.interview?.interviewDuration ?? adjustAppointmentDurationToInterviewDuration(position.appointment?.duration) ?? 30
  );
};

export const getInterviewType = (position: Position): AppointmentType => {
  return position.interview?.interviewType ?? position.appointment?.type ?? AppointmentType.video;
};
