import firebase from 'firebase';

const buildPositionOverviewThumbnailURL = (accountId: string, positionId: string): string =>
  `/accounts/${accountId}/positions/${positionId}/videos/thumbnail.jpeg`;

const buildPositionOverviewVideoURL = (accountId: string, positionId: string) =>
  `/accounts/${accountId}/positions/${positionId}/videos/overview.mp4`;

const buildInboxPositionURL = (userId: string, positionId: string, fileName: string): string =>
  `/inbox/${userId}/positions/${positionId}/${fileName}`;

const buildVideoGalleryURL = (accountId: string, fileName: string, folderName: string): string =>
  `/video/${accountId}/${folderName}/${fileName}`;

const buildProfileImmageUrlURL = (fileName: string): string => `/images/profilePictures/${fileName}`;

const setFileRef = (path: string, file: File) => firebase.storage().ref(path).put(file);

const deleteFile = (path: string) => firebase.storage().ref(path).delete();

const getStorageFileDownloadURL = (path: string): Promise<string> =>
  firebase
    .storage()
    .ref(path)
    .getDownloadURL()
    .then((data) => {
      return data as string;
    })
    .catch(() => '');

const setPositionOverviewVideoRef = (userId: string, positionId: string, file: File): firebase.storage.UploadTask =>
  setFileRef(buildInboxPositionURL(userId, positionId, 'overview.mp4'), file);

const setVideoRef = (accountId: string, file: File, fileName: string, folderName: string): firebase.storage.UploadTask =>
  setFileRef(buildVideoGalleryURL(accountId, fileName, folderName), file);

const setVideoDeleteRef = async (accountId: string, folderName: string) =>
  deleteFile(`/video/${accountId}/${folderName}/thumbnail.jpeg`);
const setVideoThumbnailDeleteRef = async (accountId: string, folderName: string) =>
  deleteFile(`/video/${accountId}/${folderName}/overview.mp4`);

const setProfilePictureRef = (profilePictureName: string, file: File): firebase.storage.UploadTask =>
  setFileRef(buildProfileImmageUrlURL(profilePictureName), file);

const setPositionOverviewThumbnailRef = (userId: string, positionId: string, file: File): firebase.storage.UploadTask =>
  setFileRef(buildInboxPositionURL(userId, positionId, 'thumbnail.jpeg'), file);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findPositionOverviewVideoThumbnailRef = (accountId: string, positionId: string): Promise<string> =>
  getStorageFileDownloadURL(buildPositionOverviewThumbnailURL(accountId, positionId));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPositionOverviewVideoRef = (accountId: string, positionId: string): Promise<string> =>
  getStorageFileDownloadURL(buildPositionOverviewVideoURL(accountId, positionId));

export {
  buildInboxPositionURL,
  buildPositionOverviewThumbnailURL,
  setPositionOverviewVideoRef,
  setPositionOverviewThumbnailRef,
  getPositionOverviewVideoRef,
  findPositionOverviewVideoThumbnailRef,
  setProfilePictureRef,
  getStorageFileDownloadURL,
  setVideoRef,
  setVideoDeleteRef,
  setVideoThumbnailDeleteRef,
};
