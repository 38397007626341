import { useMemo } from 'react';
import _isNil from 'lodash/isNil';
import { useCurrentUserProfile } from './useCurrentUserProfile';
import { useQueryOnce } from '../../firebase/firestore/hooks';
import { Candidate, CandidateStatus } from '../../firebase/firestore/documents/candidate';
import { Collections } from '../../firebase/firestore/collections';
import { Query } from '../../firebase/firestore/query/query';

export const useCandidatesWithOfferAlreadyMade = (seekerId?: string): Readonly<Candidate>[] | undefined => {
  const { currentUserProfile } = useCurrentUserProfile();
  const candidates = useQueryOnce<Readonly<Candidate>>(Collections.CANDIDATES, [
    Query.field<Candidate>('monetized').equals(true),
    Query.field<Candidate>('seeker').equals(seekerId),
    Query.field<Candidate>('account').equals(currentUserProfile?.account),
    Query.field<Candidate>('positionOpen').equals(true),
    Query.field('computedStatus.status').in([CandidateStatus.OfferMade, CandidateStatus.Hired, CandidateStatus.HireInReview]),
  ]);

  return useMemo(() => {
    if (!currentUserProfile || _isNil(candidates)) {
      return undefined;
    }
    return candidates;
  }, [candidates, currentUserProfile]);
};
