import { IconType } from 'react-icons';
import { MdTextFields, MdOutlinePin, MdFiberPin, MdLink } from 'react-icons/md';
import { IoCalendar, IoMail, IoLocation, IoToggle, IoCheckbox, IoRadioButtonOnOutline, IoImage, IoCall } from 'react-icons/io5';
import { TbNewSection } from 'react-icons/tb';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import EmailSchemaBuilder from './EmailSchemaBuilder';
import PhoneSchemaBuilder from './PhoneSchemaBuilder';
import SINSchemaBuilder from './SINSchemaBuilder';
import LinkSchemaBuilder from './LinkSchemaBuilder';
import DatePickerSchemaBuilder from './DatePickerSchemaBuilder';
import NumberSchemaBuilder from './NumberSchemaBuilder';
import RadioSchemaBuilder from './RadioSchemaBuilder';
import { MultipleChoiceImage } from '../../positionManagement/PositionType';
import MCQSchemaBuilder from './MCQSchemaBuilder';
import YesNoSchemaBuilder from './YesNoSchemaBuilder';
import AddressSchemaBuilder from './AddressSchemaBuilder';
import ImageSchemaBuilder from './ImageSchemaBuilder';
import TextFieldSchemaBuilder from './TextFieldSchemaBuilder';
import { v4 as uuidv4 } from 'uuid';

export const languages = ['en', 'fr'] as const;
export type Language = typeof languages[number];

export enum pageElements {
  section = 'section',
}
export type PageElementTypes = keyof typeof pageElements;

export enum formBuilderType {
  text = 'text',
  number = 'number',
  date = 'date',
  email = 'email',
  address = 'address',
  phone = 'phone',
  yesNo = 'yesNo',
  checkbox = 'checkbox',
  radio = 'radio',
  image = 'image',
  sin = 'sin',
  link = 'link',
}

export type FormBuilderTypes = keyof typeof formBuilderType;

export type LabelSchema = (Required<Pick<Record<Language, string>, 'en'>> | Required<Pick<Record<Language, string>, 'fr'>>) &
  Partial<Record<Language, string>>;
export interface OptionSchema {
  timestamp: string;
  id: string;
  text: LabelSchema;
  isSelected?: boolean;
}
export interface FieldSchema {
  timestamp: number;
  id: string;
  inputType: FormBuilderTypes;
  order: number;
  section?: string;
  label?: LabelSchema;
  required: boolean;
  text: LabelSchema;
  validations?: Record<string, any>;
  optionList?: OptionSchema[];
  followUpQuestionList?: FieldSchema[];
  hintText?: Partial<Record<Language, string>>;
  parent?: string | null;
  optionId?: string | null;
  isInfo?: boolean;
  link?: string;
  image?: MultipleChoiceImage;
}

export interface SchemaBuilderProps {
  defaultValue: FieldSchema;
  onChange: (schema: FieldSchema) => void;
  language: Language;
  followUpDragOver?: () => void;
  path: (string | number)[];
}
export interface FormBuilderSchema {
  type: FormBuilderTypes;
  component: ({ defaultValue, onChange }: SchemaBuilderProps) => JSX.Element;
  props: any;
  defaultValue: FieldSchema;
  icon: IconType;
  label: string;
}

export const formBuilderSchemas: Record<FormBuilderTypes, FormBuilderSchema> = {
  [formBuilderType.text]: {
    type: formBuilderType.text,
    component: TextFieldSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.text,
      required: true,
      order: 1,
      text: { en: '' },
      validations: {
        minLength: 5,
        maxLength: 200,
      },
    },
    icon: MdTextFields,
    label: 'Text',
  },
  [formBuilderType.email]: {
    type: formBuilderType.email,
    component: EmailSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.email,
      required: true,
      order: 2,
      text: {
        en:
          'What is your primary email address? Note that this email address will be used to send you a copy of the information you’ve entered here.',
      },
      label: { en: 'Email address' },
      validations: {
        minLength: 5,
        maxLength: 200,
      },
    },
    icon: IoMail,
    label: 'Email',
  },
  [formBuilderType.phone]: {
    type: formBuilderType.phone,
    component: PhoneSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.phone,
      required: true,
      order: 3,
      text: { en: 'What is your phone number?' },
      label: { en: 'Phone number' },
    },
    icon: IoCall,
    label: 'Phone',
  },
  [formBuilderType.sin]: {
    type: formBuilderType.sin,
    component: SINSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.sin,
      required: true,
      order: 4,
      text: { en: 'Enter your Social Insurance Number' },
      label: { en: 'SIN (Social Insurance Number)' },
    },
    icon: MdFiberPin,
    label: 'SIN',
  },
  [formBuilderType.link]: {
    type: formBuilderType.link,
    component: LinkSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.link,
      required: true,
      order: 5,
      text: { en: '' },
      label: { en: 'Link' },
      link: '',
    },
    icon: MdLink,
    label: 'Link',
  },
  [formBuilderType.date]: {
    type: formBuilderType.date,
    component: DatePickerSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.date,
      required: true,
      order: 6,
      text: { en: '' },
      label: { en: 'Date' },
      validations: {},
    },
    icon: IoCalendar,
    label: 'Date',
  },
  [formBuilderType.number]: {
    type: formBuilderType.number,
    component: NumberSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.number,
      required: true,
      text: { en: '' },
      label: { en: 'Number' },
      order: 7,
      validations: {
        min: 0,
        max: 100000,
      },
    },
    icon: MdOutlinePin,
    label: 'Number',
  },
  [formBuilderType.radio]: {
    type: formBuilderType.radio,
    component: RadioSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.radio,
      required: true,
      text: { en: '' },
      label: { en: 'Single Choice' },
      order: 8,
      optionList: [
        { timestamp: 'A', id: uuidv4(), text: { en: 'Option 1' } },
        { timestamp: 'B', id: uuidv4(), text: { en: 'Option 2' } },
        { timestamp: 'C', id: uuidv4(), text: { en: 'Option 3' } },
      ],
    },
    icon: IoRadioButtonOnOutline,
    label: 'Single Choice',
  },
  [formBuilderType.checkbox]: {
    type: formBuilderType.checkbox,
    component: MCQSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.checkbox,
      required: true,
      text: { en: '' },
      label: { en: 'Multiple Choice' },
      order: 9,
      optionList: [
        { timestamp: 'A', id: uuidv4(), text: { en: 'Option 1' } },
        { timestamp: 'B', id: uuidv4(), text: { en: 'Option 2' } },
        { timestamp: 'C', id: uuidv4(), text: { en: 'Option 3' } },
      ],
    },
    icon: IoCheckbox,
    label: 'Multiple Choice',
  },
  [formBuilderType.yesNo]: {
    type: formBuilderType.yesNo,
    component: YesNoSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.yesNo,
      required: true,
      text: { en: '' },
      label: { en: 'Yes/No' },
      order: 10,
      optionList: [
        { timestamp: 'Yes', id: uuidv4(), text: { en: 'Yes', fr: 'Oui' } },
        { timestamp: 'No', id: uuidv4(), text: { en: 'No', fr: 'Non' } },
      ],
    },
    icon: IoToggle,
    label: 'Yes/No',
  },
  [formBuilderType.address]: {
    type: formBuilderType.address,
    component: AddressSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.address,
      required: true,
      text: { en: 'What is your current address? Use the button below to search addresses.' },
      label: { en: 'Address' },
      order: 11,
    },
    icon: IoLocation,
    label: 'Address',
  },
  [formBuilderType.image]: {
    type: formBuilderType.image,
    component: ImageSchemaBuilder,
    props: {},
    defaultValue: {
      timestamp: Date.now(),
      id: uuidv4(),
      inputType: formBuilderType.image,
      required: true,
      text: { en: '' },
      label: { en: 'Image' },
      order: 12,
      validations: {
        acceptType: ['image/jpg', 'image/jpeg', 'image/png'],
        maxFileSize: 2 * 1024 * 1024, // 2MB
      },
    },
    icon: IoImage,
    label: 'Image',
  },
};

export interface PageElementSchema {
  key: string;
  text: { en: string; fr: string };
  icon: IconType;
  label: string;
}

export const pageElementSchema: Record<PageElementTypes, PageElementSchema> = {
  section: {
    key: 'section',
    text: { en: 'Section', fr: 'Section' },
    icon: TbNewSection,
    label: 'Section',
  },
};

export interface HrFromTemplate {
  id: string;
  account: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface HrFormTemplateGetAllResponse {
  questions: HrFromTemplate[];
}
export interface SectionSchema {
  section: string;
  fields: FieldSchema[];
}

export function generateFormSchema(sectionSchema: SectionSchema[], language: Language) {
  return sectionSchema
    .map((section) => {
      return section.fields.map((field) => ({
        ...omit(field, ['timestamp', 'hintText', 'validations', 'required']),
        section: section.section,
        label: field.label?.[language],
      }));
    })
    .flat()
    .map((item, index) => {
      const temp = { ...omitBy(item, isNil) };
      return {
        ...temp,
        order: index + 1,
      } as FieldSchema;
    });
}
