import { Collections } from '../../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../../firebase/firestore/hooks';
import { Query } from '../../../firebase/firestore/query/query';
import { GroupEventConfiguration } from '../../../firebase/firestore/documents/groupEvent';
import { BUNDLE_ID_GLOBAL, MINUTES_AFTER_GROUP_EVENT_WHEN_USER_CAN_JOIN } from '../../../firebase/firestore/constants';

export const useMinutesAfterGroupEventWhenUsersCanJoin = () => {
  const minutesAfterGroupEventWhenUsersCanJoin = useQuerySnapshot<GroupEventConfiguration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_GLOBAL),
    Query.field('name').equals(MINUTES_AFTER_GROUP_EVENT_WHEN_USER_CAN_JOIN),
  ]);
  if (minutesAfterGroupEventWhenUsersCanJoin) {
    return minutesAfterGroupEventWhenUsersCanJoin[0]?.value;
  }
  return undefined;
};
