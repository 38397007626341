import React, { ChangeEvent } from 'react';
import { Box, Text, Stack, Checkbox } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchAndSortTypes } from '../filters/seekersFiltersContextValueType';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';
import { FitStatus } from '../../../../firebase/firestore/documents/candidate';

export const AvailabilityFit = (): JSX.Element => {
  const { t } = useTranslation('audioEvaluation');

  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();

  const onHandleBackgroundCheck = (searchAndSort: SearchAndSortTypes) => {
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...searchAndSort });
  };

  return (
    <Box>
      <Stack direction="column">
        {Object.values(FitStatus).map((value) => (
          <Checkbox
            data-testid={`availabilityFit_${value}`}
            key={`${value}`}
            isChecked={searchAndSortTypes.availabilityFit.includes(value.toLowerCase())}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                searchAndSortTypes.availabilityFit.push(value.toLowerCase());
              } else {
                const index = searchAndSortTypes.availabilityFit.indexOf(value.toLowerCase());
                searchAndSortTypes.availabilityFit.splice(index, 1);
              }
              onHandleBackgroundCheck(searchAndSortTypes);
            }}
          >
            <Text
              px={3}
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
              }}
            >
              {t(`common:profile.matchingRequisition.${value.toLowerCase()}Fit`)}
            </Text>
          </Checkbox>
        ))}

        <Checkbox
          data-testid="availabilityFit_notFit"
          isChecked={searchAndSortTypes.availabilityFit.includes('NOT_FIT')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.availabilityFit.push('NOT_FIT');
            } else {
              const index = searchAndSortTypes.languageEvaluation.indexOf('NOT_FIT');
              searchAndSortTypes.availabilityFit.splice(index, 1);
            }
            onHandleBackgroundCheck(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t(`common:profile.matchingRequisition.noFit`)}
          </Text>
        </Checkbox>
      </Stack>
    </Box>
  );
};
