import React from 'react';
import { useTranslation } from 'react-i18next';
import _isNil from 'lodash/isNil';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Heading, Flex, Text, Tooltip as ChakraTooltip } from '@chakra-ui/core';
import { HiArrowNarrowUp, HiArrowNarrowDown } from 'react-icons/hi';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import colors from '../../../../../styles/colors';
import i18n from '../../../../../locales/i18n';
import { useRecruiterManagementContext } from '../../RecruiterManagementContext';

export type BarDataType = {
  name: string;
  uv: number;
  pv: number;
}[];

export type BarDateRangeType = { current: string; previous: string };

const calcDifference = function (a: number, b: number) {
  /* istanbul ignore else */
  if (a <= 0) return -100;
  /* istanbul ignore else */
  if (b <= 0) return 100;
  return a < b ? (-(b - a) * 100) / a : ((a - b) * 100) / b;
};

export type BarComparisonChartViewProps = {
  data: BarDataType;
  givenDateRange?: BarDateRangeType;
  givenComparisonPercentage?: number;
  renderedBy?: string;
};

const BarComparisonChartView = ({
  data,
  givenDateRange,
  givenComparisonPercentage,
  renderedBy,
}: BarComparisonChartViewProps): JSX.Element => {
  const { startDate, endDate } = useRecruiterManagementContext();

  const givenEndDate = moment(endDate);
  const givenStartdate = moment(startDate);
  const selectedDateDifferenceInDays = givenEndDate.diff(givenStartdate, 'days');

  const getFormattedDateWithoutYear = (date: Date): string => {
    const dateWithMonthDay = moment(date).format(
      moment
        .localeData()
        .longDateFormat('ll')
        .replace(/Y/g, '')
        .replace(/^\W|\W$|\W\W/, ''),
    );
    return dateWithMonthDay;
  };

  const customDateRangeString: BarDateRangeType = {
    current: `${getFormattedDateWithoutYear(startDate)} - ${getFormattedDateWithoutYear(endDate)}`,
    previous: `${getFormattedDateWithoutYear(moment().subtract(60, 'days').toDate())} - ${getFormattedDateWithoutYear(
      moment().locale(i18n.language).subtract(31, 'days').toDate(),
    )}`,
  };
  const dateRange = givenDateRange || customDateRangeString;
  const { t } = useTranslation('recruiterManagement');
  const comparisonPercentage = !_isNil(givenComparisonPercentage)
    ? givenComparisonPercentage
    : Math.round(
        calcDifference(
          data.reduce((partialSum, eachData) => partialSum + eachData.uv, 0),
          data.reduce((partialSum, eachData) => partialSum + eachData.pv, 0),
        ),
      );

  // istanbul ignore next
  const CustomizedAxisTick = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { x, y, payload } = props;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const str = `${payload.value}`;
    const [first, ...rest] = str.split(' ');
    return (
      <g transform={`translate(${x},${y})`} style={{ fontSize: '14px' }}>
        <text dy={16} textAnchor="middle" fill="#737B8B">
          {first}
        </text>
        <text dy={32} textAnchor="middle" fill="#737B8B">
          {rest.join(' ')}
        </text>
      </g>
    );
  };
  return (
    <Box width="100%" height="100%">
      <Box height="20%" ml={20}>
        <Heading mb={2.5} size="md" fontWeight={500} color="black">
          {t('recruiterManagement:recruiterDataVisualisation.videoInterviewDetails')}
        </Heading>

        <Flex fontWeight={600} fontSize={14} mb={2.5}>
          <Flex color={comparisonPercentage > 0 ? '#149D52' : '#B33636'} alignItems="center">
            {comparisonPercentage !== 0 && (
              <>
                {comparisonPercentage > 0 ? (
                  <HiArrowNarrowUp data-testid="upArrowBar" />
                ) : (
                  <HiArrowNarrowDown data-testid="downArrowBar" />
                )}
              </>
            )}
            <span>{`${comparisonPercentage.toFixed(2)}%`}</span>
          </Flex>
          <Text ml={1} color="black" opacity="0.5" display="flex">
            {t('recruiterManagement:recruiterDataVisualisation.vsLastMonth', { daysDifference: selectedDateDifferenceInDays })}
            {renderedBy === 'DASHBOARD' && (
              <Box ml={1} display="flex" alignItems="center" data-testid="tooltipIcon">
                <ChakraTooltip label={t('recruiterDashboardTooltipFor.barChartPercentage')} fontSize="md" placement="top">
                  <InfoOutlineIcon />
                </ChakraTooltip>
              </Box>
            )}
          </Text>
        </Flex>

        <Text fontSize={15} fontWeight={400} color="#000000" opacity={0.5}>{`${dateRange.current} , ${dateRange.previous}`}</Text>
      </Box>
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          width={600}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis interval={0} dataKey="name" height={60} tick={<CustomizedAxisTick />} />
          <YAxis tickCount={5} axisLine={false} tickLine={false} />
          <Tooltip />
          <Legend
            align="center"
            width={400}
            margin={{
              left: 85,
            }}
            iconType="circle"
            payload={[
              { value: dateRange.current, id: dateRange.current, color: colors.blue.default },
              { value: dateRange.previous, id: dateRange.previous, color: colors.gray[400] },
            ]}
          />
          <Bar dataKey="uv" fill={colors.blue.default} barSize={10} name={dateRange.current} />
          <Bar dataKey="pv" fill={colors.gray[400]} barSize={10} name={dateRange.previous} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BarComparisonChartView;
