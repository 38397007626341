export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// function to determine background color for availability selection
export const getGradientBackground = (availability: string, deg?: string) => {
  return availability === 'Day'
    ? `linear-gradient(${deg ?? '5.6'}deg, #E9ECF8 49%, #fff 51%)`
    : `linear-gradient(${deg ?? '5.6'}deg, #fff 49%, #E9ECF8 51%)`;
};
