/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState, Fragment, ReactText } from 'react';
import { Badge, Box, Button, Divider, Flex, Heading, Input, InputGroup, InputLeftElement, Text, Tooltip } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { RiSearchLine, RiStackLine } from 'react-icons/ri';
import { TbCalendarStats } from 'react-icons/tb';
import { useStoreState } from '../../../models/hooks';
import { ANIMATED_VARIANTS } from '../../../routes/constants';
import colors from '../../../styles/colors';
import { CalendarEvents } from '../../../firebase/firestore/documents/appointmentAvaiability';
import { ScheduleListView } from '../scheduleList/ScheduleListView';
import { EventListView } from '../scheduleList/EventListView';
import { CalendarEventType } from '../CalendarEventType';
import { loginButtonStyle } from '../../auth/login/loginForm/LoginForm.styles';
import { RecruiterCalendarSidebarTabTypeEnum } from '../CalendarSidebarTab';
import { RecruiterCalendarPositionList } from '../../administration/recruiterCalendar/RecruiterCalendarPositionList';
import { Position } from '../../../firebase/firestore/documents/position';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import OtherCalendarView from '../otherCalendarEvents/OtherCalendarView';

const MotionBox = motion.custom(Box);

export type ScheduleByDateProps = {
  onOpen: () => void;
  eventList: CalendarEvents;
  createNewEvent: () => void;
  handleEvent: (event: CalendarEventType, actionType: string) => void;
  positions?: Position[];
  handleCheckedPositions: ((recruiterIds: ReactText[]) => void) | undefined;
  checkedPositions?: string[];
  setActiveTab: (activeTab: RecruiterCalendarSidebarTabTypeEnum) => void;
  activeTab?: RecruiterCalendarSidebarTabTypeEnum;
};

export const ScheduleSidebarView = ({
  eventList,
  createNewEvent,
  onOpen,
  handleEvent,
  positions,
  handleCheckedPositions,
  checkedPositions,
  setActiveTab,
  activeTab,
}: ScheduleByDateProps): JSX.Element => {
  const accountStore = useStoreState((s) => s.app.accounts);
  const { t } = useTranslation('calendar');
  const [groupEventEnabled, setGroupEvenEnabled] = useState<boolean | undefined>(false);
  const [searchPosition, setSearchPosition] = useState<string>('');
  const { currentUserProfile } = useCurrentUserProfile();
  const isGoogleCalendarIntegrateEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_INTEGRATE_GOOGLE_CALENDAR_FEATURE];
  });
  const isMicrosoftCalendarIntegrateEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_INTEGRATE_MICROSOFT_CALENDAR_FEATURE];
  });

  useEffect(() => {
    // istanbul ignore next
    if (accountStore) {
      setGroupEvenEnabled(accountStore?.configuration?.groupEventEnabled);
    } else {
      setGroupEvenEnabled(false);
    }
  }, [accountStore]);

  return (
    <MotionBox
      data-testid="ScheduleSidebarView"
      initial="initial"
      animate="in"
      exit="out"
      height="100%"
      variants={ANIMATED_VARIANTS}
    >
      <Flex height="100%" flexDirection="column" justifyContent="space-between">
        <Box>
          <Flex px={4} py={3} justify="space-between" borderBottomWidth="1px" borderColor="gray.50">
            {groupEventEnabled && (
              <Button
                _focus={{ boxShadow: 'none' }}
                variant="solid"
                size="xs"
                borderRadius={3}
                css={loginButtonStyle}
                onClick={createNewEvent}
                data-testid="CreateEventButton"
                py={2}
                px={4}
                height={10}
                mr={2}
              >
                {t('eventForm.createEvent')}
              </Button>
            )}
            <Button
              size="xs"
              py={2}
              px={4}
              height={10}
              _focus={{ boxShadow: 'none' }}
              colorScheme="blue.default"
              color={colors.blue.default}
              variant="outline"
              borderRadius={3}
              onClick={onOpen}
              data-testid="OpenDrawerBtn"
            >
              {t('availabilityForm.myAvailabilityLabel')}
            </Button>
          </Flex>
          <Flex>
            <Heading
              cursor="pointer"
              d="flex"
              flex={1}
              alignItems="flex-start"
              as="h4"
              size="sm"
              px={4}
              py={3}
              borderBottomWidth="2px"
              borderColor={activeTab === RecruiterCalendarSidebarTabTypeEnum.SCHEDULE ? 'blue.500' : 'gray.50'}
              color={activeTab === RecruiterCalendarSidebarTabTypeEnum.SCHEDULE ? 'blue.500' : 'gray.600'}
              data-testid="ScheduleTabHeader"
              onClick={() => {
                setActiveTab(RecruiterCalendarSidebarTabTypeEnum.SCHEDULE);
              }}
            >
              <Box as="span" mr={2}>
                <TbCalendarStats style={{ fontSize: '18px' }} />
              </Box>
              <Box as="span" lineHeight="1.1">
                {t('availabilityForm.schedule')}
              </Box>
            </Heading>
            <Tooltip label={t('availabilityForm.myPositionsToolTip')} placement="right-end">
              <Heading
                cursor="pointer"
                d="flex"
                flex={1}
                alignItems="flex-start"
                as="h4"
                size="sm"
                px={4}
                py={3}
                borderBottomWidth="2px"
                borderColor={activeTab === RecruiterCalendarSidebarTabTypeEnum.MY_POSITION ? 'blue.500' : 'gray.50'}
                color={activeTab === RecruiterCalendarSidebarTabTypeEnum.MY_POSITION ? 'blue.500' : 'gray.600'}
                data-testid="MyPositionTabHeader"
                onClick={() => {
                  setActiveTab(RecruiterCalendarSidebarTabTypeEnum.MY_POSITION);
                }}
              >
                <Box as="span" mr={2}>
                  <RiStackLine style={{ fontSize: '18px' }} />
                </Box>
                <Box as="span" lineHeight="1.1">
                  {t('availabilityForm.myPositions')}
                </Box>
              </Heading>
            </Tooltip>
          </Flex>
          <Box
            className={`calendar-sidebar-middle-box ${
              isGoogleCalendarIntegrateEnabled || isMicrosoftCalendarIntegrateEnabled ? 'has-google-calendar-box' : ''
            }`}
          >
            {activeTab === RecruiterCalendarSidebarTabTypeEnum.SCHEDULE ? (
              <Fragment>
                <Box bg="#EAF5FF" px={4} py={1}>
                  <Text fontSize="sm">{moment(eventList.activeDate).format('L')}</Text>
                </Box>
                <Divider borderColor="gray.100" />
                <Box className="schedule-counter-box" px={4} py={3}>
                  <Heading as="h4" size="sm" d="flex" justifyContent="space-between" alignItems="center">
                    {t('calendar.individualInterview')}
                    <Badge variant="solid" background={colors.blue.default}>
                      {' '}
                      {eventList.bookings.length}
                    </Badge>
                  </Heading>
                </Box>
                <Divider borderColor="gray.50" />
                <Box>
                  {eventList.bookings.length ? (
                    <ScheduleListView listBooking={eventList.bookings} />
                  ) : (
                    <Box mx={4}>
                      <Text fontSize="sm" py={3}>
                        {t('calendar.noInterviews')}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Divider borderColor="gray.100" />
                {groupEventEnabled && (
                  <Box>
                    <Box className="schedule-counter-box" px={4} py={3}>
                      <Heading as="h4" size="sm" d="flex" justifyContent="space-between" alignItems="center">
                        {/* Today's */}
                        {t('eventForm.groupEventTitle')}
                        <Badge variant="solid" background={colors.blue.default}>
                          {' '}
                          {eventList.groupEvents.length}
                        </Badge>
                      </Heading>
                    </Box>
                    <Divider borderColor="gray.50" />
                    {eventList.groupEvents.length ? (
                      <EventListView eventList={eventList.groupEvents} handleEvent={handleEvent} />
                    ) : (
                      <Box mx={4}>
                        <Text fontSize="sm" py={3}>
                          {t('calendar.noGroupEvents')}
                        </Text>
                      </Box>
                    )}
                    <Divider borderColor="gray.100" />
                  </Box>
                )}
              </Fragment>
            ) : (
              handleCheckedPositions && (
                <Fragment>
                  <Box p={[5, 4]}>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none" p={0} style={{ color: '#CFD8DC', width: '1.8rem' }}>
                        <RiSearchLine />
                      </InputLeftElement>
                      <Input
                        data-testid="SearchInputField"
                        value={searchPosition}
                        placeholder={t('administration:calendar.sidebarPositionSearchPlaceholder')}
                        onChange={(e) => setSearchPosition(e.target.value)}
                        style={{ paddingLeft: '1.8rem', paddingRight: '0.5rem' }}
                      />
                    </InputGroup>
                  </Box>
                  <Divider borderColor="gray.100" />
                  <Flex justifyContent="end" px={4} py={2}>
                    <Button
                      colorScheme="blue"
                      variant="link"
                      size="xs"
                      data-testid="ClearBtn"
                      onClick={() => handleCheckedPositions([])}
                      _focus={{
                        boxShadow: 'none',
                      }}
                    >
                      {t('administration:calendar.sidebarPositionClearAll')}
                    </Button>
                  </Flex>
                  <Divider borderColor="gray.100" />
                  <RecruiterCalendarPositionList
                    positions={positions}
                    searchText={searchPosition}
                    handleCheckedPositions={handleCheckedPositions}
                    checkedPositions={checkedPositions}
                  />
                </Fragment>
              )
            )}
          </Box>
        </Box>
        <Box>
          {(isGoogleCalendarIntegrateEnabled || isMicrosoftCalendarIntegrateEnabled) && (
            <OtherCalendarView currentUserProfile={currentUserProfile} />
          )}
        </Box>
      </Flex>
    </MotionBox>
  );
};
