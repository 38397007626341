import { HeadingPropValue } from './recruiterVisualisation/statWithProgressBarAndComparison/StatWithProgressBarAndComparison';

export type Recruiter = {
  id: string;
  firstName: string;
  lastName: string;
  availabilityPercent: {
    availableDuration?: number;
    requiredDuration?: number;
    availabilityPercentage?: number;
  };
  hireCount: number;
  successfulInterviews?: number;
  cancelledInterviews?: number;
  candidateNoShow?: number;
  recruiterNoShow?: number;
};

export type RecruiterListResponse = { recruitersData: Recruiter[] };

export type RecruiterListQueryParams = {
  accountId: string;
  startDate: Date;
  endDate: Date;
};

export type PieAndStatInfoParams = {
  accountId: string;
  startDate: Date;
  endDate: Date;
};

export type GetRecruitersProfileDataQueryParams = {
  recruiterId: string;
  accountId: string;
  startDate: Date;
  endDate: Date;
};

export type SingleRecruiterType = {
  id?: string;
  name?: string;
  availability?: string;
  noOfHirings?: string;
  successfulInterviews?: number;
  interviewCancellations?: number;
  candidateNoShow?: number;
  recruiterNoShow?: number;
  totalAvailabilityHours?: number;
  requiredDuration?: number;
};

export type SingleRecruitersCandidateType = {
  candidateId: string;
  candidateName: string;
  businessPosition: string;
  businessLocation: string;
  notes?: string;
  status: string;
};

export type RecruitersCandidateListType = SingleRecruitersCandidateType[];

export type RecruiterProfileDataType = {
  interviewCompleted: {
    previous: number;
    current: number;
  };
  recruiterNoShow: {
    previous: number;
    current: number;
  };
  candidateNoShow: {
    previous: number;
    current: number;
  };
  totalInterviews: {
    previous: number;
    current: number;
  };
  email: string;
  lastLogin: string;
};

export type RecruitersProfileDataResponse = {
  candidatesTableData: RecruitersCandidateListType;
  recruiterProfileData: RecruiterProfileDataType;
};

export type BarGraphResponseType = {
  totalCandidates: {
    current: number;
    previous: number;
  };
  interviewCompleted: {
    current: number;
    previous: number;
  };
  recruiterNoShow: {
    current: number;
    previous: number;
  };
  candidateNoShow: {
    current: number;
    previous: number;
  };
};

export type PieChartResponseType = {
  openPositions: number;
  closedPositions: number;
  archivedPositions: number;
};

export type IncompleteInterviewsType = {
  incompleteInterviews: number;
  cancelledInterviews: number;
};

export type ScheduleAndInterviewType = {
  totalScheduled: number;
  totalInterviewed: {
    value: number;
    comparison?: number;
  };
  upcomingInterviews?: number;
};

export type InterviewDetailsType = {
  candidateNoShow: number;
  recruiterNoShow: {
    value: number;
    comparison?: number;
  };
};

export type TotalCandidatesType = {
  totalCandidates: number;
  hiredCandidates: number;
};

export type StatDataType = {
  incompleteInterviews: { statHeadingValue: HeadingPropValue };
  scheduleAndInterview: { statHeadingValue: HeadingPropValue; comparisonPercentage: number };
  interviewDetails: { statHeadingValue: HeadingPropValue; comparisonPercentage: number };
  totalCandidates: { statHeadingValue: HeadingPropValue };
};

export type PieAndStatInfoResponse = {
  incompleteInterviews: IncompleteInterviewsType;
  scheduleAndInterview: ScheduleAndInterviewType;
  interviewDetails: InterviewDetailsType;
  totalCandidates: TotalCandidatesType;
  pieChart: PieChartResponseType;
  barGraph: BarGraphResponseType;
};

export enum AvailabilityType {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NONE = 'none',
}
