import { css } from '@emotion/core';

export const interviewRoomBlock = css`
  background: #111111;
  position: relative;

  .participant-name {
    font-size: 12px;
  }

  .interviewRoomHeader {
    color: #fff;
    padding: 5px 20px;

    .chakra-stack {
      svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      .chakra-badge {
        background: #049be5;
        color: #fff;
        height: 24px;
        width: 24px;
        border-radius: 5px;
        margin-left: 9px;
        font-weight: 700;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .participant-view {
    height: initial;
  }
  .main-participant {
    height: calc(100vh - 270px);

    .publication-track {
      height: 100%;

      .video-publication {
        height: 100%;

        video {
          object-fit: cover !important;
        }
      }
      .avatar-container {
        height: 100% !important;

        .avatar-block {
          padding: 2rem !important;
          height: 6rem !important;
          width: 6rem !important;
        }
      }
    }
  }
  .participants-blocks {
    padding: 5px;
    .interview-participant {
      height: initial;
      flex-direction: row;
    }
    .publication-track {
      .video-publication {
        video {
          height: 135px !important;
          object-fit: cover !important;
        }
      }
      .avatar-container {
        height: 135px !important;
        max-width: 120px !important;
        background: #333;
      }
    }

    .participant-info-bar {
      position: absolute;
      bottom: 0 !important;
      left: 0;
      width: 100%;
      flex-direction: column-reverse;
      justify-content: space-around;
      height: 100%;
    }
  }
  .interview-with-guest {
    .css-z46iq5-InterviewingRoom {
      height: auto;
      max-width: 150px;
    }

    .participants-blocks {
      overflow-x: auto;
      position: relative;
      z-index: 1;
    }
    .participant-view {
      margin-right: 3px;
      width: 160px;
      background: #333;
      min-width: 150px;
    }

    .main-participant .publication-track .video-publication video {
      object-fit: contain !important;
    }

    .participants-blocks .publication-track .avatar-container {
      height: 135px !important;
      max-width: 120px !important;
      background: #333;
    }
  }
`;

export const mainParticipantStyle = css`
  width: 100%;
  height: calc(100vh - 64px);
  background: black;
  overflow: hidden;

  .waiting-block {
    height: 100%;
    background-color: #1c1b2c;
    svg {
      width: 40px;
      height: 40px;
      display: inline-block;
      margin-bottom: 5px;
      path {
        fill: #ffffff;
      }
    }
  }
`;

export const mainParticipantBlockStyle = css`
  width: calc(100% - 280px);

  .publication video {
    object-fit: contain !important;
    height: calc(100vh -70px);
  }
`;

export const participantBlockStyle = css`
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
`;

export const interviewRoomActionBar = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 320px;
  padding: 20px;
  right: 0;
  margin: auto;

  .chakra-button {
    background: #f3f3f3;
    border-radius: 50%;
    font-size: 28px;
    width: 43px;
    height: 43px;
    padding: 0;

    &.btn-call-end {
      background-color: #f34d4f;
    }
  }
`;

export const videosWrapper = css`
  height: calc(100vh - 64px);
  position: relative;
`;

export const InterviewVideoBlock = css`
  position: fixed;
  z-index: 9;
  width: calc(24% - 1px);
  min-width: 200px;
  top: 64px;
`;
