export default {
  uploadLogoCard: {
    headerText: 'Upload Company Logo',
    hintText: 'Please upload (200*200) image',
  },
  error: {
    fileTypeError: 'Your selected file type is not allowed',
    sizeError: 'Selected file size exceeds 5 MB',
  },
  editHintText: 'Change company logo',
  removeHintText: 'Remove logo',
  removeConfirmationModal: {
    headerText: 'Delete logo ?',
    bodyText: 'Are you sure you want to delete the logo? There will be changes on the web apply page.',
    yes: 'Yes',
    no: 'No',
  },
  uploadNoteText: 'Allowed file types .jpg, .png, .jpeg',
  dragAndDropCard: {
    mainText: 'Drag and drop your file here, or click Browse',
    uploadSize: 'Max file size 5 MB',
    buttonText: 'Browse',
  },
  apiResponse: {
    title: 'Company Logo',
    successText: '',
    errorText: 'Failed to remove company logo',
  },
};
