export default {
  audioEvaluationSubmission: {
    successful: 'Language Evaluation successful',
    unsuccessful: 'Language Evaluation unsuccessful',
  },
  audioRating: {
    accuracy: 'Accuracy',
    fluency: 'Fluency',
    pronunciation: 'Pronunciation',
  },
  phrase: 'Phrase',
  evaluateAudio: 'Evaluate The Audio Recording',
  submit: 'Submit',
  sendInterviewRequest: 'Send Interview Request',
  languageEvaluation: 'Language Evaluation',
  status: {
    EXPERT: 'Expert',
    COMPETENT: 'Competent',
    LIMITED: 'Limited',
    OCCASIONAL: 'Minimal',
    NEEDS_REVIEW: 'Needs Interview',
    TO_BE_ACCESSED: 'To Be Assessed',
    RE_REQUESTED: 'Re-Requested',
    REQUESTED: 'Requested',
  },
  account: {
    requisitionDoesNotExist: 'There are currently no active requisitions.',
  },
  resubmitAudio: 'Re-request Language Evaluation',
  resubmitAudioTooltip:
    'The previous Language Evaluation recording will be deleted, and the candidate will be asked to take a new Language Evaluation.',
  resubmissionMsg: 'You have requested the candidate to resubmit their Language Evaluation.',
  audioEvaluationReSubmission: {
    successful: 'Language Evaluation re-requested successfully',
    unsuccessful: 'Language Evaluation re-request failed',
  },
  reRequestAudio: {
    title: '  Re-request Language Evaluation',
    subTitle: 'Do you want the candidate to resubmit their Language Evaluation?',
    cancel: 'Cancel',
    reRequest: 'Re-request',
  },
  ratingInfoPass: 'This candidate has passed their Language Evaluation.',
  ratingInfoFail: 'This candidate has failed their Language Evaluation.',
  ratingInfoToastMessage: 'This candidate has failed their Language Evaluation. They will be dismissed in {{hours}} hours.',
};
