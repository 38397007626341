import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import HttpClientErrorLogger from './HttpClientErrorLogger';

const calcExponentialDelay = (attempt: number, initialDelay: number): number => {
  return 2 ** (attempt - 1) * initialDelay;
};

const HTTP_STATUS_RETRY_RANGES = [
  [100, 199],
  [429, 429],
  [500, 599],
];

const isRetryableHttpStatus = (status: number): boolean => {
  return HTTP_STATUS_RETRY_RANGES.some(([min, max]) => {
    return status >= min && status <= max;
  });
};

export type ServiceConfiguration = Pick<AxiosRequestConfig, 'baseURL' | 'headers' | 'timeout'>;

export type RetryConfiguration = {
  noResponseRetries?: number;
  retry?: number;
  retryDelayInMs?: number;
};

export type ErrorLogger = {
  handleError: (error: AxiosError) => void;
};

export type HttpClientConfiguration = {
  serviceConfiguration?: ServiceConfiguration;
  retryConfiguration?: RetryConfiguration;
  errorLogger?: ErrorLogger;
};

export default class HttpClientWithRetry {
  private readonly client: AxiosInstance;

  private readonly retryConfiguration: Required<RetryConfiguration>;

  private readonly errorLogger: ErrorLogger;

  constructor(config?: HttpClientConfiguration) {
    this.client = axios.create({
      baseURL: config?.serviceConfiguration?.baseURL ?? '',
      timeout: config?.serviceConfiguration?.timeout ?? 5000,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: {
        common: { Accept: 'application/json' },
        ...config?.serviceConfiguration?.headers,
      },
    });

    this.retryConfiguration = {
      noResponseRetries: config?.retryConfiguration?.noResponseRetries ?? 2,
      retry: config?.retryConfiguration?.retry ?? 3,
      retryDelayInMs: config?.retryConfiguration?.retryDelayInMs ?? 500,
    };

    this.errorLogger = config?.errorLogger ?? new HttpClientErrorLogger();
  }

  get interceptors(): AxiosInstance['interceptors'] {
    return this.client.interceptors;
  }

  async head<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.request({
      ...config,
      method: 'head',
      url,
    });
  }

  async get<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.request({
      ...config,
      method: 'get',
      url,
    });
  }

  async post<T = unknown, R = AxiosResponse<T>>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<R> {
    return this.request({
      ...config,
      method: 'post',
      url,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data,
    });
  }

  async put<T = unknown, R = AxiosResponse<T>>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<R> {
    return this.request({
      ...config,
      method: 'put',
      url,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data,
    });
  }

  async delete<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.request({
      ...config,
      method: 'delete',
      url,
    });
  }

  async request<T = unknown, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.requestAttempt(config, 0);
  }

  private async requestAttempt<T = unknown, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
    currentAttempt: number,
  ): Promise<R> {
    try {
      if (currentAttempt > 0) {
        await this.delayAttempt(currentAttempt);
        console.info(`HTTP connection retry attempt #${currentAttempt}`); // eslint-disable-line no-console
      }
      return await this.client.request(config);
    } catch (error) {
      if (this.shouldRetry(error, currentAttempt)) {
        return this.requestAttempt(config, currentAttempt + 1);
      }

      this.errorLogger.handleError(error);
      throw error;
    }
  }

  private async delayAttempt(currentAttempt: number): Promise<void> {
    // For an initial delay of 500ms, the delays will be in that order: 500ms, 1s, 2s, 4s, 8s, etc.
    const delay = calcExponentialDelay(currentAttempt, this.retryConfiguration.retryDelayInMs);
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  private shouldRetry(error: AxiosError, currentAttempt: number): boolean {
    if (!error.response && currentAttempt >= this.retryConfiguration.noResponseRetries) {
      return false;
    }

    if (error.response?.status) {
      if (!isRetryableHttpStatus(error.response.status)) {
        return false;
      }
    }

    return currentAttempt < this.retryConfiguration.retry;
  }
}

// For testing
export { calcExponentialDelay };
