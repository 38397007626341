import React from 'react';
import { FieldSchema, SchemaBuilderProps } from './formBuilderSchema';
import TextSchemaBuilder from './TextSchemaBuilder';

interface FormElementTextBuilderProps extends SchemaBuilderProps {
  placeholder?: string;
  startWithEditView?: boolean;
}

export default function FormElementTextBuilder({
  onChange,
  defaultValue,
  language,
  placeholder,
  startWithEditView,
}: FormElementTextBuilderProps) {
  const handleChange = (key: keyof FieldSchema | keyof FieldSchema['text'], value: any) => {
    onChange({ ...defaultValue, [key]: value });
  };
  return (
    <TextSchemaBuilder
      label={defaultValue?.text?.[language]!}
      placeholder={placeholder}
      onLabelChange={(e) => {
        handleChange('text', { ...defaultValue?.text, [language]: e });
      }}
      startWithEditView={startWithEditView}
      language={language}
    />
  );
}
