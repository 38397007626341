import moment from 'moment-timezone';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';
import { MessagePresenter } from './messagePresenter';
import { CandidateAudit } from '../../../../../firebase/firestore/documents/candidateAudit';
import {
  CandidateHistoryActionName,
  CandidateMessagingActionName,
} from '../../../../../firebase/firestore/documents/candidateAuditActionNames';

const isSameDate = (
  message: CandidateAudit | undefined,
  anotherMessage: CandidateAudit | undefined,
  granularity: 'day' | 'year',
) => {
  if (!message || !anotherMessage) {
    return false;
  }
  return moment(message.timestamp).isSame(moment(anotherMessage.timestamp), granularity);
};

const getMessageType = (message: CandidateAudit) => {
  switch (message.action) {
    case CandidateMessagingActionName.SEEKER_MESSAGE:
    case CandidateHistoryActionName.OFFER_ACCEPETED_BY_SEEKER:
    case CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER:
    case CandidateMessagingActionName.APPOINTMENT_REQUEST_ACCEPTED:
    case CandidateMessagingActionName.APPOINTMENT_PERIOD_CANCELLED:
    case CandidateHistoryActionName.CANDIDATE_FORM_HELP_REQUESTED:
    case CandidateHistoryActionName.INTEREST_REVOKED:
      return message.recruiterFirstName ? 'recruiter' : 'seeker';
    case CandidateMessagingActionName.APPOINTMENT_REQUEST_CANCELLED:
      return message.recruiterFirstName ? 'recruiter' : 'seeker';
    default:
      return 'recruiter';
  }
};

const isSameUser = (current: CandidateAudit, previous?: CandidateAudit) => {
  if (current.automation) {
    return false;
  }
  return previous?.automation ? false : previous?.userId === current.userId;
};

const getAuthorName = (message: CandidateAudit) =>
  message.action === CandidateMessagingActionName.SEEKER_MESSAGE ? message.seekerFirstName : message.recruiterFirstName;

const getDateHeaderFormat = (
  locale: string,
  current: CandidateAudit,
  previous?: CandidateAudit,
  previousOfSameType?: CandidateAudit,
) => {
  let dateFormat = '';

  if (!isSameDate(current, previousOfSameType, 'day')) {
    dateFormat += locale === 'fr' ? 'D MMM' : 'MMM D';
  }

  if (!isSameDate(current, previousOfSameType, 'year')) {
    dateFormat += ' YYYY';
  }

  if (
    !previous ||
    !previousOfSameType ||
    getMessageType(current) !== getMessageType(previous) ||
    (getMessageType(current) === getMessageType(previous) && current.userId !== previous.userId) ||
    moment(current.timestamp).diff(moment(previousOfSameType.timestamp), 'minutes') > 5
  ) {
    if (!_isEmpty(dateFormat)) {
      dateFormat += ', ';
    }
    dateFormat += 'h:mm A';
  }
  return dateFormat;
};

const getDateHeader = (
  locale: string,
  current: CandidateAudit,
  previous?: CandidateAudit,
  previousOfSameType?: CandidateAudit,
) => {
  const dateFormat = getDateHeaderFormat(locale, current, previous, previousOfSameType);
  return !_isEmpty(dateFormat) ? moment(current.timestamp).format(dateFormat) : undefined;
};

const pickPreviousMessage = (
  messageType: string,
  previousSeekerMessage: CandidateAudit,
  previousEmployerMessage: CandidateAudit,
): CandidateAudit => (messageType === 'seeker' ? previousSeekerMessage : previousEmployerMessage);

// eslint-disable-next-line import/no-default-export
export default class MessagesPresenter {
  public messages: MessagePresenter[] = [];

  constructor(candidateAuditMessages: CandidateAudit[], locale: string) {
    const orderedMessages = _sortBy(candidateAuditMessages, ['timestamp']);

    let previousMessage: CandidateAudit;
    let previousSeekerMessage: CandidateAudit;
    let previousRecruiterMessage: CandidateAudit;

    orderedMessages.forEach((message) => {
      const showPicture = !isSameUser(message, previousMessage);
      const messageType = getMessageType(message);
      const previousMessageOfSameType = pickPreviousMessage(messageType, previousSeekerMessage, previousRecruiterMessage);
      this.messages.push(
        new MessagePresenter(
          message,
          getMessageType(message),
          showPicture,
          !isSameUser(message, previousMessage) ? getAuthorName(message) : undefined,
          getDateHeader(locale, message, previousMessage, previousMessageOfSameType),
        ),
      );
      if (messageType === 'seeker') {
        previousSeekerMessage = message;
      } else {
        previousRecruiterMessage = message;
      }
      previousMessage = message;
    });
  }

  public isLastAppointmentRequestMessage(currentMessage: MessagePresenter): boolean {
    if (currentMessage.action !== CandidateMessagingActionName.APPOINTMENT_REQUEST_CREATED) {
      return false;
    }

    return !this.messages
      .filter((message) => message.isAppointmentMessage)
      .filter((message) => message.id !== currentMessage.id)
      .some((message) => message.hasBeenSentAfter(currentMessage));
  }
}
