import {
  Stack,
  HStack,
  Button,
  Text,
  Editable,
  EditablePreview,
  EditableInput,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/core';
import React from 'react';
import { generateFormSchema, LabelSchema, Language } from './formBuilderSchema';
import theme from '../../../../styles/customTheme';
import FormBuilderSections from './FormBuilderSections';
import { SectionSchema } from '../HireformView';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { useParams } from 'react-router-dom';

export interface FormBuilderProps {
  formSchema: SectionSchema[];
  setFormSchema: (schema: SectionSchema[]) => void;
  language: Language;
  templateName: LabelSchema;
  setTemplateName: (name: LabelSchema) => void;
}

export default function FormBuilder({ formSchema, setFormSchema, language, templateName, setTemplateName }: FormBuilderProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { createTemplate, updateTemplate } = useStoreActions((state) => state.hrFormTemplate);
  const { isLoading } = useStoreState((state) => state.hrFormTemplate);
  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';
  let templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;

  function handleSaveTemplate() {
    // save template
    const questions = generateFormSchema(formSchema, language);
    if (templateId) {
      updateTemplate({ id: templateId, payload: { name: templateName?.[language]!, questions: questions } });
    } else {
      createTemplate({ account: accountId, name: templateName?.[language]!, questions: questions });
    }
  }

  return (
    <Stack
      spacing={6}
      flex="1 1 50%"
      px={theme.space[4]}
      py={theme.space[6]}
      overflowY="auto"
      minHeight="0"
      height="100%"
      overflowX="hidden"
      marginLeft="0 !important"
    >
      <Text fontSize="xl" fontWeight="bold">
        Form Builder
      </Text>
      <HStack justify="space-between" align="center">
        <Editable value={templateName[language]} placeholder="Template Name" flexGrow={1} fontSize="normal" fontWeight={'bold'}>
          <EditablePreview width="100%" />
          <EditableInput
            width="100%"
            value={templateName[language]}
            onChange={(e) => setTemplateName({ ...templateName, [language]: e.target.value })}
          />
        </Editable>
        <ButtonGroup variant="outline" size="sm">
          <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur closeOnEsc>
            <PopoverTrigger>
              <Button colorScheme="red" isDisabled={formSchema.length === 0 || isLoading}>
                Clear All
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Text>Are you sure you want to clear all form elements?</Text>
                </PopoverBody>
                <PopoverFooter display="flex" alignItems="center" justifyContent="flex-end">
                  <ButtonGroup size="sm" variant="outline">
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        setFormSchema([]);
                        onClose();
                      }}
                    >
                      Yes
                    </Button>
                    <Button onClick={onClose}>No</Button>
                  </ButtonGroup>
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
          <Button
            colorScheme="blue"
            isDisabled={formSchema.length === 0 || isLoading}
            isLoading={isLoading}
            onClick={handleSaveTemplate}
          >
            Save
          </Button>
        </ButtonGroup>
      </HStack>

      <FormBuilderSections formSchema={formSchema} setFormSchema={setFormSchema} language={language} />
    </Stack>
  );
}
