import { css } from '@emotion/core';
import colors from '../../../../styles/colors';

export const seekersListContainer = css`
  .noCandidateMsg {
    display: flex;
    align-items: center;
    text-align: center;

    > div {
      max-width: 200px;
      margin: 0 auto;
    }
  }
`;

export const seekersListItems = css`
  grid-column: leftSide / midSide;
  overflow-y: auto;
  background-color: ${colors.white};
  border-right: 1px solid ${colors.dividerGray};

  &:focus,
  > div:focus {
    outline: none;
  }

  .chakra-ui-dark & {
    background-color: ${colors.black};
  }

  .candidate-block {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dedede;
    border-bottom: 1px solid rgba(222, 222, 222, 0.5);

    .inner-candidate-block {
      padding: 15px 30px;

      @media only screen and (max-width: 60rem) {
        padding: 12px;
      }

      &.active-list,
      &.active-list:hover {
        background: rgba(47, 161, 218, 0.1);
      }

      &:hover {
        background: rgba(47, 161, 218, 0.05);
        background: rgb(241 241 241);
      }

      .undismissIcon {
        opacity: 0;
      }
    }
    .inner-candidate-block .unDismissedIcon {
      opacity: 0;
    }
    .inner-candidate-block:hover .unDismissedIcon {
      opacity: 1;
    }

    .chakra-avatar__badge {
      background: #69f0ae;
      border-width: 2px;
      height: 12px;
      width: 12px;
      right: 5px;
      bottom: 3px;
    }
    .chakra-heading {
      font-weight: bold;
      font-size: 14px;
    }
    .chakra-text {
      font-weight: normal;
      font-size: 13px;
      line-height: 17px;
      color: #96999e;
    }
    .chakra-badge {
      font-weight: normal;
      font-size: 10px;
      line-height: 17px;
      color: #96999e;
      background: #e7e7e7;
      border-radius: 3px;
      text-transform: initial;
    }
  }
  .parent-block,
  .child-block,
  .candidate-block {
    position: relative;
  }

  .parent-block {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.161);
    border-radius: 10px;
    margin: 15px 0;
    padding: 10px 20px 10px 15px;
    font-weight: bold;
    font-size: 14px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .child-block {
    padding: 16px 40px 16px 30px;
    background-color: #f5f5f5;
    border-bottom: 1px solid rgba(222, 222, 222, 0.5);
    border-top: 1px solid rgba(222, 222, 222, 0.5);
    margin-top: -1px;

    .accordion-icon {
      width: 65px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .inner-child-block {
    .chakra-heading {
      font-weight: bold;
      font-size: 14px;
    }
    .chakra-text {
      font-weight: normal;
      font-size: 12px;
      color: #4a5568;
    }
  }

  .accordion-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .candidate-bar {
    position: relative;
    cursor: pointer;

    > span {
      position: absolute;
      right: -15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;
export const selectedQuestionsList = css`
  .questionWrapper {
    width: 100%;
    flex-wrap: wrap;
  }

  .addIcon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .individualQuestion {
    box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
    border: 1px solid #f1f1f1;
    padding: 10px;
    margin-right: 10px;
    border-radius: 8px;
    color: #1f3cba;
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .addButton {
    background: ${colors.gray['150']};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    cursor: pointer;
  }

  .addButton:hover {
    background: #cfd5db;
  }

  .disabledAddButton {
    background: ${colors.gray['150']};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    cursor: pointer;
  }
`;
