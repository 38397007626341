export default {
  ANALYSING: 'Analyser',
  COMPLETE: 'Terminé',
  DISPUTE: 'Dispute',
  IN_PROGRESS: 'En cours',
  PARTIAL: 'Partiel',
  SENT: 'Envoyé',
  status: {
    analysing: 'Analyser',
    complete: 'Terminé',
    dispute: 'Dispute',
    in_progress: 'En cours',
    partial: 'Partiel',
    sent: 'Envoyé',
  },
  bgCheckLabel: 'Vérification des antécédents',
  bgCheckStatusLabel: 'Statut de la vérification des antécédents',
  candidateBgCheckStatusLabel: 'Statut de la vérification des antécédents du candidat',
  bgCheckStatusText: "Vous pouvez voir l'état des vérifications des antécédents des candidats.",
  searchInputPlaceholder: 'Nom du candidat',
  statusFilterPlaceholder: 'Sélectionner le statut',
  contactAppyLabel: 'Contacte ton administrateur AppyHere pour des informations détaillées.',
  submitBtn: 'Confirmer',
  viewBtn: 'Vue',
  closeBtn: 'Fermer',
  completedDate: 'Date complétée',
  statusLabel: 'Statut',
  subscribeCertnLabel: 'Abonnez-vous pour ajouter cette fonctionnalité !',
  subscribeText: `Tu dois contacter <span style=‘color: #049BE5’>AppyHere</span> pour savoir comment ajouter cette fonctionnalité à ton abonnement.`,
  jobListingUrlLabel: `URL de l'emploi`,
  uploadCompanyLogoLabel: "Télécharge le logo de l'entreprise",
  certnIntegationLabel: 'Intégration de Certn',
  checks: 'Vérifications',
  keysUrlLabel: 'Clés et URL',
  certnSetupLabel: 'Informations sur la configuration de l’intégration de Certn.',
  selectRequiredLabel: 'Sélectionnez les vérifications des antécédents requis par défaut',
  linkCertnLabel: 'Lier le compte Certn',
  apiKeyLabel: 'Clé API',
  webhookUrlLabel: 'URL du Webhook',
  webhookSecretLabel: 'Secret du Webhook',
  checksValidation: 'Au moins une vérification est nécessaire',
  searchValidation: "Aucun candidat n'a été trouvé.",
  completedDateLabel: 'Date complétée',
  lastSubmitDateLabel: 'Dernière date de soumission',
  screeningError: 'Erreur de rapport de vérification',
  incompleteScreeningError: "Les antécédents du candidat n'ont pas encore été vérifié, ou la vérification n'est pas terminée.",
  setupStep: "Étapes nécessaires pour mettre en place l'intégration Certn",
  webHookTitle: 'Pour configurer le secret du webhook.',
  stepLoginCertn:
    "Connectez à votre compte Certn, et ensuite naviguez aux paramètres de l'équipe. Vous trouverez ces paramètres en allant dans votre profil > Paramètres > Paramètres d'équipe > Généralités.",
  stepGenNewHook:
    "Clique sur 'Générer un nouveau' et copie le secret généré du webhook, s'il y a une entrée existante, tu peux la copier en passant la souris sur le champ et en cliquant sur l'option 'copier'.",
  stepLoginRecruiter: 'Connecte-toi à ton compte Web recruteur > Navigue vers Certn Integration.',
  stepPasteWebSecret: 'Colle le secret du webhook que tu as copié à la première étape dans le champ Webook Secret.',
  apiKeyTitle: "Étapes de l'installation",
  backToCertnApi:
    "Connecte-toi à ton compte Certn ensuite, navigue dans Paramètres que tu trouveras sous ta photo de profil > Paramètres de l'équipe > Clés APIRetourne sur ton compte Certn et sélectionne Clés API dans le menu.",
  stepCopyAPI:
    "Clique sur 'Ajouter une Clé API' et copie la clé qui est générée, il s'agit d'une clé unique pour l'intégration AppyHere et elle ne peut pas être redéfinie après avoir quitté cet écran.",
  stepPasteAPI: "Colle le texte de l'étape précédente dans le champ Clé API.",
  stepSave: 'Cliquez sur Sauvegarder',
  webURLTitle: "Pour configurer l'url du webhook.",
  stepLoginCertnURl: 'Connectez à votre compte Recruteur Web. Cliquez sur Administration, et ensuite sur Intégration Certn.',
  stepCopyUrl: "Copie l'URL du Webhook.",
  stepPasteUrl: "Colle l'url de webhook copiée dans le champ URL de webhook.",
  linkToCertn: "Si vous n'avez pas créé un compte Certn, vous pouvez le faire à ls'aide du lien ci-dessous:",
  finalNote: 'Soyez certain de toujours commencer par la première étape si vous recommencez le processus.',
  apiKeyRequired: 'La clé API est requise',
  webhookSecretRequired: 'Le secret Webhook est requis',
  apiKeyPlaceholder: 'Entrez la clé API',
  jobListingCardHeader: "URL de l'offre d'emploi sur Web Apply",
  jobListingCardHint: "Copier le URL ci-dessous pour partager l'offre d'emploi",
  positionManagementLabel: "Gestion des postes d'emploi",
  save: 'Sauvegarder',
  less: 'Moins',
  more: 'Plus',
  tooltipMessageForCanadianBaseRecordCheck:
    "OneId doit être sélectionné lorsqu'une des vérifications du casier judiciaire canadien est sélectionnée. Seulement une des vérifications du casier judiciaire canadien peut être choisi en tout temps.",
  tooltipMessageForUSBasedRecordCheck:
    'Seulement une des vérifications du casier judiciaire basé aux États-Unis peut être choisi en tout temps.',
  // new missed translations
  soquijEnableLabel: '<0>SOQUIJ</0>',
  soquijTooltipMessage: `Certn fournit un accès facile au dossiers civils, criminels et les casiers judiciaires de la province de Québec.`,
  soquijHint1: `Les dossiers <1>SOQUIJ</1> sont seulement disponible en Français et seulement au <3>Canada</3>.`,
  soquijSupportLabel: `Contacter <1>l'équipe support de Certn</1> pour activer SOQUIJ dans votre compte. L'équipe peut être contactée par courriel au <3>apisupport@certn.co</3>.`,
  messageNotEnabledOnPositionLevel: `Les vérifications des antecedants ne sont pas activées pour ce poste.`,
};
