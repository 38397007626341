import React from 'react';
import { Box, Progress, Stat, StatLabel, StatNumber, Text, Flex, Tooltip } from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { BsArrowDownShort } from 'react-icons/bs';
import isNil from 'lodash/isNil';
import colors from '../../../../../styles/colors';

export type HeadingPropValue = {
  heading1: string;
  heading1TooltipText?: string;
  value1: number;
  heading2: string;
  heading2TooltipText?: string;
  value2: number;
  heading3?: string;
  heading3TooltipText?: string;
  value3?: number;
};

export type StatWithProgressBarAndComparisonProps = {
  headingValues: HeadingPropValue;
  showProgress?: boolean;
  showComparison?: boolean;
  comparisonPercentage?: number;
  statHeadingValueStyle?: React.CSSProperties;
  divideHeading1And3AsSubHeading?: { isHeadingDivided: boolean; subHeadingTitle: string };
};

const StatWithProgressBarAndComparison = ({
  showProgress,
  showComparison,
  statHeadingValueStyle,
  comparisonPercentage,
  headingValues,
  divideHeading1And3AsSubHeading,
}: StatWithProgressBarAndComparisonProps): JSX.Element => {
  const {
    heading1,
    heading1TooltipText,
    value1,
    heading2,
    heading2TooltipText,
    value2,
    heading3,
    value3,
    heading3TooltipText,
  } = headingValues;

  const displayHeaderAndValue = (heading: string, value: number, toolTipText: string) => (
    <Tooltip label={toolTipText} fontSize="md" placement="top">
      <Box display="flex" justifyContent="space-between" width="100%" style={statHeadingValueStyle} cursor="pointer">
        <Box
          display="flex"
          color="black"
          opacity="0.5"
          fontWeight="600"
          fontSize={14}
          data-testid="statHeadingLabel"
          whiteSpace="nowrap"
          alignItems="center"
        >
          <Box>{heading}</Box>
        </Box>
        <Flex alignItems="center" data-testid="statValueFlex" fontWeight={700} fontSize={17} color="black">
          {value}
        </Flex>
      </Box>
    </Tooltip>
  );

  const renderStatHeadingAndValueSideBySide = (
    headingOne: string,
    valueOne: number,
    headingTwo: string,
    valueTwo: number,
    subHeadingTitle: string,
    headingOneTooltipText = '',
    headingTwoTooltipText = '',
  ) => {
    return (
      <Stat backgroundColor="white" paddingTop="4px">
        <Text color={colors.gray[1000]} fontWeight={700} fontSize={15}>
          {subHeadingTitle}
        </Text>
        <Box display="flex" justifyContent="space-between" data-testid="statHeadingSideBySide">
          {displayHeaderAndValue(headingOne, valueOne, headingOneTooltipText)}
          <Box border={`1px solid ${colors.blue[40]}`} mx="0.8rem" />
          <Box minWidth="53%">{displayHeaderAndValue(headingTwo, valueTwo, headingTwoTooltipText)}</Box>
        </Box>
      </Stat>
    );
  };

  const renderStatHeadingAndValue = (heading: string, value: number, comparisonPercent?: number, toolTipText = '') => {
    const isComparisonPositive = comparisonPercent && comparisonPercent > 0;
    return (
      <Stat backgroundColor="white">
        <Box display="flex" flexDirection={showComparison ? 'column-reverse' : 'column'} style={statHeadingValueStyle}>
          <StatLabel color="#000000" opacity="0.5" fontWeight="600" data-testid="statHeadingLabel" whiteSpace="pre-wrap">
            <Box display="flex">
              {heading}
              {toolTipText && (
                <Box ml={1} display="flex" alignItems="center">
                  <Tooltip label={toolTipText} fontSize="md" placement="top-start">
                    <InfoOutlineIcon cursor="pointer" />
                  </Tooltip>
                </Box>
              )}
            </Box>
          </StatLabel>
          <StatNumber fontWeight={700} fontSize={20} color="#000000">
            <Flex alignItems="center" data-testid="statValueFlex">
              {value}
              {!_isNil(comparisonPercent) && (
                <Flex
                  background={isComparisonPositive ? '#149D52' : '#B33636'}
                  borderRadius="2px"
                  style={{ marginLeft: '10px' }}
                  color="white"
                  px={1}
                  py={0.5}
                >
                  <BsArrowDownShort
                    data-testid="comparisonIcon"
                    fontSize={15}
                    style={{ transform: isComparisonPositive ? 'rotate(180deg)' : '' }}
                  />
                  <Text fontSize={10} fontWeight={600} data-testid="comparisonPercentageText">
                    {`${Math.round(comparisonPercent)}%`}
                  </Text>
                </Flex>
              )}
            </Flex>
          </StatNumber>
        </Box>
      </Stat>
    );
  };

  return (
    <Box h="100%" display="flex" flexDirection="column" justifyContent="space-around">
      {divideHeading1And3AsSubHeading?.isHeadingDivided && !isNil(heading3) && !isNil(value3)
        ? renderStatHeadingAndValueSideBySide(
            heading1,
            value1,
            heading3,
            value3,
            divideHeading1And3AsSubHeading.subHeadingTitle,
            heading1TooltipText,
            heading3TooltipText,
          )
        : renderStatHeadingAndValue(heading1, value1, undefined, heading1TooltipText)}
      {showComparison && (
        <Box border="1px dashed #E6E8EC" position="relative" width="100%" left="0" data-testid="statSeperatorLine" />
      )}
      {showComparison && renderStatHeadingAndValue(heading2, value2, comparisonPercentage, heading2TooltipText)}

      {showProgress && (
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Text fontWeight={600} fontSize={13} color="#000000" data-testid="progressFirstRow">
              {`${value2} ${heading2}`}
            </Text>
            <Text fontWeight={400} fontSize={13} color="#000000" opacity="0.5" data-testid="progressSecondRow">
              {`${Math.round((value2 / value1) * 100)}%`}
            </Text>
          </Box>
          <Progress
            colorScheme="blue"
            height="6px"
            value={Math.round((value2 / value1) * 100)}
            style={{ borderRadius: '50px' }}
            data-testid="progressSlider"
          />
        </Box>
      )}
    </Box>
  );
};

export default StatWithProgressBarAndComparison;
