import React, { useRef } from 'react';

import { Box, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { dashboardStyleCSS } from './DashboardViewStyle';
import { RequisitionOverviewView } from './requisitionOverview/RequisitionOverviewView';
import { Header } from '../../app/topBar/Header';
import colors from '../../styles/colors';

export const DashboardRequisition = (): JSX.Element => {
  const candidateToDoListViewRef = useRef<null | HTMLDivElement>(null);
  const { t } = useTranslation('toDoList');

  const topWrapperRef = useRef(null);
  const containerWithStickyNav = useRef<HTMLDivElement | null>(null);

  // istanbul ignore next
  const handleScroll = () => {
    if (candidateToDoListViewRef.current && containerWithStickyNav.current) {
      // Access scrollTop, scrollHeight, clientHeight, etc. as needed
      if (candidateToDoListViewRef.current.scrollTop >= containerWithStickyNav.current.offsetTop - 50) {
        containerWithStickyNav.current.classList.add('sticky');
      } else {
        containerWithStickyNav.current.classList.remove('sticky');
      }
    }
  };

  return (
    <Box backgroundColor={colors.antiFlashWhite} ref={topWrapperRef}>
      <Header showMenu showPageTitle={t('requisition:requisitionRiskDashboard')} />
      <Box css={dashboardStyleCSS} style={{ paddingTop: `${0}px` }}>
        <Flex
          direction="column"
          height={`calc(100vh - ${0 + 68}px)`}
          overflowY="auto"
          ref={candidateToDoListViewRef}
          onScroll={handleScroll}
        >
          <Box ref={containerWithStickyNav}>
            <RequisitionOverviewView />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
