import { Collections } from '../../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../../firebase/firestore/hooks';
import { Query } from '../../../firebase/firestore/query/query';
import { GroupEventConfiguration } from '../../../firebase/firestore/documents/groupEvent';
import { BUNDLE_ID_GLOBAL, MINUTES_BEFORE_EVENT_TO_ALLOW_DELETION } from '../../../firebase/firestore/constants';

export const useEventAllowDeletionMinutes = (): number | undefined => {
  const minutesBeforeEventAllowToDelete = useQuerySnapshot<GroupEventConfiguration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_GLOBAL),
    Query.field('name').equals(MINUTES_BEFORE_EVENT_TO_ALLOW_DELETION),
  ]);
  if (minutesBeforeEventAllowToDelete) {
    return minutesBeforeEventAllowToDelete[0]?.value;
  }
  return undefined;
};
