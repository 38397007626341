import { css } from '@emotion/core';
import colors from '../styles/colors';

export const errorViewWrapper = css`
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  background: ${colors.white};
  z-index: 0;
`;

export const labelErrorViewWrapper = css`
  font-size: 24px;
  max-width: 340px;
`;

export const errorView = css`
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
`;
