import { Box, Checkbox, Divider, Flex, FormControl, FormLabel, Heading, Switch, Text, VStack } from '@chakra-ui/core';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import { RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack } from '@chakra-ui/slider';
import moment from 'moment/moment';
import { customStyledCheckbox } from './CreateJobPositionStyle';
import { JobPosition } from '../PositionType';
import Colors from '../../../../styles/colors';
import { convertTimeToNumber } from '../../../../utils/positionUtils';

export type PositionAvailabilityCardProps = {
  jobPositionData: JobPosition;
  handleAvailabilityChange: (availability: { [key: string]: { start_1: string; end_1: string } }) => void;
  handleChange: (value: boolean, fieldName: string) => void;
};

export const PositionAvailabilityCard = ({
  jobPositionData,
  handleAvailabilityChange,
  handleChange,
}: PositionAvailabilityCardProps): JSX.Element => {
  const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri'];
  const weekendDays = ['sat', 'sun'];
  const { t } = useTranslation();
  return (
    <Box backgroundColor="white" borderRadius={5} mb={6}>
      <Box px={6} py={4}>
        <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
          {t('createJobPosition:workScheduleCardHeader')}
        </Heading>
        <Text fontSize="xs" color="gray.500">
          {t('jobPosition:availabilityHint')}
        </Text>
      </Box>
      <Divider borderColor="#ECEFF1" />
      <Box css={customStyledCheckbox} p={6}>
        <VStack spacing={4} align="stretch">
          <Flex>
            <Box w="50%">
              {weekDays.map((weekDay) => (
                <Flex key={weekDay} mr="10px">
                  <Checkbox
                    isChecked={
                      _has(jobPositionData?.newAvailabilities, weekDay) && !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                    }
                    data-testid={`availabilities_${weekDay}`}
                    onChange={(e) => {
                      const availabilities = jobPositionData?.newAvailabilities || {};
                      if (!e.target.checked) {
                        delete availabilities[weekDay];
                        handleAvailabilityChange(availabilities);
                      } else {
                        availabilities[weekDay] = {
                          start_1: '09:00',
                          end_1: '17:00',
                        };
                        handleAvailabilityChange(availabilities);
                      }
                    }}
                  >
                    <Text fontSize="16px" fontWeight="700" color={Colors.black}>
                      {t(`jobPosition:days.${weekDay}`)}
                    </Text>
                  </Checkbox>
                  <Box ml="12px" w="100%">
                    <RangeSlider
                      w="100%"
                      isDisabled={
                        !(
                          _has(jobPositionData?.newAvailabilities, weekDay) &&
                          !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                        )
                      }
                      defaultValue={[36, 68]}
                      value={
                        /* istanbul ignore next */
                        _has(jobPositionData?.newAvailabilities, weekDay) &&
                        !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                          ? [
                              convertTimeToNumber((jobPositionData?.newAvailabilities || {})[weekDay].start_1),
                              convertTimeToNumber((jobPositionData?.newAvailabilities || {})[weekDay].end_1),
                            ]
                          : [36, 68]
                      }
                      bg="white"
                      data-testid="salarySlider"
                      max={96}
                      min={0}
                      minStepsBetweenThumbs={2}
                      mt="36px"
                      step={2}
                      onChange={
                        /* istanbul ignore next */ (value: number[]) => {
                          const availabilities = jobPositionData?.newAvailabilities || {};
                          if (availabilities[weekDay]) {
                            availabilities[weekDay] = {
                              start_1: `${Math.floor(value[0] / 4)
                                .toString()
                                .padStart(2, '0')}${(value[0] / 4) % 1 !== 0 ? ':30' : ':00'}`,
                              end_1: `${Math.floor(value[1] / 4)
                                .toString()
                                .padStart(2, '0')}${(value[1] / 4) % 1 !== 0 ? ':30' : ':00'}`,
                            };
                            handleAvailabilityChange(availabilities);
                          }
                        }
                      }
                    >
                      <RangeSliderTrack>
                        <RangeSliderFilledTrack
                          bg={
                            _has(jobPositionData?.newAvailabilities, weekDay) &&
                            !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                              ? Colors.blue.default
                              : Colors.gray[200]
                          }
                          w="100%"
                          p={1}
                          color="white"
                        />
                      </RangeSliderTrack>
                      <RangeSliderThumb index={0} bg="#1F3CBA" />
                      <RangeSliderThumb index={1} bg="#1F3CBA" />
                    </RangeSlider>
                    <Flex w="100%" justifyContent="space-between" ml="10px">
                      <Box
                        style={{
                          width: 'fit-content',
                          marginTop: '15px',
                          marginLeft: '-17px',
                          fontSize: '16px',
                          color: '#36464E',
                        }}
                      >
                        {_has(jobPositionData?.newAvailabilities, weekDay) &&
                        !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                          ? moment((jobPositionData?.newAvailabilities || /* istanbul ignore next */ {})[weekDay].start_1, [
                              'HH:mm',
                            ]).format('LT')
                          : moment('09:00', ['HH:mm']).format('LT')}
                      </Box>
                      <Box
                        style={{
                          width: 'fit-content',
                          marginTop: '15px',
                          marginLeft: '-17px',
                          fontSize: '16px',
                          color: '#36464E',
                        }}
                      >
                        {_has(jobPositionData?.newAvailabilities, weekDay) &&
                        !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                          ? moment((jobPositionData?.newAvailabilities || /* istanbul ignore next */ {})[weekDay].end_1, [
                              'HH:mm',
                            ]).format('LT')
                          : moment('17:00', ['HH:mm']).format('LT')}
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
              ))}
            </Box>
            <Box w="50%" ml="12px">
              {weekendDays.map((weekDay) => (
                <Flex key={weekDay} mr="10px">
                  <Checkbox
                    isChecked={
                      _has(jobPositionData?.newAvailabilities, weekDay) && !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                    }
                    data-testid={`availabilities_${weekDay}`}
                    onChange={(e) => {
                      const availabilities = jobPositionData?.newAvailabilities || /* istanbul ignore next */ {};
                      if (!e.target.checked) {
                        delete availabilities[weekDay];
                        handleAvailabilityChange(availabilities);
                      } else {
                        availabilities[weekDay] = {
                          start_1: '09:00',
                          end_1: '17:00',
                        };
                        handleAvailabilityChange(availabilities);
                      }
                    }}
                  >
                    <Text fontSize="16px" fontWeight="700" color={Colors.black}>
                      {t(`jobPosition:days.${weekDay}`)}
                    </Text>
                  </Checkbox>
                  <Box ml="12px" w="100%">
                    <RangeSlider
                      w="100%"
                      isDisabled={
                        !(
                          _has(jobPositionData?.newAvailabilities, weekDay) &&
                          !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                        )
                      }
                      defaultValue={[36, 68]}
                      value={
                        /* istanbul ignore next */
                        _has(jobPositionData?.newAvailabilities, weekDay) &&
                        !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                          ? [
                              convertTimeToNumber((jobPositionData?.newAvailabilities || {})[weekDay].start_1),
                              convertTimeToNumber((jobPositionData?.newAvailabilities || {})[weekDay].end_1),
                            ]
                          : [36, 68]
                      }
                      bg="white"
                      data-testid="salarySlider"
                      max={96}
                      min={0}
                      minStepsBetweenThumbs={2}
                      mt="36px"
                      step={2}
                      onChange={
                        /* istanbul ignore next */ (value: number[]) => {
                          const availabilities = jobPositionData?.newAvailabilities || {};
                          if (availabilities[weekDay]) {
                            availabilities[weekDay] = {
                              start_1: `${Math.floor(value[0] / 4)
                                .toString()
                                .padStart(2, '0')}${(value[0] / 4) % 1 !== 0 ? ':30' : ':00'}`,
                              end_1: `${Math.floor(value[1] / 4)
                                .toString()
                                .padStart(2, '0')}${(value[1] / 4) % 1 !== 0 ? ':30' : ':00'}`,
                            };
                            handleAvailabilityChange(availabilities);
                          }
                        }
                      }
                    >
                      <RangeSliderTrack>
                        <RangeSliderFilledTrack
                          bg={
                            _has(jobPositionData?.newAvailabilities, weekDay) &&
                            !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                              ? Colors.blue.default
                              : Colors.gray[200]
                          }
                          w="100%"
                          p={1}
                          color="white"
                        />
                      </RangeSliderTrack>
                      <RangeSliderThumb index={0} bg="#1F3CBA" />
                      <RangeSliderThumb index={1} bg="#1F3CBA" />
                    </RangeSlider>
                    <Flex w="100%" justifyContent="space-between" ml="10px">
                      <Box
                        style={{
                          width: 'fit-content',
                          marginTop: '15px',
                          marginLeft: '-17px',
                          fontSize: '16px',
                          color: '#36464E',
                        }}
                      >
                        {_has(jobPositionData?.newAvailabilities, weekDay) &&
                        !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                          ? moment((jobPositionData?.newAvailabilities || /* istanbul ignore next */ {})[weekDay].start_1, [
                              'HH:mm',
                            ]).format('LT')
                          : moment('09:00', ['HH:mm']).format('LT')}
                      </Box>
                      <Box
                        style={{
                          width: 'fit-content',
                          marginTop: '15px',
                          marginLeft: '-17px',
                          fontSize: '16px',
                          color: '#36464E',
                        }}
                      >
                        {_has(jobPositionData?.newAvailabilities, weekDay) &&
                        !_isEmpty(jobPositionData?.newAvailabilities![weekDay])
                          ? moment((jobPositionData?.newAvailabilities || /* istanbul ignore next */ {})[weekDay].end_1, [
                              'HH:mm',
                            ]).format('LT')
                          : moment('17:00', ['HH:mm']).format('LT')}
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
              ))}
            </Box>
          </Flex>
          <Divider borderColor="#ECEFF1" />
          <Flex pt={2}>
            <FormControl d="flex" w="70%">
              <Switch
                id="fullTime"
                mr={2}
                ml={2}
                isChecked={jobPositionData.fullTime}
                data-testid="FullTimeSwitch"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.checked, 'fullTime')}
              />
              <FormLabel htmlFor="fullTime">{t('createJobPosition:fullTimeLabel')}</FormLabel>
            </FormControl>
          </Flex>
        </VStack>
      </Box>
    </Box>
  );
};
