/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useEffect } from 'react';
import { InterviewGreenRoom } from './InterviewGreenRoom';
import { InterviewingRoom } from './InterviewingRoom';
import { useMeetContext } from '../../../meet/MeetContext';
import { useRoomState } from '../../../meet/twilio/hooks/useRoomState';
import { ActiveParticipantContextProvider } from '../../../meet/ActiveParticipantContext';
import { useStoreActions } from '../../../../models/hooks';

export const InterviewMeetRoom = (): JSX.Element => {
  const {
    room,
    joinRoom,
    quitRoom,
    isConnecting,
    isLoadingLocalTracks,
    isSharingScreen,
    toggleScreenSharing,
    removeLocalVideoTrack,
    removeLocalAudioTrack,
  } = useMeetContext();
  const roomState = useRoomState();

  const isLoading: boolean = isLoadingLocalTracks || isConnecting;
  const setInterviewStarted = useStoreActions((state) => state.interview.setInterviewStarted);

  useEffect(() => {
    return () => {
      removeLocalVideoTrack();
    };
  }, [removeLocalVideoTrack]);

  useEffect(() => {
    return () => {
      removeLocalAudioTrack();
    };
  }, [removeLocalAudioTrack]);

  useEffect(() => {
    return () => {
      if (isSharingScreen) {
        toggleScreenSharing();
      }
    };
  }, [isSharingScreen, toggleScreenSharing]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    room && (roomState === 'connected' || roomState === 'reconnecting') ? setInterviewStarted(true) : setInterviewStarted(false);
  }, [room, roomState, setInterviewStarted]);

  return (
    <Fragment>
      <ActiveParticipantContextProvider>
        {(!room || roomState === 'disconnected') && <InterviewGreenRoom isLoading={isLoading} joinRoom={joinRoom} />}
        {room && (roomState === 'connected' || roomState === 'reconnecting') && (
          <InterviewingRoom
            room={room}
            isConnecting={isConnecting}
            isLoadingLocalTracks={isLoadingLocalTracks}
            quitRoom={quitRoom}
          />
        )}
      </ActiveParticipantContextProvider>
    </Fragment>
  );
};
