/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment } from 'react';
import {
  Grid,
  Container,
  Box,
  Heading,
  List,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  OrderedList,
  GridItem,
  Text,
} from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import _groupBy from 'lodash/groupBy';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';
import { useSupportViewContext } from './SupportViewContext';
import { SupportLinkItem } from './SupportLinkItem';
import { SupportDetailView } from './SupportDetailView';
import { DocumentationCatEnum } from './DocumentationCatEnum';
import { CustomerSupportDetail } from './supportData';
import useCustomerSupportData from './useCustomerSupportData';
import { getTranslatedValue } from '../../utils/localizedString';

export type SupportQuestionList = {
  [key: string]: CustomerSupportDetail[];
};

export const SupportView = (): JSX.Element => {
  const { selectedSupport, searchText, setSearchText } = useSupportViewContext();
  const supportData = useCustomerSupportData();
  const { t, i18n } = useTranslation();

  const groupBySupport: SupportQuestionList = _groupBy(supportData, 'category');
  const searchResult = supportData.filter(
    (s) =>
      getTranslatedValue(s.title, i18n.language).toLowerCase().includes(searchText.toLowerCase()) ||
      getTranslatedValue(s.description, i18n.language).toLowerCase().includes(searchText.toLowerCase()),
  );

  const getSectionTitle = (documentationCat: string) => {
    switch (documentationCat) {
      case DocumentationCatEnum.POSITION_MANAGEMENT.toString():
        return t('support:positionManagement');
      case DocumentationCatEnum.TEAM_MANAGEMENT.toString():
        return t('support:teamManagement');
      default:
        return t('support:recruiterManagement');
    }
  };

  return (
    <Box py={8} backgroundColor="#fff" height="calc(100vh - 64px)" overflowY="auto">
      <Container maxW="1200px">
        <Box>
          <Container maxWidth="50%" paddingY="40px" textAlign="center">
            <Box mb={7}>
              <Heading as="h4" fontSize="lg">
                {t('support:recruiterHelpCenter')}
              </Heading>
              <Heading as="h3" fontSize="3xl">
                {t('support:howCanWeHelpYou')}
              </Heading>
            </Box>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type="search"
                value={searchText}
                placeholder={t('support:iAmLookingFor')}
                data-testid="search-input"
                onChange={(e) => setSearchText(e.target.value)}
              />
              <InputRightElement width="auto" paddingRight="0">
                <Button colorScheme="blue" size="md">
                  {t('support:search')}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Container>
          {searchText !== '' && (
            <OrderedList data-testid="support-search-result-container">
              {searchResult.length > 0 ? (
                searchResult.map((question) => (
                  <Fragment key={`${question.id}`}>
                    <SupportLinkItem fontSize="lg" question={question} />
                    <Text
                      fontSize="sm"
                      mb={4}
                      mt={1}
                      dangerouslySetInnerHTML={{
                        __html: getTranslatedValue(question.description, i18n.language).substring(0, 250),
                      }}
                    />
                  </Fragment>
                ))
              ) : (
                <Box fontSize="xl">{t('support:noResultFound')}</Box>
              )}
            </OrderedList>
          )}
          {searchText === '' && _isNil(selectedSupport) && (
            <Grid templateColumns="repeat(3, 1fr)" gap={4} paddingTop="40px" borderTop={`1px solid ${colors.gray[50]}`}>
              {Object.entries(groupBySupport).map(([keyValue, data]) => (
                <GridItem colSpan={1} key={keyValue}>
                  <Box className="feature-block" mb={4}>
                    <Heading fontSize="md" as="h6" mb={4}>
                      {getSectionTitle(keyValue)}
                    </Heading>
                    <List spacing={2} fontSize="sm">
                      {data.map((question) => (
                        <SupportLinkItem fontSize="sm" question={question} key={`${question.id}`} />
                      ))}
                    </List>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          )}
          {searchText === '' && selectedSupport && <SupportDetailView question={selectedSupport} />}
        </Box>
      </Container>
    </Box>
  );
};
