import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';
import { Collections } from '../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Query } from '../../firebase/firestore/query/query';
import { VideoGallery } from '../../firebase/firestore/documents/videoGallery';

const useVideoGalleryByAccountId = (accountId: string): VideoGallery[] => {
  const video = useQuerySnapshot<VideoGallery>(Collections.VIDEO_GALLERY, [Query.field('account').equals(accountId)]);
  if (_isNil(video)) return [];
  return _sortBy(video, 'fileName', ['desc']);
};

export default useVideoGalleryByAccountId;
