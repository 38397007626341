import React from 'react';
import { HStack, Stack } from '@chakra-ui/core';
import { SchemaBuilderProps } from './formBuilderSchema';
import ButtonPreview from './ButtonPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import FollowUpSchemaBuilder from './FollowUpSchemaBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';

export default function YesNoSchemaBuilder(props: SchemaBuilderProps) {
  const { defaultValue, language } = props;
  return (
    <Stack spacing={2}>
      <LabelSchemaBuilder {...props} />
      <FormElementTextBuilder {...props} placeholder="Enter text here for Yes/No input..." />
      <HStack>
        {defaultValue?.optionList?.map(({ text }, index) => {
          return <ButtonPreview key={index} label={text?.[language]!} />;
        })}
      </HStack>

      <FollowUpSchemaBuilder {...props} />
    </Stack>
  );
}
