import { action, Action, thunk, Thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { HRFormTemplateController } from '../../../controllers/HRFormTemplateController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { FieldSchema, HrFromTemplate } from '../hireForm/formElements/formBuilderSchema';
import { ValidationErrorItem } from 'joi';
import { AxiosError } from 'axios';

export interface CurrentSchema {
  id?: string;
  name?: string;
  questions: FieldSchema[];
}
export type HrFormTemplateStoreModel = {
  hrFormTemplate: HRFormTemplateModel;
};
export interface HRFormTemplateModel {
  isLoading: boolean;
  setLoader: Action<HRFormTemplateModel, boolean>;
  templates: HrFromTemplate[];
  errors: ValidationErrorItem[];
  setErrors: Action<HRFormTemplateModel, ValidationErrorItem[]>;
  setTemplates: Action<HRFormTemplateModel, HrFromTemplate[]>;
  currentTemplate?: CurrentSchema;
  setCurrentTemplate: Action<HRFormTemplateModel, CurrentSchema>;
  getTemplates: Thunk<HRFormTemplateModel, undefined, void, HttpStoreModel, Promise<any>>;
  createTemplate: Thunk<
    HRFormTemplateModel,
    { account: string; name: string; questions: any },
    void,
    HttpStoreModel,
    Promise<any>
  >;
  getTemplateById: Thunk<HRFormTemplateModel, { id: string }, void, HttpStoreModel, Promise<any>>;
  updateTemplate: Thunk<
    HRFormTemplateModel,
    { id: string; payload: { name: string; questions: any } },
    void,
    HttpStoreModel,
    Promise<any>
  >;
}

export const hrFormTemplateStoreModel: HRFormTemplateModel = {
  isLoading: false,
  setLoader: action((state, payload) => {
    state.isLoading = payload;
  }),
  templates: [],
  setTemplates: action((state, payload) => {
    state.templates = payload;
  }),
  errors: [],
  setErrors: action((state, payload) => {
    state.errors = payload;
  }),
  currentTemplate: undefined,
  setCurrentTemplate: action((state, payload) => {
    state.currentTemplate = payload;
  }),
  getTemplates: thunk(async (actions, _payload, { getStoreState }) => {
    try {
      actions.setLoader(true);
      const response = await new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry).getTemplates();
      actions.setTemplates(response?.data?.questions);
      return response?.data;
    } finally {
      actions.setLoader(false);
    }
  }),
  createTemplate: thunk(async (actions, payload, { getStoreState }) => {
    try {
      actions.setLoader(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.createTemplate(payload);
      return response?.data;
    } catch (error) {
      const newErr = error as AxiosError<{ code: number; message: string; errors: ValidationErrorItem[] }>;
      actions.setErrors(newErr.response?.data?.errors ?? []);
    } finally {
      actions.setLoader(false);
    }
  }),
  getTemplateById: thunk(async (actions, payload, { getStoreState }) => {
    try {
      actions.setLoader(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.getTemplateById(payload.id);
      actions.setCurrentTemplate(response?.data as any);
      return response?.data;
    } finally {
      actions.setLoader(false);
    }
  }),
  updateTemplate: thunk(async (actions, { id, payload }, { getStoreState }) => {
    try {
      actions.setLoader(true);
      const controller = new HRFormTemplateController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.updateTemplate(id, payload);
      return response?.data;
    } catch (error) {
      console.log('🚀 ~ updateTemplate:thunk ~ error:', error);
      const newErr = error as AxiosError<{ code: number; message: string; errors: ValidationErrorItem[] }>;
      console.error('🚀 ~ updateTemplate:thunk ~ error:');
      console.log('🚀 ~ updateTemplate:thunk ~ newErr.response?.data?.errors:', newErr.response?.data?.errors);
      actions.setErrors(newErr.response?.data?.errors ?? []);
      // actions.setErrors(newErr.response?.data?.errors ?? [{ message: 'Something went wrong', type: 'unknown', path: ['questions', 0, 'label'] }]);
    } finally {
      actions.setLoader(false);
    }
  }),
};
