/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useCallback, Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoMdChatbubbles } from 'react-icons/io';
import _isNil from 'lodash/isNil';
import { useHistory, useParams } from 'react-router';
import { motion } from 'framer-motion';
import { Box, Flex, HStack, Image, Menu, MenuIcon } from '@chakra-ui/core';
import moment from 'moment-timezone';
import AppyLogo from '../../assets/img/logo-web.png';
import { MESSAGING_TABS, MessagingTabsParams, PATH_MAIN } from '../../routes/constants';
import { SeekersFiltersContextProvider, useSeekersFilters } from './sidebar/filters/SeekersFiltersContext';
import { MessagingViewContextProvider } from './MessagingViewContext';
import { SeekerContainer } from './seeker/SeekerContainer';
import { messagingGridCSS, messagingGridCSSWhenInterview } from './MessagingView.styles';
import { InterviewMeetView } from './sidebar/meetInterview/InterviewMeetView';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { RemoteConfigKey } from '../../firebase/remoteConfig';
import { AvailableBookingWithPosition, Booking } from '../../firebase/firestore/documents/appointmentAvaiability';
import { seekersListContainer, seekersListItems } from './sidebar/seekerList/SeekerListContainer.styles';
import { seekersListFilters } from './sidebar/filters/SeekerListFilters.styles';
import { SeekerContainerTabItems } from './seeker/SeekerContainerContext';
import { headerGradientCSS } from '../../app/topBar/Header.styles';

const MotionBox = motion.custom(Box);

export const InterviewMessagingView = (): JSX.Element => {
  const { t } = useTranslation();
  const isCertnFeatureEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_CERTN_FEATURE];
  });

  const isUndismissedeEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_UN_DISMISSED_FEATURE];
  });

  const accountStore = useStoreState((s) => s.app.accounts);

  const [isCertnEnabled, setCertnEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (!_isNil(accountStore) && accountStore.configuration && accountStore.configuration.certnEnabled) {
      setCertnEnabled(accountStore.configuration.certnEnabled);
    } else {
      setCertnEnabled(false);
    }
  }, [accountStore]);
  const { candidateId } = useParams<MessagingTabsParams>();

  const history = useHistory();

  const gracePeriod: number = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE];
  });

  const isMeetEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_MEET_FEATURE];
  });

  const interViewDetail: AvailableBookingWithPosition | undefined = useStoreState((state) => {
    return state.interview.eventDetail;
  });

  const interviewStarted: boolean = useStoreState((state) => {
    return state.interview.interviewStarted;
  });

  // istanbul ignore next
  const { clearInterviewEvent } = useStoreActions((actions) => actions.interview);

  const { setChangeCandidate, changeCandidate } = useSeekersFilters();

  const isAbleToJoinVideoInterview = useCallback(
    (booking: Booking | undefined) => {
      if (_isNil(booking)) return false;
      return moment().isBetween(
        moment(booking.date).subtract(5, 'minutes'),
        moment(booking.date).add(booking.duration, 'minutes').add(gracePeriod, 'minute'),
      );
    },
    [gracePeriod],
  );

  const isInterviewEnabled = (): boolean => {
    if (_isNil(interViewDetail)) {
      return false;
    }
    if (changeCandidate) setChangeCandidate(false);
    return isMeetEnabled && isAbleToJoinVideoInterview(interViewDetail?.booking);
  };

  const isInterviewEnabledBoolean = isInterviewEnabled();

  const checkToredirectToMessaging = useCallback(() => {
    if (!isInterviewEnabledBoolean) {
      history.push(MESSAGING_TABS(candidateId, SeekerContainerTabItems.Messaging));
    }
  }, [candidateId, isInterviewEnabledBoolean, history]);

  useEffect(() => {
    checkToredirectToMessaging();

    return () => {
      clearInterviewEvent();
    };
  }, [isInterviewEnabledBoolean, checkToredirectToMessaging, clearInterviewEvent]);

  const getCssForGrid = () => {
    if (isInterviewEnabled()) return messagingGridCSSWhenInterview;
    return messagingGridCSS;
  };

  const renderEmptyBoxToAdjustView = () => (
    <Box data-testid="SeekerListContainer" css={seekersListContainer} d="contents">
      <Box px={3} css={seekersListFilters} />
      <MotionBox data-testid="SidebarView" css={seekersListItems}>
        <Box height="80%" />
      </MotionBox>
    </Box>
  );

  const renderInerviewHeader = () => (
    <Flex data-testid="Header" css={headerGradientCSS} justify="space-between" align="center" p={4}>
      <Link to={PATH_MAIN}>
        <HStack spacing="1rem">
          <Image src={AppyLogo} alt={t('app:bundleName')} data-testid="LogoImage" />
          <Box>
            <Box color="#FFFFFF" title={t('app:bundleName')} data-testid="LogoLightSVG">
              {/* eslint-disable react/jsx-one-expression-per-line */}
              <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{t('app:firstName')} </span>
              <span>{t('app:lastName')}</span>
            </Box>
          </Box>
        </HStack>
      </Link>
      <Menu placement="bottom-end">
        <Flex justify="flex-end">
          {!interviewStarted && (
            <Menu>
              <MenuIcon marginTop={1} as={Box} marginRight={4}>
                <Link to={MESSAGING_TABS(candidateId, SeekerContainerTabItems.Messaging)}>
                  <IoMdChatbubbles size={32} color="#fff" />
                </Link>
              </MenuIcon>
            </Menu>
          )}
        </Flex>
      </Menu>
    </Flex>
  );

  return (
    <Box data-testid="MessagingContainer">
      <Fragment>
        <MessagingViewContextProvider>
          <SeekersFiltersContextProvider>
            <MotionBox data-testid="MessagingView" className="messaging-view" css={getCssForGrid}>
              <InterviewMeetView />

              {renderInerviewHeader()}

              {renderEmptyBoxToAdjustView()}

              <SeekerContainer
                data-testid="SeekerContainer"
                isInterviewEnabled={isInterviewEnabled()}
                isCertnFeatureEnabled={isCertnFeatureEnabled}
                isCertnEnabled={isCertnEnabled}
                isUndismissedeEnabled={isUndismissedeEnabled}
                isMessagingView={false}
              />
            </MotionBox>
          </SeekersFiltersContextProvider>
        </MessagingViewContextProvider>
      </Fragment>
    </Box>
  );
};
