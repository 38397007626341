export default {
  recruiterTable: {
    tableHeader: {
      name: 'Name',
      availability: 'Availability',
      totalAvailabilityHours: 'Total Availability (minutes)',
      requiredDuration: 'Required Availability (minutes)',
      noOfHirings: 'No. of Hires',
      successfulInterviews: 'Successful Interviews',
      interviewCancellation: 'Canceled Interviews',
      candidateNoShow: 'Candidate No Show',
      recruiterNoShow: 'Recruiter No Show',
    },
    lowToHighString: {
      low: 'Low',
      medium: 'Medium',
      high: 'High',
      none: 'None',
    },
    recruiterTableLoadingMessage: 'Loading Recruiter Table...',
  },
  recruitersCandidateTable: {
    tableHeader: {
      candidateName: 'Name',
      businessPosition: 'Position',
      businessLocation: 'Location',
      notes: 'Notes',
      status: 'Status',
      viewBtn: 'View',
    },
    recruitersCandidateTableLoadingMessage: 'Loading Candidates Table...',
    viewNotesModal: {
      noteHeader: 'Candidate Notes',
      closeNote: 'Close',
      noNotesMessage: "You can view the recruiter's notes for this candidate here.",
    },
  },
  dashboardSearchInputs: {
    searchRecruiterPlaceholder: 'Search recruiters by name',
    searchCandidatePlaceholder: 'Search candidates by name',
  },
  tabHeader: {
    dashboard: 'Dashboard',
    gotoAvailability: 'Calendar',
    interviews: 'Interviews',
    candidateDetails: 'Details by Candidate',
  },
  recruiterDataVisualisation: {
    uncompletedInterviews: 'Incompleted Interviews',
    unsuccessfulInterviews: 'Unsuccessful Interviews',
    interviewCancellation: 'Canceled Interviews',
    totalScheduled: 'Total Interviews',
    totalInterviewed: 'Interviews Completed',
    totalCandidates: 'Applicants',
    totalInterviews: 'Total Candidates',
    totalAppointments: 'Total Interviews',
    last30Days: 'Last 30 Days',
    visualisationLoadingMessage: 'Loading Dashboard...',
    videoInterviewDetails: 'Interview Details',
    vsLastMonth: 'vs last {{daysDifference}} days',
    totalJobPositions: 'Positions',
    lastLogin: 'Last login',
    interviewsCompleted: 'Completed Interviews',
    successfulInterviews: 'Successful Interviews',
    interviewCompleted: 'Completed',
    total: 'Total',
    upcoming: 'Upcoming',
    appointments: 'Interviews',
  },
  loadingMessages: {
    loadingRecruiterMetrics: 'Loading Recruiter Metrics...',
  },
  recruiterDashboardTooltipFor: {
    totalInterviews: 'Interviews accepted by candidates in the last {{daysDifference}} days.',
    upcomingInterviews: 'Upcoming interviews for next 7 days.',
    completedInterviews: 'Successful interviews completed in the last {{daysDifference}} days.',
    incompletedInterviews: "Interviews where the recruiter and/or the candidate didn't join, as well as canceled interviews.",
    canceledInterviews: 'Interviews that were canceled in the last {{daysDifference}} days.',
    candidateNoShow: 'Interviews where the candidate did not show.',
    recruiterNoShow: 'Interviews where the recruiter did not show.',
    hired: 'Candidates hired in the last {{daysDifference}} days.',
    barChartPercentage: 'Percentage of successful interviews compared to active candidates.',
    activeCandidatesInLast30Days: "Candidates who've applied in the specified timeframe.",
    totalJobPositions: 'Positions.',
  },
};
