import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/core';
import { CertnKeysAndUrl } from './keys/CertnKeysAndUrl';
import { useStoreState } from '../../../models/hooks';
import { CertnBackgroundChecks } from './checks/CertnBackgroundChecks';
import { CertnTutorial } from './tutorial/CertnTutorial';

export const CertnIntegrationView = (): JSX.Element => {
  const { user } = useStoreState((s) => s.app);
  const { t } = useTranslation('certn');

  return (
    <React.Fragment>
      <Box>
        <Tabs colorScheme="blue">
          <TabList borderWidth={0} px={2} mb={6} borderRadius={5} backgroundColor="white">
            <Tab
              py={3}
              fontSize="sm"
              _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
              _focus={{ boxShadow: 'none' }}
            >
              {t('checks')}
            </Tab>
            <Tab
              py={3}
              fontSize="sm"
              _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
              _focus={{ boxShadow: 'none' }}
            >
              {t('keysUrlLabel')}
            </Tab>
            <Tab
              py={3}
              fontSize="sm"
              _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
              _focus={{ boxShadow: 'none' }}
            >
              {t('certnSetupLabel')}
            </Tab>
          </TabList>
          <TabPanels backgroundColor="white" borderRadius={5}>
            <TabPanel p={0}>
              <CertnBackgroundChecks accountId={user?.account} />
            </TabPanel>
            <TabPanel p={0}>
              <CertnKeysAndUrl accountId={user?.account} />
            </TabPanel>
            <TabPanel p={0}>
              <CertnTutorial />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </React.Fragment>
  );
};
