import React, { useState, useCallback, useEffect } from 'react';
import { Box, Heading, VStack, Button, Input, FormControl, FormLabel, useToast } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { ModernHireConfigData } from './ModernHireConfigData';

const ModernHireConfigView = (): JSX.Element => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modernHireConfigData, setModernHireConfigData] = useState<ModernHireConfigData>({
    modernHireSubDomain: '',
    password: '',
    username: '',
  });
  const accountId = useStoreState((s) => s.app.accounts?.id);
  const { getModernHireConfigData, updateModernHireConfigData } = useStoreActions((actions) => actions.administration);

  const handleChange = (value: string, field: string) => {
    setModernHireConfigData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const submitModernHireConfig = async () => {
    if (accountId) {
      setIsLoading(true);
      const response = await updateModernHireConfigData({ accountId, configData: modernHireConfigData });
      toast({
        title: response.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const getModernHireData = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const configData = await getModernHireConfigData({ accountId: id });
      if (configData) {
        setModernHireConfigData(configData);
      }
      setIsLoading(false);
    },
    [getModernHireConfigData],
  );

  useEffect(() => {
    if (accountId) {
      getModernHireData(accountId).catch((err) => console.log(err));
    }
  }, [accountId, getModernHireData]);

  return (
    <VStack align="left" backgroundColor="white" p={4}>
      <Box py={2} px={4} borderBottom="0.5px solid #ECEFF1">
        <Heading as="h4" fontSize="lg" lineHeight="1.3">
          {t('administration:modernHire.sidebarTitle')}
        </Heading>
      </Box>
      <Box py={2} px={4}>
        <Box maxWidth="480px">
          <FormControl mb={3} isRequired>
            <FormLabel>{t('administration:modernHire.form.userName')}</FormLabel>
            <Input
              type="text"
              placeholder={t('administration:modernHire.form.userName')}
              value={modernHireConfigData.username}
              data-testid="username"
              onChange={(e) => handleChange(e.target.value, 'username')}
            />
          </FormControl>
          <FormControl mb={3} isRequired>
            <FormLabel>{t('administration:modernHire.form.password')}</FormLabel>
            <Input
              type="password"
              placeholder={t('administration:modernHire.form.password')}
              data-testid="password"
              value={modernHireConfigData.password}
              onChange={(e) => handleChange(e.target.value, 'password')}
            />
          </FormControl>
          <FormControl mb={5} isRequired>
            <FormLabel>{t('administration:modernHire.form.modernHireSubDomain')}</FormLabel>
            <Input
              type="url"
              placeholder={t('administration:modernHire.form.modernHireSubDomain')}
              value={modernHireConfigData.modernHireSubDomain}
              data-testid="modern-hire-sub-domain"
              onChange={(e) => handleChange(e.target.value, 'modernHireSubDomain')}
            />
          </FormControl>
          <FormControl>
            <Button
              isLoading={isLoading}
              disabled={
                !(modernHireConfigData.username && modernHireConfigData.password && modernHireConfigData.modernHireSubDomain) ||
                isLoading
              }
              colorScheme="blue"
              data-testid="submit-btn"
              onClick={() => submitModernHireConfig()}
            >
              {t('administration:modernHire.form.submit')}
            </Button>
          </FormControl>
        </Box>
      </Box>
    </VStack>
  );
};

export default ModernHireConfigView;
