import _some from 'lodash/some';
import _assignIn from 'lodash/assignIn';
import { HistoryPresenter } from './historyPresenter';
import { CandidateAudit } from '../../../../../firebase/firestore/documents/candidateAudit';
import { CandidateHistoryActionName } from '../../../../../firebase/firestore/documents/candidateAuditActionNames';
import { HistoryTypes } from './HistoryTypes';

// eslint-disable-next-line import/no-default-export
export default class HistoriesPresenter {
  public messages: HistoryPresenter[] = [];

  constructor(candidateAuditMessages: CandidateAudit[]) {
    candidateAuditMessages
      .filter(
        (ca) =>
          ca.action !== CandidateHistoryActionName.QUESTION_ANSWERED &&
          ca.action !== CandidateHistoryActionName.FREE_FORM_QUESTION_ANSWERED,
      )
      .forEach((message) => {
        if (
          message.action === CandidateHistoryActionName.COLLABORATOR_INTERVIEW_REVIEW &&
          !_some(this.messages, { action: CandidateHistoryActionName.COLLABORATOR_INTERVIEW_REVIEW })
        ) {
          this.messages.push(new HistoryPresenter(message));
        }
        // istanbul ignore next
        else if (message.action !== CandidateHistoryActionName.COLLABORATOR_INTERVIEW_REVIEW) {
          this.messages.push(new HistoryPresenter(message));
        }
      });
  }

  getRecentOneHistoryOfEachStatus = () => {
    const historyStatus: string[] = [];
    const recentHistoryOfEachStatus: HistoryPresenter[] = [];

    this.messages.forEach((item) => {
      switch (item.action) {
        case CandidateHistoryActionName.APPOINTMENT_ASSESSMENT_POSITIVE:
        case CandidateHistoryActionName.APPOINTMENT_ASSESSMENT_NEGATIVE:
        case CandidateHistoryActionName.SEEKER_APPOINTMENT_REMINDER_SENT:
        case CandidateHistoryActionName.APPOINTMENT_REQUEST_CANCELLED:
        case CandidateHistoryActionName.APPOINTMENT_REQUEST_CREATED:
        case CandidateHistoryActionName.APPOINTMENT_PERIOD_CANCELLED:
        case CandidateHistoryActionName.APPOINTMENT_REQUEST_ACCEPTED:
        case CandidateHistoryActionName.COLLABORATOR_INTERVIEW_REVIEW:
          if (!historyStatus.includes(HistoryTypes.APPOINTMENT)) {
            _assignIn(item, { actionCategory: HistoryTypes.APPOINTMENT });
            recentHistoryOfEachStatus.push(item);
            historyStatus.push(HistoryTypes.APPOINTMENT);
          }
          break;
        case CandidateHistoryActionName.SCREENING_COMPLETED:
        case CandidateHistoryActionName.INVITED_FOR_SCREENING:
          if (!historyStatus.includes(HistoryTypes.BACKGROUND_CHECK)) {
            _assignIn(item, { actionCategory: HistoryTypes.BACKGROUND_CHECK });
            recentHistoryOfEachStatus.push(item);
            historyStatus.push(HistoryTypes.BACKGROUND_CHECK);
          }
          break;
        case CandidateHistoryActionName.HIRE_FORM_COMPLETED:
        case CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_REQUESTED:
        case CandidateHistoryActionName.CANDIDATE_FORM_HELP_REQUESTED:
        case CandidateHistoryActionName.HIRE_FORM_REQUESTED:
        case CandidateHistoryActionName.CANDIDATE_APPROVED:
        case CandidateHistoryActionName.HIRE_FORM_IN_PROGRESS:
        case CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_IN_PROGRESS:
        case CandidateHistoryActionName.CANDIDATE_REJECTED:
          if (!historyStatus.includes(HistoryTypes.HIRE_FORM)) {
            _assignIn(item, { actionCategory: HistoryTypes.HIRE_FORM });
            recentHistoryOfEachStatus.push(item);
            historyStatus.push(HistoryTypes.HIRE_FORM);
          }
          break;
        case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_STARTED:
        case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_IN_PROGRESS:
        case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_PASSED:
        case CandidateHistoryActionName.MODERN_HIRE_ASSESSMENT_FAILED:
          if (!historyStatus.includes(HistoryTypes.MODERN_HIRE)) {
            _assignIn(item, { actionCategory: HistoryTypes.MODERN_HIRE });
            recentHistoryOfEachStatus.push(item);
            historyStatus.push(HistoryTypes.MODERN_HIRE);
          }
          break;
        case CandidateHistoryActionName.CANDIDATE_BOOKMARKED:
        case CandidateHistoryActionName.CANDIDATE_CALLED:
        case CandidateHistoryActionName.CANDIDATE_DISMISSED:
        case CandidateHistoryActionName.CANDIDATE_HIRED:
        case CandidateHistoryActionName.CANDIDATE_UNHIRED:
        case CandidateHistoryActionName.CANDIDATE_UNLOCKED:
          if (!historyStatus.includes(HistoryTypes.CANDIDATE)) {
            _assignIn(item, { actionCategory: HistoryTypes.CANDIDATE });
            recentHistoryOfEachStatus.push(item);
            historyStatus.push(HistoryTypes.CANDIDATE);
          }
          break;
        case CandidateHistoryActionName.OFFER_MADE:
        case CandidateHistoryActionName.OFFER_REJECTED:
        case CandidateHistoryActionName.OFFER_ACCEPETED_BY_SEEKER:
        case CandidateHistoryActionName.OFFER_REVOKE:
        case CandidateHistoryActionName.OFFER_REJECTED_BY_SEEKER:
          if (!historyStatus.includes(HistoryTypes.OFFER)) {
            _assignIn(item, { actionCategory: HistoryTypes.OFFER });
            recentHistoryOfEachStatus.push(item);
            historyStatus.push(HistoryTypes.OFFER);
          }
          break;
        case CandidateHistoryActionName.CANDIDATE_RECORDING_UPLOADED:
        case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_COMPETENT:
        case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_LIMITED:
        case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_OCCASIONAL:
        case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_NEEDS_REVIEW:
        case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_REQUESTED:
        case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_RE_REQUESTED:
        case CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_EXPERT:
        case CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_COMPETENT:
        case CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_LIMITED:
        case CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_OCCASIONAL:
        case CandidateHistoryActionName.CANDIDATE_PREVIOUS_LANGUAGE_EVALUATION_NEEDS_REVIEW:
        case CandidateHistoryActionName.CANDIDATE_LANGUAGE_EVALUATION_EXPERT:
          if (!historyStatus.includes(HistoryTypes.AUDIO_LANGUAGE_EVALUATION)) {
            _assignIn(item, { actionCategory: HistoryTypes.AUDIO_LANGUAGE_EVALUATION });
            recentHistoryOfEachStatus.push(item);
            historyStatus.push(HistoryTypes.AUDIO_LANGUAGE_EVALUATION);
          }
          break;
        case CandidateHistoryActionName.NOT_LOOKING_FOR_WORK:
          if (!historyStatus.includes(HistoryTypes.NOT_LOOKING_FOR_WORK)) {
            _assignIn(item, { actionCategory: HistoryTypes.NOT_LOOKING_FOR_WORK });
            recentHistoryOfEachStatus.push(item);
            historyStatus.push(HistoryTypes.NOT_LOOKING_FOR_WORK);
          }
          break;
        case CandidateHistoryActionName.DEACTIVATED:
          if (!historyStatus.includes(HistoryTypes.DEACTIVATED)) {
            _assignIn(item, { actionCategory: HistoryTypes.DEACTIVATED });
            recentHistoryOfEachStatus.push(item);
            historyStatus.push(HistoryTypes.DEACTIVATED);
          }
          break;
        default:
          break;
      }
    });
    return recentHistoryOfEachStatus;
  };

  // istanbul ignore next
  getRecentOfferMadeStatus = () => {
    const historyStatus: string[] = [];
    const recentOfferStatus: HistoryPresenter[] = [];

    this.messages.forEach((item) => {
      switch (item.action) {
        case CandidateHistoryActionName.OFFER_MADE:
          if (!historyStatus.includes(HistoryTypes.OFFER)) {
            _assignIn(item, { actionCategory: HistoryTypes.OFFER });
            recentOfferStatus.push(item);
            historyStatus.push(HistoryTypes.OFFER);
          }
          break;

        default:
          break;
      }
    });
    return recentOfferStatus;
  };
}
