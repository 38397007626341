import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Collections } from '../../firebase/firestore/collections';
import { CandidateRecruiterAction } from '../../firebase/firestore/documents/candidateRecruiterAction';
import { Query } from '../../firebase/firestore/query/query';

const useCandidateRecruiterAction = (accountID: string): CandidateRecruiterAction[] | undefined => {
  return useQuerySnapshot<CandidateRecruiterAction>(Collections.CANDIDATE_RECRUITER_ACTION, [
    Query.field('accountId').equals(accountID),
  ]);
};

export default useCandidateRecruiterAction;
