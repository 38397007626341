import { thunk, Thunk } from 'easy-peasy';
import { BusinessResponse } from '../../administration/positionManagement/BusinessType';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { InviteCollaborators } from './InviteCollaboratorsTypes';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { InviteCollaboratorsController } from '../../../controllers/InvitateCollaboratorsController';

export type InviteCollaboratorsStoreModel = {
  inviteCollaborators: InviteCollaboratorsModel;
};

export interface InviteCollaboratorsModel {
  sendInvitationToCollaborators: Thunk<
    InviteCollaboratorsModel,
    {
      collaborators: InviteCollaborators;
    },
    void,
    HttpStoreModel,
    Promise<BusinessResponse>
  >;
  getInvitationLink: Thunk<
    InviteCollaboratorsModel,
    {
      collaborators: InviteCollaborators;
    },
    void,
    HttpStoreModel,
    Promise<{ invitationLink: string }>
  >;
  getCollaboratorsInvite: Thunk<
    InviteCollaboratorsModel,
    {
      bookingId: string;
    },
    void,
    HttpStoreModel,
    Promise<InviteCollaborators>
  >;
}

export const inviteCollaboratorsStoreModel: InviteCollaboratorsModel = {
  sendInvitationToCollaborators: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new InviteCollaboratorsController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.sendInvitation(payload.collaborators);
    return response?.data as BusinessResponse;
  }),
  getInvitationLink: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new InviteCollaboratorsController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getInvitationLink(payload.collaborators);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response?.data;
  }),
  getCollaboratorsInvite: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new InviteCollaboratorsController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getCollaboratorsInvite(payload.bookingId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    return response?.data?.videoInterviewInvite as InviteCollaborators;
  }),
};
