import { css } from '@emotion/core';
import ribbonBackground from '../../assets/img/ribbons.svg';

export const EmailInvitationStyle = css`
  background-color: #fff7f1;
  height: 100%;

  .invitation-content-box {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 27px;
    padding: 60px 35px;

    &.accepted {
      background-image: url(${ribbonBackground});
    }

    .logo {
      margin: -102px auto 40px auto;
    }
    h4 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
    p {
      line-height: 2;
    }
  }
`;
