import _isNil from 'lodash/isNil';
import _head from 'lodash/head';
import { useMemo } from 'react';
import LastMessagePresenter from './lastMessagePresenter';
import { Collections } from '../../../../../firebase/firestore/collections';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import { useQuerySnapshot } from '../../../../../firebase/firestore/hooks';
import { Query } from '../../../../../firebase/firestore/query/query';
import { CandidateAudit } from '../../../../../firebase/firestore/documents/candidateAudit';
import { OrderByDirection } from '../../../../../firebase/firestore/query/criteria';
import { CandidateMessagingActionName } from '../../../../../firebase/firestore/documents/candidateAuditActionNames';

const useLastMessage = (candidateId: string): { lastMessage: LastMessagePresenter; initialized: boolean } => {
  const { currentUserProfile } = useCurrentUserProfile();

  const candidateAudits = useQuerySnapshot<CandidateAudit>(Collections.CANDIDATE_AUDIT, [
    Query.field<CandidateAudit>('candidateId').equals(candidateId),
    Query.field<CandidateAudit>('action').in([
      CandidateMessagingActionName.SEEKER_MESSAGE,
      CandidateMessagingActionName.EMPLOYER_MESSAGE,
    ]),
    Query.field<CandidateAudit>('account').equals(currentUserProfile?.account),
    Query.orderBy<CandidateAudit>('timestamp', OrderByDirection.desc),
    Query.limit(1),
  ]);

  return useMemo(() => {
    let initialized = false;
    let lastMessage: LastMessagePresenter = new LastMessagePresenter();

    if (candidateAudits) {
      const candidateAudit = _head(candidateAudits);
      if (!_isNil(candidateAudit)) {
        lastMessage = new LastMessagePresenter(candidateAudit.id, candidateAudit.message);
      }
      initialized = true;
    }

    return { lastMessage, initialized };
  }, [candidateAudits]);
};

export default useLastMessage;
