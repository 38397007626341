export type SingleTeamType = {
  id: string;
  name: string;
  status: string;
  phoneNumber: string;
  action?: number;
};

export type SingleTeamInviteType = {
  id: string;
  email: string;
  status: string;
  state: string;
  userId: string;
  action?: number;
};

export type TeamMemberInfoType = {
  user: string;
  inviteeEmail: string;
  accountInviteId?: string;
};

export enum TeamMemberAction {
  DELETE = 'delete',
  PROMOTE = 'promote',
  DEMOTE = 'demote',
}

export enum TeamInviteAction {
  CANCEL = 'cancel',
  REINVITE = 'reinvite',
}
