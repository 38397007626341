import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import FormElementTextBuilder from './FormElementTextBuilder';
import { Stack } from '@chakra-ui/core';
import LabelSchemaBuilder from './LabelSchemaBuilder';

export default function TextFieldSchemaBuilder({ ...props }: SchemaBuilderProps) {
  return (
    <Stack>
      <LabelSchemaBuilder {...props} />
      <FormElementTextBuilder {...props} />
    </Stack>
  );
}
