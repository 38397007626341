import React from 'react';
import { Avatar, AvatarProps } from '@chakra-ui/core';
import { MessagePresenter } from '../messagePresenter';
import { useChatPicture } from './useChatPicture';
import { useStoreState } from '../../../../../../models/hooks';
import { RemoteConfigKey } from '../../../../../../firebase/remoteConfig';
import DefaultProfilePicture from '../../../../../../assets/img/default-profile-picture.png';

export type UserPictureProp = {
  message: MessagePresenter;
} & AvatarProps;

export const UserPicture = ({ children, name, style, message, ...rest }: UserPictureProp): React.ReactElement => {
  const picture = useChatPicture(message);

  const recruiterProfileImage = useStoreState((s) => s.app.recruiterProfileImage);
  const userId = useStoreState((s) => s.app?.user?.id);

  // istanbul ignore next
  const getChatProfileImage = (pictureUrl: string | undefined): string | undefined => {
    if (message.isFromSeeker || message.userId !== userId) {
      return pictureUrl;
    }
    return recruiterProfileImage;
  };

  /* eslint-disable*/
  const isInitialsFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_INITIALS_FEATURE];
  });
  /* eslint-enable */

  return (
    <Avatar
      style={{ color: 'white', ...style }}
      name={isInitialsFeatureEnabled ? /* istanbul ignore next */ name?.trim() : ''}
      {...rest}
      icon={<img src={DefaultProfilePicture} alt="" style={{ borderRadius: 100, position: 'relative' }} />}
      src={getChatProfileImage(picture)}
    >
      {children}
    </Avatar>
  );
};
