import { ConnectOptions } from 'twilio-video';
import { isMobile } from '../../isMobileUtil';

export const useConnectionOptions = () => {
  // See: https://sdk.twilio.com/js/video/releases/2.0.0/docs/global.html#ConnectOptions
  // for available connection options.
  const connectionOptions = {
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
    bandwidthProfile: {
      video: {
        dominantSpeakerPriority: 'standard',
      },
    },
    dominantSpeaker: true,
  } as ConnectOptions;

  if (isMobile() && connectionOptions?.bandwidthProfile?.video) {
    connectionOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;
  }

  // Here we remove any 'undefined' values. The twilio-video SDK will only use defaults
  // when no value is passed for an option. It will throw an error when 'undefined' is passed.
  return connectionOptions;
};
