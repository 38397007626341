import { useMemo } from 'react';
import { CurrentUserProfilePresenter } from './CurrentUserProfilePresenter';
import { useStoreState } from '../../models/hooks';

export type UninitializedUserProfile = {
  currentUserProfile: undefined;
  initialized: false;
};

export type UserProfile = {
  currentUserProfile: CurrentUserProfilePresenter;
  initialized: true;
};

export const useCurrentUserProfile = (): UninitializedUserProfile | UserProfile => {
  const currentUser = useStoreState((state) => state.app.user);
  return useMemo(() => {
    return currentUser
      ? { initialized: true, currentUserProfile: new CurrentUserProfilePresenter(currentUser) }
      : { initialized: false };
  }, [currentUser]);
};
