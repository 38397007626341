import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { GalleryImageType, ImageGalleryUploadResponse } from '../modules/administration/imageGallery/ImageGalleryType';

export class ImageGalleryController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getImageGallery(accountId: string) {
    const path = `/api/v1/account/${accountId}/gallery/images`;
    return this.httpClient.get<{ images: GalleryImageType[] } | undefined>(path);
  }

  async uploadImageToGallery(accountId: string, formData: FormData) {
    const path = `/api/v1/account/${accountId}/gallery/images`;
    return this.httpClient.post<ImageGalleryUploadResponse>(path, formData);
  }
}
