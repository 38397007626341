import _sortBy from 'lodash/sortBy';
import { Candidate, CandidateStatus } from '../../../../../firebase/firestore/documents/candidate';

export default class SeekerInfoPresenter {
  readonly id = this.candidate?.seeker;

  readonly firstName = this.candidate?.firstName;

  readonly lastName = this.candidate?.lastName;

  readonly statuses = _sortBy(
    this.candidates.map((candidate: Candidate) => ({
      status: candidate.computedStatus?.status ?? CandidateStatus.Unknown,
      date: candidate.computedStatus?.lastUpdate ?? new Date(),
      key: candidate.id,
    })),
    'date',
  );

  constructor(readonly candidate: Readonly<Candidate> | undefined, readonly candidates: Readonly<Candidate>[]) {}

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

export type CandidateStatusPresenter = {
  status: CandidateStatus;
  date: Date;
  key: string;
};
