import { css } from '@emotion/core';

export const gaugeNeedleCss = css`
  div {
    max-width: 450px;
  }

  .light text {
    color: black !important;
    fill: black !important;
  }
`;
