import { extendTheme } from '@chakra-ui/core';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import colors from './colors';
import { avatarWidth } from './sizes';

// 2. Update the breakpoints as key-value pairs
const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '1045px',
  xl: '1200px',
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const theme = extendTheme({
  fonts: {
    body: 'Helvetica',
  },
  components: {
    Avatar: {
      sizes: {
        sm: {
          container: {
            width: avatarWidth.sm,
            height: avatarWidth.sm,
          },
        },
        md: {
          container: {
            width: avatarWidth.md,
            height: avatarWidth.md,
          },
        },
        lg: {
          container: {
            width: avatarWidth.lg,
            height: avatarWidth.lg,
          },
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            border: '1px',
            borderColor: colors.dividerGray,
          },
        },
      },
    },
  },
  colors: {
    ...colors,
    neutralGray: {
      ...colors.neutralGray,
    },
    red: {
      ...colors.red,
    },
    orange: {
      ...colors.orange,
    },
    lightBlue: {
      ...colors.lightBlue,
    },
    green: {
      ...colors.green,
    },
    cyan: {
      ...colors.cyan,
    },
  },
  shadows: {
    '2xl': '0px 24px 24px rgba(0, 0, 0, 0.24), 0px 0px 24px rgba(0, 0, 0, 0.12)',
  },
  breakpoints,
});

export default theme;
