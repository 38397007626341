import React from 'react';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../models/hooks';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import GoogleCalendarIcon from '../../../assets/img/icon-google-calendar.svg';
import OutlookCalendarIcon from '../../../assets/img/icon-outlook-calendar.svg';

const calendarIconStyle = {
  maxWidth: '60px',
  borderRadius: '50%',
  boxShadow: '1px 1px 5px #00000024',
  padding: '10px',
  marginRight: '10px',
};

export type OtherCalendarModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setIsGoogleLogin: (event: React.MouseEvent) => void;
  microsoftLogin: () => void;
};

function OtherCalendarModal({ isOpen, onClose, setIsGoogleLogin, microsoftLogin }: OtherCalendarModalProps): JSX.Element {
  const { t } = useTranslation('calendar');
  const isGoogleCalendarIntegrateEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_INTEGRATE_GOOGLE_CALENDAR_FEATURE];
  });
  const isMicrosoftCalendarIntegrateEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_INTEGRATE_MICROSOFT_CALENDAR_FEATURE];
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-testid="connectOtherCalendarModal">
        <ModalHeader>{t('otherCalendar.selectCalendarToConnect')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={4} pb={8}>
          {process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID && isGoogleCalendarIntegrateEnabled && (
            <Button
              data-testid="connectGoogleBtn"
              onClick={(e) => setIsGoogleLogin(e)}
              leftIcon={<img src={GoogleCalendarIcon} alt="google calendar icon" style={calendarIconStyle} />}
              mr={3}
              colorScheme="blue"
              variant="link"
              _focus={{
                boxShadow: 'none',
              }}
            >
              {t('otherCalendar.googleBtnLabel')}
            </Button>
          )}
          {process.env.REACT_APP_MICROSOFT_CALENDAR_CLIENT_ID && isMicrosoftCalendarIntegrateEnabled && (
            <Button
              data-testid="connectMicrosoftBtn"
              onClick={() => microsoftLogin()}
              leftIcon={<img src={OutlookCalendarIcon} alt="Outlook Calendar icon" style={calendarIconStyle} />}
              colorScheme="blue"
              variant="link"
              mt={5}
              _focus={{
                boxShadow: 'none',
              }}
            >
              {t('otherCalendar.outlookBtnLabel')}
            </Button>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default OtherCalendarModal;
