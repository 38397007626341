import { Tooltip } from '@chakra-ui/core';
import React from 'react';

export type PositionAutomationInfoView = {
  toolTipMessage: string;
  automationLabel: string;
};

export const PositionAutomationInfoView = ({ toolTipMessage, automationLabel }: PositionAutomationInfoView): JSX.Element => {
  return (
    <Tooltip label={toolTipMessage} aria-label={toolTipMessage}>
      {automationLabel}
    </Tooltip>
  );
};
