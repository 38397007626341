/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { ListItem, Button } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomerSupportDetail } from './supportData';
import { useSupportViewContext } from './SupportViewContext';
import { getTranslatedValue } from '../../utils/localizedString';

export type SupportLinkItemProps = {
  question: CustomerSupportDetail;
  fontSize?: string;
};

export const SupportLinkItem = ({ question, fontSize }: SupportLinkItemProps): JSX.Element => {
  const { setSelectedSupport, setSearchText } = useSupportViewContext();
  const { i18n } = useTranslation();
  return (
    <ListItem key={question.id}>
      <Button
        variant="link"
        fontWeight="normal"
        fontSize={fontSize}
        data-testid={`${question.id}`}
        onClick={() => {
          setSelectedSupport(question);
          setSearchText('');
        }}
      >
        {getTranslatedValue(question.title, i18n.language)}
      </Button>
    </ListItem>
  );
};
