import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Text,
} from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AlertProps {
  open: boolean;
  setIsOpen: (open: boolean) => void;
}

const BrowserDetectAlertDialog: React.FC<AlertProps> = (props: AlertProps) => {
  const { t } = useTranslation('browserAlertDialog');

  const cancelRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  return (
    <AlertDialog
      size="2xl"
      closeOnOverlayClick
      isOpen={props.open}
      leastDestructiveRef={cancelRef}
      onClose={() => props.setIsOpen(false)}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius={0} data-testid="browserDetectTest">
          <AlertDialogHeader fontSize="2xl" fontWeight="bold" textAlign="center" pt={10}>
            {t('header')}
            <Text fontSize="sm" pt={2}>
              {t('message')}
            </Text>
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <Center>
            <Divider pt={5} />
          </Center>
          <AlertDialogBody>
            <Flex align="center" justify="space-evenly" py={8}>
              <Box>
                <Image src="/edge.jpg" alt="Microsoft Edge" />
                <Text align="center" pt={4}>
                  Edge
                </Text>
              </Box>
              <Box>
                <Image src="/chrome.png" alt="Google Chrome" />
                <Text align="center" pt={4}>
                  Chrome
                </Text>
              </Box>
              <Box>
                <Image src="/firefox.png" alt="Mozilla Firefox" />
                <Text align="center" pt={4}>
                  Firefox
                </Text>
              </Box>
            </Flex>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default BrowserDetectAlertDialog;
