/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { memo } from 'react';
import { Flex, Box } from '@chakra-ui/core';
import { Participant } from 'twilio-video';
import { usePublications } from './twilio/hooks/usePublications';
import { TRACK_AUDIO, TRACK_CAMERA, TRACK_SCREEN } from './constants';
import { ReactComponent as _IconVideo } from '../../assets/img/icon-video.svg';
import { ReactComponent as _IconVideoOff } from '../../assets/img/icon-video-off.svg';
import { ReactComponent as _IconMicroPhone } from '../../assets/img/icon-microphone.svg';
import { ReactComponent as _IconMicroPhoneOff } from '../../assets/img/icon-mute.svg';

export type ParticipantTrackProps = {
  participant: Participant;
};

const IconVideo = memo(_IconVideo);
const IconVideoOff = memo(_IconVideoOff);
const IconMicroPhone = memo(_IconMicroPhone);
const IconMicroPhoneOff = memo(_IconMicroPhoneOff);

export const ParticipantTrackIndicator = ({ participant }: ParticipantTrackProps): JSX.Element => {
  const publications = usePublications({ participant });

  const hasVideo = publications.filter((p) => p.kind.includes(TRACK_SCREEN) || p.trackName.includes(TRACK_CAMERA));
  const hasAudio = publications.filter((p) => p.kind.includes(TRACK_AUDIO) && p.isTrackEnabled);

  return (
    <Flex className="drawer-track-indicator">
      <Box className="icon-box">
        {hasVideo.length === 0 ? <IconVideoOff data-testid="VideoDisabledIcon" /> : <IconVideo data-testid="VideoEnabledIcon" />}
      </Box>
      <Box className="icon-box">
        {hasAudio.length === 0 ? (
          <IconMicroPhoneOff data-testid="AudioDisabledIcon" />
        ) : (
          <IconMicroPhone data-testid="AudioEnabledIcon" />
        )}
      </Box>
    </Flex>
  );
};
