/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';
import { GoListUnordered } from 'react-icons/go';

import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import { useCurrentUserProfile } from '../../app/hooks/useCurrentUserProfile';
import { Header } from '../../app/topBar/Header';
import { ANIMATED_VARIANTS } from '../../routes/constants';
import { AppLoadingSpinner } from '../../app/components';
import useConfiguration, { IsUnderMaintenanceEnum } from '../../app/hooks/useConfiguration';
import { MaintenanceView } from '../../error/MaintenanceView';
import useAccounts from '../../app/hooks/useAccounts';
import { ThirdPartIntegrationUrl } from '../../firebase/firestore/documents/accounts';
import colors from '../../styles/colors';

const MotionBox = motion.custom(Box);

export const NewAnalyticsView = (): JSX.Element => {
  const [url, setUrl] = React.useState<string>('');
  const [index, setIndex] = useState(0);
  const [analyticData, setAnalyticData] = useState<ThirdPartIntegrationUrl[] | undefined>(undefined);
  const { i18n, t } = useTranslation();

  const { currentUserProfile } = useCurrentUserProfile();
  const accounts = useAccounts();
  const useConfig = useConfiguration();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isSuperAdmin = (): boolean => {
    if (!accounts?.superOwners || !currentUserProfile?.id) {
      return false;
    }
    return accounts?.superOwners?.includes(currentUserProfile?.id);
  };

  const isAdmin = !isNil(accounts?.owners) && !isNil(currentUserProfile) && accounts?.owners?.includes(currentUserProfile?.id);

  useEffect(() => {
    if (analyticData && analyticData.length && currentUserProfile) {
      // istanbul ignore else
      if (i18n.language === 'en') {
        const encodedURL = analyticData[index].engURL
          .replace('{{USER_ID}}', currentUserProfile.id)
          .replace('{{ACCOUNT_ID}}', currentUserProfile.account)
          .replace('{{ACCOUNT_HASH}}', accounts?.accountHash ? accounts?.accountHash : /* istanbul ignore next */ '');
        setUrl(encodedURL);
      } else {
        const encodedURL = analyticData[index].frURL
          .replace('{{USER_ID}}', currentUserProfile.id)
          .replace('{{ACCOUNT_ID}}', currentUserProfile.account)
          .replace('{{ACCOUNT_HASH}}', accounts?.accountHash ? accounts?.accountHash : '');
        setUrl(encodedURL);
      }
    }
  }, [accounts, analyticData, currentUserProfile, index, i18n.language]);

  const onCheckRole = (role?: string) => {
    if (!role) {
      return true;
    }
    if (role.toLowerCase() === 'admin') {
      return isAdmin || isSuperAdmin();
    }
    if (role.toLowerCase() === 'superadmin') {
      return isSuperAdmin();
    }
    return false;
  };

  useEffect(() => {
    if (accounts) {
      const analyticObj = location.pathname !== '/prototype' ? accounts?.analyticsPages : accounts?.prototypeFeaturesPages;

      const data: ThirdPartIntegrationUrl[] = [];
      analyticObj?.forEach((item) => {
        if (onCheckRole(item.minimumRole)) {
          data.push(item);
        }
      });
      setAnalyticData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, location.pathname]);

  const handlePageChange = (isRightClick: boolean) => {
    if (isRightClick) {
      // istanbul ignore else
      if (analyticData?.length && index < analyticData.length - 1) {
        setIndex(index + 1);
      }
    }
    // istanbul ignore next
    else if (analyticData?.length && index > 0) {
      setIndex(index - 1);
    }
  };

  return (
    <React.Fragment>
      {accounts && accounts.accountHash ? (
        <React.Fragment>
          {useConfig === IsUnderMaintenanceEnum.MAINTENANCE && <MaintenanceView />}
          {useConfig === IsUnderMaintenanceEnum.READY && (
            <MotionBox data-testid="AnalyticsView" initial="initial" animate="in" exit="out" variants={ANIMATED_VARIANTS}>
              <Header data-testid="Header" showMenu />
              {analyticData && (
                <Flex justifyContent="end" p="14px">
                  {analyticData && analyticData.length && (
                    <Button
                      leftIcon={<GoListUnordered />}
                      background={colors.white}
                      variant="outline"
                      border="1px solid #E2E8F0"
                      mr="14px"
                      onClick={onOpen}
                      data-testid="openMenuBtn"
                    >
                      {i18n.language === 'en'
                        ? analyticData[index].engName /* istanbul ignore next */
                        : analyticData[index].frName}
                    </Button>
                  )}
                  <IconButton
                    aria-label="Search database"
                    background={colors.white}
                    border="1px solid #E2E8F0"
                    mr="14px"
                    icon={<MdOutlineArrowBackIos />}
                    onClick={() => handlePageChange(false)}
                    data-testid="onChangeUrlBtn"
                    disabled={!(analyticData?.length && index > 0)}
                  />
                  <IconButton
                    aria-label="Search database"
                    background={colors.white}
                    border="1px solid #E2E8F0"
                    icon={<MdOutlineArrowForwardIos />}
                    data-testid="onChangeUrlBtnRight"
                    onClick={() => handlePageChange(true)}
                    disabled={!(analyticData?.length && index < analyticData?.length - 1)}
                  />
                </Flex>
              )}

              {url.length > 0 && (
                <iframe
                  src={url}
                  data-testid="iframe"
                  title="analytics"
                  style={{ width: '100vw', height: `calc(100vh - 68px)` }}
                />
              )}
              {!analyticData && (
                <Center h="80vh">
                  <Text fontSize="20px" color="blue">
                    {t('common:analytics.notAllowed')}
                  </Text>
                </Center>
              )}
            </MotionBox>
          )}
          {useConfig === IsUnderMaintenanceEnum.LOADING && <AppLoadingSpinner />}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header data-testid="Header" showMenu />
          <Center h="80vh">
            <Text fontSize="20px" color="blue">
              {t('common:analytics.notAllowed')}
            </Text>
          </Center>
        </React.Fragment>
      )}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t('common:analytics.listOfAccessiblePages')}</DrawerHeader>

          <DrawerBody>
            <Box border="1px solid #E5E7EB" borderRadius="2px">
              {analyticData?.map((item, i) => (
                <Text
                  key={item.engName}
                  data-testid="changeUrlBtnModal"
                  p="12px"
                  onClick={() => {
                    setIndex(i);
                    onClose();
                  }}
                  background={i % 2 === 0 ? '#fff' : '#E5E7EB'}
                  cursor="pointer"
                  _hover={{
                    background: '#eceef6',
                  }}
                >
                  {i18n.language === 'en' ? item.engName /* istanbul ignore next */ : item.frName}
                </Text>
              ))}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </React.Fragment>
  );
};
