import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { GroupEventConfiguration } from '../../firebase/firestore/documents/groupEvent';
import { Collections } from '../../firebase/firestore/collections';
import { BUNDLE_ID_SERVICES, KNOCKED_OUT_AUTOMATIC_DISMISSAL_HOURS_DELAY } from '../../firebase/firestore/constants';
import { Query } from '../../firebase/firestore/query/query';

export const useKnockedOutAutomaticDismissalHoursDelay = () => {
  const hoursToDissmisal = useQuerySnapshot<GroupEventConfiguration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_SERVICES),
    Query.field('name').equals(KNOCKED_OUT_AUTOMATIC_DISMISSAL_HOURS_DELAY),
  ]);
  if (hoursToDissmisal) {
    return hoursToDissmisal[0]?.value;
  }
  return undefined;
};

export default useKnockedOutAutomaticDismissalHoursDelay;
