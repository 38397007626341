import React, { Fragment, useState } from 'react';
import { Box, Text, VStack, Divider } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import { FreeFormQuestion, JobPosition, MultipleChoiceQuestion, Question } from '../PositionType';
import { useStoreState } from '../../../../models/hooks';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import { SortableMultipleChoiceQuestionItem } from './SortableMultipleChoiceQuestionItem';
import { SortableSpecificQuestionItem } from './SortableSpecificQuestionItem';
import { SortableFreeFormQuestionItem } from './SortableFreeFormQuestionItem';

export type SortableListType = {
  items: MultipleChoiceQuestion[];
  editMultipleChoiceQuestion: (index: number, item: MultipleChoiceQuestion) => void;
  setMultipleChoiceQuestionEditMode: (index: number | undefined) => void;
  setDeleteIndexMultipleChoice: (index: number | null) => void;
  deleteMultipleQuestionsQuestion: (index: number) => void;
  multipleChoiceQuestionEditMode: number | undefined;
  deleteIndexMultipleChoice: number | null;
};

export type SortableListSpecificQuestionType = {
  items: Question[];
  editQuestion: (index: number, item: Question) => void;
  deleteQuestion: (index: number) => void;
};

export type SortableListFreeFormQuestionType = {
  items: FreeFormQuestion[];
  editFreeFormQuestion: (index: number, item: FreeFormQuestion) => void;
  deleteFreeFormQuestion: (index: number) => void;
};

export type SortEndType = {
  newIndex: number;
  oldIndex: number;
};

const SortableList = SortableContainer(
  ({
    items,
    editMultipleChoiceQuestion,
    setMultipleChoiceQuestionEditMode,
    setDeleteIndexMultipleChoice,
    deleteMultipleQuestionsQuestion,
    multipleChoiceQuestionEditMode,
    deleteIndexMultipleChoice,
  }: SortableListType) => {
    return (
      <ul>
        {items.map((item, index) => (
          <SortableMultipleChoiceQuestionItem
            key={`item-${item.question}-multiple-choice`}
            index={index}
            itemIndex={index}
            item={item}
            editMultipleChoiceQuestion={editMultipleChoiceQuestion}
            setMultipleChoiceQuestionEditMode={setMultipleChoiceQuestionEditMode}
            setDeleteIndexMultipleChoice={setDeleteIndexMultipleChoice}
            deleteMultipleQuestionsQuestion={deleteMultipleQuestionsQuestion}
            multipleChoiceQuestionEditMode={multipleChoiceQuestionEditMode}
            deleteIndexMultipleChoice={deleteIndexMultipleChoice}
          />
        ))}
      </ul>
    );
  },
);

const SortableSpecificQuestionList = SortableContainer(
  ({ items, editQuestion, deleteQuestion }: SortableListSpecificQuestionType) => {
    return (
      <ul>
        {items.map((item, index) => (
          <SortableSpecificQuestionItem
            key={`item-${item.id}-specific`}
            index={index}
            itemIndex={index}
            que={item}
            editQuestion={editQuestion}
            deleteQuestion={deleteQuestion}
          />
        ))}
      </ul>
    );
  },
);

const SortableFreeFormQuestionList = SortableContainer(
  ({ items, editFreeFormQuestion, deleteFreeFormQuestion }: SortableListFreeFormQuestionType) => {
    return (
      <ul>
        {items.map((item, index) => (
          <SortableFreeFormQuestionItem
            key={`item-${item.id}-freeform`}
            index={index}
            itemIndex={index}
            que={item}
            editFreeFormQuestion={editFreeFormQuestion}
            deleteFreeFormQuestion={deleteFreeFormQuestion}
          />
        ))}
      </ul>
    );
  },
);

export type QuestionsListViewProps = {
  jobPositionData: JobPosition;
  editQuestion: (index: number, que: Question) => void;
  editFreeFormQuestion: (index: number, que: FreeFormQuestion) => void;
  deleteQuestion: (questionId: number) => void;
  deleteFreeFormQuestion: (questionId: number) => void;
  editMultipleChoiceQuestion: (index: number, que: MultipleChoiceQuestion) => void;
  deleteMultipleQuestionsQuestion: (questionId: number) => void;
  reOrderMultiChoiceQuestions: (reOrderedMultipleChoiceQuestions: MultipleChoiceQuestion[]) => void;
  reOrderSpecificQuestions: (reOrderSpecificQuestions: Question[]) => void;
  reOrderFreeFormQuestions: (reOrderFreeFormQuestions: FreeFormQuestion[]) => void;
};

export const QuestionsListView = ({
  jobPositionData,
  editQuestion,
  deleteQuestion,
  editFreeFormQuestion,
  deleteFreeFormQuestion,
  editMultipleChoiceQuestion,
  deleteMultipleQuestionsQuestion,
  reOrderMultiChoiceQuestions,
  reOrderSpecificQuestions,
  reOrderFreeFormQuestions,
}: QuestionsListViewProps): JSX.Element => {
  const { t } = useTranslation();
  const [deleteIndexMultipleChoice, setDeleteIndexMultipleChoice] = useState<number | null>(null);
  const [multipleChoiceQuestionEditMode, setMultipleChoiceQuestionEditMode] = useState<number | undefined>(undefined);
  const enableMultipleChoiceFeature: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE];
  });

  /* istanbul ignore next */
  const onSortEnd = ({ oldIndex, newIndex }: SortEndType) => {
    reOrderMultiChoiceQuestions(arrayMove(jobPositionData.multipleChoiceQuestions, oldIndex, newIndex));
  };
  /* istanbul ignore next */
  const onSortEndSpecificQuestion = ({ oldIndex, newIndex }: SortEndType) => {
    reOrderSpecificQuestions(arrayMove(jobPositionData.specificQuestions, oldIndex, newIndex));
  };
  /* istanbul ignore next */
  const onSortEndFreeFormQuestions = ({ oldIndex, newIndex }: SortEndType) => {
    reOrderFreeFormQuestions(arrayMove(jobPositionData.freeFormQuestions, oldIndex, newIndex));
  };

  return (
    <Fragment>
      {enableMultipleChoiceFeature && (
        <Box>
          {jobPositionData.multipleChoiceQuestions && jobPositionData.multipleChoiceQuestions.length > 0 && (
            <Fragment>
              <Divider mt="12px" mb="12px" />
              <Box>
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:multipleChoiceQuestions')}
                </Text>
              </Box>
            </Fragment>
          )}

          <SortableList
            items={jobPositionData.multipleChoiceQuestions}
            onSortEnd={onSortEnd}
            distance={20}
            editMultipleChoiceQuestion={editMultipleChoiceQuestion}
            setMultipleChoiceQuestionEditMode={setMultipleChoiceQuestionEditMode}
            setDeleteIndexMultipleChoice={setDeleteIndexMultipleChoice}
            deleteMultipleQuestionsQuestion={deleteMultipleQuestionsQuestion}
            multipleChoiceQuestionEditMode={multipleChoiceQuestionEditMode}
            deleteIndexMultipleChoice={deleteIndexMultipleChoice}
          />
        </Box>
      )}
      {jobPositionData.specificQuestions.length > 0 && (
        <Box>
          <Divider mt="12px" mb="12px" />
          <Box>
            <Text fontSize="16px" fontWeight="700">
              {t('createJobPosition:questionCardHeader')}
            </Text>
          </Box>
          <VStack spacing={4} align="stretch">
            <SortableSpecificQuestionList
              items={jobPositionData.specificQuestions}
              onSortEnd={onSortEndSpecificQuestion}
              distance={20}
              editQuestion={editQuestion}
              deleteQuestion={deleteQuestion}
            />
          </VStack>
        </Box>
      )}
      {jobPositionData.freeFormQuestions.length > 0 && (
        <Box p={6} paddingLeft={0} paddingRight={0}>
          <Divider mt="12px" mb="12px" />
          <Box>
            <Text fontSize="16px" fontWeight="700">
              {t('createJobPosition:freeFormQuestions')}
            </Text>
          </Box>
          <VStack spacing={4} align="stretch">
            <SortableFreeFormQuestionList
              items={jobPositionData.freeFormQuestions}
              onSortEnd={onSortEndFreeFormQuestions}
              distance={20}
              editFreeFormQuestion={editFreeFormQuestion}
              deleteFreeFormQuestion={deleteFreeFormQuestion}
            />
          </VStack>
        </Box>
      )}
    </Fragment>
  );
};
