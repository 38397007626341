import { Button, Box, theme, Stack, HStack, keyframes } from '@chakra-ui/core';
import React from 'react';
import { HiTrash } from 'react-icons/hi';
import { MdDragIndicator } from 'react-icons/md';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { EmptyComponent } from './EmptyComponent';
import { FieldSchema, formBuilderSchemas, pageElements } from './formBuilderSchema';
import { FormBuilderProps } from './FormBuilder';
import { v4 as uuidv4 } from 'uuid';

const animation = keyframes`
  0% {
    background-color: ${theme.colors.blue[100]};
    outline: 2px solid ${theme.colors.blue[100]};
    outline-offset: 2px;
    padding: 6px;
    z-index: 2;
  }
  60% {
    outline: 2px solid ${theme.colors.blue[50]};
    outline-offset: 7px;
  }
  100% {
    background-color: transparent;
    outline: 0px solid transparent;
    outline-offset: 10px;
    padding: 12px;
    z-index: -1;
  }
`;

interface FormBuilderElementProps extends Omit<FormBuilderProps, 'templateName' | 'setTemplateName'> {
  item: FieldSchema;
  elementIndex: number;
  sectionIndex: number;
}

const FormBuilderElement = SortableElement(function ({
  formSchema,
  setFormSchema,
  item,
  elementIndex: index,
  sectionIndex,
  language,
}: FormBuilderElementProps) {
  const path = ['questions', index];

  const [dropTarget, setDropTarget] = React.useState<{ index: number; position: 'top' | 'bottom' } | null>(null);

  const { timestamp, inputType } = item;
  const Component = formBuilderSchemas[inputType].component;

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const rect = event.currentTarget.getBoundingClientRect();
    const cursorY = event.clientY;
    const midpointY = rect.top + rect.height / 2;

    setDropTarget({
      index,
      position: cursorY < midpointY ? 'top' : 'bottom',
    });
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    const cursorX = event.clientX;
    const cursorY = event.clientY;

    const tolerance = 1;

    const isCursorInside =
      cursorX >= rect.left - tolerance &&
      cursorX <= rect.right + tolerance &&
      cursorY >= rect.top - tolerance &&
      cursorY <= rect.bottom - tolerance;

    if (!isCursorInside) {
      setDropTarget(null);
    }
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const type = event.dataTransfer.getData('type');
    if (type !== pageElements.section) {
      const { defaultValue } = formBuilderSchemas[type as keyof typeof formBuilderSchemas];
      const newFormSchema = [...formSchema];
      // const section = 'section_' + (sectionIndex + 1);
      const currentSection = formSchema[sectionIndex];
      let spliceIndex = index;

      if (index === formSchema.length - 1 && dropTarget?.position === 'bottom') {
        spliceIndex = index + 1;
      } else {
        if (dropTarget?.position === 'top') {
          spliceIndex = index;
        } else {
          spliceIndex = index;
        }
      }
      currentSection.fields.splice(spliceIndex, 0, { ...defaultValue, timestamp: Date.now(), id: uuidv4() });
      formSchema.map((section) => {
        if (section.section === currentSection.section) {
          return currentSection;
        }
        return section;
      });
      setFormSchema(newFormSchema);
    }

    setDropTarget(null);
  };

  const SortableAnchor = SortableHandle(() => (
    <Button as="div" borderRadius="50%" cursor="grab" backgroundColor="gray" style={{ aspectRatio: '1/1' }} padding={1} size="sm">
      <MdDragIndicator />
    </Button>
  ));

  return (
    <Box onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} backgroundColor={theme.colors.white}>
      <Stack spacing={4} role="group">
        {dropTarget?.position === 'top' && (
          <Box
            style={{
              borderBottomWidth: 1,
              borderColor: '#E5E7EB',
              paddingBottom: theme.space[4],
            }}
          >
            <EmptyComponent onDrop={handleDrop} />
          </Box>
        )}
        <Box>
          <HStack
            justify="space-between"
            align="flex-start"
            position="relative"
            _after={{
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '100%',
              boxSizing: 'content-box',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              animation: timestamp > Date.now() - 1000 ? `${animation} 500ms ease-out` : '',
              zIndex: -10,
              borderRadius: theme.radii.md,
            }}
          >
            <Box flexGrow={1} transition={'flex-grow 0.2s ease-in-out'}>
              <Component
                defaultValue={item}
                onChange={(value) => {
                  const newFormSchema = [...formSchema];
                  newFormSchema[sectionIndex].fields[index] = value;
                  setFormSchema(newFormSchema);
                }}
                language={language}
                followUpDragOver={() => {
                  setDropTarget(null);
                }}
                path={path}
              />
            </Box>
            <HStack flexShrink={0} marginTop="0 !important" width="0" overflow="hidden" _groupHover={{ width: 'auto' }}>
              <SortableAnchor />
              <Button
                borderRadius="50%"
                colorScheme="red"
                style={{ aspectRatio: '1/1' }}
                padding={1}
                size="sm"
                onClick={() => {
                  const newFormSchema = [...formSchema];
                  newFormSchema[sectionIndex].fields.splice(index, 1);
                  setFormSchema(newFormSchema);
                }}
              >
                <HiTrash />
              </Button>
            </HStack>
          </HStack>
        </Box>
        {dropTarget?.position === 'bottom' && (
          <Box
            style={{
              borderTopWidth: 1,
              borderColor: '#E5E7EB',
              paddingTop: theme.space[4],
            }}
          >
            <EmptyComponent onDrop={handleDrop} />
          </Box>
        )}
      </Stack>
    </Box>
  );
});

export default FormBuilderElement;

// function useHandleDrop<T>() {
//   const [dropTarget, setDropTarget] = React.useState<{ position: 'top' | 'bottom'; index: number } | null>(null);
//   const handleDrop = (
//     event: React.DragEvent<HTMLDivElement>,
//     formSchema: T[],
//     index: number,
//     setFormSchema: React.Dispatch<React.SetStateAction<FieldSchema[][]>>
//   ) => {
//     event.preventDefault();
//     event.stopPropagation();
//     const type = event.dataTransfer.getData('type');
//     if (type !== pageElements.section) {
//       const { defaultValue } = formBuilderSchemas[type as keyof typeof formBuilderSchemas];
//       const newFormSchema = [...formSchema];
//       // const section = 'section_' + (sectionIndex + 1);

//       if (index === formSchema.length - 1 && dropTarget?.position === 'bottom') {
//         newFormSchema.push({ ...defaultValue, key: Date.now() } as T);
//         setFormSchema(newFormSchema);
//       } else {
//         if (dropTarget?.position === 'top') {
//           newFormSchema[sectionIndex].splice(index, 0, { ...defaultValue, key: Date.now(), section });
//           setFormSchema(newFormSchema);
//         } else {
//           newFormSchema[sectionIndex].splice(index + 1, 0, { ...defaultValue, key: Date.now(), section });

//           setFormSchema(newFormSchema);
//         }
//       }
//     }

//     setDropTarget(null);
//   };
//   return {
//     handleDrop,
//     dropTarget,
//   };
// }
