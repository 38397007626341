import { Flex, HStack, Stack, theme, Text, Divider } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from './ImageGallery';
import ImageGalleryRefreshButton from './ImageGalleryRefreshButton';
import ImageGallerySearch from './ImageGallerySearch';
import ImageGallerySortButton from './ImageGallerySortButton';
import ImageUploadAction from './ImageUploadAction';

export default function ImageGalleryView() {
  const { t } = useTranslation();

  return (
    <Stack backgroundColor={theme.colors.white} p={4}>
      <Flex justifyContent="space-between">
        <Stack spacing={0}>
          <Text fontSize="xl" fontWeight="bold">
            {t('administration:imageGalleryModal:title')}
          </Text>
          <Text fontSize="sm" fontWeight="normal" color={theme.colors.gray[500]}>
            {t('administration:imageGalleryModal:subtitle')}
          </Text>
        </Stack>
        <HStack spacing={4}>
          <HStack spacing={2}>
            <ImageGallerySearch size="md" iconSize={20} />
            <ImageGallerySortButton size="md" iconSize={20} />
            <ImageGalleryRefreshButton size="md" iconSize={20} />
            <ImageUploadAction triggerButtonProps={{ size: 'md', iconSize: 22 }} />
          </HStack>
        </HStack>
      </Flex>
      <Divider my={4} />
      <ImageGallery />
    </Stack>
  );
}
