/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/core';
import { Room } from 'twilio-video';
import { MdInfoOutline, MdClose } from 'react-icons/md';
import InterviewCandidateInformation from './InterviewCandidateInformation';
import { GuestInterviewingRoom } from './GuestInterviewingRoom';
import { GuestInterviewWrapperStyle } from './GuestInterviewStyle';

export type GuestInterviewRoomProps = {
  candidateId: string;
  room: Room;
  quitRoom: () => void;
  isConnecting: boolean;
  isLoadingLocalTracks: boolean;
};

export const GuestInterviewRoom = ({
  candidateId,
  room,
  quitRoom,
  isConnecting,
  isLoadingLocalTracks,
}: GuestInterviewRoomProps): JSX.Element => {
  const [showCandidateInfo, setShowCandidateInfo] = useState<boolean>(false);
  const [showReviewRating, setShowReviewRating] = useState<boolean>(false);

  return (
    <Flex data-testid="GuestInterviewWrapper" css={GuestInterviewWrapperStyle}>
      <Box w="68%" className="video-container">
        {room && (
          <GuestInterviewingRoom
            room={room}
            isConnecting={isConnecting}
            isLoadingLocalTracks={isLoadingLocalTracks}
            quitRoom={() => {
              setShowReviewRating(true);
            }}
          />
        )}
        <IconButton
          className="info-btn"
          variant="outline"
          colorScheme="white"
          color={showCandidateInfo ? 'gray.500' : 'white'}
          border="0"
          aria-label="Candidate Information"
          fontSize="24px"
          data-testid="ShowCandidateInfoBtn"
          icon={showCandidateInfo ? <MdClose /> : <MdInfoOutline />}
          onClick={() => setShowCandidateInfo(!showCandidateInfo)}
        />
      </Box>
      <Box
        w="32%"
        minW="320px"
        height="calc(100vh - 65px)"
        overflowY="auto"
        className={`candidate-info-container ${showCandidateInfo ? 'active' : ''}`}
        borderLeft="1px solid #E5E7EB"
      >
        <InterviewCandidateInformation
          candidateId={candidateId}
          setShowReviewRating={setShowReviewRating}
          showReviewRating={showReviewRating}
          quitRoom={quitRoom}
          showCandidateAssessmentButton
        />
      </Box>
    </Flex>
  );
};
