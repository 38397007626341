/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
  useToast,
  useDisclosure,
} from '@chakra-ui/core';
import moment from 'moment-timezone';
import _isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { IoMdVideocam } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { drawerCSS } from '../CalendarView.Style';
import { reactDatePickerCSS } from '../scheduleSidebar/ReactDatePicker.Style';
import { calendarCreateEvent } from './CalendarCreateEvent.Style';
import { EventDetailPresenter } from './EventDetailPresenter';
import { useStoreActions } from '../../../models/hooks';
import { EventTokenResponse, GroupEventResponse } from './CalendarParticipantType';
import { MEET_PAGE } from '../../../routes/constants';
import { useMinutesBeforeGroupEventWhenUsersCanJoin } from './useMinutesBeforeGroupeventWhenUsersCanJoin';
import { useMinutesAfterGroupEventWhenUsersCanJoin } from './useMinutesAfterGroupeventWhenUsersCanJoin';
import { CalendarDeleteEvent } from './CalendarDeleteEvent';

export type CalendarEventDetailProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedEvent: EventDetailPresenter | undefined;
};

export const CalendarEventDetail = ({ isOpen, onClose, selectedEvent }: CalendarEventDetailProps): JSX.Element => {
  const { t } = useTranslation('calendar');
  const toast = useToast();
  const history = useHistory();
  const minutesBeforeEventStart = useMinutesBeforeGroupEventWhenUsersCanJoin();
  const gracePeriod = useMinutesAfterGroupEventWhenUsersCanJoin();
  const groupEventId = selectedEvent?.calendarEvent?.groupEvent.id;
  const [eventToken, setEventToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getEventToken } = useStoreActions((actions) => actions.calendar);

  const {
    isOpen: isEventDeleteAlertDialogOpen,
    onOpen: onEventDeleteAlertDialogOpen,
    onClose: onEventDeleteAlertDialogClose,
  } = useDisclosure();

  const routeToMeetPage = () => {
    history.push(MEET_PAGE(eventToken));
  };

  const isAbleToJoinMeet = useCallback(
    (startTime: Date | undefined) => {
      return moment().isBetween(
        moment(startTime).subtract(minutesBeforeEventStart, 'minutes'),
        moment(startTime).add(gracePeriod, 'minute'),
      );
    },
    [gracePeriod, minutesBeforeEventStart],
  );

  useEffect(() => {
    if (!_isNil(groupEventId)) {
      setIsLoading(true);
      getEventToken({ groupEventId })
        .then((response) => {
          const { status, message } = response as GroupEventResponse;
          const { token } = response as EventTokenResponse;
          if (status === 400) {
            toast({
              title: 'Error',
              description: message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          } else {
            setEventToken(token);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [getEventToken, groupEventId, toast]);

  return (
    <Fragment>
      <CalendarDeleteEvent
        data-testid="CalenderDeleteEvent"
        isOpen={isEventDeleteAlertDialogOpen}
        onClose={onEventDeleteAlertDialogClose}
        selectedEvent={selectedEvent}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm" data-testid="EventDetailDrawer">
        <DrawerOverlay>
          <DrawerContent css={[drawerCSS, reactDatePickerCSS, calendarCreateEvent]}>
            <DrawerCloseButton />
            <DrawerHeader borderColor="#EBEDF3" borderBottomWidth="1px">
              <Heading
                as="h4"
                size="xs"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', whiteSpace: 'pre-line' }}
              >
                <Text>{t('eventForm.groupEventDetails')}</Text>
                <Button
                  size="sm"
                  as="button"
                  leftIcon={<IoMdVideocam />}
                  onClick={routeToMeetPage}
                  colorScheme="orange"
                  variant="solid"
                  mr={8}
                  isLoading={isLoading}
                  data-testid="VideoCallButton"
                  isDisabled={!isAbleToJoinMeet(selectedEvent?.calendarEvent?.groupEvent.startTime)}
                >
                  {moment(selectedEvent?.calendarEvent?.groupEvent.startTime).fromNow()}
                </Button>
              </Heading>
            </DrawerHeader>
            <DrawerBody p="0">
              <Box className="booking-title" borderColor="#EBEDF3" borderBottomWidth="1px" p="4" bg="#EAF5FF">
                <Heading as="h4" size="xs">
                  {selectedEvent?.calendarEvent?.groupEvent.title}
                  {/* {getTranslatedValue(bookingDetail.candidate.jobTitle, i18n.language)} */}
                </Heading>
                <Text fontSize="sm">
                  {`${moment(selectedEvent?.calendarEvent?.groupEvent.startTime).format('hh:mm A')}
                 -
                 ${moment(selectedEvent?.calendarEvent?.groupEvent.endTime).format('hh:mm A')}`}
                </Text>
              </Box>
              <Box p="0px 15px">
                <Box borderColor="#EBEDF3" borderBottomWidth="1px" p="15px 0">
                  <Heading as="h4" size="xs">
                    {t('eventForm.date')}
                  </Heading>
                  <Text fontSize="sm">{moment(selectedEvent?.calendarEvent?.groupEvent.date).format('MMM DD, YYYY')}</Text>
                </Box>
                <Box borderColor="#EBEDF3" borderBottomWidth="1px" p="15px 0">
                  <Heading as="h4" size="xs">
                    {t('eventForm.recruitersLabel')}
                  </Heading>
                  <Text fontSize="sm">{selectedEvent?.recruiters.map((r) => r.label).join(', ')}</Text>
                </Box>
                <Box borderColor="#EBEDF3" borderBottomWidth="1px" p="15px 0">
                  <Heading as="h4" size="xs">
                    {t('eventForm.candidatesLabel')}
                  </Heading>
                  <Text fontSize="sm">{selectedEvent?.candidates.map((c) => c.label).join(', ')}</Text>
                </Box>
                <Box borderColor="#EBEDF3" borderBottomWidth="1px" p="15px 0">
                  <Heading as="h4" size="xs">
                    {t('eventForm.description')}
                  </Heading>
                  {selectedEvent && selectedEvent.calendarEvent && (
                    <Text
                      fontSize="sm"
                      dangerouslySetInnerHTML={{ __html: selectedEvent?.calendarEvent?.groupEvent.description }}
                    />
                  )}
                </Box>
              </Box>
            </DrawerBody>
            <Flex justify="flex-start" py={3} borderTop="2px" borderColor="#EBEDF3">
              {/* <Button data-testid="RescheduleEventBtn" colorScheme="blue" variant="solid" borderRadius={3} ml={6}>
                {t('eventForm.rescheduleEvent')}
              </Button> */}
              <Button
                variant="outline"
                ml={6}
                borderRadius={3}
                mr={3}
                onClick={onEventDeleteAlertDialogOpen}
                data-testid="CancelDrawerBtn"
              >
                {t('eventForm.cancel')}
              </Button>
            </Flex>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Fragment>
  );
};
