import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { AccountController } from '../../../controllers/accountController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { BackgroundChecks } from '../../../firebase/firestore/documents/candidate';
import { CertnFormData, SuccessMessageResponse, UploadLogoResponse } from '../certn/CertnType';
import { ModernHireConfigData, ModernHireUpdateResponseType } from '../ModernHireConfigView/ModernHireConfigData';

export type AdministrationStoreModel = {
  administration: AdministrationModel;
};

export interface AdministrationModel {
  saveCompanyLogo: Thunk<
    AdministrationModel,
    { accountId: string; companyLogo: string },
    void,
    HttpStoreModel,
    Promise<UploadLogoResponse>
  >;

  getCompanyLogo: Thunk<AdministrationModel, { accountId: string }, void, HttpStoreModel, Promise<string>>;

  saveCertnSecrets: Thunk<
    AdministrationModel,
    { accountId: string; apiKey: string; webhookSignature: string },
    void,
    HttpStoreModel,
    Promise<SuccessMessageResponse>
  >;
  getCertnSecrets: Thunk<AdministrationModel, { accountId: string }, void, HttpStoreModel, Promise<CertnFormData>>;
  administrationBackgroundCheck: Thunk<
    AdministrationModel,
    { accountId: string; backgroundCheckList: BackgroundChecks },
    void,
    HttpStoreModel,
    Promise<SuccessMessageResponse>
  >;
  getHireFormAPIKey: Thunk<AdministrationModel, { accountId: string; isRenew: boolean }, void, HttpStoreModel, Promise<string>>;
  getModernHireConfigData: Thunk<AdministrationModel, { accountId: string }, void, HttpStoreModel, Promise<ModernHireConfigData>>;
  updateModernHireConfigData: Thunk<
    AdministrationModel,
    { accountId: string; configData: ModernHireConfigData },
    void,
    HttpStoreModel,
    Promise<ModernHireUpdateResponseType>
  >;
}

export const administrationStoreModel: AdministrationModel = {
  saveCompanyLogo: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.uploadLogo(payload.accountId, payload.companyLogo);
    return response;
  }),

  getCompanyLogo: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getCompanyLogo(payload.accountId);
    return response;
  }),

  saveCertnSecrets: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.saveCertnSecrets(payload.accountId, payload.apiKey, payload.webhookSignature);
    return response;
  }),

  getCertnSecrets: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getCertnSecrets(payload.accountId);
    return response;
  }),

  administrationBackgroundCheck: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.administrationBackgroundCheck(payload.accountId, payload.backgroundCheckList);
    return response;
  }),

  getHireFormAPIKey: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getHireFormAPIKey(payload.accountId, payload.isRenew);
    return response;
  }),

  getModernHireConfigData: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getModernHireConfigData(payload.accountId);
    return response;
  }),

  updateModernHireConfigData: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updateModernHireConfigData(payload.accountId, payload.configData);
    return response;
  }),
};
