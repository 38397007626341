import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Box, Flex, List, ListItem, Text, Spinner } from '@chakra-ui/core';
import moment from 'moment-timezone';
import _orderBy from 'lodash/orderBy';
import _isNil from 'lodash/isNil';
import { css } from '@emotion/core';
import { notificationListCSS, tabButtonList } from '../Header.styles';
import useStories from './useStories';
import { News } from '../../../firebase/firestore/documents/news';
import { AvailabilityAlert } from './AvailabilityAlert';
import { useStoreState } from '../../../models/hooks';
import colors from '../../../styles/colors';

export type NotificationViewProps = {
  language: string;
  unreadNotificationNumber: (num: number) => void;
};

export const storiesCss = css`
  .note-body {
    div p:last-child {
      margin-bottom: 0;
    }

    .note-content {
      h1 {
        font-size: 1.5rem;
      }

      h2 {
        font-size: 1.2rem;
      }

      h3 {
        font-size: 1rem;
      }

      h1,
      h2,
      h3 {
        font-weight: 700;
      }

      a {
        text-decoration: underline;
      }

      p,
      ul,
      ol {
        font-size: 14px;
      }

      ul,
      ol {
        padding-left: 30px;
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StoriesView = ({ unreadNotificationNumber, language }: NotificationViewProps): JSX.Element => {
  const news = useStories();
  const currentUser = useStoreState((state) => state.app.user);

  const { t } = useTranslation('notification');

  const newsItemBlock = (newsData: News) => {
    return (
      <ListItem
        key={newsData.id}
        d="flex"
        alignItems="start"
        px={5}
        py={4}
        borderBottom="1px solid #EBEBEB"
        className={newsData.new ? '' : 'read-notification'}
      >
        <Box w="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <Box css={storiesCss}>
              <Text fontSize="sm" fontWeight="700" className="note-body">
                <div
                  className="note-content"
                  style={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{
                    __html:
                      language === 'en' ? newsData.title.en.replaceAll('\\n', '\n') : newsData.title.fr.replaceAll('\\n', '\n'),
                  }}
                />
              </Text>
            </Box>
            <Box>
              {moment().diff(newsData.createdAt, 'minutes') >= 0 ? <Badge className="badge">{t('inPastLabel')}</Badge> : ''}
            </Box>
          </Flex>
          <Text fontSize="xs" color="#A0AEC0">
            {moment(newsData.createdAt).format('lll')}
          </Text>
          <Box css={storiesCss}>
            <Text fontSize="sm" className="note-body">
              <div
                className="note-content"
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{
                  __html: language === 'en' ? newsData.text.en.replaceAll('\\n', '\n') : newsData.text.fr.replaceAll('\\n', '\n'),
                }}
              />
            </Text>
          </Box>
        </Box>
      </ListItem>
    );
  };

  return (
    <Box css={tabButtonList} data-testid="StoriesListContainer">
      <Box>
        {currentUser?.employerData?.[currentUser.account]?.availabilityWaitingCandidates !== undefined && <AvailabilityAlert />}

        <List css={notificationListCSS}>
          {_isNil(news) ? (
            <Box d="flex" p={8} justifyContent="center">
              <Spinner size="md" color={colors.blue.default} data-testid="StoriesSpinner" />
            </Box>
          ) : (
            <Box>
              {' '}
              {news.length > 0 &&
                _orderBy(news, 'createAt', ['desc']).map((newsItem) => {
                  return newsItemBlock(newsItem);
                })}
              {news.length <= 0 && (
                <ListItem key="1" borderBottom="1px solid #EBEBEB" px={5} py={4}>
                  <Box>
                    <Text fontSize="sm" fontWeight="700" data-testid="NoStoriesText">
                      {t('noNewsDescription')}
                    </Text>
                  </Box>
                </ListItem>
              )}
            </Box>
          )}
        </List>
      </Box>
    </Box>
  );
};
