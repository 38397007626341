import { useEffect, useState } from 'react';
import { useMeetContext } from '../../MeetContext';

export type RoomStateType = 'disconnected' | 'connected' | 'reconnecting';

export const useRoomState = (): RoomStateType => {
  const { room } = useMeetContext();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [roomState, _setRoomState] = useState<RoomStateType>('disconnected');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      const setRoomState = () => _setRoomState(room.state as RoomStateType);
      setRoomState();

      room.on('disconnected', setRoomState);
      room.on('reconnected', setRoomState);
      room.on('reconnecting', setRoomState);

      return () => {
        room.off('disconnected', setRoomState);
        room.off('reconnected', setRoomState);
        room.off('reconnecting', setRoomState);
      };
    }
  }, [room]);

  return roomState;
};
