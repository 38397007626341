export default {
  pageTitle: 'Currently Processing Offers & Hires',
  toDoBreadCrumb: 'Back to To Do List',
  offerAndHireStatus: 'Offer/Hire Status:',
  noDataFound: 'No Data Found',
  km: 'KM',

  filterOption: {
    all: 'All',
    hiresProcessing: 'Processing Hires',
    hired: 'Hired',
    offersProcessing: 'Processing Offers',
    offersRejected: 'Rejected Offers',
    offersRevoked: 'Revoked Offers',
  },
  offerAndHireProcessTableDetails: {
    header: {
      locationAndPosition: 'Location & Position',
      candidate: 'Candidate',
      requisitionId: 'Requisition ID',
      commuteData: 'Commute Time',
      currentStatus: 'Current Status',
    },
  },
};
