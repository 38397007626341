/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _has from 'lodash/has';
import { useToast } from '@chakra-ui/core';
import { useParams } from 'react-router';
import { GreenRoom } from './GreenRoom';
import { InterviewRoom } from './InterviewRoom';
import { useMeetContext } from './MeetContext';
import { Header } from '../../app/topBar/Header';
import { useRoomState } from './twilio/hooks/useRoomState';
import { ActiveParticipantContextProvider } from './ActiveParticipantContext';
import { InvalidMeetTokenResponse, ValidMeetTokenResponse } from './MeetTokenResponse';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { GroupEventMeetParams, GuestMeetParams, MeetTypeEnum } from '../../routes/constants';
import { GuestInterviewRoom } from '../guestMeet/GuestInterviewRoom';
import { RemoteConfigKey } from '../../firebase/remoteConfig';

export const MeetRoom = (): JSX.Element => {
  const {
    room,
    joinRoom,
    quitRoom,
    joinGroupEventRoom,
    quitGroupEventRoom,
    isConnecting,
    isLoadingLocalTracks,
    isSharingScreen,
    toggleScreenSharing,
    removeLocalVideoTrack,
    removeLocalAudioTrack,
  } = useMeetContext();
  const { token } = useParams<GroupEventMeetParams>();
  const { candidateId } = useParams<GuestMeetParams>();

  const roomState = useRoomState();

  const [groupEventInfo, setGroupEventInfo] = useState<ValidMeetTokenResponse | null>(null);
  // istanbul ignore next
  const { verifyMeetToken } = useStoreActions((actions) => actions.meet);
  // istanbul ignore next
  const isCollaboratorFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_COLLABORATOR_FEATURE];
  });
  // istanbul ignore next

  const isRegisterFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_REGISTER_FEATURE];
  });

  const toast = useToast();

  const isLoading: boolean = isLoadingLocalTracks || isConnecting;

  const verifyToken = useCallback(async () => {
    if (_isNil(token) || _isEmpty(token)) return;
    const tokenResponse = await verifyMeetToken({ meetToken: token });
    if (_isObject(tokenResponse) && _has(tokenResponse, 'verified')) {
      const { verified } = tokenResponse as ValidMeetTokenResponse;
      if (verified) {
        setGroupEventInfo(tokenResponse as ValidMeetTokenResponse);
      } else {
        toast({
          description: 'Not Verified',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      const { message } = tokenResponse as InvalidMeetTokenResponse;
      toast({
        description: `${message}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      // window.location.replace('https://www.appyhere.com/en-us/');
    }
  }, [toast, token, verifyMeetToken]);

  useEffect(() => {
    verifyToken().catch((err) => {
      console.log('ERROR', err);
    });
  }, [verifyToken]);

  useEffect(() => {
    return () => {
      removeLocalVideoTrack();
    };
  }, [removeLocalVideoTrack]);

  useEffect(() => {
    return () => {
      removeLocalAudioTrack();
    };
  }, [removeLocalAudioTrack]);

  useEffect(() => {
    return () => {
      if (isSharingScreen) {
        toggleScreenSharing();
      }
    };
  }, [isSharingScreen, toggleScreenSharing]);

  return (
    <Fragment>
      <Header data-testid="Header" showMenu={(!room || roomState === 'disconnected') && _isEmpty(candidateId)} />
      <ActiveParticipantContextProvider>
        {(!room || roomState === 'disconnected') && (
          <GreenRoom
            isLoading={isLoading}
            joinGroupEventRoom={joinGroupEventRoom}
            groupEventInfo={groupEventInfo}
            joinRoom={joinRoom}
            meetType={token ? MeetTypeEnum.EVENT : MeetTypeEnum.INTERVIEW}
            isCollaboratorFeatureEnabled={isCollaboratorFeatureEnabled && /* istanbul ignore next */ isRegisterFeatureEnabled}
          />
        )}
        {room && (roomState === 'connected' || roomState === 'reconnecting') && (
          <Fragment>
            {token && (
              <InterviewRoom
                room={room}
                isConnecting={isConnecting}
                isLoadingLocalTracks={isLoadingLocalTracks}
                isSharingScreen={isSharingScreen}
                toggleScreenSharing={toggleScreenSharing}
                quitGroupEventRoom={quitGroupEventRoom}
                groupEventInfo={groupEventInfo}
              />
            )}
            {candidateId && (
              <GuestInterviewRoom
                candidateId={candidateId}
                room={room}
                quitRoom={quitRoom}
                isConnecting={isConnecting}
                isLoadingLocalTracks={isLoadingLocalTracks}
              />
            )}
          </Fragment>
        )}
      </ActiveParticipantContextProvider>
    </Fragment>
  );
};
