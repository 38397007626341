import firebase from 'firebase/app';
import 'firebase/firestore';

import { snapshotsManager } from './snapshots';
import { formatDocument } from './documentConverter';
import { reportFirestoreOperationError, reportSnapshotError } from './error';
import { Collections, SubCollections } from './collections';

export const onSnapshotById = <T>(collectionName: Collections, documentId: string, onSuccess: (document: T) => void): string =>
  snapshotsManager.registerSnapshot(
    firebase
      .firestore()
      .collection(collectionName)
      .doc(documentId)
      .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
        onSuccess(formatDocument<T>(snapshot));
      }, reportSnapshotError('onSnapshotById', collectionName, { [collectionName]: documentId })),
  );

export const setDocumentById = async <T>(
  collectionName: Collections,
  documentId: string | undefined,
  documentToSave: Partial<T>,
  merge = false,
): Promise<string | void> => {
  try {
    const updatedDocumentId = documentId ?? firebase.firestore().collection(collectionName).doc().id;
    await firebase.firestore().collection(collectionName).doc(updatedDocumentId).set(documentToSave, { merge });
  } catch (error) {
    reportFirestoreOperationError('setDocumentById', collectionName, {
      [collectionName]: documentId,
      documentToSave,
      merge,
    })(error);
  }
};

export const deleteDocumentById = async <T>(collectionName: Collections, documentId: string): Promise<string | void> => {
  try {
    await firebase.firestore().collection(collectionName).doc(documentId).delete();
  } catch (error) {
    reportFirestoreOperationError('deleteDocumentById', collectionName, {
      [collectionName]: documentId,
    })(error);
  }
};

export const setSubDocumentById = async <T>(
  collectionName: Collections,
  subCollectionName: SubCollections,
  documentToSave: Partial<T>,
  documentId?: string,
  subDocumentId?: string,
  merge = false,
): Promise<string | void> => {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentId)
      .collection(subCollectionName)
      .doc(subDocumentId)
      .set(documentToSave, { merge });
  } catch (error) {
    reportFirestoreOperationError(
      'setSubDocumentById',
      collectionName,
      {
        [collectionName]: documentId,
        [subCollectionName]: subDocumentId,
        documentToSave,
        merge,
      },
      subCollectionName,
    )(error);
  }
};

export const deleteSubDocumentById = async <T>(
  collectionName: Collections,
  subCollectionName: SubCollections,
  documentId?: string,
  subDocumentId?: string,
): Promise<string | void> => {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentId)
      .collection(subCollectionName)
      .doc(subDocumentId)
      .delete();
  } catch (error) {
    reportFirestoreOperationError(
      'deleteSubDocumentById',
      collectionName,
      {
        [collectionName]: documentId,
        [subCollectionName]: subDocumentId,
      },
      subCollectionName,
    )(error);
  }
};
