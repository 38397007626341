import { css } from '@emotion/core';

export const recruiterProfileWrapper = css`
  background: white;
  padding: 1rem;

  .wrapperStyles {
    border-radius: 3px;
    border: 1px solid #eceff1;
  }
`;
