export default {
  bundleName: 'AppyHere Recruteur',
  loading: 'AppyHere…',
  firstName: 'AppyHere',
  lastName: 'Recruteur',
  deviceDetect: {
    header: 'Nous travaillons toujours sur AppyHere pour les navigateurs mobiles!',
    message: "Nous recommandons d'utiliser un ordinateur pour un expérience optimale.",
  },
};
