/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LocalVideoTrack } from 'twilio-video';
import { Box } from '@chakra-ui/core';
import { css } from '@emotion/core';
import { VideoTrack } from './twilio/VideoTrack';
import { useMeetContext } from './MeetContext';
import { LocalAudioPreview } from './LocalAudioPreview';

const localVideoStyle = css`
  height: 100%;
  width: 100%;
  background: #202124;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const localAudioStyle = css`
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export const LocalVideoPreview = (): JSX.Element => {
  const { localTracks } = useMeetContext();

  const videoTrack = localTracks.find((track) => {
    return track.name.includes('camera');
  }) as LocalVideoTrack;

  return (
    <Box css={localVideoStyle}>
      {videoTrack && <VideoTrack track={videoTrack} priority="low" isLocal />}
      <Box css={localAudioStyle}>
        <LocalAudioPreview />
      </Box>
    </Box>
  );
};
