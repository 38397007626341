export default {
  ANALYSING: 'Analysing',
  COMPLETE: 'Completed',
  DISPUTE: 'Dispute',
  IN_PROGRESS: 'In Progress',
  PARTIAL: 'Partial',
  SENT: 'Sent',
  status: {
    analysing: 'Analysing',
    complete: 'Complete',
    dispute: 'Dispute',
    in_progress: 'In Progress',
    partial: 'Partial',
    sent: 'Sent',
  },
  bgCheckLabel: 'Background Check',
  bgCheckStatusLabel: 'Background Check Status',
  candidateBgCheckStatusLabel: 'Candidate Background Check Status',
  bgCheckStatusText: 'You can see the list of candidate background check status.',
  searchInputPlaceholder: 'Candidate Name',
  statusFilterPlaceholder: 'Select Status',
  contactAppyLabel: 'Contact your AppyHere administrator for detailed information.',
  submitBtn: 'Submit',
  viewBtn: 'View',
  closeBtn: 'Close',
  completedDate: 'Completed Date',
  statusLabel: 'Status',
  subscribeCertnLabel: 'Subscribe to add this features !',
  subscribeText: `You need to contact <span style=‘color: #049BE5’>AppyHere</span> for information on how to add this feature to their subscription.`,
  jobListingUrlLabel: `Job Listing URL`,
  uploadCompanyLogoLabel: 'Upload Company Logo',
  certnIntegationLabel: 'Certn Integration',
  checks: 'Checks',
  keysUrlLabel: 'Keys and URL',
  certnSetupLabel: 'Information on setup for certn integration',
  selectRequiredLabel: 'Select the required checks as default',
  linkCertnLabel: 'Link Certn Account',
  apiKeyLabel: 'API Key',
  webhookUrlLabel: 'Webhook URL',
  webhookSecretLabel: 'Webhook Secret',
  checksValidation: 'At least one check is required.',
  searchValidation: 'No candidates found.',
  completedDateLabel: 'Completed Date',
  lastSubmitDateLabel: 'Last Submitted Date',
  screeningError: 'Screening report error',
  incompleteScreeningError: 'Candidate has not been screened or the screening is yet to be completed',
  setupStep: 'Steps in setting up the Certn Integration',
  webHookTitle: 'For setting up the webhook secret.',
  stepLoginCertn:
    'Login to your Certn account then navigate to Settings, which you will find under your profile > Team Settings > General',
  stepGenNewHook: 'Click ‘Generate New’ and copy the generated webhook secret.',
  stepLoginRecruiter:
    'Login to your AppyHere recruiter account on the web, navigate to Administration, which you will find under your profile picture > Certn Integration.',
  stepPasteWebSecret: 'Paste the webhook secret you copied in the first step into the Webook Secret field.',
  apiKeyTitle: 'For setting up the API key.',
  backToCertnApi: 'Go back to your Certn account and select API Keys in the menu.',
  stepCopyAPI:
    "Click 'Add API Key' and copy the key that is generated, this is a unique key for the AppyHere integration and cannot be read after you leave this screen.",
  stepPasteAPI: 'Paste the text from the above step into the API Key field.',
  stepSave: 'Click Submit',
  webURLTitle: 'For setting up the webhook url.',
  stepLoginCertnURl: 'Login to your web recruiter account and Navigate to Certn Integration.',
  stepCopyUrl: 'Copy Webhook URL.',
  stepPasteUrl: 'Paste the copied webhook url in the Webhook URL field.',
  linkToCertn: 'If you do not have created certn account yet, sign up using the link below',
  finalNote: 'Steps number should always start back at #1 when starting a new "step"',
  apiKeyRequired: 'API Key is required',
  webhookSecretRequired: 'Webhook Secret is required',
  apiKeyPlaceholder: 'Enter API Key',
  jobListingCardHeader: 'Web apply job listing url',
  jobListingCardHint: 'You can copy url from below',
  positionManagementLabel: 'Position Management',
  save: 'Save',
  less: 'Less',
  more: 'More',
  tooltipMessageForCanadianBaseRecordCheck:
    'Selecting a Canadian Criminal Record check requires OneId to be selected. Only one of the Canadian Criminal Record checks can be selected at one time.',
  tooltipMessageForUSBasedRecordCheck: 'Only one of the US Criminal Record checks can be selected at one time.',
  // new missed translations
  soquijEnableLabel: '<0>SOQUIJ</0>',
  soquijTooltipMessage: `Certn provides easy access to published civil, criminal and
  penal judicial records from the Quebec courthouses and municipal courts.`,
  soquijHint1: `<0>SOQUIJ</0> records are only available in French, and can only be ordered in <1>Canada</1>.`,
  soquijSupportLabel: `Contact <1>Certn's support team</1> to enable it on your account.They can be reached at <3>apisupport@certn.co</3>.`,
  messageNotEnabledOnPositionLevel: 'Background checks are not enabled for this position.',
};
