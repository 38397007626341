import { css } from '@emotion/core';

export const tableStyle = css`
  table {
    border-collapse: collapse;
    border: 1px solid #edf2f7;
    width: 100%;
  }
  tr {
    border-top: 1px solid #edf2f7;
  }

  th,
  td {
    border: none;
    text-align: left;
    padding: 8px;
  }

  /* Set alternate row background colors */
  tr:nth-child(even) {
    background-color: #edf2f7;
  }
`;
