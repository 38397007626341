import { AppointmentType, Position } from '../../../../../firebase/firestore/documents/position';
import * as PositionService from '../../../../../services/positionService';

export class InterviewConfigPresenter {
  constructor(private readonly position: Position) {}

  getDuration(): number {
    return PositionService.getInterviewDuration(this.position);
  }

  getType(): AppointmentType {
    return PositionService.getInterviewType(this.position);
  }
}
