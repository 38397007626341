import { AxiosResponse } from 'axios';
import moment from 'moment';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import {
  GetRecruitersProfileDataQueryParams,
  PieAndStatInfoParams,
  RecruiterListQueryParams,
} from '../modules/administration/recruiterManagement/RecruiterType';

export class RecruiterManagementController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getRecruiterList(params: RecruiterListQueryParams): Promise<AxiosResponse> {
    const path = `/api/v1/account/${params.accountId}/dashboard/section2?fromDate=${moment(params.startDate).format(
      'YYYY/MM/DD',
    )}&toDate=${moment(params.endDate).format('YYYY/MM/DD')}`;
    return this.httpClient.get(path);
  }

  async getRecruitersProfileData(params: GetRecruitersProfileDataQueryParams): Promise<AxiosResponse> {
    const path = `/api/v1/account/${params.accountId}/user/${params.recruiterId}/dashboard?fromDate=${moment(
      params.startDate,
    ).format('YYYY/MM/DD')}&toDate=${moment(params.endDate).format('YYYY/MM/DD')}`;
    return this.httpClient.get(path);
  }

  async getPieAndStatInfo(params: PieAndStatInfoParams): Promise<AxiosResponse> {
    const path = `/api/v1/account/${params.accountId}/dashboard/section1?fromDate=${moment(params.startDate).format(
      'YYYY/MM/DD',
    )}&toDate=${moment(params.endDate).format('YYYY/MM/DD')}`;
    return this.httpClient.get(path);
  }
}
