import React, { useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import _isNil from 'lodash/isNil';
import { PositionList } from './positionList/PositionList';
import { BusinessListView } from './businessList/BusinessListView';
import { Business } from './BusinessType';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { PositionManagementContextProvider, usePositionManagementContext } from './PositionManagementContext';

export type ChangeActiveHandler = {
  resetActiveTab: () => void;
};

const PositionManagementComponent = (): JSX.Element => {
  const [activeBusiness, setActiveBusiness] = useState<Business | null>(null);
  const [scrollToBusiness, setScrollToBusiness] = useState<Business | null>(null);
  const appUserId = useStoreState((s) => s.app.user?.id);
  const appOwnersId = useStoreState((s) => s.app.accounts?.owners);
  const currentActiveBusinessFromStore = useStoreState((s) => s.positionManagement.currentSelectedActiveBusiness);
  const setCurrentSelectedActiveBusiness = useStoreActions(
    (actions) => actions.positionManagement.setCurrentSelectedActiveBusiness,
  );
  const { t } = useTranslation();
  const isAdministrationAccount = !_isNil(appOwnersId) && !_isNil(appUserId) && appOwnersId?.includes(appUserId);
  const { positionQueryParams, setPositionQueryParams } = usePositionManagementContext();
  const changeActiveBusiness = (business: Business | null) => {
    const newpositionQueryParams = positionQueryParams;
    newpositionQueryParams.positionStatus = 'open';
    newpositionQueryParams.archive = false;
    setPositionQueryParams({ ...newpositionQueryParams });
    setActiveBusiness(business);
    setCurrentSelectedActiveBusiness(business);
  };

  React.useEffect(() => {
    setActiveBusiness(currentActiveBusinessFromStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <PositionManagementContextProvider>
        <Box>
          <Tabs colorScheme="blue" index={0} data-testid="ChangeTabIndexBtn" isLazy>
            <TabList borderWidth={0} px={2} mb={6} borderRadius={5} backgroundColor="white">
              <Tab
                py={3}
                fontSize="sm"
                _selected={{ fontWeight: '700', borderColor: 'blue.500', color: 'blue.500' }}
                _focus={{ boxShadow: 'none' }}
                onClick={() => {
                  setActiveBusiness(null);
                }}
              >
                {t('createJobPosition:businessManagementTab.businessAndPosition')}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <BusinessListView
                  changeActiveBusiness={changeActiveBusiness}
                  isAdministrationAccount={isAdministrationAccount}
                  activeBusiness={activeBusiness}
                  scrollToBusiness={scrollToBusiness}
                  setScrollToBusiness={setScrollToBusiness}
                />
                {activeBusiness && (
                  <PositionList
                    activeBusiness={activeBusiness}
                    isAdministrationAccount={isAdministrationAccount}
                    changeActiveBusiness={changeActiveBusiness}
                    setScrollToBusiness={setScrollToBusiness}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </PositionManagementContextProvider>
    </React.Fragment>
  );
};

export const PositionManagementView = React.forwardRef<ChangeActiveHandler, unknown>(PositionManagementComponent);
