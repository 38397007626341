import { AxiosResponse } from 'axios';
import _omit from 'lodash/omit';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { Business, BusinessListQueryParams } from '../modules/administration/positionManagement/BusinessType';

export class BusinessManagementController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async createBusiness(accountId: string, businessData: Business): Promise<AxiosResponse> {
    const path = `/api/v1/accounts/${accountId}/business`;
    return this.httpClient.post(path, _omit(businessData, ['id']));
  }

  async editBusiness(accountId: string, businessData: Business, businessId: string): Promise<AxiosResponse> {
    const path = `/api/v1/accounts/${accountId}/business/${businessId}`;
    return this.httpClient.put(path, _omit(businessData, ['id']));
  }

  async getBusinessList(params: BusinessListQueryParams): Promise<AxiosResponse> {
    const path = `api/v2/businesses?sortBy=${params.sortBy}&sortOrder=${params.sortOrder}&page=${params.page}&limit=${params.limit}&onlyFollowedBusiness=${params.onlyFollowedBusiness}&searchQuery=${params.searchQuery}`;
    return this.httpClient.get(path);
  }
}
