/* istanbul ignore file */
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import moment from 'moment-timezone';
// import _sortBy from 'lodash/sortBy';
import i18n from 'i18next';
import { StarIcon } from '@chakra-ui/icons';
import _sortBy from 'lodash/sortBy';
import { loadInterviewIcon } from '../../../sidebar/meetInterview/InterviewRating';
import colors from '../../../../../styles/colors';
import useLookupsByType from '../../../../../app/hooks/useLookupsByType';
import { getTranslatedValue } from '../../../../../utils/localizedString';
import { useSeekerContainerContext } from '../../SeekerContainerContext';

export const CollaboratorInterviewRatingView = (): JSX.Element => {
  const lookups = useLookupsByType('negativeAssessmentReasons');
  const { selectedCandidate } = useSeekerContainerContext();

  const getReview = (review?: string) => {
    const currentLookUp = lookups.filter((e) => e.id === review);
    if (currentLookUp && currentLookUp.length > 0) {
      return getTranslatedValue(currentLookUp[0].label, i18n.language);
    }
    return '';
  };

  return (
    <Box borderBottom="1px solid #ECEFF1">
      {_sortBy(selectedCandidate?.anonymousRecruiterInterviewReview, 'date')
        .reverse()
        ?.map((e, index) => (
          <Box background={index % 2 === 0 ? '#ffffff' : '#F5F8FA'} p={6} borderTop="1px solid #ECEFF1" key={`_${e?.positionId}`}>
            <Flex>
              <Box
                border="1px solid #CFD8DC"
                background="#ECEFF1"
                display="inline-block"
                padding=" 7px"
                borderRadius="5px"
                height="46px"
              >
                {loadInterviewIcon(/* istanbul ignore next */ e.interviewType ?? '')}
              </Box>
              <Box ml="12px" w="100%">
                <Flex justifyContent="space-between">
                  <Box>
                    <Box>
                      {e.rating &&
                        Array(e.rating)
                          .fill(null)
                          .map((i: number) => (
                            <StarIcon
                              key={i}
                              mb="0.1rem"
                              boxSize={3}
                              color="#FF6600"
                              style={{ marginRight: '6px', cursor: 'pointer', stroke: '#FF6600' }}
                            />
                          ))}
                    </Box>
                    <Text fontSize="sm" style={{ width: '190px', color: '#000' }} fontWeight="bold">
                      {e.collaboratorName}
                    </Text>
                  </Box>

                  <Flex direction="column" minW="90px" pt={1} lineHeight={1.4}>
                    <Flex className="history-info-date" justify="flex-end">
                      <Text fontSize="sm">{moment(e.date).locale(i18n.language).format('ll')}</Text>
                    </Flex>
                    <Flex fontSize="sm" className="history-info-time" justify="flex-end">
                      <Text>{moment(e.date).locale(i18n.language).format('LT')}</Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Text mt="6px" fontWeight="bold" color={colors.orange[500]}>
                  {getReview(e.assessmentReason)}
                </Text>
                <Text mt="6px">{e.review}</Text>
              </Box>
            </Flex>
          </Box>
        ))}
    </Box>
  );
};
