import { LocalParticipant, Participant } from 'twilio-video';
import _isEmpty from 'lodash/isEmpty';
import { useActiveParticipant } from '../../ActiveParticipantContext';
import { useMeetContext } from '../../MeetContext';
import { useDominantSpeaker } from './useDominantSpeaker';
import { useParticipants } from './useParticipants';
import { useScreenShareParticipant } from './useScreenShareParticipant';

export const useMainParticipant = (): Participant | LocalParticipant | undefined => {
  const { room } = useMeetContext();
  const { activeParticipant } = useActiveParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();

  const localParticipant = room?.localParticipant;

  const remoteScreenShareParticipant = screenShareParticipant !== localParticipant ? screenShareParticipant : null;

  return (
    activeParticipant ||
    remoteScreenShareParticipant ||
    dominantSpeaker ||
    (!_isEmpty(participants) ? participants[0] : null) ||
    localParticipant
  );
};
