import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { Candidate } from '../../firebase/firestore/documents/candidate';
import { Collections } from '../../firebase/firestore/collections';
import { useDocumentSnapshot } from '../../firebase/firestore/hooks';
import useAppointmentAvailability from './useAppointmentAvailability';
import { Booking } from '../../firebase/firestore/documents/appointmentAvaiability';

export type CandidateBookingType = {
  booking: Booking;
  candidate: Candidate;
};

const useCandidateBooking = (candidateId: string): CandidateBookingType | undefined => {
  const candidateData = useDocumentSnapshot<Candidate>(Collections.CANDIDATES, candidateId);
  const bookingData = useAppointmentAvailability(candidateData?.appointment?.appointmentAvailabilityId);

  if (_isNil(candidateData) || _isNil(bookingData)) return undefined;

  const filteredBooking = _map(bookingData.bookings, (b: Booking, key) => {
    return { ...b, id: key, availabilityId: candidateData?.appointment?.appointmentAvailabilityId } as Booking;
  });
  const matchedBooking = _find(filteredBooking, (booking: Booking) => booking?.id === candidateData?.appointment?.bookingId);

  return {
    booking: matchedBooking,
    candidate: candidateData,
  } as CandidateBookingType;
};

export default useCandidateBooking;
