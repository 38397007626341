/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo, useEffect, useState } from 'react';
import _isNil from 'lodash/isNil';
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/core';
import { EventDetailPresenter } from './EventDetailPresenter';
import { useStoreActions } from '../../../models/hooks';

export type CalendarDeleteEventProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedEvent: EventDetailPresenter | undefined;
};

export const CalendarDeleteEvent = ({ isOpen, onClose, selectedEvent }: CalendarDeleteEventProps): JSX.Element => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { deleteEvent } = useStoreActions((actions) => actions.calendar);

  const deleteGroupEvent = async () => {
    if (selectedEvent && selectedEvent.calendarEvent) {
      const { groupEvent } = selectedEvent.calendarEvent;
      if (!_isNil(groupEvent.id)) {
        try {
          setIsLoading(true);
          await deleteEvent({ groupEventId: groupEvent.id });
          onClose();
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {`Delete ${selectedEvent?.calendarEvent?.groupEvent.title}`}
          </AlertDialogHeader>

          <AlertDialogBody>Are you sure? You cannot undo this action afterwards.</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button data-testid="DeleteEventBtn" isLoading={isLoading} colorScheme="red" onClick={deleteGroupEvent} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
