import { LocalAudioTrack } from 'twilio-video';
import { useCallback, useState } from 'react';
import { useTrackEnabled } from './useTrackEnabled';
import { useMeetContext } from '../../MeetContext';

export type LocalAudioTrackInfo = {
  isAudioEnabled: boolean;
  toggleAudio: () => void;
};

export const useLocalAudioToggle = (): LocalAudioTrackInfo => {
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const { room, localTracks, getLocalAudioTrack, removeLocalAudioTrack } = useMeetContext();
  const localParticipant = room?.localParticipant;
  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;

  const isEnabled = useTrackEnabled(audioTrack);

  // eslint-disable-next-line @typescript-eslint/require-await
  const toggleAudio = useCallback(async () => {
    if (isPublishing) return;

    if (audioTrack) {
      const localTrackPublication = localParticipant?.unpublishTrack(audioTrack);
      // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
      localParticipant?.emit('trackUnpublished', localTrackPublication);
      removeLocalAudioTrack();
    } else {
      setIsPublishing(true);

      getLocalAudioTrack()
        .then((track: LocalAudioTrack) => {
          return localParticipant?.publishTrack(track, { priority: 'low' });
        })
        .finally(() => {
          setIsPublishing(false);
        });
    }
  }, [getLocalAudioTrack, isPublishing, localParticipant, removeLocalAudioTrack, audioTrack]);

  return { isAudioEnabled: isEnabled, toggleAudio };
};
