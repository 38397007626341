import React from 'react';
import {
  Modal,
  Button,
  useDisclosure,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as LowAvailabilitySvg } from '../../assets/img/low_availability.svg';
import { ReactComponent as NoAvailabilitySvg } from '../../assets/img/no_availability.svg';
import colors from '../../styles/colors';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { AdditionalContextDatType } from '../../models/notificationsModel';

export enum AvailabilityNotificationType {
  LOW_AVAILABILITY = 'lowAvailability',
  NO_AVAILABILITY = 'noAvailability',
}

export const AvailabilityReminderModal = ({ type }: { type: AvailabilityNotificationType }): JSX.Element => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { t } = useTranslation('notification');
  const history = useHistory();
  const isLowAvailability = type === AvailabilityNotificationType.LOW_AVAILABILITY;
  const clearLowAvailabilityNotification = useStoreActions((state) => state.notifications.clearLowAvailabilityNotification);
  const clearNoAvailabilityNotification = useStoreActions((state) => state.notifications.clearNoAvailabilityNotification);
  const lowAvailabilityData = useStoreState((state) => state.notifications.lowAvailabilityReminder);

  const clearAndClose = () => {
    if (isLowAvailability) clearLowAvailabilityNotification();
    else clearNoAvailabilityNotification();
    onClose();
  };

  const routeToCreate = () => {
    if (history.location.pathname === '/calendar') {
      window.location.reload();
      window.history.replaceState({ state: true }, document.title);
    } else {
      history.push({
        pathname: '/calendar',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state: true,
      });
    }
    clearAndClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={clearAndClose} isCentered>
      <ModalOverlay />
      <ModalContent px={5} py={3} data-testid={isLowAvailability ? 'lowAvailabilityModal' : 'noAvailabilityModal'}>
        <ModalHeader textAlign="center" alignSelf="center">
          {isLowAvailability ? <LowAvailabilitySvg /> : <NoAvailabilitySvg />}
          <Text mt={6} color={colors.black} fontSize="xl" fontWeight={500}>
            {t(`availability.${type}.title`)}
          </Text>
        </ModalHeader>
        <ModalBody textAlign="center">
          <Text fontSize="sm" color={colors.gray[700]}>
            {t(
              `availability.${type}.description`,
              isLowAvailability
                ? {
                    candidates: lowAvailabilityData?.data?.additionalContextData
                      ? (JSON.parse(lowAvailabilityData.data.additionalContextData) as AdditionalContextDatType)
                          .noOfCandidatesWaitingForAvailability
                      : '',
                  }
                : {},
            )}
          </Text>
        </ModalBody>

        <ModalFooter mt={2}>
          <Button data-testid="availabilityModalConfirmBtn" colorScheme="blue" mr={3} onClick={routeToCreate}>
            {t(`common:button.add`)}
          </Button>
          <Button data-testid="availabilityModalCloseBtn" colorScheme="red" onClick={clearAndClose}>
            {t('common:button.later')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
