import moment from 'moment-timezone';
import { Note } from './note';
import { CandidateStatus } from '../../../../../firebase/firestore/documents/candidate';
import { SeekerNoteV2Item } from '../../../../../firebase/firestore/documents/candidate';

export class PositionNote {
  public positionId: string;

  public positionName: string;

  public location: string;

  public status?: CandidateStatus;

  public rating?: string;

  public notes: Note[];

  constructor(
    notes: Note[],
    positionId: string,
    positionName: string,
    businessName: string,
    status?: CandidateStatus,
    rating?: string,
  ) {
    this.positionId = positionId;
    this.positionName = positionName;
    this.location = businessName;
    this.notes = notes;
    this.status = status;
    this.rating = rating;
  }

  getNoteById(id?: string): Note | undefined {
    if (!id) return undefined;

    const filteredNotes = this.notes.filter((n) => n.id === id);
    if (filteredNotes && filteredNotes.length > 0) return filteredNotes[0];

    return undefined;
  }

  getCandidateStatus(): string {
    if (!this.status) return CandidateStatus.Unknown.toString();
    return this.status.toString();
  }

  getCandidateRating(): string | boolean {
    if (!this.rating || this.rating === '0') return false;
    return this.rating;
  }

  getFirstNote(): string | SeekerNoteV2Item[] | undefined {
    if (this.notes.length > 0) {
      return this.notes[0].note;
    }

    return '';
  }

  getDate(): string {
    return moment().format('D');
  }

  getMonth(): string {
    return moment().format('MMM');
  }

  getYear(): string {
    return moment().format('Y');
  }
}
