import React, { ChangeEvent } from 'react';
import { Box, Text, Stack, Checkbox } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchAndSortTypes } from '../filters/seekersFiltersContextValueType';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';

export enum LanguageEvaluationStatus {
  EXPERT = 'EXPERT',
  COMPETENT = 'COMPETENT',
  LIMITED = 'LIMITED',
  OCCASIONAL = 'OCCASIONAL',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  TO_BE_ACCESSED = 'TO_BE_ACCESSED',
  REQUESTED = 'REQUESTED',
}
export const LanguageEvaluation = (): JSX.Element => {
  const { t } = useTranslation('audioEvaluation');

  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();

  const onHandleBackgroundCheck = (searchAndSort: SearchAndSortTypes) => {
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...searchAndSort });
  };

  return (
    <Box>
      <Stack direction="column">
        {Object.values(LanguageEvaluationStatus).map((value) => (
          <Checkbox
            data-testid={`languageEvaluation_${value}`}
            key={`${value}`}
            isChecked={searchAndSortTypes.languageEvaluation.includes(value.toLowerCase())}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                searchAndSortTypes.languageEvaluation.push(value.toLowerCase());
              } else {
                const index = searchAndSortTypes.languageEvaluation.indexOf(value.toLowerCase());
                searchAndSortTypes.languageEvaluation.splice(index, 1);
              }
              onHandleBackgroundCheck(searchAndSortTypes);
            }}
          >
            <Text
              px={3}
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
              }}
            >
              {t(`status.${value}`)}
            </Text>
          </Checkbox>
        ))}
      </Stack>
    </Box>
  );
};
