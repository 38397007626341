import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';

export class OfferAndHireProcessController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getOfferAndHireDetails(accountId: string): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/dashboard/offerHireDetail`;
    return this.httpClient.get(path);
  }
}
