export default {
  modalTitle: `Détails de l'évaluation des compétences`,
  close: `Fermer`,
  completionDate: "Date d'achèvement de l'évaluation",
  score: 'Score',
  band: 'Résultat',
  title: 'Title',
  reviewURL: "URL de l'évaluation",
  comments: 'Commentaires',
  status: {
    ASSESSMENT_STARTED: 'Demandé',
    ASSESSMENT_IN_PROGRESS: 'En cours',
    ASSESSMENT_PASSED: 'Réussie',
    ASSESSMENT_FAILED: 'Échouée',
  },
  assessmentStarted: "L'évaluation des compétences a débuté.",
  assessmentProcessing: "L'évaluation des compétences est en cours de traitement.",
  Success: 'Réussite',
  Fail: 'Échec',
};
