import React, { ChangeEvent } from 'react';
import { Box, Text, Checkbox } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';

export const PriorityCandidatesView = (): JSX.Element => {
  const { t } = useTranslation();
  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();
  const onHandlePriority = (isFavouriteApplied: boolean) => {
    searchAndSortTypes.isPriorityCandidates = isFavouriteApplied;
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...searchAndSortTypes });
  };
  return (
    <Box>
      <Checkbox
        data-testid="proprotyCheckBox"
        isChecked={searchAndSortTypes.isPriorityCandidates}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onHandlePriority(e.target.checked)}
      >
        <Text
          px={3}
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
          }}
        >
          {t('searchAndSort.priority')}
        </Text>
      </Checkbox>
    </Box>
  );
};
