import { useEffect, useState } from 'react';
import { LocalTrackPublication, RemoteTrackPublication } from 'twilio-video';
import { LocalTrack, RemoteTrack } from 'twilio-video/tsdef/types';

type UseTrackProps = {
  publication: LocalTrackPublication | RemoteTrackPublication | undefined;
};

export const useTrack = ({ publication }: UseTrackProps): LocalTrack | RemoteTrack | null => {
  const [track, setTrack] = useState<LocalTrack | RemoteTrack | null>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!publication) return undefined;

    setTrack(publication && publication.track);

    const removeTrack = () => {
      setTrack(null);
    };

    publication.on('subscribed', setTrack);
    publication.on('unsubscribed', removeTrack);

    return () => {
      publication.off('subscribed', setTrack);
      publication.off('unsubscribed', removeTrack);
    };
  }, [publication]);

  return track;
};
