/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Flex } from '@chakra-ui/core';
import { IconContext } from 'react-icons/lib';
import { IoMdVideocam } from 'react-icons/io';
import { MdLocalPhone } from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';
import { appointmentTypeIconCSS } from './AppointmentTypeIcon.styles';
import { AppointmentType } from '../../../firebase/firestore/documents/position';
import colors from '../../../styles/colors';

export type AppointmentTypeIconProps = {
  type: AppointmentType | undefined;
};

export const AppointmentTypeIcon = ({ type }: AppointmentTypeIconProps): JSX.Element => {
  return (
    <Flex alignItems="center" direction="column" justify="center" className="blue-circle-icon" css={appointmentTypeIconCSS}>
      <IconContext.Provider value={{ color: colors.white }}>
        {type === AppointmentType.video && <IoMdVideocam size={14} data-testid="video-icon" />}
        {type === AppointmentType.phone && <MdLocalPhone size={14} data-testid="phone-icon" />}
        {type === AppointmentType.inPerson && <FiUsers size={14} data-testid="person-icon" />}
      </IconContext.Provider>
    </Flex>
  );
};
