/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useEffect, useState } from 'react';
import { Box, BoxProps, Menu, MenuList } from '@chakra-ui/core';
import _find from 'lodash/find';
import colors from '../../../../../styles/colors';
import CandidatePickerItemPresenter from './item/candidatePickerItemPresenter';
import CandidatesDropdownButton from './CandidatesDropdownButton';
import CandidatesDropdownItem from './item/CandidatesDropdownItem';

import { menuButtonPositionCSS, menuListPositionCSS } from './CandidatesDropdown.styles';
import { Candidate } from '../../../../../firebase/firestore/documents/candidate';

export type CandidatesDropdownProps = BoxProps & {
  candidates?: CandidatePickerItemPresenter[];
  value?: Candidate;
  onPickItem: (candidate: Candidate) => void;
};

const CandidatesDropdown = ({ candidates, value, onPickItem, ...rest }: CandidatesDropdownProps): JSX.Element => {
  const [candidate, setCandidate] = useState<CandidatePickerItemPresenter | null>(null);

  useEffect(() => {
    const found = _find(candidates, ['id', value?.id]);
    if (found) {
      setCandidate(found);
    } else if (candidates && candidates.length > 0) {
      setCandidate(candidates[0]);
    } else {
      setCandidate(null);
    }
  }, [value, candidates]);

  return (
    <Box {...rest}>
      {candidates && candidates?.length > 0 && (
        <Menu placement="bottom-end" autoSelect={false}>
          {({ isOpen }) => (
            <Fragment>
              <CandidatesDropdownButton
                css={menuButtonPositionCSS}
                isOpen={isOpen}
                candidate={candidate}
                minW={365}
                minH={70}
                marginLeft={2}
              />
              <MenuList marginTop={0} borderColor={colors.neutralGray['300']} css={menuListPositionCSS}>
                {candidates?.map((pickerItem) => (
                  <CandidatesDropdownItem
                    key={pickerItem.id}
                    candidate={pickerItem}
                    onPickItem={onPickItem}
                    candidateLength={candidates && candidates.length}
                  />
                ))}
              </MenuList>
            </Fragment>
          )}
        </Menu>
      )}
    </Box>
  );
};

export default CandidatesDropdown;
