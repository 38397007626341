export const uiGridItemsWidth = 'minmax(280px, 20vw)';
export const uiGridItemsWidthForInterview = 'minmax(280px, 24vw)';
export const uiGridSearchSortWidth = 'minmax(240px, 16vw)';
export const uiGridItemsWidthNarrow = '200px';
export const headerHeight = '64px';
export const filtersHeight = '106px';
export const filtersHeightWhenCertn = '115px';
export const chatInputHeight = '60px';
export const transitionSpeed = '120ms';
export const avatarWidth = {
  sm: '30px',
  md: '48px',
  lg: '68px',
};
