import SpecificQuestionsPresenter from './specificQuestionsPresenter';
import { Collections } from '../../../../../firebase/firestore/collections';
import { Position } from '../../../../../firebase/firestore/documents/position';
import { useDocumentSnapshot } from '../../../../../firebase/firestore/hooks';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';
import { EngagedCandidate } from '../../../../../firebase/firestore/documents/candidate';

export enum QuestionStateEnum {
  UNDEFINED,
  LOADING,
  READY,
}

export type QuestionState = {
  state: QuestionStateEnum;
  questions: SpecificQuestionsPresenter | undefined;
  initialized: boolean;
};

const useSpecificQuestions = (candidateFromTopCandidate?: EngagedCandidate | undefined): QuestionState => {
  const { selectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();

  const candidate = selectedCandidate || selectedEngagedCandidate || candidateFromTopCandidate;

  const position = useDocumentSnapshot<Position>(Collections.POSITIONS, candidate?.position || '');

  if (candidate && position) {
    if (position.specificQuestions?.length === 0 || !position?.specificQuestions?.length) {
      return {
        state: QuestionStateEnum.UNDEFINED,
        questions: undefined,
        initialized: true,
      };
    }

    return {
      state: QuestionStateEnum.READY,
      questions: new SpecificQuestionsPresenter(candidate, position),
      initialized: true,
    };
  }

  return {
    state: QuestionStateEnum.LOADING,
    questions: undefined,
    initialized: false,
  };
};

export default useSpecificQuestions;
