/* istanbul ignore file */
import {
  Badge,
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import moment from 'moment-timezone';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosInformationCircleOutline, IoMdInformationCircleOutline } from 'react-icons/io';
import { RiArrowDownSLine } from 'react-icons/ri';
import {
  Requisition,
  RequisitionStatus,
  SalaryUnits,
  ShiftDetail,
  Shifts,
} from '../../../../../firebase/firestore/documents/requisition';
import { useStoreState } from '../../../../../models/hooks';
import colors from '../../../../../styles/colors';
import { isRequisitionAvailable } from '../../../../../utils/RequisitionEffectiveComparisionWithThresholdValue';
import { drawerCSS } from '../../../../calendar/CalendarView.Style';
import { reactDatePickerCSS } from '../../../../calendar/scheduleSidebar/ReactDatePicker.Style';
import { useMessagingViewContext } from '../../../MessagingViewContext';
import { tableStyle } from '../../header/actionsDropdown/HireWithRequisitionStyle';
import ViewRequisitionDetailUtils from './ViewRequisitionDetailUtils';

const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri'];
const weekEnds = ['sat', 'sun'];

export type ViewRequisitionDetailProps = {
  isOpen: boolean;
  onClose: () => void;
  showReqStatus?: boolean;
  requisition?: Requisition;
  shouldCompare?: boolean;
  requisitionActiveThresholdDaysPosition?: number;
  shiftIntersection?: Shifts;
  availability?: Shifts;
  expectedWorkHour?: { unit: string; value: string };
  offeredSalary?: { salary: string; salaryUnit: string; customSalaryUnit?: string };
  dashboard?: boolean;
};

export const ViewRequisitionDetail = ({
  isOpen,
  onClose,
  requisition,
  showReqStatus,
  shouldCompare,
  requisitionActiveThresholdDaysPosition,
  shiftIntersection,
  availability,
  expectedWorkHour,
  offeredSalary,
  dashboard,
}: ViewRequisitionDetailProps): JSX.Element => {
  const { t, i18n } = useTranslation('requisition');
  const selectedRequisition = requisition;

  const [showComparision, setShowComparision] = useState<boolean>(false);
  const requisitionActiveThresholdDaysAccount = useStoreState(
    (state) => state.app.accounts?.configuration?.requisitionActiveThresholdDays,
  );

  const getColorScheme = (status: RequisitionStatus) => {
    switch (status) {
      case RequisitionStatus.PROCESSING:
        return 'teal';
      case RequisitionStatus.FILLED:
        return 'green';
      case RequisitionStatus.CANCELLED:
        return 'red';
      default:
        return 'blue';
    }
  };
  const { selectedSeeker } = useMessagingViewContext();
  /* istanbul ignore next */
  const candidateAvailabilities = (availability || selectedSeeker?.newAvailabilities) ?? null;
  const candidateNightAvailabilities = selectedSeeker?.nightAvailabilities ?? null;

  /* istanbul ignore next */
  const isAvailabilitiesMatchedToRequisition = (reqAvailabilities?: ShiftDetail, day?: string) => {
    if (!shouldCompare || !reqAvailabilities) {
      return true;
    }

    if (reqAvailabilities.start_1 < reqAvailabilities.end_1) {
      if (candidateAvailabilities && day && candidateAvailabilities[day] && reqAvailabilities) {
        const startTime1 = new Date(`2000-01-01T${candidateAvailabilities[day].start_1}:00`);
        const endTime1 = new Date(`2000-01-01T${candidateAvailabilities[day].end_1}:00`);
        const startTime2 = new Date(`2000-01-01T${reqAvailabilities.start_1}:00`);
        const endTime2 = new Date(`2000-01-01T${reqAvailabilities.end_1}:00`);
        return startTime1 <= startTime2 && endTime1 >= endTime2;
      }
    } else if (reqAvailabilities.start_1 > reqAvailabilities.end_1) {
      if (candidateNightAvailabilities && day && candidateNightAvailabilities[day] && reqAvailabilities) {
        const startTime1 = new Date(`2000-01-01T${candidateNightAvailabilities[day].start_1}:00`);
        const endTime1 = new Date(`2000-01-01T${candidateNightAvailabilities[day].end_1}:00`);
        const startTime2 = new Date(`2000-01-01T${reqAvailabilities.start_1}:00`);
        const endTime2 = new Date(`2000-01-01T${reqAvailabilities.end_1}:00`);
        return startTime1 <= startTime2 && endTime1 >= endTime2;
      }
    }

    return false;
  };

  /* istanbul ignore next */
  const isAvailabilitiesIntersectionMatchedToRequisition = (
    reqAvailabilities?: ShiftDetail,
    day?: string,
    isFirstShift = true,
    isSeekerOvernight = false,
  ) => {
    if (!reqAvailabilities) {
      return true;
    }

    if (!isFirstShift && (reqAvailabilities.start_2 === undefined || reqAvailabilities.end_2 === undefined)) {
      return true;
    }

    const startField = isFirstShift ? 'start_1' : 'start_2';
    const endField = isFirstShift ? 'end_1' : 'end_2';

    if (reqAvailabilities[startField] !== undefined && reqAvailabilities[endField] !== undefined) {
      if (reqAvailabilities[startField]! < reqAvailabilities[endField]! && !isSeekerOvernight) {
        if (candidateAvailabilities && day && candidateAvailabilities[day]) {
          const startTime1 = new Date(`2000-01-01T${candidateAvailabilities[day].start_1}:00`);
          const endTime1 = new Date(`2000-01-01T${candidateAvailabilities[day].end_1}:00`);
          const startTime2 = new Date(`2000-01-01T${reqAvailabilities[startField]}:00`);
          const endTime2 = new Date(`2000-01-01T${reqAvailabilities[endField]}:00`);
          return startTime1 <= startTime2 && endTime1 >= endTime2;
        }
      } else if (reqAvailabilities[startField]! > reqAvailabilities[endField]! || isSeekerOvernight) {
        if (candidateNightAvailabilities && day && candidateNightAvailabilities[day]) {
          const startTime1 = new Date(`2000-01-01T${candidateNightAvailabilities[day].start_1}:00`);
          const endTime1 = new Date(`2000-01-01T${candidateNightAvailabilities[day].end_1}:00`);
          const startTime2 = new Date(`2000-01-01T${reqAvailabilities[startField]}:00`);
          const endTime2 = new Date(`2000-01-01T${reqAvailabilities[endField]}:00`);
          return startTime1 <= startTime2 && endTime1 >= endTime2;
        }
      }
    }
    return false;
  };

  const onGetColorByIndex = (index: number) => {
    if (index % 2) return '#edf2f7';
    return '#ffffff';
  };

  // helper function
  const getDayRange = (day: string) => {
    const daysOfWeek = [...weekDays, ...weekEnds];
    const currentIndex = daysOfWeek.indexOf(day);
    const nextDay = currentIndex < daysOfWeek.length - 1 ? daysOfWeek[currentIndex + 1] : daysOfWeek[0];
    return `${t(`common:days.${day}`)} - ${t(`common:days.${nextDay}`)}`;
  };

  return (
    <Box>
      <Modal
        id="ViewRequisitionDetailModal"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick
        size="4xl"
        scrollBehavior="inside"
        isCentered={false}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalCloseButton data-testid="requisition-modal-close" />
            <ModalBody css={[drawerCSS, reactDatePickerCSS]}>
              <Box>
                {selectedRequisition ? (
                  <Box>
                    <Flex mt="16px">
                      <Text fontSize={16} fontWeight={700} mr="12px">
                        {t('requisitionDetails')}
                      </Text>
                      {selectedRequisition.seasonal && (
                        <Text fontSize="xs" fontWeight="bold" d="inline-flex" alignItems="center">
                          <Badge
                            variant="subtle"
                            colorScheme="blue"
                            mr="12px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="#263DBA"
                            background="#263DBA1A"
                          >
                            {t('seasonal')}
                          </Badge>
                        </Text>
                      )}

                      <Text fontSize="xs" fontWeight="bold" d="inline-flex" alignItems="center">
                        <Badge
                          variant="subtle"
                          colorScheme="blue"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          mr="4px"
                          color="#263DBA"
                          background="#263DBA1A"
                        >
                          {selectedRequisition.fullTime ? t('fullTime') : t('partTime')}
                        </Badge>
                        {`${
                          selectedRequisition.fullTime
                            ? ''
                            : `: ${selectedRequisition.partTimeHours?.toFixed(2)} ${t('partTimeHoursPerWeek')}`
                        } `}
                      </Text>
                      {showReqStatus && selectedRequisition.status && (
                        <Text fontSize="xs" fontWeight="bold" d="inline-flex" alignItems="center">
                          <Badge
                            variant="subtle"
                            colorScheme={getColorScheme(selectedRequisition.status)}
                            ml="6px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {t(`requisition:${selectedRequisition.status}`)}
                          </Badge>
                        </Text>
                      )}
                    </Flex>
                    <Divider mt="16px" />
                    {!dashboard && <ViewRequisitionDetailUtils requisition={requisition} />}
                    {expectedWorkHour && expectedWorkHour.value && (
                      <Flex mt="12px">
                        <Text fontSize="14px" fontWeight="700" mr={2}>
                          {t('candidateHistory:expectedWorkHours')}
                        </Text>
                        <Text fontSize="14px" fontWeight="400">
                          {`${expectedWorkHour.value} ${t('common:hrsWeek')}`}
                        </Text>
                      </Flex>
                    )}
                    <Flex mt="16px" alignItems="center">
                      <Text fontSize="14px" fontWeight="700">
                        {t('requisitionID')}
                      </Text>
                      <Text pl="6px">{selectedRequisition.requisitionId}</Text>
                    </Flex>
                    <Flex mt="16px" alignItems="center">
                      {selectedRequisition?.effectiveDate && (
                        <Flex w="50%" alignItem="center">
                          <Text fontSize="14px" fontWeight="700">
                            {t('effectiveTime')}
                          </Text>
                          <Text pl="6px">{moment(selectedRequisition?.effectiveDate).locale(i18n.language).format('ll')}</Text>
                          {
                            /* istanbul ignore next */
                            !isRequisitionAvailable(
                              selectedRequisition?.effectiveDate,
                              requisitionActiveThresholdDaysAccount,
                              requisitionActiveThresholdDaysPosition,
                            ) && (
                              <Flex alignItems="center">
                                <Tooltip
                                  placement="top"
                                  size="xl"
                                  label={t('effectiveTimeInfo', {
                                    requisitionActiveThresholdDays: moment(selectedRequisition.effectiveDate)
                                      .subtract(
                                        requisitionActiveThresholdDaysPosition || requisitionActiveThresholdDaysAccount,
                                        'days',
                                      )
                                      .locale(i18n.language)
                                      .format('ll'),
                                  })}
                                  aria-label="value?.label"
                                  shouldWrapChildren
                                >
                                  <IoMdInformationCircleOutline
                                    color={colors.red[500]}
                                    style={{ cursor: 'pointer', marginLeft: '6px' }}
                                  />
                                </Tooltip>
                              </Flex>
                            )
                          }
                        </Flex>
                      )}
                      {selectedRequisition?.wage && (
                        <Flex w="50%" ml={selectedRequisition?.effectiveDate ? '12px' : /* istanbul ignore next */ '0px'}>
                          <Text fontSize="14px" fontWeight="700">
                            {t('wages')}
                          </Text>
                          <Text pl="6px">
                            {`${`$${
                              selectedRequisition?.wage?.value
                                ? selectedRequisition?.wage?.value?.toLocaleString(i18n.language, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : /* istanbul ignore next */ ''
                            }`} ${`${
                              selectedRequisition?.wage.maxValue
                                ? ` - $${selectedRequisition?.wage.maxValue?.toLocaleString(i18n.language, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}`
                                : ''
                            }/`} ${
                              selectedRequisition?.wage.unit === SalaryUnits.CUSTOM
                                ? selectedRequisition?.wage?.customUnit
                                : t(selectedRequisition?.wage.unit.toLowerCase())
                            }`}
                          </Text>
                        </Flex>
                      )}
                      {offeredSalary && (
                        <Flex w="50%">
                          <Text fontSize="14px" fontWeight="700">
                            {t('salaryOffered')}
                          </Text>
                          <Text pl="6px">
                            $
                            {`${`${Number(offeredSalary.salary)?.toLocaleString(i18n.language, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}/`} ${
                              /* istanbul ignore next */
                              offeredSalary.salaryUnit === SalaryUnits.CUSTOM.toLowerCase()
                                ? offeredSalary.customSalaryUnit
                                : t(offeredSalary.salaryUnit.toLowerCase())
                            }`}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                    {selectedRequisition.seasonal && selectedRequisition?.endDate && (
                      <Flex mt="12px">
                        <Text fontSize="14px" fontWeight="700">
                          {t('seasonalEndDate')}
                        </Text>
                        <Text pl="6px">{moment(selectedRequisition?.endDate).locale(i18n.language).format('ll')}</Text>
                      </Flex>
                    )}

                    {shiftIntersection && (
                      <Box mt="10px">
                        <Flex alignItems="center" mb="6px">
                          <Text fontSize="14px" fontWeight="700" mr="10px">
                            {t('candidateRequisitionMatch')}
                          </Text>
                          <Tooltip label={t('candidateAvailabilitiesInfo')} placement="top" shouldWrapChildren>
                            <IoIosInformationCircleOutline />
                          </Tooltip>
                        </Flex>

                        <Flex alignItems="baseline" css={tableStyle} style={{ display: 'flex' }}>
                          <Box w="50%">
                            <table>
                              {weekDays.map((day, index) => {
                                // const reqShift = selectedRequisition?.shifts && selectedRequisition?.shifts[day];
                                const reqIntersectionShift = shiftIntersection && shiftIntersection[day];
                                const isAnotherRequisitionOnSameDayAvailable =
                                  reqIntersectionShift &&
                                  reqIntersectionShift.start_2 &&
                                  reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_2 < reqIntersectionShift.end_2;

                                const isAnotherRequisitionOnDifferentDayAvailable =
                                  reqIntersectionShift &&
                                  reqIntersectionShift.start_2 &&
                                  reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_2 >= reqIntersectionShift.end_2;

                                const isBothRequisitionOnDifferentDay =
                                  reqIntersectionShift &&
                                  reqIntersectionShift.start_2 &&
                                  reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_2 > reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_1 > reqIntersectionShift.end_1;

                                const isFirstRequisitionOnDifferentDayAndSecondOnSame =
                                  reqIntersectionShift &&
                                  reqIntersectionShift.start_2 &&
                                  reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_2 < reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_1 >= reqIntersectionShift.end_1;
                                return (
                                  <Flex direction="column" key={day}>
                                    {isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                      <tr
                                        style={{
                                          background: isAvailabilitiesIntersectionMatchedToRequisition(
                                            shiftIntersection[day]!,
                                            day,
                                            reqIntersectionShift?.isSeekerOvernight2,
                                          )
                                            ? /* istanbul ignore next */ onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <td>{t(`common:days.${day}`)}</td>

                                        <td style={{ float: 'right' }}>
                                          <Flex>
                                            {reqIntersectionShift &&
                                            reqIntersectionShift.start_2 &&
                                            reqIntersectionShift.end_2 &&
                                            selectedRequisition.shifts &&
                                            selectedRequisition.shifts[day] ? (
                                              <Text>
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      shiftIntersection[day].start_2 !== selectedRequisition.shifts[day].start_2
                                                        ? 'bold'
                                                        : 'normal',
                                                  }}
                                                >
                                                  {`${moment(shiftIntersection[day].start_2, ['HH:mm']).format('LT')}`}
                                                </span>
                                                <span>{` - `}</span>
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      shiftIntersection[day].end_2 !== selectedRequisition.shifts[day].end_2
                                                        ? 'bold'
                                                        : 'normal',
                                                  }}
                                                >
                                                  {`${moment(shiftIntersection[day].end_2, ['HH:mm']).format('LT')}`}
                                                </span>
                                              </Text>
                                            ) : (
                                              <Text>---</Text>
                                            )}
                                          </Flex>
                                        </td>
                                      </tr>
                                    )}
                                    <tr
                                      style={{
                                        background: isAvailabilitiesIntersectionMatchedToRequisition(
                                          shiftIntersection[day]!,
                                          day,
                                          reqIntersectionShift?.isSeekerOvernight1,
                                        )
                                          ? /* istanbul ignore next */ onGetColorByIndex(index)
                                          : colors.red[100],
                                        borderTop: '1px solid #edf2f7',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <td>
                                        {reqIntersectionShift && reqIntersectionShift.start_1 > reqIntersectionShift.end_1
                                          ? getDayRange(day)
                                          : t(`common:days.${day}`)}
                                      </td>

                                      <td style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <td style={{ float: 'right', padding: 0 }}>
                                          {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame ? (
                                            <Badge
                                              background={
                                                isAvailabilitiesIntersectionMatchedToRequisition(
                                                  shiftIntersection[day]!,
                                                  day,
                                                  reqIntersectionShift?.isSeekerOvernight1,
                                                )
                                                  ? '#e9f0ff'
                                                  : '#ffe9e9'
                                              }
                                            >
                                              <Flex>
                                                {reqIntersectionShift &&
                                                reqIntersectionShift.start_1 &&
                                                reqIntersectionShift.end_1 &&
                                                selectedRequisition.shifts &&
                                                selectedRequisition.shifts[day] ? (
                                                  <Text fontSize={15} fontWeight="normal">
                                                    <span
                                                      style={{
                                                        fontWeight:
                                                          reqIntersectionShift.start_1 !==
                                                          selectedRequisition?.shifts[day].start_1
                                                            ? 'bold'
                                                            : 'normal',
                                                      }}
                                                    >
                                                      {`${moment(reqIntersectionShift.start_1, ['HH:mm']).format('LT')}`}
                                                    </span>
                                                    <span>{` - `}</span>
                                                    <span
                                                      style={{
                                                        fontWeight:
                                                          reqIntersectionShift.end_1 !== selectedRequisition.shifts[day].end_1
                                                            ? 'bold'
                                                            : 'normal',
                                                      }}
                                                    >
                                                      {`${moment(reqIntersectionShift.end_1, ['HH:mm']).format('LT')}`}
                                                    </span>
                                                  </Text>
                                                ) : (
                                                  <Text>---</Text>
                                                )}
                                              </Flex>
                                            </Badge>
                                          ) : (
                                            <Flex paddingRight={2}>
                                              {reqIntersectionShift?.start_1 &&
                                              reqIntersectionShift?.end_1 &&
                                              selectedRequisition?.shifts &&
                                              selectedRequisition?.shifts[day] ? (
                                                <Text>
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        shiftIntersection[day].start_1 !==
                                                        selectedRequisition?.shifts[day].start_1
                                                          ? 'bold'
                                                          : 'normal',
                                                    }}
                                                  >
                                                    {`${moment(shiftIntersection[day].start_1, ['HH:mm']).format('LT')}`}
                                                  </span>
                                                  <span>{` - `}</span>
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        shiftIntersection[day].end_1 !== selectedRequisition.shifts[day].end_1
                                                          ? 'bold'
                                                          : 'normal',
                                                    }}
                                                  >
                                                    {`${moment(shiftIntersection[day].end_1, ['HH:mm']).format('LT')}`}
                                                  </span>
                                                </Text>
                                              ) : (
                                                <Text>---</Text>
                                              )}
                                            </Flex>
                                          )}
                                        </td>

                                        {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                            <td style={{ float: 'right' }}>
                                              <Badge
                                                background={
                                                  isAvailabilitiesIntersectionMatchedToRequisition(
                                                    shiftIntersection[day]!,
                                                    day,
                                                    reqIntersectionShift?.isSeekerOvernight2,
                                                  )
                                                    ? '#e9f0ff'
                                                    : '#ffe9e9'
                                                }
                                              >
                                                <Flex>
                                                  {reqIntersectionShift.start_2 &&
                                                  reqIntersectionShift.end_2 &&
                                                  selectedRequisition.shifts &&
                                                  selectedRequisition.shifts[day] ? (
                                                    <Text fontSize={15} fontWeight="normal">
                                                      <span
                                                        style={{
                                                          fontWeight:
                                                            shiftIntersection[day].start_2 !==
                                                            selectedRequisition?.shifts[day].start_2
                                                              ? 'bold'
                                                              : 'normal',
                                                        }}
                                                      >
                                                        {`${moment(shiftIntersection[day].start_2, ['HH:mm']).format('LT')}`}
                                                      </span>
                                                      <span>{` - `}</span>
                                                      <span
                                                        style={{
                                                          fontWeight:
                                                            shiftIntersection[day].end_2 !== selectedRequisition.shifts[day].end_2
                                                              ? 'bold'
                                                              : 'normal',
                                                        }}
                                                      >
                                                        {`${moment(shiftIntersection[day].end_2, ['HH:mm']).format('LT')}`}
                                                      </span>
                                                    </Text>
                                                  ) : (
                                                    <Text>---</Text>
                                                  )}
                                                </Flex>
                                              </Badge>
                                            </td>
                                          )}
                                      </td>
                                    </tr>

                                    {isAnotherRequisitionOnDifferentDayAvailable && !isBothRequisitionOnDifferentDay && (
                                      <tr
                                        style={{
                                          background: isAvailabilitiesIntersectionMatchedToRequisition(
                                            shiftIntersection[day]!,
                                            day,
                                            reqIntersectionShift?.isSeekerOvernight2,
                                          )
                                            ? /* istanbul ignore next */ onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <td>{getDayRange(day)}</td>

                                        <td style={{ float: 'right' }}>
                                          <Flex>
                                            {reqIntersectionShift.start_2 &&
                                            reqIntersectionShift.end_2 &&
                                            selectedRequisition.shifts &&
                                            selectedRequisition.shifts[day] ? (
                                              <Text fontSize={16}>
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      shiftIntersection[day].start_2 !== selectedRequisition?.shifts[day].start_2
                                                        ? 'bold'
                                                        : 'normal',
                                                  }}
                                                >
                                                  {`${moment(shiftIntersection[day].start_2, ['HH:mm']).format('LT')}`}
                                                </span>
                                                <span>{` - `}</span>
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      shiftIntersection[day].end_2 !== selectedRequisition.shifts[day].end_2
                                                        ? 'bold'
                                                        : 'normal',
                                                  }}
                                                >
                                                  {`${moment(shiftIntersection[day].end_2, ['HH:mm']).format('LT')}`}
                                                </span>
                                              </Text>
                                            ) : (
                                              <Text>---</Text>
                                            )}
                                          </Flex>
                                        </td>
                                      </tr>
                                    )}
                                  </Flex>
                                );
                              })}
                            </table>
                          </Box>

                          <Box w="50%" ml="12px">
                            <table>
                              {weekEnds.map((day, index) => {
                                // const reqShift = selectedRequisition?.shifts && selectedRequisition?.shifts[day];
                                const reqIntersectionShift = shiftIntersection && shiftIntersection[day];
                                const isAnotherRequisitionOnSameDayAvailable =
                                  reqIntersectionShift &&
                                  reqIntersectionShift.start_2 &&
                                  reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_2 < reqIntersectionShift.end_2;

                                const isAnotherRequisitionOnDifferentDayAvailable =
                                  reqIntersectionShift &&
                                  reqIntersectionShift.start_2 &&
                                  reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_2 >= reqIntersectionShift.end_2;

                                const isBothRequisitionOnDifferentDay =
                                  reqIntersectionShift &&
                                  reqIntersectionShift.start_2 &&
                                  reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_2 > reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_1 > reqIntersectionShift.end_1;

                                const isFirstRequisitionOnDifferentDayAndSecondOnSame =
                                  reqIntersectionShift &&
                                  reqIntersectionShift.start_2 &&
                                  reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_2 < reqIntersectionShift.end_2 &&
                                  reqIntersectionShift.start_1 >= reqIntersectionShift.end_1;
                                return (
                                  <Flex direction="column" key={day}>
                                    {isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                      <tr
                                        style={{
                                          background: isAvailabilitiesIntersectionMatchedToRequisition(
                                            shiftIntersection[day]!,
                                            day,
                                            reqIntersectionShift?.isSeekerOvernight2,
                                          )
                                            ? /* istanbul ignore next */ onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <td>{t(`common:days.${day}`)}</td>

                                        <td style={{ float: 'right' }}>
                                          <Flex>
                                            {reqIntersectionShift &&
                                            reqIntersectionShift.start_2 &&
                                            reqIntersectionShift.end_2 &&
                                            selectedRequisition.shifts &&
                                            selectedRequisition.shifts[day] ? (
                                              <Text>
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      shiftIntersection[day].start_2 !== selectedRequisition.shifts[day].start_2
                                                        ? 'bold'
                                                        : 'normal',
                                                  }}
                                                >
                                                  {`${moment(shiftIntersection[day].start_2, ['HH:mm']).format('LT')}`}
                                                </span>
                                                <span>{` - `}</span>
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      shiftIntersection[day].end_2 !== selectedRequisition.shifts[day].end_2
                                                        ? 'bold'
                                                        : 'normal',
                                                  }}
                                                >
                                                  {`${moment(shiftIntersection[day].end_2, ['HH:mm']).format('LT')}`}
                                                </span>
                                              </Text>
                                            ) : (
                                              <Text>---</Text>
                                            )}
                                          </Flex>
                                        </td>
                                      </tr>
                                    )}
                                    <tr
                                      style={{
                                        background: isAvailabilitiesIntersectionMatchedToRequisition(
                                          shiftIntersection[day]!,
                                          day,
                                          reqIntersectionShift?.isSeekerOvernight1,
                                        )
                                          ? /* istanbul ignore next */ onGetColorByIndex(index)
                                          : colors.red[100],
                                        borderTop: '1px solid #edf2f7',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <td>
                                        {reqIntersectionShift && reqIntersectionShift.start_1 > reqIntersectionShift.end_1
                                          ? getDayRange(day)
                                          : t(`common:days.${day}`)}
                                      </td>
                                      <td style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <td style={{ float: 'right', padding: 0 }}>
                                          {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame ? (
                                            <Badge
                                              background={
                                                isAvailabilitiesIntersectionMatchedToRequisition(
                                                  shiftIntersection[day]!,
                                                  day,
                                                  reqIntersectionShift?.isSeekerOvernight1,
                                                )
                                                  ? '#e9f0ff'
                                                  : '#ffe9e9'
                                              }
                                            >
                                              <Flex>
                                                {reqIntersectionShift &&
                                                reqIntersectionShift.start_1 &&
                                                reqIntersectionShift.end_1 &&
                                                selectedRequisition.shifts &&
                                                selectedRequisition.shifts[day] ? (
                                                  <Text fontSize={15} fontWeight="normal">
                                                    <span
                                                      style={{
                                                        fontWeight:
                                                          reqIntersectionShift.start_1 !==
                                                          selectedRequisition?.shifts[day].start_1
                                                            ? 'bold'
                                                            : 'normal',
                                                      }}
                                                    >
                                                      {`${moment(reqIntersectionShift.start_1, ['HH:mm']).format('LT')}`}
                                                    </span>
                                                    <span>{` - `}</span>
                                                    <span
                                                      style={{
                                                        fontWeight:
                                                          reqIntersectionShift.end_1 !== selectedRequisition.shifts[day].end_1
                                                            ? 'bold'
                                                            : 'normal',
                                                      }}
                                                    >
                                                      {`${moment(reqIntersectionShift.end_1, ['HH:mm']).format('LT')}`}
                                                    </span>
                                                  </Text>
                                                ) : (
                                                  <Text>---</Text>
                                                )}
                                              </Flex>
                                            </Badge>
                                          ) : (
                                            <Flex paddingRight={2}>
                                              {reqIntersectionShift?.start_1 &&
                                              reqIntersectionShift?.end_1 &&
                                              selectedRequisition?.shifts &&
                                              selectedRequisition?.shifts[day] ? (
                                                <Text>
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        shiftIntersection[day].start_1 !==
                                                        selectedRequisition?.shifts[day].start_1
                                                          ? 'bold'
                                                          : 'normal',
                                                    }}
                                                  >
                                                    {`${moment(shiftIntersection[day].start_1, ['HH:mm']).format('LT')}`}
                                                  </span>
                                                  <span>{` - `}</span>
                                                  <span
                                                    style={{
                                                      fontWeight:
                                                        shiftIntersection[day].end_1 !== selectedRequisition.shifts[day].end_1
                                                          ? 'bold'
                                                          : 'normal',
                                                    }}
                                                  >
                                                    {`${moment(shiftIntersection[day].end_1, ['HH:mm']).format('LT')}`}
                                                  </span>
                                                </Text>
                                              ) : (
                                                <Text>---</Text>
                                              )}
                                            </Flex>
                                          )}
                                        </td>

                                        {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                            <td style={{ float: 'right' }}>
                                              <Badge
                                                background={
                                                  isAvailabilitiesIntersectionMatchedToRequisition(
                                                    shiftIntersection[day]!,
                                                    day,
                                                    reqIntersectionShift?.isSeekerOvernight2,
                                                  )
                                                    ? '#e9f0ff'
                                                    : '#ffe9e9'
                                                }
                                              >
                                                <Flex>
                                                  {reqIntersectionShift.start_2 &&
                                                  reqIntersectionShift.end_2 &&
                                                  selectedRequisition.shifts &&
                                                  selectedRequisition.shifts[day] ? (
                                                    <Text fontSize={15} fontWeight="normal">
                                                      <span
                                                        style={{
                                                          fontWeight:
                                                            shiftIntersection[day].start_2 !==
                                                            selectedRequisition?.shifts[day].start_2
                                                              ? 'bold'
                                                              : 'normal',
                                                        }}
                                                      >
                                                        {`${moment(shiftIntersection[day].start_2, ['HH:mm']).format('LT')}`}
                                                      </span>
                                                      <span>{` - `}</span>
                                                      <span
                                                        style={{
                                                          fontWeight:
                                                            shiftIntersection[day].end_2 !== selectedRequisition.shifts[day].end_2
                                                              ? 'bold'
                                                              : 'normal',
                                                        }}
                                                      >
                                                        {`${moment(shiftIntersection[day].end_2, ['HH:mm']).format('LT')}`}
                                                      </span>
                                                    </Text>
                                                  ) : (
                                                    <Text>---</Text>
                                                  )}
                                                </Flex>
                                              </Badge>
                                            </td>
                                          )}
                                      </td>
                                    </tr>

                                    {isAnotherRequisitionOnDifferentDayAvailable && !isBothRequisitionOnDifferentDay && (
                                      <tr
                                        style={{
                                          background: isAvailabilitiesIntersectionMatchedToRequisition(
                                            shiftIntersection[day]!,
                                            day,
                                            reqIntersectionShift?.isSeekerOvernight2,
                                          )
                                            ? /* istanbul ignore next */ onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <td>{getDayRange(day)}</td>

                                        <td style={{ float: 'right' }}>
                                          <Flex>
                                            {reqIntersectionShift.start_2 &&
                                            reqIntersectionShift.end_2 &&
                                            selectedRequisition.shifts &&
                                            selectedRequisition.shifts[day] ? (
                                              <Text fontSize={16}>
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      shiftIntersection[day].start_2 !== selectedRequisition?.shifts[day].start_2
                                                        ? 'bold'
                                                        : 'normal',
                                                  }}
                                                >
                                                  {`${moment(shiftIntersection[day].start_2, ['HH:mm']).format('LT')}`}
                                                </span>
                                                <span>{` - `}</span>
                                                <span
                                                  style={{
                                                    fontWeight:
                                                      shiftIntersection[day].end_2 !== selectedRequisition.shifts[day].end_2
                                                        ? 'bold'
                                                        : 'normal',
                                                  }}
                                                >
                                                  {`${moment(shiftIntersection[day].end_2, ['HH:mm']).format('LT')}`}
                                                </span>
                                              </Text>
                                            ) : (
                                              <Text>---</Text>
                                            )}
                                          </Flex>
                                        </td>
                                      </tr>
                                    )}
                                  </Flex>
                                );
                              })}
                            </table>
                          </Box>
                        </Flex>
                      </Box>
                    )}
                    {shouldCompare ? (
                      <Box mt="16px">
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          cursor="pointer"
                          data-testid="showReqAndCandidateAvabilityBtn"
                          onClick={() => setShowComparision(!showComparision)}
                          _hover={{ background: colors.gray[100] }}
                          p="6px 0px"
                        >
                          <Text fontSize="14px" fontWeight="700" mb="6px">
                            {t('compare')}
                          </Text>
                          <Box>
                            <RiArrowDownSLine />
                          </Box>
                        </Flex>
                        {showComparision && (
                          <Box mt="16px" alignItems="baseline" css={tableStyle}>
                            <table>
                              <tr>
                                <td style={{ width: '20%', fontWeight: 700 }} />
                                <td style={{ width: '40%', float: 'right', fontWeight: 700 }}>{t('requisitionShifts')}</td>
                                <td style={{ width: '40%', float: 'right', fontWeight: 700 }}>{t('candidateAvailability')}</td>
                              </tr>
                            </table>
                            <table>
                              {weekDays.map((day, index) => {
                                const reqShift = selectedRequisition?.shifts && selectedRequisition?.shifts[day];
                                const isAnotherRequisitionOnSameDayAvailable =
                                  reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 < reqShift.end_2;

                                const isAnotherRequisitionOnDifferentDayAvailable =
                                  reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 >= reqShift.end_2;

                                const isBothRequisitionOnDifferentDay =
                                  reqShift &&
                                  reqShift.start_2 &&
                                  reqShift.end_2 &&
                                  reqShift.start_2 > reqShift.end_2 &&
                                  reqShift.start_1 > reqShift.end_1;

                                const isFirstRequisitionOnDifferentDayAndSecondOnSame =
                                  reqShift &&
                                  reqShift.start_2 &&
                                  reqShift.end_2 &&
                                  reqShift.start_2 < reqShift.end_2 &&
                                  reqShift.start_1 >= reqShift.end_1;
                                return (
                                  <Fragment key={day}>
                                    {isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                      <tr>
                                        <td>{t(`common:days.${day}`)}</td>

                                        <td
                                          style={{
                                            float: 'right',
                                            width: '40%',
                                            background: /* istanbul ignore next */ isAvailabilitiesMatchedToRequisition(
                                              selectedRequisition?.shifts?.[day],
                                              day,
                                            )
                                              ? onGetColorByIndex(index)
                                              : colors.red[100],
                                            borderTop: '1px solid #edf2f7',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          <Flex>
                                            <Text fontSize={16}>
                                              {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(reqShift?.end_2, [
                                                'HH:mm',
                                              ]).format('LT')}`}
                                            </Text>
                                          </Flex>
                                        </td>

                                        <td style={{ float: 'left', width: '40%' }}>
                                          <Text>---</Text>
                                        </td>
                                      </tr>
                                    )}
                                    <tr key={day}>
                                      <td>
                                        {reqShift && (reqShift.start_1 > reqShift.end_1 || reqShift.isOvernightShift)
                                          ? getDayRange(day)
                                          : t(`common:days.${day}`)}
                                      </td>
                                      <td
                                        style={{
                                          float: 'right',
                                          width: '40%',
                                          background: /* istanbul ignore next */ isAvailabilitiesMatchedToRequisition(
                                            selectedRequisition?.shifts?.[day],
                                            day,
                                          )
                                            ? onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <td style={{ float: 'left', padding: 0 }}>
                                          {(isAnotherRequisitionOnSameDayAvailable ||
                                            isAnotherRequisitionOnSameDayAvailable ||
                                            isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame ? (
                                            <Badge
                                              background={
                                                isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                  ? '#e9f0ff'
                                                  : '#ffe9e9'
                                              }
                                            >
                                              <Flex>
                                                {reqShift ? (
                                                  <Text fontSize={15} fontWeight="normal">
                                                    {`${moment(reqShift.start_1, ['HH:mm']).format(
                                                      'LT',
                                                    )} - ${moment(reqShift.end_1, ['HH:mm']).format('LT')}`}
                                                  </Text>
                                                ) : (
                                                  <Text>---</Text>
                                                )}
                                              </Flex>
                                            </Badge>
                                          ) : (
                                            <Flex paddingRight={2}>
                                              {reqShift ? (
                                                <Text>
                                                  {`${moment(reqShift.start_1, ['HH:mm']).format('LT')} - ${moment(
                                                    reqShift.end_1,
                                                    ['HH:mm'],
                                                  ).format('LT')}`}
                                                </Text>
                                              ) : (
                                                <Text>---</Text>
                                              )}
                                            </Flex>
                                          )}
                                        </td>

                                        {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                            <td style={{ float: 'left', padding: 0 }}>
                                              <Badge
                                                background={
                                                  isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                    ? '#e9f0ff'
                                                    : '#ffe9e9'
                                                }
                                              >
                                                <Flex>
                                                  <Text fontSize={15} fontWeight="normal">
                                                    {`${moment(reqShift?.start_2, ['HH:mm']).format(
                                                      'LT',
                                                    )} - ${moment(reqShift?.end_2, ['HH:mm']).format('LT')}`}
                                                  </Text>
                                                </Flex>
                                              </Badge>
                                            </td>
                                          )}
                                      </td>
                                      {reqShift &&
                                      reqShift?.start_1 < reqShift?.end_1 &&
                                      _isUndefined(reqShift.isOvernightShift) ? (
                                        <td style={{ float: 'right', width: '40%' }}>
                                          {availability && availability[day] ? (
                                            <Flex w="100%">
                                              <Text w="35%">{moment(availability[day].start_1, ['HH:mm']).format('LT')}</Text>
                                              <Text w="5%" mr="6px">
                                                -
                                              </Text>
                                              <Text w="35%">{moment(availability[day].end_1, ['HH:mm']).format('LT')}</Text>
                                            </Flex>
                                          ) : (
                                            /* istanbul ignore next */ <Text>---</Text>
                                          )}
                                        </td>
                                      ) : (
                                        <td style={{ float: 'right', width: '40%' }}>
                                          {candidateNightAvailabilities && candidateNightAvailabilities[day] ? (
                                            <Flex w="100%">
                                              <Text w="35%">
                                                {moment(candidateNightAvailabilities[day].start_1, ['HH:mm']).format('LT')}
                                              </Text>
                                              <Text w="5%" mr="6px">
                                                -
                                              </Text>
                                              <Text w="35%">
                                                {moment(candidateNightAvailabilities[day].end_1, ['HH:mm']).format('LT')}
                                              </Text>
                                            </Flex>
                                          ) : (
                                            /* istanbul ignore next */ <Text>---</Text>
                                          )}
                                        </td>
                                      )}
                                    </tr>

                                    {isAnotherRequisitionOnDifferentDayAvailable && !isBothRequisitionOnDifferentDay && (
                                      <tr>
                                        <td>{getDayRange(day)}</td>

                                        <td
                                          style={{
                                            float: 'right',
                                            width: '40%',
                                            background: /* istanbul ignore next */ isAvailabilitiesMatchedToRequisition(
                                              selectedRequisition?.shifts?.[day],
                                              day,
                                            )
                                              ? onGetColorByIndex(index)
                                              : colors.red[100],
                                            borderTop: '1px solid #edf2f7',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          <Flex>
                                            <Text fontSize={16}>
                                              {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(reqShift?.end_2, [
                                                'HH:mm',
                                              ]).format('LT')}`}
                                            </Text>
                                          </Flex>
                                        </td>
                                      </tr>
                                    )}
                                  </Fragment>
                                );
                              })}
                            </table>
                            <table style={{ marginTop: '12px' }}>
                              {weekEnds.map((day, index) => {
                                const reqShift = selectedRequisition?.shifts && selectedRequisition?.shifts[day];
                                const isAnotherRequisitionOnSameDayAvailable =
                                  reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 < reqShift.end_2;

                                const isAnotherRequisitionOnDifferentDayAvailable =
                                  reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 >= reqShift.end_2;

                                const isBothRequisitionOnDifferentDay =
                                  reqShift &&
                                  reqShift.start_2 &&
                                  reqShift.end_2 &&
                                  reqShift.start_2 > reqShift.end_2 &&
                                  reqShift.start_1 > reqShift.end_1;

                                const isFirstRequisitionOnDifferentDayAndSecondOnSame =
                                  reqShift &&
                                  reqShift.start_2 &&
                                  reqShift.end_2 &&
                                  reqShift.start_2 < reqShift.end_2 &&
                                  reqShift.start_1 >= reqShift.end_1;
                                return (
                                  <Fragment key={day}>
                                    {isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                      <tr>
                                        <td>{t(`common:days.${day}`)}</td>

                                        <td
                                          style={{
                                            float: 'right',
                                            width: '40%',
                                            background: /* istanbul ignore next */ isAvailabilitiesMatchedToRequisition(
                                              selectedRequisition?.shifts?.[day],
                                              day,
                                            )
                                              ? onGetColorByIndex(index)
                                              : colors.red[100],
                                            borderTop: '1px solid #edf2f7',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          <Flex>
                                            <Text fontSize={16}>
                                              {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(reqShift?.end_2, [
                                                'HH:mm',
                                              ]).format('LT')}`}
                                            </Text>
                                          </Flex>
                                        </td>

                                        <td style={{ float: 'left', width: '40%' }}>
                                          <Text>---</Text>
                                        </td>
                                      </tr>
                                    )}
                                    <tr key={day}>
                                      <td>
                                        {reqShift && (reqShift.start_1 > reqShift.end_1 || reqShift.isOvernightShift)
                                          ? getDayRange(day)
                                          : t(`common:days.${day}`)}
                                      </td>
                                      <td
                                        style={{
                                          float: 'right',
                                          width: '40%',
                                          background: /* istanbul ignore next */ isAvailabilitiesMatchedToRequisition(
                                            selectedRequisition?.shifts?.[day],
                                            day,
                                          )
                                            ? onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <td style={{ float: 'left', padding: 0 }}>
                                          {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame ? (
                                            <Badge
                                              background={
                                                isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                  ? '#e9f0ff'
                                                  : '#ffe9e9'
                                              }
                                            >
                                              <Flex>
                                                {reqShift ? (
                                                  <Text fontSize={15} fontWeight="normal">
                                                    {`${moment(reqShift.start_1, ['HH:mm']).format(
                                                      'LT',
                                                    )} - ${moment(reqShift.end_1, ['HH:mm']).format('LT')}`}
                                                  </Text>
                                                ) : (
                                                  <Text>---</Text>
                                                )}
                                              </Flex>
                                            </Badge>
                                          ) : (
                                            <Flex paddingRight={2}>
                                              {reqShift ? (
                                                <Text>
                                                  {`${moment(reqShift.start_1, ['HH:mm']).format('LT')} - ${moment(
                                                    reqShift.end_1,
                                                    ['HH:mm'],
                                                  ).format('LT')}`}
                                                </Text>
                                              ) : (
                                                <Text>---</Text>
                                              )}
                                            </Flex>
                                          )}
                                        </td>

                                        {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                            <td style={{ float: 'left', padding: 0 }}>
                                              <Badge
                                                background={
                                                  isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                    ? '#e9f0ff'
                                                    : '#ffe9e9'
                                                }
                                              >
                                                <Flex>
                                                  <Text fontSize={15} fontWeight="normal">
                                                    {`${moment(reqShift?.start_2, ['HH:mm']).format(
                                                      'LT',
                                                    )} - ${moment(reqShift?.end_2, ['HH:mm']).format('LT')}`}
                                                  </Text>
                                                </Flex>
                                              </Badge>
                                            </td>
                                          )}
                                      </td>
                                      {reqShift &&
                                      reqShift?.start_1 < reqShift?.end_1 &&
                                      _isUndefined(reqShift.isOvernightShift) ? (
                                        <td style={{ float: 'right', width: '40%' }}>
                                          {availability && availability[day] ? (
                                            <Flex w="100%">
                                              <Text w="35%">{moment(availability[day].start_1, ['HH:mm']).format('LT')}</Text>
                                              <Text w="5%" mr="6px">
                                                -
                                              </Text>
                                              <Text w="35%">{moment(availability[day].end_1, ['HH:mm']).format('LT')}</Text>
                                            </Flex>
                                          ) : (
                                            /* istanbul ignore next */ <Text>---</Text>
                                          )}
                                        </td>
                                      ) : (
                                        <td style={{ float: 'right', width: '40%' }}>
                                          {candidateNightAvailabilities && candidateNightAvailabilities[day] ? (
                                            <Flex w="100%">
                                              <Text w="35%">
                                                {moment(candidateNightAvailabilities[day].start_1, ['HH:mm']).format('LT')}
                                              </Text>
                                              <Text w="5%" mr="6px">
                                                -
                                              </Text>
                                              <Text w="35%">
                                                {moment(candidateNightAvailabilities[day].end_1, ['HH:mm']).format('LT')}
                                              </Text>
                                            </Flex>
                                          ) : (
                                            /* istanbul ignore next */ <Text>---</Text>
                                          )}
                                        </td>
                                      )}
                                    </tr>

                                    {isAnotherRequisitionOnDifferentDayAvailable && !isBothRequisitionOnDifferentDay && (
                                      <tr>
                                        <td>{getDayRange(day)}</td>

                                        <td
                                          style={{
                                            float: 'right',
                                            width: '40%',
                                            background: /* istanbul ignore next */ isAvailabilitiesMatchedToRequisition(
                                              selectedRequisition?.shifts?.[day],
                                              day,
                                            )
                                              ? onGetColorByIndex(index)
                                              : colors.red[100],
                                            borderTop: '1px solid #edf2f7',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          <Flex>
                                            <Text fontSize={16}>
                                              {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(reqShift?.end_2, [
                                                'HH:mm',
                                              ]).format('LT')}`}
                                            </Text>
                                          </Flex>
                                        </td>
                                      </tr>
                                    )}
                                  </Fragment>
                                );
                              })}
                            </table>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box mt="16px">
                        <Text fontSize="14px" fontWeight="700" mb="12px">
                          {t('shifts')}
                        </Text>
                        <Flex mt="16px" alignItems="baseline" css={tableStyle} style={{ display: 'flex' }}>
                          <Box w="50%">
                            <table>
                              {weekDays.map((day, index) => {
                                const reqShift = selectedRequisition?.shifts && selectedRequisition?.shifts[day];
                                const isAnotherRequisitionOnSameDayAvailable =
                                  reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 < reqShift.end_2;

                                const isAnotherRequisitionOnDifferentDayAvailable =
                                  reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 >= reqShift.end_2;

                                const isBothRequisitionOnDifferentDay =
                                  reqShift &&
                                  reqShift.start_2 &&
                                  reqShift.end_2 &&
                                  reqShift.start_2 >= reqShift.end_2 &&
                                  reqShift.start_1 >= reqShift.end_1;

                                const isFirstRequisitionOnDifferentDayAndSecondOnSame =
                                  reqShift &&
                                  reqShift.start_2 &&
                                  reqShift.end_2 &&
                                  reqShift.start_2 < reqShift.end_2 &&
                                  reqShift.start_1 >= reqShift.end_1;
                                return (
                                  <Flex direction="column" key={day}>
                                    {isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                      <tr
                                        style={{
                                          background: isAvailabilitiesMatchedToRequisition(
                                            selectedRequisition?.shifts?.[day],
                                            day,
                                          )
                                            ? /* istanbul ignore next */ onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <td>{t(`common:days.${day}`)}</td>

                                        <td style={{ float: 'right' }}>
                                          <Flex>
                                            <Text fontSize={16}>
                                              {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(reqShift?.end_2, [
                                                'HH:mm',
                                              ]).format('LT')}`}
                                            </Text>
                                          </Flex>
                                        </td>
                                      </tr>
                                    )}

                                    <tr
                                      style={{
                                        background: isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                          ? /* istanbul ignore next */ onGetColorByIndex(index)
                                          : colors.red[100],
                                        borderTop: '1px solid #edf2f7',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <td>
                                        {reqShift && (reqShift.start_1 > reqShift.end_1 || reqShift.isOvernightShift)
                                          ? getDayRange(day)
                                          : t(`common:days.${day}`)}
                                      </td>

                                      <td style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <td style={{ float: 'right', padding: 0 }}>
                                          {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame ? (
                                            <Badge
                                              background={
                                                isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                  ? '#e9f0ff'
                                                  : '#ffe9e9'
                                              }
                                            >
                                              <Flex>
                                                {reqShift ? (
                                                  <Text fontSize={15} fontWeight="normal">
                                                    {`${moment(reqShift.start_1, ['HH:mm']).format(
                                                      'LT',
                                                    )} - ${moment(reqShift.end_1, ['HH:mm']).format('LT')}`}
                                                  </Text>
                                                ) : (
                                                  <Text>---</Text>
                                                )}
                                              </Flex>
                                            </Badge>
                                          ) : (
                                            <Flex paddingRight={2}>
                                              {reqShift ? (
                                                <Text>
                                                  {`${moment(reqShift.start_1, ['HH:mm']).format('LT')} - ${moment(
                                                    reqShift.end_1,
                                                    ['HH:mm'],
                                                  ).format('LT')}`}
                                                </Text>
                                              ) : (
                                                <Text>---</Text>
                                              )}
                                            </Flex>
                                          )}
                                        </td>

                                        {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                            <td style={{ float: 'right' }}>
                                              <Badge
                                                background={
                                                  isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                    ? '#e9f0ff'
                                                    : '#ffe9e9'
                                                }
                                              >
                                                <Flex>
                                                  <Text fontSize={15} fontWeight="normal">
                                                    {`${moment(reqShift?.start_2, ['HH:mm']).format(
                                                      'LT',
                                                    )} - ${moment(reqShift?.end_2, ['HH:mm']).format('LT')}`}
                                                  </Text>
                                                </Flex>
                                              </Badge>
                                            </td>
                                          )}
                                      </td>
                                    </tr>

                                    {isAnotherRequisitionOnDifferentDayAvailable && !isBothRequisitionOnDifferentDay && (
                                      <tr
                                        style={{
                                          background: isAvailabilitiesMatchedToRequisition(
                                            selectedRequisition?.shifts?.[day],
                                            day,
                                          )
                                            ? /* istanbul ignore next */ onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <td>{getDayRange(day)}</td>

                                        <td style={{ float: 'right' }}>
                                          <Flex>
                                            <Text fontSize={16}>
                                              {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(reqShift?.end_2, [
                                                'HH:mm',
                                              ]).format('LT')}`}
                                            </Text>
                                          </Flex>
                                        </td>
                                      </tr>
                                    )}
                                  </Flex>
                                );
                              })}
                            </table>
                          </Box>

                          <Box
                            w="50%"
                            ml={
                              /* istanbul ignore next */
                              selectedRequisition?.shifts && !_isEmpty(selectedRequisition?.shifts) ? '12px' : '0px'
                            }
                          >
                            <table>
                              {weekEnds.map((day, index) => {
                                const reqShift = selectedRequisition?.shifts && selectedRequisition?.shifts[day];

                                const isAnotherRequisitionOnSameDayAvailable =
                                  reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 < reqShift.end_2;

                                const isAnotherRequisitionOnDifferentDayAvailable =
                                  reqShift && reqShift.start_2 && reqShift.end_2 && reqShift.start_2 >= reqShift.end_2;

                                const isBothRequisitionOnDifferentDay =
                                  reqShift &&
                                  reqShift.start_2 &&
                                  reqShift.end_2 &&
                                  reqShift.start_2 > reqShift.end_2 &&
                                  reqShift.start_1 > reqShift.end_1;

                                const isFirstRequisitionOnDifferentDayAndSecondOnSame =
                                  reqShift &&
                                  reqShift.start_2 &&
                                  reqShift.end_2 &&
                                  reqShift.start_2 < reqShift.end_2 &&
                                  reqShift.start_1 >= reqShift.end_1;
                                return (
                                  <Flex direction="column" key={day}>
                                    {isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                      <tr
                                        style={{
                                          background: isAvailabilitiesMatchedToRequisition(
                                            selectedRequisition?.shifts?.[day],
                                            day,
                                          )
                                            ? /* istanbul ignore next */ onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <td>{t(`common:days.${day}`)}</td>

                                        <td style={{ float: 'right' }}>
                                          <Flex>
                                            <Text fontSize={16}>
                                              {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(reqShift?.end_2, [
                                                'HH:mm',
                                              ]).format('LT')}`}
                                            </Text>
                                          </Flex>
                                        </td>
                                      </tr>
                                    )}

                                    <tr
                                      style={{
                                        background: isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                          ? /* istanbul ignore next */ onGetColorByIndex(index)
                                          : colors.red[100],
                                        borderTop: '1px solid #edf2f7',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <td>
                                        {reqShift && (reqShift.start_1 > reqShift.end_1 || reqShift.isOvernightShift)
                                          ? getDayRange(day)
                                          : t(`common:days.${day}`)}
                                      </td>

                                      <td style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                        <td style={{ float: 'right', padding: 0 }}>
                                          {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame ? (
                                            <Badge
                                              background={
                                                isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                  ? '#e9f0ff'
                                                  : '#ffe9e9'
                                              }
                                            >
                                              <Flex>
                                                {reqShift ? (
                                                  <Text fontSize={15} fontWeight="normal">
                                                    {`${moment(reqShift.start_1, ['HH:mm']).format(
                                                      'LT',
                                                    )} - ${moment(reqShift.end_1, ['HH:mm']).format('LT')}`}
                                                  </Text>
                                                ) : (
                                                  <Text>---</Text>
                                                )}
                                              </Flex>
                                            </Badge>
                                          ) : (
                                            <Flex paddingRight={2}>
                                              {reqShift ? (
                                                <Text>
                                                  {`${moment(reqShift.start_1, ['HH:mm']).format('LT')} - ${moment(
                                                    reqShift.end_1,
                                                    ['HH:mm'],
                                                  ).format('LT')}`}
                                                </Text>
                                              ) : (
                                                <Text>---</Text>
                                              )}
                                            </Flex>
                                          )}
                                        </td>

                                        {(isAnotherRequisitionOnSameDayAvailable || isBothRequisitionOnDifferentDay) &&
                                          !isFirstRequisitionOnDifferentDayAndSecondOnSame && (
                                            <td style={{ float: 'right' }}>
                                              <Badge
                                                background={
                                                  isAvailabilitiesMatchedToRequisition(selectedRequisition?.shifts?.[day], day)
                                                    ? '#e9f0ff'
                                                    : '#ffe9e9'
                                                }
                                              >
                                                <Flex>
                                                  <Text fontSize={15} fontWeight="normal">
                                                    {`${moment(reqShift?.start_2, ['HH:mm']).format(
                                                      'LT',
                                                    )} - ${moment(reqShift?.end_2, ['HH:mm']).format('LT')}`}
                                                  </Text>
                                                </Flex>
                                              </Badge>
                                            </td>
                                          )}
                                      </td>
                                    </tr>

                                    {isAnotherRequisitionOnDifferentDayAvailable && !isBothRequisitionOnDifferentDay && (
                                      <tr
                                        style={{
                                          background: isAvailabilitiesMatchedToRequisition(
                                            selectedRequisition?.shifts?.[day],
                                            day,
                                          )
                                            ? /* istanbul ignore next */ onGetColorByIndex(index)
                                            : colors.red[100],
                                          borderTop: '1px solid #edf2f7',
                                          cursor: 'pointer',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <td>{getDayRange(day)}</td>

                                        <td style={{ float: 'right' }}>
                                          <Flex>
                                            <Text fontSize={16}>
                                              {`${moment(reqShift?.start_2, ['HH:mm']).format('LT')} - ${moment(reqShift?.end_2, [
                                                'HH:mm',
                                              ]).format('LT')}`}
                                            </Text>
                                          </Flex>
                                        </td>
                                      </tr>
                                    )}
                                  </Flex>
                                );
                              })}
                            </table>
                          </Box>
                        </Flex>
                      </Box>
                    )}
                    <Box css={tableStyle} w="60%">
                      {
                        /* istanbul ignore next */ (selectedRequisition?.minHours ||
                          selectedRequisition?.maxHours ||
                          selectedRequisition?.guaranteedHours ||
                          selectedRequisition?.guaranteedHours === 0 ||
                          selectedRequisition?.minHours === 0 ||
                          selectedRequisition?.maxHours === 0) && (
                          <Text fontSize="14px" fontWeight="700" mt="12px" mb="6px">
                            {t('contractedHours')}
                          </Text>
                        )
                      }
                      <table style={{ width: '50%' }}>
                        {(selectedRequisition?.minHours || selectedRequisition?.minHours === 0) && (
                          <tr>
                            <td>{t('minimum')}</td>
                            <td style={{ textAlign: 'end' }}>
                              {selectedRequisition?.minHours.toLocaleString(i18n.language, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        )}
                        {(selectedRequisition?.maxHours || selectedRequisition?.maxHours === 0) && (
                          <tr>
                            <td>{t('maximum')}</td>
                            <td style={{ textAlign: 'end' }}>
                              {selectedRequisition?.maxHours.toLocaleString(i18n.language, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        )}
                        {(selectedRequisition?.guaranteedHours || selectedRequisition?.guaranteedHours === 0) && (
                          <tr>
                            <td>{t('guaranteedHours')}</td>
                            <td style={{ textAlign: 'end' }}>
                              {selectedRequisition?.guaranteedHours.toLocaleString(i18n.language, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        )}
                      </table>
                    </Box>
                    <Text mt="12px" fontSize="14px">
                      {selectedRequisition?.note}
                    </Text>
                  </Box>
                ) : (
                  <Box m={10} fontSize="16px" fontWeight="700">
                    {t('couldNotLoadRequisition')}
                  </Box>
                )}
              </Box>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  );
};
