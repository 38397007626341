import React, { ChangeEvent } from 'react';
import { Box, Text, Checkbox } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';

export const KioskCandidatesView = (): JSX.Element => {
  const { t } = useTranslation();
  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();
  const onHandleKiosk = (isKiosk: boolean) => {
    searchAndSortTypes.kioskEnabled = isKiosk;
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...searchAndSortTypes });
  };
  return (
    <Box>
      <Checkbox
        data-testid="kioskCheckBox"
        isChecked={searchAndSortTypes.kioskEnabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onHandleKiosk(e.target.checked)}
      >
        <Text
          px={3}
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
          }}
        >
          {t('searchAndSort.kiosk')}
        </Text>
      </Checkbox>
    </Box>
  );
};
