import React from 'react';
import { Box, Flex, MenuButton, MenuButtonProps, Text, useColorMode } from '@chakra-ui/core';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import colors from '../../../../../styles/colors';
import CandidatePickerItemPresenter from './item/candidatePickerItemPresenter';
import { PositionState } from '../../../../common';

export type CandidatesDropDownProps = MenuButtonProps & {
  candidate: CandidatePickerItemPresenter | null;
  isOpen: boolean;
};

const CandidatesDropdownButton = ({
  candidate,
  isOpen = false,
  className = '',
  ...rest
}: CandidatesDropDownProps): JSX.Element => {
  const { t } = useTranslation('candidateStatus');
  const { colorMode } = useColorMode();
  const mainTextColor = colorMode === 'dark' ? colors.white : colors.black;
  const secondaryTextColor = colorMode === 'dark' ? colors.gray['300'] : colors.gray['500'];

  const onGetCandidateStatus = () => {
    if (candidate) {
      if (candidate?.candidate.deactivated) {
        return t('DEACTIVATED');
      }
      if (candidate?.candidate.hireDetailsStatus === 'inProgress') {
        return t('HIRE_FORM_IN_PROGRESS');
      }
      if (candidate?.candidate.hireDetailsStatus === 'requested' || candidate?.candidate.hireDetailsStatus === 'needsAction') {
        return t(`HIRE_FORM_REQUESTED`);
      }
      if (candidate?.candidate.hireDetailsStatus === 'helpRequested') {
        return t(`HIRE_FORM_HELP_REQUESTED`);
      }

      return t(`${candidate.status}`);
    }
    return '';
  };

  return (
    <MenuButton
      _disabled={{
        opacity: 0.5,
        cursor: 'not-allowed',
        boxShadow: 'none',
      }}
      px={3}
      py={2}
      rounded="lg"
      data-testid="CandidatesDropDownButton"
      className={isOpen ? `is-opened ${className}` : className}
      {...rest}
    >
      <Flex alignItems="center">
        {candidate ? (
          <>
            <Flex
              flex={1}
              direction="column"
              minW={{ base: '8rem', md: '10rem', lg: '12rem' }}
              alignItems="flex-start"
              data-testid="CandidatesDropDownButtonInner"
            >
              <Text
                fontSize="xs"
                textTransform="uppercase"
                lineHeight="shorter"
                isTruncated
                maxW={{ base: '11rem', md: '12rem', lg: '18rem' }}
                opacity={candidate?.candidate.deactivated ? '0.6' : '1'}
              >
                {candidate.businessName}
              </Text>
              <Text
                fontSize="16px"
                lineHeight="shorter"
                fontWeight="bold"
                color="blue.500"
                isTruncated
                maxW={{ base: '11rem', md: '12rem', lg: '18rem' }}
                opacity={candidate?.candidate.deactivated ? '0.6' : '1'}
              >
                {candidate.positionName}
              </Text>
              <Text
                fontSize="14px"
                lineHeight="shorter"
                fontWeight="bold"
                color={mainTextColor}
                isTruncated
                maxW={{ base: '11rem', md: '12rem', lg: '18rem' }}
                alt={candidate.businessCompleteAddress}
                opacity={candidate?.candidate.deactivated ? '0.6' : '1'}
              >
                {candidate.businessCompleteAddress}
              </Text>
              <Flex alignItems="center">
                <PositionState
                  status={candidate.status}
                  isDeactivated={candidate?.candidate.deactivated}
                  style={{ margin: '0 0.25rem 0 0' }}
                />
                <Text
                  fontSize="xs"
                  color={secondaryTextColor}
                  lineHeight="shorter"
                  pt={1}
                  maxW={{ base: '11rem', md: '12rem', lg: '18rem' }}
                >
                  {onGetCandidateStatus()}
                </Text>
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex flex={1} direction="column" />
        )}
        <Box ml={2} marginRight="6px" color={secondaryTextColor} opacity={candidate?.candidate.deactivated ? '0.6' : '1'}>
          {isOpen ? <IoIosArrowUp size={16} /> : <IoIosArrowDown size={16} />}
        </Box>
      </Flex>
    </MenuButton>
  );
};

export default CandidatesDropdownButton;
