import {
  Button,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../styles/customTheme';
import { IoSearch } from 'react-icons/io5';
import { MdChevronLeft } from 'react-icons/md';
import { formBuilderSchemas, pageElementSchema } from './formBuilderSchema';
import FormBuilderTags from './FormBuilderTags';
import { SectionSchema } from '../HireformView';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

interface FormBuilderComponentsProps {
  formSchema: SectionSchema[];
  setFormSchema: (schema: SectionSchema[]) => void;
}
export default function FormBuilderComponents({ formSchema, setFormSchema }: FormBuilderComponentsProps) {
  const history = useHistory();
  return (
    <Stack
      flex="0 0 25%"
      spacing={theme.space[4]}
      backgroundColor={theme.colors.white}
      px={theme.space[4]}
      py={theme.space[6]}
      minHeight="0"
      height="100%"
      overflowY="auto"
      overflowX="hidden"
    >
      <HStack spacing={theme.space[4]}>
        <Button
          size="sm"
          variant="solid"
          paddingX={1}
          borderRadius="50%"
          colorScheme="gray"
          onClick={() => history.replace('/hire-form-template')}
        >
          <MdChevronLeft fontSize={24} />
        </Button>
        <InputGroup variant="outline">
          <Input type="search" placeholder="Search Components" />
          <InputRightElement paddingX={2} children={<IoSearch size={16} color="#2D3748" />} />
        </InputGroup>
      </HStack>
      <FormBuilderTabs formSchema={formSchema} setFormSchema={setFormSchema} />
    </Stack>
  );
}

interface FormBuilderTabsProps extends FormBuilderComponentsProps {}

function FormBuilderTabs({ formSchema, setFormSchema }: FormBuilderTabsProps) {
  return (
    <Tabs isFitted size="sm">
      <TabList mb="1em">
        <Tab panelId="basic">Basic</Tab>
        <Tab panelId="advanced">Advanced</Tab>
        <Tab panelId="tags">Tags</Tab>
      </TabList>
      <TabPanels>
        <TabPanel id="basic" px={0}>
          <Stack spacing={theme.space[4]}>
            <Grid gridTemplateColumns="repeat(auto-fit, minmax(136px,1fr))" gridAutoRows="69px" gridGap={theme.space[3]}>
              {(Object.keys(formBuilderSchemas) as (keyof typeof formBuilderSchemas)[]).map((inputType) => {
                const schema = formBuilderSchemas[inputType];
                const { icon: Icon, label, defaultValue } = schema;
                return (
                  <GridItem
                    key={inputType}
                    draggable
                    borderRadius={theme.radii.md}
                    backgroundColor="#F3F6F9"
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={() => {
                      const schemas = [...formSchema];
                      const lastSection = schemas[schemas.length - 1];
                      if (lastSection?.section) lastSection.fields.push({ ...defaultValue, id: uuidv4(), timestamp: Date.now() });
                      setFormSchema(schemas);
                    }}
                    onDragStart={(e) => {
                      e.dataTransfer.setData('type', inputType);
                      e.dataTransfer.effectAllowed = 'copy';
                      e.dataTransfer.dropEffect = 'copy';
                    }}
                    gridColumn={Object.keys(formBuilderSchemas).length > 1 ? 'auto' : '1 / -1'}
                  >
                    <Stack spacing={2} align="center">
                      <Icon size={24} />
                      <Text fontWeight={700} fontSize="sm" lineHeight="21px" margin={0}>
                        {label}
                      </Text>
                    </Stack>
                  </GridItem>
                );
              })}
            </Grid>
            <Stack>
              <Text textAlign="right" fontSize="sm">
                Page Elements
              </Text>
              <Grid gridTemplateColumns="repeat(auto-fit, minmax(136px,1fr))" gridAutoRows="69px" gridGap={theme.space[3]}>
                {Object.entries(pageElementSchema).map(([key, schema]) => {
                  const { icon: Icon, label } = schema;
                  return (
                    <GridItem
                      key={key}
                      draggable
                      borderRadius={theme.radii.md}
                      backgroundColor="#F3F6F9"
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center"
                      cursor="pointer"
                      onClick={() => {
                        const newFormSchema = [...formSchema];
                        newFormSchema.push({ section: 'section_' + (formSchema.length + 1), fields: [] });
                        setFormSchema(newFormSchema);
                      }}
                      onDragStart={(e) => {
                        e.dataTransfer.setData('type', key);
                        e.dataTransfer.effectAllowed = 'copy';
                        e.dataTransfer.dropEffect = 'copy';
                      }}
                      gridColumn={Object.keys(pageElementSchema).length > 1 ? 'auto' : '1 / -1'}
                    >
                      <Stack spacing={2} align="center">
                        <Icon size={24} />
                        <Text fontWeight={700} fontSize="sm" lineHeight="21px" margin={0}>
                          {label}
                        </Text>
                      </Stack>
                    </GridItem>
                  );
                })}
              </Grid>
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel id="advanced" px={0}>
          Advanced
        </TabPanel>
        <TabPanel id="tags" px={0}>
          <FormBuilderTags />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
