import { useTranslation } from 'react-i18next';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { SeekerNote } from '../../../../../firebase/firestore/documents/candidate';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import { Collections, SubCollections } from '../../../../../firebase/firestore/collections';
import { Query } from '../../../../../firebase/firestore/query/query';
import { useSubCollectionQuerySnapshot } from '../../../../../firebase/firestore/hooks';
import { PositionNote } from './positionNote';
import { getTranslatedValue } from '../../../../../utils/localizedString';
import { Note } from './note';
import useRecruiter from './useRecruiter';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';

const useCandidateNote = (): PositionNote | undefined => {
  const { i18n } = useTranslation();

  const { selectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const candidate = selectedCandidate || selectedEngagedCandidate;

  const { currentUserProfile } = useCurrentUserProfile();
  const recruiters = useRecruiter();

  const seekerId = candidate?.seeker ?? '';
  const positionId = candidate?.position ?? '';
  const accountId = currentUserProfile?.account ?? '';
  const recruiterId = currentUserProfile?.id ?? '';

  const seekerNote: SeekerNote[] | undefined = useSubCollectionQuerySnapshot<SeekerNote>(
    Collections.ACCOUNT,
    accountId,
    SubCollections.SEEKER_NOTES,
    [
      Query.field('seeker').equals(seekerId),
      Query.field('recruiter').equals(recruiterId),
      Query.field('position').equals(positionId),
    ],
  );

  if (seekerNote && candidate && recruiters) {
    const jobTitle = getTranslatedValue(candidate.jobTitle, i18n.language);
    const candidateRating = candidate.recruiterInterviewReview?.[recruiterId]?.rating;
    const candidateStatus = candidate.computedStatus?.status;
    const { businessName } = candidate;

    const notes = _map(seekerNote, (n) => {
      const recruiter = _find(recruiters, { id: n.recruiter });
      const recruiterName = `${recruiter?.firstName} ${recruiter?.lastName}`;
      const recruiterIdInNote = recruiter ? recruiter?.id : /* istanbul ignore next */ recruiterId;
      const recruiterRating = candidate.recruiterInterviewReview?.[recruiterIdInNote]?.rating;
      return new Note(n, recruiterName, recruiter?.id === recruiterId, recruiterIdInNote, recruiterRating, n.type);
    });

    return new PositionNote(notes, positionId, jobTitle, businessName, candidateStatus, candidateRating);
  }
  return undefined;
};

export default useCandidateNote;
