/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { Redirect } from 'react-router-dom';
import { Header } from '../../app/topBar/Header';
import { ANIMATED_VARIANTS, PATH_MAIN } from '../../routes/constants';
import { SupportViewContextProvider } from './SupportViewContext';
import { SupportView } from './SupportView';
import { useStoreState } from '../../models/hooks';
import { RemoteConfigKey } from '../../firebase/remoteConfig';

const MotionBox = motion.custom(Box);

export const SupportLayout = (): JSX.Element => {
  const isSupportDocumentationEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_SUPPORT_DOCUMENTATION];
  });

  if (!isSupportDocumentationEnabled) {
    return <Redirect to={PATH_MAIN} />;
  }
  return (
    <React.Fragment>
      <MotionBox data-testid="SupportView" initial="initial" animate="in" exit="out" variants={ANIMATED_VARIANTS}>
        <SupportViewContextProvider>
          <Header data-testid="Header" showMenu />
          <SupportView />
        </SupportViewContextProvider>
      </MotionBox>
    </React.Fragment>
  );
};
