interface Tooltip {
  text: string;
}

interface SubArc {
  limit: number;
  color: string; // Adjust the type if colors are defined in a specific type
  tooltip: Tooltip;
}

export interface GaugeNeedleType {
  title: string;
  value: number;
  minValue: number;
  maxValue: number;
  subArc: SubArc[];
}

export interface ExtendedGaugeNeedleType extends GaugeNeedleType {
  atRisk: number;
  positionsImpacted: number;
  unfilled: number;
}

export enum DateSelectRange {
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
}
