import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, LabelList } from 'recharts';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Box } from '@chakra-ui/core';
import colors from '../../../styles/colors';
import { AgedRequisitionDetails } from '../../../firebase/firestore/documents/managementOverview';

interface AgedRequisitionBarChartPropTypes {
  agedRequisitionDetails: AgedRequisitionDetails;
  setTotalAgedRequisitions: (total: string) => void;
  isDarkMode: boolean;
}

// istanbul ignore next
export const CustomTooltip: React.FC<{
  active?: boolean;
  payload?: Array<{ value: number }>;
  label?: string;
}> = ({ active, payload, label }) => {
  const { t } = useTranslation('managementOverview');

  if (active && payload && payload.length) {
    const { value } = payload[0];
    return (
      <Box
        color={colors.white}
        backgroundColor="#333"
        fontWeight="bold"
        className="custom-tooltip"
        borderRadius="md"
        padding={4}
        border="1px solid white"
      >
        <p>{label}</p>
        <p>
          {t('hiringOverview.value')}
          {value}
        </p>
      </Box>
    );
  }
  return null;
};

function transformInputToBarChartData(input: AgedRequisitionDetails, t: TFunction) {
  const entries = Object.entries(input);
  return entries.map(([key, value], index) => {
    const isLast = index === entries.length - 1;
    const name = isLast
      ? `${key.split(' ')[0]} ${t('hiringOverview.weekAndMore')}`
      : `${key.split(' ')[0]} ${t('hiringOverview.weekLabel')}`;
    return {
      name,
      value,
    };
  });
}

function AgedRequisitionBarChart({
  agedRequisitionDetails,
  setTotalAgedRequisitions,
  isDarkMode,
}: AgedRequisitionBarChartPropTypes) {
  const { t } = useTranslation('managementOverview');

  const [dataForBarchart, setDataForBarchart] = useState<
    {
      name: string;
      value: number;
    }[]
  >();

  useEffect(() => {
    const transformedData = transformInputToBarChartData(agedRequisitionDetails, t);
    const total = transformedData.reduce((accumulator, current) => {
      return accumulator + current.value;
    }, 0);

    setTotalAgedRequisitions(total.toString());

    setDataForBarchart(transformedData);
  }, [agedRequisitionDetails, setTotalAgedRequisitions, t]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={dataForBarchart}
        margin={{
          top: 15,
          right: 30,
          left: 20,
          bottom: 25,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke={isDarkMode ? '#333' : '#fff'} />
        <XAxis dataKey="name" stroke={isDarkMode ? '#ccc' : '#000'} interval={0} style={{ fontSize: '12px' }} />
        <YAxis stroke={isDarkMode ? '#ccc' : '#000'}>
          <Label
            angle={-90}
            position="insideLeft"
            style={{ textAnchor: 'middle', fill: isDarkMode ? colors.white : colors.black }}
          >
            {t('hiringOverview.unfilledReqs')}
          </Label>
        </YAxis>
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="value" fill="#ff9900">
          <LabelList
            dataKey="value"
            position="top"
            style={{ fill: isDarkMode ? colors.white : colors.black, fontWeight: 'bold' }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default AgedRequisitionBarChart;
