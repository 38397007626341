import { Action, action } from 'easy-peasy';
import { AvailableBookingWithPosition } from '../firebase/firestore/documents/appointmentAvaiability';
import { Candidate } from '../firebase/firestore/documents/candidate';

export interface RatingFor {
  seekerId: string;
  positionId: string;
  name: string;
  selectedCandidate: Candidate;
}

export type InterViewStoreModel = {
  interview: InterviewModel;
};

export interface InterviewModel {
  eventDetail: AvailableBookingWithPosition | undefined;
  interviewStarted: boolean;
  ratingFor: RatingFor | undefined;

  setInterviewEvent: Action<InterviewModel, AvailableBookingWithPosition>;
  clearInterviewEvent: Action<InterviewModel>;

  setInterviewStarted: Action<InterviewModel, boolean>;
  clearInterviewStarted: Action<InterviewModel>;
  updateRatingFor: Action<InterviewModel, RatingFor>;
  clearRatingFor: Action<InterviewModel>;
}

export const interviewStoreModel: InterviewModel = {
  eventDetail: undefined,
  interviewStarted: false,
  ratingFor: undefined,

  setInterviewEvent: action((state, selectedEvent) => {
    state.eventDetail = { ...state.eventDetail, ...selectedEvent };
  }),

  clearInterviewEvent: action((state) => {
    state.eventDetail = undefined;
  }),

  setInterviewStarted: action((state, value) => {
    state.interviewStarted = value;
  }),

  clearInterviewStarted: action((state) => {
    state.interviewStarted = false;
  }),

  updateRatingFor: action((state, value) => {
    state.ratingFor = value;
  }),

  clearRatingFor: action((state) => {
    state.ratingFor = undefined;
  }),
};
