import { css } from '@emotion/core';
import colors from '../../../../../styles/colors';

export const questionsViewWrapper = css`
  grid-column: midSide / rightSide;
  grid-row: sub2 / bottom;
  overflow-y: visible;
  background-color: ${colors.white};

  .chakra-ui-dark & {
    background-color: ${colors.black};
  }

  .answeredBorder {
    border-left: 4px solid #69f0ae;
  }

  .unansweredBorder {
    border-left: 4px solid #90a4ae;
  }
`;
