/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { motion } from 'framer-motion';
import { Box } from '@chakra-ui/core';
import { MeetContextProvider } from './MeetContext';
import { MeetRoom } from './MeetRoom';

const MotionBox = motion.custom(Box);

export const MeetView = (): JSX.Element => {
  // const isMeetEnabled = useStoreState((state) => {
  //   return state.remoteConfig[RemoteConfigKey.ENABLE_MEET_FEATURE];
  // });

  // if (!isMeetEnabled) {
  //   return <Redirect to={PATH_MAIN} />;
  // }

  return (
    <MotionBox initial="initial" data-testid="MeetView">
      <MeetContextProvider>
        <MeetRoom />
      </MeetContextProvider>
    </MotionBox>
  );
};
