import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import { useDominantSpeaker } from './useDominantSpeaker';
import { useMeetContext } from '../../MeetContext';

export const useParticipants = (): RemoteParticipant[] => {
  const { room } = useMeetContext();
  const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState<RemoteParticipant[]>(Array.from(room?.participants.values() ?? []));

  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants((prevParticipants) => [
        dominantSpeaker as RemoteParticipant,
        ...prevParticipants.filter((p) => {
          return p !== dominantSpeaker;
        }),
      ]);
    }
  }, [dominantSpeaker]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      const onParticipantConnected = (participant: RemoteParticipant) => {
        setParticipants((prevParticipants) => [...prevParticipants, participant]);
      };

      const onParticipantDisconnected = (participant: RemoteParticipant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => {
            return p !== participant;
          }),
        );
      };

      room.on('participantConnected', onParticipantConnected);
      room.on('participantDisconnected', onParticipantDisconnected);

      return () => {
        room.off('participantConnected', onParticipantConnected);
        room.off('participantDisconnected', onParticipantDisconnected);
      };
    }
  }, [room]);

  return participants;
};
