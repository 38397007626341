/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { Participant, Room } from 'twilio-video';
import { css } from '@emotion/core';
import { ParticipantView } from '../../../meet/ParticipantView';
import { useParticipants } from '../../../meet/twilio/hooks/useParticipants';
import { useDominantSpeaker } from '../../../meet/twilio/hooks/useDominantSpeaker';
import { VideoRooms } from '../../../../firebase/firestore/documents/videoRooms';
import { useMainParticipant } from '../../../meet/twilio/hooks/useMainParticipant';

type ParticipantListProps = {
  room: Room;
  // eslint-disable-next-line react/require-default-props
  roomInfo?: VideoRooms;
};

const hiddenStyle = css`
  height: 0;
  width: 0;
  overflow: hidden;
`;

export const InterviewParticipantList = ({ room, roomInfo }: ParticipantListProps): JSX.Element => {
  const { localParticipant } = room;
  const participants = useParticipants();
  const dominantSpeaker = useDominantSpeaker();
  const mainParticipant = useMainParticipant();
  const roomParticipantWithName = roomInfo ? roomInfo.participants : [];

  const getParticipantName = (identity: string) => {
    return roomParticipantWithName.find((pp) => pp.userId === identity)?.fullName;
  };

  const getParticipantRole = (identity: string) => {
    return !roomParticipantWithName.find((pp) => pp.userId === identity)?.isSeeker;
  };

  const isVideoEnabled = (identity: string) => {
    const isSeeker = getParticipantRole(identity);
    if (!isSeeker) {
      return roomInfo?.seekerVideoEnabled;
    }
    return false;
  };

  const isMicEnabled = (identity: string) => {
    const isSeeker = getParticipantRole(identity);
    if (!isSeeker) {
      return roomInfo?.seekerMicEnabled;
    }
    return false;
  };

  return (
    <Flex className="participant-list interview-participant" data-testid="ParticipantList">
      <ParticipantView
        participant={localParticipant as Participant}
        participantName={getParticipantName(localParticipant.identity)}
        isRecruiter={getParticipantRole(localParticipant.identity)}
      />
      {participants.map((participantData) => (
        <Box
          css={participantData === (dominantSpeaker || mainParticipant) ? /* istanbul ignore next */ hiddenStyle : ''}
          key={participantData.sid}
        >
          <ParticipantView
            isSelected={participantData === dominantSpeaker}
            participant={participantData}
            participantName={getParticipantName(participantData.identity)}
            isRecruiter={getParticipantRole(participantData.identity)}
            isVideoEnabled={isVideoEnabled(participantData.identity)}
            isMicEnabled={isMicEnabled(participantData.identity)}
          />
        </Box>
      ))}
    </Flex>
  );
};
