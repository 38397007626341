/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FormEventHandler } from 'react';
import { Input, InputGroup, InputGroupProps, InputLeftElement, useColorMode, ChakraStyleProps } from '@chakra-ui/core';
import { IoIosSearch } from 'react-icons/io';

import { SearchInputHeightCSS } from './SearchInput.styles';
import colors from '../../../styles/colors';

export type SearchInputProps = InputGroupProps & {
  onChange?: FormEventHandler;
  value?: string | ReadonlyArray<string> | number;
  placeholder?: string;
  inputBoxStyle?: React.CSSProperties;
  _styleOnFocus?: ChakraStyleProps;
};

const SearchInput = ({ onChange, value, placeholder, inputBoxStyle, _styleOnFocus, ...rest }: SearchInputProps): JSX.Element => {
  const { colorMode } = useColorMode();
  const iconColor = inputBoxStyle?.color || colors.gray['400'];
  const borderColor: string = colorMode === 'dark' ? colors.gray['600'] : colors.dividerGray;
  const focusColor: string = colorMode === 'dark' ? colors.gray['400'] : colors.gray['300'];
  return (
    <InputGroup role="group" {...rest}>
      <InputLeftElement css={SearchInputHeightCSS} px={2}>
        <IoIosSearch size={18} color={iconColor} />
      </InputLeftElement>
      <Input
        type="search"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        data-testid="QueryInput"
        rounded="full"
        fontSize="sm"
        css={SearchInputHeightCSS}
        pl={8}
        borderColor={borderColor}
        _focus={_styleOnFocus || { borderColor: focusColor }}
        _hover={{ borderColor }}
        _placeholder={{ color: iconColor }}
        style={inputBoxStyle}
      />
    </InputGroup>
  );
};

export default SearchInput;
