import { TreeNode } from 'react-simple-tree-menu';
import { instanceOfLocalizedString, LocalizedString } from '../../../../firebase/firestore/documents/localizedString';
import { getTranslatedValue } from '../../../../utils/localizedString';
import { EngagedCandidate } from '../../../../firebase/firestore/documents/candidate';

export enum NodeType {
  PARENT,
  CHILD,
  CANDIDATE,
}

export type TreeGroup = TreeNode & {
  key: string;
  type: NodeType;
  item: ParentItemNode | CandidateItemNode;
  nodes?: TreeGroup[];
};

export type ParentItemNode = TreeNode & {
  title: string | LocalizedString;
  childCount: number;
  lastActivity: string;
};

export type CandidateItemNode = TreeNode & {
  currentId: string;
  notificationBadgeValue: number;
  candidate: EngagedCandidate;
  minutesBeforeInactivity: number;
  engagedDate: Date;
  candidateName: string;
  lastActivity: Date;
};

export const getTranslatedTitle = (item: ParentItemNode, lang: string): string => {
  if (instanceOfLocalizedString(item.title)) {
    return getTranslatedValue(item.title, lang);
  }
  return item.title;
};
