/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Box } from '@chakra-ui/core';
import { Dots, TypingIndicatorStyle } from './TypingIndicator.styles';
import { leftBoxVariants } from '../Chat.styles';

const MotionBox = motion.custom(Box);
const TypingIndicator = (): JSX.Element => {
  return (
    <MotionBox data-testid="TypingIndicator" variants={leftBoxVariants} mt={1}>
      <div css={TypingIndicatorStyle}>
        <Dots />
        <Dots />
        <Dots />
      </div>
    </MotionBox>
  );
};

export default TypingIndicator;
