import { Thunk, thunk, Action, action } from 'easy-peasy';
import { AxiosResponse } from 'axios';
import { HttpStoreModel } from '../../../http/models/httpModel';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { PositionManagementController } from '../../../controllers/positionManagementController';
import {
  CreatePositionResponse,
  DeepLinkResponse,
  GetIndustriesResponse,
  GetJobListResponse,
  GetOccupationResponse,
  JobPosition,
  JobPositionToCopy,
  JopPositionResponse,
  PositionListQueryParams,
  PositionListResponse,
  PositionStatus,
} from '../positionManagement/PositionType';
import { TopCandidateBodyDataType, TopCandidateDataType } from '../topCandidates/TopCandidateDataType';
import { Shifts } from '../../../firebase/firestore/documents/requisition';
import { AutomationTypes } from '../positionManagement/createJobs/AutomationTypes';
import { Business as BusinessType } from '../positionManagement/BusinessType';

export type PositionManagementStoreModel = {
  positionManagement: PositionManagementModel;
};

export interface PositionManagementModel {
  currentSelectedActiveBusiness: BusinessType | null;
  setCurrentSelectedActiveBusiness: Action<PositionManagementModel, BusinessType | null>;
  getIndustries: Thunk<PositionManagementModel, null, void, HttpStoreModel, Promise<GetIndustriesResponse>>;
  getJobList: Thunk<PositionManagementModel, null, void, HttpStoreModel, Promise<GetJobListResponse>>;
  getOccupationList: Thunk<PositionManagementModel, null, void, HttpStoreModel, Promise<GetOccupationResponse>>;
  createJobPosition: Thunk<
    PositionManagementModel,
    { accountId: string; jobPositionData: JobPosition },
    void,
    HttpStoreModel,
    Promise<CreatePositionResponse>
  >;
  copyJobPosition: Thunk<
    PositionManagementModel,
    { jobPositionToCopy: JobPositionToCopy },
    void,
    HttpStoreModel,
    Promise<CreatePositionResponse>
  >;
  updateJobPosition: Thunk<
    PositionManagementModel,
    { accountId: string; jobPositionData: JobPosition; positionId: string },
    void,
    HttpStoreModel,
    Promise<CreatePositionResponse>
  >;
  updatePositionStatus: Thunk<
    PositionManagementModel,
    {
      accountId: string;
      businessId: string;
      positionId: string;
      positionStatus: PositionStatus;
      doCancellAppointmentHireOrOffer?: boolean;
    },
    void,
    HttpStoreModel,
    Promise<CreatePositionResponse>
  >;
  updateArchivePosition: Thunk<
    PositionManagementModel,
    { positionId: string },
    void,
    HttpStoreModel,
    Promise<CreatePositionResponse>
  >;
  updateUnArchivePosition: Thunk<
    PositionManagementModel,
    { positionId: string },
    void,
    HttpStoreModel,
    Promise<CreatePositionResponse>
  >;
  updateNoOfOpenings: Thunk<
    PositionManagementModel,
    { accountId: string; businessId: string; positionId: string; noOfOpenings: number },
    void,
    HttpStoreModel,
    Promise<CreatePositionResponse>
  >;
  updatePositionByFollow: Thunk<
    PositionManagementModel,
    { positionId: string },
    void,
    HttpStoreModel,
    Promise<CreatePositionResponse>
  >;
  updatePositionByUnFollow: Thunk<
    PositionManagementModel,
    { positionId: string; recruiterId: string },
    void,
    HttpStoreModel,
    Promise<CreatePositionResponse>
  >;
  getPositionList: Thunk<
    PositionManagementModel,
    { accountId: string; businessId: string; params: PositionListQueryParams },
    void,
    HttpStoreModel,
    Promise<PositionListResponse>
  >;

  getPositionDetail: Thunk<
    PositionManagementModel,
    { accountId: string; businessId: string; positionId: string },
    void,
    HttpStoreModel,
    Promise<JopPositionResponse>
  >;

  getDeepLinkOfPosition: Thunk<
    PositionManagementModel,
    { positionId: string; locale: string },
    void,
    HttpStoreModel,
    Promise<DeepLinkResponse>
  >;

  getRecruiterProfilePicture: Thunk<PositionManagementModel, { accountId: string }, void, HttpStoreModel, Promise<string>>;
  uploadOverviewVideo: Thunk<
    PositionManagementModel,
    { userId: string; positionId: string; overviewVideo: File },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  uploadOverviewThumbnail: Thunk<
    PositionManagementModel,
    { userId: string; positionId: string; overviewThumbnail: File },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  uploadOverviewVideoConfirmCall: Thunk<PositionManagementModel, { positionId: string }, void, HttpStoreModel, Promise<string>>;
  getOverviewVideo: Thunk<
    PositionManagementModel,
    { accountId: string; positionId: string },
    void,
    HttpStoreModel,
    Promise<string>
  >;
  getOverviewThumbnail: Thunk<
    PositionManagementModel,
    { accountId: string; positionId: string },
    void,
    HttpStoreModel,
    Promise<string>
  >;
  removeOverviewVideo: Thunk<PositionManagementModel, { positionId: string }, void, HttpStoreModel, Promise<string>>;

  getTopCandidatesOfPosition: Thunk<
    PositionManagementModel,
    { positionId: string; topCandidateBodyData: TopCandidateBodyDataType },
    void,
    HttpStoreModel,
    Promise<TopCandidateDataType[]>
  >;

  updateContactRequestNoteOfPosition: Thunk<
    PositionManagementModel,
    { positionId: string; seekerId: string; recruiterNotes: string },
    void,
    HttpStoreModel,
    Promise<string>
  >;

  unlock: Thunk<
    PositionManagementModel,
    { positionId: string; seekerId: string; recruiterId: string; modernHireEnable: boolean },
    void,
    HttpStoreModel,
    Promise<string>
  >;

  getShiftIntersection: Thunk<
    PositionManagementModel,
    { positionId: string; seekerId: string; requisitionId: string },
    void,
    HttpStoreModel,
    Promise<Shifts>
  >;

  setPositionAutomation: Thunk<
    PositionManagementModel,
    { positionId: string; automationType: AutomationTypes; virtualRecruiterEnabled?: boolean; candidateScreenTarget?: number },
    void,
    HttpStoreModel,
    Promise<AxiosResponse>
  >;
  getPositionRequisition: Thunk<PositionManagementModel, { positionId: string }, void, HttpStoreModel, Promise<AxiosResponse>>;
}

export const positionManagementStoreModel: PositionManagementModel = {
  currentSelectedActiveBusiness: null,
  setCurrentSelectedActiveBusiness: action((state, payload) => {
    state.currentSelectedActiveBusiness = payload;
  }),

  getIndustries: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getIndustryList();
    return response?.data as GetIndustriesResponse;
  }),

  getJobList: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getJobList();
    return response?.data as GetJobListResponse;
  }),

  getOccupationList: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getOccupationList();
    return response?.data as GetOccupationResponse;
  }),

  createJobPosition: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.createJobPosition(payload.accountId, payload.jobPositionData);
    return response?.data as CreatePositionResponse;
  }),
  copyJobPosition: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.copyJobPosition(payload.jobPositionToCopy);
    return response?.data as CreatePositionResponse;
  }),

  updateJobPosition: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updateJobPosition(payload.accountId, payload.jobPositionData, payload.positionId);
    return response?.data as CreatePositionResponse;
  }),

  updatePositionStatus: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updatePositionStatus(
      payload.accountId,
      payload.businessId,
      payload.positionId,
      payload.positionStatus,
      payload.doCancellAppointmentHireOrOffer,
    );
    return response?.data as CreatePositionResponse;
  }),

  updateArchivePosition: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updateArchivePosition(payload.positionId);
    return response?.data as CreatePositionResponse;
  }),
  updateUnArchivePosition: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updateUnArchivePosition(payload.positionId);
    return response?.data as CreatePositionResponse;
  }),

  updateNoOfOpenings: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updateNoOfOpenings(
      payload.accountId,
      payload.businessId,
      payload.positionId,
      payload.noOfOpenings,
    );
    return response?.data as CreatePositionResponse;
  }),

  updatePositionByFollow: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updatePositionByFollow(payload.positionId);
    return response?.data as CreatePositionResponse;
  }),

  updatePositionByUnFollow: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updatePositionByUnFollow(payload.positionId, payload.recruiterId);
    return response?.data as CreatePositionResponse;
  }),

  getPositionList: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getPositionList(payload.accountId, payload.businessId, payload.params);
    return response?.data as PositionListResponse;
  }),

  getPositionDetail: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getPositionDetail(payload.accountId, payload.businessId, payload.positionId);
    return response?.data as JopPositionResponse;
  }),

  getDeepLinkOfPosition: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getDeepLinkOfPosition(payload.positionId, payload.locale);
    return response?.data as DeepLinkResponse;
  }),

  getRecruiterProfilePicture: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getRecruiterProfilePicture(payload.accountId);
    return response;
  }),

  uploadOverviewVideo: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    return controller.uploadOverviewVideo(payload.userId, payload.positionId, payload.overviewVideo);
  }),

  uploadOverviewThumbnail: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    return controller.uploadOverviewThumbnail(payload.userId, payload.positionId, payload.overviewThumbnail);
  }),

  uploadOverviewVideoConfirmCall: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.uploadOverviewVideoConfirmCall(payload.positionId);
    return response?.data as string;
  }),

  getOverviewVideo: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getOverviewVideo(payload.accountId, payload.positionId);
    return response;
  }),

  getOverviewThumbnail: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getOverviewThumbnail(payload.accountId, payload.positionId);
    return response;
  }),

  removeOverviewVideo: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.removeOverviewVideo(payload.positionId);
    return response?.data as string;
  }),

  getTopCandidatesOfPosition: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getTopCandidatesOfPosition(payload.positionId, payload.topCandidateBodyData);
    return response?.data as TopCandidateDataType[];
  }),

  updateContactRequestNoteOfPosition: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.updateContactRequestNoteOfPosition(
      payload.positionId,
      payload.seekerId,
      payload.recruiterNotes,
    );
    return response?.data as string;
  }),

  unlock: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.unlock(payload.positionId, payload.seekerId, payload.recruiterId, payload.modernHireEnable);
    return response?.data as string;
  }),

  getShiftIntersection: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getShiftIntersection(payload.positionId, payload.seekerId, payload.requisitionId);
    return response?.data as Shifts;
  }),

  setPositionAutomation: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
    return controller.setPositionAutomation(
      payload.positionId,
      payload.automationType,
      payload.virtualRecruiterEnabled,
      payload.candidateScreenTarget,
    );
  }),

  getPositionRequisition: thunk(
    /* istanbul ignore next */ async (_actions, payload, { getStoreState }) => {
      const controller = new PositionManagementController(getStoreState().http.client as HttpClientWithRetry);
      // istanbul ignore next
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      return (await controller.getPositionRequisition(payload.positionId)).data;
    },
  ),
};
