import React from 'react';
import _isNil from 'lodash/isNil';
import useCandidate from '../../../app/components/useCandidate';
import { History } from '../../messaging/seeker/candidate/history/History';
import { useStoreState } from '../../../models/hooks';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';

export default function ProfileHistory({ candidateId }: { candidateId: string }) {
  const isCertnFeatureEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_CERTN_FEATURE];
  });
  const accountStore = useStoreState((s) => s.app.accounts);
  const [isCertnEnabled, setCertnEnabled] = React.useState<boolean>(false);
  const selectedCandidate = useCandidate(candidateId);

  React.useEffect(() => {
    if (!_isNil(accountStore) && accountStore.configuration && accountStore.configuration.certnEnabled) {
      setCertnEnabled(accountStore.configuration.certnEnabled);
    } else {
      setCertnEnabled(false);
    }
  }, [accountStore]);

  return (
    <History
      isCertnEnabled={isCertnEnabled}
      isCertnFeatureEnabled={isCertnFeatureEnabled}
      candidateFromTopCandidate={selectedCandidate}
    />
  );
}
