import { useStoreState } from '../../models/hooks';

export const useSeekerPicture = (seekerId?: string): string | undefined => {
  const pictures = useStoreState((state) => state.messaging.seekersProfilePictures);

  if (!seekerId) {
    return undefined;
  }

  return pictures[seekerId];
};
