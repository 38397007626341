import React from 'react';
import { HStack, Stack } from '@chakra-ui/core';
import DatePickerField from './DatePickerField';
import { FieldSchema, SchemaBuilderProps } from './formBuilderSchema';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';

export default function DatePickerSchemaBuilder(props: SchemaBuilderProps) {
  const { onChange, defaultValue } = props;
  const handleChange = (key: keyof FieldSchema | keyof FieldSchema['validations'], value: any) => {
    onChange({ ...defaultValue, [key]: value });
  };
  return (
    <Stack>
      <LabelSchemaBuilder {...props} />
      <FormElementTextBuilder {...props} placeholder="Enter text here for date input..." />
      <HStack justify="space-between">
        <DatePickerField
          label="Start Date"
          value={defaultValue?.validations?.minDate}
          onChange={(date) => handleChange('validations', { ...defaultValue?.validations, minDate: date as Date })}
          startDate={defaultValue?.validations?.minDate}
          endDate={defaultValue?.validations?.maxDate}
          selectsStart
        />
        <DatePickerField
          label="End Date"
          value={defaultValue?.validations?.maxDate}
          onChange={(date) => handleChange('validations', { ...defaultValue?.validations, maxDate: date as Date })}
          startDate={defaultValue?.validations?.minDate}
          minDate={defaultValue?.validations?.minDate}
          endDate={defaultValue?.validations?.maxDate}
          selectsEnd
        />
      </HStack>
    </Stack>
  );
}
