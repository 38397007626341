export default {
  noRequisition: "Ce poste n'a aucune réquisition ouverte. Ajouter une réquisition pour continuer.",
  requisitionDetails: 'Détails de la réquisition',
  partTime: 'Temps partiel',
  fullTime: 'Temps plein',
  seasonal: 'Saisonnier',
  effectiveTime: 'Date effective :',
  wages: ' Salaire : ',
  seasonalEndDate: 'Date de fin:',
  shifts: 'Horaire',
  contractedHours: 'Heures contractées',
  minimum: 'Minimum',
  maximum: 'Maximum',
  guaranteedHours: 'Heures garanties',
  requisitionID: 'Réquisition',
  offerNotes: "Notes de l'offre",
  selectRequisition: `Choissisez Réquisition`,
  locationAndPositionChanged: 'Ce candidat a reçu une offre pour un poste différent que celui auquel il a appliqué.',
  conformAlert: {
    title: 'Avertissement',
    subtitle:
      'Vous êtes sur le point de changer le poste pour lequel le candidat se fait embaucher. Êtes-vous certain de vouloir faire ces changements?',
    close: 'Fermer',
    continue: 'Confirmer',
  },
  hourly: `Horaire`,
  annual: `Annuel`,
  weekly: `Hebdomadaire`,
  partTimeHoursPerWeek: 'Heures/semaine',
  couldNotLoadRequisition: 'Impossible de charger la réquisition pour le moment.',
  OPEN: 'Ouverte',
  CANCELLED: 'Annulée',
  FILLED: 'Comblée',
  PROCESSING: 'En traitement',
  associatedCandidate: 'Candidat sélectionné',
  effectiveTimeInfo: "La date d'entrée en vigueur de cette réquisition est {{requisitionActiveThresholdDays}} jours d'ici.",
  candidateRequisitionMatch: 'Chevauchement entre la disponibilité du candidat et le poste',
  compare: 'Comparaison',
  days: 'Days',
  candidateAvailability: 'Disponibilité du candidat',
  candidateAvailabilitiesInfo: "Ceci est l'intersection entre la disponibilité du candidat et les besoins du poste.",
  requisitionShifts: 'Disponibilité désirée',
  requisitionRiskDashboard: 'Aperçu des réquisitions',
  requisitionInsights: 'Détails des réquisitions',
  riskRequisitionDetailTable: {
    header: {
      requisitionId: 'Identifiant de la réquisition',
      status: 'Statut',
      createdUpdated: 'Création / Dernière modification',
      hireBy: 'Doit embaucher avant',
      locationPosition: 'Emplacement & Poste',
    },
    riskStatusText: {
      safe: 'OK',
      atRisk: 'À risque',
      overdue: 'En retard',
    },
    overdueDaysText: 'En retard de {{riskDays}} jours',
    riskDaysText: "{{riskDays}} jours restants pour l'embauche",
  },
  requisitionByLocation: 'Réquisitions par emplacement',
  businessRequisitionRiskStatus: {
    safe: 'OK',
    atRisk: 'À risque',
    overdue: 'En retard',
  },
  requisitionCount: {
    unfilled: 'Ouvert',
    atRisk: 'À risque',
    overdue: 'En retard',
    locationImpacted: 'Emplacements affectés',
    positionImpacted: 'Postes affectés',
  },
  pagination: {
    showingResult: "Affiche présentement {{currentItemsLength}} d'un total de {{totalItemsLength}} réquisitions",
  },
  backBtnText: 'Retour',
  salaryOffered: 'Salaire offert :',
  noRequisitionAvailable: `Ce poste n'a aucune réquisition ouverte.`,
};
