import React, { useContext, useEffect, useState } from 'react';
import { Participant } from 'twilio-video';
import _noop from 'lodash/noop';
import { useMeetContext } from './MeetContext';

export type ActiveParticipantContextValueType = {
  activeParticipant?: Participant | null;
  setActiveParticipant: (participant: Participant) => void;
};

export const DEFAULT_ACTIVE_PARTICIPANT_CONTEXT: ActiveParticipantContextValueType = {
  activeParticipant: undefined,
  setActiveParticipant: _noop,
};

const ActiveParticipantContext = React.createContext<ActiveParticipantContextValueType>(DEFAULT_ACTIVE_PARTICIPANT_CONTEXT);

export const ActiveParticipantContextProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [activeParticipant, _setActiveParticipant] = useState<Participant | null>();
  const { room } = useMeetContext();

  const setActiveParticipant = (participant: Participant) => {
    const newParticipant = activeParticipant === participant ? null : participant;
    _setActiveParticipant(newParticipant);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      const onDisconnect = () => {
        _setActiveParticipant(null);
      };

      const onParticipantDisconnect = (participant: Participant) => {
        _setActiveParticipant((prevParticipant) => {
          return prevParticipant === participant ? null : prevParticipant;
        });
      };

      room.on('disconnected', onDisconnect);
      room.on('participantDisconnected', onParticipantDisconnect);

      return () => {
        room.off('disconnected', onDisconnect);
        room.off('participantDisconnected', onParticipantDisconnect);
      };
    }
  }, [room, activeParticipant]);

  return (
    <ActiveParticipantContext.Provider value={{ activeParticipant, setActiveParticipant }}>
      {children}
    </ActiveParticipantContext.Provider>
  );
};

export const useActiveParticipant = (): ActiveParticipantContextValueType => useContext(ActiveParticipantContext);
