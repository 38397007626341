/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box } from '@chakra-ui/core';
import { LocalAudioTrack } from 'twilio-video';
import { useMeetContext } from './MeetContext';
import { AudioLevelIndicator } from './twilio/AudioLevelIndicator';

export const LocalAudioPreview = (): JSX.Element => {
  const { localTracks } = useMeetContext();
  const audioTrack = localTracks.find((t) => t.kind === 'audio') as LocalAudioTrack;

  return <Box>{audioTrack && <AudioLevelIndicator audioTrack={audioTrack} />}</Box>;
};
