import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const TypingIndicatorStyle = css`
  display: inline-block;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  background-color: #f0f4c3;
  border: 1px solid rgba(0, 0, 0, 0.04);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: #000000;
  line-height: 1.25;
`;

export const Dots = styled.span`
  height: 5px;
  width: 5px;
  border-radius: 5px;
  margin-left: 4px;
  background-color: black;
  display: inline-block;
  animation: 1.2s typing-dot ease-in-out infinite;

  :nth-of-type(2) {
   animation-delay: 0.15s;
  }
  :nth-of-type(3) {
   animation-delay: 0.25s;
  }

  @keyframes typing-dot {
   15% {
     transform: translateY(-35%);
     opacity: 0.5;
   }
   30% {
     transform: translateY(0%);
     opacity: 1;
   }
`;
