import { useTranslation } from 'react-i18next';

interface UseValidateImageUploadProps {
  onChange: (value: File | null) => void;
  onError?: (error: string | null) => void;
}
/**
 * Custom hook to validate image upload.
 *
 * This hook provides a method to handle image upload validations, ensuring
 * that the selected image file is of the correct type and size.
 *
 * @param {Object} props - The properties object.
 * @param {(value: File | null) => void} props.onChange - Callback function to handle when a valid image file is selected.
 * @param {(error: string | null) => void} [props.onError] - Optional callback function to handle error messages.
 *
 * @returns {Object} - Returns an object containing the `handleImageChange` function.
 * @returns {Function} returns.handleImageChange - Function to be used as an event handler for image input changes.
 */
export function useValidateImageUpload({ onChange, onError }: UseValidateImageUploadProps) {
  const { t } = useTranslation();

  const validateImageType = (file: File) => {
    const allowedTypes = ['image/png', 'image/jpeg'];
    return allowedTypes.includes(file.type);
  };

  const validateImageSize = (file: File) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 5MB
    return file.size <= MAX_FILE_SIZE;
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const isValidType = validateImageType(file);
      const isValidSize = validateImageSize(file);

      if (!isValidType) {
        onError?.(t('administration:imageGalleryModal:invalidImageType'));
      } else if (!isValidSize) {
        onError?.(t('administration:imageGalleryModal:invalidImageSize'));
      } else {
        onChange(file);
        onError?.(null);
      }
    }
  };

  return { handleImageChange };
}
