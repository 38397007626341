import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import { Collections, SubCollections } from '../../../../../firebase/firestore/collections';
import { Query } from '../../../../../firebase/firestore/query/query';
import { Candidate, SeekerNote } from '../../../../../firebase/firestore/documents/candidate';
import SeekerNotesPresenter from './seekerNotesPresenter';
import { useQuerySnapshot, useSubCollectionQuerySnapshot } from '../../../../../firebase/firestore/hooks';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import useRecruiter from './useRecruiter';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';

export enum NotesStateEnum {
  UNDEFINED,
  LOADING,
  READY,
}

export type NoteState = {
  state: NotesStateEnum;
  presenter: SeekerNotesPresenter;
};

export type GetFilteredNotes = {
  recruiterId: string;
  seekerId: string;
  positionId: string;
};

const useSeekerNotes = (getFilteredNotes?: GetFilteredNotes): NoteState => {
  const { i18n } = useTranslation();

  const { selectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const candidate = selectedCandidate || selectedEngagedCandidate;

  const { currentUserProfile } = useCurrentUserProfile();

  const seekerId = candidate?.seeker ?? '';
  const accountId = currentUserProfile?.account ?? '';
  const recruiterId = currentUserProfile?.id ?? '';

  const seekerNotes = useSubCollectionQuerySnapshot<SeekerNote>(
    Collections.ACCOUNT,
    accountId,
    SubCollections.SEEKER_NOTES,
    _isEmpty(getFilteredNotes)
      ? [Query.field('seeker').equals(seekerId)]
      : [
          Query.field('seeker').equals(getFilteredNotes?.seekerId),
          Query.field('recruiter').equals(getFilteredNotes?.recruiterId),
          Query.field('position').equals(getFilteredNotes?.positionId),
        ],
  );

  const candidates = useQuerySnapshot<Candidate>(
    Collections.CANDIDATES,
    _isEmpty(getFilteredNotes)
      ? [
          Query.field<Candidate>('seeker').equals(seekerId),
          Query.field<Candidate>('account').equals(accountId),
          Query.field<Candidate>('monetized').equals(true),
        ]
      : [
          Query.field<Candidate>('seeker').equals(getFilteredNotes?.seekerId),
          Query.field<Candidate>('account').equals(accountId),
          Query.field<Candidate>('monetized').equals(true),
        ],
  );

  const recruiters = useRecruiter();

  if (seekerNotes && candidates && !_isEmpty(recruiters)) {
    if (seekerNotes.length === 0) {
      return {
        state: NotesStateEnum.UNDEFINED,
        presenter: new SeekerNotesPresenter([], [], [], recruiterId, i18n.language),
      };
    }
    return {
      state: NotesStateEnum.READY,
      presenter: new SeekerNotesPresenter(seekerNotes, candidates, recruiters, recruiterId, i18n.language),
    };
  }
  return {
    state: NotesStateEnum.LOADING,
    presenter: new SeekerNotesPresenter([], [], [], recruiterId, i18n.language),
  };
};

export default useSeekerNotes;
