import { Collections } from '../../../firebase/firestore/collections';
import { Query } from '../../../firebase/firestore/query/query';
import { useQuerySnapshot } from '../../../firebase/firestore/hooks';
import { News } from '../../../firebase/firestore/documents/news';
import { BUNDLE_ID_APP_BASED } from '../../../firebase/firestore/constants';

const useStories = (): News[] | undefined => {
  const news: News[] | undefined = useQuerySnapshot<News>(Collections.NEWS, [
    Query.field<News>('appBundleId').equals(BUNDLE_ID_APP_BASED),
    Query.limit(100),
  ]);

  return news;
};

export default useStories;
