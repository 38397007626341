/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Stack,
  Text,
  ButtonGroup,
  IconButton,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/core';
import { ChevronDownIcon } from '@chakra-ui/icons';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment-timezone';
import { enGB, fr } from 'date-fns/locale';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { drawerCSS } from '../CalendarView.Style';
import { reactDatePickerCSS } from './ReactDatePicker.Style';
import { ReactComponent as _IconCalendar } from '../../../assets/img/icon-calendar-blue.svg';
import { useStoreActions } from '../../../models/hooks';
import {
  ActiveAppointmentAvailabilitySlot,
  AppointmentAvailability,
  AppointmentType,
} from '../../../firebase/firestore/documents/appointmentAvaiability';
import { loginButtonStyle } from '../../auth/login/loginForm/LoginForm.styles';
import colors from '../../../styles/colors';
import i18n from '../../../locales/i18n';

registerLocale('fr', enGB);
registerLocale('fr', fr);

const IconCalendar = memo(_IconCalendar);

export type DrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  redirectToPreviousPageHandler: () => void;
  activeAppointmentSlot: ActiveAppointmentAvailabilitySlot | undefined;
  scheduleDetail?: AppointmentAvailability;
};

export const BookingSchedule = ({
  isOpen,
  onClose,
  redirectToPreviousPageHandler,
  scheduleDetail,
  activeAppointmentSlot,
}: DrawerProps): JSX.Element => {
  const { t } = useTranslation('calendar');
  const [appointmentTypes, setAppointmentTypes] = React.useState<AppointmentType[]>(
    scheduleDetail?.appointmentTypes ?? [AppointmentType.inPerson, AppointmentType.video, AppointmentType.phone],
  );
  const [startDate, setStartDate] = React.useState<Date | null>();
  const [startTime, setStartTime] = React.useState<Date | null>();
  const [endTime, setEndTime] = React.useState<Date | null>();
  const [bookingCapacity, setBookingCapacity] = React.useState<number>(1);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const { saveSchedule, updateSchedule } = useStoreActions((actions) => actions.calendar);

  const toast = useToast();

  useEffect(() => {
    if (scheduleDetail) {
      setAppointmentTypes(scheduleDetail.appointmentTypes);
      setStartDate(scheduleDetail.fromDate);
      setStartTime(scheduleDetail.fromDate);
      setEndTime(scheduleDetail.toDate);
      setBookingCapacity(scheduleDetail.bookingCapacity || 1);
    } else {
      setStartDate(null);
      setStartTime(null);
      setEndTime(null);
    }
  }, [scheduleDetail]);

  useEffect(() => {
    if (!_isNil(activeAppointmentSlot)) {
      setStartDate(activeAppointmentSlot.fromDate);
      setStartTime(activeAppointmentSlot.fromDate);
      setEndTime(activeAppointmentSlot.toDate);
    }
  }, [activeAppointmentSlot]);

  const isInPersonOnly = () => {
    if (appointmentTypes.length === 1 && appointmentTypes.includes(AppointmentType.inPerson)) {
      return true;
    }
    return false;
  };

  const clearAllFieldsOnClose = () => {
    onClose();
    setAppointmentTypes([]);
    setStartDate(null);
    setStartTime(null);
    setEndTime(null);
  };

  const onBookingDrawerClose = () => {
    setIsSaving(false);
    clearAllFieldsOnClose();
  };

  const addTimeToDate = (sDate: Date, eDate: Date) => {
    const eMoment = moment(eDate);
    return moment(sDate).set('minutes', eMoment.get('minutes')).set('hours', eMoment.get('hours')).set('seconds', 0).toDate();
  };

  const createBooking = async (formattedStartDate: Date, formattedEndDate: Date) => {
    await saveSchedule({
      fromDate: formattedStartDate,
      toDate: formattedEndDate,
      appointmentTypes,
      bookingCapacity,
    });

    toast({
      title: t('eventForm.successTitle'),
      description: t('availabilityForm.saveMessage'),
      duration: 5000,
      isClosable: true,
    });
    clearAllFieldsOnClose();
  };

  const updateBooking = async (scheduleDetailId: string, formattedStartDate: Date, formattedEndDate: Date) => {
    await updateSchedule({
      id: scheduleDetailId,
      payload: { fromDate: formattedStartDate, toDate: formattedEndDate, appointmentTypes, bookingCapacity },
    });

    toast({
      title: t('eventForm.successTitle'),
      description: t('availabilityForm.updateMessage'),
      duration: 5000,
      isClosable: true,
    });

    onBookingDrawerClose();
  };

  const saveBooking = async () => {
    if (_isNil(startDate) || _isNil(startTime) || _isNil(endTime) || _isEmpty(appointmentTypes)) {
      toast({
        status: 'warning',
        title: t('eventForm.errorTitle'),
        description: t('eventForm.errorMessage'),
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    setIsSaving(true);

    const newStartTime = addTimeToDate(startDate, startTime);
    const newEndTime = addTimeToDate(startDate, endTime);

    if (scheduleDetail && scheduleDetail.id) {
      await updateBooking(scheduleDetail.id, newStartTime, newEndTime);
      redirectToPreviousPageHandler();
    } else {
      await createBooking(newStartTime, newEndTime);
      redirectToPreviousPageHandler();
    }
  };

  /* istanbul ignore next */
  const setStartDateOfAvailability = (date: Date) => {
    setStartDate(date);
    setStartTime(null);
    setEndTime(null);
  };

  /* istanbul ignore next */
  const setStartTimeOfAvailability = (date: Date) => {
    setStartTime(date);
    setEndTime(null);
  };

  const CustomDatePickerInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <ButtonGroup
      className={`customDatepicker ${!!startDate && !!scheduleDetail?.id ? 'disabled' : ''}`}
      size="sm"
      isAttached
      variant="outline"
      onClick={props.onClick}
      ref={ref}
    >
      <IconButton aria-label="calendar" icon={<IconCalendar width={30} height={30} />} />
      <Button mr="-px" isFullWidth>
        {props.value}
      </Button>
    </ButtonGroup>
  );

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={
        /* istanbul ignore next */
        () => onBookingDrawerClose()
      }
      size="sm"
      data-testid="DrawerTest"
    >
      <DrawerOverlay>
        <DrawerContent css={[drawerCSS, reactDatePickerCSS]}>
          <DrawerCloseButton _focus={{ border: '1px solid #1F3CBA' }} />
          <DrawerHeader borderBottom="2px" borderColor="#EBEDF3" color={colors.blue.default}>
            {t('availabilityForm.myAvailabilityLabel')}
          </DrawerHeader>
          <DrawerBody>
            <Box className="event-form-control date-picker-box">
              <Text mb={2}>{t('availabilityForm.appointmentDateLabel')}</Text>
              <Box className="date-input-group">
                <DatePicker
                  locale={i18n.language}
                  customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                  selected={startDate}
                  minDate={moment().toDate()}
                  disabled={!!startDate && !!scheduleDetail?.id}
                  dateFormat="P"
                  onChange={/* istanbul ignore next */ (date) => setStartDateOfAvailability(date as Date)}
                />
              </Box>
            </Box>
            <Flex mt={5} align="center" className="time-picker-box">
              <Box>
                <Text mb={2}>{t('eventForm.startTime')}</Text>
                <Box className="time-input-group">
                  <DatePicker
                    locale={i18n.language}
                    selected={startTime}
                    minTime={
                      moment(startDate).diff(new Date(), 'hours') < 0
                        ? moment().toDate()
                        : moment(startDate).startOf('day').toDate()
                    }
                    maxTime={moment(startDate).endOf('day').toDate()}
                    disabled={!!startTime && !!scheduleDetail?.id}
                    onChange={/* istanbul ignore next */ (date) => setStartTimeOfAvailability(date as Date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption={t('eventForm.timeCaption')}
                    dateFormat="p"
                  />
                  <ChevronDownIcon />
                </Box>
              </Box>
              <Box ml={4}>
                <Text mb={2}>{t('eventForm.endTime')}</Text>
                <Box className="time-input-group">
                  <DatePicker
                    locale={i18n.language}
                    selected={endTime}
                    minTime={moment(startTime).add(30, 'minutes').toDate()}
                    openToDate={startTime ? moment(startTime).toDate() : moment().toDate()}
                    maxTime={moment(startDate).endOf('day').toDate()}
                    disabled={!!endTime && !!scheduleDetail?.id}
                    onChange={/* istanbul ignore next */ (date) => setEndTime(date as Date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption={t('eventForm.timeCaption')}
                    dateFormat="p"
                  />
                  <ChevronDownIcon />
                </Box>
              </Box>
            </Flex>
            <Box mt={5}>
              <Text>{t('availabilityForm.appointmentTypeLabel')}</Text>
              <CheckboxGroup
                onChange={
                  /* istanbul ignore next */ (value) => {
                    setAppointmentTypes(value as any);
                    if (!isInPersonOnly()) setBookingCapacity(1);
                  }
                }
                value={appointmentTypes}
              >
                <Stack direction="row" mt={2}>
                  <Checkbox value="phone" mr={3} data-testid="checkbox1">
                    {t('phone')}
                  </Checkbox>
                  <Checkbox value="video" mr={3}>
                    {t('video')}
                  </Checkbox>
                  <Checkbox value="inPerson" mr={3}>
                    {t('inPerson')}
                  </Checkbox>
                </Stack>
              </CheckboxGroup>
            </Box>
            <Box mt={5}>
              <Text>{t('availabilityForm.bookingCapacity')}</Text>
              <NumberInput
                onChange={(value: string) => setBookingCapacity(parseInt(value, 10))}
                value={isInPersonOnly() ? bookingCapacity : 1}
                size="sm"
                isDisabled={!isInPersonOnly()}
                defaultValue={1}
                min={1}
                max={isInPersonOnly() ? 50 : 1}
                maxW={32}
                mt={2}
              >
                <NumberInputField data-testid="bookingCapacity" />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
          </DrawerBody>
          <Flex justify="space-between" py={3} borderTop="2px" borderColor="#EBEDF3">
            <Button
              isLoading={isSaving}
              disabled={isSaving}
              data-testid="SaveScheduleBtn"
              css={loginButtonStyle}
              borderRadius={3}
              ml={6}
              onClick={() => saveBooking()}
            >
              {t('availabilityForm.saveAvailability')}
            </Button>
            <Button
              variant="outline"
              borderRadius={3}
              mr={3}
              onClick={() => onBookingDrawerClose()}
              data-testid="CancelDrawerBtn"
            >
              {t('eventForm.cancel')}
            </Button>
          </Flex>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
