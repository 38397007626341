import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';

import { useTranslation } from 'react-i18next';
import { AiOutlineSearch, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { Column } from 'react-table';
import { SingleTeamInviteType, SingleTeamType, TeamInviteAction, TeamMemberAction } from './TeamType';
import TeamInfoTableView from './TeamInfoTableView';
import { TeamCreateModal } from './TeamCreateModal';
import { TeamMemberInviteModal } from './TeamMemberInviteModal';
import { ConfirmationDialog } from '../../common/AlertDialog/ConfirmationDialog';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { AccountType } from '../../../firebase/firestore/documents/accounts';
import useTeamMembers from './useTeamMembers';
import { User } from '../../../firebase/firestore/documents/user';
import useAccountInvites from './useAccountInvites';
import TeamInvitesTableView from './TeamInvitesTableView';
import { AccountInvites } from '../../../firebase/firestore/documents/accountInvites';
import colors from '../../../styles/colors';

export const TeamView = (): JSX.Element => {
  const { t } = useTranslation('team');
  const [tableSearchText, setTableSearchText] = useState<string>('');
  const [activeMember, setActiveMember] = useState<SingleTeamType | SingleTeamInviteType | null>(null);
  const [actionForActiveMember, setActionForActiveMember] = useState<TeamMemberAction | TeamInviteAction | null>(null);
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    isOpen: isTeamCreateModalOpen,
    onOpen: onTeamCreateModalOpen,
    onClose: onConfirmTeamCreateModalClose,
  } = useDisclosure();
  const {
    isOpen: isTeamInviteModalOpen,
    onOpen: onTeamInviteModalOpen,
    onClose: onConfirmTeamInviteModalClose,
  } = useDisclosure();
  const { isOpen: isConfirmationOpen, onOpen: onConfirmationOpen, onClose: onConfirmationClose } = useDisclosure();

  const { cancelInvite, resendInvite, removeTeamMember, demoteTeamMember, promoteTeamMember } = useStoreActions(
    (actions) => actions.profileManagement,
  );
  const { accounts, user } = useStoreState((state) => state.app);
  const toast = useToast();
  const teamMembersState = useTeamMembers();
  const teamInvitesState = useAccountInvites();

  const teamTableHeaders = {
    name: t('teamInfoTableHeader.name'),
    status: t('teamInfoTableHeader.status'),
    phoneNumber: t('teamInfoTableHeader.phoneNumber'),
    action: t('teamInfoTableHeader.action'),
  };

  const teamInviteHeaders = {
    email: t('teamInviteTableHeader.email'),
    status: t('teamInviteTableHeader.status'),
    action: t('teamInviteTableHeader.action'),
  };

  const teamTableColumns: Column<SingleTeamType>[] = [
    {
      Header: teamTableHeaders.name,
      accessor: 'name',
      sortType: 'string',
    },
    {
      Header: teamTableHeaders.status,
      accessor: 'status',
    },
    {
      Header: teamTableHeaders.action,
      accessor: 'action',
    },
  ];

  const teamInvitesTableColumns: Column<SingleTeamInviteType>[] = [
    {
      Header: teamInviteHeaders.email,
      accessor: 'email',
    },
    {
      Header: teamInviteHeaders.status,
      accessor: 'status',
    },
    {
      Header: teamInviteHeaders.action,
      accessor: 'action',
    },
  ];

  const formattedTeamMembers = (teamMemberList: User[]): SingleTeamType[] => {
    return teamMemberList.map((teamMember) => ({
      id: teamMember.id,
      name: `${teamMember.firstName} ${teamMember.lastName}`,
      status: t('active'),
      phoneNumber: teamMember.phone,
    }));
  };

  const formattedTeamInvites = (teamInvites: AccountInvites[]): SingleTeamInviteType[] => {
    return teamInvites.map((invite) => ({
      id: invite.id,
      email: invite.inviteeEmail,
      userId: invite.user,
      status: t(invite.status),
      state: invite.status,
    }));
  };

  const handleTeamMember = (teamMember: SingleTeamType, action: TeamMemberAction) => {
    switch (action) {
      case TeamMemberAction.PROMOTE:
        setConfirmationMessage(t('confirmationMessage.promote', { teamMemberName: teamMember.name }));
        break;
      case TeamMemberAction.DEMOTE:
        setConfirmationMessage(t('confirmationMessage.demote', { teamMemberName: teamMember.name }));
        break;
      default:
        setConfirmationMessage(t('confirmationMessage.delete', { teamMemberName: teamMember.name }));
    }
    setActiveMember(teamMember);
    setActionForActiveMember(action);
    onConfirmationOpen();
  };

  const handleTeamInvite = (invite: SingleTeamInviteType, action: TeamInviteAction) => {
    switch (action) {
      case TeamInviteAction.CANCEL:
        setConfirmationMessage(t('confirmationMessage.cancel', { inviteEmail: invite.email }));
        break;
      default:
        setConfirmationMessage(t('confirmationMessage.reInvite', { inviteEmail: invite.email }));
    }
    setActiveMember(invite);
    setActionForActiveMember(action);
    onConfirmationOpen();
  };

  const handleConfirm = async (action: TeamMemberAction | TeamInviteAction) => {
    const activeTeamMember = activeMember as SingleTeamType;
    const activeInvite = activeMember as SingleTeamInviteType;
    if (action && user) {
      let successMessage = '';
      const payload = {
        accountId: user?.account,
        userId: activeTeamMember.id,
      };
      const emailInvitePayload = {
        accountInviteId: activeInvite.id,
        userId: activeInvite.userId,
      };
      setIsLoading(true);
      switch (action) {
        case TeamInviteAction.REINVITE:
          await resendInvite(emailInvitePayload);
          successMessage = t('successMessage.reInvite', { inviteEmail: activeInvite.email });
          break;
        case TeamInviteAction.CANCEL:
          await cancelInvite(emailInvitePayload);
          successMessage = t('successMessage.cancel', { inviteEmail: activeInvite.email });
          break;
        case TeamMemberAction.PROMOTE:
          await promoteTeamMember(payload);
          successMessage = t('successMessage.promote', { teamMemberName: activeTeamMember.name });
          break;
        case TeamMemberAction.DEMOTE:
          await demoteTeamMember(payload);
          successMessage = t('successMessage.demote', { teamMemberName: activeTeamMember.name });
          break;
        default:
          if (accounts?.owners?.includes(activeTeamMember.id)) {
            await demoteTeamMember(payload);
          }
          await removeTeamMember(payload);
          successMessage = t('successMessage.delete', { teamMemberName: activeTeamMember.name });
      }
      toast({
        title: successMessage,
        duration: 2000,
      });
    }
    onConfirmationClose();
    setIsLoading(false);
  };

  const personAccountView = (): JSX.Element => {
    return (
      <Flex data-testid="PersonalView" alignItems="center" justifyContent="center" flexDirection="column" px={2} py={4}>
        <Heading as="h3" mb={6} fontSize="lg" fontWeight="normal" lineHeight="1.4">
          {t('createTeamLabel')}
        </Heading>
        <Button
          className="createTeamName"
          varient="solid"
          data-testid="TeamCreateModal"
          colorScheme="blue"
          onClick={() => onTeamCreateModalOpen()}
        >
          {t('createTeamBtn')}
        </Button>
      </Flex>
    );
  };

  const businessAccountView = (): JSX.Element => {
    return (
      <Fragment>
        <Flex alignItems="center" py={4} data-testid="BusinessView">
          <Heading fontSize="lg">{accounts?.name}</Heading>
          <Spacer />
          <Box>
            <Button
              data-testid="TeamEditModal"
              variant="ghost"
              bg="cyan.lightCyan"
              color="blue.500"
              mr={3}
              onClick={() => onTeamCreateModalOpen()}
            >
              {t('editTeamLabel')}
            </Button>
            <Button
              data-testid="TeamInviteModal"
              rightIcon={<AiOutlineUsergroupAdd style={{ height: '22px', width: '22px' }} />}
              colorScheme="blue"
              variant="solid"
              onClick={() => onTeamInviteModalOpen()}
              className="inviteTeamMember"
            >
              {t('addTeamBtn')}
            </Button>
          </Box>
        </Flex>
        <Box mb={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" p={0} color="gray.200">
              <AiOutlineSearch />
            </InputLeftElement>
            <Input
              data-testid="TeamSearchField"
              type="text"
              placeholder={t('teamSearchPlaceholder')}
              onChange={(e) => setTableSearchText(e.target.value)}
            />
          </InputGroup>
        </Box>
        <TeamInfoTableView
          columns={teamTableColumns}
          data={formattedTeamMembers(teamMembersState.teamMembers).filter((value) =>
            value.name?.toLocaleLowerCase().includes(tableSearchText.toLocaleLowerCase()),
          )}
          isLoading={teamMembersState.state}
          handleTeamMember={handleTeamMember}
        />
        <Heading fontSize="sm" lineHeight="1.2" pb={2} mt={6} mb={3} borderBottom={`1px solid ${colors.gray['100']}`}>
          {t('accountInviteInfo')}
        </Heading>
        <TeamInvitesTableView
          data={formattedTeamInvites(teamInvitesState.accountInvites)}
          columns={teamInvitesTableColumns}
          isLoading={teamInvitesState.state}
          handleTeamInvite={handleTeamInvite}
        />
      </Fragment>
    );
  };

  return (
    <React.Fragment>
      <Box mb={10} backgroundColor="white" borderRadius={5} px={6} py={4} w="100%">
        <Heading as="h4" fontSize="lg" lineHeight="1.3" marginBottom="10px">
          {t('teamInfoTitle')}
        </Heading>
        <Divider />
        {accounts?.type === AccountType.PERSONAL ? personAccountView() : businessAccountView()}
        <TeamCreateModal onClose={onConfirmTeamCreateModalClose} isOpen={isTeamCreateModalOpen} />
        <TeamMemberInviteModal onClose={onConfirmTeamInviteModalClose} isOpen={isTeamInviteModalOpen} />
        <ConfirmationDialog
          isLoading={isLoading}
          isOpen={isConfirmationOpen}
          onClose={onConfirmationClose}
          message={confirmationMessage}
          action={actionForActiveMember}
          handleConfirm={handleConfirm}
        />
      </Box>
    </React.Fragment>
  );
};
