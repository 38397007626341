import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Text,
  Textarea,
  VStack,
  Popover,
  PopoverContent,
  PopoverCloseButton,
  PopoverArrow,
  PopoverFooter,
  ButtonGroup,
  useDisclosure,
  Heading,
  PopoverTrigger,
  PopoverBody,
} from '@chakra-ui/core';
import React, { ChangeEvent, useState, useRef, useEffect } from 'react';
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import { GrClose } from 'react-icons/gr';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import colors from '../../../../styles/colors';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
} from '../../../../utils/FormErrorUtils';
import { FreeFormQuestion, JobPosition } from '../PositionType';
import { useStoreState } from '../../../../models/hooks';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';

const initialFreeFormQuestion = {
  text: '',
  isActive: true,
};

export type FreeFormQuestionsProps = {
  jobPositionData: JobPosition;
  deleteFreeFormQuestion: (questionId: number) => void;
  addFreeFormQuestion: (question: FreeFormQuestion, questionEditMode: number | undefined) => void;
  showQuestionForm: boolean;
  questionToEdit: FreeFormQuestion;
  questionToEditMode: number | undefined;
  onRefreshState: () => void;
};

export const FreeFormQuestionsCard = ({
  jobPositionData,
  deleteFreeFormQuestion,
  addFreeFormQuestion,
  showQuestionForm,
  questionToEdit,
  questionToEditMode,
  onRefreshState,
}: FreeFormQuestionsProps): JSX.Element => {
  const [freeFormQuestion, setFreeFormQuestion] = useState<FreeFormQuestion>(initialFreeFormQuestion);
  const [queFormErrors, setQueFormErrors] = useState<FormattedError[]>([]);
  const [freeFormQuestionEditMode, setFreeFormQuestionEditMode] = useState<number | undefined>(undefined);
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const textAreaRef = useRef() as React.MutableRefObject<HTMLTextAreaElement>;
  const [showForm, setShowForm] = useState<boolean>(showQuestionForm);
  const enableMultipleChoiceFeature: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE];
  });

  useEffect(() => {
    setFreeFormQuestion(questionToEdit);
    setFreeFormQuestionEditMode(questionToEditMode);
  }, [questionToEdit, questionToEditMode, setFreeFormQuestion, setFreeFormQuestionEditMode]);

  const questionSchema = Joi.object().keys({
    text: Joi.string()
      .trim(true)
      .min(10)
      .max(500)
      .required()
      .messages({
        'string.max': t('createJobPosition:questionMaxLetterRequired', { count: 500 }),
      }),
    isActive: Joi.boolean().optional(),
    id: Joi.string().optional(),
  });

  const freeFormQueFormValidation = (formData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = questionSchema.validate(formData);
    if (error) {
      setQueFormErrors(errorFormat(error.details));
      return false;
    }

    setQueFormErrors([]);
    return true;
  };

  const handleFreeFormQuestionChange = (value: string | Date | boolean | ChangeEvent<HTMLInputElement>, fieldName: string) => {
    setFreeFormQuestion((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setQueFormErrors(clearErrorMessage(queFormErrors, fieldName));
  };

  // eslint-disable-next-line consistent-return
  const createFreeFormQuestion = () => {
    const validate = freeFormQueFormValidation(freeFormQuestion);
    if (!validate) {
      return false;
    }
    addFreeFormQuestion(freeFormQuestion, freeFormQuestionEditMode);
    setFreeFormQuestion(initialFreeFormQuestion);
    setFreeFormQuestionEditMode(undefined);
    if (!showQuestionForm) setShowForm(false);
    onRefreshState();
  };

  const editFreeFormQuestion = (questionId: number | undefined, ques: FreeFormQuestion) => {
    setFreeFormQuestion(ques);
    setFreeFormQuestionEditMode(questionId);
  };

  return (
    <Box backgroundColor="white" borderTopRadius={5} mb={6}>
      {!enableMultipleChoiceFeature && (
        <Flex alignItems="center" justifyContent="space-between">
          <Box px={6} py={4} w="80%" paddingLeft={showQuestionForm ? '1.5rem' : 0}>
            <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
              {t('createJobPosition:freeFormQuestionCardHeader')}
            </Heading>
            <Text fontSize="xs" color="gray.500">
              {t('createJobPosition:freeFormQuestionCardHint')}
            </Text>
          </Box>
          {!showQuestionForm && (
            <Box w="20%" textAlign="right">
              <IconButton
                aria-label="Add to friends"
                icon={
                  showForm ? <GrClose style={{ color: colors.blue[500] }} /> : <AddIcon style={{ color: colors.blue[500] }} />
                }
                data-testid="showFreeFromBtn"
                onClick={() => setShowForm(!showForm)}
              />
            </Box>
          )}
        </Flex>
      )}
      {(showForm || enableMultipleChoiceFeature) && (
        <Box>
          <Divider borderColor="#ECEFF1" />

          <Box p={6}>
            <Box mb={6}>
              <FormControl isInvalid={hasErrorMessage(queFormErrors, 'text')}>
                <FormLabel htmlFor="question">{t('createJobPosition:freeFormQuestionLabel')}</FormLabel>
                <Textarea
                  variant="filled"
                  id="freeFormQuestion"
                  placeholder={t('createJobPosition:freeFormQuestionInputPlaceholder')}
                  value={freeFormQuestion.text}
                  data-testid="FreeFormQuestionField"
                  ref={textAreaRef}
                  onChange={(e) => handleFreeFormQuestionChange(e.target.value, 'text')}
                />
                <FormErrorMessage>{getErrorMessage(queFormErrors, 'text')}</FormErrorMessage>
              </FormControl>
            </Box>
            <Flex justifyContent="end">
              <Button
                size="sm"
                colorScheme="blue"
                disabled={freeFormQuestion.text.length < 9}
                data-testid="addFreeFormQuestionBtn"
                onClick={() => createFreeFormQuestion()}
              >
                {t('createJobPosition:confirmBtn')}
              </Button>
            </Flex>
          </Box>
        </Box>
      )}

      {!enableMultipleChoiceFeature && jobPositionData.freeFormQuestions.length > 0 && (
        <Box p={6} paddingLeft={showQuestionForm ? '1.5rem' : 0} paddingRight={showQuestionForm ? '1.5rem' : 0}>
          <Divider mt="12px" mb="12px" />
          <VStack spacing={4} align="stretch">
            {jobPositionData.freeFormQuestions.map((que, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Flex key={index} alignItems="center" justifyContent="space-between">
                <Text>{que.text}</Text>
                <Flex>
                  <IconButton
                    backgroundColor="#ECEFF1"
                    aria-label="Edit Question"
                    icon={<RiEdit2Fill style={{ color: colors.blue[500] }} />}
                    data-testid={`EditQuestionBtn-${index}`}
                    onClick={() => {
                      setShowForm(true);
                      /* istanbul ignore else */
                      if (showQuestionForm) textAreaRef.current.focus();
                      editFreeFormQuestion(index, que);
                    }}
                  />
                  <Box>
                    <Popover
                      returnFocusOnClose={false}
                      isOpen={isOpen && deleteIndex === index}
                      onClose={onClose}
                      closeOnBlur
                      isLazy
                    >
                      <PopoverTrigger>
                        <IconButton
                          backgroundColor="#ECEFF1"
                          ml={3}
                          aria-label="Delete Question"
                          icon={<RiDeleteBin5Fill style={{ color: colors.blue[500] }} />}
                          data-testid={`DeleteQuestionBtn-${index}`}
                          onClick={() => {
                            onOpen();
                            // istanbul ignore else
                            if (index === freeFormQuestionEditMode) setFreeFormQuestionEditMode(undefined);
                            if (freeFormQuestionEditMode && index < freeFormQuestionEditMode)
                              setFreeFormQuestionEditMode(freeFormQuestionEditMode - 1);
                            setDeleteIndex(index);
                          }}
                        />
                      </PopoverTrigger>
                      {isOpen && (
                        <PopoverContent rootProps={{ style: { right: 0 } }}>
                          <PopoverBody fontWeight="semibold">{t('createJobPosition:deleteQuestionConfirmation')}</PopoverBody>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverFooter d="flex" justifyContent="flex-end">
                            <ButtonGroup size="sm">
                              <Button variant="outline" onClick={onClose}>
                                {t('common:questions.answerNegative')}
                              </Button>
                              <Button
                                colorScheme="red"
                                onClick={() => {
                                  setDeleteIndex(null);
                                  deleteFreeFormQuestion(index);
                                  onClose();
                                }}
                                data-testid={`deleteQuestionConfirmBtn-${index}`}
                              >
                                {t('common:questions.answerPositive')}
                              </Button>
                            </ButtonGroup>
                          </PopoverFooter>
                        </PopoverContent>
                      )}
                    </Popover>
                  </Box>
                </Flex>
              </Flex>
            ))}
          </VStack>
        </Box>
      )}
    </Box>
  );
};
