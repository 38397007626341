import React from 'react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/core';
import { PositionManagementForTopCandidate } from './PositionManagementForTopCandidate';

type JobPositionTopCandidateProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const JobPositionModalView = ({ isOpen, onClose }: JobPositionTopCandidateProps): JSX.Element => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" size="xl">
      <ModalOverlay />
      <ModalContent maxW="45rem">
        <PositionManagementForTopCandidate />
      </ModalContent>
    </Modal>
  );
};
