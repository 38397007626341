import React, { useEffect, useState } from 'react';
import { Box, FlexProps, useColorMode } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import colors from '../../../../../../styles/colors';
import { SeekerContainerTabItems, useSelectedTab } from '../../../SeekerContainerContext';

export type TabButtonProps = Omit<FlexProps, 'fontWeight' | 'fontSize' | 'cursor'> & {
  item: SeekerContainerTabItems;
};

const TabButton = ({ item, marginRight = 8, paddingBottom = 1, ...rest }: TabButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(false);
  const { selectedTab, setSelectedTab } = useSelectedTab();
  const { colorMode } = useColorMode();
  const tabItemColor = { light: 'gray.600', dark: 'gray.300' };

  useEffect(() => {
    setSelected(selectedTab === item);
  }, [selectedTab, item]);

  return (
    <Box
      marginRight={marginRight}
      paddingBottom={paddingBottom}
      color={selected ? colors.blue.default : tabItemColor[colorMode]}
      fontWeight={selected ? 'bold' : 'normal'}
      fontSize="sm"
      borderBottomWidth={2}
      borderBottomColor={selected ? colors.blue.default : colors.transparent}
      direction="column"
      cursor="pointer"
      onClick={() => setSelectedTab(item)}
      data-testid="TabButton"
      {...rest}
    >
      {t(`tabs.${item}`)}
    </Box>
  );
};

export default TabButton;
