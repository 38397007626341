import React, { Fragment } from 'react';
import { Modal, Button, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import colors from '../../../../../styles/colors';
import { useStoreActions } from '../../../../../models/hooks';
import { NoteViewsEnum } from './NotesViewsEnum';

export type DeleteNoteConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setActiveView: (page: NoteViewsEnum) => void;
  accountId: string | undefined;
  positionId: string;
  seekerId: string | undefined;
  noteIndex: number | undefined;
};

export const DeleteNoteConfirmationModal = ({
  isOpen,
  onClose,
  setActiveView,
  accountId,
  positionId,
  seekerId,
  noteIndex,
}: DeleteNoteConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation(['notes']);

  const [isLoading, setIsLoading] = React.useState(false);

  const toast = useToast();

  const { deleteNote } = useStoreActions((actions) => actions.account);

  const deleteNoteAction = async () => {
    try {
      setIsLoading(true);
      await deleteNote({
        accountId: accountId as string,
        positionId,
        seekerId: seekerId as string,
        noteIndex: noteIndex as number,
      });
    } finally {
      setIsLoading(false);
      setActiveView(NoteViewsEnum.NOTE_LIST);
      toast({
        title: t('deleteSuccessMsg.title'),
        description: t('deleteSuccessMsg.description'),
        duration: 5000,
        isClosable: true,
      });
      onClose();
    }
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
        <ModalOverlay />
        <ModalContent data-testid="PreferenceModal">
          <ModalHeader className="modal-header" style={{ color: colors.blue.default }}>
            {t('deleteNote.headerText')}
          </ModalHeader>
          <ModalBody>
            <Text fontSize="1rem" lineHeight="24px" mb={3} color={colors.gray[700]}>
              <p>{t('deleteNote.description')}</p>
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button data-testid="CancelNoteDeleteButton" colorScheme="blue" mr={3} onClick={onClose} disabled={isLoading}>
              {t('messaging:actions.hire.confirmationModal.cancelButton')}
            </Button>
            <Button
              data-testid="ConfirmNoteDeleteButton"
              colorScheme="red"
              onClick={deleteNoteAction}
              isLoading={isLoading}
              disabled={isLoading}
            >
              {t('createNotes.deleteNote')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
