export default {
  env: process.env.REACT_APP_ENV,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  serverUrl: process.env.REACT_APP_SERVER_URL,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  bugsnagApiKey: '394dc0b249550fafe1006097ac43b336',
  hjid: process.env.REACT_APP_HJID,
  hjsv: process.env.REACT_APP_HJSV,
  vapidKey: process.env.REACT_APP_VAPID_KEY,
  gtmId: process.env.REACT_APP_GTM_ID,
  apiPicKey: process.env.REACT_APP_PUBLIC_PIC_API_KEY,
};
