/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment } from 'react';
import {
  Flex,
  Button,
  Box,
  Spacer,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Text,
  Link,
  useClipboard,
} from '@chakra-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { SiGooglemeet } from 'react-icons/si';
import { RiCheckboxMultipleFill, RiFileCopyLine, RiUserSettingsFill } from 'react-icons/ri';
import colors from '../../../styles/colors';
import { CalendarEventType } from '../CalendarEventType';
import { bookingDrawer } from '../bookingDetail/BookingDrawer.Style';

export type OtherCalendarEventDetailDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  eventDetail: CalendarEventType;
};

export const OtherCalendarEventDetailDrawer = ({
  isOpen,
  onClose,
  eventDetail,
}: OtherCalendarEventDetailDrawerProps): JSX.Element => {
  const { t } = useTranslation('calendar');
  const { hasCopied, onCopy } = useClipboard(eventDetail.eventLink ? eventDetail.eventLink : '');
  return (
    <Fragment>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay>
          <DrawerContent css={bookingDrawer} className="google-event-setail-drawer">
            <DrawerCloseButton _focus={{ border: '1px solid #1F3CBA' }} />
            <DrawerHeader borderColor="#EBEDF3" borderBottomWidth="1px">
              <Heading as="h3" size="sm" color={`${colors.blue.default} !important`}>
                {t('otherCalendar.eventDetail')}
              </Heading>
            </DrawerHeader>
            <DrawerBody p="0">
              <Box className="booking-title" borderColor="#EBEDF3" borderBottomWidth="1px" p="4">
                <Heading as="h4" size="xs" color={`${colors.blue.default} !important`}>
                  <Text fontSize="sm">{eventDetail.title}</Text>
                </Heading>
                <Text fontSize="sm">
                  {`${moment(eventDetail.start).format('hh:mm A')} - ${moment(eventDetail.end).format('hh:mm A')}`}
                </Text>
              </Box>
              {eventDetail.email !== eventDetail.creator?.email && (
                <Flex alignItems="center" p="15px 15px" borderBottom={`1px solid ${colors.gray['50']}`}>
                  <Text color="blue.500" d="flex" alignItems="center">
                    <RiUserSettingsFill />
                  </Text>
                  <Text fontSize="sm">
                    <Text as="span" color="blue.500" fontWeight="bold" mr={2}>
                      {`${t('otherCalendar.owner')} :`}
                    </Text>
                    {eventDetail.creator?.email}
                  </Text>
                </Flex>
              )}
              {eventDetail.eventLink && (
                <Flex data-testid="googleMeetLinkBar" alignItems="center" justifyContent="space-between" p="15px 15px">
                  <Box>
                    <Link
                      background="blue.500"
                      color="white"
                      d="inline-flex"
                      alignItems="center"
                      borderRadius="5px"
                      px={5}
                      py={2}
                      fontSize="sm"
                      fontWeight="bold"
                      href={eventDetail.eventLink}
                      isExternal
                      _hover={{ outline: 'none' }}
                    >
                      <SiGooglemeet />
                      <Spacer mr={1} />
                      {t('otherCalendar.meetBtnLabel')}
                    </Link>
                    <Text fontSize="xs" color="gray.200">
                      {eventDetail.eventLink.replace(/^https?:\/\//, '')}
                    </Text>
                  </Box>
                  <Box>
                    <Button
                      onClick={onCopy}
                      ml={2}
                      fontSize="xl"
                      bg="#F5F8FA"
                      color="blue.500"
                      size="sm"
                      _hover={{ bg: '#F5F8FA' }}
                      _active={{ bg: '#F5F8FA' }}
                    >
                      {/* istanbul ignore next */ hasCopied ? <RiCheckboxMultipleFill /> : <RiFileCopyLine />}
                    </Button>
                  </Box>
                </Flex>
              )}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Fragment>
  );
};
