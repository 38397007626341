import React from 'react';
import { Alert, Box, Text } from '@chakra-ui/core';
import { RiCalendar2Line } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import colors from '../../../styles/colors';
import { useStoreState } from '../../../models/hooks';

export const AvailabilityAlert = (): JSX.Element => {
  const { t } = useTranslation('notification');
  const history = useHistory();
  const currentUser = useStoreState((state) => state.app.user);

  const count = currentUser?.employerData?.[currentUser.account]?.availabilityWaitingCandidates;
  const routeToCreate = () => {
    if (history.location.pathname === '/calendar') {
      window.location.reload();
      window.history.replaceState({ state: true }, document.title);
    } else {
      history.push({
        pathname: '/calendar',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state: true,
      });
    }
  };
  return (
    <Alert status="error" variant="solid" data-testid="RedirectAlert" backgroundColor={colors.customRed} onClick={routeToCreate}>
      <Box mr={3} fontSize="lg">
        <RiCalendar2Line />
      </Box>
      <Text
        fontSize="sm"
        cursor="pointer"
        dangerouslySetInnerHTML={{
          __html: t(count === 0 ? 'availability.alert.lowAvailabilityNoCount' : 'availability.alert.lowAvailability', {
            candidates: count ?? '',
          }),
        }}
      />
    </Alert>
  );
};
