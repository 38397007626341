/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RemoteVideoTrack, Track, VideoTrack as IVideoTrack } from 'twilio-video';
import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import _isNil from 'lodash/isNil';
import _isFunction from 'lodash/isFunction';
import { useVideoTrackDimensions } from './hooks/useVideoTrackDimensions';
import { useMediaStreamTrack } from './hooks/useMediaStreamTrack';
import { TRACK_SCREEN } from '../constants';

const Video = styled('video')({
  width: '100%',
  height: '100%',
});

type VideoTrackProps = {
  track: IVideoTrack;
  priority: Track.Priority | null;
  isLocal?: boolean;
};

export const VideoTrack = ({ track, priority, isLocal }: VideoTrackProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = useRef<HTMLVideoElement>(null!);
  const dimensions = useVideoTrackDimensions(track);
  const mediaStreamTrack = useMediaStreamTrack(track);

  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);
  const isFrontFacing = mediaStreamTrack.getSettings().facingMode !== 'environment';

  const style = {
    transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
    objectFit:
      isPortrait || track.name.includes(TRACK_SCREEN) || _isNil(dimensions.height) ? ('contain' as const) : ('cover' as const),
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const el = ref.current;
    el.muted = true;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (priority && _isFunction(track.setPriority)) {
      (track as RemoteVideoTrack).setPriority(priority);
    }

    track.attach(el);

    return () => {
      track.detach(el);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (priority && _isFunction(track.setPriority)) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        (track as RemoteVideoTrack).setPriority(priority);
      }
    };
  }, [track, priority]);

  return <Video ref={ref} data-testid="VideoView" style={style} />;
};

VideoTrack.defaultProps = {
  isLocal: false,
};
