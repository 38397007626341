export default {
  video: `vidéo`,
  phone: `téléphone`,
  inPerson: `en personne`,
  eventForm: {
    successTitle: `Succès !`,
    errorTitle: `Erreur !`,
    errorMessage: `Veuillez remplir tous les champs avant d'enregistrer`,
    saveMessage: `Événement enregistré avec succès`,
    updateMessage: `Événement mis à jour avec succès`,
    groupEventTitle: 'Événement de groupe',
    eventTitle: `Titre de l'événement`,
    createEvent: `Créer un événement`,
    events: `Evénements`,
    eventDetails: `Détails de l'événement`,
    groupEventDetails: `Détails de\nl'événement de\ngroupe`,
    startVideoInterview: `Commencer l'entrevue vidéo`,
    startVideoCall: `Commencer l'appel vidéo`,
    rescheduleEvent: ` Événements de rééchelonnement`,
    potentialCandidates: `Candidats potentiels`,
    sendMessage: `Envoyer un message`,
    date: 'Date',
    timeCaption: 'Heure',
    startTime: `Heure de début`,
    endTime: `Heure de fin`,
    recruitersLabel: `Recruteurs`,
    recruiterHint: 'Sélectionner les recruteurs',
    emailHint: 'Saisir les courriels des candidats',
    titleHint: "Saisir le titre de l'événement",
    detailHint: "Entrer la description de l'événement",
    candidatesLabel: 'Candidats',
    description: `Description`,
    eventDescriptionLabel: `Description de l'événement`,
    saveEvent: `Enregistrer l'événement`,
    cancel: `Annuler`,
    close: `Fermer`,
    interviewLabel: `Entrevue`,
    interviewerLabel: 'Intervieweur',
    nameLabel: `Nom`,
    experienceLabel: `Expérience`,
    monthLabel: `Mois`,
    weekLabel: `Semaine`,
    dayLabel: `Jour`,
    todayLabel: `Aujourd'hui`,
  },
  eventListView: {
    tooltip: `L'événement ne peut être modifié que {allowDeletionMinutes} minutes avant l'heure de début`,
    expiredEvent: `Dans le passé`,
  },
  availabilityForm: {
    schedule: `Horaire`,
    myPositions: 'Mes postes',
    myPositionsToolTip: 'Vous pouvez voir les entrevues cédulées par position avec ce filtre.',
    myAvailabilityLabel: 'Ajouter une disponibilité',
    appointmentCalendarLabel: `Calendrier de entrevues`,
    appointmentTypeLabel: `Type d’entrevue`,
    appointmentDateLabel: `Date de disponibilité`,
    saveAvailability: `Enregistrer la disponibilité`,
    saveMessage: `Entrevues enregistré avec succès`,
    updateMessage: `Entrevues mis à jour avec succès`,
    bookingCapacity: 'Nombre de participants (en personne seulement)',
  },
  calendar: {
    totalBooked: 'Total des candidats invités ',
    availability: 'Disponibilité',
    meetings: 'Rencontre(s)',
    bookingCapacity: 'Nombre de participants:',
    individualInterview: 'Entrevues individuelles',
    noInterviews: `Aucune entrevue`,
    noGroupEvents: `Pas d’événements de groupe`,
    view: 'Vue',
    hrs: 'heures',
  },
  booking: {
    interviewHeader: "Détails de l'entrevue",
    cancelEvent: 'Annuler l’entrevue',
    experience: 'Plus de 10 ans',
    location: 'Lieu de l’entrevue',
    accept: 'Annuler l’entrevue',
    decline: 'Garder l’entrevue',
    description:
      "Une fois l'entrevue annulée, l'entrevue sera supprimée du calendrier et la plage sera disponible pour une autre entrevue.",
    warningDescription: "L'entrevue que vous essayez d'annuler est avec un autre recruteur.",
    interviewCancelReasons: `Raison pour l'annulation de l'entrevue`,
    interviewCancelReasonRequired: `Vous devez saisir le motif de l'annulation`,
  },
  interview: {
    location: 'Lieu de l’entrevue',
    rateBtn: 'Note de l’entrevue',
    longDuration: 'Plus de 10 ans',
  },
  otherCalendar: {
    otherCalendarLabel: 'Autres calendriers',
    addNewTooltip: 'Ajouter un nouveau calendrier',
    selectCalendarToConnect: 'Sélectionnez le calendrier à connecter',
    deleteConfirmationLabel: 'Êtes-vous sûr de vouloir supprimer ce calendrier?',
    noData: 'Aucun calendrier trouvé.',
    eventDetail: "Détails de l'événement",
    owner: 'Organisateur',
    meetBtnLabel: "Rejoindre l'événement",
    googleBtnLabel: 'Google Calendar',
    outlookBtnLabel: 'Outlook Calendar',
    deleteConfirmationLabelWithEmail: `Nous éprouvons des difficultés à extraire des événements pour {{ emailValue }}. Désirez vous le supprimer de votre calendrier?`,
  },
  availabilityDetail: {
    drawerTitle: 'Détails de la disponibilité',
    interviewType: `Les types d’entrevues qui peut être planifiés`,
    bookingCapacity: 'Nombre de participants',
    bookingCapacityHelpText: 'Ceci est le nombre de candidats par entrevue en personne',
    bookedInterviews: 'Entrevues planifiées',
    noInterviewsMessage: `Il n’y a aucune entrevue de planifiée pour cette période de disponibilité.`,
    deleteAvailability: 'Supprimer la disponibilité',
    confirmationMessageToDeleteAvailabilityWithOutBookings:
      'Êtes-vous certain de vouloir supprimer cette période de disponibilité?',
    waringMessageWhenUserTriesToDeletePassedAvailability:
      'Les disponibilités commençant dans le passé ne peuvent pas être supprimées.',
    warningMessageToDeleteAvailabilityWithOutBookings:
      'La période de disponibilité que vous essayez de supprimer appartient à un autre recruteur.',
    confirmationMessageToDeleteAvailabilityWithBookings:
      'Toutes les entrevues cédulées pendant cette période seront annulées. Êtes-vous certain de vouloir supprimer cette période de disponibilité?',
  },
  user: {
    googleCalendar: {
      alreadyIntegrated: 'Le calendrier Google est déjà intégré.',
    },
    microsoftTokens: {
      invalidAuthCode: "Code d'authentification invalide",
    },
    microsoftCalendar: {
      alreadyAdded: 'Calendrier Microsoft déjà ajouté.',
    },
  },
  collaborators: {
    interviewLink: "Lien pour l'entrevue",
    inviteCollaborators: 'Inviter des co-intervieweurs',
    link: 'Lien',
    linkCopied: "Lien d'invitation copié ",
    shortCode: 'Code de vérification',
    addCollaborators: 'Inviter des co-intervieweurs',
    addCollaboratorsInfo: "Les co-intervieweurs peuvent joindre l'entrevue et interagir avec le candidat.",
    email: 'Courriel',
    invite: 'Inviter',
    copy: 'Copier',
    cancel: 'Annuler',
    linkSend: "Lien d'invitation envoyé",
    linkSendError: "Le lien d'invitation ne pouvait pas être envoyé.",
    inviteeEmails: 'Co-intervieweur(s) invité(s)',
    interviewlink: "Lien pour l'entrevue",
    userAlreadyExists: "Le lien d'invitation ne pouvait pas être envoyé.\n",
  },
};
