import firebase from 'firebase/app';
import 'firebase/storage';

import { useMemo } from 'react';
import { useDownloadURL } from 'react-firebase-hooks/storage';

export const useEmployerPicture = (userId?: string, dialogFlow?: boolean): string | undefined => {
  const [downloadUrl, loading] = useDownloadURL(
    firebase.storage().ref(`/images/profilePictures/${dialogFlow ? 'dialogflowbot' : userId || 'generic'}.png`),
  );

  return useMemo(() => {
    if (!loading && downloadUrl) {
      return downloadUrl;
    }

    return undefined;
  }, [downloadUrl, loading]);
};
