import _isNil from 'lodash/isNil';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { Collections } from '../../../firebase/firestore/collections';
import { useQueryOnce } from '../../../firebase/firestore/hooks';
import { Query } from '../../../firebase/firestore/query/query';
import { Business } from '../../../firebase/firestore/documents/business';
import { BusinessSelectType } from './PositionType';

const useBusinessLocations = (): BusinessSelectType[] => {
  const { currentUserProfile } = useCurrentUserProfile();

  const businessLocations: Business[] | undefined = useQueryOnce<Business>(Collections.BUSINESSES, [
    Query.field<Business>('account').equals(currentUserProfile?.account),
  ]);

  if (_isNil(businessLocations) || !currentUserProfile) return [];

  // istanbul ignore next
  const sortedBusunessLocation = businessLocations.sort((a, b) => a.name.localeCompare(b.name));

  return sortedBusunessLocation?.map((businessItem) => {
    return {
      id: businessItem.id,
      label: `${businessItem.name} → ${businessItem.completeAddress}`,
      value: businessItem.name,
      locationId: businessItem.locationId,
      placeId: businessItem.placeId,
      positions: businessItem.positions,
      country: businessItem.country,
      longitude: businessItem.longitude,
      latitude: businessItem.latitude,
      completeAddress: businessItem.completeAddress,
      hasRequisition: businessItem.hasRequisition,
    } as BusinessSelectType;
  });
};

export default useBusinessLocations;
