import { useTranslation } from 'react-i18next';
import { Collections } from '../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Query } from '../../firebase/firestore/query/query';
import { Analytics } from '../../firebase/firestore/documents/analytics';
import { ANALYTICS_NAME, BUNDLE_ID_APP_BASED } from '../../firebase/firestore/constants';
import { getTranslatedValue } from '../../utils/localizedString';

export const useAnalytics = () => {
  const { i18n } = useTranslation();
  const analyticsUrl = useQuerySnapshot<Analytics>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_APP_BASED),
    Query.field('name').equals(ANALYTICS_NAME),
  ]);
  if (analyticsUrl) {
    return getTranslatedValue(analyticsUrl[0].value, i18n.language);
  }
  return undefined;
};
