import { Editable, EditablePreview, EditableInput, Input, FormControl, FormErrorMessage } from '@chakra-ui/core';
import { languages, SchemaBuilderProps } from './formBuilderSchema';
import React, { useEffect } from 'react';
import { useStoreState } from '../../../../models/hooks';
import isEqual from 'lodash/isEqual';
import Joi from 'joi';

export default function LabelSchemaBuilder({ ...props }: SchemaBuilderProps) {
  const value = props.defaultValue?.label?.[props.language];
  const { errors } = useStoreState((state) => state.hrFormTemplate);
  const [error, setError] = React.useState<Joi.ValidationErrorItem>();
  const path = [...props.path, 'label'];

  const serverError = errors?.find((error) => isEqual(error.path, path));
  const hasError = !!error;

  const labelSchema = Joi.object()
    .pattern(
      Joi.string().valid(...languages),
      Joi.string().min(3).required().messages({
        'any.required': `label is required`,
        'string.empty': `label is required`,
        'string.min': `label must be at least 3 characters long`,
      }),
    )
    .required()
    .messages({
      'any.required': `label is required`,
      'string.empty': `label is required`,
    });

  useEffect(() => {
    const formLabel = { ...props?.defaultValue?.label!, [props.language]: value };
    const error = labelSchema.validate(formLabel, { abortEarly: false });
    console.log('🚀 ~ LabelSchemaBuilder ~ error:', error);
    setError(error?.error?.details?.[0]);
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (serverError) {
      setError(serverError);
    }
  }, [serverError]);

  return (
    <Editable value={value} placeholder="Click to add Label" fontSize="xs" width="fit-content">
      <EditablePreview
        color={hasError ? 'red.500' : value ? 'black' : 'gray.600'}
        fontWeight={value ? 'bold' : 'normal'}
        width={'100%'}
        textDecoration={!value ? 'underline' : 'none'}
      />
      <FormControl isInvalid={hasError}>
        <Input
          as={EditableInput}
          width={'100%'}
          onChange={(e) => {
            const formLabel = { ...props?.defaultValue?.label!, [props.language]: e.target.value };
            props.onChange({ ...props.defaultValue!, label: formLabel });
          }}
          maxLength={50}
          placeholder="Enter label here..."
          isInvalid={hasError}
          errorBorderColor="red.300"
          fontSize="xs"
          variant="unstyled"
          size="sm"
        />
        {hasError && (
          <FormErrorMessage mt={0} fontSize="xs">
            {error?.message}
          </FormErrorMessage>
        )}
      </FormControl>
    </Editable>
  );
}
