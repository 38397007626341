import _isNil from 'lodash/isNil';
import { AxiosError } from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import { useCallback, useState } from 'react';
import { useToast } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { MeetTypeEnum } from '../../../../routes/constants';
import { ParticipantData } from '../../MeetTokenResponse';
import { VideoRoomAccessError, VideoRoomAccessInfo } from '../../models/videoRoomAccessInfo';

type MeetInfo = {
  connect: (token: string) => void;
  disconnect: () => void;
  meetToken?: string;
  meetType?: MeetTypeEnum;
  onError?: (error: Error) => void;
};

type MeetType = {
  joinRoom: () => void;
  quitRoom: () => void;
  joinGroupEventRoom: (participantInfo: ParticipantData) => void;
  quitGroupEventRoom: () => void;
  joinedByAnotherRecruiterName?: string | undefined;
};

export const useMeet = ({ meetToken, meetType, connect, disconnect, onError }: MeetInfo): MeetType => {
  const toast = useToast();
  const { t } = useTranslation('meet');
  const [joinedByAnotherRecruiterName, setJoinedByAnotherRecruiterName] = useState<string | undefined>('');
  // istanbul ignore next
  const { joinInterview, leaveInterview, joinMeet, leaveMeet, clearMeetGroupEvent } = useStoreActions((actions) => actions.meet);
  // istanbul ignore next
  const { clearInterviewEvent, clearInterviewStarted } = useStoreActions((actions) => actions.interview);
  // istanbul ignore next
  const groupEventDetail = useStoreState((state) => state.meet.groupEventDetail);
  const eventDetail = useStoreState(/* istanbul ignore next */ (state) => state.interview.eventDetail);

  const { updateRatingFor } = useStoreActions(/* istanbul ignore next */ (actions) => actions.interview);

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleJoin = useCallback(async () => {
    setJoinedByAnotherRecruiterName('');

    if (_isNil(meetToken) || _isEmpty(meetToken) || _isNil(meetType) || _isEmpty(meetType)) return;
    // istanbul ignore next
    joinInterview({ candidateId: meetToken })
      .then((accessToken) => {
        const accessCode = accessToken as VideoRoomAccessInfo;
        const errMessage = accessToken as VideoRoomAccessError;
        // For some reason, null returned from thunk is not handled properly
        if (_isNil(accessToken) || _isEmpty(accessToken) || _isString(errMessage)) {
          onError?.(new Error('No ongoing interview. Please try again later.'));
          toast({
            title: 'No ongoing interview. Please try again later.',
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        if (errMessage && errMessage.code) {
          toast({
            title: t(`errorCode.${errMessage.code}`),
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        connect(accessCode.accessToken);
        setJoinedByAnotherRecruiterName('');
      })
      .catch((errorData: AxiosError) => {
        const recruiterName = errorData.message;
        setJoinedByAnotherRecruiterName(recruiterName);
      });
  }, [connect, joinInterview, meetToken, meetType, onError, toast, t]);

  const handleJoinOfGroupEvent = useCallback(
    async (participantInfo: ParticipantData) => {
      if (
        _isNil(meetToken) ||
        _isEmpty(meetToken) ||
        _isNil(meetType) ||
        _isEmpty(meetType) ||
        _isNil(participantInfo) ||
        _isEmpty(participantInfo)
      )
        return;

      const accessTokenMeet = await joinMeet({
        groupEventId: participantInfo.groupEventId,
        participantId: participantInfo.participant.id,
      });

      // For some reason, null returned from thunk is not handled properly
      // istanbul ignore next
      if (_isNil(accessTokenMeet) || _isEmpty(accessTokenMeet)) {
        onError?.(new Error('No ongoing interview. Please try again later.'));
        return;
      }

      connect(accessTokenMeet.accessToken);
    },
    [connect, joinMeet, meetToken, meetType, onError],
  );

  const handleQuit = useCallback(async () => {
    if (_isNil(meetToken) || _isEmpty(meetToken) || _isNil(meetType) || _isEmpty(meetType)) return;
    await leaveInterview({ candidateId: meetToken });
    clearInterviewEvent();
    clearInterviewStarted();

    // istanbul ignore next
    if (eventDetail && eventDetail.candidate) {
      const { firstName } = eventDetail.candidate;
      const { seeker } = eventDetail.candidate;
      const { position } = eventDetail.candidate;
      updateRatingFor({ name: firstName, seekerId: seeker, positionId: position, selectedCandidate: eventDetail.candidate });
    }

    disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetToken, meetType, clearInterviewEvent, clearInterviewStarted, disconnect, leaveInterview, updateRatingFor]);

  const handleQuitOfGroupEvent = useCallback(async () => {
    if (_isNil(meetToken) || _isEmpty(meetToken) || _isNil(meetType) || _isEmpty(meetType) || _isNil(groupEventDetail)) return;

    await leaveMeet({
      groupEventId: groupEventDetail.groupEventId,
      participantId: groupEventDetail.participant.id,
    });
    clearMeetGroupEvent();
    disconnect();
  }, [meetToken, meetType, groupEventDetail, leaveMeet, clearMeetGroupEvent, disconnect]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    joinRoom: handleJoin,
    quitRoom: handleQuit,
    joinGroupEventRoom: handleJoinOfGroupEvent,
    quitGroupEventRoom: handleQuitOfGroupEvent,
    joinedByAnotherRecruiterName,
  };
};
