import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { Box, Button, chakra, FormLabel, HStack, Menu, MenuButton, MenuItem, MenuList, Switch, Text } from '@chakra-ui/core';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/table';
import { useTable, useSortBy, Column } from 'react-table';
import { mainTable, tableHeading } from '../recruiterManagement/recruiterDashboard/recruitersInfoTable/RecruiterInfoTableStyle';
import moment from 'moment';
import { IoEllipsisVertical, IoAdd } from 'react-icons/io5';

import { TablePaginationFooter } from '../../../app/components/TablePaginationFooter';
import { HrFromTemplate } from './formElements/formBuilderSchema';
import { useHistory } from 'react-router-dom';

// const getLatestUpdatedDate = (array: any[]) => {
//   const latestDate = array.reduce((latest, current) => {
//     const latestMoment = moment(latest.updatedDate);
//     const currentMoment = moment(current.updatedDate);
//     return currentMoment.isAfter(latestMoment) ? current : latest;
//   }, array[0]);
//   return moment(latestDate.updatedDate).fromNow();
// };

export default function HRFormTemplateView() {
  const { getTemplates } = useStoreActions((state) => state.hrFormTemplate);
  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line
  }, []);
  return <DataTable />;
}

function DataTable() {
  const [page, setPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [currentItems, setCurrentItems] = React.useState<HrFromTemplate[]>([]);
  const { templates } = useStoreState((state) => state.hrFormTemplate);
  const history = useHistory();

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Template Name',
          accessor: 'name',
        },
        {
          Header: 'Created Date',
          accessor: 'createdAt',
        },
        {
          Header: 'Edit History',
          accessor: 'updatedAt',
        },
        {
          Header: 'Action',
          accessor: 'action',
        },
      ] as Column<HrFromTemplate>[],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { data: currentItems, columns },
    useSortBy,
  );

  function handleCreateNewTemplate() {
    history.push('/hire-form');
  }

  return (
    <Box>
      <HStack justify="space-between" paddingX={4} paddingY={3}>
        <Text fontSize="lg" fontWeight={700}>
          HR Form Templates
        </Text>
        <HStack spacing={4}>
          <HStack>
            <FormLabel htmlFor="hr-form-templates-defaults" mb="0">
              Show defaults
            </FormLabel>
            <Switch id="hr-form-templates-defaults" size="sm" />
          </HStack>
          <Button colorScheme="blue" leftIcon={<IoAdd size={24} />} size="md" onClick={handleCreateNewTemplate}>
            <Text>Add New Template</Text>
          </Button>
        </HStack>
      </HStack>
      <Table {...getTableProps()} css={mainTable} className="mainTable simple-bordered-table">
        <Thead css={tableHeading} className="tableHeading">
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())} isNumeric={column.isNumeric} align="left">
                  {column.render('Header')}
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  switch (cell.column.id) {
                    case 'updatedAt':
                      return (
                        <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                          <HStack>
                            {/* <AvatarGroup size="sm" max={4}>
                              {cell.value.map((item: any, index: number) => {
                                return <Avatar name={item.name} key={index} />;
                              })}
                            </AvatarGroup> */}
                            <Text fontSize={'sm'} color={'gray.500'} fontWeight={'400'}>
                              {/* {getLatestUpdatedDate(cell.value)} */}
                              {moment(cell.value).format('MMM Do YYYY')}
                            </Text>
                          </HStack>
                        </Td>
                      );
                    case 'createdAt':
                      return (
                        <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                          {moment(cell.value).format('MMM Do YYYY')}
                        </Td>
                      );
                    case 'action':
                      return (
                        <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                          <Menu>
                            <MenuButton
                              px={2}
                              py={2}
                              transition="all 0.2s"
                              borderRadius="md"
                              _hover={{ bg: 'gray.400' }}
                              // _expanded={{ bg: 'blue.400' }}
                              _focus={{ boxShadow: 'outline', backgroundColor: 'none' }}
                            >
                              <IoEllipsisVertical size={20} color="#6B7280" />
                            </MenuButton>
                            <MenuList>
                              <MenuItem>Duplicate</MenuItem>
                              <MenuItem onClick={() => history.push(`/hire-form/${cell.row.original.id}/edit`)}>Edit</MenuItem>
                              <MenuItem color={'red.500'}>Delete</MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      );
                    default:
                      return (
                        <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                          {cell.render('Cell')}
                        </Td>
                      );
                  }
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <TablePaginationFooter
        setCurrentItemsList={(items: any[]) => {
          setCurrentItems(
            items.map((item) => {
              return {
                ...item,
                action: true,
              };
            }),
          );
        }}
        finalListOfItems={templates || []}
        currentPage={page}
        setCurrentPage={setPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
      />
    </Box>
  );
}
