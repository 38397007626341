/** @jsx jsx */
import { Box, Button, Flex, Grid } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { jsx } from 'theme-ui';
import { motion } from 'framer-motion';
import { memo } from 'react';
import { Label } from '../modules/common';
import { errorView, errorViewWrapper, labelErrorViewWrapper } from './ErrorView.styles';
import { ANIMATED_VARIANTS } from '../routes/constants';
import { ReactComponent as _ErrorImage } from '../assets/img/error.svg';

const ErrorImage = memo(_ErrorImage);
const MotionBox = motion.custom(Box);

export const ErrorView = (): JSX.Element => {
  const { t } = useTranslation('error');
  return (
    <MotionBox css={errorViewWrapper} px="2vw" initial="initial" animate="in" exit="out" variants={ANIMATED_VARIANTS}>
      <Box css={errorView}>
        <Grid gridRow="1">
          <Flex justify="center" align="center" direction="row">
            <ErrorImage data-testid="ErrorImageSVG" />
          </Flex>
          <Flex justify="center" align="center" direction="row" paddingTop={60} paddingBottom={26}>
            <Label whiteSpace="initial" textAlign="center" css={labelErrorViewWrapper}>
              {t('experiencingDifficulty')}
            </Label>
          </Flex>
          <Flex justify="center" align="center" direction="row">
            <Button data-testid="ErrorButton" onClick={() => window.location.reload()} colorScheme="blue">
              {t('reload')}
            </Button>
          </Flex>
        </Grid>
      </Box>
    </MotionBox>
  );
};
