import _map from 'lodash/map';
import { Recruiter } from '../../../../../firebase/firestore/documents/recruiter';
import { useQueryOnce } from '../../../../../firebase/firestore/hooks';
import { Collections } from '../../../../../firebase/firestore/collections';
import { Query } from '../../../../../firebase/firestore/query/query';
import { User } from '../../../../../firebase/firestore/documents/user';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';

const useRecruiter = (): Recruiter[] => {
  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';

  const recruiters: User[] | undefined = useQueryOnce<User>(Collections.USERS, [
    Query.field<User>('account').equals(accountId),
    Query.field<User>('isEmployer').equals(true),
  ]);

  if (recruiters && currentUserProfile && accountId) {
    const filteredRecruiters = recruiters.filter((r) => r.anonymousUser !== true && r.firstName !== 'anonymous');
    return _map(filteredRecruiters, (r) => {
      return r as Recruiter;
    });
  }

  return [];
};

export default useRecruiter;
