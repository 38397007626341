import { Collections, SubCollections } from '../../../../../../firebase/firestore/collections';
import { useSubCollectionQuerySnapshot } from '../../../../../../firebase/firestore/hooks';
import { Requisition } from '../../../../../../firebase/firestore/documents/requisition';
import { Query } from '../../../../../../firebase/firestore/query/query';

const useRequisitionsByPosition = (positionId: string, status: string[]): Requisition[] | undefined => {
  const requisitions: Requisition[] | undefined = useSubCollectionQuerySnapshot<Requisition>(
    Collections.POSITIONS,
    positionId,
    SubCollections.REQUISITIONS,
    [Query.field('status').in(status)],
  );

  return requisitions;
};

export default useRequisitionsByPosition;
