import { css } from '@emotion/core';
import colors from '../../../styles/colors';

export const calendarCreateEvent = css`
  .auto-picker-box {
    position: relative;
    border: 1px solid ${colors.neutralGray[600]};
    border-radius: 0.375rem;
    min-height: 40px;

    .chakra-form__label {
      pointer-events: none;
    }

    input {
      padding-left: 5px;
      height: 25px;
      border-radius: 3px;
      border: 0;
      display: inline;
      width: initial;
      max-width: 100%;

      &:hover,
      &:focus,
      &:active {
        outline: none;
        border: 0;
        box-shadow: none;
        border-color: ${colors.blue.default};
      }
    }

    svg.icon-add-user {
      position: absolute;
      bottom: 1px;
      padding: 5px;
      background: #dadded;
      width: 30px;
      left: 0;
      top: 0;
      height: 100%;
    }

    .chakra-stack {
      > div:last-child {
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        transform: translateY(100%);
        max-height: 200px;
        overflow: auto;
        z-index: 2;
        background: #fff;
        padding: 0;
        margin: 0;
      }

      .chakra-form__label {
        margin-bottom: 0;
      }

      .chakra-button {
        display: none;
      }
    }

    ul li svg {
      color: ${colors.blue[500]};
    }

    &.candidate-auto-complete {
      padding: 5px 5px 5px 35px;

      .candidate-email-wrapper {
        display: inline;
      }
    }
  }
`;
