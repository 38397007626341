import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/table';
import { Box, Text, ScaleFade, Alert, AlertIcon } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, Column } from 'react-table';
import _isNil from 'lodash/isNil';
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti';
import { GoPrimitiveDot } from 'react-icons/go';
import { mainTable, tableHeading, recruiterNameCell, sortIconWrapper } from './RecruiterInfoTableStyle';
import { RecruiterDashboardTabItems, RecruiterSelectType, useRecruiterManagementContext } from '../../RecruiterManagementContext';
import { RemoteConfigKey } from '../../../../../firebase/remoteConfig';
import { useStoreState } from '../../../../../models/hooks';

export type RecruiterTableViewProps<Data extends Record<string, unknown>> = {
  data: Data[];
  columns: Column<Data>[];
};

export default function RecruiterTableView<Data extends Record<string, unknown>>({
  data,
  columns,
}: RecruiterTableViewProps<Data>) {
  const isRecruiterSearchFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.DASHBOARD_ENABLE_RECRUITER_SEARCH];
  });
  const { t } = useTranslation('notification');
  const { setSelectedTab, setSelectedRecruiter } = useRecruiterManagementContext();

  const onRowClick = (id: string, label: string) => {
    const dataForSelect: RecruiterSelectType = {
      id,
      label,
      value: id,
    };
    setSelectedRecruiter(dataForSelect);
    setSelectedTab(RecruiterDashboardTabItems.RecruiterProfile);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
      },
    },
    useSortBy,
  );

  return (
    <ScaleFade initialScale={0.7} in unmountOnExit={false}>
      {data.length ? (
        <Table {...getTableProps()} css={mainTable} className="mainTable" key="mainTable">
          <Thead css={tableHeading} className="tableHeading" pl="4">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()} textAlign="left" key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    isNumeric={column.isNumeric}
                    key={column.getHeaderProps().key}
                    className="fixBorder"
                    style={{ borderColor: '#d0e9f4' }}
                    paddingLeft="1rem"
                  >
                    <Box className={column.isNumeric ? 'styleForNumbers' : ''} style={{ display: 'flex', maxWidth: '100%' }}>
                      <Text textTransform="none" display="inline">
                        {column.render('Header')}
                      </Text>
                      <Box pl="4" display="inline" css={sortIconWrapper} className="sortIconWrapper">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {column.isSorted ? (
                          // istanbul ignore next
                          column.isSortedDesc ? (
                            <TiArrowSortedUp aria-label="sorted descending" color="#BCC2CE" style={{ display: 'inline' }} />
                          ) : (
                            <TiArrowSortedDown aria-label="sorted ascending" color="#BCC2CE" style={{ display: 'inline' }} />
                          )
                        ) : (
                          <TiArrowUnsorted color="#BCC2CE" style={{ display: 'inline' }} data-testid="sortedAscendingBtn" />
                        )}
                      </Box>
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  style={{ height: '40px' }}
                  key={row.getRowProps().key}
                  cursor="pointer"
                  _hover={{ background: 'lightgrey', opacity: '0.95' }}
                >
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      pr=""
                      key={cell.getCellProps().key}
                      data-testid="tableRow"
                      className={cell.column.id === 'name' ? 'nameFixedWidth fixBorder' : 'fixBorder'}
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        isRecruiterSearchFeatureEnabled &&
                          onRowClick(cell.row.original.id as string, cell.row.original.name as string);
                      }}
                      paddingLeft="1rem"
                    >
                      {cell.column.id === 'name' && (
                        <Text css={recruiterNameCell} className="recruiterNameCell name" data-testid="nameCell">
                          {cell.value !== undefined ? cell.render('Cell') : 'N/A'}
                        </Text>
                      )}
                      {cell.column.id === 'availability' && (
                        <Text
                          css={recruiterNameCell}
                          className={`recruiterNameCell ${String(cell.value).toLowerCase()}`}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <GoPrimitiveDot />
                          {cell.value !== undefined
                            ? t(`recruiterManagement:recruiterTable.lowToHighString.${cell.value}`)
                            : 'N/A'}
                        </Text>
                      )}
                      {cell.column.id === 'totalAvailabilityHours' && (
                        <Text
                          css={recruiterNameCell}
                          className="recruiterNameCell styleForNumbers"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {!_isNil(cell.value) ? cell.render('Cell') : '0'}
                        </Text>
                      )}
                      {cell.column.id === 'requiredDuration' && (
                        <Text
                          css={recruiterNameCell}
                          className="recruiterNameCell styleForNumbers"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {!_isNil(cell.value) ? cell.render('Cell') : '0'}
                        </Text>
                      )}

                      {cell.column.id === 'noOfHirings' && (
                        <Text
                          css={recruiterNameCell}
                          className="recruiterNameCell styleForNumbers"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {!_isNil(cell.value) ? cell.render('Cell') : '0'}
                        </Text>
                      )}
                      {cell.column.id === 'successfulInterviews' && (
                        <Text css={recruiterNameCell} className="recruiterNameCell styleForNumbers">
                          {cell.value !== undefined ? cell.render('Cell') : 'N/A'}
                        </Text>
                      )}
                      {cell.column.id === 'candidateNoShow' && (
                        <Text css={recruiterNameCell} className="recruiterNameCell styleForNumbers">
                          {cell.value !== undefined ? cell.render('Cell') : 'N/A'}
                        </Text>
                      )}
                      {cell.column.id === 'interviewCancellations' && (
                        <Text css={recruiterNameCell} className="recruiterNameCell styleForNumbers">
                          {cell.value !== undefined ? cell.render('Cell') : 'N/A'}
                        </Text>
                      )}
                      {cell.column.id === 'recruiterNoShow' && (
                        <Text css={recruiterNameCell} className="recruiterNameCell styleForNumbers">
                          {cell.value !== undefined ? cell.render('Cell') : 'N/A'}
                        </Text>
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <Alert status="info" backgroundColor="gray.400" color="white" data-testid="noDataFoundAlert">
          <AlertIcon color="white" />
          {t('noDataFound')}
        </Alert>
      )}
    </ScaleFade>
  );
}
