import _isNil from 'lodash/isNil';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { Collections } from '../../../firebase/firestore/collections';
import { useQueryOnce } from '../../../firebase/firestore/hooks';
import { Query } from '../../../firebase/firestore/query/query';
import { Position } from '../../../firebase/firestore/documents/position';

const usePositions = (): Position[] => {
  const { currentUserProfile } = useCurrentUserProfile();

  const positions: Position[] | undefined = useQueryOnce<Position>(Collections.POSITIONS, [
    Query.field<Position>('account').equals(currentUserProfile?.account),
    Query.field<Position>('open').equals(true),
  ]);

  if (_isNil(positions) || !currentUserProfile) return [];

  return positions;
};

export default usePositions;
