import React, { memo, useEffect, useState } from 'react';
import _isNil from 'lodash/isNil';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/core';
import { StarIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import DatePicker from 'react-datepicker';
import i18next from 'i18next';
import { RiPhoneFill, RiUserVoiceFill, RiVidiconFill } from 'react-icons/ri';
import moment from 'moment';
import useLookupsByType from '../../../../app/hooks/useLookupsByType';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { getTranslatedValue } from '../../../../utils/localizedString';
import { useMessagingViewContext } from '../../MessagingViewContext';
import { IndustryRadiosStyle } from '../../../administration/positionManagement/createJobs/CreateJobPositionStyle';
import { ReactComponent as _IconCalendar } from '../../../../assets/img/icon-calendar-blue.svg';
import colors from '../../../../styles/colors';
import { reactDatePickerCSS } from '../../../calendar/scheduleSidebar/ReactDatePicker.Style';
import { Candidate } from '../../../../firebase/firestore/documents/candidate';
import { usePositionDocumentSnapshot } from '../../seeker/header/actionsDropdown/usePositionDocumentSnapshot';
import { AppointmentType } from '../../../../firebase/firestore/documents/position';

const IconCalendar = memo(_IconCalendar);

const datePickerStyle = css`
  .react-datepicker-wrapper {
    display: block;
  }
`;

const interviewTypes = [
  { label: { en: 'In Person', fr: 'Sur place' }, value: AppointmentType.inPerson },
  { label: { en: 'Video', fr: 'Vidéo' }, value: AppointmentType.video },
  { label: { en: 'Phone', fr: 'Téléphone' }, value: AppointmentType.phone },
];

export const loadInterviewIcon = (iconType: string) => {
  switch (iconType) {
    case 'inPerson':
      return <RiUserVoiceFill style={{ color: colors.blue[500], width: '28px', height: '28px' }} />;
    case 'video':
      return <RiVidiconFill style={{ color: colors.blue[500], width: '28px', height: '28px' }} />;
    default:
      return <RiPhoneFill style={{ color: colors.blue[500], width: '28px', height: '28px' }} />;
  }
};

export type InterviewRatingPropsType = {
  onSelectNextCandidateAfterRating: () => void;
  onClose: () => void;
};
export const InterviewRating = ({ onSelectNextCandidateAfterRating, onClose }: InterviewRatingPropsType): JSX.Element => {
  const [rating, setRating] = useState(0);
  const [reason, setReason] = useState('');
  const [remarks, setRemarks] = useState('');
  const [interviewDate, setInterviewDate] = React.useState<Date | null>(new Date());
  const [interviewType, setInterviewType] = React.useState<AppointmentType>(AppointmentType.video);
  const [submitting, setSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const toast = useToast();
  const ratingStars: number[] = Array.from({ length: 5 }, (_, i) => i);
  const lookups = useLookupsByType('negativeAssessmentReasons');
  const { t, i18n } = useTranslation('messaging');
  const appUserId = useStoreState((s) => s.app.user?.id);
  const ratingFor = useStoreState((state) => state.interview.ratingFor);
  const clearRatingFor = useStoreActions((state) => state.interview.clearRatingFor);
  const rateInterview = useStoreActions((state) => state.meet?.rateInterview);
  const { selectedEngagedCandidate, setSelectedEngagedCandidate } = useMessagingViewContext();
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate | undefined>();
  const isDisabled = (): boolean => {
    if (!remarks.trim()) return true;
    if (rating > 1) return false;
    if (rating <= 1 && reason) return false;
    return true;
  };
  const positionDocument = usePositionDocumentSnapshot(selectedCandidate?.position);

  /* istanbul ignore next */
  useEffect(() => {
    if (ratingFor && appUserId) {
      const reviews = ratingFor.selectedCandidate.recruiterInterviewReview;
      if (reviews) {
        const feedback = reviews[appUserId];
        if (feedback) {
          setRating(parseInt(feedback.rating, 10));
          setRemarks(feedback.assessmentReason === 'APPOINTMENT_ASSESSMENT_NO_SHOW' ? '' : feedback.review);
          setReason(feedback.assessmentReason);
          setInterviewType(feedback.interviewType);
          setInterviewDate(moment(feedback.date).toDate());
        }
      }
      setSelectedCandidate(ratingFor.selectedCandidate);
    }
  }, [appUserId, ratingFor, isOpen]);

  /* istanbul ignore next */
  const handleSubmit = async (): Promise<void | null> => {
    const pathString = window.location.pathname.split('/');
    const splitCandidateId = pathString[2].split('_');
    const seeker = splitCandidateId[1];
    const position = splitCandidateId[0];
    setSubmitting(true);
    try {
      if (!_isNil(rateInterview) && !_isNil(interviewDate) && positionDocument?.business)
        await rateInterview({
          positionId: position,
          seekerId: seeker,
          rating,
          assessmentReason: reason,
          date: interviewDate,
          interviewType,
          businessId: positionDocument?.business,
          review: remarks,
        });
      if (selectedEngagedCandidate?.recruiterInterviewReview && appUserId) {
        const feedback = selectedEngagedCandidate?.recruiterInterviewReview[appUserId];

        const lastEdited = new Date();
        let recruiterReview = {
          rating: '',
          review: '',
          lastEdited,
          assessmentReason: '',
          date: moment().toDate(),
          interviewType: AppointmentType.video,
        };
        if (feedback) {
          recruiterReview.rating = rating.toString();
          recruiterReview.review = remarks;
          recruiterReview.date = !_isNil(interviewDate) ? interviewDate : moment().toDate();
          recruiterReview.interviewType = interviewType;
        } else {
          recruiterReview = {
            rating: rating.toString(),
            lastEdited,
            review: remarks,
            assessmentReason: reason,
            date: !_isNil(interviewDate) ? interviewDate : moment().toDate(),
            interviewType,
          };
        }

        selectedEngagedCandidate.recruiterInterviewReview[appUserId] = recruiterReview;
        setSelectedEngagedCandidate(selectedEngagedCandidate);
      }
      toast({
        title: t('interviewRating.successful'),
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: t('interviewRating.unsuccessful'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setSubmitting(false);
      return null;
    }
    setSubmitting(false);
    clearRatingFor();
    onClose();
    // APPOINTMENT_ASSESSMENT_REMOVE_FOREVER
    if (rating && rating <= 1 && reason === 'APPOINTMENT_ASSESSMENT_REMOVE_FOREVER') {
      onSelectNextCandidateAfterRating();
    }
    return null;
  };

  const CustomDatePickerInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <ButtonGroup
      d="flex"
      bg="gray.150"
      className="customDatepicker"
      size="md"
      isAttached
      variant="outline"
      onClick={props.onClick}
      ref={ref}
    >
      <Button fontWeight="normal" mr="-px" borderColor="gray.150" style={{ width: '100%' }} justifyContent="flex-start">
        {props.value}
      </Button>
      <IconButton
        borderColor="gray.150"
        style={{ padding: '0.2rem' }}
        aria-label="calendar"
        icon={<IconCalendar width={20} height={20} />}
      />
    </ButtonGroup>
  );

  return (
    <Modal
      closeOnOverlayClick={false}
      onClose={
        /* istanbul ignore next */ () => {
          setIsOpen(false);
          clearRatingFor();
          onClose();
        }
      }
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius="20px" maxWidth="55rem">
        <ModalCloseButton />
        <ModalBody style={{ marginTop: '20px', padding: '25px' }} data-testid="InterviewRatingModal">
          <Box mb={6}>
            <Heading as="h5" size="md">
              {t('interviewRating.title')}
            </Heading>
          </Box>
          <Flex mb={5}>
            <Box w="49%" mr="2%">
              <FormControl isRequired>
                <FormLabel htmlFor="jobLocation" fontWeight="bold">
                  {t('createJobPosition:businessLocation')}
                </FormLabel>
                <Box data-testid="businessLocationNameField" cursor="not-allowed" bg="gray.150" borderRadius="3px" px={3} py={2}>
                  {selectedCandidate?.businessName}
                </Box>
              </FormControl>
            </Box>
            <Box w="49%">
              <FormControl isRequired>
                <FormLabel htmlFor="jobPosition" fontWeight="bold">
                  {t('createJobPosition:positionNamePlaceholder')}
                </FormLabel>
                <Box data-testid="positionNameField" cursor="not-allowed" bg="gray.150" borderRadius="3px" px={3} py={2}>
                  {getTranslatedValue(selectedCandidate?.jobTitle || {}, i18n.language)}
                </Box>
              </FormControl>
            </Box>
          </Flex>
          <Flex mb={5}>
            <Box w="49%" mr="2%">
              <FormControl isRequired>
                <FormLabel htmlFor="jobLocation" fontWeight="bold">
                  {t('interviewRating.dateAndTimeOFInterview')}
                </FormLabel>
                <Box css={reactDatePickerCSS} data-testid="interviewDateBtn">
                  <Box css={datePickerStyle}>
                    <DatePicker
                      selected={interviewDate}
                      customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                      locale={i18n.language}
                      showTimeSelect
                      maxDate={moment().toDate()}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      shouldCloseOnSelect
                      onChange={
                        /* istanbul ignore next */ (date) => {
                          setInterviewDate(date as Date);
                        }
                      }
                    />
                  </Box>
                </Box>
              </FormControl>
            </Box>
            <Box w="49%">
              <FormControl isRequired>
                <FormLabel htmlFor="jobPosition" fontWeight="bold">
                  {t('interviewRating.interviewType')}
                </FormLabel>
                <Box data-testid="PositionNameChangeBtn">
                  <RadioGroup
                    name="interviewType"
                    id="interviewTypeLabel"
                    css={IndustryRadiosStyle}
                    value={interviewType}
                    colorScheme="blue"
                    data-testid="interviewType"
                  >
                    <Flex direction="row" wrap="wrap" justify="flex-start">
                      {interviewTypes.map((interviewTypeItem) => {
                        return (
                          <Radio
                            value={interviewTypeItem.value}
                            key={interviewTypeItem.value}
                            mr={2}
                            mt={0}
                            data-testid={`InterviewType-${interviewTypeItem.value}`}
                            onChange={(e) => setInterviewType(e.target.value as AppointmentType)}
                          >
                            <Box>{loadInterviewIcon(interviewTypeItem.value)}</Box>
                            {getTranslatedValue(interviewTypeItem.label, i18next.language)}
                          </Radio>
                        );
                      })}
                    </Flex>
                  </RadioGroup>
                </Box>
              </FormControl>
            </Box>
          </Flex>
          <Box>
            <FormControl isRequired>
              <FormLabel>{t('interviewRating.feedbackDetail')}</FormLabel>
            </FormControl>
            <Box mt={5} mb={6}>
              {ratingStars.map((k) => {
                const val: number = k + 1;
                let color = '#FF6600';
                if (rating < val) {
                  color = '#FFFFFF';
                }

                return (
                  <StarIcon
                    boxSize={7}
                    color={color}
                    style={{ marginRight: '10px', cursor: 'pointer', stroke: '#FF6600' }}
                    onClick={() => {
                      setRating(val);
                      setReason('');
                    }}
                    key={k}
                    data-testid="RatingStars"
                  />
                );
              })}
            </Box>
            {rating && rating <= 1 ? (
              <RadioGroup
                onChange={(rsn) => {
                  setReason(rsn as string);
                }}
                name="lowReason"
                value={reason}
                mt={5}
                mb={5}
                data-testid="RatingReasonsGroup"
              >
                <FormControl isRequired>
                  <FormLabel fontWeight="bold">{t('interviewRating.lowReason')}</FormLabel>
                  <Stack>
                    {lookups.map((lu) => {
                      const label = getTranslatedValue(lu.label, i18n.language);
                      return (
                        <Radio value={lu.id} key={label} data-testid="RatingReasons" paddingBottom="5px">
                          <Text fontSize="sm">{label}</Text>
                        </Radio>
                      );
                    })}
                  </Stack>
                </FormControl>
              </RadioGroup>
            ) : null}
            <Box>
              <FormControl isRequired>
                <FormLabel fontWeight="bold">{t('interviewRating.recruitersFeedback')}</FormLabel>
                <Textarea
                  placeholder={t('interviewRating.commentPlaceholder')}
                  border="none"
                  value={remarks}
                  mt={1}
                  borderRadius="3px"
                  p={4}
                  bg="gray.150"
                  data-testid="RatingRemarksInput"
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </FormControl>
            </Box>
            <Box>
              <Button
                colorScheme="blue"
                style={{ float: 'right', marginTop: '20px' }}
                isDisabled={isDisabled()}
                isLoading={submitting}
                onClick={() => handleSubmit()}
                name="Submit"
                data-testid="SubmitRatingButton"
              >
                {t('actions.hire.confirmationModal.sendButton')}
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
