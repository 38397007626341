import { useQueryOnce } from '../../../../../firebase/firestore/hooks';
import { Collections } from '../../../../../firebase/firestore/collections';
import { ContactRequestDeclinedReasonsTypes } from '../../../../../firebase/firestore/documents/contactRequestDeclinedReasonsTypes';

const useContactRequestDeclineReasons = (): ContactRequestDeclinedReasonsTypes[] => {
  const declinedReasons = useQueryOnce<ContactRequestDeclinedReasonsTypes>(
    Collections.CONTACT_REQUEST_DECLINED_REASONS,
    [],
    false,
  );

  if (!declinedReasons || declinedReasons.length === 0 || !declinedReasons.length) {
    return [];
  }

  return declinedReasons;
};

export default useContactRequestDeclineReasons;
