import { css } from '@emotion/core';

export const SupportDetailCSS = css`
  h2 {
    font-size: 2.2rem;
    line-height: 1.4;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  h3 {
    font-size: 1.8rem;
    line-height: 1.4;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
  h4 {
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  }
  ul {
    margin-bottom: 1.5rem;
    margin-left: 1rem;
  }
  iframe {
    width: 100%;
    max-width: 100%;
    height: 400px;
    margin-bottom: 1rem;
  }
`;
