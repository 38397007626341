import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';

export class GroupEventController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async acceptInvitation(token: string): Promise<AxiosResponse> {
    const path = `/api/v1/groupEvent/accept?token=${token}`;
    return this.httpClient.get(path);
  }

  async declineInvitation(token: string): Promise<AxiosResponse> {
    const path = `/api/v1/groupEvent/decline?token=${token}`;
    return this.httpClient.get(path);
  }

  async notificationAcceptInvitation(groupEventId: string): Promise<AxiosResponse> {
    const path = `/api/v1/groupEvent/${groupEventId}/accept`;
    return this.httpClient.post(path);
  }

  async notificationDeclineInvitation(groupEventId: string): Promise<AxiosResponse> {
    const path = `/api/v1/groupEvent/${groupEventId}/decline`;
    return this.httpClient.post(path);
  }

  async readNotification(notificationId: string): Promise<AxiosResponse> {
    const path = `api/v1/groupEvent/notifications/${notificationId}/read`;
    return this.httpClient.post(path);
  }
}
