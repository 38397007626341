import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { GroupEventController } from '../../../controllers/groupEventController';

export type EmailInvitationStoreModel = {
  emailInvitation: EmailInvitationModel;
};

export interface EmailInvitationModel {
  acceptInvitation: Thunk<EmailInvitationModel, { token: string }, void, HttpStoreModel>;
  declineInvitation: Thunk<EmailInvitationModel, { token: string }, void, HttpStoreModel>;
}

export const emailInvitationStoreModel: EmailInvitationModel = {
  acceptInvitation: thunk(
    async (_actions, payload, { getStoreState }): Promise<any> => {
      try {
        const controller = new GroupEventController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.acceptInvitation(payload.token);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response.data;
      } catch (e) {
        return null;
      }
    },
  ),
  declineInvitation: thunk(
    async (_actions, payload, { getStoreState }): Promise<any> => {
      try {
        const controller = new GroupEventController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.declineInvitation(payload.token);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return response.data;
      } catch (e) {
        return null;
      }
    },
  ),
};
