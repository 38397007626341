import React from 'react';
import { Button, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Modal } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { confirmationModalHeaderCSS } from '../../../modules/messaging/seeker/header/actionsDropdown/ConfirmationModal.styles';
import { Notification, NotificationInvitationAction } from '../../../firebase/firestore/documents/notification';

export type GroupInvitationConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  notification: Notification | undefined;
  invitationAction: NotificationInvitationAction | undefined;
  confirmationAction: (notification: Notification | undefined, action: NotificationInvitationAction | undefined) => void;
};

export const GroupInvitationConfirmationModal = ({
  isOpen,
  onClose,
  notification,
  invitationAction,
  confirmationAction,
}: GroupInvitationConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation();

  const showActionText = () => {
    return invitationAction && invitationAction === NotificationInvitationAction.ACCEPT
      ? t('notification:accept')
      : t('notification:decline');
  };

  return (
    <Modal id="GroupInvitationConfirmationModal" isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader css={confirmationModalHeaderCSS} className="modal-header" style={{ color: 'black' }}>
            {t('notification:invitationConfirmationModal.headerText', { invitationAction: showActionText() })}
          </ModalHeader>
          <ModalBody>
            <p />
          </ModalBody>
          <ModalFooter>
            <Button data-testid="CancelButton" colorScheme="blue" mr={3} onClick={onClose}>
              {t('notification:invitationConfirmationModal.cancelButton')}
            </Button>
            <Button
              data-testid="ConfirmButton"
              colorScheme="red"
              onClick={() => confirmationAction(notification, invitationAction)}
            >
              {t('notification:invitationConfirmationModal.confirmButton')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
