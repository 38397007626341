import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { StarIcon } from '@chakra-ui/icons';
import _isEmpty from 'lodash/isEmpty';
import {
  AudioRatingType,
  LanguageEvaluationRating,
  LanguageEvaluationReview,
} from '../../../../../firebase/firestore/documents/candidate';

export type RatingType = {
  fluency: number;
  pronunciation: number;
  accuracy: number;
};

export type AudioRatingProps = {
  evaluationValue: string;
  sendRatingToParent: { (rating: RatingType): void };
  languageEvaluationReview: LanguageEvaluationReview | undefined;
  disableRating: boolean;
};

export const AudioRating = ({
  evaluationValue,
  sendRatingToParent,
  languageEvaluationReview,
  disableRating,
}: AudioRatingProps): JSX.Element => {
  const [rating, setRating] = useState<RatingType>({
    [AudioRatingType.fluency]: languageEvaluationReview?.fluency || 0,
    [AudioRatingType.pronunciation]: languageEvaluationReview?.pronunciation || 0,
    [AudioRatingType.accuracy]: languageEvaluationReview?.accuracy || 0,
  });

  const setAllRatingState = (ratingValue: number) => {
    setRating({
      [AudioRatingType.fluency]: ratingValue,
      [AudioRatingType.pronunciation]: ratingValue,
      [AudioRatingType.accuracy]: ratingValue,
    });
  };

  const setRatingBasedOnEvaluation = useCallback((evaluation: string) => {
    switch (evaluation) {
      case LanguageEvaluationRating.EXPERT:
        setAllRatingState(5);
        break;
      case LanguageEvaluationRating.COMPETENT:
        setAllRatingState(4);
        break;
      case LanguageEvaluationRating.LIMITED:
        setAllRatingState(3);
        break;
      case LanguageEvaluationRating.OCCASIONAL:
        setAllRatingState(2);
        break;
      case LanguageEvaluationRating.NEEDS_REVIEW:
        setAllRatingState(1);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    _isEmpty(languageEvaluationReview) && setRatingBasedOnEvaluation(evaluationValue);
  }, [setRatingBasedOnEvaluation, evaluationValue, languageEvaluationReview]);

  useEffect(() => {
    if (languageEvaluationReview && languageEvaluationReview.automated) {
      setRatingBasedOnEvaluation(evaluationValue);
    }
  }, [setRatingBasedOnEvaluation, evaluationValue, languageEvaluationReview]);

  useEffect(() => {
    sendRatingToParent(rating);
  }, [rating, sendRatingToParent]);

  const ratingStars: number[] = Array.from({ length: 5 }, (_, i) => i);

  const { t } = useTranslation('audioEvaluation');
  return (
    <Fragment>
      <Box className="audioRatingBox">
        {Object.values(AudioRatingType).map((value) => (
          <Flex alignItems="center" justifyContent="space-between" key={value}>
            <Text fontWeight="bold" fontSize="14px">
              {t(`audioRating.${value}`)}
            </Text>
            <Box mb={3}>
              {ratingStars.map((k) => {
                const val: number = k + 1;
                let color = '#FF6600';
                if (rating[value] < val) {
                  color = '#FFFFFF';
                }

                return (
                  <StarIcon
                    boxSize={5}
                    color={color}
                    style={{ marginRight: '10px', cursor: 'pointer', stroke: '#FF6600' }}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      _isEmpty(languageEvaluationReview) && !disableRating && setRating({ ...rating, [value]: val });
                    }}
                    key={k}
                    data-testid={`ratingStar_${value}_${k}`}
                  />
                );
              })}
            </Box>
          </Flex>
        ))}
      </Box>
    </Fragment>
  );
};
