import { messaging } from 'firebase';
import { thunkOn, ThunkOn, Thunk, Action, action } from 'easy-peasy';
import _isNil from 'lodash/isNil';
import { User } from '../firebase/firestore/documents/user';
import { setDocumentById } from '../firebase/firestore/firestore';
import { Collections } from '../firebase/firestore/collections';
import Config from '../firebase/Config';
import { AppStoreModel } from '../app/models/appModel';
import { pushNotification, Payload } from '../app/helper/foregroundNotifcation';

import { NotificationTypes } from '../firebase/firestore/documents/notificationTypes';

export type NotificationsStoreModel = {
  notifications: NotificationsModel;
};

export interface AdditionalContextDatType {
  noOfCandidatesWaitingForAvailability: number;
}

export interface NotificationDataType {
  noOfOpenings?: number;
  accountId?: string;
  businessId?: string;
}

export interface NotificationsModel {
  videoInterviewReminder: Payload | undefined;
  lowAvailabilityReminder: Payload | undefined;
  noAvailabilityReminder: Payload | undefined;
  positionOpeningsFilledReminder: Payload | undefined;
  positionClosingReminder: Payload | undefined;
  positionClosedReminder: Payload | undefined;
  positionInactiveReminder: Payload | undefined;
  inactivePositionClosed: Payload | undefined;
  setVideoInterviewNotification: Action<NotificationsModel, Payload | undefined>;
  setLowAvailabilityNotification: Action<NotificationsModel, Payload | undefined>;
  setNoAvailabilityNotification: Action<NotificationsModel, Payload | undefined>;
  setPositionOpeningsFilledNotification: Action<NotificationsModel, Payload | undefined>;
  setPositionClosingReminderNotification: Action<NotificationsModel, Payload | undefined>;
  setPositionClosedNotification: Action<NotificationsModel, Payload | undefined>;
  setPositionInactiveReminderNotification: Action<NotificationsModel, Payload | undefined>;
  setInactivePositionClosedNotification: Action<NotificationsModel, Payload | undefined>;
  clearPositionInactiveReminderNotification: Action<NotificationsModel>;
  clearInactivePositionClosedNotification: Action<NotificationsModel>;
  clearPositionOpeningsFilled: Action<NotificationsModel>;
  clearPositionClosingReminder: Action<NotificationsModel>;
  clearPositionClosed: Action<NotificationsModel>;
  clearVideoInterviewNotification: Action<NotificationsModel>;
  clearLowAvailabilityNotification: Action<NotificationsModel>;
  clearNoAvailabilityNotification: Action<NotificationsModel>;
  onAppSetUser: ThunkOn<NotificationsModel, void, AppStoreModel>;
  onAppInitialize: ThunkOn<NotificationsModel, void, AppStoreModel>;
  payloadData?: Thunk<NotificationsModel, { payload: any }, void, AppStoreModel>;
}

export const notificationsModel: NotificationsModel = {
  videoInterviewReminder: undefined,
  lowAvailabilityReminder: undefined,
  noAvailabilityReminder: undefined,
  positionOpeningsFilledReminder: undefined,
  positionClosingReminder: undefined,
  positionClosedReminder: undefined,
  positionInactiveReminder: undefined,
  inactivePositionClosed: undefined,
  onAppSetUser: thunkOn(
    (_actions, storeActions) => storeActions.app.setUser,
    async (_state, target) => {
      const user = target.payload;

      if (!user || !messaging.isSupported()) return;

      const permission = await Notification.requestPermission();

      if (permission !== 'granted') return;

      const token = await messaging().getToken({ vapidKey: Config.vapidKey });

      if (user.notificationTokens && user.notificationTokens[token]) return;

      const payload: Partial<User> = {
        notificationTokens: {
          [token]: { failureCount: 0, type: 'web' },
        },
      };
      const merge = true;
      await setDocumentById(Collections.USERS, user.id, payload, merge);
    },
  ),

  // Not able to mock global.navigator
  // istanbul ignore next
  onAppInitialize: thunkOn(
    // istanbul ignore next
    (_actions, storeActions) => storeActions.app.initialize,
    // istanbul ignore next
    async ({
      setVideoInterviewNotification,
      setLowAvailabilityNotification,
      setNoAvailabilityNotification,
      setPositionOpeningsFilledNotification,
      setPositionClosingReminderNotification,
      setPositionClosedNotification,
      setPositionInactiveReminderNotification,
      setInactivePositionClosedNotification,
    }) => {
      // the service worker auto register on production
      if ('serviceWorker' in navigator && messaging.isSupported()) {
        if (Config.env === 'development') {
          try {
            await navigator.serviceWorker.register(`${window.location.origin}/firebase-messaging-sw.js`);
          } catch (error) {
            console.log('Service worker registration failed, error:', error);
          }
        }

        // Register for foreground notification.
        messaging().onMessage((payload) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          if (payload.data.code === NotificationTypes.EMPLOYERS_VIDEO_INTERVIEW_REMINDER_NOTIFICATION) {
            setVideoInterviewNotification(payload);
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          if (payload.data.code === NotificationTypes.RECRUITER_LOW_AVAILABILITY_NOTIFICATION) {
            setLowAvailabilityNotification(payload);
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          if (payload.notification.title.includes('No appointment availability')) {
            setNoAvailabilityNotification(payload);
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          if (payload.data.code === NotificationTypes.POSITION_OPENINGS_FILLED) {
            setPositionOpeningsFilledNotification(payload);
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          if (payload.data.code === NotificationTypes.POSITINON_CLOSING_REMINDER) {
            setPositionClosingReminderNotification(payload);
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          if (payload.data.code === NotificationTypes.POSITION_CLOSED) {
            setPositionClosedNotification(payload);
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          if (payload.data.code === NotificationTypes.POSITION_INACTIVE_REMINDER) {
            setPositionInactiveReminderNotification(payload);
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          if (payload.data.code === NotificationTypes.INACTIVE_POSITION_CLOSED) {
            setInactivePositionClosedNotification(payload);
          }
          pushNotification(payload);
        });
      }
    },
  ),
  clearVideoInterviewNotification: action((state) => {
    state.videoInterviewReminder = undefined;
  }),
  clearLowAvailabilityNotification: action((state) => {
    state.lowAvailabilityReminder = undefined;
  }),
  clearNoAvailabilityNotification: action((state) => {
    state.noAvailabilityReminder = undefined;
  }),
  clearPositionOpeningsFilled: action((state) => {
    state.positionOpeningsFilledReminder = undefined;
  }),
  clearPositionClosingReminder: action((state) => {
    state.positionClosingReminder = undefined;
  }),
  clearPositionClosed: action((state) => {
    state.positionClosedReminder = undefined;
  }),
  clearPositionInactiveReminderNotification: action((state) => {
    state.positionInactiveReminder = undefined;
  }),
  clearInactivePositionClosedNotification: action((state) => {
    state.inactivePositionClosed = undefined;
  }),
  setVideoInterviewNotification: action((state, interviewReminderData) => {
    // istanbul ignore next
    if (_isNil(interviewReminderData)) {
      state.videoInterviewReminder = undefined;
      return;
    }
    state.videoInterviewReminder = interviewReminderData;
  }),
  setLowAvailabilityNotification: action((state, lowAvailabilityReminderData) => {
    // istanbul ignore next
    if (_isNil(lowAvailabilityReminderData)) {
      state.lowAvailabilityReminder = undefined;
      return;
    }
    state.lowAvailabilityReminder = lowAvailabilityReminderData;
  }),
  setNoAvailabilityNotification: action((state, setNoAvailabilityNotification) => {
    // istanbul ignore next
    if (_isNil(setNoAvailabilityNotification)) {
      state.noAvailabilityReminder = undefined;
      return;
    }
    state.noAvailabilityReminder = setNoAvailabilityNotification;
  }),
  setPositionOpeningsFilledNotification: action((state, setPositionOpeningsFilledNotificationData) => {
    if (_isNil(setPositionOpeningsFilledNotificationData)) {
      state.positionOpeningsFilledReminder = undefined;
      return;
    }
    state.positionOpeningsFilledReminder = setPositionOpeningsFilledNotificationData;
  }),
  setPositionClosingReminderNotification: action((state, setPositionClosingReminderNotificationData) => {
    if (_isNil(setPositionClosingReminderNotificationData)) {
      state.positionClosingReminder = undefined;
      return;
    }
    state.positionClosingReminder = setPositionClosingReminderNotificationData;
  }),
  setPositionClosedNotification: action((state, setPositionClosedNotificationData) => {
    if (_isNil(setPositionClosedNotificationData)) {
      state.positionClosedReminder = undefined;
      return;
    }
    state.positionClosedReminder = setPositionClosedNotificationData;
  }),
  setPositionInactiveReminderNotification: action((state, setPositionOpeningsFilledNotificationData) => {
    if (_isNil(setPositionOpeningsFilledNotificationData)) {
      state.positionInactiveReminder = undefined;
      return;
    }
    state.positionInactiveReminder = setPositionOpeningsFilledNotificationData;
  }),
  setInactivePositionClosedNotification: action((state, setInactivePositionClosedNotificationData) => {
    if (_isNil(setInactivePositionClosedNotificationData)) {
      state.inactivePositionClosed = undefined;
      return;
    }
    state.inactivePositionClosed = setInactivePositionClosedNotificationData;
  }),
};
