import React from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import map from 'lodash/map';
import assign from 'lodash/assign';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { DayWiseReport } from '../../../firebase/firestore/documents/managementOverview';
import colors from '../../../styles/colors';

export interface HireOfferLineChartProps {
  dayWiseReport: DayWiseReport[] | undefined;
  isDarkMode: boolean;
}

function HireOfferLineChart({ dayWiseReport, isDarkMode }: HireOfferLineChartProps) {
  const { t } = useTranslation('managementOverview');
  const dayWiseReportForChart = map(dayWiseReport, (item) => {
    const month = item.day.split(' ')[0].toLocaleLowerCase();
    const day = item.day.split(' ')[1];
    return assign({}, item, { day: `${t(`common:monthsSmall.${month}`)} ${day}` });
  });

  const sortedData = sortBy(dayWiseReportForChart, (item) => moment(item.day, 'MMM D'));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={sortedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" style={{ fill: isDarkMode ? colors.white : colors.black }} />
        <YAxis style={{ fill: isDarkMode ? colors.white : colors.black }} />
        <Tooltip contentStyle={{ backgroundColor: '#333', color: '#fff' }} />
        <Legend />
        <Line
          type="monotone"
          dataKey="offerMadeCount"
          name={t('hireOfferStat.offerMadeCount')}
          stroke="#4CCAFF"
          strokeWidth={2}
        />
        <Line
          type="monotone"
          dataKey="offerAcceptCount"
          name={t('hireOfferStat.offerAcceptCount')}
          stroke={colors.green[400]}
          strokeWidth={2}
        />
        <Line
          type="monotone"
          dataKey="offerRejectedCount"
          name={t('hireOfferStat.offerRejectCount')}
          stroke="#F36E5F"
          strokeWidth={2}
        />
        <Line
          type="monotone"
          dataKey="offerRevokedCount"
          name={t('hireOfferStat.offerRevokedCount')}
          stroke="rgb(244,125,241)"
          strokeWidth={2}
        />
        <Line
          type="monotone"
          dataKey="dismissedCandidateStatsCount"
          name={t('hireOfferStat.dismissedCandidateCount')}
          stroke="#F9C264"
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default HireOfferLineChart;
