import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { RiskRequisitionDetail } from '../firebase/firestore/documents/requisition';

export class RequisitionController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getRiskRequisitionDetail(accountId: string): Promise<RiskRequisitionDetail> {
    const path = `/api/v1/account/${accountId}/dashboard/riskRequisitionDetail`;
    return this.httpClient.get(path);
  }
}
