import React from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Alert,
  AlertIcon,
  Skeleton,
} from '@chakra-ui/core';
import _groupBy from 'lodash/groupBy';
import _isUndefined from 'lodash/isUndefined';
import _sortBy from 'lodash/sortBy';
import _has from 'lodash/has';
import { useTranslation } from 'react-i18next';

import usePositionsImpacted from '../../../app/hooks/usePositionsImpacted';
import colors from '../../../styles/colors';

export type PositionImpactedModalProps = {
  recruiterId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const PositionsImpactedModal = ({ recruiterId, isOpen, onClose }: PositionImpactedModalProps) => {
  const { t } = useTranslation('lowAvailability');
  const positions = usePositionsImpacted(recruiterId);
  const sortedd = _sortBy(positions, (business) => business.businessName.toLowerCase());
  const sortedPositions = _groupBy(sortedd, 'business');

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('positionsImpacted')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxHeight="40vh" overflow="auto">
            {Object.keys(sortedPositions).map((businessId) => {
              return (
                <Box key={businessId} data-testid={`businessItem-${businessId}`}>
                  <Text background="rgba(233, 237, 239, 0.5)" p={2} fontWeight="500">
                    {sortedPositions[businessId][0].businessName}
                  </Text>
                  <Text>
                    {_sortBy(
                      sortedPositions[businessId],
                      (position) => position.customName && position.customName.toLowerCase(),
                    ).map((position) => {
                      return (
                        _has(position, 'job') && (
                          <ShowJobDesc
                            key={position.job}
                            jobId={position.job}
                            customName={position?.customName}
                            isFullTime={position?.fullTime}
                          />
                        )
                      );
                    })}
                  </Text>
                </Box>
              );
            })}
            {_isUndefined(positions) && (
              <Box padding="4" boxShadow="lg" bg="white">
                <Skeleton height="10px" />
                <Skeleton height="10px" />
                <Skeleton height="10px" />
              </Box>
            )}

            {Array.isArray(positions) && !positions?.length && (
              <Alert status="info">
                <AlertIcon />
                {t('jobPosition:positionImpactedModal.emptyAutomatedPositions')}
              </Alert>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              {t('certn:closeBtn')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const ShowJobDesc = ({
  jobId,
  isFullTime,
  customName,
}: {
  jobId: string;
  isFullTime: boolean;
  customName: string | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <Box p={2} data-testid={`jobDescriptionFor-${jobId}`} key={jobId}>
      <>
        {customName ? <Text fontWeight="600">{customName}</Text> : '---'}
        <Text color={colors.gray[500]}>{isFullTime ? t('jobPosition:fullTime') : t('jobPosition:partTime')}</Text>
      </>
    </Box>
  );
};
