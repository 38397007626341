export default {
  teamInfoTitle: 'Company Management',
  createTeamLabel: 'Create your company to manage your locations and positions with other users!',
  createTeamBtn: 'Create Company',
  editTeamLabel: 'Edit Company Name',
  addTeamBtn: 'Invite New Member',
  teamSearchPlaceholder: 'Search members',
  teamInfoTableHeader: {
    name: 'Name',
    status: 'Status',
    phoneNumber: 'Phone number',
    action: 'Options',
  },
  teamInviteTableHeader: {
    email: 'Email',
    status: 'Status',
    action: 'Options',
  },
  accountInviteInfo: 'Pending Invitations',
  confirmationMessage: {
    promote: `Are you sure you want to give administrative rights to {{teamMemberName}}.`,
    demote: `Are you sure you want to revoke {{teamMemberName}}'s administrative rights?`,
    delete: `Are you sure you want to remove {{teamMemberName}} from the company?`,
    cancel: `Are you sure you want to cancel the invitation sent to {{inviteEmail}}?`,
    reInvite: `Are you sure you want to resend an invitation to {{inviteEmail}}?`,
  },
  successMessage: {
    promote: `{{teamMemberName}} has been successfully given administrative rights.`,
    demote: `{{teamMemberName}} 's administrative rights have successfully been revoked..`,
    delete: `{{teamMemberName}} has been successfully removed from the Company.`,
    cancel: `The email invitation for {{inviteEmail}} has been canceled successfully.`,
    reInvite: `The email invitation for {{inviteEmail}} has been re-sent successfully.`,
  },
  createTeamModal: {
    createLabel: 'Name your Company',
    editLabel: 'Edit Your Company Name',
    cancelBtn: 'Cancel',
    saveBtn: 'Save',
    updateBtn: 'Update',
    successMessage: {
      create: 'Company name saved successfully',
      update: 'Company name updated successfully.',
    },
    validationMessage: {
      name: 'Company name is required',
      empty: 'Email is required',
      validation: 'Must be a valid email address',
    },
  },
  inviteTeamModal: {
    inviteLabel: 'Invite New  Member',
    inviteEmailPlaceholder: 'Email',
    cancelBtn: 'Cancel',
    saveBtn: 'Send',
    validationMessage: {
      empty: 'Email is required',
      name: `Name is required`,
      validation: 'Must be a valid email address',
    },
  },
  noData: 'No data found.',
  noPendingInvitation: 'No pending invitations found.',
  cancelInvite: 'Cancel Invitation',
  resend: 'Resend',
  confirmBtn: 'Confirm',
  cancelBtn: 'Cancel',
  admin: 'Admin',
  active: 'Active',
  pending: 'Pending',
  expired: 'Expired',
};
