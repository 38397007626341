import { useDocumentSnapshot } from '../../../../firebase/firestore/hooks';
import { Collections } from '../../../../firebase/firestore/collections';

import { Position } from '../../../../firebase/firestore/documents/position';

const usePosition = (positionId: string | undefined): Position | undefined => {
  return useDocumentSnapshot<Position>(Collections.POSITIONS, positionId);
};

export default usePosition;
