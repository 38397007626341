import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import _upperFirst from 'lodash/upperFirst';
import _isDate from 'lodash/isDate';
import moment from 'moment-timezone';

import en from './en';
import fr from './fr';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      fr,
    },
    whitelist: ['fr', 'en'],
    defaultNS: 'common',
    fallbackLng: 'fr',
    debug: false,

    detection: {
      order: ['querystring', 'localStorage', 'sessionStorage', 'navigator', 'cookie', 'path', 'subdomain', 'htmlTag'],
      // more options: https://github.com/i18next/i18next-browser-languageDetector
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default

      format(value, formats) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        let formattedValue = value;
        const formatsArray = formats?.split('---') ?? [];

        formatsArray.forEach((format) => {
          if (format === 'upperFirst') {
            formattedValue = _upperFirst(formattedValue);
          } else if (_isDate(value)) {
            formattedValue = moment(formattedValue).format(format);
          }
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return formattedValue;
      },
    },
  });

export default i18n;
