import {
  Badge,
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import ModernHire from '../../../../../assets/img/modernHire.svg';

import { Candidate } from '../../../../../firebase/firestore/documents/candidate';
import colors from '../../../../../styles/colors';
import { useStoreActions } from '../../../../../models/hooks';
import {
  ModernHireDetailResponse,
  ModernHireStatusList,
  OverallFitScores,
} from '../../../../../firebase/firestore/documents/modernHireTypes';

export type SeekerModernHireStatusProps = BoxProps & {
  selectedCandidate: Candidate;
};

const SeekerModernHireStatus = ({ selectedCandidate, ...rest }: SeekerModernHireStatusProps): JSX.Element => {
  const { t } = useTranslation('modernHire');
  const toast = useToast();
  const { isOpen: isModernHireModalOpen, onOpen: onModernHireModalOpen, onClose: onModernHireModalOpenClose } = useDisclosure();
  const { modernHireResult } = useStoreActions((actions) => actions.modernHireModal);
  const [modernHireInfo, setModernHireInfo] = useState<ModernHireDetailResponse | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const onViewResults = async () => {
    if (
      ModernHireStatusList.ASSESSMENT_PASSED === selectedCandidate.modernHire?.status ||
      ModernHireStatusList.ASSESSMENT_FAILED === selectedCandidate.modernHire?.status
    ) {
      setLoading(true);
      try {
        const data = await modernHireResult({ candidateId: selectedCandidate.id });
        setModernHireInfo(data);
        setLoading(false);
        onModernHireModalOpen();
      } catch (e) {
        setLoading(false);
        toast({
          title: `Something went wrong! Could not load modern hire result`,
          status: 'error',
          isClosable: true,
        });
      }
    } else {
      onModernHireModalOpen();
    }
  };
  const getColorScheme = (status: string | undefined): string => {
    switch (status) {
      case 'ASSESSMENT_STARTED':
        return 'blue';
      case 'ASSESSMENT_IN_PROGRESS':
        return 'teal';
      case 'ASSESSMENT_PASSED':
        return 'green';
      case 'ASSESSMENT_FAILED':
        return 'red';
      default:
        return 'blue';
    }
  };
  const getIcons = (status: string | undefined) => {
    if (status === 'ASSESSMENT_PASSED') {
      return (
        <Badge variant="solid" colorScheme="green">
          {t('Success')}
        </Badge>
      );
    }
    return (
      <Badge variant="solid" colorScheme="red">
        {t('Fail')}
      </Badge>
    );
  };

  const onGetScore = (overallFitScores: OverallFitScores[]) => {
    const info = overallFitScores?.find((score) => score?.name.includes('Career Stability'));
    return info?.score;
  };
  const onGetResult = (overallFitScores: OverallFitScores[]) => {
    const info = overallFitScores?.find((score) => score?.name.includes('Career Stability'));
    return `${info?.score} / ${info?.max}`;
  };

  const onGetModalBody = () => {
    if (ModernHireStatusList.ASSESSMENT_STARTED === selectedCandidate.modernHire?.status) {
      return (
        <Box pl="22px" pr="22px" pt="22px" fontSize="16px">
          {t('assessmentStarted')}
        </Box>
      );
    }
    if (ModernHireStatusList.ASSESSMENT_IN_PROGRESS === selectedCandidate.modernHire?.status) {
      return (
        <Box pl="22px" pr="22px" pt="22px" fontSize="16px">
          {t('assessmentProcessing')}
        </Box>
      );
    }

    return (
      <Box p={0}>
        {modernHireInfo?.results?.map((info) => (
          <Box mt={1} key={info.title}>
            <Box paddingLeft="22px" paddingRight="22px">
              <Flex alignItems="center">
                <Text fontWeight="700" fontSize="16px" mt={1} mr={2}>
                  {info.title}
                </Text>
                {getIcons(selectedCandidate.modernHire?.status)}
              </Flex>
              <Flex justifyContent="space-between" mt={1} alignItems=" flex-start">
                <Text fontWeight="700" fontSize="14px">
                  {t('completionDate')}
                </Text>
                <Text fontWeight="400" fontSize="14px" width="60%" textAlign="left">
                  {moment(info.completionDate).format('ll')}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" mt={1} alignItems=" flex-start">
                <Text fontWeight="700" fontSize="14px">
                  {t('score')}
                </Text>
                {info.overallFitScores && info.overallFitScores.length > 0 ? (
                  <Text fontWeight="700" fontSize="14px" width="60%" textAlign="left">
                    {onGetScore(info.overallFitScores)}
                  </Text>
                ) : (
                  <Text fontWeight="700" fontSize="14px" width="60%" textAlign="left">
                    {info.score}
                  </Text>
                )}
              </Flex>
              <Flex justifyContent="space-between" mt={1} alignItems=" flex-start">
                <Text fontWeight="700" fontSize="14px">
                  {t('band')}
                </Text>
                {info.overallFitScores && info.overallFitScores.length > 0 ? (
                  <Text fontWeight="700" fontSize="14px" width="60%" textAlign="left" style={{ color: colors.green[400] }}>
                    {onGetResult(info.overallFitScores)}
                  </Text>
                ) : (
                  <Text fontWeight="700" fontSize="14px" width="60%" textAlign="left" style={{ color: colors.green[400] }}>
                    {info.band}
                  </Text>
                )}
              </Flex>
              <Flex justifyContent="space-between" mt={1} alignItems=" flex-start">
                <Text fontWeight="700" fontSize="14px">
                  {t('reviewURL')}
                </Text>
                <Text fontWeight="400" fontSize="14px" width="60%" textAlign="left">
                  <a
                    href={info.reviewUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#029bff', textDecoration: 'underline' }}
                  >
                    view
                  </a>
                </Text>
              </Flex>
              <Flex justifyContent="space-between" mt={1} alignItems=" flex-start">
                <Text fontWeight="700" fontSize="14px">
                  {t('comments')}
                </Text>
                <Text fontWeight="400" fontSize="14px" width="60%" textAlign="left" whiteSpace="pre-line">
                  {info.comments}
                </Text>
              </Flex>
            </Box>
            <Divider mt={2} mb={2} />
          </Box>
        ))}
      </Box>
    );
  };
  return (
    <Box {...rest} mr={2}>
      <Tooltip label={t('modalTitle')} hasArrow fontSize="sm">
        <Badge
          data-testid="loadHireDetailButton"
          colorScheme={getColorScheme(selectedCandidate.modernHire?.status)}
          textTransform="none"
          borderRadius="4px"
          variant="subtle"
          cursor="pointer"
          onClick={onViewResults}
        >
          <Flex alignItems="center" padding="4px">
            {loading ? (
              <CircularProgress isIndeterminate size="22px" />
            ) : (
              <img src={ModernHire} width={22} alt="morden hire icon" />
            )}
            <Text ml={1} color={colors.black}>
              {t(`status.${selectedCandidate.modernHire?.status}`)}
            </Text>
          </Flex>
        </Badge>
      </Tooltip>
      {isModernHireModalOpen && (
        <Modal isOpen={isModernHireModalOpen} onClose={onModernHireModalOpenClose} scrollBehavior="inside" isCentered>
          <ModalOverlay />
          <ModalContent maxWidth="32rem">
            <ModalHeader px={5} paddingRight="2.1rem" borderBottom={`1px solid ${colors.gray[100]}`}>
              {t('modalTitle')}
            </ModalHeader>
            <Box>
              <ModalCloseButton />
            </Box>
            <ModalBody p={0}>{onGetModalBody()}</ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={onModernHireModalOpenClose}>
                {t('close')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};
export default SeekerModernHireStatus;
