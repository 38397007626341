import React, { useContext, useState } from 'react';
import _noop from 'lodash/noop';
import { CustomerSupportDetail } from './supportData';

export type SupportViewContainerContextValueType = {
  selectedSupport: CustomerSupportDetail | null;
  setSelectedSupport: (selectedSupport: CustomerSupportDetail | null) => void;
  searchText: string;
  setSearchText: (text: string) => void;
};

export type SupportViewContextValueType = SupportViewContainerContextValueType;

export const SUPPORT_VIEW_CONTEXT_DEFAULT_VALUE: SupportViewContextValueType = {
  selectedSupport: null,
  setSelectedSupport: _noop,
  searchText: '',
  setSearchText: _noop,
};

export const SupportViewContext = React.createContext<SupportViewContextValueType>(SUPPORT_VIEW_CONTEXT_DEFAULT_VALUE);

export const SupportViewContextProvider: React.FC = ({ children }) => {
  const [selectedSupport, setSelectedSupport] = useState<CustomerSupportDetail | null>(
    SUPPORT_VIEW_CONTEXT_DEFAULT_VALUE.selectedSupport,
  );
  const [searchText, setSearchText] = useState<string>(SUPPORT_VIEW_CONTEXT_DEFAULT_VALUE.searchText);
  return (
    <SupportViewContext.Provider
      value={{
        selectedSupport,
        setSelectedSupport,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </SupportViewContext.Provider>
  );
};

export const useSupportViewContext = (): SupportViewContextValueType => useContext(SupportViewContext);
