import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isUndefined from 'lodash/isUndefined';
import SpecificQuestionPresenter from './specificQuestionPresenter';
import { Candidate, QuestionResponse } from '../../../../../firebase/firestore/documents/candidate';
import { Position, SpecificQuestion } from '../../../../../firebase/firestore/documents/position';

const ignoreQuestion = (specificQuestion: SpecificQuestion): boolean => _isEmpty(specificQuestion.text);

const getCandidateResponse = (questionId: string, candidate: Candidate): QuestionResponse | undefined => {
  return _find(candidate.questionResponses, { id: questionId });
};

const getCandidateAnswer = (questionResponse?: QuestionResponse): boolean => {
  return questionResponse?.answer || false;
};

const isQuestionAnswered = (questionResponse?: QuestionResponse): boolean => {
  return !_isUndefined(questionResponse?.answer);
};

const isKnockedOut = (specificQuestion: SpecificQuestion, questionResponse?: QuestionResponse): boolean => {
  if (!specificQuestion.knockout) {
    return false;
  }
  return isQuestionAnswered(questionResponse) ? getCandidateAnswer(questionResponse) !== specificQuestion.preferredAnswer : false;
};

export default class SpecificQuestionsPresenter {
  public questions: SpecificQuestionPresenter[] = [];

  constructor(private readonly candidate: Candidate, private readonly position: Position) {
    position.specificQuestions?.forEach((specificQuestion) => {
      if (!ignoreQuestion(specificQuestion) && specificQuestion.isActive !== false) {
        const candidateResponse = getCandidateResponse(specificQuestion.id, candidate);
        this.questions.push(
          new SpecificQuestionPresenter(
            specificQuestion.id,
            specificQuestion.text,
            specificQuestion.preferredAnswer,
            getCandidateAnswer(candidateResponse),
            isQuestionAnswered(candidateResponse),
            specificQuestion.knockout,
            isKnockedOut(specificQuestion, candidateResponse),
            specificQuestion.isActive,
          ),
        );
      }
    });
  }

  public getQuestionsCount = (): number => {
    const countActiveQuestions = this.questions.filter((obj) => obj.isActive !== false).length;
    return countActiveQuestions;
  };

  public isRightAnswer = (question: SpecificQuestionPresenter) => question.answer === question.preferredAnswer;

  public getAnsweredQuestionsCount = (): number => {
    return _filter(this.questions, (question) => question.answered && question.isActive !== false && this.isRightAnswer(question))
      .length;
  };
}
