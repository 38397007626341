import { css } from '@emotion/core';
import colors from '../../../styles/colors';

export const appointmentTypeIconCSS = css`
  &.blue-circle-icon {
    background-color: ${colors.blue.icon};
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
`;
