import { Alert, AlertIcon, Box, Button, Flex, Heading, ScaleFade, Spinner, Text, useDisclosure } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import isUndefined from 'lodash/isUndefined';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column, useTable } from 'react-table';
import { Requisition } from '../../../../firebase/firestore/documents/requisition';
import colors from '../../../../styles/colors';
import { hexToRgba } from '../../../../utils/uiUtils';
import { ViewRequisitionDetail } from '../../../messaging/seeker/candidate/history/ViewRequisitionDetail';
import useSingleRequisitionByPosition from '../../../messaging/seeker/candidate/profile/components/useSingleRequisitionByPosition';
import { RequisitionInsightsTableDataType, RequisitionStatusEnum } from './RequisitionInsightsTableType';

export type RequisitionInsightsTableProps<Data extends Record<string, unknown>> = {
  data: Data[];
  columns: Column<Data>[];
  isLoading: boolean;
};

export default function RequisitionInsightsTable<Data extends Record<string, unknown>>({
  data,
  columns,
  isLoading,
}: RequisitionInsightsTableProps<Data>) {
  const { t } = useTranslation('requisition');

  const getRiskStatusText = {
    [RequisitionStatusEnum.SAFE]: t('riskRequisitionDetailTable.riskStatusText.safe'),
    [RequisitionStatusEnum.AT_RISK]: t('riskRequisitionDetailTable.riskStatusText.atRisk'),
    [RequisitionStatusEnum.OVERDUE]: t('riskRequisitionDetailTable.riskStatusText.overdue'),
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const [positionId, setPositionId] = useState('');
  const [requisitionId, setRequisitionId] = useState('');
  const [requisition, setRequisition] = React.useState<Requisition | undefined>(undefined);

  const singleRequisition = useSingleRequisitionByPosition(positionId, requisitionId);
  const { isOpen: isOpenRequisition, onOpen: onOpenRequisition, onClose: onCloseRequisition } = useDisclosure();

  useEffect(() => {
    if (singleRequisition) {
      setRequisition(singleRequisition[0]);
      onOpenRequisition();
    }
  }, [singleRequisition, onOpenRequisition]);

  const handleRequisitionModalOpen = useCallback(
    (clickedPositionId: string, clickedRequisitionId: string) => {
      setPositionId(clickedPositionId);
      setRequisitionId(clickedRequisitionId);
    },
    [setPositionId, setRequisitionId],
  );

  const onRequisitionModalClose = () => {
    onCloseRequisition();
    setPositionId('');
    setRequisitionId('');
  };
  const getColorAccordingToRequisitionStatus = (requisitionStatus: RequisitionStatusEnum) => {
    switch (requisitionStatus) {
      case RequisitionStatusEnum.AT_RISK:
        return colors.yellow[500];

      case RequisitionStatusEnum.OVERDUE:
        return colors.red[500];

      default:
        return colors.green[500];
    }
  };

  const renderCell = (cellData: Cell<Data>) => {
    const columnData = (cellData.row.original as unknown) as RequisitionInsightsTableDataType;
    switch (cellData.column.id) {
      case 'riskStatus':
        return (
          <Button
            backgroundColor={hexToRgba(getColorAccordingToRequisitionStatus(columnData.riskStatus[0]), 0.1)}
            color="white"
            borderRadius="64px"
            px={10}
            py="6px"
            mr={2}
            fontSize="15px"
            fontWeight="600"
            w="100%"
            pointerEvents="none"
          >
            <Text color={getColorAccordingToRequisitionStatus(columnData.riskStatus[0])} opacity={1}>
              {getRiskStatusText[columnData.riskStatus[0]]}
            </Text>
          </Button>
        );
      case 'createdAt':
        return (
          <Box className={cellData.column.id} data-testid={cellData.column.id} py="15px">
            <Text fontSize="16px" color="gray.700">
              {!isUndefined(columnData) && !isUndefined(columnData?.updatedAt)
                ? // istanbul ignore next
                  moment(columnData?.updatedAt?._seconds * 1000).format('DD/MM/YYYY')
                : moment(columnData?.createdAt?._seconds * 1000).format('DD/MM/YYYY')}
            </Text>
          </Box>
        );

      case 'effectiveDate':
        return (
          <Box className={cellData.column.id} data-testid={cellData.column.id} py="15px">
            <Text fontSize="16px" color="gray.700">
              {/* eslint-disable-next-line no-underscore-dangle */}
              {moment(columnData.effectiveDate._seconds * 1000).format('DD/MM/YYYY')}
            </Text>
            {/* TODO: Need to update status later */}
            {/* <Text fontSize="14px" color={getColorAccordingToRequisitionStatus(columnData.riskStatus[0])}>
              {columnData.riskStatus[0] === RequisitionStatusEnum.AT_RISK &&
                t('riskRequisitionDetailTable.riskDaysText', { riskDays: columnData.riskDays })}
              {columnData.riskStatus[0] === RequisitionStatusEnum.OVERDUE &&
                t('riskRequisitionDetailTable.overdueDaysText', { riskDays: columnData.riskDays })}
            </Text> */}
          </Box>
        );

      case 'businessName':
        return (
          <Link to={{ pathname: `/positionCandidates/${columnData.positionId}/messaging`, state: 'RequisitionInsightsTable' }}>
            <Box className={cellData.column.id} data-testid={cellData.column.id} py="15px">
              <Text fontSize="14px" color="gray.700" mb={1}>
                {cellData.value}
              </Text>
              <Heading
                fontSize="16px"
                color="gray.800"
                lineHeight="1.2"
                as="span"
                style={{ color: colors.linkBlue, textDecoration: 'underline' }}
              >
                {columnData.positionName}
              </Heading>
            </Box>
          </Link>
        );
      case 'requisitionId':
        return (
          <Box className={cellData.column.id} data-testid={cellData.column.id} py="15px" textDecoration="underline">
            <Box
              fontSize="16px"
              fontWeight="500"
              style={{ color: colors.linkBlue }}
              data-testid={`${cellData.column.id}-${cellData.value}`}
              onClick={() => handleRequisitionModalOpen(columnData.positionId, cellData.value)}
            >
              {cellData.value}
            </Box>
          </Box>
        );
      /* istanbul ignore next */
      default:
        return (
          <Text className={cellData.column.id} data-testid={cellData.column.id}>
            {cellData.value !== undefined ? cellData.render('Cell') : 'N/A'}
          </Text>
        );
    }
  };

  return (
    <ScaleFade initialScale={0.7} in unmountOnExit={false}>
      <Box height="100%">
        <Table
          {...getTableProps()}
          className="mainTable"
          key="mainTable"
          border="1px solid #E5E7EB"
          borderCollapse="collapse"
          width="100%"
          height="100%"
          background={colors.white}
        >
          <Thead className="tableHeading" bg="blue.100">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()} textAlign="left" key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps()}
                    isNumeric={column.isNumeric}
                    key={column.getHeaderProps().key}
                    className="fixBorder"
                    style={{ borderColor: '#d0e9f4' }}
                    px={3}
                    py={2}
                    border="1px solid #E5E7EB"
                    borderCollapse="collapse"
                  >
                    <Box style={{ display: 'flex', maxWidth: '100%' }}>
                      <Text textTransform="none" display="inline">
                        {column.render('Header')}
                      </Text>
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!isLoading ? (
              data.length ? (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <Tr
                      {...row.getRowProps()}
                      style={{ height: '40px' }}
                      key={row.getRowProps().key}
                      cursor="pointer"
                      _hover={{ background: '#f7fafc' }}
                    >
                      {row.cells.map((cell) => (
                        <Td
                          {...cell.getCellProps()}
                          isNumeric={cell.column.isNumeric}
                          pr=""
                          key={cell.getCellProps().key}
                          data-testid="tableRow"
                          px={3}
                          py="3px"
                          border="1px solid #E5E7EB"
                          borderCollapse="collapse"
                        >
                          {renderCell(cell)}
                        </Td>
                      ))}
                    </Tr>
                  );
                })
              ) : (
                <Td colSpan={5}>
                  <Alert status="info" backgroundColor="gray.400" color="white" data-testid="noDataFoundAlert">
                    <AlertIcon color="white" />
                    {t('toDoList:noData')}
                  </Alert>
                </Td>
              )
            ) : (
              <Td colSpan={5}>
                <Flex alignItems="center" justifyContent="center" p={5}>
                  <Spinner colorScheme="blue" size="lg" />
                </Flex>
              </Td>
            )}
          </Tbody>
        </Table>
        {isOpenRequisition && (
          <ViewRequisitionDetail
            isOpen={isOpenRequisition}
            onClose={onRequisitionModalClose}
            requisition={requisition}
            shouldCompare={false}
            dashboard
          />
        )}
      </Box>
    </ScaleFade>
  );
}
