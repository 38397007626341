export default {
  modalTitle: `Competency Evaluation Details`,
  close: `Close`,
  completionDate: 'Completion Date',
  score: 'Score',
  band: 'Result',
  title: 'Title',
  reviewURL: 'Evaluation URL',
  comments: 'Comments',
  status: {
    ASSESSMENT_STARTED: 'Requested',
    ASSESSMENT_IN_PROGRESS: 'In progress',
    ASSESSMENT_PASSED: 'Passed',
    ASSESSMENT_FAILED: 'Failed',
  },
  assessmentStarted: 'The Competency Evaluation has been started.',
  assessmentProcessing: 'The Competency Evaluation is being processed.',
  Success: 'Success',
  Fail: 'Fail',
};
