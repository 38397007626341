/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo } from 'react';
import { Button } from '@chakra-ui/core';
import { useLocalAudioToggle } from './hooks/useLocalAudioToggle';
import { useDevices } from './hooks/useDevices';
import { ReactComponent as _IconMicroPhone } from '../../../assets/img/icon-microphone.svg';
import { ReactComponent as _IconMicroPhoneOff } from '../../../assets/img/icon-mute.svg';

const IconMicroPhone = memo(_IconMicroPhone);
const IconMicroPhoneOff = memo(_IconMicroPhoneOff);

type AudioToggleButtonProps = {
  isLoading: boolean;
};

export const AudioToggleButton = ({ isLoading }: AudioToggleButtonProps): JSX.Element => {
  const { isAudioEnabled, toggleAudio } = useLocalAudioToggle();
  const { hasAudioInputDevices, audioInputDevices } = useDevices();

  return (
    <Button
      data-testid="ToggleAudioButton"
      onClick={toggleAudio}
      isDisabled={!hasAudioInputDevices || isLoading || audioInputDevices[0]?.deviceId === ''}
      isLoading={isLoading}
      className={isAudioEnabled ? 'on' : 'off'}
      borderRadius="100%"
      height="45px"
      width="45px"
      p="5px"
    >
      {isAudioEnabled ? <IconMicroPhone data-testid="AudioEnabledIcon" /> : <IconMicroPhoneOff data-testid="AudioDisabledIcon" />}
    </Button>
  );
};
