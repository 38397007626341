export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  frameRate: 16,
  height: 720,
  width: 1280,
};

export const DEFAULT_INTERVIEW_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  frameRate: 16,
  height: 720,
  width: 426,
};

export const SELECTED_AUDIO_OUTPUT_KEY = 'TWILLIO_AUDIO_OUTPUT';
export const SELECTED_AUDIO_INPUT_KEY = 'TWILLIO_AUDIO_INPUT';
export const SELECTED_VIDEO_INPUT_KEY = 'TWILLIO_VIDEO_INPUT';

export const TRACK_SCREEN = 'screen';
export const TRACK_CAMERA = 'camera';
export const TRACK_VIDEO = 'video';
export const TRACK_AUDIO = 'audio';
