import { AxiosResponse } from 'axios';

import HttpClientWithRetry from '../http/client/HttpClientWithRetry';

export class UnsubscribeController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async unSubscribeEmail(userId: string): Promise<AxiosResponse> {
    const path = `/api/v1/user/${userId}/unsubscribe`;
    return this.httpClient.get(path);
  }
}
