import { Box, Stack, HStack, Divider, Button, Select, Text } from '@chakra-ui/core';
import { useEffect } from 'react';
import { IoMoveSharp } from 'react-icons/io5';
import { SchemaBuilderProps, FieldSchema, formBuilderSchemas } from './formBuilderSchema';
import React from 'react';
import theme from '../../../../styles/customTheme';
import { EmptyComponent } from './EmptyComponent';
import { v4 as uuidv4 } from 'uuid';

interface FollowUpSchemaBuilderProps extends SchemaBuilderProps {}
export default function FollowUpSchemaBuilder({
  defaultValue: parentDefaultValue,
  onChange,
  language,
  followUpDragOver,
  path: parentPath,
}: FollowUpSchemaBuilderProps) {
  const [followUpOptions, setFollowUpOptions] = React.useState<string[]>([]);
  const path = [...parentPath, 'followUpQuestionList'];

  useEffect(() => {
    setFollowUpOptions(parentDefaultValue?.followUpQuestionList?.map((v) => v?.optionId!) || []);
    // eslint-disable-next-line
  }, []);
  return (
    <Box
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        followUpDragOver?.();
      }}
    >
      <Stack>
        <HStack>
          <AddFollowUp
            followUpOptions={followUpOptions}
            setFollowUpOptions={setFollowUpOptions}
            parentDefaultValue={parentDefaultValue}
          />
        </HStack>
        <Stack marginLeft="16px !important" divider={<Divider />} spacing={4}>
          {followUpOptions.map((optionId, index) => (
            <FollowUpQuestionSchemaBuilder
              followUpOptionId={optionId}
              followUpOptions={followUpOptions}
              setFollowUpOptions={setFollowUpOptions}
              defaultValue={parentDefaultValue}
              onChange={onChange}
              language={language}
              followUpDragOver={followUpDragOver}
              path={path}
              index={index}
              key={optionId}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}

interface AddFollowUpProps {
  followUpOptions: string[];
  setFollowUpOptions: (followUps: string[]) => void;
  parentDefaultValue: FieldSchema;
}
function AddFollowUp({ followUpOptions, setFollowUpOptions, parentDefaultValue }: AddFollowUpProps) {
  if (followUpOptions.length >= parentDefaultValue?.optionList?.length!) return null;

  const { optionList: options } = parentDefaultValue;

  function handleAddFollowUp() {
    if (!options?.length) return;
    if (followUpOptions?.length) {
      const nextOption = options.find((option) => followUpOptions.find((id) => id !== option.id));
      setFollowUpOptions([...followUpOptions, nextOption?.id!]);
    } else {
      setFollowUpOptions([options[0].id]);
    }
  }
  return (
    <Button variant="link" fontSize={12} fontWeight={'normal'} onClick={handleAddFollowUp}>
      Add Follow Up
    </Button>
  );
}

interface FollowUpOptionSelectProps {
  followUpOptions: string[];
  followUpOptionId: string;
  setFollowUpOptions: (followUps: string[]) => void;
  parentDefaultValue: FieldSchema;
  onChange: (schema: FieldSchema) => void;
}

function FollowUpOptionSelect({
  followUpOptionId,
  followUpOptions,
  setFollowUpOptions,
  parentDefaultValue,
  onChange,
}: FollowUpOptionSelectProps) {
  const options = [...parentDefaultValue?.optionList!];
  const followUpQuestions = parentDefaultValue?.followUpQuestionList || [];
  const availableOptions = options?.filter(
    (option) => !followUpOptions?.filter((optionId) => optionId !== followUpOptionId)?.find((id) => id === option.id),
  );

  return (
    <Select
      width="auto"
      size="sm"
      borderRadius={theme.radii.md}
      variant="filled"
      backgroundColor="#F3F6F9"
      value={followUpOptionId}
      onChange={(e) => {
        const currentKey = e.target.value;
        const followUpIndex = followUpOptions?.findIndex((optionId) => optionId === followUpOptionId);
        if (followUpIndex === -1) return;
        const newFollowUps = [...followUpOptions];
        newFollowUps[followUpIndex] = currentKey;
        setFollowUpOptions(newFollowUps);

        const followUpQuestionIndex = followUpQuestions?.findIndex((question) => question?.optionId === followUpOptionId) ?? -1;
        if (followUpQuestionIndex >= 0) {
          const newFollowUpQuestions = [...followUpQuestions];
          newFollowUpQuestions[followUpQuestionIndex] = {
            ...followUpQuestions?.[followUpQuestionIndex],
            optionId: currentKey,
            parent: parentDefaultValue.id,
          };
          const schema = {
            ...parentDefaultValue,
            followUpQuestion: newFollowUpQuestions,
          };
          onChange(schema);
        }
      }}
    >
      {availableOptions?.map(({ id }, index) => {
        return (
          <option key={index} value={id}>
            {String.fromCharCode(65 + index)}
          </option>
        );
      })}
    </Select>
  );
}

interface FollowUpQuestionSchemaBuilderProps extends SchemaBuilderProps {
  followUpOptionId: string;
  followUpOptions: string[];
  setFollowUpOptions: (followUps: string[]) => void;
  index: number;
}

function FollowUpQuestionSchemaBuilder({
  defaultValue: parentDefaultValue,
  onChange,
  language,
  followUpDragOver,
  followUpOptionId,
  followUpOptions,
  setFollowUpOptions,
  path: parentPath,
  index,
}: FollowUpQuestionSchemaBuilderProps) {
  const item = parentDefaultValue?.followUpQuestionList?.find((value) => value?.optionId === followUpOptionId);
  const Component = formBuilderSchemas?.[item?.inputType as keyof typeof formBuilderSchemas]?.component;

  const path = [...parentPath, index];
  return (
    <Stack key={followUpOptionId}>
      <HStack>
        <Button variant="link" fontSize={12} fontWeight={'normal'}>
          Show next question on:
        </Button>
        <FollowUpOptionSelect
          followUpOptions={followUpOptions}
          parentDefaultValue={parentDefaultValue}
          onChange={onChange}
          followUpOptionId={followUpOptionId}
          setFollowUpOptions={setFollowUpOptions}
        />
      </HStack>
      {!item && (
        <FollowUpEmptyComponent
          followUpOptionId={followUpOptionId}
          defaultValue={parentDefaultValue}
          onChange={onChange}
          language={language}
          followUpDragOver={followUpDragOver}
          path={path}
        />
      )}
      {item && 'HELLO'}
      {item && (
        <Component
          defaultValue={item!}
          onChange={(value) => {
            const newFollowUpQuestion = [...(parentDefaultValue?.followUpQuestionList ?? [])];
            const index = newFollowUpQuestion?.findIndex((v) => v.optionId === followUpOptionId) ?? -1;
            if (index >= 0) {
              newFollowUpQuestion[index] = { ...newFollowUpQuestion[index], ...value };
            }

            const result = {
              ...parentDefaultValue,
              followUpQuestionList: newFollowUpQuestion,
            };
            onChange(result);
          }}
          language={language}
          followUpDragOver={followUpDragOver}
          path={path}
        />
      )}
    </Stack>
  );
}

interface FollowUpEmptyComponentProps extends SchemaBuilderProps {
  followUpOptionId: string;
}

function FollowUpEmptyComponent({
  defaultValue: parentDefaultValue,
  onChange,
  followUpDragOver,
  followUpOptionId,
}: FollowUpEmptyComponentProps) {
  const followUpQuestions = parentDefaultValue.followUpQuestionList || [];
  return (
    <EmptyComponent
      onDrop={(event) => {
        const type = event.dataTransfer.getData('type');
        const { defaultValue } = formBuilderSchemas[type as keyof typeof formBuilderSchemas];

        const index = followUpQuestions?.findIndex((v) => v.optionId === followUpOptionId) ?? -1;
        if (index >= 0) {
          const newFollowUpQuestion = [...followUpQuestions];
          newFollowUpQuestion[index] = {
            ...defaultValue,
            id: uuidv4(),
            parent: parentDefaultValue.id,
            optionId: followUpOptionId,
            timestamp: Date.now(),
          };
          const schema = {
            ...parentDefaultValue,
            followUpQuestion: newFollowUpQuestion,
          };

          onChange(schema);
        } else {
          const schema = {
            ...parentDefaultValue,
            followUpQuestionList: [
              ...followUpQuestions,
              { ...defaultValue, id: uuidv4(), parent: parentDefaultValue.id, optionId: followUpOptionId, timestamp: Date.now() },
            ],
          };
          onChange(schema);
        }
      }}
      onDragOver={followUpDragOver}
    >
      <HStack
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#586073"
        align="center"
        justify="center"
        flexGrow={1}
      >
        <IoMoveSharp size={24} />
        <Text fontSize="sm" fontWeight="bold">
          Drag or Click on the items on your left to add a follow up.
        </Text>
      </HStack>
    </EmptyComponent>
  );
}
