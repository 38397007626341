/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useToast,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
} from '@chakra-ui/core';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useParams, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { meetViewStyle } from '../meet/MeetVide.styles';
import { useAuthenticationStatus } from '../../app/hooks/useAuthenticationStatus';
import i18n from '../../locales/i18n';
import { AuthenticationStatus } from '../../app/models/appModel';
import { GuestMeetParams } from '../../routes/constants';
import { ValidateURLCode, ValidateURLCodeExpiredError } from '../meet/MeetTokenResponse';
import InterviewCandidateInformation from './InterviewCandidateInformation';
import { CollaboratorBooking } from '../../firebase/firestore/documents/appointmentAvaiability';
import { RemoteConfigKey } from '../../firebase/remoteConfig';

export type QueryParamsType = {
  appointmentAvailabilityId: string;
  bookingId: string;
  candidateId: string;
};

export type MeetGuestVerificationProps = {
  joinRoom: () => void;
  isLoading: boolean;
};

export const MeetGuestVerification = ({ joinRoom, isLoading }: MeetGuestVerificationProps): JSX.Element => {
  const { t } = useTranslation('meet');
  const { candidateId } = useParams<GuestMeetParams>();
  const searchParams = decodeURI(useLocation().search);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryParamsData = searchParams
    ? (JSON.parse(searchParams.replace('?', '')) as QueryParamsType)
    : { bookingId: '', appointmentAvailabilityId: '' };

  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [bookingDetail, setBookingDetail] = useState<CollaboratorBooking | null>(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const { signInWithAnonymously } = useStoreActions((actions) => actions.auth);
  const { verifyURLWithCode, getInformationOfBooking } = useStoreActions((actions) => actions.meet);
  const { updateUserDetail, initializeAsEmployer, employerOnboarded } = useStoreActions((actions) => actions.user);
  const appUserId = useStoreState((s) => s.auth.firebaseUser?.uid);
  const loggedInUserName = useStoreState((s) => s.app.user?.firstName);
  const gracePeriod: number = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE];
  });

  const authenticationStatus = useAuthenticationStatus();

  const handleGuestLogin = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    await signInWithAnonymously().catch((err: any) => {
      console.log('error:', err);
      setIsLoadingBtn(false);
    });
  };

  const registerAnonymousUser = async (userId: string) => {
    await initializeAsEmployer({
      userId,
      employerInitializeData: {
        phoneNumber: null,
        locale: moment.locale(i18n.language),
        timeOffset: moment.parseZone(new Date()).utcOffset(),
      },
    });
    await employerOnboarded({ userId });
    await updateUserDetail({
      userId,
      userDetail: {
        firstName: userName,
        lastName: ' ',
        email: '',
        phone: '',
        anonymousUser: true,
        candidateId,
      },
    });
  };

  const getInformationOfInterviewBooking = useCallback(async () => {
    await getInformationOfBooking({
      availabilityId: queryParamsData.appointmentAvailabilityId,
      bookingId: queryParamsData.bookingId,
    }).then((responseData) => {
      setBookingDetail(responseData);
    });
  }, [getInformationOfBooking, queryParamsData.appointmentAvailabilityId, queryParamsData.bookingId]);

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.NEW_USER) {
      if (appUserId) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        registerAnonymousUser(appUserId).then(() => {
          joinRoom();
          setIsLoadingBtn(false);
        });
      }
    }
    if (authenticationStatus === AuthenticationStatus.AUTHENTICATED) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getInformationOfInterviewBooking();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationStatus]);

  const handleGuestTokenVerification = async () => {
    setIsLoadingBtn(true);
    await verifyURLWithCode({ code: verificationCode, bookingId: queryParamsData.bookingId })
      .then((response) => {
        const { isValid } = response as ValidateURLCode;
        const { message } = response as ValidateURLCodeExpiredError;
        if (isValid) {
          handleGuestLogin().catch((err) => {
            console.log(err);
          });
        } else {
          toast({
            title: message || t('guestMeet.invalidCode'),
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setIsLoadingBtn(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingBtn(false);
      });
  };

  const isAbleToJoinVideoInterview = useCallback(
    (booking: CollaboratorBooking) => {
      return moment().isBetween(
        moment(booking.date).subtract(5, 'minutes'),
        moment(booking.date).add(booking.duration, 'minutes').add(gracePeriod, 'minute'),
      );
    },
    [gracePeriod],
  );

  useEffect(() => {
    if (!bookingDetail) {
      return;
    }
    const checkTime = () => {
      setIsButtonEnabled(isAbleToJoinVideoInterview(bookingDetail));
    };

    // Check the time immediately when the component mounts
    checkTime();

    // Set an interval to check every minute
    intervalRef.current = setInterval(checkTime, 5 * 1000);

    // Cleanup the interval on unmount
    // eslint-disable-next-line consistent-return
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [bookingDetail, isAbleToJoinVideoInterview]);

  const displayMessage = (bookingInfo: CollaboratorBooking) => {
    if (moment(bookingInfo.date).add(bookingInfo.duration, 'minutes').add(gracePeriod, 'minute').isBefore()) {
      return `${t('guestMeet.joinMessagePastInterview')}`;
    }
    if (moment().diff(bookingDetail?.date, 'days') !== 0) {
      return `${t('guestMeet.joinMessagePreInterviewOnAnotherDay', {
        day: `${moment(bookingDetail?.date).format('MMM')} ${moment(bookingDetail?.date).format('DD')}`,
        time: moment(bookingDetail?.date).format('LT'),
      })}`;
    }
    return `${t('guestMeet.joinMessagePreInterview', { time: moment(bookingDetail?.date).format('LT') })}`;
  };

  return (
    <Box css={meetViewStyle}>
      <Box boxShadow="lg" mb={4}>
        <Box className="meet-card-header">
          <Heading as="h3" size="md">
            {t('guestMeet.readyToJoin')}
          </Heading>
          {(authenticationStatus === AuthenticationStatus.NEW_USER ||
            authenticationStatus === AuthenticationStatus.NOT_AUTHENTICATED) && <Text>{t('guestMeet.enterInfoToJoin')}</Text>}
        </Box>
        <Box className="meet-card-body" spacing={4}>
          {authenticationStatus === AuthenticationStatus.NEW_USER ||
          authenticationStatus === AuthenticationStatus.NOT_AUTHENTICATED ? (
            <Fragment>
              <FormControl id="email" mb={3} isRequired>
                <FormLabel>{t('guestMeet.name')}</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  value={userName}
                  placeholder={t('guestMeet.namePlaceholder')}
                  data-testid="NameInput"
                  onChange={(event) => setUserName(event.target.value.trimStart())}
                  required
                />
              </FormControl>
              <FormControl id="email" mb={5} isRequired>
                <FormLabel>{t('guestMeet.code')}</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  value={verificationCode}
                  placeholder={t('guestMeet.codePlaceholder')}
                  data-testid="CodeInput"
                  onChange={(event) => setVerificationCode(event.target.value)}
                  required
                />
              </FormControl>
              <Button
                mb={2}
                mr={2}
                size="sm"
                bg="blue.500"
                color="white"
                _hover={{
                  bg: 'blue.600',
                }}
                data-testid="askToJoinBtn"
                isLoading={isLoadingBtn || isLoading}
                disabled={isLoadingBtn || _isEmpty(userName) || _isEmpty(verificationCode)}
                onClick={() => handleGuestTokenVerification()}
              >
                {t('guestMeet.join')}
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              {loggedInUserName && bookingDetail && (
                <Heading size="sm" mb={3}>
                  <Text>{t('guestMeet.joinAsLabel', { userName: loggedInUserName })}</Text>
                  {isButtonEnabled ? <Text>{t('guestMeet.joinMessage')}</Text> : displayMessage(bookingDetail)}
                </Heading>
              )}
              <Button
                mb={2}
                mr={2}
                size="sm"
                bg="blue.500"
                color="white"
                _hover={{
                  bg: 'blue.600',
                }}
                data-testid="guestJoinBtn"
                isLoading={isLoadingBtn || isLoading}
                disabled={!isButtonEnabled || isLoadingBtn || isLoading}
                onClick={() => joinRoom()}
              >
                {t('guestMeet.join')}
              </Button>
              <Button
                mb={2}
                size="sm"
                bg="blue.500"
                color="white"
                _hover={{
                  bg: 'blue.600',
                }}
                onClick={onOpen}
              >
                Candidate Information
              </Button>
              <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />

                  <DrawerBody pl={2} pr={2}>
                    <InterviewCandidateInformation
                      candidateId={candidateId || ''}
                      setShowReviewRating={() => {}}
                      showReviewRating={false}
                      quitRoom={() => {}}
                      showCandidateAssessmentButton={(!_isNil(bookingDetail) && moment().isAfter(bookingDetail.date)) || false}
                    />
                  </DrawerBody>

                  <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button colorScheme="blue">Save</Button>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            </Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
};
