import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Stack,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useNotifications from '../../../app/topBar/notification/useNotifications';
import { NotificationTypeEnum } from '../../../app/topBar/notification/NotificationTypeEnum';
import colors from '../../../styles/colors';
import { LowAvailabilityStatusRecruiter, Notification } from '../../../firebase/firestore/documents/notification';
import { useEmployerPicture } from '../../../firebase/storage/hooks/useEmployerPicture';
import { ProfilePicture } from '../../common';
import { AvatarContainerStyles } from './LowAvailabilityView.styles';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { PATH_RECRUITER_CALENDAR } from '../../../routes/constants';
import { PositionsImpactedModal } from './PositionsImpactedModal';

export const LowAvailabilityView = (): JSX.Element => {
  const { t } = useTranslation('lowAvailability');
  const notifications = useNotifications(NotificationTypeEnum.LOW_AVAILABILITY, 7);

  const [activeNotification, setActiveNotification] = useState<Notification | null>(null);
  const [orderedNotifications, setOrderedNotifications] = useState<Notification[] | undefined>();

  useEffect(() => {
    const actNotifications = _orderBy(notifications, 'sentAt', ['desc']);
    setOrderedNotifications(actNotifications);
  }, [notifications]);

  useEffect(() => {
    if (!activeNotification) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      orderedNotifications && setActiveNotification(orderedNotifications[0]);
    }
  }, [orderedNotifications, activeNotification]);

  return (
    <VStack align="left" backgroundColor="white" p={4} minHeight="644px">
      <Box py={2} px={4} borderBottom="0.5px solid #E9EDEF">
        <Heading as="h4" fontSize="lg" lineHeight="1.3">
          {t('title')}
        </Heading>
        <Text m="3px 0" color="gray.500" fontSize="12px">
          {t('subTitle')}
        </Text>
      </Box>
      <Box py={4} px={6}>
        <Stack spacing={1} mb={3}>
          <Accordion defaultIndex={[0]} allowToggle allowMultiple>
            {orderedNotifications?.map((notification, notificationIndex) => {
              return (
                <AccordionItem
                  marginBottom="1rem"
                  border="none"
                  key={notification.id}
                  data-testid={`accordionItem-${notification.id}`}
                  onClick={() => {
                    setActiveNotification(notification);
                  }}
                >
                  <h2>
                    <Tooltip hasArrow label={t('tooltipModalHint')}>
                      <AccordionButton
                        borderBottom="0.5px solid #E9EDEF"
                        _expanded={{ bg: `rgba(233, 237, 239, 0.5)`, color: 'white', boxShadow: 'none' }}
                        _focus={{ bg: `rgba(233, 237, 239, 0.5)`, color: 'white', boxShadow: 'none' }}
                      >
                        <Box flex="1" textAlign="left">
                          <Text fontSize="xs" color={colors.gray[400]} fontWeight="700">
                            {moment(notification.sentAt).format('lll')}
                          </Text>

                          <Text
                            fontSize="sm"
                            color={colors.black}
                            data-testid={`notificationItem-${notification.id}`}
                            fontWeight="700"
                            cursor="pointer"
                          >
                            {notification.message}
                          </Text>
                        </Box>
                        <AccordionIcon color={colors.black} />
                      </AccordionButton>
                    </Tooltip>
                  </h2>
                  <RenderAccordionPanel notification={notification} currentNotificationIndex={notificationIndex} />
                </AccordionItem>
              );
            })}
          </Accordion>
        </Stack>
      </Box>
    </VStack>
  );
};

const LowAvailabilityRecruiter = ({ recruiter }: { recruiter: LowAvailabilityStatusRecruiter }): JSX.Element => {
  const picture = useEmployerPicture(recruiter.recruiterUserId);
  return (
    <Stack
      key={recruiter.recruiterUserId}
      direction="row"
      py={3}
      mr={8}
      className="recruiter-item"
      data-testid={`recruiterItem-${recruiter.recruiterUserId}`}
    >
      <ProfilePicture
        src={picture}
        className="avatar"
        name={`${recruiter.recruiterName}`}
        bg="transparent"
        style={{ height: '48px', width: '48px' }}
      />
      <Box maxWidth="90%">
        <Text fontSize="0.9rem" fontWeight="700" color={colors.black}>
          {recruiter.recruiterName}
        </Text>
        <Text fontSize="xs" color={colors.gray[400]}>
          {recruiter.recruiterEmail}
        </Text>
      </Box>
    </Stack>
  );
};

export type RenderAccordionPanelProps = {
  notification: Notification;
  currentNotificationIndex: number;
};

const RenderAccordionPanel = ({ notification, currentNotificationIndex }: RenderAccordionPanelProps): JSX.Element => {
  const { currentUserProfile } = useCurrentUserProfile();
  const [currentPositionImpactedRecruiterId, setCurrentPositionImpactedRecruiterId] = useState<string>('');
  const { t } = useTranslation('lowAvailability');

  const {
    isOpen: isPositionsImpactedModalOpen,
    onOpen: onPositionsImpactedOpen,
    onClose: onPositionsImpactedClose,
  } = useDisclosure();

  return (
    <AccordionPanel>
      <Box css={AvatarContainerStyles}>
        {_sortBy(notification?.additionalContextData?.recruiterAvailabilityStatusDetails, [
          (recruiter) => recruiter.recruiterUserId !== currentUserProfile?.id,
        ]).map((recruiter) => {
          return (
            <Box display="flex" alignItems="center" key={recruiter.recruiterUserId}>
              <LowAvailabilityRecruiter key={recruiter.recruiterUserId} recruiter={recruiter} />
              <Text color="#263DBA" fontWeight={700}>
                {currentUserProfile?.id === recruiter.recruiterUserId && (
                  <Link to={PATH_RECRUITER_CALENDAR}>{t('mySchedule')}</Link>
                )}
                {currentUserProfile?.id !== recruiter.recruiterUserId && currentNotificationIndex === 0 && (
                  <Text
                    onClick={() => {
                      onPositionsImpactedOpen();
                      setCurrentPositionImpactedRecruiterId(recruiter.recruiterUserId);
                    }}
                    cursor="pointer"
                    data-testid={`positionImpacted-${recruiter.recruiterUserId}`}
                  >
                    {t('positionsImpacted')}
                  </Text>
                )}
              </Text>
            </Box>
          );
        })}
      </Box>
      {currentPositionImpactedRecruiterId && (
        <PositionsImpactedModal
          isOpen={isPositionsImpactedModalOpen}
          onClose={onPositionsImpactedClose}
          recruiterId={currentPositionImpactedRecruiterId}
        />
      )}
    </AccordionPanel>
  );
};
