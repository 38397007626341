import { LocalVideoTrack } from 'twilio-video';
import { useCallback, useState } from 'react';
import _isNil from 'lodash/isNil';
import { useMeetContext } from '../../MeetContext';

export type LocalVideoTrackInfo = {
  isVideoEnabled: boolean;
  toggleVideo: () => void;
};

export const useLocalVideoToggle = (): LocalVideoTrackInfo => {
  const [isPublishing, setIsPublishing] = useState<boolean>(false);

  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack } = useMeetContext();
  const localParticipant = room?.localParticipant;

  const videoTrack = localTracks.find((track) => {
    return track.name.includes('camera');
  }) as LocalVideoTrack;

  // eslint-disable-next-line @typescript-eslint/require-await
  const toggleVideo = useCallback(async () => {
    if (isPublishing) return;

    if (videoTrack) {
      const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);

      // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
      localParticipant?.emit('trackUnpublished', localTrackPublication);
      removeLocalVideoTrack();
    } else {
      setIsPublishing(true);

      getLocalVideoTrack()
        .then((track: LocalVideoTrack) => {
          return localParticipant?.publishTrack(track, { priority: 'low' });
        })
        .finally(() => {
          setIsPublishing(false);
        });
    }
  }, [getLocalVideoTrack, isPublishing, localParticipant, removeLocalVideoTrack, videoTrack]);

  return {
    isVideoEnabled: !_isNil(videoTrack),
    toggleVideo,
  };
};
