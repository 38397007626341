import { css } from '@emotion/core';

export const BusinessListStyleForModal = css`
  .chakra-stack.search-bar {
    padding: 5px 15px;
    border-bottom: 1px solid #e5e7eb;
    > div {
      margin-bottom: 0;
    }
  }
  .business-location-list {
    padding: 0 !important;

    .business-location-item-padding {
      padding: 15px 40px 0;
      margin: 0;

      hr {
        width: calc(100% + 80px);
        margin: 0 -40px;
      }

      > div {
        align-items: center;
      }

      .go-arrow {
        display: block;
        transform: rotate(-90deg);
        position: relative;
      }
    }
  }
`;
