/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Flex, Grid, Text, Image } from '@chakra-ui/core';
import { IoMdVideocam } from 'react-icons/io';
import { IconContext } from 'react-icons/lib';
import { MdLocalPhone } from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import colors from '../../../../../../styles/colors';
import { MessagePresenter } from '../messagePresenter';
import { appointmentMessageCSS } from './AppointmentMessage.style';
import { AppointmentType } from '../../../../../../firebase/firestore/documents/candidateAudit';
import { InterviewConfigPresenter } from '../../../header/actionsDropdown/interviewConfigPresenter';
import {
  CandidateHistoryActionName,
  CandidateMessagingActionName,
} from '../../../../../../firebase/firestore/documents/candidateAuditActionNames';

export type AppointmentMessageProps = {
  message: MessagePresenter;
  interviewConfig: InterviewConfigPresenter | undefined;
};

export const AppointmentMessage = ({ message, interviewConfig, ...rest }: AppointmentMessageProps): JSX.Element => {
  const { t } = useTranslation('appointmentMessages');
  const appointmentInfoClassName = () => {
    switch (message.action) {
      case CandidateMessagingActionName.APPOINTMENT_REQUEST_CANCELLED:
      case CandidateMessagingActionName.APPOINTMENT_PERIOD_CANCELLED:
        return 'is-cancelled';
      default:
        return 'is-accepted';
    }
  };

  const appointmentType = message.appointmentType ?? interviewConfig?.getType();
  const appointmentDuration = message.appointmentDuration ?? interviewConfig?.getDuration();

  const getTranslatedMessage = () => {
    if (message.action === CandidateMessagingActionName.APPOINTMENT_REQUEST_CANCELLED) {
      return t('APPOINTMENT_REQUEST_DECLINED', {
        appointmentType: appointmentType ? appointmentType.toUpperCase() : ' ',
      });
    }

    if (message.action === CandidateMessagingActionName.APPOINTMENT_PERIOD_CANCELLED) {
      return t('APPOINTMENT_REQUEST_CANCELLED', {
        count: appointmentDuration,
        appointmentType: appointmentType ? appointmentType.toUpperCase() : ' ',
      });
    }

    return t(message.action, {
      count: appointmentDuration,
      appointmentType: appointmentType ? appointmentType.toUpperCase() : ' ',
    });
  };

  return (
    <Box
      css={appointmentMessageCSS}
      data-testid="AppointmentMessageBox"
      className={message.isFromSeeker ? 'is-pin-left' : 'is-pin-right'}
      {...rest}
    >
      <Grid id={message.id} className={appointmentType ? 'splitGrid' : ''} css={appointmentMessageCSS} width="100%">
        {appointmentType && (
          <Flex
            direction="column"
            alignItems="center"
            justify="center"
            marginTop={interviewConfig || message.action === CandidateMessagingActionName.APPOINTMENT_REQUEST_CREATED ? 1 : 3}
            className={`${appointmentInfoClassName()}-icon`}
            css={appointmentMessageCSS}
          >
            <IconContext.Provider value={{ color: colors.white }}>
              {appointmentType === AppointmentType.video && <IoMdVideocam size={14} />}
              {appointmentType === AppointmentType.phone && <MdLocalPhone size={14} />}
              {appointmentType === AppointmentType.inPerson && <FiUsers size={14} />}
            </IconContext.Provider>
          </Flex>
        )}

        {message.action === CandidateMessagingActionName.APPOINTMENT_REQUEST_CREATED && (
          <Text paddingLeft={interviewConfig ? 0 : 3} paddingRight={3} py={1} color={colors.blue.default} lineHeight="shorter">
            {interviewConfig &&
              `${t(message.action, {
                appointmentType: message.appointmentType?.toUpperCase() ?? interviewConfig.getType().toUpperCase(),
                count: appointmentDuration,
              })}`}
            {!interviewConfig && t(`${message.action}_NO_TYPE`)}
          </Text>
        )}
        {message.action === CandidateHistoryActionName.INTEREST_REVOKED && (
          <Text paddingLeft={3} paddingRight={3} py={1} lineHeight="shorter">
            {t('INTEREST_REVOKED')}
          </Text>
        )}
        {message.action !== CandidateMessagingActionName.APPOINTMENT_REQUEST_CREATED &&
          message.action !== CandidateHistoryActionName.INTEREST_REVOKED &&
          message.action !== CandidateHistoryActionName.CANDIDATE_FORM_HELP_REQUESTED && (
            <Flex direction="column" paddingRight="20px" paddingLeft={appointmentType ? '' : '20px'}>
              <Text className={`${appointmentInfoClassName()}-info`} css={appointmentMessageCSS}>
                {getTranslatedMessage()}
              </Text>
              <Text className="appointmentDate" css={appointmentMessageCSS}>
                {message.appointmentDate && t('APPOINTMENT_MESSAGE.APPOINTMENT_DATE', { date: message.appointmentDate })}
              </Text>
            </Flex>
          )}
      </Grid>
    </Box>
  );
};
