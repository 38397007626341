import React, { useState, useRef, useCallback } from 'react';
import {
  Box,
  Heading,
  VStack,
  Button,
  InputGroup,
  Textarea,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  useDisclosure,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../models/hooks';

const HireFormApiKeyView = (): JSX.Element => {
  const { t } = useTranslation();
  const toast = useToast();
  const [apiKey, setApiKey] = useState('');
  const accountId = useStoreState((s) => s.app.accounts?.id);
  const { getHireFormAPIKey } = useStoreActions((actions) => actions.administration);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  const getAPIKey = useCallback(
    (isRenew: boolean) => {
      if (accountId) {
        getHireFormAPIKey({ accountId, isRenew })
          .then((data) => {
            setApiKey(data);
            onOpen();
          })
          .catch((e: string) => {
            console.log('error:', e);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, getHireFormAPIKey, toast],
  );

  const copyAPIKey = async () => {
    await navigator.clipboard.writeText(apiKey);
    toast({
      title: t('administration:hireFormAPIKey.copiedLabel'),
      status: 'info',
      duration: 1000,
    });
    onClose();
    setApiKey('');
  };

  const showAsterisk = () => {
    let longString = '';
    for (let i = 0; i < 500; i += 1) {
      longString += '*';
    }

    return longString;
  };

  const confirmationDialog = (): JSX.Element => {
    return (
      <AlertDialog
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent data-testid="ConfirmationAlertModal">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {apiKey.length <= 0
                ? t('administration:hireFormAPIKey.alertModal.title')
                : t('administration:hireFormAPIKey.alertModalCopy.title')}
            </AlertDialogHeader>
            <AlertDialogBody>
              {apiKey.length <= 0 ? (
                t('administration:hireFormAPIKey.alertModal.description')
              ) : (
                <Box bg="gray.50" border="1px solid" borderColor="gray.100" borderRadius="5px" p={3} fontSize="sm">
                  {apiKey}
                </Box>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>{t('administration:hireFormAPIKey.alertModal.cancel')}</Button>
              <Button
                data-testid="getNewAPIKeyBtn"
                colorScheme="red"
                onClick={() => {
                  if (apiKey.length <= 0) {
                    getAPIKey(true);
                    onClose();
                  } else {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    copyAPIKey();
                  }
                }}
                ml={3}
              >
                {apiKey.length <= 0
                  ? t('administration:hireFormAPIKey.alertModal.confirm')
                  : t('administration:hireFormAPIKey.alertModalCopy.copyBtn')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  };

  return (
    <VStack align="left" backgroundColor="white" p={4}>
      <Box py={2} px={4} borderBottom="0.5px solid #ECEFF1">
        <Heading as="h4" fontSize="lg" lineHeight="1.3">
          {t('administration:hireFormAPIKey.label')}
        </Heading>
      </Box>
      <Box py={2} px={4}>
        <InputGroup size="md" mb={4}>
          <Textarea pr="3.5rem" minH="100px" borderColor="gray.100" defaultValue={showAsterisk()} />
        </InputGroup>
        <Button data-testid="generateNewAPIKeyBtn" colorScheme="blue" onClick={onOpen}>
          {t('administration:hireFormAPIKey.generateNewAPIKey')}
        </Button>
      </Box>
      {confirmationDialog()}
    </VStack>
  );
};

export default HireFormApiKeyView;
