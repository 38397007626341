/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import _isNil from 'lodash/isNil';
import { Container, Box, Center, Heading, Image } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { useParams, useLocation } from 'react-router';
import moment from 'moment-timezone';
import { ANIMATED_VARIANTS, EmailInvitationTabParams } from '../../routes/constants';
import { EmailInvitationStyle } from './EmailInvitation.styles';
import logo from '../../assets/img/appyhere-seeker-logo.svg';
import { useStoreActions } from '../../models/hooks';

const MotionBox = motion.custom(Box);

const content = {
  accepted: {
    title: 'Great! Thank you for accepting.',
    description:
      'Here on the next step you will receive this reminder on the day of the event\n' +
      'on your email, make sure to use that link to join the event on',
  },
  declined: {
    title: 'Oh No! You’ve declined.',
    description: 'You have declined the invitation which is on',
  },
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const EmailInvitationView = (): JSX.Element => {
  const { type } = useParams<EmailInvitationTabParams>();
  const token = useQuery().get('token');
  const eventDate = moment(useQuery().get('date')).tz(moment.tz.guess()).format('dddd, DD MMMM YYYY, hh:mmA (z)');
  const { acceptInvitation, declineInvitation } = useStoreActions((actions) => actions.emailInvitation);

  useEffect(() => {
    if (!_isNil(token) && !_isNil(type)) {
      if (type === 'accept') {
        acceptInvitation({ token });
      } else {
        declineInvitation({ token });
      }
    }
  }, [acceptInvitation, declineInvitation, token, type]);

  return (
    <MotionBox
      css={EmailInvitationStyle}
      data-testid="EmailInvitationView"
      initial="initial"
      animate="in"
      exit="out"
      variants={ANIMATED_VARIANTS}
    >
      <Center height="100vh" minH="100vh">
        <Container maxW="container.sm" centerContent>
          <Box
            className={`invitation-content-box ${type === 'accept' ? 'accepted' : 'declined'}`}
            bg="white"
            mb={4}
            textAlign="center"
          >
            <Image
              src={logo}
              className="logo"
              htmlWidth={100}
              htmlHeight={100}
              alt="AppyHere Seeker logo"
              data-testid="LogoImage"
            />
            <Heading as="h4" size="lg" isTruncated>
              {type === 'accept' ? content.accepted.title : content.declined.title}
            </Heading>
            <p>
              {type === 'accept' ? content.accepted.description : content.declined.description}
              <b data-testid="EventTime">{` ${eventDate}`}</b>
            </p>
          </Box>
        </Container>
      </Center>
    </MotionBox>
  );
};
