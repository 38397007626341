import { css } from '@emotion/core';

export const guestInterviewStyle = css`
  .interview-with-guest {
    @media (max-width: 992px) {
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .main-participant {
    height: calc(100vh - 145px);

    .publication-track {
      height: 100%;
    }

    video {
      height: calc(100vh - 145px);

      @media (max-width: 992px) {
        height: calc(100vh - 300px);
      }

      @media screen and (max-width: 922px) and (orientation: landscape) {
        height: calc(100vh - 240px);
      }
    }
  }
  .participants-blocks {
    height: 100%;
    width: 30%;
    height: 100%;
    min-width: 220px;
    max-width: 280px;

    @media (max-width: 992px) {
      height: 180px;
      width: 100%;
      max-width: 100%;
      overflow-x: auto;
    }

    @media (max-width: 576px) {
      width: 100%;
    }
    .participant-list {
      width: 100%;
      overflow-y: auto;
      height: 100%;
      background-color: #34344e;
      padding: 5px;
      flex-direction: column;

      @media (max-width: 992px) {
        flex-direction: row;
        overflow-y: hidden;
        overflow-x: auto;
      }

      .participant-view {
        width: 100%;
        height: 10rem;
        border-radius: 10px;
        margin-bottom: 5px;

        @media (max-width: 992px) {
          min-width: 150px;
          height: 120px;
          margin-right: 5px;
          max-width: 200px;
        }

        @media screen and (max-width: 922px) and (orientation: landscape) {
          height: 90px;
        }

        .publication-track {
          height: 100%;
        }

        .video-publication {
          height: 100%;
        }

        .avatar-container {
          width: 100% !important;
        }

        .participant-info-bar {
          @media (max-width: 992px) {
            flex-direction: column-reverse;
            height: 100%;
            bottom: 0;

            .participant-name {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .video-wrapper .participant-view video {
    max-height: 100% !important;
  }
`;

export const GuestInterviewWrapperStyle = css`
  .video-container {
    @media (max-width: 568px) {
      width: 100%;
    }

    .info-btn {
      position: absolute;
      top: 15px;
      right: 10px;
      z-index: 111;
      padding: 0px !important;
      height: 35px;
      width: 35px !important;
      min-width: 35px;

      &:focus {
        box-shadow: none;
      }

      @media (min-width: 568px) {
        display: none;
      }
    }
  }
  .candidate-info-container {
    @media screen and (max-width: 922px) and (orientation: landscape) {
      min-width: 250px;
    }
    @media (max-width: 568px) {
      position: fixed;
      background: #fff;
      width: 100%;
      top: 0;
      height: 100%;
      left: 100%;
      transition: all ease 0.3s;

      &.active {
        left: 0;
      }
    }
    > div {
      max-width: 100%;
    }

    .experience-wrapper div > div {
      @media screen and (max-width: 922px) and (orientation: landscape) {
        flex-direction: column;
      }
    }
  }
`;
