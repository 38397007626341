/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Action, action, thunk, Thunk } from 'easy-peasy';
import _isNil from 'lodash/isNil';
import { HttpStoreModel } from '../../../http/models/httpModel';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { MeetController } from '../../../controllers/meetController';
import { VideoRoomAccessInfo, VideoRoomAccessError } from './videoRoomAccessInfo';
import {
  InvalidMeetTokenResponse,
  InvalidUser,
  NewUserInfo,
  NotVerified,
  ParticipantData,
  ValidMeetTokenResponse,
  CandidateInfoForGuest,
  ValidateURLCode,
  ValidateURLCodeExpiredError,
} from '../MeetTokenResponse';
import { MeetParticipantResponse, GetInterviewBookingDetailResponse } from '../MeetParticipantResponse';
import { AppointmentType } from '../../../firebase/firestore/documents/position';
import { CollaboratorNotes } from '../../../firebase/firestore/documents/candidate';
import { CollaboratorBooking } from '../../../firebase/firestore/documents/appointmentAvaiability';

export type MeetStoreModel = {
  meet: MeetModel;
};

export interface MeetModel {
  groupEventDetail: ParticipantData | undefined;
  setGroupEvent: Action<MeetModel, ParticipantData | undefined>;
  joinInterview: Thunk<
    MeetModel,
    { candidateId: string },
    void,
    HttpStoreModel,
    Promise<VideoRoomAccessInfo | VideoRoomAccessError | null>
  >;
  leaveInterview: Thunk<MeetModel, { candidateId: string }, void, HttpStoreModel>;
  joinMeet: Thunk<
    MeetModel,
    { groupEventId: string; participantId: string },
    MeetModel,
    HttpStoreModel,
    Promise<VideoRoomAccessInfo | null>
  >;
  leaveMeet: Thunk<MeetModel, { groupEventId: string; participantId: string }, void, HttpStoreModel>;

  verifyMeetToken: Thunk<
    MeetModel,
    { meetToken: string },
    void,
    HttpStoreModel,
    Promise<ValidMeetTokenResponse | InvalidMeetTokenResponse | NotVerified | null>
  >;

  verifyURLWithCode: Thunk<
    MeetModel,
    { code: string; bookingId: string },
    void,
    HttpStoreModel,
    Promise<ValidateURLCode | ValidateURLCodeExpiredError | NotVerified | null>
  >;

  meetOnBoarding: Thunk<
    MeetModel,
    { isCandidate: boolean; email: string; newUserInfo?: NewUserInfo; groupEventId?: string },
    void,
    HttpStoreModel,
    Promise<ParticipantData | InvalidUser | null>
  >;

  getCandidateDataOfMeet: Thunk<
    MeetModel,
    { positionId: string; seekerId: string },
    void,
    HttpStoreModel,
    Promise<CandidateInfoForGuest | InvalidMeetTokenResponse | NotVerified | null>
  >;

  clearMeetGroupEvent: Action<MeetModel>;
  setMeetParticipants: Action<MeetModel, MeetParticipantResponse | undefined>;
  meetParticipants: MeetParticipantResponse | undefined;
  getMeetParticipants: Thunk<
    MeetModel,
    { groupEventId: string; participantId: string },
    void,
    HttpStoreModel,
    Promise<MeetParticipantResponse | null>
  >;
  clearMeetParticipant: Action<MeetModel>;

  rateInterview: Thunk<
    MeetModel,
    {
      positionId: string;
      seekerId: string;
      rating: number;
      assessmentReason: string;
      date: Date;
      interviewType: AppointmentType;
      businessId: string;
      review: string;
    },
    void,
    HttpStoreModel
  >;

  collaboratorRateInterview: Thunk<
    MeetModel,
    {
      positionId: string;
      seekerId: string;
      rating: number;
      assessmentReason: string;
      date: Date;
      interviewType: AppointmentType;
      businessName: string;
      collaborator: string;
      review: string;
    },
    void,
    HttpStoreModel
  >;

  getInterviewBookingDetail: Thunk<
    MeetModel,
    { bookingId: string },
    void,
    HttpStoreModel,
    Promise<GetInterviewBookingDetailResponse | null>
  >;

  getCollaboratorNotes: Thunk<
    MeetModel,
    { positionId: string; seekerId: string },
    void,
    HttpStoreModel,
    Promise<CollaboratorNotes | null>
  >;

  getInformationOfBooking: Thunk<
    MeetModel,
    { availabilityId: string; bookingId: string },
    void,
    HttpStoreModel,
    Promise<CollaboratorBooking | null>
  >;
}

export const meetStoreModel: MeetModel = {
  groupEventDetail: undefined,
  meetParticipants: undefined,
  joinInterview: thunk(
    async (actions, payload, { getStoreState }): Promise<VideoRoomAccessInfo | VideoRoomAccessError | null> => {
      const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
      const response = await controller.joinInterview(payload.candidateId);
      // istanbul ignore next
      if (!_isNil(response)) {
        if (response.status === 422) {
          return response.data as VideoRoomAccessError;
        }
        return response.data as VideoRoomAccessInfo;
      }
      return null;
    },
  ),

  joinMeet: thunk(
    async (_actions, payload, { getStoreState }): Promise<VideoRoomAccessInfo | null> => {
      try {
        const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.joinMeet(payload.groupEventId, payload.participantId);
        return response.data as VideoRoomAccessInfo;
      } catch (e) {
        return null;
      }
    },
  ),

  leaveInterview: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
    await controller.leaveInterview(payload.candidateId);
  }),

  leaveMeet: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
    await controller.leaveMeet(payload.groupEventId, payload.participantId);
  }),

  verifyMeetToken: thunk(
    async (
      _actions,
      payload,
      { getStoreState },
    ): Promise<ValidMeetTokenResponse | InvalidMeetTokenResponse | NotVerified | null> => {
      try {
        const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.verifyMeetToken(payload.meetToken);
        return response?.data as ValidMeetTokenResponse | InvalidMeetTokenResponse;
      } catch (e) {
        // istanbul ignore next
        return null;
      }
    },
  ),

  getCandidateDataOfMeet: thunk(
    async (_actions, payload, { getStoreState }): Promise<CandidateInfoForGuest | InvalidMeetTokenResponse | null> => {
      try {
        const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.getCandidateDataOfMeet(payload.positionId, payload.seekerId);
        return response?.data as CandidateInfoForGuest | InvalidMeetTokenResponse;
      } catch (e) {
        // istanbul ignore next
        return null;
      }
    },
  ),

  verifyURLWithCode: thunk(
    async (_actions, payload, { getStoreState }): Promise<ValidateURLCode | ValidateURLCodeExpiredError | NotVerified | null> => {
      try {
        const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.verifyURLWithCode(payload.code, payload.bookingId);
        return response?.data as ValidateURLCode | ValidateURLCodeExpiredError;
      } catch (e) {
        // istanbul ignore next
        return null;
      }
    },
  ),

  meetOnBoarding: thunk(
    async ({ setGroupEvent }, payload, { getStoreState }): Promise<ParticipantData | InvalidUser | null> => {
      try {
        const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.meetOnBoarding(
          payload.email,
          payload.isCandidate,
          payload?.newUserInfo,
          payload?.groupEventId,
        );

        setGroupEvent(response.data as ParticipantData);
        // istanbul ignore next
        return response.data as ParticipantData | InvalidUser;
      } catch (e) {
        setGroupEvent(undefined);
        return null;
      }
    },
  ),

  setGroupEvent: action((state, eventDetail) => {
    if (_isNil(eventDetail)) {
      state.groupEventDetail = undefined;
      return;
    }
    state.groupEventDetail = { ...state.groupEventDetail, ...eventDetail };
  }),

  clearMeetGroupEvent: action((state) => {
    state.groupEventDetail = undefined;
  }),

  setMeetParticipants: action((state, meetParticipants) => {
    if (_isNil(meetParticipants)) {
      state.meetParticipants = undefined;
      return;
    }
    state.meetParticipants = { ...state.meetParticipants, ...meetParticipants };
  }),

  getMeetParticipants: thunk(
    async ({ setMeetParticipants }, payload, { getStoreState }): Promise<MeetParticipantResponse | null> => {
      try {
        const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.getMeetParticipants(payload.groupEventId, payload.participantId);
        setMeetParticipants(response.data as MeetParticipantResponse);
        // istanbul ignore next
        return response.data as MeetParticipantResponse;
      } catch (e) {
        return null;
      }
    },
  ),

  clearMeetParticipant: action((state) => {
    state.meetParticipants = undefined;
  }),

  rateInterview: thunk(
    /* istanbul ignore next */ async (_actions, payload, { getStoreState }) => {
      // istanbul ignore next
      const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
      await controller.rateInterview(
        payload.positionId,
        payload.seekerId,
        payload.rating,
        payload.review,
        payload.date,
        payload.interviewType,
        payload.businessId,
        payload.assessmentReason,
      );
    },
  ),

  collaboratorRateInterview: thunk(
    /* istanbul ignore next */ async (_actions, payload, { getStoreState }) => {
      // istanbul ignore next
      const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
      await controller.collaboratorRateInterview(
        payload.positionId,
        payload.seekerId,
        payload.rating,
        payload.review,
        payload.date,
        payload.interviewType,
        payload.businessName,
        payload.collaborator,
        payload.assessmentReason,
      );
    },
  ),

  getInterviewBookingDetail: thunk(
    /* istanbul ignore next */ async (
      _actions,
      payload,
      { getStoreState },
    ): Promise<GetInterviewBookingDetailResponse | null> => {
      // istanbul ignore next
      try {
        const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.getInterviewBookingDetail(payload.bookingId);
        return response?.data as GetInterviewBookingDetailResponse;
      } catch (e) {
        // istanbul ignore next
        return null;
      }
    },
  ),

  getCollaboratorNotes: thunk(
    /* istanbul ignore next */ async (_actions, payload, { getStoreState }): Promise<CollaboratorNotes | null> => {
      // istanbul ignore next
      try {
        const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.getCollaboratorNotes(payload.positionId, payload.seekerId);
        return response?.data as CollaboratorNotes;
      } catch (e) {
        // istanbul ignore next
        return null;
      }
    },
  ),

  getInformationOfBooking: thunk(
    /* istanbul ignore next */ async (_actions, payload, { getStoreState }): Promise<CollaboratorBooking | null> => {
      // istanbul ignore next
      try {
        const controller = new MeetController(getStoreState().http.client as HttpClientWithRetry);
        const response = await controller.getInformationOfBooking(payload.availabilityId, payload.bookingId);
        // istanbul ignore next
        return response?.data as CollaboratorBooking;
      } catch (e) {
        // istanbul ignore next
        return null;
      }
    },
  ),
};
