import { Collections } from '../../firebase/firestore/collections';
import { useDocumentSnapshot } from '../../firebase/firestore/hooks';
import { AppointmentAvailability } from '../../firebase/firestore/documents/appointmentAvaiability';

const useAppointmentAvailability = (appointmentAvailabilityId: string | undefined): AppointmentAvailability | undefined => {
  const bookingData = useDocumentSnapshot<AppointmentAvailability>(
    Collections.APPOINTMENT_AVAILABILITY,
    appointmentAvailabilityId,
  );
  return bookingData;
};

export default useAppointmentAvailability;
