import { Box, Text } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../styles/customTheme';

interface InputPreviewProps {
  value: string;
}
export default function InputPreview({ value }: InputPreviewProps) {
  return (
    <Box backgroundColor="#F3F6F9" borderRadius={theme.radii.md} padding={theme.space[2]} display="inline-flex" w="fit-content">
      <Text fontSize="sm">{value}</Text>
    </Box>
  );
}
