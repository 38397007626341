import React from 'react';
import { Box, Spinner, Stack, Text } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import colors from '../../styles/colors';

interface AppLoadingSpinnerProps {
  loadingText?: string;
  height?: string;
  color?: string;
}

export const AppLoadingSpinner = ({ loadingText, height, color }: AppLoadingSpinnerProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box className="super-centered" height={isEmpty(height) ? '100vh' : height}>
      <Stack direction="column" alignItems="center" spacing={4} data-testid="Loading">
        <Spinner color="#1F3CBA" data-testid="Spinner" />
        <Text textColor={isEmpty(color) ? colors.black : color}>{isEmpty(loadingText) ? t('app:loading') : loadingText}</Text>
      </Stack>
    </Box>
  );
};

AppLoadingSpinner.defaultProps = {
  loadingText: '',
  height: '',
  color: '',
};
