/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/core';
import { ChevronDownIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import moment from 'moment-timezone';
import _isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import Select, { ValueType, components } from 'react-select';
import Joi, { ValidationErrorItem } from 'joi';
import { drawerCSS, reactSelectStyles } from '../CalendarView.Style';
import { reactDatePickerCSS } from '../scheduleSidebar/ReactDatePicker.Style';
import { ReactComponent as _IconCalendar } from '../../../assets/img/icon-calendar-blue.svg';
import { ReactComponent as _IconAddUser } from '../../../assets/img/icon-add-user.svg';
import { useStoreActions } from '../../../models/hooks';
import { calendarCreateEvent } from './CalendarCreateEvent.Style';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { loginButtonStyle } from '../../auth/login/loginForm/LoginForm.styles';
import { CalendarEventType } from '../CalendarEventType';
import { CalendarParticipantType, GroupEventResponse } from './CalendarParticipantType';
import { Recruiter } from '../../../firebase/firestore/documents/recruiter';
import { EventDetailPresenter } from './EventDetailPresenter';
import { getUndefinedOrEventType } from '../getUndefinedOrEventType';
import { clearErrorMessage, FormattedError, getErrorMessage, hasErrorMessage } from '../../../utils/FormErrorUtils';
import colors from '../../../styles/colors';
import i18n from '../../../locales/i18n';

const IconCalendar = memo(_IconCalendar);
const IconAddUser = memo(_IconAddUser);

export type CalendarCreateEventProps = {
  isOpen: boolean;
  onClose: () => void;
  calendarEvent: CalendarEventType | undefined;
  selectedEvent: EventDetailPresenter | undefined;
  allRecruiters: Recruiter[];
};

export const CalendarCreateEvent = ({
  isOpen,
  onClose,
  calendarEvent,
  selectedEvent,
  allRecruiters,
}: CalendarCreateEventProps): JSX.Element => {
  const { t } = useTranslation('calendar');
  const firstField = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const quillRef = React.useRef() as React.RefObject<ReactQuill>;
  const [startDate, setStartDate] = React.useState<Date | undefined>();
  const [startTime, setStartTime] = React.useState<Date | null>();
  const [endTime, setEndTime] = React.useState<Date | null>();
  const [eventTitle, setEventTitle] = useState<string>('');
  const [noteContent, setNoteContent] = useState<string>('');
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [recruiterItems, setRecruiterItems] = React.useState<CalendarParticipantType[]>([]);
  const [selectedRecruiters, setSelectedRecruiters] = React.useState<readonly CalendarParticipantType[]>([]);
  const [candidateEmails, setCandidateEmails] = React.useState<string>('');
  const [selectedCandidates, setSelectedCandidates] = React.useState<CalendarParticipantType[]>([]);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [candidateInputWidth, setCandidateInputWidth] = useState(27);

  const { saveEvent, updateEvent } = useStoreActions((actions) => actions.calendar);

  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';
  const currentUserId = currentUserProfile?.id ?? '';

  const toast = useToast();

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      title: Joi.string().required().messages({
        'string.empty': `Event title is required`,
      }),
      description: Joi.string().required().messages({
        'string.empty': `Event description is required`,
      }),
      date: Joi.date().required().messages({
        'any.required': `Date is required`,
      }),
      startTime: Joi.date().required().messages({
        'any.required': `Required`,
        'date.base': 'Required',
      }),
      endTime: Joi.date().required().messages({
        'any.required': `Required`,
        'date.base': 'Required',
      }),
      candidates: Joi.array()
        .items(
          Joi.string()
            .required()
            .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } }),
        )
        .required()
        .messages({ 'array.includesRequiredUnknowns': 'Candidates is required' }),
    });

  /* istanbul ignore next */
  const handleEventTitle = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    setEventTitle(event.target.value);
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  /* istanbul ignore next */
  const handleDateOfEvent = (fieldName: string, date: Date) => {
    setStartDate(date);
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  /* istanbul ignore next */
  const handleStartTimeOfEvent = (fieldName: string, date: Date) => {
    setStartTime(date);
    setEndTime(null);
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  /* istanbul ignore next */
  const handleEndTimeOfEvent = (fieldName: string, date: Date) => {
    setEndTime(date);
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const handleCandidateEmails = (fieldName: string, items: string) => {
    setCandidateEmails(items.trim());
    /* istanbul ignore next */
    setCandidateInputWidth(items.length === 0 ? 27 : items.length);
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  // eslint-disable-next-line consistent-return
  const handleCandidateOnBlur = () => {
    /* istanbul ignore next */
    if (candidateEmails === '') {
      return false;
    }
    const candidatesArray = candidateEmails.split(',');
    const formattedCandidateEmails = candidatesArray.map((c) => {
      return {
        label: `${c.trim()}`,
        value: c.trim(),
      } as CalendarParticipantType;
    });
    setSelectedCandidates((prevState) => [...prevState, ...formattedCandidateEmails]);
    setCandidateEmails('');
    setCandidateInputWidth(27);
  };

  /* istanbul ignore next */
  const handleCandidateOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleCandidateOnBlur();
    }
  };

  /* istanbul ignore next */
  const removeSelectedCandidate = (event: any, i: number) => {
    const newList = selectedCandidates.filter((item, index) => index !== i);
    setSelectedCandidates(newList);
    setFormErrors(clearErrorMessage(formErrors, 'candidates'));
  };

  // eslint-disable-next-line no-shadow
  /* istanbul ignore next */
  const handleOptionChange = (sRecruiters: ValueType<CalendarParticipantType, true>) => {
    if (sRecruiters) {
      setSelectedRecruiters(sRecruiters);
    }
  };

  /* istanbul ignore next */
  const handleNoteContent = (value: string) => {
    setNoteContent(value);
    setFormErrors(clearErrorMessage(formErrors, 'description'));
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <IconAddUser className="icon-add-user" width={30} height={30} />
      </components.DropdownIndicator>
    );
  };

  /* istanbul ignore next */
  const CustomDatePickerInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <ButtonGroup
      className={`customDatepicker ${props.value ? '' : 'has-placeholder'}`}
      size="sm"
      isAttached
      variant="outline"
      onClick={props.onClick}
      ref={ref}
    >
      <IconButton aria-label="calendar" icon={<IconCalendar width={30} height={30} />} />
      <Button mr="-px" isFullWidth>
        {props.value ? props.value : ''}
      </Button>
    </ButtonGroup>
  );

  const handleApiResponse = (response: GroupEventResponse | null) => {
    if (response && response.status === 400) {
      toast({
        title: t('eventForm.errorTitle'),
        description: t(response.message),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsSaving(false);
    } else {
      toast({
        title: t('eventForm.successTitle'),
        description: response?.message,
        duration: 3000,
        isClosable: true,
      });
      onClose();
    }
  };

  const createEvent = async (
    title: string,
    sDate: Date,
    sTime: Date,
    eTime: Date,
    recruiters: string[],
    candidates: string[],
  ) => {
    const response = await saveEvent({
      accountId,
      groupEvent: {
        title,
        description: noteContent,
        date: sDate,
        startTime: sTime,
        endTime: eTime,
      },
      candidates,
      recruiters,
    });

    handleApiResponse(response);
  };

  const updateGroupEvent = async (
    groupEventId: string,
    title: string,
    sDate: Date,
    sTime: Date,
    eTime: Date,
    recruiters: string[],
    candidates: string[],
  ) => {
    const response = await updateEvent({
      groupEventId,
      accountId,
      groupEvent: {
        title,
        description: noteContent,
        date: sDate,
        startTime: sTime,
        endTime: eTime,
      },
      candidates,
      recruiters,
    });

    handleApiResponse(response);
  };

  const addTimeToDate = (sDate: Date, eDate: Date) => {
    const eMoment = moment(eDate);
    return moment(sDate).set('minutes', eMoment.get('minutes')).set('hours', eMoment.get('hours')).set('seconds', 0).toDate();
  };

  const formatError = (errors: ValidationErrorItem[]) => {
    return errors.reduce((acc, obj) => {
      let found = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < acc.length; i++) {
        if (acc[i].label === obj.path[0] && obj.type === 'string.email') {
          found = true;
          // eslint-disable-next-line no-param-reassign
          acc[i].message = `${acc[i].message}, ${obj.context?.value}`;
          // eslint-disable-next-line no-param-reassign
          acc[i].type = `${obj.type}`;
        }
      }
      if (!found) {
        if (obj.type === 'string.email') {
          acc.push({
            label: obj.path[0],
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            message: obj.context?.value,
          } as FormattedError);
        } else {
          acc.push({
            label: obj.path[0],
            message: obj.message,
          } as FormattedError);
        }
      }
      return acc;
    }, [] as FormattedError[]);
  };

  const formValidation = (formData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = schema.validate(formData);
    if (error) {
      setFormErrors(formatError(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const submitEvent = async () => {
    const selectedRecruiter = selectedRecruiters.map((r) => r.value);
    const selectedCandidate = selectedCandidates.map((c) => c.value);

    const formData = {
      title: eventTitle,
      description: noteContent,
      date: startDate,
      startTime,
      endTime,
      candidates: selectedCandidate,
    };

    if (formValidation(formData) && !_isNil(startDate) && !_isNil(startTime) && !_isNil(endTime)) {
      setIsSaving(true);
      const newStartDate = addTimeToDate(startDate, startTime);
      const newStartTime = addTimeToDate(startDate, startTime);
      const newEndTime = addTimeToDate(startDate, endTime);
      const selectedRecruiterWithProfileEmail = selectedRecruiter.concat(currentUserProfile?.email ?? '');

      if (selectedEvent && selectedEvent.calendarEvent) {
        const { groupEvent } = selectedEvent.calendarEvent;
        await updateGroupEvent(
          groupEvent.id!,
          eventTitle,
          newStartDate,
          newStartTime,
          newEndTime,
          selectedRecruiterWithProfileEmail,
          selectedCandidate,
        );
      } else {
        await createEvent(
          eventTitle,
          newStartDate,
          newStartTime,
          newEndTime,
          selectedRecruiterWithProfileEmail,
          selectedCandidate,
        );
      }
    }
  };

  useEffect(() => {
    const recruiterList = allRecruiters
      .filter((i) => i.id !== currentUserId)
      .filter((c) => c.email)
      .filter((p, i, rList) => {
        return rList.findIndex((r) => r.email === p.email) === i;
      })
      .map((r) => {
        return {
          label: `${r.firstName} ${r.lastName}`,
          value: r.email,
        } as CalendarParticipantType;
      });

    setRecruiterItems(recruiterList);
  }, [allRecruiters, currentUserId]);

  useEffect(() => {
    const event = getUndefinedOrEventType(calendarEvent);
    if (calendarEvent && event) {
      const { groupEvent } = event;

      setStartDate(moment(groupEvent.date).toDate());
      setNoteContent(groupEvent.description);
      setStartTime(moment(groupEvent.startTime).toDate());
      setEndTime(moment(groupEvent.endTime).toDate());

      setEventTitle(calendarEvent.title);
    }
  }, [calendarEvent]);

  useEffect(() => {
    if (!selectedEvent) return;
    setSelectedRecruiters(selectedEvent.recruiters);
    setSelectedCandidates(selectedEvent.candidates);
  }, [selectedEvent]);

  return (
    <Drawer
      isOpen={isOpen}
      initialFocusRef={firstField}
      placement="right"
      onClose={onClose}
      size="sm"
      data-testid="CreateEventDrawer"
    >
      <DrawerOverlay>
        <DrawerContent css={[drawerCSS, reactDatePickerCSS, calendarCreateEvent]}>
          <DrawerCloseButton />
          <DrawerHeader borderBottom="2px" borderColor="#EBEDF3" color={colors.blue.default}>
            {t('eventForm.groupEventTitle')}
          </DrawerHeader>
          <DrawerBody>
            <FormControl className="event-form-control" mb={5} isInvalid={hasErrorMessage(formErrors, 'title')} isRequired>
              <FormLabel mb={2}>{t('eventForm.eventTitle')}</FormLabel>
              <Input
                ref={firstField}
                placeholder={t('eventForm.titleHint')}
                value={eventTitle}
                onChange={/* istanbul ignore next */ (e) => handleEventTitle('title', e)}
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'title')}</FormErrorMessage>
            </FormControl>
            <FormControl
              mb={5}
              className="event-form-control date-picker-box"
              isInvalid={hasErrorMessage(formErrors, 'date')}
              isRequired
            >
              <FormLabel mb={2}>{t('eventForm.date')}</FormLabel>
              <Box className="date-input-group">
                <DatePicker
                  /* istanbul ignore next */
                  customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                  selected={startDate}
                  locale={i18n.language}
                  dateFormat="P"
                  minDate={moment().toDate()}
                  onChange={/* istanbul ignore next */ (date) => handleDateOfEvent('date', date as Date)}
                  className="customDatepicker"
                />
              </Box>
              <FormErrorMessage>{getErrorMessage(formErrors, 'date')}</FormErrorMessage>
            </FormControl>
            <Flex d="inline-flex" mb={5} align="top" className="time-picker-box">
              <FormControl className="event-form-control" isInvalid={hasErrorMessage(formErrors, 'startTime')} isRequired>
                <FormLabel mb={2}>{t('eventForm.startTime')}</FormLabel>
                <Box className="time-input-group">
                  <DatePicker
                    selected={startTime}
                    onChange={/* istanbul ignore next */ (date) => handleStartTimeOfEvent('startTime', date as Date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption={t('eventForm.timeCaption')}
                    dateFormat="p"
                  />
                  <ChevronDownIcon />
                </Box>
                <FormErrorMessage>{getErrorMessage(formErrors, 'startTime')}</FormErrorMessage>
              </FormControl>
              <FormControl className="event-form-control" ml={4} isInvalid={hasErrorMessage(formErrors, 'endTime')} isRequired>
                <FormLabel mb={2}>{t('eventForm.endTime')}</FormLabel>
                <Box className="time-input-group">
                  <DatePicker
                    selected={endTime}
                    minTime={moment(startTime).add(30, 'minutes').toDate()}
                    openToDate={startTime ? moment(startTime).toDate() : moment().toDate()}
                    maxTime={moment(startDate).endOf('day').toDate()}
                    onChange={/* istanbul ignore next */ (date) => handleEndTimeOfEvent('endTime', date as Date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption={t('eventForm.timeCaption')}
                    dateFormat="p"
                  />
                  <ChevronDownIcon />
                </Box>
                <FormErrorMessage>{getErrorMessage(formErrors, 'endTime')}</FormErrorMessage>
              </FormControl>
            </Flex>
            <FormControl mb={5} className="event-form-control">
              <FormLabel mb={2}>{t('eventForm.recruitersLabel')}</FormLabel>
              <Box className="auto-picker-box" data-testid="RecruiterAutoComplete">
                <Select
                  css={reactSelectStyles}
                  isMulti
                  classNamePrefix="react-select"
                  placeholder={`${t('eventForm.recruiterHint')}`}
                  isClearable={false}
                  value={selectedRecruiters}
                  onChange={handleOptionChange}
                  options={recruiterItems}
                  components={{ DropdownIndicator }}
                />
              </Box>
            </FormControl>
            <FormControl mb={5} className="event-form-control" isInvalid={hasErrorMessage(formErrors, 'candidates')} isRequired>
              <FormLabel mb={2}>{t('eventForm.candidatesLabel')}</FormLabel>
              <Box className="auto-picker-box candidate-auto-complete" data-testid="CandidateAutoComplete">
                <Box className="candidate-email-wrapper">
                  {selectedCandidates.map((c, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Tag size="sm" borderRadius="5px" variant="solid" color="#333" bg="gray.50" key={i} marginRight="5px">
                      <TagLabel>{c.value}</TagLabel>
                      <TagCloseButton onClick={/* istanbul ignore next */ (e) => removeSelectedCandidate(e, i)} />
                    </Tag>
                  ))}
                </Box>
                <IconAddUser className="icon-add-user" width={30} height={30} />
                <input
                  style={{ width: `${candidateInputWidth + 1}ch` }}
                  value={candidateEmails}
                  onChange={(e) => handleCandidateEmails('candidates', e.target.value)}
                  onBlur={() => handleCandidateOnBlur()}
                  placeholder={selectedCandidates.length ? '' : t('eventForm.emailHint')}
                  onKeyPress={/* istanbul ignore next */ (e) => handleCandidateOnEnter(e)}
                  onKeyDown={
                    /* istanbul ignore next */ (e) => {
                      if (e.key === 'Tab') {
                        e.preventDefault();
                        if (quillRef.current) quillRef.current.focus();
                      }
                    }
                  }
                  data-testid="candidate-input"
                />
              </Box>
              <FormErrorMessage>{`${getErrorMessage(formErrors, 'candidates')} , must be email`}</FormErrorMessage>
            </FormControl>
            <FormControl
              mb={10}
              className="event-form-control date-picker-box"
              isInvalid={hasErrorMessage(formErrors, 'description')}
              isRequired
            >
              <FormLabel mb={2}>{t('eventForm.eventDescriptionLabel')}</FormLabel>
              <Box className="description-block">
                <ReactQuill
                  data-testid="quill-editor"
                  placeholder={t('eventForm.detailHint')}
                  value={noteContent}
                  onChange={handleNoteContent}
                  ref={quillRef}
                />
              </Box>
              <FormErrorMessage>{getErrorMessage(formErrors, 'description')}</FormErrorMessage>
            </FormControl>
          </DrawerBody>
          <Flex justify="space-between" py={3} borderTop="2px" borderColor="#EBEDF3">
            <Button
              data-testid="SaveEventBtn"
              css={loginButtonStyle}
              variant="solid"
              borderRadius={3}
              ml={6}
              isLoading={isSaving}
              disabled={isSaving}
              onClick={() => submitEvent()}
            >
              {t('eventForm.saveEvent')}
            </Button>
            <Button variant="outline" borderRadius={3} mr={3} onClick={() => onClose()} data-testid="CancelDrawerBtn">
              {t('eventForm.cancel')}
            </Button>
          </Flex>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
