import { Collections } from '../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Configuration } from '../../firebase/firestore/documents/configuration';
import { Query } from '../../firebase/firestore/query/query';
import { BUNDLE_ID_APP_BASED, MAINTENANCE_NAME } from '../../firebase/firestore/constants';

export enum IsUnderMaintenanceEnum {
  MAINTENANCE,
  LOADING,
  READY,
}

const useConfiguration = (): IsUnderMaintenanceEnum => {
  const underMaintenance = useQuerySnapshot<Configuration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_APP_BASED),
    Query.field('name').equals(MAINTENANCE_NAME),
  ]);
  if (underMaintenance?.length === 0) {
    return IsUnderMaintenanceEnum.READY;
  }
  if (underMaintenance && underMaintenance.length) {
    return underMaintenance[0].value ? IsUnderMaintenanceEnum.MAINTENANCE : IsUnderMaintenanceEnum.READY;
  }
  return IsUnderMaintenanceEnum.LOADING;
};

export default useConfiguration;
