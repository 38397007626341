import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/ban-types
type PrivateRouteProps<RouteComponentProps = {}> = RouteProps & {
  userIsAuth: boolean;
  component: React.ComponentType<RouteComponentProps> | RouteComponentProps;
};

// eslint-disable-next-line react/prop-types
const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: RouteComponent, userIsAuth, ...rest }) => {
  return (
    <Route {...rest} render={(routeProps) => (userIsAuth ? <RouteComponent {...routeProps} /> : <Redirect to="/login" />)} />
  );
};

export default PrivateRoute;
