import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Stack, Spinner, Center } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from '../../../../models/hooks';
import StatWithProgressBarAndComparison from './statWithProgressBarAndComparison/StatWithProgressBarAndComparison';
import BarComparisonChartView, { BarDataType } from './dataVisualizationCharts/BarComparisonChartView';
import PieChartView, { PieDataType } from './dataVisualizationCharts/PieChartView';
import { BarGraphResponseType, PieAndStatInfoResponse, PieChartResponseType, StatDataType } from '../RecruiterType';
import { useRecruiterManagementContext } from '../RecruiterManagementContext';

export type RecruiterDashboardVisualisationViewProps = {
  accountId: string | undefined;
  onSetActiveTab: () => void;
};

export const defaultPieChartAndStatDataResponse: PieAndStatInfoResponse = {
  incompleteInterviews: {
    incompleteInterviews: 0,
    cancelledInterviews: 0,
  },
  scheduleAndInterview: {
    totalScheduled: 0,
    totalInterviewed: {
      value: 0,
      comparison: 0,
    },
  },
  interviewDetails: {
    candidateNoShow: 0,
    recruiterNoShow: {
      value: 0,
      comparison: 0,
    },
  },
  totalCandidates: {
    totalCandidates: 0,
    hiredCandidates: 0,
  },
  pieChart: {
    openPositions: 0,
    closedPositions: 0,
    archivedPositions: 0,
  },
  barGraph: {
    totalCandidates: {
      current: 0,
      previous: 0,
    },
    interviewCompleted: {
      current: 0,
      previous: 0,
    },
    recruiterNoShow: {
      current: 0,
      previous: 0,
    },
    candidateNoShow: {
      current: 0,
      previous: 0,
    },
  },
};

export const RecruiterDashboardVisualisationView = ({
  accountId,
  onSetActiveTab,
}: RecruiterDashboardVisualisationViewProps): JSX.Element => {
  const { t } = useTranslation();

  const { startDate, endDate } = useRecruiterManagementContext();

  const { getPieAndStatInfo } = useStoreActions((actions) => actions.recruiterManagement);

  const [data, setData] = useState<PieAndStatInfoResponse>(defaultPieChartAndStatDataResponse);
  const [pieChartData, setPieChartData] = useState<PieDataType>([]);
  const [statData, setStatData] = useState<StatDataType | undefined>();
  const [barChartData, setBarChartData] = useState<BarDataType>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [dashboardBarComparisonPercentage, setDashboardBarComparisonPercentage] = useState<number>(0);

  const givenEndDate = moment(endDate);
  const givenStartdate = moment(startDate);
  const selectedDateDifferenceInDays = givenEndDate.diff(givenStartdate, 'days');

  const setBarChartDataState = (barChartDataFromResponse: BarGraphResponseType) => {
    const { totalCandidates, interviewCompleted, recruiterNoShow, candidateNoShow } = barChartDataFromResponse;
    const currentChange = (interviewCompleted.current / totalCandidates.current) * 100 || 0;
    const previousChange = (interviewCompleted.previous / totalCandidates.previous) * 100 || 0;

    const barChartDataToSet: BarDataType = [
      {
        name: t('recruiterManagement:recruiterDataVisualisation.totalInterviews'),
        uv: totalCandidates.current,
        pv: totalCandidates.previous,
      },
      {
        name: t('recruiterManagement:recruiterDataVisualisation.successfulInterviews'),
        uv: interviewCompleted.current,
        pv: interviewCompleted.previous,
      },
      {
        name: t('recruiterManagement:recruiterTable.tableHeader.recruiterNoShow'),
        uv: recruiterNoShow.current,
        pv: recruiterNoShow.previous,
      },
      {
        name: t('recruiterManagement:recruiterTable.tableHeader.candidateNoShow'),
        uv: candidateNoShow.current,
        pv: candidateNoShow.previous,
      },
    ];
    setBarChartData(barChartDataToSet);
    setDashboardBarComparisonPercentage(currentChange - previousChange);
  };

  const setPieChartDataState = (pieChartDataFromResponse: PieChartResponseType) => {
    const { openPositions, closedPositions, archivedPositions } = pieChartDataFromResponse;
    const pieChartDataToSet: PieDataType = [
      { name: t('jobPosition:openedPositions'), value: openPositions },
      { name: t('jobPosition:closedPositions'), value: closedPositions },
      { name: t('jobPosition:archivedPositions'), value: archivedPositions },
    ];
    setPieChartData(pieChartDataToSet);
  };

  const setStatDataState = (dataFromRespponse: PieAndStatInfoResponse) => {
    const { incompleteInterviews, scheduleAndInterview, interviewDetails, totalCandidates } = dataFromRespponse;
    const statDataToSet: StatDataType = {
      incompleteInterviews: {
        statHeadingValue: {
          heading1: t('recruiterManagement:recruiterDataVisualisation.unsuccessfulInterviews'),
          heading1TooltipText: t('recruiterManagement:recruiterDashboardTooltipFor.incompletedInterviews'),
          value1: incompleteInterviews.incompleteInterviews || 0,
          heading2: t('recruiterManagement:recruiterDataVisualisation.interviewCancellation'),
          heading2TooltipText: t('recruiterManagement:recruiterDashboardTooltipFor.canceledInterviews', {
            daysDifference: selectedDateDifferenceInDays,
          }),
          value2: incompleteInterviews.cancelledInterviews || 0,
        },
      },
      scheduleAndInterview: {
        statHeadingValue: {
          heading1: t('recruiterManagement:recruiterDataVisualisation.total'),
          heading1TooltipText: t('recruiterManagement:recruiterDashboardTooltipFor.totalInterviews', {
            daysDifference: selectedDateDifferenceInDays,
          }),
          value1: scheduleAndInterview.totalScheduled || 0,
          heading2: t('recruiterManagement:recruiterDataVisualisation.successfulInterviews'),
          heading2TooltipText: t('recruiterManagement:recruiterDashboardTooltipFor.completedInterviews', {
            daysDifference: selectedDateDifferenceInDays,
          }),
          value2: scheduleAndInterview.totalInterviewed.value || 0,
          heading3: t('recruiterManagement:recruiterDataVisualisation.upcoming'),
          value3: scheduleAndInterview.upcomingInterviews || 0,
          heading3TooltipText: t('recruiterManagement:recruiterDashboardTooltipFor.upcomingInterviews'),
        },
        comparisonPercentage: scheduleAndInterview.totalInterviewed.comparison || 0,
      },
      interviewDetails: {
        statHeadingValue: {
          heading1: t('recruiterManagement:recruiterTable.tableHeader.candidateNoShow'),
          heading1TooltipText: t('recruiterManagement:recruiterDashboardTooltipFor.candidateNoShow'),
          value1: interviewDetails.candidateNoShow || 0,
          heading2: t('recruiterManagement:recruiterTable.tableHeader.recruiterNoShow'),
          heading2TooltipText: t('recruiterManagement:recruiterDashboardTooltipFor.recruiterNoShow'),
          value2: interviewDetails.recruiterNoShow.value || 0,
        },
        comparisonPercentage: interviewDetails.recruiterNoShow.comparison || 0,
      },
      totalCandidates: {
        statHeadingValue: {
          heading1: t('recruiterManagement:recruiterDataVisualisation.totalCandidates'),
          value1: totalCandidates.totalCandidates || 0,
          heading1TooltipText: t('recruiterManagement:recruiterDashboardTooltipFor.activeCandidatesInLast30Days'),
          heading2: t('candidateStatus:HIRED'),
          heading2TooltipText: t('recruiterManagement:recruiterDashboardTooltipFor.hired', {
            daysDifference: selectedDateDifferenceInDays,
          }),
          value2: totalCandidates.hiredCandidates || 0,
        },
      },
    };
    setStatData(statDataToSet);
  };

  const onGetPieAndStatInfo: (userAccountId: string) => void = useCallback(
    async (userAccountId) => {
      setIsDataLoading(true);
      const responseData: PieAndStatInfoResponse = await getPieAndStatInfo({
        params: { accountId: userAccountId, startDate, endDate },
      });
      /* istanbul ignore else */
      if (responseData) {
        const { pieChart, barGraph } = responseData;
        setPieChartDataState(pieChart);
        setBarChartDataState(barGraph);
        setStatDataState(responseData);
        setData(responseData);
      }
      setIsDataLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPieAndStatInfo, startDate, endDate],
  );

  useEffect(() => {
    /* istanbul ignore else */
    if (accountId) onGetPieAndStatInfo(accountId);
  }, [accountId, onGetPieAndStatInfo]);

  useEffect(() => {
    /* istanbul ignore else */
    if (!isDataLoading) {
      const { pieChart, barGraph } = data;
      setBarChartDataState(barGraph);
      setPieChartDataState(pieChart);
      setStatDataState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return (
    <Stack direction="column">
      {!isDataLoading ? (
        <React.Fragment>
          <Stack direction="row" data-testid="chartsAndInfoView" spacing={4} mb={6} minH="60vh">
            <Box backgroundColor="white" borderRadius={5} w="52%" py={7}>
              <BarComparisonChartView
                data={barChartData}
                givenComparisonPercentage={dashboardBarComparisonPercentage}
                renderedBy="DASHBOARD"
              />
            </Box>
            <Box w="48%">
              {statData && (
                <Stack
                  borderRadius={5}
                  w="100%"
                  h="60%"
                  direction="row"
                  style={{ flexFlow: 'wrap', justifyContent: 'space-between' }}
                >
                  <Box backgroundColor="white" py={0} pl={7} pr={4} w="48%" height="140px" position="relative">
                    <StatWithProgressBarAndComparison
                      showComparison
                      comparisonPercentage={statData.scheduleAndInterview.comparisonPercentage}
                      headingValues={statData.scheduleAndInterview.statHeadingValue}
                      divideHeading1And3AsSubHeading={{
                        isHeadingDivided: true,
                        subHeadingTitle: t('recruiterManagement:recruiterDataVisualisation.appointments'),
                      }}
                    />
                  </Box>
                  <Box backgroundColor="white" py={0} px={7} w="48%" height="140px">
                    <StatWithProgressBarAndComparison
                      showComparison
                      statHeadingValueStyle={{ flexDirection: 'column-reverse' }}
                      headingValues={statData.incompleteInterviews.statHeadingValue}
                    />
                  </Box>

                  <Box backgroundColor="white" py={0} px={7} w="48%" height="140px" style={{ marginLeft: '0' }}>
                    <StatWithProgressBarAndComparison
                      statHeadingValueStyle={{ flexDirection: 'column-reverse' }}
                      showComparison
                      headingValues={statData.totalCandidates.statHeadingValue}
                    />
                  </Box>

                  <Box
                    backgroundColor="white"
                    py={0}
                    px={7}
                    w="48%"
                    height="140px"
                    overflow="hidden"
                    position="relative"
                    style={{ marginLeft: '0' }}
                  >
                    <StatWithProgressBarAndComparison
                      showComparison
                      comparisonPercentage={statData.interviewDetails.comparisonPercentage}
                      headingValues={statData.interviewDetails.statHeadingValue}
                    />
                  </Box>
                </Stack>
              )}
              {pieChartData.length > 0 && (
                <Box backgroundColor="white" h="40%" pr={3.5} pt={5} pb={6}>
                  <PieChartView data={pieChartData} onSetActiveTab={onSetActiveTab} />
                </Box>
              )}
            </Box>
          </Stack>
        </React.Fragment>
      ) : (
        <Center style={{ display: 'flex', flexDirection: 'column' }} p={10} background="white" mb={8}>
          <Spinner color="blue.500" size="lg" mb="6" />
          <Box>{t('recruiterManagement:recruiterDataVisualisation.visualisationLoadingMessage')}</Box>
        </Center>
      )}
    </Stack>
  );
};
