// istanbul ignore file
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import { Box, Container } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router';
import { Header } from '../../../app/topBar/Header';
import { ANIMATED_VARIANTS } from '../../../routes/constants';
import { CreateBusinessView } from '../../administration/positionManagement/createBusiness/CreateBusinessView';
import { Business } from '../../administration/positionManagement/BusinessType';

const MotionBox = motion.custom(Box);

const defaultBusinessData: Business = {
  id: '0',
  name: '',
  locationId: '',
  cityName: '',
  address: '',
  country: 'Canada',
  latitude: 0,
  longitude: 0,
  zip: '',
  state: '',
  phoneNumber: '',
  webSite: '',
  completeAddress: '',
  placeId: '',
  positions: 0,
};

export const SmbBusinessManagementView = (): JSX.Element => {
  const history = useHistory();

  const onSuccessCallBack = () => {
    history.push(`/administration/positionManagement`);
  };
  const onCloseCallBack = () => {
    history.push(`/administration/positionManagement`);
  };

  return (
    <MotionBox data-testid="SMBBusinessMgmtView" initial="initial" animate="in" exit="out" variants={ANIMATED_VARIANTS}>
      <Header data-testid="Header" showMenu={false} />
      <Box py={8} backgroundColor="#F3F3F3" height="calc(100vh - 64px)" overflowY="auto">
        <Container maxW="1200px" position="relative">
          <CreateBusinessView
            successCallBack={onSuccessCallBack}
            closeCallBack={onCloseCallBack}
            businessData={defaultBusinessData}
            createNewBusiness
          />
        </Container>
      </Box>
    </MotionBox>
  );
};
