import React, { Fragment } from 'react';
import { MenuButton, Portal, MenuList, MenuItem, Menu, Button, Spinner, useDisclosure } from '@chakra-ui/core';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import _sortBy from 'lodash/sortBy';
import useRequisitionsByPosition from './useRequisitionsByPosition';
import colors from '../../../../../../styles/colors';
import { ViewRequisitionDetail } from '../../history/ViewRequisitionDetail';
import { Requisition } from '../../../../../../firebase/firestore/documents/requisition';
import usePosition from '../../../../sidebar/seekerList/usePosition';
import { isRequisitionAvailable } from '../../../../../../utils/RequisitionEffectiveComparisionWithThresholdValue';
import { useStoreState } from '../../../../../../models/hooks';

type CandidatePositionRequisitionsProps = {
  positionId: string;
};

const CandidatePositionRequisitions = ({ positionId }: CandidatePositionRequisitionsProps): JSX.Element => {
  const { t } = useTranslation();
  const [requisition, setRequisition] = React.useState<Requisition | undefined>(undefined);
  const requisitionActiveThresholdDaysAccount = useStoreState(
    (state) => state.app.accounts?.configuration?.requisitionActiveThresholdDays,
  );
  const { isOpen: isOpenRequisition, onOpen: onOpenRequisition, onClose: onCloseRequisition } = useDisclosure();
  const positionRequisitions = useRequisitionsByPosition(positionId, ['OPEN']);

  const position = usePosition(positionId);

  if (!positionRequisitions) {
    return <Spinner size="xs" color="blue.500" />;
  }
  if (positionRequisitions.length <= 0) {
    return (
      <Button mt={1} colorScheme="gray" variant="outline" size="xs" disabled>
        {t('profile.noRequisition')}
      </Button>
    );
  }

  return (
    <Fragment>
      <Menu>
        <MenuButton mt={1} as={Button} colorScheme="gray" variant="outline" size="xs" rightIcon={<RiArrowDownSLine />}>
          {t('profile.openRequisitions')}
        </MenuButton>
        <Portal>
          <MenuList border={`1px solid ${colors.gray['50']}`} maxHeight="200px" overflowY="auto">
            {_sortBy(positionRequisitions, ['effectiveDate'], ['asc'])?.map((req, i) => (
              <MenuItem
                fontSize="sm"
                borderBottom={positionRequisitions?.length > i + 1 ? `1px solid ${colors.gray['50']}` : 0}
                key={req.requisitionId}
                data-testid={`ReqMenuBtn-${req.requisitionId}`}
                onClick={() => {
                  setRequisition(req);
                  onOpenRequisition();
                }}
                color={
                  isRequisitionAvailable(
                    req.effectiveDate,
                    requisitionActiveThresholdDaysAccount,
                    position?.requisitionActiveThresholdDays,
                  )
                    ? colors.black
                    : /* istanbul ignore next */ colors.gray[400]
                }
              >
                {req.requisitionId}
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
      {isOpenRequisition && (
        <ViewRequisitionDetail
          isOpen={isOpenRequisition}
          onClose={onCloseRequisition}
          requisition={requisition}
          shouldCompare={false}
        />
      )}
    </Fragment>
  );
};

export default CandidatePositionRequisitions;
