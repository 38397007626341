interface Data {
  id?: string;
  code?: string;
  positionId?: string;
  candidateId?: string;
  additionalContextData?: string;
}

interface Notif {
  title: string;
  body: string;
  click_action: string;
}

export interface Payload {
  data: Data;
  notification: Notif;
}

// istanbul ignore next
export const showPushNotification = (notfPayload: Notif) => {
  const notification = new Notification(notfPayload.title, {
    body: notfPayload.body,
    icon: '/favicon.ico',
  });

  notification.onclick = (e: Event) => {
    e.preventDefault();
    window.open(notfPayload.click_action, '_self');
    notification.close();
  };
};

// Not able to mock pathname
// istanbul ignore next
export const pushNotification = function (payload: Payload): void {
  const pathString = window.location.pathname.split('/');
  const splitCandidateId = pathString[2];

  if (!splitCandidateId) return;
  if (window.location.pathname.includes('messaging') && splitCandidateId === payload.data.candidateId) return;
  if (!('Notification' in window)) return;
  showPushNotification(payload.notification);
};
