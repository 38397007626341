import { Collections } from '../../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../../firebase/firestore/hooks';
import { Query } from '../../../firebase/firestore/query/query';
import { BUNDLE_ID_GLOBAL, MINUTES_BEFORE_GROUP_EVENT_WHEN_USER_CAN_JOIN } from '../../../firebase/firestore/constants';
import { GroupEventConfiguration } from '../../../firebase/firestore/documents/groupEvent';

export const useMinutesBeforeGroupEventWhenUsersCanJoin = () => {
  const minutesBeforeGroupeventWhenUsersCanJoin = useQuerySnapshot<GroupEventConfiguration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_GLOBAL),
    Query.field('name').equals(MINUTES_BEFORE_GROUP_EVENT_WHEN_USER_CAN_JOIN),
  ]);
  if (minutesBeforeGroupeventWhenUsersCanJoin) {
    return minutesBeforeGroupeventWhenUsersCanJoin[0]?.value;
  }
  return undefined;
};
