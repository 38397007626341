/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { getTranslatedTitle, ParentItemNode } from '../treeGroup';

export type ParentItemProps = {
  item: ParentItemNode;
  isFilterApplied: boolean | undefined;
};

export const ParentItem = ({ item, isFilterApplied }: ParentItemProps): JSX.Element => {
  const { i18n } = useTranslation();

  const getChildCount = (): string => {
    if (isFilterApplied) {
      return '';
    }
    if (!item.childCount) return '';
    return `(${item.childCount})`;
  };

  return (
    <Box className="inner-parent-block" data-testid="ParentItem">
      {`${getTranslatedTitle(item, i18n.language)} ${getChildCount()}`}
    </Box>
  );
};
