import _isNil from 'lodash/isNil';

import { EngagedCandidate } from '../../firebase/firestore/documents/candidate';
import { Collections } from '../../firebase/firestore/collections';
import { useDocumentSnapshot } from '../../firebase/firestore/hooks';

const useCandidate = (candidateId: string): EngagedCandidate | undefined => {
  const candidateData = useDocumentSnapshot<EngagedCandidate>(Collections.CANDIDATES, candidateId);

  if (_isNil(candidateData)) return undefined;

  return candidateData;
};

export default useCandidate;
