/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { confirmationModalHeaderCSS } from './ConfirmationModal.styles';
import { ReactComponent as _IconEmailSend } from '../../../../../assets/img/icon-email.svg';

const IconEmailSend = memo(_IconEmailSend);

export type SubscribeForBackgroundCheckModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SubscribeForBackgroundCheckModal = ({ isOpen, onClose }: SubscribeForBackgroundCheckModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal id="DismissConfirmationModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay>
        <ModalContent p={5} py={6} px={2} borderRadius="30px">
          <ModalHeader css={confirmationModalHeaderCSS} className="modal-header">
            <Flex direction="column" alignItems="center" textAlign="center">
              <IconEmailSend />
              <Text mt={6}>{t('messaging:actions.backgroundCheck.confirmationModal.header')}</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton mr={4} />
          <ModalBody textAlign="center">{t('messaging:actions.backgroundCheck.confirmationModal.body')}</ModalBody>
          <ModalFooter justifyContent="center">
            <Button data-testid="CancelButton" colorScheme="blue" mr={3} onClick={onClose}>
              {t('messaging:actions.backgroundCheck.confirmationModal.sendButton')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
