import { useEffect, useState } from 'react';

export type ActiveDeviceInfo = {
  audioInputDevices: MediaDeviceInfo[];
  videoInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  hasAudioInputDevices: boolean;
  hasVideoInputDevices: boolean;
};

export const useDevices = (): ActiveDeviceInfo => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    const getNewDevices = () => {
      navigator.mediaDevices
        .enumerateDevices()
        .then((d) => {
          setDevices(d);
        })
        .finally(() => {});
    };

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    navigator.mediaDevices.addEventListener('devicechange', getNewDevices);

    getNewDevices();

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      navigator.mediaDevices.removeEventListener('devicechange', getNewDevices);
    };
  }, []);

  return {
    audioInputDevices: devices.filter((device: MediaDeviceInfo) => device.kind === 'audioinput'),
    videoInputDevices: devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter((device: MediaDeviceInfo) => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.filter((device: MediaDeviceInfo) => device.kind === 'audioinput').length > 0,
    hasVideoInputDevices: devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput').length > 0,
  };
};
