import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../http/models/httpModel';
import { AccountLevelConfigurationController } from '../controllers/accountLevelConfigurationController';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';

export type AccountLevelConfigurationStoreModel = {
  accountLevelConfiguration: AccountLevelConfigurationModel;
};

export interface AccountLevelConfigurationModel {
  addUnresponsivePeriod: Thunk<
    AccountLevelConfigurationModel,
    { unresponsiveDismissPeriod: number; unresponsiveAgedPeriod: number; accountId: string },
    void,
    HttpStoreModel,
    Promise<boolean>
  >;
}

export const accountLevelConfigurationStoreModel: AccountLevelConfigurationModel = {
  addUnresponsivePeriod: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new AccountLevelConfigurationController(getStoreState().http.client as HttpClientWithRetry);
    await controller.addUnresponsivePeriod(payload.unresponsiveDismissPeriod, payload.unresponsiveAgedPeriod, payload.accountId);
    return true;
  }),
};
