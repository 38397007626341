import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  Textarea,
  VStack,
  Popover,
  PopoverContent,
  PopoverCloseButton,
  PopoverArrow,
  PopoverFooter,
  ButtonGroup,
  useDisclosure,
  PopoverTrigger,
  PopoverBody,
  Heading,
} from '@chakra-ui/core';
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { GrClose } from 'react-icons/gr';
import { AddIcon } from '@chakra-ui/icons';
import colors from '../../../../styles/colors';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
} from '../../../../utils/FormErrorUtils';
import { JobPosition, Question } from '../PositionType';
import { useStoreState } from '../../../../models/hooks';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';

const initialQuestion = {
  text: '',
  preferredAnswer: true,
  knockout: false,
};

export type SpecificQuestionsProps = {
  jobPositionData: JobPosition;
  deleteQuestion: (questionId: number) => void;
  addQuestion: (question: Question, questionEditMode: number | undefined) => void;
  showQuestionForm: boolean;
  questionToEdit: Question;
  questionToEditMode: number | undefined;
  onRefreshState: () => void;
};

export const SpecificQuestionsCard = ({
  jobPositionData,
  deleteQuestion,
  addQuestion,
  showQuestionForm,
  questionToEdit,
  questionToEditMode,
  onRefreshState,
}: SpecificQuestionsProps): JSX.Element => {
  const [question, setQuestion] = useState<Question>(initialQuestion);
  const [queFormErrors, setQueFormErrors] = useState<FormattedError[]>([]);
  const [questionEditMode, setQuestionEditMode] = useState<number | undefined>(undefined);
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [showForm, setShowForm] = useState<boolean>(showQuestionForm);
  const enableMultipleChoiceFeature: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE];
  });
  const textAreaRef = useRef() as React.MutableRefObject<HTMLTextAreaElement>;

  const questionSchema = Joi.object().keys({
    text: Joi.string()
      .trim(true)
      .max(500)
      .required()
      .messages({
        'string.max': t('createJobPosition:questionMaxLetterRequired', { count: 500 }),
      }),
    preferredAnswer: Joi.boolean().optional(),
    knockout: Joi.boolean().optional(),
    id: Joi.string().optional(),
    isActive: Joi.boolean().optional(),
  });

  const queFormValidation = (formData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = questionSchema.validate(formData);

    if (error) {
      setQueFormErrors(errorFormat(error.details));
      return false;
    }

    setQueFormErrors([]);
    return true;
  };

  const handleQuestionChange = (value: string | Date | boolean | ChangeEvent<HTMLInputElement>, fieldName: string) => {
    setQuestion((prevData) => ({
      ...prevData,
      [fieldName]: fieldName === 'preferredAnswer' ? value === 'true' : value,
    }));
    setQueFormErrors(clearErrorMessage(queFormErrors, fieldName));
  };

  // eslint-disable-next-line consistent-return
  const createQuestion = () => {
    const validate = queFormValidation(question);
    if (!validate) {
      return false;
    }
    addQuestion(question, questionEditMode);
    setQuestion(initialQuestion);
    setQuestionEditMode(undefined);
    if (!showQuestionForm) setShowForm(false);
    onRefreshState();
  };

  const editQuestion = (questionId: number | undefined, ques: Question) => {
    setQuestion(ques);
    setQuestionEditMode(questionId);
  };

  useEffect(() => {
    setQuestion(questionToEdit);
    setQuestionEditMode(questionToEditMode);
  }, [questionToEdit, questionToEditMode, setQuestion, setQuestionEditMode]);

  return (
    <Box backgroundColor="white" borderTopRadius={5}>
      {!enableMultipleChoiceFeature && (
        <Flex alignItems="center" justifyContent="space-between">
          <Box px={6} py={4} w="80%" paddingLeft={showQuestionForm ? '1.5rem' : 0}>
            <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
              {t('createJobPosition:questionCardHeader')}
            </Heading>
            <Text fontSize="xs" color="gray.500">
              {t('createJobPosition:questionCardHint')}
            </Text>
          </Box>
          {!showQuestionForm && (
            <Box w="20%" textAlign="right">
              <IconButton
                aria-label="Add to friends"
                icon={
                  showForm ? <GrClose style={{ color: colors.blue[500] }} /> : <AddIcon style={{ color: colors.blue[500] }} />
                }
                onClick={() => setShowForm(!showForm)}
                data-testid="addSpecificQuestionCard"
              />
            </Box>
          )}
        </Flex>
      )}
      {(showForm || enableMultipleChoiceFeature) && (
        <Box>
          <Divider borderColor="#ECEFF1" />
          <Box p={6}>
            <Box mb={6}>
              <FormControl isInvalid={hasErrorMessage(queFormErrors, 'text')}>
                <FormLabel htmlFor="question">{t('createJobPosition:questionLabel')}</FormLabel>
                <Textarea
                  variant="filled"
                  ref={textAreaRef}
                  id="question"
                  placeholder={t('createJobPosition:questionInputPlaceholder')}
                  value={question.text}
                  data-testid="QuestionField"
                  onChange={(e) => handleQuestionChange(e.target.value, 'text')}
                />
                <FormErrorMessage>{getErrorMessage(queFormErrors, 'text')}</FormErrorMessage>
              </FormControl>
            </Box>

            <Flex mb={6}>
              <Box w="50%">
                <FormControl>
                  <FormLabel htmlFor="expectedAnswer">{t('createJobPosition:questionExpectedAnsLabel')}</FormLabel>
                  <RadioGroup value={question.preferredAnswer ? 'true' : 'false'}>
                    <Stack spacing={4} direction="row">
                      {['true', 'false'].map((preferredAns) => {
                        return (
                          <Radio
                            key={preferredAns}
                            value={preferredAns}
                            data-testid={`PreferredAns-${preferredAns}`}
                            onChange={(e) => handleQuestionChange(e.target.value, 'preferredAnswer')}
                          >
                            {preferredAns === 'true'
                              ? t('common:questions.answerPositive')
                              : t('common:questions.answerNegative')}
                          </Radio>
                        );
                      })}
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box w="50%">
                <FormControl>
                  <FormLabel htmlFor="autoDismiss" style={{ pointerEvents: 'none' }}>
                    {t('createJobPosition:questionAutoDismissLabel')}
                  </FormLabel>
                  <Switch
                    id="autoDismiss"
                    isChecked={question.knockout}
                    data-testid="Knockout-question"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleQuestionChange(e.target.checked, 'knockout')}
                  />
                </FormControl>
              </Box>
            </Flex>
            <Flex justifyContent="end">
              <Button
                size="sm"
                colorScheme="blue"
                disabled={question.text.length < 9}
                data-testid="addQuestionBtn"
                onClick={() => createQuestion()}
              >
                {t('createJobPosition:confirmBtn')}
              </Button>
            </Flex>
          </Box>
        </Box>
      )}

      {!enableMultipleChoiceFeature && (
        <Box p={6} paddingLeft={showQuestionForm ? '1.5rem' : 0} paddingRight={showQuestionForm ? '1.5rem' : 0}>
          <Divider mt="12px" mb="12px" />
          <VStack spacing={4} align="stretch">
            {jobPositionData.specificQuestions.length > 0 &&
              jobPositionData.specificQuestions.map((que, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Flex key={index} alignItems="center" justifyContent="space-between">
                  <Text>
                    <Text as="span" mr={1} fontWeight={que.knockout ? 'bold' : 'normal'}>{`${que.text}`}</Text>
                    <Text as="span" fontSize="xs" fontStyle="italic">
                      {`(${que.preferredAnswer ? t('common:questions.answerPositive') : t('common:questions.answerNegative')})`}
                    </Text>
                  </Text>
                  <Flex>
                    <IconButton
                      backgroundColor="#ECEFF1"
                      aria-label="Edit Question"
                      icon={<RiEdit2Fill style={{ color: colors.blue[500] }} />}
                      data-testid={`EditQuestionBtn-${index}`}
                      onClick={() => {
                        setShowForm(true);
                        /* istanbul ignore else */
                        if (showQuestionForm) textAreaRef.current.focus();
                        editQuestion(index, que);
                      }}
                    />
                    <Box>
                      <Popover
                        returnFocusOnClose={false}
                        isOpen={isOpen && deleteIndex === index}
                        onClose={onClose}
                        closeOnBlur
                        isLazy
                      >
                        <PopoverTrigger>
                          <IconButton
                            backgroundColor="#ECEFF1"
                            ml={3}
                            aria-label="Delete Question"
                            icon={<RiDeleteBin5Fill style={{ color: colors.blue[500] }} />}
                            data-testid={`DeleteQuestionBtn-${index}`}
                            onClick={() => {
                              onOpen();
                              if (index === questionEditMode) setQuestionEditMode(undefined);
                              if (questionEditMode && index < questionEditMode) setQuestionEditMode(questionEditMode - 1);
                              setDeleteIndex(index);
                            }}
                          />
                        </PopoverTrigger>
                        {isOpen && (
                          <PopoverContent rootProps={{ style: { right: 0 } }}>
                            <PopoverBody fontWeight="semibold">{t('createJobPosition:deleteQuestionConfirmation')}</PopoverBody>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverFooter d="flex" justifyContent="flex-end">
                              <ButtonGroup size="sm">
                                <Button variant="outline" onClick={onClose}>
                                  {t('common:questions.answerNegative')}
                                </Button>
                                <Button
                                  colorScheme="red"
                                  onClick={() => {
                                    setDeleteIndex(null);
                                    deleteQuestion(index);
                                    onClose();
                                  }}
                                  data-testid={`deleteQuestionConfirmBtn-${index}`}
                                >
                                  {t('common:questions.answerPositive')}
                                </Button>
                              </ButtonGroup>
                            </PopoverFooter>
                          </PopoverContent>
                        )}
                      </Popover>
                    </Box>
                  </Flex>
                </Flex>
              ))}
          </VStack>
        </Box>
      )}
    </Box>
  );
};
