/* istanbul ignore file */
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useToast,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
  updateObject,
} from '../../../utils/FormErrorUtils';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { AccountType } from '../../../firebase/firestore/documents/accounts';

export type TeamCreateModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const TeamCreateModal = ({ isOpen, onClose }: TeamCreateModalProps): JSX.Element => {
  const { t } = useTranslation('team');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teamInfo, setTeamInfo] = useState({
    name: '',
  });
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);

  const { createTeam, updateTeam } = useStoreActions((actions) => actions.profileManagement);
  const { user, accounts } = useStoreState((state) => state.app);
  const toast = useToast();
  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      name: Joi.string()
        .max(30)
        .required()
        .messages({
          'string.empty': t('createTeamModal.validationMessage.name'),
        }),
    });

  const formValidation = (formData: { name: string }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = schema.validate(formData);

    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const handleChange = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    updateObject(teamInfo, fieldName, event.target.value);

    setFormErrors(clearErrorMessage(formErrors, fieldName));
    setTeamInfo({ ...teamInfo });
  };

  const saveTeamName = async () => {
    if (formValidation(teamInfo) && user) {
      setIsLoading(true);
      const payload = { accountId: user.account, name: teamInfo.name };
      if (accounts?.type === AccountType.PERSONAL) {
        await createTeam(payload);
      } else {
        await updateTeam(payload);
      }
      setIsLoading(false);
      toast({
        title:
          accounts?.type === AccountType.PERSONAL
            ? t('createTeamModal.successMessage.create')
            : t('createTeamModal.successMessage.update'),
        duration: 2000,
      });
      onClose();
    }
  };

  useEffect(() => {
    setTeamInfo((prevState) => ({
      ...prevState,
      name: accounts?.name,
    }));
  }, [accounts]);

  return (
    <Modal id="TeamCreateModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="md">
      <ModalOverlay>
        <ModalContent pt={4} px={3} pb={8}>
          <ModalBody>
            <FormControl isInvalid={hasErrorMessage(formErrors, 'name')}>
              <FormLabel fontWeight="bold">
                {accounts?.type === AccountType.PERSONAL ? t('createTeamModal.createLabel') : t('createTeamModal.editLabel')}
              </FormLabel>
              <Input
                variant="filled"
                id="firstName"
                type="text"
                size="lg"
                bg="gray.150"
                _hover={{ bg: 'gray.150' }}
                value={teamInfo.name}
                onChange={(e) => handleChange('name', e)}
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'name')}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={onClose} disabled={isLoading}>
              {t('createTeamModal.cancelBtn')}
            </Button>
            <Button data-testid="CreateButton" colorScheme="blue" isLoading={isLoading} onClick={() => saveTeamName()}>
              {accounts?.type === AccountType.PERSONAL ? t('createTeamModal.saveBtn') : t('createTeamModal.updateBtn')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
