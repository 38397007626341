import React, { Fragment } from 'react';
import {
  Modal,
  Button,
  useDisclosure,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Text,
  useToast,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from '../../models/hooks';
import { AddVacancyModal } from './AddVacancyModal';
import { Payload } from '../helper/foregroundNotifcation';
import { NotificationTypes } from '../../firebase/firestore/documents/notificationTypes';
import { useCurrentUserProfile } from '../hooks/useCurrentUserProfile';
import { NotificationDataType } from '../../models/notificationsModel';

export type VacancyStatusReminderModalProps = {
  notificationPayload: Payload | undefined;
};

export const VacancyStatusReminderModal = ({ notificationPayload }: VacancyStatusReminderModalProps): JSX.Element => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { t } = useTranslation('notification');

  const { currentUserProfile } = useCurrentUserProfile();

  const { updatePositionStatus } = useStoreActions((actions) => actions.positionManagement);
  const { updateNotificationStatus } = useStoreActions((actions) => actions.user);

  const clearPositionOpeningsFilled = useStoreActions((state) => state.notifications.clearPositionOpeningsFilled);
  const clearPositionClosingReminder = useStoreActions((state) => state.notifications.clearPositionClosingReminder);
  const clearPositionClosed = useStoreActions((state) => state.notifications.clearPositionClosed);

  const [isLoading, setIsLoading] = React.useState(false);
  const [vacancyModalOpen, setVacancyModalOpen] = React.useState(false);

  const toast = useToast();

  React.useEffect(() => {
    updateNotificationStatus({
      userId: currentUserProfile?.id as string,
      notificationId: notificationPayload?.data?.id as string,
    });
  }, [notificationPayload, currentUserProfile, updateNotificationStatus]);

  const openVacancyModalHandler = () => {
    onClose();
    setVacancyModalOpen(true);
  };

  const clearAndClose = () => {
    if (notificationPayload?.data.code === NotificationTypes.POSITION_CLOSED) {
      clearPositionClosed();
    } else if (notificationPayload?.data.code === NotificationTypes.POSITINON_CLOSING_REMINDER) {
      clearPositionClosingReminder();
    } else {
      clearPositionOpeningsFilled();
    }
    onClose();
  };

  const closePosition = async () => {
    try {
      setIsLoading(true);
      // istanbul ignore else
      if (notificationPayload?.data?.additionalContextData) {
        await updatePositionStatus({
          accountId: (JSON.parse(notificationPayload?.data?.additionalContextData) as NotificationDataType).accountId ?? '',
          businessId: (JSON.parse(notificationPayload?.data?.additionalContextData) as NotificationDataType).businessId ?? '',
          positionId: notificationPayload?.data?.positionId as string,
          positionStatus: {
            open: false,
          },
        });
      }
      clearAndClose();
    } finally {
      setIsLoading(false);
      toast({
        title: t('vacancyStatusReminderModal.actionSuccessful'),
        description: t('vacancyStatusReminderModal.actionSuccessfulDescription'),
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const vacancyStatusActionHandler = () => {
    let vacancyStatusActions = (
      <ModalContent data-testid="PositionClosingRemainder">
        <ModalHeader>{notificationPayload?.notification.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{notificationPayload?.notification.body}</Text>
        </ModalBody>
        <ModalFooter mt={2}>
          <Button data-testid="AddVacancyConfirmBtn" onClick={openVacancyModalHandler} colorScheme="blue">
            {t('vacancyStatusReminderModal.addVacancy')}
          </Button>
          <Button data-testid="CloseVacancyConfirmBtn" disabled={isLoading} colorScheme="red" onClick={closePosition} ml={1}>
            {t('vacancyStatusReminderModal.closePosition')}
          </Button>
        </ModalFooter>
      </ModalContent>
    );
    if (notificationPayload?.data.code === NotificationTypes.POSITION_CLOSED) {
      vacancyStatusActions = (
        <ModalContent data-testid="PositionClosedRemainder">
          <ModalHeader>{notificationPayload?.notification.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{notificationPayload?.notification.body}</Text>
          </ModalBody>
          <ModalFooter mt={2}>
            <Button data-testid="CloseVacancyClosedModalBtn" disabled={isLoading} colorScheme="red" onClick={clearAndClose}>
              {t('vacancyStatusReminderModal.okay')}
            </Button>
          </ModalFooter>
        </ModalContent>
      );
    }
    return vacancyStatusActions;
  };

  return (
    <Fragment>
      {vacancyModalOpen && <AddVacancyModal data-testid="AddVacancyModal" notificationPayload={notificationPayload} />}
      <Modal isOpen={isOpen} onClose={clearAndClose} isCentered>
        <ModalOverlay />
        {vacancyStatusActionHandler()}
      </Modal>
    </Fragment>
  );
};
