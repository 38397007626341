import firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useEffect, useMemo } from 'react';
import Bugsnag from '@bugsnag/js';
import { formatDocument } from '../documentConverter';
import { Collections } from '../collections';
import { useUserIsAuthenticated } from '../../../app/hooks/useUserIsAuthenticated';
import { Criteria } from '../query/criteria';

const getFirestoreQuery = (
  userIsAuthenticated: boolean,
  requireAuthentication: boolean,
  collection: Collections,
  queryObjects: Criteria[] | null,
): firebase.firestore.Query | undefined => {
  if (!queryObjects) {
    return undefined;
  }
  if (requireAuthentication && !userIsAuthenticated) {
    return undefined;
  }
  let query: firebase.firestore.Query = firebase.firestore().collection(collection);
  queryObjects.forEach((queryObject: Criteria) => {
    if (Criteria.isWhereCriteria(queryObject)) {
      query = query.where(queryObject.getKey(), queryObject.getOperation(), queryObject.getValue());
    }
    if (Criteria.isLimitCriteria(queryObject)) {
      query = query.limit(queryObject.getLimit());
    }
    if (Criteria.isOrderCriteria(queryObject)) {
      query = query.orderBy(queryObject.getFieldPath(), queryObject.getDirection());
    }
  });
  return query;
};

export const useQuerySnapshot = <T>(
  collection: Collections,
  queryObjects: Criteria[] | null,
  requireAuthentication = true,
): T[] | undefined => {
  const userIsAuthenticated = useUserIsAuthenticated();

  const [querySnapshot, loading, error] = useCollection(
    getFirestoreQuery(userIsAuthenticated, requireAuthentication, collection, queryObjects),
    { snapshotListenOptions: { includeMetadataChanges: true } },
  );

  useEffect(() => {
    if (error) {
      Bugsnag.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = `Firestore useQuerySnapshot Error ${collection}`;
        // eslint-disable-next-line no-param-reassign
        event.groupingHash = `useQuerySnapshot.${collection}`;
        event.addMetadata('query', { collection });
      });
    }
  }, [error, collection]);

  return useMemo(() => {
    // Snapshot trigger with partial results and then with all results. This make the UI flicker.
    // According to the following post, once everything is received, metadata.fromCache is false.
    // To ensure this behaviour includeMetadataChanges is also set to true in the query.
    // https://github.com/firebase/firebase-js-sdk/issues/3053#issuecomment-628302623
    if (querySnapshot && !loading && !error && !querySnapshot?.metadata.fromCache) {
      return formatDocument<T>(querySnapshot);
    }

    return undefined;
  }, [querySnapshot, loading, error]);
};
