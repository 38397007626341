import { Experience, Seeker } from '../../../firebase/firestore/documents/seeker';
import SeekerExperiencePresenter from '../../messaging/seeker/candidate/profile/seekerExperiencePresenter';

export default class BookingDetailPresenter {
  readonly experiences = (
    this.selectedSeeker?.experiences?.map((experience: Experience) => new SeekerExperiencePresenter(experience)) ?? []
  ).sort((x, y) => Number(y.current) - Number(x.current));

  constructor(readonly selectedSeeker?: Seeker) {}

  get hasExperiences(): boolean {
    return this.experiences.length > 0;
  }
}
