export default {
  DISMISSED: 'Rejeté',
  NOT_LOOKING_FOR_WORK: 'Non disponible',
  HIRED: 'Embauché',
  OFFER_MADE: 'Offre faite',
  UNHIRED: 'Non embauché',
  OFFER_REJECTED: 'Offre refusée',
  HIRED_ON_ANOTHER_POSITION: 'Embauché sur un autre poste',
  CONTACT_REQUESTED: 'Demande contact demandé',
  CONTACT_REQUEST_ACCEPTED: 'Demande contact accepté',
  CONTACT_REQUEST_DECLINED: 'Demande contact refusé',
  APPOINTMENT_REQUESTED: 'Entrevue demandée',
  APPOINTMENT_ACCEPTED: 'Entrevue acceptée',
  APPOINTMENT_DECLINED: 'Entrevue refusée',
  APPOINTMENT_CANCELLED: 'Entrevue annulée',
  APPOINTMENT_RESCHEDULING_NEEDED: 'Entrevue à replanifier',
  BOOKMARKED: 'Sauvegardé',
  LIKED: 'Appliqué',
  BLACKLISTED: 'Blacklisted',
  UNKNOWN: 'Inconnue',
  INTEREST_REVOKED: 'Pas intéressé',
  APPOINTMENT_REQUEST_MODIFIED: 'Entrevues modifié',
  HIRE_IN_REVIEW: "Formulaire d'embauche en attente de vérification",
  CANDIDATE_RECORDING_UPLOADED: 'Language Evaluation Performed',
  HIRE_FORM_REQUESTED: "Formulaire d'embauche demandé",
  HIRE_FORM_IN_PROGRESS: "Formulaire d'embauche en cours",
  HIRE_FORM_HELP_REQUESTED: "Formulaire d'embauche aide demandée",
};
