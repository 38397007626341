import _uniqueId from 'lodash/uniqueId';
import _findIndex from 'lodash/findIndex';

type UnregisterFunction = () => void;
type Snapshot = {
  id: string;
  unregister: UnregisterFunction;
};

class Snapshots {
  private readonly registeredSnapshots: Snapshot[] = [];

  registerSnapshot(unregisterFunction: UnregisterFunction) {
    const uid = _uniqueId();
    this.registeredSnapshots.push({ id: uid, unregister: unregisterFunction });
    return uid;
  }

  unregisterSnapshot(id?: string) {
    if (!id) {
      return;
    }

    const { registeredSnapshots } = this;
    const index = _findIndex(registeredSnapshots, { id });
    if (index !== -1) {
      registeredSnapshots[index].unregister();
      this.registeredSnapshots.splice(index, 1);
    } else {
      console.warn(`SnapshotManager -> No snapshot found for id: ${id}`);
    }
  }
}

export const snapshotsManager = new Snapshots();
export default { snapshotsManager };
