import {
  Input,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Center,
  theme,
  PopoverFooter,
  ButtonGroup,
  Image,
  Text,
  Box,
  ButtonProps,
} from '@chakra-ui/core';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { BiImageAdd } from 'react-icons/bi';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import colors from '../../../styles/colors';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { ImageGalleryUploadResponse } from './ImageGalleryType';
import { useValidateImageUpload } from './hooks/useValidateImageUpload';

interface ImageUploadActionProps {
  triggerButtonProps?: Pick<ButtonProps, 'size' | 'variant' | 'colorScheme' | 'backgroundColor'> & {
    iconSize?: number;
  };
  onImageUploadSuccess?: (image: ImageGalleryUploadResponse['data']) => void;
}

/**
 * ImageUploadAction component.
 *
 * This component allows a user to select an image file and upload it to the image gallery.
 * The component displays a button to open the image select dialog and displays an image preview
 * if the user has selected an image. If the user has selected an image, the component also displays
 * a save and cancel button to either save the image to the image gallery or cancel the upload.
 * The component also displays any errors that occur during the upload process.
 *
 * - `triggerButtonProps` - The props for the button that triggers the image select dialog.
 *
 * @return {React.ReactElement} The React component.
 */
export default function ImageUploadAction({ triggerButtonProps, onImageUploadSuccess }: ImageUploadActionProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);

  const { t } = useTranslation();

  const { uploadImageToGallery } = useStoreActions((actions) => actions.imageGallery);
  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';

  const { isImageUploadingToGallery, isGalleryLoading, isGalleryRefetching } = useStoreState((s) => s.imageGallery);
  const isLoading = isImageUploadingToGallery || isGalleryLoading || isGalleryRefetching;

  const open = useCallback(() => {
    if (imagePreview || error) {
      setIsOpen(true);
    }
  }, [imagePreview, error]);

  useEffect(() => {
    open();
    // eslint-disable-next-line
  }, [imagePreview, error]);

  const handleResetState = () => {
    setIsOpen(false);
    setImagePreview(null);
    setError(null);
    setImage(null);
  };

  const { handleImageChange } = useValidateImageUpload({
    onChange(value) {
      setImagePreview(URL.createObjectURL(value!));
      setImage(value);
    },
    onError(err) {
      if (err) {
        setImagePreview(null);
      }
      setError(err);
    },
  });

  const openImageSelect = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    /* istanbul ignore else */
    if (inputRef.current && !isLoading) {
      inputRef.current?.focus();
      inputRef.current?.click();
    }
  };

  async function handleImageUpload() {
    const formData = new FormData();
    const images = [image!];
    images.forEach((val) => {
      formData.append('images[]', val, val.name);
    });
    try {
      const response = await uploadImageToGallery({ accountId, formData });
      handleResetState();
      onImageUploadSuccess?.(response.data);
    } catch {
      setError(t('administration:imageGalleryModal:uploadError'));
    }
  }

  return (
    <Box marginTop="0 !important" data-testid="ImageUploadAction">
      <Input
        type="file"
        accept="image/*"
        title=""
        ref={inputRef}
        onChange={handleImageChange}
        data-testid="image-upload-input"
        visibility="hidden"
        style={{
          opacity: '0',
          width: '0%',
          zIndex: -100,
          position: 'absolute',
        }}
      />
      <Popover
        isOpen={isOpen}
        onOpen={open}
        onClose={handleResetState}
        placement="bottom"
        closeOnBlur={false}
        returnFocusOnClose={false}
        closeOnEsc={!isImageUploadingToGallery}
      >
        <PopoverTrigger>
          <Button
            onClick={openImageSelect}
            leftIcon={<BiImageAdd size={triggerButtonProps?.iconSize} />}
            isDisabled={isLoading}
            data-testid="image-upload-button"
            {...omit(triggerButtonProps, ['iconSize'])}
            {...{
              colorScheme: triggerButtonProps?.colorScheme || colors.blue[500],
              backgroundColor: triggerButtonProps?.backgroundColor || colors.blue[500],
            }}
          >
            {t('administration:imageGalleryModal:uploadButtonLabel')}
          </Button>
        </PopoverTrigger>
        <PopoverContent borderColor={error ? colors.red[300] : 'initial'}>
          <PopoverArrow />
          {error && <PopoverCloseButton />}
          <PopoverBody>
            <Center>
              {imagePreview && (
                <Image
                  src={imagePreview}
                  width={300}
                  height={300}
                  objectFit="cover"
                  data-testid="upload-image-preview"
                  borderRadius={theme.radii.md}
                />
              )}
              {error && (
                <Text pr={10} color={colors.red[500]} fontSize="sm" fontWeight="bold" data-testid="upload-image-error">
                  {error}
                </Text>
              )}
            </Center>
          </PopoverBody>
          {imagePreview && (
            <PopoverFooter d="flex" justifyContent="flex-end">
              <ButtonGroup size="sm">
                <Button
                  variant="outline"
                  onClick={handleResetState}
                  isDisabled={isImageUploadingToGallery}
                  data-testid="cancel-image-button"
                >
                  {t('administration:imageGalleryModal:uploadPreviewCancelButtonLabel')}
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={handleImageUpload}
                  isLoading={isImageUploadingToGallery}
                  data-testid="upload-image-button"
                >
                  {t('administration:imageGalleryModal:uploadPreviewSaveButtonLabel')}
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          )}
        </PopoverContent>
      </Popover>
    </Box>
  );
}

ImageUploadAction.defaultProps = {
  triggerButtonProps: {
    size: 'lg',
    variant: 'solid',
    iconSize: 24,
  },
  onImageUploadSuccess: undefined,
};
