function getTranslatedNumber(localizedNumber: string | number, locale: string): string | number {
  const localizedNumberInStr = localizedNumber.toString();
  // eslint-disable-next-line prefer-const
  let [beforeDecimal, afterDecimal] = localizedNumberInStr.split('.');
  if (afterDecimal) {
    if (afterDecimal.length === 1) {
      afterDecimal = afterDecimal.concat('0');
    }
  }
  let newLocalizedNumberInStr = afterDecimal ? `${beforeDecimal}.${afterDecimal}` : beforeDecimal;
  if (locale === 'fr') {
    newLocalizedNumberInStr = newLocalizedNumberInStr.replace('.', ',');
  }
  return newLocalizedNumberInStr;
}

function getTranslatedStringValue(localizedNumber: string | number, locale: string): string | number {
  let localizedNumberInStrForValue = localizedNumber.toString();
  if (locale === 'fr') {
    localizedNumberInStrForValue = localizedNumberInStrForValue.replace('.', ',');
  } else {
    localizedNumberInStrForValue = localizedNumberInStrForValue.replace(',', '.');
  }
  return localizedNumberInStrForValue;
}

// eslint-disable-next-line import/prefer-default-export
export { getTranslatedNumber, getTranslatedStringValue };
