import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { BusinessManagementController } from '../../../controllers/businessManagementController';
import { Business, BusinessListQueryParams, BusinessListResponse, BusinessResponse } from '../positionManagement/BusinessType';

export type BusinessManagementStoreModel = {
  businessManagement: BusinessManagementModel;
};

export interface BusinessManagementModel {
  createBusiness: Thunk<
    BusinessManagementModel,
    { accountId: string; businessData: Business },
    void,
    HttpStoreModel,
    Promise<BusinessResponse>
  >;
  editBusiness: Thunk<
    BusinessManagementModel,
    { accountId: string; businessData: Business; businessId: string },
    void,
    HttpStoreModel,
    Promise<BusinessResponse>
  >;
  getBusinessList: Thunk<
    BusinessManagementModel,
    { params: BusinessListQueryParams },
    void,
    HttpStoreModel,
    Promise<BusinessListResponse>
  >;
}

export const businessManagementStoreModel: BusinessManagementModel = {
  createBusiness: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new BusinessManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.createBusiness(payload.accountId, payload.businessData);
    return response?.data as BusinessResponse;
  }),
  editBusiness: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new BusinessManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.editBusiness(payload.accountId, payload.businessData, payload.businessId);
    return response?.data as BusinessResponse;
  }),
  getBusinessList: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new BusinessManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getBusinessList(payload.params);
    return response?.data as BusinessListResponse;
  }),
};
