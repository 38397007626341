import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, Flex, Spinner, Stack, useDisclosure } from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';
import _flatten from 'lodash/flatten';
import moment from 'moment';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { CurrentUserProfilePresenter } from '../../../app/hooks/CurrentUserProfilePresenter';
import { MicrosoftUser } from '../../../firebase/firestore/documents/user';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import {
  CalendarEventType,
  GoogleEventsType,
  OtherCalendarErrorResponseType,
  ValidateMicrosoftAuthType,
} from '../CalendarEventType';
import { CalendarScheduleTypeEnum } from '../CalendarScheduleTypeEnum';
import { ConfirmationDialog } from '../../common/AlertDialog/ConfirmationDialog';
import { TeamInviteAction, TeamMemberAction } from '../../administration/team/TeamType';
import { gmailCheckCSS } from '../CalendarView.Style';
import { OtherAccountTypeEnum } from '../OtherAccountTypeEnum';

export type MicrosoftEventsViewProps = {
  currentUserProfile: CurrentUserProfilePresenter;
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MicrosoftEventsView = ({ currentUserProfile }: MicrosoftEventsViewProps): JSX.Element => {
  const { t } = useTranslation('calendar');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [isLoading, setIsLoading] = useState<{ [x: string]: boolean }>({});
  const [deleteMessage, setDeleteMessage] = useState<string>(t('otherCalendar.deleteConfirmationLabel'));
  const [activeMicrosoft, setActiveMicrosoft] = useState<string | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const code = useQuery().get('code');

  const location = useLocation();
  const history = useHistory();

  const microsoftColors = ['red', 'teal', 'yellow', 'gray', 'blue'];

  const {
    validateOtherCalendarAuthToken,
    getOtherCalendarEvent,
    setMicrosoftCalendarEvent,
    updateCheckedEmailStatus,
    removeOtherCalendarAccountAuth,
  } = useStoreActions((actions) => actions.calendar);
  const { microsoftCalendarEvent, currentQuarterOnCalendar } = useStoreState((state) => state.calendar);

  const filterEventWithValidValues = (microsoftEvents: CalendarEventType[]): CalendarEventType[] => {
    return microsoftEvents
      .filter((microsoftEvent) => !_isNil(microsoftEvent.title) && !_isNil(microsoftEvent.start) && !_isNil(microsoftEvent.end))
      .map((microsoftEvent) => ({
        ...microsoftEvent,
        start: moment(microsoftEvent.start).toDate(),
        end: moment(microsoftEvent.end).toDate(),
        resource: {
          ...microsoftEvent.resource,
          type: CalendarScheduleTypeEnum.OUTLOOK_EVENT,
        },
      }));
  };

  const getStyleForMicrosoftMail = (email: string | undefined) => {
    const microsoftConnectedAccount = _sortBy(currentUserProfile?.microsoftCalendar, 'microsoftMail');
    const indexOfEmail = microsoftConnectedAccount.findIndex((r) => r.microsoftMail === email) % 10;
    return microsoftColors[indexOfEmail > 0 ? indexOfEmail : 0];
  };

  const filterMicrosoftEventWithEmail = (events: CalendarEventType[], email: string) => {
    return events.filter((microsoftEvent) => microsoftEvent.email !== email);
  };

  const getEventByEmailAddress = async (
    currentUser: CurrentUserProfilePresenter,
    emailValue: string,
    calendarAccount: OtherAccountTypeEnum,
  ): Promise<CalendarEventType[]> => {
    setIsLoading((prevState) => ({
      ...prevState,
      [emailValue]: true,
    }));
    return getOtherCalendarEvent({
      accountId: currentUser?.account,
      userId: currentUser?.id,
      email: emailValue,
      fromDate: moment(currentQuarterOnCalendar.date)
        .quarter(currentQuarterOnCalendar.quarter)
        .startOf('quarter')
        .subtract(7, 'days')
        .format('YYYY/MM/DD'),
      toDate: moment(currentQuarterOnCalendar.date)
        .quarter(currentQuarterOnCalendar.quarter)
        .endOf('quarter')
        .add(7, 'days')
        .format('YYYY/MM/DD'),
      calendarAccount,
    })
      .then((getCalendarEventResponse) => {
        const { events } = getCalendarEventResponse as GoogleEventsType;
        const { status } = getCalendarEventResponse as OtherCalendarErrorResponseType;

        if (status === 400) {
          setActiveMicrosoft(emailValue);
          setDeleteMessage(t('otherCalendar.deleteConfirmationLabelWithEmail', { emailValue }));
          onOpen();
          return [];
        }
        return filterEventWithValidValues(events);
      })
      .finally(() => {
        setIsLoading((prevState) => ({
          ...prevState,
          [emailValue]: false,
          loading: false,
        }));
      });
  };

  const loadAllMicrosoftCalendarEvents = (newAccount: MicrosoftUser | boolean = false) => {
    const microsoftEventRequests: Promise<CalendarEventType[]>[] = [];
    const newMicrosoftAccount = newAccount as MicrosoftUser;
    if (currentUserProfile?.microsoftCalendar) {
      const microsoftAccounts = newMicrosoftAccount
        ? [...currentUserProfile?.microsoftCalendar, newMicrosoftAccount]
        : currentUserProfile.microsoftCalendar;
      // eslint-disable-next-line consistent-return
      microsoftAccounts?.forEach((account) => {
        if (account.active) {
          return microsoftEventRequests.push(
            getEventByEmailAddress(currentUserProfile, account.microsoftMail, OtherAccountTypeEnum.OUTLOOK),
          );
        }
      });
      Promise.all(microsoftEventRequests)
        .then((allEvents: CalendarEventType[][]) => {
          setMicrosoftCalendarEvent(_flatten(allEvents));
        })
        .catch((error) => console.log(error));
    }
  };

  // istanbul ignore next
  const loadMicrosoftCalendarEvents = async (
    checked: boolean,
    emailValue: string,
    currentUser: CurrentUserProfilePresenter,
    calendarAccount: OtherAccountTypeEnum,
  ) => {
    if (checked) {
      const filteredMicrosoftCalendarEvent = !currentUser.microsoftCalendar?.find(
        (account) => account.microsoftMail === emailValue,
      )
        ? microsoftCalendarEvent
        : filterMicrosoftEventWithEmail(microsoftCalendarEvent, emailValue);

      setMicrosoftCalendarEvent([
        ...filteredMicrosoftCalendarEvent,
        ...(await getEventByEmailAddress(currentUser, emailValue, OtherAccountTypeEnum.OUTLOOK)),
      ]);
    } else {
      await updateCheckedEmailStatus({
        accountId: currentUser?.account,
        userId: currentUser?.id,
        email: emailValue,
        calendarAccount,
      });
      setMicrosoftCalendarEvent(filterMicrosoftEventWithEmail(microsoftCalendarEvent, emailValue));
    }
  };

  const loadMicrosoftAccounts = (microAccounts: MicrosoftUser[]): JSX.Element => {
    const microsoftMailList = _sortBy(microAccounts, 'microsoftMail').map((value) => (
      <Flex key={value.microsoftMail} css={gmailCheckCSS} data-testid={`checkBtn-${value.microsoftMail}`}>
        <Checkbox
          isChecked={value.active}
          isDisabled={Object.values(isLoading).includes(true)}
          value={value.microsoftMail}
          onChange={
            /* istanbul ignore next */ (e) =>
              loadMicrosoftCalendarEvents(e.target.checked, value.microsoftMail, currentUserProfile, OtherAccountTypeEnum.OUTLOOK)
          }
          colorScheme={getStyleForMicrosoftMail(value.microsoftMail)}
        >
          {value.microsoftMail}
          {isLoading && isLoading[value.microsoftMail] && <Spinner size="xs" ml={2} />}
        </Checkbox>
        <RiDeleteBin5Fill
          cursor="pointer"
          className="deleteIcon"
          data-testid={`deleteBtn-${value.microsoftMail}`}
          onClick={() => {
            setDeleteMessage(t('otherCalendar.deleteConfirmationLabel'));
            onOpen();
            setActiveMicrosoft(value.microsoftMail);
          }}
        />
      </Flex>
    ));

    return (
      <Stack spacing={[1, 0]} direction="column" overflow="visible">
        {microsoftMailList}
      </Stack>
    );
  };

  const handleConfirm = async (action: TeamMemberAction | TeamInviteAction) => {
    // istanbul ignore else
    if (action && activeMicrosoft) {
      await removeOtherCalendarAccountAuth({
        accountId: currentUserProfile.account,
        userId: currentUserProfile.id,
        email: activeMicrosoft,
        calendarAccount: OtherAccountTypeEnum.OUTLOOK,
      });
      setMicrosoftCalendarEvent(filterMicrosoftEventWithEmail(microsoftCalendarEvent, activeMicrosoft));
    }
    onClose();
  };

  const validateAccount = useCallback(
    async (microsoftCode: string, currentUser: CurrentUserProfilePresenter) => {
      const response = await validateOtherCalendarAuthToken({
        accountId: currentUser.account,
        userId: currentUser.id,
        code: microsoftCode,
        calendarAccount: OtherAccountTypeEnum.OUTLOOK,
      });
      const sucessResponse = response as ValidateMicrosoftAuthType;
      const errorResponse = response as OtherCalendarErrorResponseType;
      const queryParams = new URLSearchParams(location.search);
      if (sucessResponse && sucessResponse.microsoftEmail) {
        loadAllMicrosoftCalendarEvents({ microsoftMail: sucessResponse.microsoftEmail, active: true });
      }
      if (errorResponse && errorResponse.status === 400) {
        setDeleteMessage(errorResponse.title);
        onOpen();
      }
      queryParams.delete('code');
      history.replace({
        search: queryParams.toString(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadMicrosoftCalendarEvents, validateOtherCalendarAuthToken, history, location.search],
  );

  useEffect(() => {
    loadAllMicrosoftCalendarEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuarterOnCalendar]);

  useEffect(() => {
    currentUserProfile?.microsoftCalendar?.map((account) => {
      if (account.calendarUpdated) {
        loadMicrosoftCalendarEvents(true, account.microsoftMail, currentUserProfile, OtherAccountTypeEnum.OUTLOOK).catch((e) => {
          console.log(e);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserProfile]);

  useEffect(() => {
    if (code) {
      validateAccount(code, currentUserProfile).catch((e) => console.log(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <Fragment>
      <Box p={2} pt={0}>
        {currentUserProfile?.microsoftCalendar && loadMicrosoftAccounts(currentUserProfile?.microsoftCalendar)}
      </Box>
      <ConfirmationDialog
        isLoading={false}
        isOpen={isOpen}
        onClose={onClose}
        message={deleteMessage}
        action={activeMicrosoft ? TeamMemberAction.DELETE : null}
        handleConfirm={handleConfirm}
      />
    </Fragment>
  );
};

export default MicrosoftEventsView;
