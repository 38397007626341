import React from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalFooter,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { TokenList } from './tokenList/TokenList';
import { ProductType } from './TokenTypes';
import { useStoreActions } from '../../models/hooks';

const defaultItem: ProductType = {
  productId: '',
  productName: '',
  priceId: '',
  price: '',
  description: '',
  currency: '',
  credit: 0,
};

type ModalProps = {
  open: boolean;
  onCloseModal: () => void;
  positionId: string;
};
export const TokenView = ({ open, onCloseModal, positionId }: ModalProps): JSX.Element => {
  const { t } = useTranslation('tokenPayment');
  const [selectedItem, setSelectedItem] = React.useState<ProductType>(defaultItem);
  const [isSelected, setIsSelected] = React.useState<boolean>(false);
  const [buyingToken, setIsBuyingToken] = React.useState<boolean>(false);

  const handleTokenSelect = (product: ProductType, selected: boolean) => {
    setSelectedItem(product);
    setIsSelected(selected);
  };

  // get the current URL
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  // extracting root domain
  const rootDomain = url.origin;

  const { buyToken } = useStoreActions((actions) => actions.product);

  // function to handle buying token/product
  const handleBuyToken = async () => {
    if (isSelected) {
      try {
        setIsBuyingToken(true);
        const res = await buyToken({
          productId: selectedItem.productId,
          priceId: selectedItem.priceId,
          successUrl: `${rootDomain}/paymentSuccess?positionId=${positionId}`,
          cancelUrl: `${rootDomain}/positionId/${positionId}`,
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (res?.data?.data?.checkOutUrl && res?.data?.data?.checkOutUrl !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          window.location.href = res.data.data.checkOutUrl;
        } else {
          console.error('No checkout url found');
        }
        setIsBuyingToken(false);
      } catch (e) {
        setIsBuyingToken(false);
      }
    }
  };

  return (
    <Modal isOpen={open} onClose={() => onCloseModal()}>
      <ModalOverlay />
      <ModalContent maxW="40rem" padding="10px">
        <ModalHeader>
          <Text m="3px 0" fontSize="18px" fontWeight={700}>
            {t('cannotEngage')}
          </Text>
          <Box backgroundColor="#EDF2F7" padding="24px" marginTop="20px" borderRadius="16px" marginBottom="20px">
            <Center>
              <Image src="/noToken.png" alt="no_token" />
            </Center>
            <Center>
              <Text fontSize="20px" color="orange.500" paddingBottom="8px" paddingTop="8px">
                {t('outOfCredit')}
              </Text>
            </Center>
            <Center>
              <Text fontSize="14px" fontWeight={400} textAlign="center">
                {t('purchaseMore')}
              </Text>
            </Center>
          </Box>
          <Divider paddingTop="1px" paddingBottom="1px" />
        </ModalHeader>
        <ModalCloseButton data-testid="closeToken" marginTop="1rem" marginRight="1.5rem" />
        <ModalBody>
          <Text fontSize="16px" fontWeight={700}>
            {t('choosePackage')}
          </Text>

          <TokenList onTokenSelect={handleTokenSelect} />

          <Divider paddingTop="12px" paddingBottom="12px" />
        </ModalBody>
        <ModalFooter>
          <Button data-testid="tokenBtn" colorScheme="blue" isLoading={buyingToken} onClick={handleBuyToken}>
            {t('buyCredits')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
