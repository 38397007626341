import { useEffect, useState } from 'react';
import { AudioTrack, VideoTrack } from 'twilio-video';

export const useMediaStreamTrack = (track: AudioTrack | VideoTrack): MediaStreamTrack => {
  const [mediaStreamTrack, setMediaStreamTrack] = useState(track.mediaStreamTrack);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setMediaStreamTrack(track.mediaStreamTrack);

    const handleStart = () => setMediaStreamTrack(track.mediaStreamTrack);

    track.on('started', handleStart);

    return () => {
      track.off('started', handleStart);
    };
  }, [track]);

  return mediaStreamTrack;
};
