import { Box, Button, HStack, Select, Stack, Text } from '@chakra-ui/core';
import React from 'react';
import theme from '../../../../styles/customTheme';
import { FieldSchema, Language, languages } from './formBuilderSchema';
import { HiOutlineDeviceMobile, HiMenuAlt4 } from 'react-icons/hi';
import { MdOutlineCircle } from 'react-icons/md';
import { SectionSchema } from '../HireformView';

const previewModes = {
  device: {
    icon: <HiOutlineDeviceMobile />,
    label: 'Device View',
  },
  list: {
    icon: <HiMenuAlt4 />,
    label: 'List View',
  },
} as const;

interface FormPreviewProps {
  schema: SectionSchema[];
  language: Language;
  onLanguageChange: (lang: Language) => void;
}

export default function FormPreview({ language, onLanguageChange, schema }: FormPreviewProps) {
  const [previewMode, setPreviewMode] = React.useState<keyof typeof previewModes>('list');
  return (
    <Box minHeight={0} height="100%" background={theme.colors.white} paddingY={theme.space[6]} paddingX={theme.space[4]}>
      <Stack minHeight={0} height="100%">
        <HStack justify="space-between">
          <Text fontSize="lg" fontWeight="bold">
            Form Preview
          </Text>
          <Button colorScheme="blue" size="sm">
            Publish
          </Button>
        </HStack>
        <HStack justify="flex-end">
          <Select
            onChange={(e) => onLanguageChange(e.target.value as Language)}
            value={language}
            variant="outline"
            size="sm"
            colorScheme="blue[100]"
            borderRadius={theme.radii.md}
            maxWidth="fit-content"
          >
            {languages.map((lang) => (
              <option key={lang}>{lang}</option>
            ))}
          </Select>
          <Button
            size="sm"
            colorScheme="gray"
            rightIcon={previewModes[previewMode].icon}
            onClick={() => setPreviewMode((prev) => (prev === 'device' ? 'list' : 'device'))}
          >
            {previewModes[previewMode].label}
          </Button>
        </HStack>
        <Box flexGrow={1} overflowY={'auto'} overflowX={'hidden'}>
          {previewMode === 'list' && (
            <Stack
              sx={{
                '& ol, & ul': {
                  margin: 'revert',
                  padding: 'revert',
                },
              }}
            >
              {schema.map((item, index) => (
                <SectionPreview key={index} schema={item} language={language} />
              ))}
            </Stack>
          )}
        </Box>
      </Stack>
    </Box>
  );
}

function SectionPreview({ schema, language }: { schema: SectionSchema; language: Language }) {
  return (
    <Box>
      <Text fontWeight="bold" fontSize="xs">
        {schema.section}
      </Text>
      {schema.fields.map((field) => (
        <ElementPreview key={field.id} schema={field} language={language} />
      ))}
    </Box>
  );
}

function ElementPreview({ schema, language }: { schema: FieldSchema; language: Language }) {
  return (
    <Box borderRadius={theme.radii.md} fontSize={theme.fontSizes.sm} paddingLeft={2}>
      <Text fontWeight="bold" fontSize="xs">
        {schema?.label?.[language]}
      </Text>
      <Box dangerouslySetInnerHTML={{ __html: schema?.text?.[language] ?? '' }} />
      {schema?.optionList?.map((option) => (
        <Stack>
          <HStack>
            <MdOutlineCircle color="blue" />

            <Box key={option.id} dangerouslySetInnerHTML={{ __html: option.text?.[language] ?? '' }} />
          </HStack>
          <FollowUpPreview key={option.id} optionId={option.id} schema={schema} language={language} />
        </Stack>
      ))}
    </Box>
  );
}

function FollowUpPreview({ optionId, schema, language }: { optionId: string; schema: FieldSchema; language: Language }) {
  if (!schema?.followUpQuestionList?.length && schema.followUpQuestionList?.findIndex((v) => v.optionId === optionId) === -1)
    return null;
  const followUp = schema.followUpQuestionList?.find((v) => v.optionId === optionId);
  return (
    <Box paddingLeft={8}>
      <ElementPreview schema={followUp!} language={language} />
    </Box>
  );
}
