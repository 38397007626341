import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/core';
import React from 'react';
import _isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { AutomationTypes } from './AutomationTypes';
import { JobPosition } from '../PositionType';
import { InterviewDetail } from './InterviewDetail';
import { FormattedError, getErrorMessage, hasErrorMessage } from '../../../../utils/FormErrorUtils';

export type AutomationProps = {
  jobPositionData: JobPosition;
  formErrors: FormattedError[];
  isInterviewAndBusinessLocationSame: boolean;
  handleChange: (value: string | null, fieldName: string, dataType: boolean) => void;
  handleAutomationChange: (value: string | null, fieldName: string) => void;
  handleChangeInterviewLocation: (value: boolean) => void;
  removePadding: boolean;
};

export const AutomationCard = ({
  jobPositionData,
  formErrors,
  isInterviewAndBusinessLocationSame,
  handleChange,
  handleAutomationChange,
  handleChangeInterviewLocation,
  removePadding,
}: AutomationProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box backgroundColor="white" borderRadius={5} mb={6}>
      <Box px={6} py={4} paddingLeft={removePadding ? 0 : '1.5rem'} paddingRight={removePadding ? 0 : '1.5rem'}>
        <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
          {t('createJobPosition:automationCardHeader')}
        </Heading>
        <Text fontSize="xs" color="gray.500">
          {t('createJobPosition:automationCardHint')}
        </Text>
      </Box>
      <Divider borderColor="#ECEFF1" />
      <Box py={4} px={0} position="relative">
        <RadioGroup defaultValue="1" value={!_isNil(jobPositionData.automationType) ? jobPositionData.automationType : 'null'}>
          <VStack divider={<StackDivider borderColor="#ECEFF1" />} spacing={4} align="stretch">
            <Radio
              value="null"
              px={5}
              py={1}
              alignItems="flex-start"
              onChange={/* istanbul ignore next */ (e) => handleAutomationChange(e.target.value, 'automationType')}
            >
              <Box ml={2}>
                <Heading as="h4" fontSize="md" lineHeight="1" mb={2}>
                  {t('createJobPosition:automationOptionOneLabel')}
                </Heading>
                <Text fontSize="xs" color="gray.500">
                  {t('createJobPosition:automationOptionOneHint')}
                </Text>
              </Box>
            </Radio>
            <Radio
              value="screening"
              px={5}
              py={1}
              alignItems="flex-start"
              onChange={/* istanbul ignore next */ (e) => handleAutomationChange(e.target.value, 'automationType')}
            >
              <Box ml={2}>
                <Heading as="h4" fontSize="md" lineHeight="1" mb={2}>
                  {t('createJobPosition:automationOptionTwoLabel')}
                </Heading>
                <Text fontSize="xs" color="gray.500">
                  {t('createJobPosition:automationOptionTwoHint')}
                </Text>
              </Box>
            </Radio>
            {jobPositionData.automationType === AutomationTypes.SCREENING && (
              <Box px={10} py={3}>
                <FormControl
                  d="flex"
                  alignItems="center"
                  isInvalid={hasErrorMessage(formErrors, 'automationConfiguration.target')}
                  isRequired
                >
                  <FormLabel htmlFor="candidateScreen" m={0} fontWeight="bold">
                    {t('createJobPosition:automationOptionNoOfCandidateScreenLabel')}
                  </FormLabel>
                  <Box w="120px" ml={3}>
                    <Input
                      type="number"
                      variant="filled"
                      id="candidateScreen"
                      data-testid="CandidateScreenInput"
                      value={
                        !_isNil(jobPositionData.automationConfiguration?.target)
                          ? jobPositionData.automationConfiguration?.target
                          : undefined
                      }
                      onChange={(e) => handleChange(e.target.value, 'automationConfiguration.target', true)}
                    />
                  </Box>
                  <FormErrorMessage ml={2}>{getErrorMessage(formErrors, 'automationConfiguration.target')}</FormErrorMessage>
                </FormControl>
              </Box>
            )}
            <Radio
              value="scheduling"
              px={5}
              py={1}
              alignItems="flex-start"
              onChange={/* istanbul ignore next */ (e) => handleAutomationChange(e.target.value, 'automationType')}
            >
              <Box ml={2}>
                <Heading as="h4" fontSize="md" lineHeight="1" mb={2}>
                  {t('createJobPosition:automationOptionThreeLabel')}
                </Heading>
                <Text fontSize="xs" color="gray.500">
                  {t('createJobPosition:automationOptionThreeHint')}
                </Text>
              </Box>
            </Radio>
            {jobPositionData.automationType === AutomationTypes.SCHEDULING && (
              <Box px={6} py={3}>
                <InterviewDetail
                  jobPositionData={jobPositionData}
                  formErrors={formErrors}
                  isInterviewAndBusinessLocationSame={isInterviewAndBusinessLocationSame}
                  handleChange={handleChange}
                  handleChangeInterviewLocation={handleChangeInterviewLocation}
                />
              </Box>
            )}
          </VStack>
        </RadioGroup>
      </Box>
    </Box>
  );
};
