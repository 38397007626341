import React, { Fragment } from 'react';
import { Box, Stack, Text, Flex, Badge, Image, Center } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { questionsViewWrapper } from '../specificQuestions/Questions.styles';
import colors from '../../../../../styles/colors';
import { Candidate } from '../../../../../firebase/firestore/documents/candidate';
import { QuestionType } from '../questions/QuestionType';
import ImageView from '../../../../../app/components/ImageView';
import { ImagePreviewModal } from '../../../../../app/components/ImagePreviewModal';

const MotionStack = motion.custom(Stack);
const parentVariants = {
  visible: {
    transition: { staggerChildren: 0.05, delayChildren: 0.05 },
  },
  hidden: {
    transition: { staggerChildren: 0.01, staggerDirection: -1 },
  },
};

export type MultipleChoiceQuestionViewProps = {
  activeQuestionType: QuestionType;
  selectedCandidate: Readonly<Candidate> | undefined;
};

export const MultipleChoiceQuestionView = ({
  activeQuestionType,
  selectedCandidate,
}: MultipleChoiceQuestionViewProps): JSX.Element => {
  const { t } = useTranslation();

  // istanbul ignore next
  const getAnswers = (
    options: {
      id: string;
      text: string;
      wasAnswered: boolean;
    },
    answers: {
      id: string;
      answer: string;
      preferredAnswer?: boolean;
    }[],
    preferredAnswers: string[] | undefined,
  ) => {
    if (
      answers
        .map((ans) => {
          return ans.id;
        })
        .includes(options.id)
    ) {
      return preferredAnswers && preferredAnswers.length > 1 ? (
        <MdCheckBox style={{ color: colors.blue[500], marginTop: '3px' }} />
      ) : (
        <MdRadioButtonChecked style={{ color: colors.blue[500], marginTop: '3px' }} />
      );
    }
    return preferredAnswers && preferredAnswers.length > 1 ? (
      <MdCheckBoxOutlineBlank style={{ color: colors.blue[500], marginTop: '3px' }} />
    ) : (
      <MdRadioButtonUnchecked style={{ color: colors.blue[500], marginTop: '3px' }} />
    );
  };
  return (
    <Fragment>
      <MotionStack
        key="CandidateQuestionsMotionStack"
        initial="hidden"
        animate="visible"
        variants={parentVariants}
        spacing={2}
        padding={2}
        className={['user-selectable', ''].join(' ')}
        css={questionsViewWrapper}
        data-testid="SpecificQuestions"
      >
        {selectedCandidate?.multipleChoiceQuestionResponses && selectedCandidate?.multipleChoiceQuestionResponses.length > 0 ? (
          <Box border="1px solid" borderColor="gray.50">
            {selectedCandidate?.multipleChoiceQuestionResponses?.map((q, index) => {
              const hasImage = (q?.images?.length ?? 0) > 0;
              return (
                <Box
                  p={2}
                  border="1px solid"
                  borderColor="gray.50"
                  marginTop="-1px"
                  key={q.id}
                  bg={index % 2 ? 'gray.150' : 'white'}
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems={hasImage ? 'flex-start' : 'center'}
                    borderBottom="1px solid"
                    borderColor="gray.50"
                    mb={2}
                  >
                    <Stack spacing={2} mb={2}>
                      <Text fontSize="sm" fontWeight="400">
                        {`${index + 1}. ${q.question}`}
                      </Text>
                      {hasImage && (
                        <ImagePreviewModal
                          images={q.images!}
                          getImageKey={(image) => image?.galleryId || ''}
                          getImageSrc={(image) => image?.url || ''}
                          setTrigger={<ImageView src={q.images?.[0]?.url} />}
                        />
                      )}
                    </Stack>
                    <Badge colorScheme={q.isAnsweredCorrectly ? 'green' : 'red'}>
                      {q.isAnsweredCorrectly ? t('questions.pass') : t('questions.fail')}
                    </Badge>
                  </Flex>
                  <Flex flexWrap="wrap">
                    {q.options.map((option) => (
                      <Flex w="48%" mb={1} key={option.id} alignItems="flex-start" mr="2%">
                        {getAnswers(option, q.answers, q.preferredAnswers)}
                        <Text
                          fontSize="14px"
                          fontWeight={
                            q.answers
                              .map((ans) => {
                                return ans.id;
                              })
                              .includes(option.id)
                              ? '700'
                              : '400'
                          }
                          w="90%"
                          ml={2}
                        >
                          {option.text}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </Box>
              );
            })}
          </Box>
        ) : (
          activeQuestionType !== QuestionType.ALL_QUESTIONS && (
            <Center>
              <Flex direction="column" data-testid="NoQuestions" align="center" p={3} textAlign="center">
                <Image src="/questions.svg" w="180px" h="auto" alt="No SpecificQuestions" />
                <Text mt="8">
                  <b>{t('questions.noAnswers')}</b>
                </Text>
              </Flex>
            </Center>
          )
        )}
      </MotionStack>
    </Fragment>
  );
};
