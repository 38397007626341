import { css } from '@emotion/core';
import colors from '../../../../../styles/colors';

export const historyViewWrapper = css`
  grid-column: midSide / rightSide;
  grid-row: sub2 / bottom;
  overflow-y: auto;
  background-color: ${colors.white};

  .chakra-ui-dark & {
    background-color: ${colors.black};
  }

  .history-list-wrapper {
    border: 1px solid ${colors.gray[50]};

    .history-block-item {
      padding: 0.5rem 0.75rem;
      border-bottom: 1px solid ${colors.gray[50]};
    }

    > div::last-of-type {
      border: 0;
    }

    .sub-cat-histories {
      border-bottom: 1px solid ${colors.gray[50]};

      > div:last-of-type > div .history-block-item {
        border: 0;
      }
    }
  }

  .history-block-item {
    margin-top: 1.5rem;
    padding: 0.5rem 0;

    span.icon-block {
      border: 1px solid ${colors.gray[100]};
      background: ${colors.gray[50]};
      display: inline-block;
      padding: 7px;
      border-radius: 5px;
      width: 35px;

      svg {
        width: 20px;
        height: auto;
      }
    }
    .chakra-text {
      color: #a0aec0;

      @media only screen and (max-width: 60rem) {
        font-size: 14px;
      }
    }
    &:first-of-type {
      margin-top: 0;
    }
  }
  .reverse {
    transform: scaleX(-1);
  }
  .success {
    color: ${colors.green['600']};
  }
  .info {
    color: ${colors.blue.default};
  }
  .danger {
    color: ${colors.red['600']};
  }
  .seeker_icon {
    color: #ff6600;
  }
  .info-bgCheckInitial {
    color: ${colors.blue.default};
    transition: all 0.2s ease-in-out;
  }
  .info-bgCheckInitial:hover {
    color: ${colors.blue['600']};
    transform: scale(1.2);
  }
  .info-bgCheckSecond {
    color: ${colors.gray['50']};
    transition: all 0.2s ease-in-out;
  }
  .info-bgCheckSecond:hover {
    color: ${colors.blue.default};
    transform: scale(1.2);
  }

  //Card Small Style
  .cardsmall {
    background: repeating-linear-gradient(to bottom, transparent 0 4px, ${colors.blue.default} 4px 8px) 18px calc(100% - 18px) /
      1px calc(100% - 40px) no-repeat;
    position: relative;

    .cardsmall:before {
      content: '';
      position: absolute;
      top: 12px;
      right: calc(100% - 27px);
      width: 30px;
      height: 1px;
      background: repeating-linear-gradient(to right, transparent 0 4px, ${colors.blue.default} 4px 8px) no-repeat;
    }

    .cardsmall-content {
      padding: 10px 5px 5px 20px;
      overflow: hidden;
    }

    .cardsmall {
      margin-top: 20px;

      :first-of-type {
        margin-top: -10px;
      }
    }
  }
`;
