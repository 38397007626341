import React, { useState } from 'react';
import { BoxProps, Box, Stack, Center, Spinner } from '@chakra-ui/core';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { RecruiterDashboardVisualisationView } from '../recruiterVisualisation/RecruiterDashboardVisualisationView';
import RecruiterTableView from './recruitersInfoTable/RecruiterInfoTableView';
import { SingleRecruiterType } from '../RecruiterType';
import { SearchInput } from '../../../common';

export type RecruiterDashboadViewProps = BoxProps & {
  accountId?: string | undefined;
  recruiterListData: SingleRecruiterType[];
  showSearchView?: boolean;
  onSetActiveTab: () => void;
};

const RecruiterDashboardView = ({
  accountId,
  recruiterListData,
  showSearchView,
  onSetActiveTab,
}: RecruiterDashboadViewProps): JSX.Element => {
  const [tableSearchText, setTableSearchText] = useState<string>('');
  const { t } = useTranslation('recruiterManagement');

  const headerInfoFromTranslation = {
    name: t('recruiterTable.tableHeader.name'),
    availability: t('recruiterTable.tableHeader.availability'),
    totalAvailabilityHours: t('recruiterTable.tableHeader.totalAvailabilityHours'),
    requiredDuration: t('recruiterTable.tableHeader.requiredDuration'),
    noOfHirings: t('recruiterTable.tableHeader.noOfHirings'),
    successfulInterviews: t('recruiterTable.tableHeader.successfulInterviews'),
    interviewCancellation: t('recruiterTable.tableHeader.interviewCancellation'),
    candidateNoShow: t('recruiterTable.tableHeader.candidateNoShow'),
    recruiterNoShow: t('recruiterTable.tableHeader.recruiterNoShow'),
  };

  const recruiterTableColumns: Column<SingleRecruiterType>[] = [
    {
      Header: headerInfoFromTranslation.name,
      accessor: 'name',
      sortType: 'string',
    },
    {
      Header: headerInfoFromTranslation.availability,
      accessor: 'availability',
    },
    {
      Header: headerInfoFromTranslation.totalAvailabilityHours,
      accessor: 'totalAvailabilityHours',
    },
    {
      Header: headerInfoFromTranslation.requiredDuration,
      accessor: 'requiredDuration',
    },
    {
      Header: headerInfoFromTranslation.noOfHirings,
      accessor: 'noOfHirings',
      isNumeric: true,
    },
    {
      Header: headerInfoFromTranslation.successfulInterviews,
      accessor: 'successfulInterviews',
      isNumeric: true,
    },
    {
      Header: headerInfoFromTranslation.interviewCancellation,
      accessor: 'interviewCancellations',
      isNumeric: true,
    },
    {
      Header: headerInfoFromTranslation.candidateNoShow,
      accessor: 'candidateNoShow',
      isNumeric: true,
    },
    {
      Header: headerInfoFromTranslation.recruiterNoShow,
      accessor: 'recruiterNoShow',
      isNumeric: true,
    },
  ];

  return (
    <>
      <RecruiterDashboardVisualisationView accountId={accountId} onSetActiveTab={onSetActiveTab} />
      <Stack backgroundColor="white" p={4}>
        {showSearchView && recruiterListData.length && (
          <Box mb={2}>
            <SearchInput
              placeholder={t('dashboardSearchInputs.searchRecruiterPlaceholder')}
              onChange={(evt: React.FormEvent) => {
                const target = evt.target as HTMLInputElement;
                setTableSearchText(target.value);
              }}
              _styleOnFocus={{ boxShadow: ' 0 0 0 2px #1f3cba' }}
              inputBoxStyle={{ background: '#F3F8FA', color: '#86A0AC', borderRadius: '6px', borderColor: '#F3F8FA' }}
              value={tableSearchText}
            />
          </Box>
        )}

        <Stack backgroundColor="white" mt={0} data-testid="tableView" style={{ height: '50vh', overflowY: 'auto' }}>
          {recruiterListData.length ? (
            <RecruiterTableView
              columns={recruiterTableColumns}
              data={recruiterListData.filter((value) =>
                value.name?.toLocaleLowerCase().includes(tableSearchText.toLocaleLowerCase()),
              )}
            />
          ) : (
            <Center style={{ display: 'flex', flexDirection: 'column' }} p={10}>
              <Spinner color="blue.500" size="lg" mb="6" />
              <Box>{t('recruiterTable.recruiterTableLoadingMessage')}</Box>
            </Center>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default RecruiterDashboardView;
