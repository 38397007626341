import React, { ChangeEvent } from 'react';
import {
  Modal,
  Button,
  useDisclosure,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Text,
  Input,
  Flex,
  useToast,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from '../../models/hooks';
import { Payload } from '../helper/foregroundNotifcation';
import { usePositionBackgroundCheckSnapshot } from '../../modules/messaging/seeker/header/actionsDropdown/usePositionBackgroundCheckSnapshot';
import { NotificationTypes } from '../../firebase/firestore/documents/notificationTypes';
import { NotificationDataType } from '../../models/notificationsModel';

export type AddVacancyModalProps = {
  notificationPayload: Payload | undefined;
};

export const AddVacancyModal = ({ notificationPayload }: AddVacancyModalProps): JSX.Element => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { t } = useTranslation('notification');

  const { updateNoOfOpenings } = useStoreActions((actions) => actions.positionManagement);

  const [isLoading, setIsLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [noOfOpenings, setNumberOfOpenings] = React.useState<number>(1);

  const clearPositionOpeningsFilled = useStoreActions((state) => state.notifications.clearPositionOpeningsFilled);
  const clearPositionClosingReminder = useStoreActions((state) => state.notifications.clearPositionClosingReminder);
  const clearPositionClosed = useStoreActions((state) => state.notifications.clearPositionClosed);

  const toast = useToast();
  const position = usePositionBackgroundCheckSnapshot(notificationPayload?.data?.positionId);

  const handleNoOfOpeningsChange = (e: ChangeEvent<HTMLInputElement>) => {
    if ((/^[0-9]*$/.exec(e.target.value) && parseInt(e.target.value, 10) < 1) || Number.isNaN(parseInt(e.target.value, 10))) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setNumberOfOpenings(parseInt(e.target.value, 10));
  };

  const clearAndClose = () => {
    if (notificationPayload?.data.code === NotificationTypes.POSITION_CLOSED) {
      clearPositionClosed();
    } else if (notificationPayload?.data.code === NotificationTypes.POSITINON_CLOSING_REMINDER) {
      clearPositionClosingReminder();
    } else {
      clearPositionOpeningsFilled();
    }
    onClose();
  };

  const updateNoOfOpeningsHandler = async () => {
    try {
      setIsLoading(true);
      // istanbul ignore else
      if (notificationPayload?.data?.additionalContextData) {
        await updateNoOfOpenings({
          accountId: (JSON.parse(notificationPayload?.data?.additionalContextData) as NotificationDataType).accountId ?? '',
          businessId: (JSON.parse(notificationPayload?.data?.additionalContextData) as NotificationDataType).businessId ?? '',
          positionId: notificationPayload?.data?.positionId as string,
          noOfOpenings: Number(position?.noOfOpenings) + Number(noOfOpenings),
        });
      }
      clearAndClose();
    } finally {
      setIsLoading(false);
      toast({
        title: t('vacancyStatusReminderModal.actionSuccessful'),
        description: t('addVacancyModal.actionSuccessfulDescription'),
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const addVacancyModalActionsHandler = () => {
    let addVacancyModalActions = (
      <ModalContent>
        <ModalHeader>{t('vacancyStatusReminderModal.addVacancy')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="row" justify="flex-start">
            <Input
              type="number"
              value={noOfOpenings}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleNoOfOpeningsChange(e)}
              htmlSize={2}
              data-testid="OpeningsInput"
              width="20%"
            />
            <Text mt={2} pl={4}>
              {t('addVacancyModal.addNoOfOpenings')}
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter mt={2}>
          <Button
            data-testid="VacancyModalAddConfirmBtn"
            disabled={isLoading || disabled}
            colorScheme="blue"
            onClick={updateNoOfOpeningsHandler}
            mr={3}
          >
            {t(`common:button.add`)}
          </Button>
          <Button data-testid="VacancyModalLaterBtn" colorScheme="red" onClick={clearAndClose}>
            {t('common:button.later')}
          </Button>
        </ModalFooter>
      </ModalContent>
    );
    if (
      position?.noOfOpenings &&
      notificationPayload?.data?.additionalContextData &&
      position?.noOfOpenings >
        ((JSON.parse(notificationPayload?.data?.additionalContextData) as NotificationDataType)?.noOfOpenings ?? 0)
    ) {
      addVacancyModalActions = (
        <ModalContent>
          <ModalHeader>{t('vacancyStatusReminderModal.addVacancy')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mt={2} pl={4}>
              {t('addVacancyModal.alreadyUpdated')}
            </Text>
          </ModalBody>
          <ModalFooter mt={2}>
            <Button data-testid="VacancyModalCloseBtn" colorScheme="red" onClick={clearAndClose}>
              {t('vacancyStatusReminderModal.okay')}
            </Button>
          </ModalFooter>
        </ModalContent>
      );
    }
    return addVacancyModalActions;
  };

  return (
    <Modal isOpen={isOpen} onClose={clearAndClose} isCentered>
      <ModalOverlay />
      {addVacancyModalActionsHandler()}
    </Modal>
  );
};
