export default {
  recruiterTable: {
    tableHeader: {
      name: 'Nom',
      availability: 'Disponibilité',
      totalAvailabilityHours: 'Disponibilité totale (minutes)',
      requiredDuration: 'Disponibilité requise (minutes)',
      noOfHirings: "Nombre d'embauches",
      successfulInterviews: 'Entrevues réussies',
      interviewCancellation: 'Entrevues annulées',
      candidateNoShow: "Candidat ne s'est pas présenté",
      recruiterNoShow: "Recruteur ne s'est pas présenté",
    },
    lowToHighString: {
      low: 'Faible',
      medium: 'Moyen',
      high: 'Haut',
      none: 'Aucun',
    },
    recruiterTableLoadingMessage: 'Chargement de la table des recruteurs...',
  },
  recruitersCandidateTable: {
    tableHeader: {
      candidateName: 'Nom',
      businessPosition: 'Poste',
      businessLocation: 'Site',
      notes: 'Notes',
      status: 'Statut',
      viewBtn: 'Ouvrir',
    },
    recruitersCandidateTableLoadingMessage: 'Chargement de la table de candidats en cours...',
    viewNotesModal: {
      noteHeader: 'Notes sur le candidat',
      closeNote: 'Fermer',
      noNotesMessage: 'Vous pouvez voir les notes du recruteur pour ce candidat ici.',
    },
  },
  dashboardSearchInputs: {
    searchRecruiterPlaceholder: 'Rechercher par nom de recruteur',
    searchCandidatePlaceholder: 'Rechercher par nom de candidat',
  },
  tabHeader: {
    dashboard: 'Dashboard',
    gotoAvailability: 'Calendrier',
    interviews: 'Entrevues',
    candidateDetails: 'Détails par candidat',
  },
  recruiterDataVisualisation: {
    uncompletedInterviews: 'Entrevues incomplètes',
    unsuccessfulInterviews: 'Entrevues échouées',
    interviewCancellation: 'Entrevues annulées',
    totalScheduled: 'Entrevues planifiées',
    totalInterviewed: 'Candidats passés en entrevue',
    totalCandidates: 'Candidats',
    totalInterviews: 'Candidats totales',
    totalAppointments: 'Somme des entrevues',
    last30Days: '30 derniers jours',
    visualisationLoadingMessage: 'Chargement du rapport...',
    videoInterviewDetails: 'Détails des entrevues',
    vsLastMonth: 'contre les derniers {{daysDifference}} jours',
    totalJobPositions: 'Postes',
    lastLogin: 'Dernière connexion',
    interviewsCompleted: 'Entrevues complétées',
    successfulInterviews: 'Entrevues réussies',
    interviewCompleted: 'Complétées',
    total: 'Total',
    upcoming: 'À venir',
    appointments: 'Entrevues',
  },
  loadingMessages: {
    loadingRecruiterMetrics: 'Loading Recruiter Metrics...',
  },
  recruiterDashboardTooltipFor: {
    totalInterviews: 'Entrevues acceptées par les candidats dans les derniers {{daysDifference}} jours.',
    upcomingInterviews: 'Entrevues cédulées dans les 7 prochains jours.',
    completedInterviews: 'Entrevues complétées avec succès dans les derniers {{daysDifference}} jours.',
    incompletedInterviews: "Entrevues où le recruteur et/ou le candidat n'ont pas participé, ainsi que les entrevues annulées.",
    canceledInterviews: 'Entrevues qui ont été annulées dans les {{daysDifference}} derniers jours.',
    candidateNoShow: "Entrevues où le candidat n'a pas participé.",
    recruiterNoShow: "Entrevues où le recruteur n'a pas participé.",
    hired: 'Candidats embauchés dans les {{daysDifference}} derniers jours.',
    barChartPercentage: "Pourcentage d'entrevues complétées avec succès comparé aux candidats actifs.",
    activeCandidatesInLast30Days: 'Candidats qui ont postulé dans les dates définies.',
    totalJobPositions: 'Postes.',
  },
};
