import _groupBy from 'lodash/groupBy';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import moment from 'moment-timezone';
import _isDate from 'lodash/isDate';
import _isString from 'lodash/isString';
import { AppointmentAvailability, AvailableBookingWithPosition } from '../../firebase/firestore/documents/appointmentAvaiability';
import { Candidate } from '../../firebase/firestore/documents/candidate';
import { getTranslatedValue } from '../../utils/localizedString';
import { CalendarScheduleTypeEnum } from './CalendarScheduleTypeEnum';
import { CalendarEventType } from './CalendarEventType';
import { CalendarAvailabilityType } from './CalendarAvailabilityType';
import { CalendarBookingType } from './CalendarBookingType';
import { Recruiter } from '../../firebase/firestore/documents/recruiter';

// eslint-disable-next-line import/no-default-export
export default class CalendarPresenter {
  private availableBookingWithPosition: AvailableBookingWithPosition[] = [];

  constructor(
    readonly availableAppointments: AppointmentAvailability[],
    readonly candidates: Candidate[],
    readonly recruiters: Recruiter[],
    readonly locale: string,
  ) {
    const groupedCandidate = _groupBy(this.candidates, 'id');

    _forEach(this.availableAppointments, (availability) => {
      _forEach(availability.bookings, (booking, id) => {
        const candidateGroup = groupedCandidate[booking.candidateId];
        if (booking && candidateGroup) {
          this.availableBookingWithPosition.push({
            booking: {
              ...booking,
              id,
              availabilityId: availability.id,
              recruiterName: this.getRecruiterFullNameOfBooking(availability.userId),
              recruiterId: availability.userId,
            },
            candidate: candidateGroup[0],
          });
        }
      });
    });
  }

  getRecruiterFullNameOfBooking = (id: string | undefined): string => {
    const selectedRecruiter = this.recruiters.filter((r) => r.id === id);
    if (selectedRecruiter.length > 0) {
      return `${selectedRecruiter[0].firstName} ${selectedRecruiter[0].lastName}`;
    }
    return '';
  };

  listBooking = (queryBy: Date | string): AvailableBookingWithPosition[] => {
    if (_isString(queryBy)) return this.listBookingByAppointmentId(queryBy);
    return this.listBookingByDate(queryBy);
  };

  listBookingByDate = (date: Date): AvailableBookingWithPosition[] => {
    return _filter(this.availableBookingWithPosition, (schedule: AvailableBookingWithPosition) => {
      return moment(schedule.booking.date).format('L') === moment(date).format('L');
    });
  };

  listBookingByAppointmentId = (id: string): AvailableBookingWithPosition[] => {
    function filterFunction(schedule: AvailableBookingWithPosition) {
      return schedule.booking.availabilityId === id;
    }

    return _filter(this.availableBookingWithPosition, filterFunction);
  };

  listBookingByAppointmentIdAndPositionsId = (id: string, positionsId: string[] | undefined): AvailableBookingWithPosition[] => {
    function filterFunction(schedule: AvailableBookingWithPosition) {
      return /* istanbul ignore next */ schedule.booking.availabilityId === id && positionsId
        ? positionsId.some((positionId) => positionId === schedule.booking.positionId)
        : false;
    }

    return _filter(this.availableBookingWithPosition, filterFunction);
  };

  listFormattedBookingForCalendar = (): CalendarEventType[] => {
    const eventListForCalendar: CalendarEventType[] = [];
    const groupedCandidate = _groupBy(this.candidates, 'id');
    _forEach(this.availableAppointments, (availability) => {
      let totalBookedTime = 0;
      let totalSchedules = 0;
      _forEach(availability.bookings, (booking) => {
        totalBookedTime += booking.duration;
        totalSchedules += 1;
      });
      eventListForCalendar.push({
        title: 'Availability',
        start: availability.fromDate,
        end: availability.toDate,
        bookingCapacity: availability.bookingCapacity,
        resource: {
          availability,
          totalSchedules,
          userId: availability.userId,
          type: CalendarScheduleTypeEnum.AVAILABILITY,
          bookedTime: moment().startOf('day').add({ minutes: totalBookedTime }).format('H:mm'),
          availabilityTime: moment
            .utc(moment(availability.toDate, 'DD/MM/YYYY HH:mm').diff(moment(availability.fromDate, 'DD/MM/YYYY HH:mm')))
            .format('HH:mm'),
        } as CalendarAvailabilityType,
      } as CalendarEventType);

      _forEach(availability.bookings, (booking, id) => {
        const candidateGroup = groupedCandidate[booking.candidateId];
        if (candidateGroup) {
          eventListForCalendar.push({
            title: getTranslatedValue(candidateGroup[0].jobTitle, this.locale),
            start: booking.date,
            end: moment(booking.date).add(booking.duration, 'minutes').toDate(),
            resource: {
              type: CalendarScheduleTypeEnum.BOOKING,
              booking: { ...booking, id },
              candidate: candidateGroup[0],
            } as CalendarBookingType,
          } as CalendarEventType);
        }
      });
    });
    return eventListForCalendar;
  };

  listFormattedAvailableBookingWithPositionForCalendar = (positionsId: string[]): CalendarEventType[] => {
    const eventListForCalendar: CalendarEventType[] = [];

    const groupedCandidate = _groupBy(this.candidates, 'id');

    const availableAppointmentsWithBooking = this.availableAppointments.filter((a) => a.bookings !== undefined);

    const availableAppointmentsWithBookingPosition: AppointmentAvailability[] = [];

    _forEach(availableAppointmentsWithBooking, (availability) => {
      _forEach(availability.bookings, (booking) => {
        const candidateGroup = groupedCandidate[booking.candidateId];
        const hasPosition = positionsId.some((positionId) => booking.positionId === positionId);
        if (hasPosition && candidateGroup) {
          availableAppointmentsWithBookingPosition.push(availability);
        }
      });
    });

    const availableAppointmentsWithBookingPositionWithoutDuplicateAvailability = availableAppointmentsWithBookingPosition.filter(
      function (value, index, array) {
        return array.indexOf(value) === index;
      },
    );

    _forEach(availableAppointmentsWithBookingPositionWithoutDuplicateAvailability, (availability) => {
      let totalBookedTime = 0;
      let totalSchedules = 0;
      _forEach(availability.bookings, (booking) => {
        totalBookedTime += booking.duration;
        totalSchedules += 1;
      });
      eventListForCalendar.push({
        title: 'Availability',
        start: availability.fromDate,
        end: availability.toDate,
        bookingCapacity: availability.bookingCapacity,
        resource: {
          availability,
          totalSchedules,
          userId: availability.userId,
          type: CalendarScheduleTypeEnum.AVAILABILITY,
          bookedTime: moment().startOf('day').add({ minutes: totalBookedTime }).format('H:mm'),
          availabilityTime: moment
            .utc(moment(availability.toDate, 'DD/MM/YYYY HH:mm').diff(moment(availability.fromDate, 'DD/MM/YYYY HH:mm')))
            .format('HH:mm'),
        } as CalendarAvailabilityType,
      } as CalendarEventType);

      _forEach(availability.bookings, (booking, id) => {
        const candidateGroup = groupedCandidate[booking.candidateId];
        const hasPosition = positionsId.some((positionId) => booking.positionId === positionId);
        /* istanbul ignore next */
        if (candidateGroup && hasPosition) {
          eventListForCalendar.push({
            title: getTranslatedValue(candidateGroup[0].jobTitle, this.locale),
            start: booking.date,
            end: moment(booking.date).add(booking.duration, 'minutes').toDate(),
            resource: {
              type: CalendarScheduleTypeEnum.BOOKING,
              booking: { ...booking, id },
              candidate: candidateGroup[0],
            } as CalendarBookingType,
          } as CalendarEventType);
        }
      });
    });

    return eventListForCalendar;
  };

  getAvailableBookingByDate = (selectedDate: Date | string): AvailableBookingWithPosition[] => {
    if (_isDate(selectedDate)) {
      return this.listBookingByDate(selectedDate);
    }

    return this.listBookingByAppointmentId(selectedDate);
  };
}
