import React, { ReactText } from 'react';
import _groupBy from 'lodash/groupBy';
import _isNil from 'lodash/isNil';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  CheckboxGroup,
  Heading,
  Stack,
} from '@chakra-ui/core';
import _forEach from 'lodash/forEach';
import { Position } from '../../../firebase/firestore/documents/position';

export type RecruiterListProps = {
  searchText: string;
  positions: Position[] | undefined;
  handleCheckedPositions: (recruiterIds: ReactText[]) => void;
  checkedPositions?: string[];
};

export type BusinessWithPositionList = {
  [key: string]: Position[];
};

export const RecruiterCalendarPositionList = ({
  searchText,
  positions,
  handleCheckedPositions,
  checkedPositions,
}: RecruiterListProps): JSX.Element => {
  const [accordionIndex, setAccrodionIndex] = React.useState<number[]>([0]);

  const positionListWithDuplicateNameHandle = (locationPositions: Position[]): Position[] => {
    locationPositions.forEach((item) => {
      // Find all related objects
      const matches = locationPositions.filter((el) => el.customName === item.customName);
      for (let n = 1; n < matches.length; n += 1) {
        const suffix = ` (${n})`;
        matches[n].customName += suffix;
      }
    });

    return locationPositions;
  };

  const positionsGroupedByBusinessLocation = () => {
    if (!_isNil(positions)) {
      const filteredPositions = positions
        .filter(
          (p) =>
            p.customName !== undefined && p.customName !== '' && p.customName.toLowerCase().includes(searchText.toLowerCase()),
        )
        .sort((a, b) =>
          /* istanbul ignore next */
          // eslint-disable-next-line no-nested-ternary
          !!b.customName && !!a.customName ? (a?.customName.toLowerCase() > b?.customName.toLowerCase() ? 1 : -1) : -1,
        );

      const unorderedGroupedByBusiness: BusinessWithPositionList = _groupBy(filteredPositions, (position) => {
        return position.businessName.toLowerCase();
      });

      const orderedBusinessWithPositionList: BusinessWithPositionList = Object.keys(unorderedGroupedByBusiness)
        .sort()
        .reduce((obj: BusinessWithPositionList, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key.toLowerCase()] = unorderedGroupedByBusiness[key];
          return obj;
        }, {});

      const recruitersCheckboxList: JSX.Element[] = [];
      let count = 0;

      _forEach(orderedBusinessWithPositionList, (groupedPositions) => {
        const recruiterAccordionItem: JSX.Element[] = [];
        const recruiterAccordionPanel: JSX.Element[] = [];

        const hasCheckedPosition = groupedPositions.some((p) => checkedPositions?.includes(p.id));
        if (hasCheckedPosition) {
          if (!accordionIndex.includes(count)) {
            setAccrodionIndex([...accordionIndex, count]);
          }
        }

        recruiterAccordionItem.push(
          <AccordionButton
            p={0}
            _focus={{
              boxShadow: 'none',
            }}
            key={groupedPositions[0].businessName.replace(/ /g, '')}
          >
            <Box flex="1" textAlign="left">
              <Heading py={2} as="h4" size="sm">
                {groupedPositions[0].businessName}
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>,
        );

        positionListWithDuplicateNameHandle(groupedPositions).map((position, index) =>
          recruiterAccordionPanel.push(
            <Checkbox
              d="flex"
              colorScheme="blue"
              key={position.id}
              data-testid={`Checkbox-${position.id}`}
              value={position.id}
              mb={groupedPositions.length === index + 1 ? '3' : '0'}
            >
              {`${position.customName}`}
            </Checkbox>,
          ),
        );

        recruiterAccordionItem.push(
          <AccordionPanel p={0} key={`${groupedPositions[0].businessName.replace(/ /g, '')}-panel`}>
            <Stack
              className="location-wise-position-stack"
              pt={3}
              borderTopWidth="1px"
              borderTopColor="gray.100"
              spacing={[1, 2]}
            >
              {recruiterAccordionPanel}
            </Stack>
          </AccordionPanel>,
        );
        recruitersCheckboxList.push(
          <AccordionItem
            borderTopWidth="1px"
            borderTopColor="gray.50"
            borderBottomColor="gray.50"
            key={`${groupedPositions[0].businessName.replace(/ /g, '')}-item`}
          >
            {recruiterAccordionItem}
          </AccordionItem>,
        );

        count += 1;
      });
      return recruitersCheckboxList;
    }
    return '';
  };

  return (
    <CheckboxGroup value={checkedPositions} onChange={(data) => handleCheckedPositions(data)}>
      <Stack data-testid="RecruiterPositionList" className="recruiter-stack" p={[5, 4]} spacing={[1, 2]}>
        <Accordion defaultIndex={accordionIndex} allowMultiple>
          {positionsGroupedByBusinessLocation()}
        </Accordion>
      </Stack>
    </CheckboxGroup>
  );
};
