import { css } from '@emotion/core';
import colors from '../../../../../styles/colors';
import { transitionSpeed } from '../../../../../styles/sizes';

export const menuButtonPositionCSS = css`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: background-color ${transitionSpeed}, border-color ${transitionSpeed}, color ${transitionSpeed};
  border: 1px solid transparent;

  background-color: ${colors.white};
  border-color: ${colors.gray['100']};
  color: ${colors.gray['700']};
  min-width: 250px;

  .chakra-ui-dark & {
    background-color: ${colors.gray['700']};
    border-color: ${colors.gray['800']};
    color: ${colors.white};
  }

  &.is-opened {
    background-color: ${colors.neutralGray['50']};
    border-color: ${colors.gray['300']};
    color: ${colors.gray['700']};

    .chakra-ui-dark & {
      background-color: ${colors.gray['800']};
      border-color: ${colors.gray['900']};
      color: ${colors.white};
    }
  }
`;

// (If any box-shadow is present, clip-path inset negative values)
export const menuListPositionCSS = css`
  max-height: calc(100vh - 9rem);
  overflow-y: auto;
  //transform: translateX(-50px) translateY(0) !important;
  //clip-path: inset(3px -5px -5px -5px);

  .chakra-menu__menuitem:last-child {
    border-bottom: 0 !important;
  }
`;
