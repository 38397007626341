export default {
  pageTitle: 'Offres & embauches en cours de traitement',
  toDoBreadCrumb: 'Revenir à la liste de tâches',
  offerAndHireStatus: "Statut de l'offre/embauche:",
  noDataFound: 'Aucune donnée trouvée',
  km: 'KM',

  filterOption: {
    all: 'Tous',
    hiresProcessing: 'Embauches en cours',
    hired: 'Embauché',
    offersProcessing: 'Offres en cours',
    offersRejected: 'Offres refusées',
    offersRevoked: 'Offres révoquées',
  },
  offerAndHireProcessTableDetails: {
    header: {
      locationAndPosition: 'Emplacement & Poste',
      candidate: 'Candidat',
      requisitionId: 'Identifiant de la réquisition',
      commuteData: 'Temps de déplacement',
      currentStatus: 'Statut actuel',
    },
  },
};
