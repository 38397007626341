/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Text } from '@chakra-ui/core';
import { Participant } from 'twilio-video';
import _isNil from 'lodash/isNil';
import { useMainParticipant } from './twilio/hooks/useMainParticipant';
import { useMeetContext } from './MeetContext';
import { ParticipantTrack } from './ParticipantTrack';
import { MediaTypeEnum } from './MediaTypeEnum';
import { mainParticipantStyle } from './MeetViewStyle';
import { ReactComponent as _IconVideo } from '../../assets/img/icon-video.svg';
import { VideoRooms } from '../../firebase/firestore/documents/videoRooms';

const IconVideo = memo(_IconVideo);

export type MainParticipantProps = {
  mediaType: MediaTypeEnum;
  screenShareParticipant?: Participant;
  roomInfo?: VideoRooms;
};

export const MainParticipant = ({ mediaType, screenShareParticipant, roomInfo }: MainParticipantProps): JSX.Element => {
  const { t } = useTranslation('meet');
  const { room } = useMeetContext();
  const mainParticipant = useMainParticipant();
  const roomParticipantWithName = roomInfo ? roomInfo.participants : [];

  const getParticipantName = (identity: string) => {
    return roomParticipantWithName?.find((pp) => pp.userId === identity)?.fullName;
  };

  const getParticipantRole = (identity: string) => {
    return !roomParticipantWithName?.find((pp) => pp.userId === identity)?.isSeeker;
  };
  const isVideoEnabled = (identity: string) => {
    const isSeeker = getParticipantRole(identity);
    if (!isSeeker) {
      return roomInfo?.seekerVideoEnabled;
    }
    return false;
  };

  const isMicEnabled = (identity: string) => {
    const isSeeker = getParticipantRole(identity);
    if (!isSeeker) {
      return roomInfo?.seekerMicEnabled;
    }
    return false;
  };

  if (!room) return <Box data-testid="RoomNotAvailable">Room not available</Box>;

  const videoPriority = 'standard';

  return (
    <Box css={mainParticipantStyle} className="main-participant">
      {_isNil(mainParticipant) ||
        (room.localParticipant === mainParticipant && _isNil(screenShareParticipant) && (
          <Center data-testid="WaitingForCandidate" className="waiting-block">
            <Box align="center">
              <IconVideo data-testid="VideoEnabledIcon" color="#ffffff" />
              <Text fontSize="xl" color="#ffffff">
                {t('waitingForCandidate')}
              </Text>
            </Box>
          </Center>
        ))}
      {!_isNil(mainParticipant) && (
        <ParticipantTrack
          participant={!_isNil(screenShareParticipant) ? screenShareParticipant : (mainParticipant as Participant)}
          mediaType={mediaType}
          videoPriority={videoPriority}
          showScreenShare={!_isNil(screenShareParticipant)}
          participantName={getParticipantName(mainParticipant?.identity)}
          isRecruiter={getParticipantRole(mainParticipant?.identity)}
          isVideoEnabled={isVideoEnabled(mainParticipant.identity)}
          isMicEnabled={isMicEnabled(mainParticipant.identity)}
        />
      )}
    </Box>
  );
};
