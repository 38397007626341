/* istanbul ignore file */
// import { useEffect, useState } from 'react';
import React, { useEffect } from 'react';
import _isNil from 'lodash/isNil';
import { Participant, Room } from 'twilio-video';
import { useParticipants } from './twilio/hooks/useParticipants';
import { AllMeetParticipant, MeetParticipantResponse, MeetRemoteParticipantInfo } from './MeetParticipantResponse';
import { useStoreActions, useStoreState } from '../../models/hooks';

export const useMeetParticipants = (room: Room): AllMeetParticipant => {
  const { localParticipant } = room;

  const [meetParticipants, setMeetParticipants] = React.useState<MeetParticipantResponse | null>();
  const groupEventDetail = useStoreState((state) => state.meet.groupEventDetail);
  const { getMeetParticipants } = useStoreActions((actions) => actions.meet);

  const participants = useParticipants();

  useEffect(() => {
    if (groupEventDetail && groupEventDetail.groupEventId && groupEventDetail.participant) {
      getMeetParticipants({
        groupEventId: groupEventDetail.groupEventId,
        participantId: groupEventDetail.participant.id,
      })
        .then((response) => {
          setMeetParticipants(response);
        })
        .finally(() => {});
    }
  }, [getMeetParticipants, groupEventDetail, participants]);

  if (_isNil(meetParticipants)) {
    return {
      filteredMeetParticipant: [],
      allMeetParticipants: [],
    };
  }

  const { recruiters, candidates } = meetParticipants;

  const allMeetParticipants = [...recruiters, ...candidates];

  const filteredMeetParticipant: MeetRemoteParticipantInfo[] = [];
  const lParticipant = localParticipant as Participant;

  const localParticipantData = allMeetParticipants.find(
    (meetParticipant) => meetParticipant.seekerId === lParticipant.identity || meetParticipant.id === lParticipant.identity,
  );

  if (!_isNil(localParticipantData)) {
    filteredMeetParticipant.push({
      participant: lParticipant,
      participantInfo: localParticipantData,
    } as MeetRemoteParticipantInfo);
  }

  participants.forEach((p) => {
    const remoteParticipant = allMeetParticipants.find(
      (meetParticipant) => meetParticipant.seekerId === p.identity || meetParticipant.id === p.identity,
    );
    if (!_isNil(remoteParticipant)) {
      filteredMeetParticipant.push({
        participant: p,
        participantInfo: remoteParticipant,
      } as MeetRemoteParticipantInfo);
    }
  });

  const meetParticiantsData = {
    filteredMeetParticipant,
    allMeetParticipants,
  };

  return meetParticiantsData;
};
