import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';

export class AccountLevelConfigurationController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async addUnresponsivePeriod(
    unresponsiveDismissPeriod: number,
    unresponsiveAgedPeriod: number,
    accountID: string,
  ): Promise<AxiosResponse> {
    const reqObj = {
      configuration: {
        unresponsiveAgedPeriod,
        unresponsiveDismissPeriod,
      },
    };
    const path = `api/v1/accounts/${accountID}/configuration`;
    return this.httpClient.put(path, reqObj);
  }
}
