import firebase from 'firebase/app';
import { Action, action, Thunk, thunk } from 'easy-peasy';
import firebaseInstance from '../../../firebase/Firebase';

export type AuthStoreModel = {
  auth: AuthModel;
};

export interface AuthModel {
  initialized: boolean;
  initialize: Thunk<AuthModel>;
  finalize: Thunk<AuthModel>;
  onAuthStateChangeUnsubscribe?: firebase.Unsubscribe;
  setOnAuthStateChangeUnsubscribe: Action<AuthModel, firebase.Unsubscribe | undefined>;
  firebaseUser?: firebase.User | null;
  setFirebaseUser: Action<AuthModel, firebase.User | null>;
  confirmationResult?: firebase.auth.ConfirmationResult;
  setConfirmationResult: Action<AuthModel, firebase.auth.ConfirmationResult>;
  signInWithPhoneNumber: Thunk<AuthModel, { phoneNumber: string; appVerifier: firebase.auth.RecaptchaVerifier }>;
  confirmPhoneNumber: Thunk<AuthModel, string>;
  signIn: Thunk<AuthModel, { email: string; password: string }>;
  signInWithAnonymously: Thunk<AuthModel>;
  signOut: Thunk<AuthModel, void, void, AuthStoreModel, Promise<void>>;
}

const authModel: AuthModel = {
  initialized: false,
  confirmationResult: undefined,
  onAuthStateChangeUnsubscribe: undefined,
  firebaseUser: undefined,

  setOnAuthStateChangeUnsubscribe: action((state, payload) => {
    state.onAuthStateChangeUnsubscribe = payload;
  }),

  setFirebaseUser: action((state, payload) => {
    state.initialized = true;
    state.firebaseUser = payload;
  }),

  setConfirmationResult: action((state, payload) => {
    state.confirmationResult = payload;
  }),

  initialize: thunk((actions) => {
    const unsubscribe = firebaseInstance.onAuthStateChanged((authUser) => {
      actions.setFirebaseUser(authUser);
    });
    actions.setOnAuthStateChangeUnsubscribe(unsubscribe);
  }),

  finalize: thunk((actions, payload, { getState }) => {
    const { onAuthStateChangeUnsubscribe } = getState();

    if (onAuthStateChangeUnsubscribe) {
      onAuthStateChangeUnsubscribe();
    }
    actions.setOnAuthStateChangeUnsubscribe(undefined);
  }),

  signInWithPhoneNumber: thunk(async (actions, payload) => {
    const { phoneNumber, appVerifier } = payload;
    const confirmationResult = await firebaseInstance.signInWithPhoneNumber(phoneNumber, appVerifier);
    actions.setConfirmationResult(confirmationResult);
  }),

  confirmPhoneNumber: thunk(async (actions, payload, { getState }) => {
    await getState().confirmationResult?.confirm(payload);
  }),

  signIn: thunk(async (actions, payload) => {
    await firebaseInstance.singInWithEmailAndPassword(payload.email, payload.password);
  }),

  signInWithAnonymously: thunk(async () => {
    await firebaseInstance.signInWithAnonymously();
  }),

  signOut: thunk(async (): Promise<void> => firebaseInstance.signOut()),
};

export default authModel;
