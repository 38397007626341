import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Center,
  AvatarBadge,
  Text,
  Divider,
  Input,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  useToast,
  CircularProgress,
  Badge,
  Tooltip,
} from '@chakra-ui/core';
import { AiOutlineCamera } from 'react-icons/ai';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import Bugsnag from '@bugsnag/js';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import colors from '../../../styles/colors';
import {
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
  updateObject,
  clearErrorMessage,
} from '../../../utils/FormErrorUtils';
import { profileStyle } from '../ProfileStyle';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { ProfilePicture } from '../../common';
import { UserProfileType } from '../ProfileTypes';
import PreferenceDial from './PreferenceDial';
import useMaxCandidateLimit from '../../../app/hooks/useMaxCandidateLimit';

const DEFAULT_PREFERENCE_DAYS = 3;

const deafultUserProfile: UserProfileType = {
  firstName: '',
  lastName: '',
  email: '',
  filterCandidateByDays: DEFAULT_PREFERENCE_DAYS,
};
type MyProfileViewProps = {
  picture: string | undefined;
  onClose: () => void;
};

export const MyProfileView = ({ picture, onClose }: MyProfileViewProps): JSX.Element => {
  const { t } = useTranslation('profile');
  const { user } = useStoreState((s) => s.app);
  const [userProfile, setUserProfile] = useState<UserProfileType>(deafultUserProfile);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [updatingProfile, setUpdatingProfile] = useState<boolean>(false);
  const [updatingProfilePicture, setUpdatingProfilePicture] = useState<boolean>(false);
  const [profilePicture, setProfilePicture] = useState<string>(picture || '');
  const setLocalProfileImageUrl = useStoreActions((a) => a.app.setRecruiterProfileImage);
  const maxCandidateLimit = useMaxCandidateLimit();
  const [candidateNumberResponse, setCandidateNumberResponse] = useState<number>();
  const isMaximumLimit = Boolean(candidateNumberResponse && maxCandidateLimit && candidateNumberResponse > maxCandidateLimit);

  const showCandidateCount =
    candidateNumberResponse && maxCandidateLimit && candidateNumberResponse > maxCandidateLimit ? (
      <>
        <Tooltip label={t('approxCandidateTooltipMsg')} bg="#EDF2F7" color="black" placement="top" rounded="md">
          <Badge variant="solid" ml="1" colorScheme="red" rounded="sm">
            {`${maxCandidateLimit} +`}
          </Badge>
        </Tooltip>
      </>
    ) : (
      <>
        <Tooltip label={t('approxCandidateTooltipMsg')} bg="#EDF2F7" color="black" placement="top" rounded="md">
          <Badge variant="solid" ml="1" colorScheme="blue" rounded="sm">
            {candidateNumberResponse}
          </Badge>
        </Tooltip>
      </>
    );

  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { updateProfile, uploadProfilePicture } = useStoreActions((actions) => actions.profileManagement);

  useEffect(() => {
    if (user) {
      updateObject(userProfile, 'firstName', user.firstName, false);
      updateObject(userProfile, 'lastName', user.lastName, false);
      updateObject(userProfile, 'email', user.email, false);
      updateObject(userProfile, 'filterCandidateByDays', user.filterCandidateByDays || DEFAULT_PREFERENCE_DAYS, false);
      setUserProfile({ ...userProfile });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const uploadTrigger = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      const actFileSize = Math.round(file.size / 1024);

      if (!(file.type === 'image/png' || file.type === 'image/jpeg')) {
        toast({
          title: t('profileImageFormatFail'),
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        // this line right below will reset the
        // input field so if you removed it you can re-add the same file
        /* istanbul ignore else */
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }
      if (actFileSize > 1024) {
        toast({
          title: t('profileImageSizeFail'),
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        // this line right below will reset the
        // input field so if you removed it you can re-add the same file
        /* istanbul ignore else */
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }
      setProfilePicture(URL.createObjectURL(file));
      if (user) {
        const filename = `${user.id}.png`;
        try {
          setUpdatingProfilePicture(true);
          await uploadProfilePicture({ fileName: filename, profilePicture: file });
          setLocalProfileImageUrl(URL.createObjectURL(file));
          setUpdatingProfilePicture(false);
          toast({
            title: t('profilePictureUpdated'),
            status: 'info',
            duration: 3000,
            isClosable: true,
          });
        } catch (e) {
          setLocalProfileImageUrl(picture || '');
          setProfilePicture(picture || '');
          setUpdatingProfilePicture(false);
          Bugsnag.notify(e);
          toast({
            title: t('profilePictureUpdateFail'),
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      lastName: Joi.string()
        .trim()
        .required()
        .min(2)
        .max(30)
        .messages({
          'string.empty': t('lastNameRequired'),
          'string.min': t('lastNameMin'),
          'string.max': t('lastNameMax'),
        }),
      firstName: Joi.string()
        .trim()
        .required()
        .min(2)
        .max(30)
        .messages({
          'string.empty': t('firstNameRequired'),
          'string.min': t('firstNameMin'),
          'string.max': t('firstNameMax'),
        }),
      email: Joi.string()
        .required()
        .email({ tlds: { allow: false } })
        .messages({
          'string.empty': t('emailNameRequired'),
          'string.email': t('emailFormatIncorrect'),
        }),
      filterCandidateByDays: Joi.number().required(),
    });

  const formValidation = (formData: UserProfileType) => {
    const { error } = schema.validate(formData);
    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | string, id?: string) => {
    // istanbul ignore else
    if (_isString(event) && id === 'filterCandidateByDays') {
      updateObject(userProfile, id, parseInt(event, 10), false);
      setFormErrors(clearErrorMessage(formErrors, id));
    } else if (_isObject(event) && 'target' in event) {
      updateObject(userProfile, event.target.id, event.target.value, false);
      setFormErrors(clearErrorMessage(formErrors, event.target.id));
    }

    setUserProfile({ ...userProfile });
  };

  const onUpdateProfile = async () => {
    const validate = formValidation(userProfile);

    if (validate && user) {
      try {
        setUpdatingProfile(true);
        await updateProfile({ userID: user.id, userProfile });
        setUpdatingProfile(false);
        toast({
          title: t('profileUpdatedSuccessfully'),
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } catch (e) {
        Bugsnag.notify(e);
        setUpdatingProfile(false);
        toast({
          title: t('profileUpdatedFailed'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };
  const isRequsitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);
  return (
    <Box css={profileStyle}>
      <Center>
        <ProfilePicture
          data-testid="UserProfilePicture"
          name={userProfile?.firstName}
          style={{ backgroundColor: colors.blue.default, color: 'white' }}
          src={profilePicture}
          width="80px"
          height="80px"
          className="profilePicture"
          onClick={() => {
            /* istanbul ignore else */
            if (!updatingProfilePicture) {
              fileInputRef.current?.click();
            }
          }}
        >
          <AvatarBadge boxSize="2em" bg={colors.profile.profileBatchColor}>
            {updatingProfilePicture ? (
              <CircularProgress isIndeterminate />
            ) : (
              <AiOutlineCamera color={colors.profile.cameraIconColor} />
            )}
          </AvatarBadge>
        </ProfilePicture>
        <Box display="none">
          <input type="file" onChange={uploadTrigger} ref={fileInputRef} accept="image/*" data-testid="profileImageInput" />
        </Box>
      </Center>

      <Center>
        <Text paddingTop="1rem" fontSize="14px" fontWeight={700} color={colors.orange[500]}>
          {`${userProfile.firstName} ${userProfile.lastName}`}
        </Text>
      </Center>
      <Box marginTop="1rem">
        <Text fontSize="14px" fontWeight={700}>
          {t('personalInformation')}
        </Text>
        <Divider />
        <Flex>
          <Box paddingTop="1rem" w="50%" marginRight=".5rem">
            <FormControl isInvalid={hasErrorMessage(formErrors, 'firstName')} isRequired>
              <FormLabel htmlFor="address">{t('firstName')}</FormLabel>
              <Input
                data-testid="firstNameTextField"
                variant="filled"
                id="firstName"
                type="text"
                size="lg"
                value={userProfile.firstName}
                onChange={handleChange}
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'firstName')}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box paddingTop="1rem" w="50%" marginLeft=".5rem">
            <FormControl isInvalid={hasErrorMessage(formErrors, 'lastName')} isRequired>
              <FormLabel htmlFor="address">{t('lastName')}</FormLabel>
              <Input
                data-testid="lastNameTextField"
                variant="filled"
                id="lastName"
                type="text"
                size="lg"
                value={userProfile.lastName}
                onChange={handleChange}
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'lastName')}</FormErrorMessage>
            </FormControl>
          </Box>
        </Flex>
        <Flex>
          <Box paddingTop="1rem" w="50%" marginRight=".5rem">
            <FormControl isInvalid={hasErrorMessage(formErrors, 'email')} isRequired>
              <FormLabel htmlFor="address">{t('email')}</FormLabel>
              <Input
                data-testid="emailNameTextField"
                variant="filled"
                id="email"
                type="text"
                size="lg"
                value={userProfile.email}
                onChange={handleChange}
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'email')}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box paddingTop="1rem" w="50%" marginLeft=".5rem" />
        </Flex>
      </Box>
      {isRequsitionEnabled && (
        <Box marginTop="1rem">
          <Text fontSize="14px" fontWeight={700}>
            {t('preferences')}
          </Text>
          <Divider />
          <Flex mt="1rem" alignItems="center">
            <Box w="50%" marginRight=".5rem">
              <Text fontSize="1rem" fontWeight={500}>
                {t('filterCandidatesByDays')}
                {showCandidateCount}
              </Text>
            </Box>
            <Box w="50%" marginLeft=".5rem">
              <FormControl>
                <PreferenceDial
                  defaultValue={userProfile && userProfile?.filterCandidateByDays}
                  onHandleChange={handleChange}
                  setCandidateNumberResponse={setCandidateNumberResponse}
                />
              </FormControl>
            </Box>
          </Flex>
          {isMaximumLimit && (
            <Flex>
              <Box w="50%" marginRight=".5rem" />
              <Text
                color={colors.red[500]}
                textAlign="center"
                mt={2}
                fontSize="14px"
                fontWeight={700}
                w="50%"
                marginLeft=".5rem"
                data-testid="maxLimitErrorMessage"
              >
                {t('maxLimitErrorMessage')}
              </Text>
            </Flex>
          )}
        </Box>
      )}

      <Flex justifyContent="end" marginTop="1rem">
        <Button data-testid="updateProfileBtn" colorScheme="blue" isLoading={updatingProfile} onClick={onUpdateProfile}>
          {t('update')}
        </Button>
      </Flex>
    </Box>
  );
};
