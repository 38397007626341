import { CSSProperties } from 'react';
import { CandidateStatus } from '../firebase/firestore/documents/candidate';
import colors from '../styles/colors';

export const getColorForState = (status?: CandidateStatus): CSSProperties => {
  switch (status) {
    case CandidateStatus.Dismissed:
      return { color: 'red' };
    case CandidateStatus.ContactRequested:
      return { color: 'gray' };
    case CandidateStatus.Bookmarked:
      return { color: 'gray' };
    default:
      return { color: colors.blue.default };
  }
};

export const secondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (hours !== 0) {
    return `${hours} hrs ${minutes} min`;
  }

  return `${minutes} min`;
};
export const convertTimeToNumber = (time: string) => {
  const [hoursStr, minutesStr] = time.split(':');
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  let number = hours * 4;

  if (minutes !== 0) {
    number += 2;
  }

  return number;
};
