/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useRef } from 'react';
import { AudioTrack as IAudioTrack } from 'twilio-video';
import styled from '@emotion/styled';
import { useMeetContext } from '../MeetContext';

const Audio = styled('audio')();

type AudioTrackProps = {
  track: IAudioTrack;
};

export const AudioTrack = ({ track }: AudioTrackProps): JSX.Element | null => {
  const { activeSinkId } = useMeetContext();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = useRef<HTMLAudioElement>(null!);

  useEffect(() => {
    ref.current = track.attach();
    ref.current.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(ref.current);
    return () =>
      track.detach().forEach(
        // istanbul ignore next
        (el) => el.remove(),
      );
  }, [track]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    ref.current?.setSinkId?.(activeSinkId);
  }, [activeSinkId]);

  return <Audio ref={ref} data-testid="AudioView" />;
};
