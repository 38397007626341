/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint-disable-next-line max-classes-per-file
import { FirestoreOperation } from '../firestoreOperation';
import { Criteria, LimitCriteria, OrderByDirection, OrderCriteria, WhereCriteria } from './criteria';

export class Query {
  public static field<T>(field: keyof T): Query;
  public static field(field: string): Query;
  public static field(...fields: string[]): Query {
    return new Query(fields.join('.'));
  }

  public static limit(limit: number): Criteria {
    return new LimitCriteria(limit);
  }

  public static orderBy<T>(fieldPath: keyof T, direction: OrderByDirection): Query;
  public static orderBy(fieldPath: string, direction: OrderByDirection = OrderByDirection.asc): Criteria {
    return new OrderCriteria(fieldPath, direction);
  }

  private constructor(private readonly field: string) {}

  public equals(value: unknown): Criteria {
    return new WhereCriteria(this.field, FirestoreOperation.EQUAL, value);
  }

  public smallerThan(value: unknown): Criteria {
    return new WhereCriteria(this.field, FirestoreOperation.SMALLER, value);
  }

  public smallerThanOrEqual(value: unknown): Criteria {
    return new WhereCriteria(this.field, FirestoreOperation.SMALLER_OR_EQUAL, value);
  }

  public greaterThan(value: unknown): Criteria {
    return new WhereCriteria(this.field, FirestoreOperation.GREATER, value);
  }

  public greaterThanOrEqual(value: unknown): Criteria {
    return new WhereCriteria(this.field, FirestoreOperation.GREATER_OR_EQUAL, value);
  }

  public arrayContains(value: unknown): Criteria {
    return new WhereCriteria(this.field, FirestoreOperation.ARRAY_CONTAINS, value);
  }

  public in(value: unknown[]): Criteria {
    return new WhereCriteria(this.field, FirestoreOperation.IN, value);
  }
}
