import { css } from '@emotion/core';
import colors from '../../../../../../styles/colors';

export const chatMessageCSS = css`
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
  white-space: pre-line;

  &.is-pin-right {
    border-top-right-radius: 0;
    word-break: break-word;
    background-color: ${colors.blue.default};

    .chakra-ui-dark & {
      background-color: ${colors.blue.default};
    }

    &.is-stranger {
      background-color: ${colors.blue.alpha700};

      .chakra-ui-dark & {
        background-color: ${colors.blue.alpha700};
      }
    }
  }

  &.is-pin-left {
    border-top-left-radius: 0;
    word-break: break-word;
    background-color: ${colors.lime};

    .chakra-ui-dark & {
      background-color: ${colors.lime};
    }
  }

  &.is-pin-left:last-child {
    margin-bottom: 22px;
  }

  &.is-pin-right:last-child {
    margin-bottom: 22px;
  }
`;
