import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
} from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _isNil from 'lodash/isNil';
import { RiPhoneFill, RiUserVoiceFill, RiVidiconFill } from 'react-icons/ri';
import i18next from 'i18next';
import { FormattedError, getErrorMessage, hasErrorMessage } from '../../../../utils/FormErrorUtils';
import { IndustryRadiosStyle, InterViewDurationStyle } from './CreateJobPositionStyle';
import colors from '../../../../styles/colors';
import { JobPosition } from '../PositionType';
import { getTranslatedValue } from '../../../../utils/localizedString';

const interviewTypes = [
  { label: { en: 'In Person', fr: 'Sur place' }, value: 'inPerson' },
  { label: { en: 'Video', fr: 'Vidéo' }, value: 'video' },
  { label: { en: 'Phone', fr: 'Téléphone' }, value: 'phone' },
];

const loadInterviewIcon = (iconType: string) => {
  switch (iconType) {
    case 'inPerson':
      return <RiUserVoiceFill style={{ color: colors.blue[500], width: '28px', height: '28px' }} />;
    case 'video':
      return <RiVidiconFill style={{ color: colors.blue[500], width: '28px', height: '28px' }} />;
    default:
      return <RiPhoneFill style={{ color: colors.blue[500], width: '28px', height: '28px' }} />;
  }
};

const loadBusinessAddress = (address: string | undefined) => {
  if (!_isNil(address)) {
    return address.split('→')[1];
  }
  return address;
};

export type InterviewDetailProp = {
  jobPositionData: JobPosition;
  formErrors: FormattedError[];
  isInterviewAndBusinessLocationSame: boolean;
  handleChange: (value: string, fieldName: string, dataType: boolean) => void;
  handleChangeInterviewLocation: (value: boolean) => void;
};

export const InterviewDetail = ({
  jobPositionData,
  formErrors,
  isInterviewAndBusinessLocationSame,
  handleChange,
  handleChangeInterviewLocation,
}: InterviewDetailProp): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box>
      <Flex mb={6}>
        <Box w="55%" mr={4}>
          <FormControl isInvalid={hasErrorMessage(formErrors, 'interview.interviewType')} isRequired>
            <FormLabel htmlFor="interviewType">{t('createJobPosition:interviewType')}</FormLabel>
            <RadioGroup
              name="interviewType"
              id="interviewTypeLabel"
              css={IndustryRadiosStyle}
              value={jobPositionData.interview.interviewType}
              colorScheme="blue"
              data-testid="interviewType"
            >
              <Flex direction="row" wrap="wrap" justify="flex-start">
                {interviewTypes.map((interviewType) => {
                  return (
                    <Radio
                      onChange={(e) => handleChange(e.target.value, 'interview.interviewType', false)}
                      value={interviewType.value}
                      key={interviewType.value}
                      mr={2}
                      mt={0}
                      data-testid={`InterviewType-${interviewType.value}`}
                    >
                      <Box>{loadInterviewIcon(interviewType.value)}</Box>
                      {getTranslatedValue(interviewType.label, i18next.language)}
                    </Radio>
                  );
                })}
              </Flex>
            </RadioGroup>
            <FormErrorMessage>{getErrorMessage(formErrors, 'interview.interviewType')}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box w="45%">
          <FormControl isInvalid={hasErrorMessage(formErrors, 'interview.interviewDuration')} isRequired>
            <FormLabel htmlFor="interviewDuration">{t('createJobPosition:interviewDuration')}</FormLabel>
            <RadioGroup
              css={InterViewDurationStyle}
              value={
                !_isNil(jobPositionData.interview.interviewDuration) ? jobPositionData.interview.interviewDuration.toString() : ''
              }
            >
              <Stack direction="row" flexWrap="wrap">
                {['10', '15', '30', '60'].map((interviewDuration) => {
                  return (
                    <Radio
                      key={interviewDuration}
                      value={interviewDuration}
                      data-testid={`Duration-${interviewDuration}`}
                      onChange={(e) => handleChange(e.target.value, 'interview.interviewDuration', true)}
                    >
                      {`${interviewDuration} min.`}
                    </Radio>
                  );
                })}
              </Stack>
            </RadioGroup>
            <FormErrorMessage>{getErrorMessage(formErrors, 'interview.interviewDuration')}</FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      {jobPositionData.interview.interviewType === 'inPerson' && (
        <Flex mb={6}>
          <Box w="50%" mr={6}>
            <FormControl>
              <Checkbox
                isDisabled={jobPositionData.business === ''}
                isChecked={isInterviewAndBusinessLocationSame}
                onChange={(e) => handleChangeInterviewLocation(e.target.checked)}
                id="businessLocation"
                data-testid="ChangedBusinessLocationChecked"
              >
                {t('createJobPosition:interviewLocation')}
              </Checkbox>
              <FormLabel mt={3} mb={0}>
                <Input
                  isReadOnly
                  variant="filled"
                  disabled={!isInterviewAndBusinessLocationSame}
                  value={isInterviewAndBusinessLocationSame ? loadBusinessAddress(jobPositionData?.businessAddress) : ''}
                  type="text"
                />
              </FormLabel>
            </FormControl>
          </Box>
          <Box w="50%">
            <FormControl
              isInvalid={hasErrorMessage(formErrors, 'interview.interviewLocation')}
              isRequired={jobPositionData.interview.interviewType === 'inPerson' && !isInterviewAndBusinessLocationSame}
            >
              <FormLabel htmlFor="otherLocation">{t('createJobPosition:interviewOtherLocation')}</FormLabel>
              <Input
                variant="filled"
                id="otherLocation"
                type="text"
                value={isInterviewAndBusinessLocationSame ? '' : jobPositionData.interview.interviewLocation}
                isDisabled={isInterviewAndBusinessLocationSame}
                data-testid="InterviewLocation"
                onChange={(e) => handleChange(e.target.value, 'interview.interviewLocation', false)}
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'interview.interviewLocation')}</FormErrorMessage>
            </FormControl>
          </Box>
        </Flex>
      )}
      <Box>
        <FormControl isInvalid={hasErrorMessage(formErrors, 'interview.interviewMessage')}>
          <FormLabel htmlFor="interviewMessage">{t('createJobPosition:interviewMessage')}</FormLabel>
          <Textarea
            variant="filled"
            id="interviewMessage"
            placeholder={t('createJobPosition:interviewDescriptionPlaceholder')}
            data-testid="InterviewMessageField"
            value={jobPositionData.interview.interviewMessage}
            onChange={(e) => handleChange(e.target.value, 'interview.interviewMessage', false)}
          />
          <FormErrorMessage>{getErrorMessage(formErrors, 'interview.interviewMessage')}</FormErrorMessage>
        </FormControl>
      </Box>
    </Box>
  );
};
