import React from 'react';
import { InputGroupProps, Box, Spinner } from '@chakra-ui/core';
import { CgSearch } from 'react-icons/cg';
import { ReactCustomSelectStyles } from './RecruiterSearchStyle';
import { MultiSelectDropdown } from '../../../common/MultiSelectDropDown/MultiSelectDropDown';
import { RecruiterSelectType, RecruiterDashboardTabItems, useRecruiterManagementContext } from '../RecruiterManagementContext';

import { SingleRecruiterType } from '../RecruiterType';

export type SearchInputProps = InputGroupProps & {
  placeholder?: string;
  recruiterListData: SingleRecruiterType[];
  isRecruiterListLoading: boolean;
};

const RecruiterSearchView = ({ placeholder, recruiterListData, isRecruiterListLoading }: SearchInputProps): JSX.Element => {
  const { setSelectedTab, setSelectedRecruiter, selectedRecruiter } = useRecruiterManagementContext();

  const handleRecruiterSearchChange = (data: RecruiterSelectType | null) => {
    setSelectedRecruiter(data);
    setSelectedTab(RecruiterDashboardTabItems.RecruiterProfile);
  };

  const convertRecruiterListForSearchOpton = () => {
    const recruiterOptions: RecruiterSelectType[] = [];
    recruiterListData.map((recruiterListItem) => {
      return recruiterOptions.push({
        id: recruiterListItem.id || '',
        label: recruiterListItem.name || '',
        value: recruiterListItem.id || '',
      });
    });
    return recruiterOptions;
  };

  return (
    <Box css={ReactCustomSelectStyles} className="selectWrapperBox" data-testid="selectWrapperBox">
      <Box zIndex={1} display="flex">
        {isRecruiterListLoading ? (
          <Spinner size="sm" color="#8BB0C1" data-testid="loadingSpinner" />
        ) : (
          <CgSearch size={16} color="#8BB0C1" />
        )}
      </Box>
      <Box width="100%" data-testid="recruiterSelectBtn">
        <MultiSelectDropdown
          placeholder={/* istanbul ignore next */ selectedRecruiter?.value ? selectedRecruiter?.label : placeholder}
          id="recruiterSearch"
          handleChange={handleRecruiterSearchChange}
          options={convertRecruiterListForSearchOpton()}
          value={null}
        />
      </Box>
    </Box>
  );
};

export default RecruiterSearchView;
