/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import {
  Flex,
  FormControl,
  Input,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Spinner,
  useToast,
  Menu,
  MenuButton,
  Portal,
  MenuItem,
  MenuList,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import isNil from 'lodash/isNil';
import { MdInfoOutline } from 'react-icons/md';
import { RiArrowDownSLine } from 'react-icons/ri';
import colors from '../../../../styles/colors';
import { AutomationConfiguration } from '../PositionType';
import { AutomationTypes, PositionAutomationTypes } from '../createJobs/AutomationTypes';
import { FormattedError } from '../../../../utils/FormErrorUtils';

const AVAILABLE_AUTOMATIONS = [
  PositionAutomationTypes.NONE,
  PositionAutomationTypes.SCREENING,
  PositionAutomationTypes.SCHEDULING,
  PositionAutomationTypes.VIRTUAL_RECRUITER_ENABLED,
];

interface AutomationSelectProps {
  currentAutomationValue: AutomationTypes | 'null';
  positionId: string;
  isAccountVirtualRecruiterEnabled: boolean | undefined;
  isPositionVirtualRecruiterEnabled: boolean | undefined;
  updatePositionAutomation: (
    givenPositionId: string,
    givenAutomationType: string | null,
    screeningTarget: number | undefined,
    shouldUpdateAutomationApiCalled?: boolean,
    schedulingVrEnabled?: boolean,
  ) => void;
  automationConfiguration: AutomationConfiguration | undefined;
}

const PositionAutomationSelect: React.FC<AutomationSelectProps> = ({
  currentAutomationValue,
  positionId,
  isAccountVirtualRecruiterEnabled,
  updatePositionAutomation,
  automationConfiguration,
  isPositionVirtualRecruiterEnabled,
}) => {
  const toast = useToast();
  const { t } = useTranslation();

  const [currentSelectedAutomationValue, setCurrentSelectedAutomationValue] = useState<PositionAutomationTypes>(
    currentAutomationValue as PositionAutomationTypes,
  );
  useEffect(() => {
    if (currentAutomationValue === AutomationTypes.SCHEDULING && isPositionVirtualRecruiterEnabled) {
      setCurrentSelectedAutomationValue(PositionAutomationTypes.VIRTUAL_RECRUITER_ENABLED);
    } else {
      setCurrentSelectedAutomationValue(currentAutomationValue as PositionAutomationTypes);
    }
  }, [currentAutomationValue, isPositionVirtualRecruiterEnabled]);

  const [currentAutomationConfiguration, setCurrentAutomationConfiguration] = useState<AutomationConfiguration | undefined>(
    automationConfiguration,
  );
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [formErrors, setFormErrors] = useState<FormattedError | {}>({});
  const [isScreeningInputModalOpen, setIsScreeningInputModalOpen] = useState(false);
  const [isAutomationUpdating, setIsAutomationUpdating] = useState(false);
  const [isVRWarningModalOpen, setIsVRWarningModalOpen] = useState(false);

  // eslint-disable-next-line consistent-return
  const getAutomationTranslation = (automationType: PositionAutomationTypes) => {
    // eslint-disable-next-line default-case
    switch (automationType) {
      case PositionAutomationTypes.NONE:
        return t('jobPosition:automationTypes.none');
      case PositionAutomationTypes.SCREENING:
        return t('jobPosition:automationTypes.screen');
      case PositionAutomationTypes.SCHEDULING:
        return t('jobPosition:automationTypes.screenAndScheduleInterviews');
      case PositionAutomationTypes.VIRTUAL_RECRUITER_ENABLED:
        return t('jobPosition:automationTypes.screenAndSendOffers');
    }
  };

  const updatePositionAutomationType = async (currentGivenAutomationValue: PositionAutomationTypes | null) => {
    setIsAutomationUpdating(true);
    setCurrentSelectedAutomationValue(currentGivenAutomationValue as PositionAutomationTypes);

    try {
      if (currentGivenAutomationValue === PositionAutomationTypes.SCREENING && currentAutomationConfiguration?.target) {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await updatePositionAutomation(positionId, currentGivenAutomationValue, currentAutomationConfiguration.target, true);
      } else if (currentGivenAutomationValue === PositionAutomationTypes.VIRTUAL_RECRUITER_ENABLED) {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await updatePositionAutomation(positionId, AutomationTypes.SCHEDULING, undefined, true, true);
      } else {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await updatePositionAutomation(positionId, currentGivenAutomationValue, undefined, true);
      }
      toast({
        title: t('jobPosition:positionAutomationSelect.successInUpdating', {
          automationLevel: getAutomationTranslation(currentGivenAutomationValue as PositionAutomationTypes),
        }),
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      // istanbul ignore next
      setCurrentSelectedAutomationValue(currentSelectedAutomationValue);
      // istanbul ignore next
      toast({
        title: t('jobPosition:positionAutomationSelect.errorInUpdating'),
        description: t('jobPosition:positionAutomationSelect.errorUpdatingDescription'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsAutomationUpdating(false);
    }
  };

  const setNewAutomationValue = async (automationType: PositionAutomationTypes) => {
    // istanbul ignore next
    if (!automationType) return;
    if (automationType === PositionAutomationTypes.VIRTUAL_RECRUITER_ENABLED) {
      setIsVRWarningModalOpen(true);
    } else if (automationType === PositionAutomationTypes.SCREENING) {
      setFormErrors({ ...formErrors, 'automationConfiguration.target': null });
      setIsScreeningInputModalOpen(true);
    } else {
      await updatePositionAutomationType(automationType);
    }
  };

  const automationConfigurationSchema = Joi.object().keys({
    target: Joi.number()
      .min(1)
      .required()
      .messages({
        'number.base': t('createJobPosition:automationConfigurationRequired'),
        'number.min': t('createJobPosition:automationConfigurationMinRequired'),
        'any.required': t('createJobPosition:automationConfigurationRequired'),
      }),
  });

  // istanbul ignore next
  const validateInput = (value: string) => {
    const { error } = automationConfigurationSchema.validate({ target: value });
    return error ? error.details[0].message : null;
  };

  const handleInputChange = (value: string) => {
    // istanbul ignore else
    if (value) {
      setCurrentAutomationConfiguration({ target: Number(value) });
    } else {
      setCurrentAutomationConfiguration(undefined);
    }
    setFormErrors({ ...formErrors, 'automationConfiguration.target': null });
  };

  const hasErrorMessage = (errors: any, field: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    return errors && errors[field];
  };

  // istanbul ignore next
  const getErrorMessage = (errors: any, field: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    return errors && errors[field] ? errors[field] : '';
  };

  const handleScreeningModalSubmit = async () => {
    // istanbul ignore next
    const errorMessage = validateInput(currentAutomationConfiguration?.target?.toString() || '');
    // istanbul ignore else
    if (!errorMessage && currentAutomationConfiguration?.target) {
      setIsScreeningInputModalOpen(false);
      setCurrentSelectedAutomationValue(PositionAutomationTypes.SCREENING);
      await updatePositionAutomationType(PositionAutomationTypes.SCREENING);
    } else {
      setFormErrors({ ...formErrors, 'automationConfiguration.target': errorMessage });
    }
  };

  const handleScreenAndSendOffersModalSumbit = async () => {
    setIsVRWarningModalOpen(false);
    setCurrentSelectedAutomationValue(PositionAutomationTypes.VIRTUAL_RECRUITER_ENABLED);
    await updatePositionAutomationType(PositionAutomationTypes.VIRTUAL_RECRUITER_ENABLED);
  };

  return (
    <Flex>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={isAutomationUpdating ? <Spinner size="xs" pl={1} /> : <RiArrowDownSLine />}
          size="sm"
          background="#edf2f7"
          transition="all 0.2s"
          borderRadius="md"
          borderWidth="1px"
          border="none"
          mr={2}
          data-testid="SelectAutomationMenuBtn"
          _hover={{ bg: '#e2e8f0' }}
          _expanded={{ bg: '#1F3CBA', color: '#fff' }}
          disabled={isAutomationUpdating}
          textAlign="left"
          minWidth="300px"
        >
          {getAutomationTranslation(currentSelectedAutomationValue)}
        </MenuButton>
        <Portal>
          <MenuList border={`1px solid ${colors.gray['50']}`} maxHeight="200px" overflowY="auto">
            {AVAILABLE_AUTOMATIONS.filter(
              (automation) =>
                automation !== PositionAutomationTypes.VIRTUAL_RECRUITER_ENABLED || isAccountVirtualRecruiterEnabled,
            ).map((automationType, i) => (
              <MenuItem
                fontSize="sm"
                borderBottom={AVAILABLE_AUTOMATIONS?.length > i + 1 ? `1px solid ${colors.gray['50']}` : 0}
                key={automationType}
                data-testid={`AutomatiionMenuBtn-${automationType}`}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  setNewAutomationValue(automationType);
                }}
                fontWeight={automationType === currentSelectedAutomationValue ? 'bold' : 'unset'}
                color={automationType === currentSelectedAutomationValue ? colors.white : /* istanbul ignore next */ colors.black}
                backgroundColor={automationType === currentSelectedAutomationValue ? '#1F3CBA !important' : 'white'}
              >
                {getAutomationTranslation(automationType)}
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>

      <Modal
        isOpen={isScreeningInputModalOpen}
        onClose={/* istanbul ignore next */ () => setIsScreeningInputModalOpen(false)}
        data-testid="ScreeningInputModal"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('createJobPosition:automationOptionNoOfCandidateScreenLabel')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <FormControl isInvalid={hasErrorMessage(formErrors, 'automationConfiguration.target')} isRequired>
              <Input
                type="number"
                id="candidateScreen"
                data-testid="automationInputField"
                value={
                  !isNil(currentAutomationConfiguration?.target)
                    ? currentAutomationConfiguration?.target
                    : // istanbul ignore next
                      undefined
                }
                onChange={(e) => handleInputChange(e.target.value)}
              />
              <FormErrorMessage data-testid="screeningInputErrorMessage">
                {getErrorMessage(formErrors, 'automationConfiguration.target')}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button data-testid="screeningInputSubmitBtn" colorScheme="blue" onClick={handleScreeningModalSubmit} mr={2}>
              {t('jobPosition:screeningInputModal.submitText')}
            </Button>
            <Button data-testid="screeningInputCancelBtn" colorScheme="red" onClick={() => setIsScreeningInputModalOpen(false)}>
              {t('jobPosition:screeningInputModal.cancelText')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isVRWarningModalOpen}
        onClose={/* istanbul ignore next */ () => setIsVRWarningModalOpen(false)}
        data-testid="VRWarningModal"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Box mr={4}>
                <MdInfoOutline color={colors.blue.default} fontSize="40px" />
              </Box>

              <Text>{t('jobPosition:screenAndSendOffersModal.warningText')}</Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button data-testid="VRWarningCloseBtn" colorScheme="blue" onClick={handleScreenAndSendOffersModalSumbit} mr={2}>
              {t('jobPosition:screenAndSendOffersModal.submitText')}
            </Button>
            <Button data-testid="VRWarningCancelBtn" colorScheme="red" onClick={() => setIsVRWarningModalOpen(false)}>
              {t('jobPosition:screenAndSendOffersModal.cancelText')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default PositionAutomationSelect;
