import React from 'react';
import { Box } from '@chakra-ui/core';
import { useState } from 'react';
import { ImagePreviewModal } from '../../../../app/components/ImagePreviewModal';
import ImageView from '../../../../app/components/ImageView';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import ImageGalleryModal from '../../imageGallery/ImageGalleryModal';
import { GalleryImageType } from '../../imageGallery/ImageGalleryType';
import { MultipleChoiceImage } from '../PositionType';

interface SelectMCQImageProps {
  images: MultipleChoiceImage[];
  onSelect: (images: MultipleChoiceImage[]) => void;
}
export default function SelectMCQImage({ onSelect, images }: SelectMCQImageProps) {
  const [open, setOpen] = useState(false);
  const { currentUserProfile } = useCurrentUserProfile();
  const accountId = currentUserProfile?.account ?? '';

  function convertToMultipleChoiceImage(image: GalleryImageType): MultipleChoiceImage {
    return {
      galleryId: image.id,
      url: image.url,
      path: image.path,
    };
  }

  function convertToGalleryImage(image: MultipleChoiceImage): GalleryImageType {
    return {
      id: image?.galleryId,
      url: image.url,
      account: accountId,
      path: image?.path || '',
    };
  }
  return (
    <Box pl={4}>
      <ImageGalleryModal
        selected={images?.[0] && convertToGalleryImage(images?.[0])}
        onSelected={(image) => onSelect(image ? [convertToMultipleChoiceImage(image)] : [])}
        triggerAction={
          <ImageView
            src={images?.[0]?.url}
            showAddImageIcon
            onExpand={() => {
              setOpen(true);
            }}
            onRemove={() => {
              onSelect([]);
            }}
            shape="rounded"
            height="42px"
          />
        }
      />
      <ImagePreviewModal
        setTrigger={open}
        images={images}
        getImageKey={(image) => image?.galleryId || ''}
        getImageSrc={(image) => image?.url || ''}
        onModalClose={() => setOpen(false)}
      />
    </Box>
  );
}
