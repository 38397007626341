import _map from 'lodash/map';
import { useSubCollectionQuerySnapshot } from '../../../firebase/firestore/hooks';
import { Collections, SubCollections } from '../../../firebase/firestore/collections';
import { CalendarParticipantType } from './CalendarParticipantType';
import { EventRecruiters } from '../../../firebase/firestore/documents/EventRecruiters';
import { Query } from '../../../firebase/firestore/query/query';

const useEventRecruiters = (eventId?: string): CalendarParticipantType[] => {
  const invitedRecruiters = useSubCollectionQuerySnapshot<EventRecruiters>(
    Collections.GROUP_EVENTS,
    eventId ?? '',
    SubCollections.RECRUITERS,
    [Query.field('invitationStatus').in(['PENDING', 'ACCEPTED'])],
  );

  if (eventId && invitedRecruiters) {
    return _map(invitedRecruiters, (r) => {
      return {
        label: r.firstName ? `${r.firstName} ${r.lastName}` : r.email,
        value: r.email,
      } as CalendarParticipantType;
    });
  }

  return [];
};

export default useEventRecruiters;
