import firebase from 'firebase/app';
import { Action, action, ThunkOn, thunkOn } from 'easy-peasy';
import { AxiosRequestConfig } from 'axios';
import Config from '../../firebase/Config';
import { AuthStoreModel } from '../../modules/auth/models/authModel';
import * as Package from '../../../package.json';
import HttpClientWithRetry from '../client/HttpClientWithRetry';

const registerBearerTokenInterceptor = (client: HttpClientWithRetry, user: firebase.User) => {
  // istanbul ignore next
  client.interceptors.request.use(
    async (config): Promise<AxiosRequestConfig> => {
      const token = await user.getIdToken();
      // eslint-disable-next-line no-param-reassign,@typescript-eslint/no-unsafe-member-access
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
  );
};

const registerPublicTokenInterceptor = (client: HttpClientWithRetry) => {
  // istanbul ignore next
  client.interceptors?.request.use(
    (config): AxiosRequestConfig => {
      const token = Config.apiPicKey;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,no-param-reassign
      if (config.url?.includes('publicApi')) config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
  );
};

const initHttpClient = (user?: firebase.User | null): HttpClientWithRetry => {
  const client = new HttpClientWithRetry({
    serviceConfiguration: {
      baseURL: Config.serverUrl,
      timeout: 30000,
      headers: {
        appVersion: `${Package.name}:${Package.version}`,
      },
    },
    retryConfiguration: {},
  });

  registerPublicTokenInterceptor(client);

  if (user) {
    registerBearerTokenInterceptor(client, user);
  }

  return client;
};

export type HttpStoreModel = {
  http: HttpModel;
};

export interface HttpModel {
  client: HttpClientWithRetry;
  setClient: Action<HttpModel, HttpClientWithRetry>;
  onSetFirebaseUser: ThunkOn<HttpModel, void, AuthStoreModel>;
}

const httpModel: HttpModel = {
  client: initHttpClient(),

  setClient: action((state, payload) => {
    state.client = payload;
  }),

  onSetFirebaseUser: thunkOn(
    (actions, storeActions) => storeActions.auth.setFirebaseUser,
    (actions, target) => {
      actions.setClient(initHttpClient(target.payload));
    },
  ),
};

export default httpModel;
