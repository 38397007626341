import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Center,
  Divider,
  Image,
  Text,
} from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AlertProps {
  isDeviceOpen: boolean;
  setIsDeviceOpen: (open: boolean) => void;
}

const DeviceDetectAlertDialog: React.FC<AlertProps> = (props: AlertProps) => {
  const { t } = useTranslation('app');

  const cancelRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  return (
    <AlertDialog
      size="2xl"
      closeOnOverlayClick
      isOpen={props.isDeviceOpen}
      leastDestructiveRef={cancelRef}
      onClose={/* istanbul ignore next */ () => props.setIsDeviceOpen(false)}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius={0} data-testid="deviceDetectTest">
          <Center>
            <Image src="/device_detect.svg" alt="Microsoft Edge" />
          </Center>
          <Center>
            <Divider pt={5} />
          </Center>
          <AlertDialogHeader fontSize="xl" fontWeight="bold" textAlign="center" pt={10}>
            {t('deviceDetect.header')}
            <Text fontSize="sm" pt={2}>
              {t('deviceDetect.message')}
            </Text>
          </AlertDialogHeader>
          <AlertDialogCloseButton />
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeviceDetectAlertDialog;
