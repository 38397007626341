import React from 'react';
import { Avatar, AvatarProps } from '@chakra-ui/core';
import DefaultProfilePicture from '../../../assets/img/default-profile-picture.png';
import { useEmployerPicture } from '../../../firebase/storage/hooks/useEmployerPicture';

export const DialogFlowPicture = ({ children, name, style, ...rest }: AvatarProps): React.ReactElement => {
  const dialogFlowImage = useEmployerPicture('', true);

  return (
    <Avatar
      style={{ color: 'white', ...style }}
      {...rest}
      icon={<img src={DefaultProfilePicture} alt="" style={{ borderRadius: 100, position: 'relative' }} />}
      src={dialogFlowImage}
    >
      {children}
    </Avatar>
  );
};
