import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import _noop from 'lodash/noop';
import { PositionListQueryParams } from './PositionType';
import { Business } from './BusinessType';

export const defaultPositionListParams: PositionListQueryParams = {
  positionStatus: 'open',
  followedOnly: false,
  sortBy: 'customName',
  sortOrder: 'asc',
  page: 1,
  limit: 20,
  searchTerm: '',
  archive: false,
};

export type PositionManagementContainerContextValueType = {
  positionQueryParams: PositionListQueryParams;
  setPositionQueryParams: (queryParams: PositionListQueryParams) => void;
  businessData: Business[];
  setBusinessData: Dispatch<SetStateAction<Business[]>>;
  selectedBusinessId: string;
  setSelectedBusinessId: Dispatch<SetStateAction<string>>;
};

export type PositionManagementContextValueType = PositionManagementContainerContextValueType;

export const POSITION_MANAGEMENT_CONTEXT_DEFAULT_VALUE: PositionManagementContextValueType = {
  positionQueryParams: defaultPositionListParams,
  setPositionQueryParams: _noop,
  businessData: [],
  setBusinessData: _noop,
  selectedBusinessId: '',
  setSelectedBusinessId: _noop,
};

export const PositionManagementContext = React.createContext<PositionManagementContextValueType>(
  POSITION_MANAGEMENT_CONTEXT_DEFAULT_VALUE,
);

export const PositionManagementContextProvider: React.FC = ({ children }) => {
  const [positionQueryParams, setPositionQueryParams] = useState<PositionListQueryParams>(defaultPositionListParams);
  const [businessData, setBusinessData] = useState<Business[]>([]);
  const [selectedBusinessId, setSelectedBusinessId] = useState<string>('');

  return (
    <PositionManagementContext.Provider
      value={{
        positionQueryParams,
        setPositionQueryParams,
        businessData,
        setBusinessData,
        selectedBusinessId,
        setSelectedBusinessId,
      }}
    >
      {children}
    </PositionManagementContext.Provider>
  );
};

export const usePositionManagementContext = (): PositionManagementContextValueType => useContext(PositionManagementContext);
