export enum ModernHireStatusList {
  ASSESSMENT_STARTED = 'ASSESSMENT_STARTED',
  ASSESSMENT_IN_PROGRESS = 'ASSESSMENT_IN_PROGRESS',
  ASSESSMENT_PASSED = 'ASSESSMENT_PASSED',
  ASSESSMENT_FAILED = 'ASSESSMENT_FAILED',
}
export type ModernHireDetail = {
  scheduledLiveInterviewDateTime?: string;
  reviewUrl?: string;
  band?: string;
  score?: number;
  status?: string;
  comments?: string;
  completionDate?: string;
  title?: string;
  passed?: boolean;
  overallFitScores?: OverallFitScores[];
};

export type OverallFitScores = {
  name: string;
  max: string;
  score: number;
};

export type ModernHireDetailResponse = {
  id: string;
  requisitionId?: string;
  packageId: string;
  candidateId: string;
  orderId: string;
  results?: ModernHireDetail[];
};
