import React from 'react';
import { Box } from '@chakra-ui/core';
import { ViewRequisitionDetail } from '../../seeker/candidate/history/ViewRequisitionDetail';
import useSingleRequisitionByPosition from '../../seeker/candidate/profile/components/useSingleRequisitionByPosition';

export type ViewSingleRequisitionDetailProps = {
  isOpen: boolean;
  onClose: () => void;
  positionId: string;
  requisitionId: string;
};

const ViewSingleRequisitionDetail = ({
  isOpen,
  onClose,
  positionId,
  requisitionId,
}: ViewSingleRequisitionDetailProps): JSX.Element => {
  const singleRequisition = useSingleRequisitionByPosition(positionId, requisitionId);

  return (
    <Box>
      {singleRequisition && (
        <ViewRequisitionDetail isOpen={isOpen} onClose={onClose} requisition={singleRequisition?.[0]} shouldCompare={false} />
      )}
    </Box>
  );
};

export default ViewSingleRequisitionDetail;
