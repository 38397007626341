/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Box,
  Text,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { TeamInviteAction, TeamMemberAction } from '../../administration/team/TeamType';
import colors from '../../../styles/colors';

export type ConfirmationDialogProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  message: string;
  warningMessage?: string;
  action: TeamMemberAction | TeamInviteAction | null;
  handleConfirm: (action: TeamMemberAction | TeamInviteAction) => void;
};

export const ConfirmationDialog = ({
  isLoading,
  isOpen,
  onClose,
  message,
  warningMessage,
  action,
  handleConfirm,
}: ConfirmationDialogProps): JSX.Element => {
  const { t } = useTranslation('team');
  const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  return (
    <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader fontSize="md">
          <Box>
            {message}
            <Text fontSize="14px" fontStyle="italic" color={colors.red[500]}>
              {warningMessage}
            </Text>
          </Box>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button colorScheme="blue" ref={cancelRef} onClick={onClose}>
            {t('cancelBtn')}
          </Button>
          {action && (
            <Button isLoading={isLoading} data-testid="ConfirmBtn" colorScheme="red" ml={3} onClick={() => handleConfirm(action)}>
              {t('confirmBtn')}
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
