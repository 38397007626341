import _isNil from 'lodash/isNil';
import { Collections } from '../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Query } from '../../firebase/firestore/query/query';
import { BACKGROUND_CHECK } from '../../firebase/firestore/constants';
import { Lookups } from '../../firebase/firestore/documents/lookups';

const useBackgroundChecks = (): Lookups[] => {
  const backgroundChecks = useQuerySnapshot<Lookups>(Collections.LOOKUPS, [Query.field('type').equals(BACKGROUND_CHECK)]);
  if (_isNil(backgroundChecks)) return [];
  return backgroundChecks;
};

export default useBackgroundChecks;
