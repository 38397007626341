import firebase from 'firebase/app';
import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import last from 'lodash/last';
import _chunk from 'lodash/chunk';
import _isString from 'lodash/isString';
import { useCurrentUserProfile } from '../app/hooks/useCurrentUserProfile';
import { useUserIsAuthenticated } from '../app/hooks/useUserIsAuthenticated';
import { RemoteConfigKey } from '../firebase/remoteConfig';
import { useStoreActions, useStoreState } from '../models/hooks';
import { AdministrationLayout } from '../modules/administration/AdministrationLayout';
import { RecruiterCalendarView } from '../modules/administration/recruiterCalendar/RecruiterCalendarView';
import { TeamView } from '../modules/administration/team/TeamView';
import { TopCandidateHome } from '../modules/administration/topCandidates/TopCandidateHome';
import { AnalyticsView } from '../modules/analytics/AnalyticsView';
import { NewAnalyticsView } from '../modules/analytics/NewAnalyticsView';
import { CalendarView } from '../modules/calendar/CalendarView';
import { DashboardHome } from '../modules/dashboard/DashboardHome';
import OfferAndHireProcessView from '../modules/dashboard/offerAndHireProcessOverview/OfferAndHireProcessView';
import { EmailInvitationView } from '../modules/emailInvitation/EmailInvitationView';
import { JobPositionView } from '../modules/jobPosition/JobPositionView';
import { MeetView } from '../modules/meet/MeetView';
import { CandidateMessagingView } from '../modules/messaging/CandidateMessagingView';
import { InterviewMessagingView } from '../modules/messaging/InterviewMessagingView';
import { MessagingView } from '../modules/messaging/MessagingView';
import { SeekersPresenter } from '../modules/messaging/sidebar/seekerList/seekersPresenter';
import { SupportLayout } from '../modules/support/SupportLayout';
import UnsubscribeView from '../modules/unsubscribe/UnSubscribeView';
import { isCandidatePage, isCandidatesPage, isPositionCandidatesPage } from '../utils/candidatesUtils';
import PrivateRoute from './PrivateRoute';
import {
  PATH_ADMINISTRATION,
  PATH_ADMINISTRATION_TABS,
  PATH_ANALYTICS,
  PATH_CALENDAR,
  PATH_CANDIDATE,
  PATH_DASHBOARD,
  PATH_EMAIL_INVITATION,
  PATH_GUEST_MEET,
  PATH_INTERVIEW_MESSAGING_TABS,
  PATH_LOGIN,
  PATH_MAIN,
  PATH_MEET,
  PATH_MESSAGING_TABS,
  PATH_OFFER_HIRE_PROCESS,
  PATH_POSITION,
  PATH_MANAGEMENT_OVERVIEW,
  PATH_POSITIONS,
  PATH_PROTOTYPE,
  PATH_RECRUITER_CALENDAR,
  PATH_ROOT,
  PATH_SUPPORT,
  PATH_TEAM,
  PATH_UNSUBSCRIBE,
  PATH_SUCCESSFUL,
  PATH_SMB_BUSINESS_MANAGEMENT,
  PATH_SMB_POSITION_MANAGEMENT,
  PATH_POSITION_CANDIDATE,
  PATH_DASHBOARD_REQUISITION,
} from './constants';
import { ManagementOverviewView } from '../modules/managementOverview/ManagementOverviewView';
import { PaymentSuccessfulView } from '../modules/paymentSuccess/PaymentSuccessView';
import { SMBLoginView } from '../modules/auth/login/SMBLoginView';
import SmbPositionManagementView from '../modules/smbPositionManagement/SmbPositionManagementView/SmbPositionManagementView';
import { SmbBusinessManagementView } from '../modules/smbPositionManagement/SmbPositionManagementView/SmbBusinessManagementView';
import { DashboardRequisition } from '../modules/dashboard/DashboardRequisition';
import HireformView from '../modules/administration/hireForm/HireformView';
import HRFormTemplateView from '../modules/administration/hireForm/HRFormTemplateView';

const Routes = (): JSX.Element => {
  const location = useLocation();
  const isAuthenticated = useUserIsAuthenticated();
  const { currentUserProfile } = useCurrentUserProfile();

  const { loadCandidate, loadCandidatesOfPositions } = useStoreActions((actions) => actions.candidate);
  const setAllCandidates = useStoreActions((a) => a.candidate.setAllCandidates);
  const getAllFollowedPositionOfAccount = useStoreActions((a) => a.account.getAllFollowedPositionOfAccount);
  const minutesBeforeInactivity = useStoreState((state) => state.remoteConfig[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]);
  const superOwners = useStoreState((s) => s.app.accounts?.superOwners);
  const userId = useStoreState((s) => s.app.user?.id);
  const appOwnersId = useStoreState((s) => s.app.accounts?.owners);
  const isRequisitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);
  const currentAccountId = useStoreState((state) => state.app.accounts?.id);

  // Function to load candidates for a given chunk
  /* istanbul ignore next */
  const loadCandidatesForChunk = async (accountId: string, chunkedPositions: string[]) => {
    try {
      const response = await loadCandidatesOfPositions({ accountId, positionIds: chunkedPositions });
      return response; // Adjust based on your API response format
    } catch (error) {
      console.error(`Error loading candidates for chunk ${chunkedPositions}:`, error);
      return []; // Return an empty array in case of error
    }
  };

  /* istanbul ignore next */
  const onLoadCandidatesOfPositions = async (accountId: string, positions: string[]) => {
    const positionChunks = _chunk(positions, 90);
    try {
      const allCandidatesPromises = positionChunks.map((chunkPositions) => loadCandidatesForChunk(accountId, chunkPositions));
      const candidatesResults = await Promise.all(allCandidatesPromises);
      const combinedCandidates = candidatesResults.flat(); // Flatten the array
      const dismissedcandidates = combinedCandidates.filter((c) => c.dismissed);
      const seekerPresenter = new SeekersPresenter(
        combinedCandidates,
        currentUserProfile?.id ?? '',
        minutesBeforeInactivity,
        dismissedcandidates,
      );
      setAllCandidates(seekerPresenter);
    } catch (error) {
      console.error('Error loading all candidates:', error);
    }
  };

  /* istanbul ignore next */
  const onLoadCandidates = () => {
    if (currentUserProfile && minutesBeforeInactivity) {
      loadCandidate({ accountId: currentUserProfile.account })
        .then((response) => {
          const candidates = response;
          const dismissedcandidates = candidates.filter((c) => c.dismissed);
          const seekerPresenter = new SeekersPresenter(
            candidates,
            currentUserProfile.id,
            minutesBeforeInactivity,
            dismissedcandidates,
          );
          setAllCandidates(seekerPresenter);
        })
        .catch((error) => {
          console.error('Error fetching candidate:', error);
        });
    }
  };

  const currentPath = location?.pathname?.split('/');

  const canLoadCandidates =
    last(currentPath) === 'messaging' ||
    isUndefined(isRequisitionEnabled) ||
    (!isUndefined(isRequisitionEnabled) && !isRequisitionEnabled);

  /* istanbul ignore next */
  const listAllPositionsOfAccount = useCallback(
    async (accountId: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const result: { positions: { id: string }[] } = await getAllFollowedPositionOfAccount({ accountId });
      return result.positions.map((position: { id: string }) => position.id);
    },
    [getAllFollowedPositionOfAccount],
  );

  /* istanbul ignore next */
  useEffect(() => {
    // Ensure currentAccountId is a string or fallback to an empty string
    const accountId: string = _isString(currentAccountId) ? currentAccountId : '';

    // Function to handle position fetching and interval setup
    const handlePositionFetching = async (): Promise<number | null> => {
      let timerId: number | null = null; // Declare timerId with explicit type

      try {
        if (!isPositionCandidatesPage()) {
          if (!currentUserProfile?.enabledNewApiForCandidates) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onLoadCandidates();
            timerId = window.setInterval(onLoadCandidates, 240000); // 4 minutes
          } else {
            const positions: string[] = await listAllPositionsOfAccount(accountId);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onLoadCandidatesOfPositions(accountId, positions);
            timerId = window.setInterval(() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              onLoadCandidatesOfPositions(accountId, positions);
            }, 5 * 60 * 1000); // 5 minutes
          }
        }
      } catch (error) {
        console.error('Error fetching positions or setting interval:', error);
      }

      return timerId;
    };

    // Scroll to bottom of the page if on candidate-related pages
    if (isCandidatePage() || isCandidatesPage()) {
      window.scrollTo(0, document.body.scrollHeight);
    }

    // Initialize the timer
    let timer: number | null = null;
    const setupTimer = async () => {
      if (
        currentUserProfile &&
        minutesBeforeInactivity &&
        !isCandidatePage() &&
        canLoadCandidates &&
        accountId &&
        last(currentPath) !== 'administration'
      ) {
        timer = await handlePositionFetching();
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setupTimer();

    // Cleanup the timer when the component unmounts
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      if (timer !== null) {
        window.clearInterval(timer);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserProfile, loadCandidate, minutesBeforeInactivity, location, currentAccountId]);

  useEffect(() => {
    if (location.pathname) {
      firebase.analytics().logEvent('change_route', { pathname: location.pathname });
    }
  }, [location.pathname]);

  const isNewAnalyticEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_NEW_ANALYTIC_VIEW];
  });

  const isSuperAdmin = (): boolean => {
    if (!superOwners || !userId) {
      return false;
    }
    return superOwners?.includes(userId);
  };

  const isAdmin = !isNil(appOwnersId) && !isNil(userId) && appOwnersId?.includes(userId);

  return (
    <Switch location={location} key={location.pathname}>
      <Redirect exact from={PATH_ROOT} to={PATH_LOGIN} />
      <Route exact path={PATH_LOGIN} component={SMBLoginView} />
      {/* <Route exact path={PATH_SMB_LOGIN} component={SMBLoginView} /> */}
      <Route exact path={PATH_GUEST_MEET} component={MeetView} />
      <Route exact path={PATH_TEAM} component={TeamView} />
      <Route exact path={PATH_MEET} component={MeetView} />
      <Route exact path={PATH_SUPPORT} component={SupportLayout} />
      <Route exact path={PATH_EMAIL_INVITATION} component={EmailInvitationView} />
      <Route exact path={PATH_UNSUBSCRIBE} component={UnsubscribeView} />
      <PrivateRoute exact path={PATH_ADMINISTRATION} userIsAuth={isAuthenticated} component={AdministrationLayout} />
      <PrivateRoute exact path={PATH_POSITION} userIsAuth={isAuthenticated} component={TopCandidateHome} />
      <PrivateRoute exact path={PATH_ADMINISTRATION_TABS} userIsAuth={isAuthenticated} component={AdministrationLayout} />
      <PrivateRoute
        exact
        path={PATH_ANALYTICS}
        userIsAuth={isAuthenticated}
        component={isNewAnalyticEnabled ? NewAnalyticsView : AnalyticsView}
      />
      <PrivateRoute exact path={PATH_PROTOTYPE} userIsAuth={isAuthenticated} component={NewAnalyticsView} />
      <PrivateRoute exact path={PATH_MAIN} userIsAuth={isAuthenticated} component={MessagingView} />
      <PrivateRoute exact path={PATH_MESSAGING_TABS} userIsAuth={isAuthenticated} component={MessagingView} />
      <PrivateRoute exact path={PATH_CANDIDATE} userIsAuth={isAuthenticated} component={CandidateMessagingView} />
      <PrivateRoute exact path={PATH_POSITION_CANDIDATE} userIsAuth={isAuthenticated} component={MessagingView} />
      <PrivateRoute exact path={PATH_INTERVIEW_MESSAGING_TABS} userIsAuth={isAuthenticated} component={InterviewMessagingView} />
      <PrivateRoute exact path={PATH_CALENDAR} userIsAuth={isAuthenticated} component={CalendarView} />
      <PrivateRoute exact path={PATH_RECRUITER_CALENDAR} userIsAuth={isAuthenticated} component={RecruiterCalendarView} />
      <PrivateRoute exact path={PATH_POSITIONS} userIsAuth={isAuthenticated} component={JobPositionView} />
      <PrivateRoute exact path={PATH_DASHBOARD} userIsAuth={isAuthenticated} component={DashboardHome} />
      <PrivateRoute exact path={PATH_DASHBOARD_REQUISITION} userIsAuth={isAuthenticated} component={DashboardRequisition} />
      <PrivateRoute
        exact
        path={PATH_SMB_BUSINESS_MANAGEMENT}
        userIsAuth={isAuthenticated}
        component={SmbBusinessManagementView}
      />
      <PrivateRoute
        exact
        path={PATH_SMB_POSITION_MANAGEMENT}
        userIsAuth={isAuthenticated}
        component={SmbPositionManagementView}
      />
      <PrivateRoute
        exact
        path={PATH_MANAGEMENT_OVERVIEW}
        userIsAuth={isAuthenticated}
        component={isSuperAdmin() || isAdmin ? ManagementOverviewView : MessagingView}
      />
      <PrivateRoute exact path={PATH_OFFER_HIRE_PROCESS} userIsAuth={isAuthenticated} component={OfferAndHireProcessView} />
      <PrivateRoute exact path={PATH_SUCCESSFUL} userIsAuth={isAuthenticated} component={PaymentSuccessfulView} />
      <PrivateRoute exact path="/hire-form" userIsAuth={isAuthenticated} component={HireformView} />
      <PrivateRoute exact path="/hire-form/:templateId/edit" userIsAuth={isAuthenticated} component={HireformView} />
      <PrivateRoute exact path="/hire-form-template" userIsAuth={isAuthenticated} component={HRFormTemplateView} />

      <Redirect from="*" to={isAuthenticated ? PATH_MAIN : PATH_LOGIN} />
    </Switch>
  );
};

export default Routes;
