/* istanbul ignore file */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/core';
import { MdArrowForwardIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { StatusEnum } from './RecruiterTodoListType';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { CandidateRecruiterAction } from '../../firebase/firestore/documents/candidateRecruiterAction';
import { ToDoList } from '../../firebase/firestore/documents/toDo';

export type CandidateStatusDropdownProps = {
  id: string;
  status: string;
  candidateId: string;
  actionLabel: string;
  candidateRecruiterActions?: CandidateRecruiterAction[];
  setToDoList: React.Dispatch<React.SetStateAction<ToDoList>>;
};

export const CandidateStatusDropdown = ({
  id,
  candidateId,
  status,
  actionLabel,
  candidateRecruiterActions,
  setToDoList,
}: CandidateStatusDropdownProps): JSX.Element => {
  const [actionBtnLabel, setActionBtnLabel] = useState<string>(actionLabel);
  const [taskStatus, setTaskStatus] = useState<StatusEnum>(StatusEnum.TODO);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { updateRecruiterActionStatus } = useStoreActions((actions) => actions.toDoList);
  const userId = useStoreState((state) => state.app.user?.id);
  const { t } = useTranslation('toDoList');

  const setNewTodoList = useCallback(
    (actionStatusLabel: StatusEnum, givenId: string) => {
      setToDoList((prevState) => {
        const data = map(prevState.data, (item) => {
          if (item.recruiterActionId === givenId) {
            return {
              ...item,
              todoStatus: actionStatusLabel,
            };
          }
          return item;
        });

        return {
          ...prevState,
          count: prevState.count,
          data,
        };
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const updateCandidateRecruiterAction = async (actionStatusLabel: StatusEnum) => {
    if (userId) {
      setIsLoading(true);
      await updateRecruiterActionStatus({
        userId,
        docId: `${id}`,
        status: actionStatusLabel,
        category: status,
        subCategory: null,
        candidateId,
      })
        .then(() => {
          setTaskStatus(actionStatusLabel === StatusEnum.TODO ? StatusEnum.TODO : StatusEnum.COMPLETED);
          setActionBtnLabel(actionStatusLabel === StatusEnum.TODO ? t('toDo') : t('completed'));
          setNewTodoList(actionStatusLabel, id);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    const docIndex = candidateRecruiterActions?.find((doc) => doc.id === id);
    if (docIndex && docIndex.status === 'COMPLETED') {
      setActionBtnLabel(t('completed'));
      setTaskStatus(StatusEnum.COMPLETED);
      setNewTodoList(StatusEnum.COMPLETED, id);
    } else {
      setActionBtnLabel(t('toDo'));
      setTaskStatus(StatusEnum.TODO);
      setNewTodoList(StatusEnum.TODO, id);
    }
  }, [candidateRecruiterActions, id, t, setNewTodoList]);

  return (
    <Box position="relative" py="15px">
      <Menu fixed={false} offset={[2, 3]}>
        <MenuButton as={Flex}>
          <Flex alignItems="center" maxW="160px">
            <Button
              bg={taskStatus === StatusEnum.TODO ? '#e9ecf8' : '#ebf6f0'}
              color={taskStatus === StatusEnum.TODO ? 'blue.500' : '#38A169'}
              borderRadius="64px"
              px="20px"
              py="8px"
              mr={2}
              fontSize="15px"
              fontWeight="600"
              h="2.1rem"
              w="100%"
              isLoading={isLoading}
              loadingText="Updating"
              border="1px solid white"
            >
              {actionBtnLabel}
            </Button>
            <MdArrowForwardIos style={{ transform: 'rotate(90deg)' }} />
          </Flex>
        </MenuButton>
        <MenuList borderColor="blue.30" fontSize="14px">
          <MenuItem
            fontWeight="600"
            onClick={() => updateCandidateRecruiterAction(StatusEnum.TODO)}
            isDisabled={actionBtnLabel.toLowerCase() === 'to do'}
          >
            {t('toDo')}
          </MenuItem>
          <MenuItem
            fontWeight="600"
            onClick={() => updateCandidateRecruiterAction(StatusEnum.COMPLETED)}
            isDisabled={actionBtnLabel.toLowerCase() === 'completed'}
          >
            {t('completed')}
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
