import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  Box,
  Flex,
  Badge,
  Button,
} from '@chakra-ui/core';
import { IoCheckmarkSharp, IoDocumentText } from 'react-icons/io5';
import { RiHandCoinLine, RiCloseLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { AiFillAudio } from 'react-icons/ai';
import isEmpty from 'lodash/isEmpty';
import colors from '../../styles/colors';
import { BackgroundCheck, Count, HireForm, OfferMade } from '../../firebase/firestore/documents/toDo';
import CertnLogoSuccess from '../../assets/img/certn-logo-success.svg';
import AppyHereIcon from '../../assets/img/appy_logo.svg';

export type ToDoCountViewSideBarProps = {
  count: Count;
  filterByStatus: string;
  setFilterByStatus: (status: string) => void;
};
export const ToDoCountViewSideBar = ({ count, filterByStatus, setFilterByStatus }: ToDoCountViewSideBarProps): JSX.Element => {
  const { t } = useTranslation('toDoList');

  const renderCustomBadge = (countNumber: number) =>
    countNumber.toString().length < 3 ? (
      <Box
        mr="6px"
        w="20px"
        lineHeight="20px"
        borderRadius="50%"
        textAlign="center"
        background={colors.red[500]}
        color={colors.white}
        fontSize="14px"
        fontWeight={700}
      >
        {countNumber}
      </Box>
    ) : (
      <Badge background={colors.red[500]} color={colors.white} ml="6px" fontSize="14px" fontWeight={700} borderRadius="12px">
        {countNumber}
      </Badge>
    );

  return (
    <Box>
      <Box overflow="auto">
        {filterByStatus && (
          <Flex align="center" justifyContent="space-between" px={3} py={2} borderTop={`1px solid ${colors.neutral[200]}`}>
            <Text fontSize="sm" fontWeight="bold">
              {t('filterBy')}
            </Text>
            <Button
              size="xs"
              onClick={() => setFilterByStatus('')}
              rightIcon={<RiCloseLine />}
              colorScheme="blue"
              variant="outline"
              data-testid="status-reset"
            >
              {t(`${filterByStatus}`)}
            </Button>
          </Flex>
        )}
        <Accordion allowToggle>
          {count.totalHireForm > 0 && (
            <AccordionItem borderTop={`1px solid ${colors.neutral[200]}`}>
              <h2>
                <AccordionButton>
                  <Flex as="span" flex="1" textAlign="left" alignItems="center">
                    <IoDocumentText style={{ marginRight: '4px' }} color={colors.blue.default} size="20px" />
                    <Text fontSize="14px" fontWeight={700} ml="8px">
                      {t('hireForm')}
                    </Text>
                  </Flex>
                  {renderCustomBadge(count.totalHireForm)}
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel p={0}>
                {!isEmpty(count?.hireForm) &&
                  Object.keys(count.hireForm).map(
                    (item) =>
                      count.hireForm?.[item as keyof HireForm] !== 0 && (
                        <Flex
                          key={item}
                          as="span"
                          flex="1"
                          textAlign="left"
                          alignItems="center"
                          background="#F7FAFC"
                          p="12px"
                          border="1px solid var(--Outline-Border-Color, #E5E7EB);"
                          justifyContent="space-between"
                          onClick={() => setFilterByStatus(item)}
                          data-testid={`status-${item}`}
                        >
                          <Text d="inline-flex" alignItems="center" fontSize="14px" fontWeight={400}>
                            {t(`${item}`)}
                            {filterByStatus === item && (
                              <Box as="span" color="blue.500" fontSize="18px" ml={2}>
                                <IoCheckmarkSharp />
                              </Box>
                            )}
                          </Text>
                          {renderCustomBadge(count.hireForm?.[item as keyof HireForm])}
                        </Flex>
                      ),
                  )}
              </AccordionPanel>
            </AccordionItem>
          )}
          {count.totalBackgroundCheck > 0 && (
            <AccordionItem borderTop={`1px solid ${colors.neutral[200]}`}>
              <h2>
                <AccordionButton>
                  <Flex as="span" flex="1" textAlign="left" alignItems="center">
                    <img src={CertnLogoSuccess} style={{ width: 26 }} alt="" />
                    <Text fontSize="14px" fontWeight={700} ml="8px">
                      {t('backgroundCheck')}
                    </Text>
                  </Flex>
                  {renderCustomBadge(count.totalBackgroundCheck)}
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel p={0}>
                {!isEmpty(count?.backgroundCheck) &&
                  Object.keys(count.backgroundCheck).map(
                    (item) =>
                      count.backgroundCheck?.[item as keyof BackgroundCheck] !== 0 && (
                        <Flex
                          key={item}
                          as="span"
                          flex="1"
                          textAlign="left"
                          alignItems="center"
                          background="#F7FAFC"
                          p="12px"
                          border="1px solid var(--Outline-Border-Color, #E5E7EB);"
                          justifyContent="space-between"
                          onClick={() => setFilterByStatus(item)}
                          data-testid={`status-${item}`}
                        >
                          <Text d="inline-flex" alignItems="center" fontSize="14px" fontWeight={400}>
                            {t(`${item}`)}
                            {filterByStatus === item && (
                              <Box as="span" color="blue.500" fontSize="18px" ml={2}>
                                <IoCheckmarkSharp />
                              </Box>
                            )}
                          </Text>
                          {renderCustomBadge(count.backgroundCheck?.[item as keyof BackgroundCheck])}
                        </Flex>
                      ),
                  )}
              </AccordionPanel>
            </AccordionItem>
          )}
          {count.totalOfferMade > 0 && (
            <AccordionItem borderTop={`1px solid ${colors.neutral[200]}`}>
              <h2>
                <AccordionButton>
                  <Flex as="span" flex="1" textAlign="left" alignItems="center">
                    <RiHandCoinLine style={{ marginRight: '4px' }} color={colors.blue.default} size="20px" />
                    <Text fontSize="14px" fontWeight={700} ml="8px">
                      {t('offer')}
                    </Text>
                  </Flex>
                  {renderCustomBadge(count.totalOfferMade)}
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel p={0}>
                {!isEmpty(count?.offerMade) &&
                  Object.keys(count.offerMade).map(
                    (item) =>
                      count.offerMade?.[item as keyof OfferMade] !== 0 && (
                        <Flex
                          key={item}
                          as="span"
                          flex="1"
                          textAlign="left"
                          alignItems="center"
                          background="#F7FAFC"
                          p="12px"
                          border="1px solid var(--Outline-Border-Color, #E5E7EB);"
                          justifyContent="space-between"
                          onClick={() => setFilterByStatus(item)}
                          data-testid={`status-${item}`}
                        >
                          <Text d="inline-flex" alignItems="center" fontSize="14px" fontWeight={400}>
                            {t(`${item}`)}
                            {filterByStatus === item && (
                              <Box as="span" color="blue.500" fontSize="18px" ml={2}>
                                <IoCheckmarkSharp />
                              </Box>
                            )}
                          </Text>
                          {renderCustomBadge(count.offerMade?.[item as keyof OfferMade])}
                        </Flex>
                      ),
                  )}
              </AccordionPanel>
            </AccordionItem>
          )}
          {count.languageEvaluation > 0 && (
            <AccordionItem
              data-testid="status-languageEvaluation"
              onClick={() => setFilterByStatus('languageEvaluation')}
              borderTop={`1px solid ${colors.neutral[200]}`}
            >
              <h2>
                <AccordionButton>
                  <Flex as="span" flex="1" textAlign="left" alignItems="center">
                    <AiFillAudio style={{ marginRight: '4px' }} color={colors.orange[500]} size="20px" />
                    <Text d="inline-flex" alignItems="center" fontSize="14px" fontWeight={700} ml="8px">
                      {t('languageEvaluation')}
                      {filterByStatus === 'languageEvaluation' && (
                        <Box as="span" color="blue.500" fontSize="18px" ml={2}>
                          <IoCheckmarkSharp />
                        </Box>
                      )}
                    </Text>
                  </Flex>
                  {renderCustomBadge(count.languageEvaluation)}
                  <AccordionIcon color={colors.white} visibility="hidden" />
                </AccordionButton>
              </h2>
            </AccordionItem>
          )}
          {count?.dialogflow > 0 && (
            <AccordionItem
              onClick={() => setFilterByStatus('dialogflow')}
              data-testid="status-dialogflow"
              borderTop={`1px solid ${colors.neutral[200]}`}
              borderBottom={`1px solid ${colors.neutral[200]}`}
            >
              <h2>
                <AccordionButton>
                  <Flex as="span" flex="1" textAlign="left" alignItems="center">
                    <img src={AppyHereIcon} style={{ width: 16 }} alt="" />
                    <Text d="inline-flex" alignItems="center" fontSize="14px" fontWeight={700} ml="8px">
                      {t('conversationalAI')}
                      {filterByStatus === 'dialogflow' && (
                        <Box as="span" color="blue.500" fontSize="18px" ml={2}>
                          <IoCheckmarkSharp />
                        </Box>
                      )}
                    </Text>
                  </Flex>
                  {renderCustomBadge(count.dialogflow)}
                  <AccordionIcon visibility="hidden" />
                </AccordionButton>
              </h2>
            </AccordionItem>
          )}
        </Accordion>
      </Box>
    </Box>
  );
};
