import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';
import { LocalTrack } from 'twilio-video/tsdef/types';

export const usePublicationFailed = (room: Room | null): void => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      const onError = (error: TwilioError, track: LocalTrack) => {
        console.log(error, track);
      };

      room.localParticipant.on('trackPublicationFailed', onError);

      return () => {
        room.localParticipant.off('trackPublicationFailed', onError);
      };
    }
  });
};
