export default {
  status: {
    requested: 'Requested',
    inProgress: 'In Progress',
    inprogress: 'In Progress',
    completed: 'Completed',
    declined: 'Declined',
    approved: 'Approved',
    rejected: 'Rejected',
    needsAction: 'Needs Action',
    needsaction: 'Needs Action',
    helpRequested: 'Help Requested',
    helprequested: 'Help Requested',
  },
  hireDetailInformation: 'Hire form information',
  hireFormType: {
    NEW_HIRE: 'New Hire',
    WORK_PERMIT: 'Work Permit',
    SEASONAL: 'Seasonal Contract',
  },
  hiringInformation: 'Hire Information',
  candidateInformation: 'Candidate Information',
  primaryContactInformation: 'Emergency Contact Information',
  hiringFormStatus: 'Hire Form Status',
  documents: 'Documents',
  contract: 'Contract',
  address: 'Address line 1',
  city: 'City',
  region: 'Region',
  country: 'Country',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  undeclared: 'Un Declared',
  nonBinary: 'Non Binary',
  name: 'Name',
  correspondenceLanguage: 'Correspondence Language',
  english: 'English',
  french: 'French',
  optionalPhoneNumber: 'Alternate Phone Number',
  postalCode: 'Postal Code',
  positionName: 'Position Name',
  dateOfBirth: 'Date of Birth',
  socialInsuranceNumber: 'Social Insurance Number',
  apartment: 'Apartment',
  startingDate: 'Candidate’s earliest availability',
  wage: 'Salary',
  hourly: 'Hourly',
  annually: 'Annually',
  phoneNumber: 'Phone Number',
  jobStatus: 'Classification',
  partTime: 'Part Time',
  fullTime: 'Full Time',
  worksHoursPerWeek: 'Work Hours per Week',
  businessName: 'Business Name',
  chequeBook: 'Cheque',
  check: 'Check',
  documentToImageURL: 'Personal Identification Document',
  signature: 'Signature',
  workPermit: 'Work Permit',
  note: 'Recruiter Notes',
  close: 'Close',
  lastName: 'Last Name',
  firstName: 'First Name',
  nickName: 'Preferred Name',
  email: 'Email Address',
  workPermitEndDate: 'Work Permit End Date',
  canadianBankAccountDetails: 'Canadian Bank Account Details',
  americanBankAccountDetails: 'American Bank Account Details',
  branchTransitNumber: 'Branch Transit Number',
  financialInstitutionNumber: 'Financial Institution Number',
  bankAccountNumber: 'Bank Account Number',
  routingNumber: 'Routing Number',
  rejectedReason: 'Rejection Reason',
  noPermissionToViewImage: 'You do not have sufficient permissions to view this image.',
  locationName: 'Location Name',
  completeAddress: 'Complete Address',
  emergencyContact: 'Emergency Contact',
  chequebookImageUrl: 'Void Cheque Image',
  codeofconductandethics: 'Code of Conduct and Ethics',
  privacynoticeforemployees: 'Privacy Notice for Employees',
  electronicmonitoringpolicy: 'Electronic Monitoring Policy',
};
