import _find from 'lodash/find';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';
import _forEach from 'lodash/forEach';
import { Recruiter } from '../../../../../firebase/firestore/documents/recruiter';
import { PositionNote } from './positionNote';
import { Candidate, SeekerNote } from '../../../../../firebase/firestore/documents/candidate';
import { Note } from './note';
import { getTranslatedValue } from '../../../../../utils/localizedString';

export default class SeekerNotesPresenter {
  public positionNotes: PositionNote[] = [];

  public candidates: Candidate[];

  public locale: string;

  public recruiters: Recruiter[] = [];

  private readonly recruiterId: string;

  constructor(seekerNotes: SeekerNote[], candidates: Candidate[], recruiters: Recruiter[], recruiterId: string, locale: string) {
    this.locale = locale;
    this.candidates = candidates;
    this.recruiters = recruiters;
    this.recruiterId = recruiterId;

    this.getNotePositions(seekerNotes);
  }

  getLatestNoteDate = (notes: Note[]): number => {
    if (notes.length === 0) return 0;

    const latestNoteDate = Math.max(
      ...notes.map((note) => {
        if (Array.isArray(note.note)) {
          return Math.max(...note.note.map((item) => new Date(item.lastEdited).getTime()));
        }
        return 0;
      }),
      0,
    );

    return latestNoteDate;
  };

  getNotePositions = (seekerNotes: SeekerNote[]): PositionNote[] => {
    const groupedPositionNotes = _groupBy(seekerNotes, 'position');
    _forEach(groupedPositionNotes, (v, k) => {
      const notePosition = this.getNotePosition(v, k);
      if (notePosition) this.positionNotes.push(notePosition);
    });

    const { positionNotes } = this;

    positionNotes.sort((a, b) => {
      const latestNoteA = this.getLatestNoteDate(a.notes);
      const latestNoteB = this.getLatestNoteDate(b.notes);

      return latestNoteB - latestNoteA; // Sort positionNotes in descending order of latest note
    });

    positionNotes.forEach((position) => {
      position.notes.sort((a, b) => {
        const latestNoteDateA = Array.isArray(a.note)
          ? Math.max(...a.note.map((item) => new Date(item.lastEdited).getTime()), 0)
          : 0;
        const latestNoteDateB = Array.isArray(b.note)
          ? Math.max(...b.note.map((item) => new Date(item.lastEdited).getTime()), 0)
          : 0;
        return latestNoteDateB - latestNoteDateA;
      });

      position.notes.forEach((noteItem) => {
        const { note } = noteItem;
        if (Array.isArray(note)) {
          note.sort((a, b) => new Date(b.lastEdited).getTime() - new Date(a.lastEdited).getTime());
        }
      });
    });

    return positionNotes;
  };

  /* eslint-disable consistent-return */
  getNotePosition = (seekerNote: SeekerNote[], positionId: string): PositionNote | undefined => {
    const candidateByPosition = _find<Candidate>(this.candidates, { position: positionId });

    if (!candidateByPosition) return undefined;

    const jobTitle = getTranslatedValue(candidateByPosition.jobTitle, this.locale);
    const candidateStatus = candidateByPosition.computedStatus?.status;
    const candidateRating = candidateByPosition.recruiterInterviewReview?.[this.recruiterId]?.rating;
    const { businessName } = candidateByPosition;

    const notes = _map(seekerNote, (n) => {
      const recruiter = _find(this.recruiters, { id: n.recruiter });
      const recruiterName = recruiter ? `${recruiter.firstName} ${recruiter.lastName}` : n.recruiterName;
      const recruiterIdInNote = recruiter ? recruiter?.id : this.recruiterId;
      const recruiterRating = candidateByPosition.recruiterInterviewReview?.[recruiterIdInNote]?.rating;
      return new Note(n, recruiterName, recruiter?.id === this.recruiterId, recruiterIdInNote, recruiterRating, n.type);
    });

    const finalNote = notes.filter((note) => note?.note && note.note?.length > 0);
    // istanbul ignore else
    if (finalNote.length > 0) {
      return new PositionNote(finalNote, positionId, jobTitle, businessName, candidateStatus, candidateRating);
    }
  };
}
