import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Stack,
  Flex,
  HStack,
  Box,
} from '@chakra-ui/core';
import React, { ComponentProps, useCallback, useEffect, useState } from 'react';
import { BiImageAdd } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import colors from '../../../styles/colors';
import ImageGallery from './ImageGallery';
import theme from '../../../styles/customTheme';
import ImageGalleryRefreshButton from './ImageGalleryRefreshButton';
import ImageUploadAction from './ImageUploadAction';
import { GalleryImageType, ImageGalleryUploadResponse } from './ImageGalleryType';
import { useStoreState } from '../../../models/hooks';
import ImageGallerySortButton from './ImageGallerySortButton';
import ImageGallerySearch from './ImageGallerySearch';

interface ImageGalleryModalProps extends Pick<ComponentProps<typeof ImageGallery>, 'selected' | 'onSelected'> {
  triggerAction?: React.ReactNode;
}

/**
 * A modal that displays the image gallery.
 *
 * The modal contains a title, a description, a refresh button, an upload button,
 * a close button, and the image gallery component. The refresh button and the
 * upload button are not rendered if the gallery is currently loading.
 *
 * The modal is used as a dialog to manage and upload image contents.
 *
 * @param {ImageGalleryModalProps} props The props for the modal.
 * @param {GalleryImageType} [props.selected] The currently selected image.
 * @param {(image: GalleryImageType | null) => void} [props.onSelected] The callback function invoked when an image is selected.
 * @param {React.ReactNode} [props.triggerAction] The action to trigger the modal.
 * @returns {React.ReactElement} The React component.
 */
export default function ImageGalleryModal({ selected, onSelected, triggerAction }: ImageGalleryModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<ImageGalleryUploadResponse['data'] | null>(null);
  const { images } = useStoreState((s) => s.imageGallery);

  const { t } = useTranslation();

  const handleModalOpen = () => {
    setIsOpen(true);
  };
  const handleModalClose = useCallback(() => {
    setIsOpen(false);
    setUploadedImage(null);
  }, []);

  useEffect(() => {
    const image = images.find((val) => val.id === uploadedImage?.id);
    if (image) {
      onSelected?.(image);
      handleModalClose();
    }
    // eslint-disable-next-line
  }, [images, uploadedImage]);

  const handleImageSelection = (selectedImage: GalleryImageType | null) => {
    onSelected?.(selectedImage);
    if (selectedImage) {
      handleModalClose();
    }
  };

  return (
    <>
      <Box onClick={handleModalOpen} data-testid="image-gallery-modal-button">
        {triggerAction ?? (
          <Button
            backgroundColor={colors.blue[500]}
            leftIcon={<BiImageAdd size="24" />}
            size="lg"
            colorScheme={colors.blue[500]}
            data-testid="image-gallery-modal-trigger-button"
          >
            {t('administration:imageGalleryModal:triggerButtonLabel')}
          </Button>
        )}
      </Box>

      <Modal
        onClose={handleModalClose}
        onEsc={handleModalClose}
        preserveScrollBarGap
        isOpen={isOpen}
        isCentered
        size="4xl"
        scrollBehavior="inside"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent data-testid="image-gallery-modal" height="-webkit-fill-available">
          <ModalHeader>
            <Flex justifyContent="space-between">
              <Stack spacing={0}>
                <Text fontSize="xl">{t('administration:imageGalleryModal:title')}</Text>
                <Text fontSize="sm" fontWeight="normal" color={theme.colors.gray[500]}>
                  {t('administration:imageGalleryModal:subtitle')}
                </Text>
              </Stack>
              <HStack spacing={4}>
                <HStack spacing={2}>
                  <ImageGallerySearch size="md" iconSize={20} />
                  <ImageGallerySortButton size="md" iconSize={20} />
                  <ImageGalleryRefreshButton size="md" iconSize={20} />
                  <ImageUploadAction triggerButtonProps={{ size: 'md', iconSize: 22 }} onImageUploadSuccess={setUploadedImage} />
                </HStack>
                <ModalCloseButton position="unset" size="lg" data-testid="modal-close-button" />
              </HStack>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <ImageGallery selected={selected} onSelected={handleImageSelection} />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}

ImageGalleryModal.defaultProps = {
  triggerAction: null,
};
