import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Text,
  Badge,
  Input,
  Stack,
  InputGroup,
  InputLeftElement,
  Center,
  Spinner,
  IconButton,
  ButtonGroup,
} from '@chakra-ui/core';
import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { RiEditBoxLine } from 'react-icons/ri';
import { useRouteMatch } from 'react-router-dom';
import { CgChevronDown } from 'react-icons/cg';
import { numberOfJobsChips, noBusinessMsg, openPotionTitle } from './BusinessListStyle';
import { CreateBusinessView } from '../createBusiness/CreateBusinessView';
import { useStoreActions } from '../../../../models/hooks';
import { Business, BusinessListQueryParams, BusinessListResponse } from '../BusinessType';
import { defaultPositionListParams, usePositionManagementContext } from '../PositionManagementContext';

const defaultParams: BusinessListQueryParams = {
  sortBy: 'name',
  sortOrder: 'asc',
  page: 1,
  limit: 20,
  onlyFollowedBusiness: true,
  searchQuery: '',
};

const defaultBusinessListResponse: BusinessListResponse = {
  count: 0,
  lastPage: 0,
  to: 0,
  from: 0,
  data: [],
};
const defaultBusinessData: Business = {
  id: '0',
  name: '',
  locationId: '',
  cityName: '',
  address: '',
  country: 'Canada',
  latitude: 0,
  longitude: 0,
  zip: '',
  state: '',
  phoneNumber: '',
  webSite: '',
  completeAddress: '',
  placeId: '',
  positions: 0,
};

export type BusinessListViewProps = {
  changeActiveBusiness: (business: Business) => void;
  isAdministrationAccount: boolean;
  activeBusiness: Business | null;
  scrollToBusiness: Business | null;
  setScrollToBusiness: (business: Business | null) => void;
};

export const BusinessListView = ({
  changeActiveBusiness,
  isAdministrationAccount,
  activeBusiness,
  scrollToBusiness,
  setScrollToBusiness,
}: BusinessListViewProps): JSX.Element => {
  const { t } = useTranslation('businessManagement');
  const [showBusinessList, setShowBusinessList] = useState<boolean>(true);
  const [createNewBusiness, setCreateNewBusiness] = useState<boolean>(true);
  const [loadingBusiness, setLoadingBusiness] = useState<boolean>(true);
  const [queryParams, setQueryParams] = useState<BusinessListQueryParams>(defaultParams);
  const { getBusinessList } = useStoreActions((actions) => actions.businessManagement);
  const [businessListData, setBuinessListData] = useState<BusinessListResponse>(defaultBusinessListResponse);
  const [bussinessDataToEdit, setBusinessDataToEdit] = useState<Business>(defaultBusinessData);
  const bussinessRef = useRef<null | HTMLDivElement>(null);
  const match = useRouteMatch();
  const isCurrentPage = (path: string): boolean => match.url.includes(path);
  const { setPositionQueryParams, setBusinessData } = usePositionManagementContext();
  const onGetBusinessList: (loadMore: boolean) => void = async (loadMore) => {
    setLoadingBusiness(true);
    const data: BusinessListResponse = await getBusinessList({ params: queryParams });

    if (data) {
      businessListData.data = loadMore ? businessListData.data.concat(data.data) : data.data;

      businessListData.lastPage = data.lastPage;
      setBuinessListData({ ...businessListData });
      setBusinessData(businessListData.data);
      setLoadingBusiness(false);
    }
  };

  const onSuccessCallBack = (business: Business) => {
    const updatedBusinessListData = businessListData.data.map((businessItem) =>
      businessItem.id === business.id ? business : businessItem,
    );
    setBuinessListData({ ...businessListData, data: updatedBusinessListData });
    setShowBusinessList(true);
    const selectorId = `#elementID_${business.id}`;
    const section = document.querySelector(selectorId);
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const onLoadGetBusinessList = () => {
    queryParams.page = 1;
    setQueryParams({ ...queryParams });
    onGetBusinessList(false);
    setShowBusinessList(true);
  };

  useEffect(() => {
    onLoadGetBusinessList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.onlyFollowedBusiness]);

  const loadMoreBusiness = () => {
    queryParams.page += 1;
    setQueryParams({ ...queryParams });
    onGetBusinessList(true);
  };

  const onCloseCallBack = (businessId: string) => {
    setShowBusinessList(true);
    setBusinessDataToEdit(defaultBusinessData);
    const selectorId = `#elementID_${businessId}`;

    setTimeout(() => {
      const section = document.querySelector(selectorId);
      section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 5);
  };

  const onHandleCreateBtn = () => {
    setCreateNewBusiness(true);
    setShowBusinessList(false);
  };

  const onHandleEditBtn = ({ business }: { business: Business }) => {
    bussinessRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setBusinessDataToEdit(business);
    setShowBusinessList(false);
    setCreateNewBusiness(false);
  };

  const onChangeSearchText = (value: string) => {
    setQueryParams((prevData) => ({
      ...prevData,
      searchQuery: value,
      page: 1,
    }));
  };

  const onGetBusinessByLocation = (onlyFollowedBusiness: boolean) => {
    queryParams.onlyFollowedBusiness = onlyFollowedBusiness;
    setQueryParams({ ...queryParams });
  };

  useEffect(() => {
    const selectorId = `#elementID_${scrollToBusiness?.id}`;
    const section = document.querySelector(selectorId);
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    const updatedBusinessListData = businessListData.data.map((businessItem) =>
      businessItem.id === scrollToBusiness?.id ? scrollToBusiness : businessItem,
    );
    setBuinessListData({ ...businessListData, data: updatedBusinessListData });

    setScrollToBusiness(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToBusiness, setScrollToBusiness]);

  return (
    <Box ref={bussinessRef} data-testid="businessListSection">
      {showBusinessList ? (
        <Box>
          {!activeBusiness && (
            <Box data-testid="businessList">
              <Stack className="search-bar" spacing={4} direction={['column', 'row']}>
                <Box backgroundColor="white" borderRadius={5} mb={6} px={6} py={4} w="100%">
                  <Stack spacing={4}>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.300" />
                      </InputLeftElement>
                      <Input
                        data-testid="searchInput"
                        type="text"
                        value={queryParams.searchQuery}
                        placeholder={t('businessList.searchBusiness')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeSearchText(e.target.value)}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && onGetBusinessList(false)}
                      />
                    </InputGroup>
                  </Stack>
                </Box>
                <Box backgroundColor="white" borderRadius={5} mb={6} px={6} py={4}>
                  <ButtonGroup isAttached>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      data-testid="myLocationBtn"
                      variant={queryParams.onlyFollowedBusiness ? 'solid' : 'outline'}
                      onClick={() => onGetBusinessByLocation(true)}
                    >
                      {t('businessList.myLocations')}
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      data-testid="allLocationBtn"
                      variant={!queryParams.onlyFollowedBusiness ? 'solid' : 'outline'}
                      onClick={() => onGetBusinessByLocation(false)}
                    >
                      {t('businessList.allLocations')}
                    </Button>
                  </ButtonGroup>
                </Box>
              </Stack>
              <Box className="business-location-list" backgroundColor="white" borderRadius={5} mb={6} style={{ padding: 18 }}>
                <Flex mb={3} alignItems="center" className="business-location-item-padding">
                  <Box w={isAdministrationAccount ? '81%' : '86%'} mr={6}>
                    <Heading as="h1" size="14px">
                      <b>{t('businessList.business')}</b>
                    </Heading>
                    <Text fontSize="12px" color="gray.500">
                      {t('businessList.listOfBusiness')}
                    </Text>
                  </Box>
                  <Flex
                    w="19%"
                    alignItems="center"
                    justifyContent="center"
                    mr={3}
                    pr={1}
                    borderRight={isAdministrationAccount && !isCurrentPage('/positions') ? '1px solid #f3f6f9' : 'unset'}
                  >
                    <Badge css={openPotionTitle} className="openPotionTitle">
                      <Box as="span" fontWeight="bold" fontSize="sm">
                        {t('businessList.openPositions')}
                      </Box>
                    </Badge>
                  </Flex>

                  {isAdministrationAccount && !isCurrentPage('/positions') && (
                    <Box w="10%">
                      <Button
                        colorScheme="blue"
                        data-testid="createBusinessBtn"
                        style={{ float: 'right' }}
                        size="sm"
                        onClick={onHandleCreateBtn}
                      >
                        {t('businessList.create')}
                      </Button>
                    </Box>
                  )}
                </Flex>
                <Fragment>
                  {!loadingBusiness && businessListData.data.length <= 0 && (
                    <Box className="noBusinessMsg" css={noBusinessMsg} data-testid="noBusinessCreated">
                      <Center>{t('businessList.noBusinessCreated')}</Center>
                    </Box>
                  )}

                  {businessListData.data.map((item: Business) => (
                    <Box
                      py={2}
                      key={item.id}
                      cursor="pointer"
                      id={`elementID_${item.id}`}
                      className="business-location-item-padding"
                    >
                      <Divider borderColor="#ECEFF1" />
                      <Flex style={{ paddingTop: 16 }}>
                        <Box
                          w={isAdministrationAccount ? '79%' : '84%'}
                          mr={6}
                          data-testid={`Business_${item.id}`}
                          onClick={() => {
                            changeActiveBusiness(item);
                            setPositionQueryParams(defaultPositionListParams);
                          }}
                        >
                          {item.locationId && item.locationId !== '' && (
                            <Heading
                              as="h3"
                              fontSize="13px"
                              fontWeight={600}
                              lineHeight={1.4}
                              color="gray.500"
                              style={{ whiteSpace: 'pre-wrap' }}
                            >
                              {`${t('createBusiness.businessId')}: ${item.locationId}`}
                            </Heading>
                          )}
                          <Heading as="h1" size="16px" style={{ whiteSpace: 'pre-wrap' }}>
                            <b>{item.name}</b>
                          </Heading>
                          <Text fontSize="12px" color="gray.500" style={{ whiteSpace: 'pre-wrap' }}>
                            {item.completeAddress}
                          </Text>
                        </Box>
                        <Box
                          w="16%"
                          mr={6}
                          data-testid={`Business_Position_${item.id}`}
                          onClick={() => {
                            changeActiveBusiness(item);
                            setPositionQueryParams(defaultPositionListParams);
                          }}
                        >
                          <Badge css={numberOfJobsChips} className="jobBadge">
                            <Box as="span" color="blue.500" style={{ fontWeight: 'bold' }}>
                              {`${item.positions} ${item.positions !== 1 ? t('businessList.jobs') : t('businessList.job')}`}
                            </Box>
                          </Badge>
                        </Box>
                        <Box className="go-arrow" d="none" fontSize="20px" color="blue.500">
                          <CgChevronDown />
                        </Box>
                        {isAdministrationAccount && !isCurrentPage('/positions') && (
                          <Box
                            w="10%"
                            data-testid="editBtn"
                            onClick={() => onHandleEditBtn({ business: item })}
                            style={{ justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <IconButton
                              size="sm"
                              fontSize="1.3rem"
                              colorScheme="blue"
                              aria-label={t('jobPosition:edit')}
                              icon={<RiEditBoxLine />}
                              mr={3}
                            />
                          </Box>
                        )}
                      </Flex>
                    </Box>
                  ))}
                </Fragment>

                {loadingBusiness && (
                  <Center>
                    <Spinner color="blue.500" />
                  </Center>
                )}
              </Box>
              {businessListData.lastPage === queryParams.page || businessListData.data.length <= 0 ? (
                ''
              ) : (
                <Center>
                  <Button onClick={loadMoreBusiness} data-testid="loadMoreBtn" colorScheme="blue">
                    {t('businessList.loadMore')}
                  </Button>
                </Center>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <CreateBusinessView
          successCallBack={onSuccessCallBack}
          closeCallBack={onCloseCallBack}
          businessData={bussinessDataToEdit}
          createNewBusiness={createNewBusiness}
        />
      )}
    </Box>
  );
};
