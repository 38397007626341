import React, { useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  useClipboard,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/core';
import { RiFileCopyLine, RiCheckboxMultipleFill, RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from '../../../../models/hooks';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
} from '../../../../utils/FormErrorUtils';
import Config from '../../../../firebase/Config';
import { CertnFormData } from '../CertnType';

export type CertnKeysAndUrlProps = {
  accountId: string | undefined;
};

export const CertnKeysAndUrl = ({ accountId }: CertnKeysAndUrlProps): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showApiKey, setShowApiKey] = React.useState(false);
  const [hasCertnSubscribed, setHasCertnSubscribed] = React.useState(false);
  const [certnFormData, setCertnFormData] = React.useState({
    apiKey: '',
    webhookSignature: '',
  } as CertnFormData);

  const { hasCopied, onCopy } = useClipboard(
    certnFormData.webhookRoute ? `${Config.serverUrl}${certnFormData.webhookRoute}` : '',
  );

  const toast = useToast();

  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const { saveCertnSecrets, getCertnSecrets } = useStoreActions((actions) => actions.administration);

  const handleChange = (value: string, fieldName: string) => {
    setCertnFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      apiKey: Joi.string()
        .required()
        .messages({
          'string.empty': t('certn:apiKeyRequired'),
        }),
      webhookRoute: Joi.string().allow('').allow(null).optional(),
      webhookSignature: Joi.string()
        .required()
        .messages({
          'string.empty': t('certn:webhookSecretRequired'),
        }),
    });

  const formValidation = (formData: CertnFormData) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = schema.validate(formData);

    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const getCertnSecret = useCallback(async () => {
    if (accountId) {
      const getCertnSecretsResponse = await getCertnSecrets({ accountId });
      setCertnFormData(getCertnSecretsResponse);
      if (getCertnSecretsResponse.apiKey) {
        setHasCertnSubscribed(true);
      }
    }
  }, [accountId, getCertnSecrets]);

  const submitCertnSecret = async () => {
    if (formValidation(certnFormData) && accountId) {
      try {
        setIsLoading(true);
        const certnResponse = await saveCertnSecrets({ ...certnFormData, accountId });

        toast({
          title: certnResponse.message,
          duration: 3000,
          isClosable: true,
        });
        await getCertnSecret();
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getCertnSecret();
  }, [getCertnSecret]);

  return (
    <Box>
      <Box px={6} py={4}>
        <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
          {t('certn:keysUrlLabel')}
        </Heading>
        <Text fontSize="xs" color="gray.500">
          {t('certn:linkCertnLabel')}
        </Text>
      </Box>
      <Divider borderColor="#ECEFF1" />
      <Box p={6}>
        <FormControl id="apiKey" mb={5} isInvalid={hasErrorMessage(formErrors, 'apiKey')} isRequired>
          <FormLabel fontSize="sm" fontWeight="700">
            {t('certn:apiKeyLabel')}
          </FormLabel>
          <InputGroup size="md">
            <Input
              data-testid="ApiKeyInput"
              backgroundColor="#F5F8FA"
              borderWidth={0}
              pr="4.5rem"
              type={showApiKey ? 'text' : 'password'}
              placeholder={t('certn:apiKeyPlaceholder')}
              fontSize="sm"
              autoComplete="new-password"
              value={certnFormData.apiKey}
              onChange={(e) => handleChange(e.target.value, 'apiKey')}
            />
            <InputRightElement width="3.5rem">
              <Button
                h="1.75rem"
                size="xl"
                fontSize="xl"
                bg="#F5F8FA"
                color="blue.500"
                pr={0}
                _hover={{ bg: '#F5F8FA' }}
                _active={{ bg: '#F5F8FA', outline: 'none' }}
                _focus={{ outline: 'none' }}
                data-testid="ShowTextBtn"
                onClick={() => setShowApiKey(!showApiKey)}
              >
                {showApiKey ? <RiEyeOffLine /> : <RiEyeLine />}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{getErrorMessage(formErrors, 'apiKey')}</FormErrorMessage>
        </FormControl>
        {hasCertnSubscribed && (
          <FormControl id="webhookUrl" mb={5}>
            <FormLabel fontSize="sm" fontWeight="700">
              {t('certn:webhookUrlLabel')}
            </FormLabel>
            <Flex w="80%" alignItems="center">
              <Box flex={1}>
                <Text px={0} color="blue.500">
                  {`${Config.serverUrl}${certnFormData.webhookRoute}`}
                </Text>
              </Box>
              <Box>
                <Button
                  onClick={onCopy}
                  ml={2}
                  fontSize="2xl"
                  bg="#F5F8FA"
                  color="blue.500"
                  _hover={{ bg: '#F5F8FA' }}
                  _active={{ bg: '#F5F8FA' }}
                >
                  {/* istanbul ignore next */ hasCopied ? <RiCheckboxMultipleFill /> : <RiFileCopyLine />}
                </Button>
              </Box>
            </Flex>
          </FormControl>
        )}
        <FormControl id="webhookSignature" mb={5} isInvalid={hasErrorMessage(formErrors, 'webhookSignature')} isRequired>
          <FormLabel fontSize="sm" fontWeight="700">
            {t('certn:webhookSecretLabel')}
          </FormLabel>
          <Input
            data-testid="WebHookSignatureInput"
            fontSize="sm"
            backgroundColor="#F5F8FA"
            borderWidth={0}
            type="text"
            value={certnFormData.webhookSignature}
            onChange={(e) => handleChange(e.target.value, 'webhookSignature')}
          />
          <FormErrorMessage>{getErrorMessage(formErrors, 'webhookSignature')}</FormErrorMessage>
        </FormControl>
        <Box textAlign="right" mb={10}>
          <Button
            isLoading={isLoading}
            data-testid="SubmitBtn"
            mt={4}
            colorScheme="blue"
            type="submit"
            onClick={() => submitCertnSecret()}
          >
            {t('certn:submitBtn')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
