import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { RecruiterManagementController } from '../../../controllers/recruiterManagementController';

import {
  PieAndStatInfoResponse,
  RecruiterListQueryParams,
  RecruiterListResponse,
  GetRecruitersProfileDataQueryParams,
  RecruitersProfileDataResponse,
  PieAndStatInfoParams,
} from '../recruiterManagement/RecruiterType';

export type RecruiterManagementStoreModel = {
  recruiterManagement: RecruiterManagementModel;
};

export interface RecruiterManagementModel {
  getRecruiterList: Thunk<
    RecruiterManagementModel,
    { params: RecruiterListQueryParams },
    void,
    HttpStoreModel,
    Promise<RecruiterListResponse>
  >;

  getRecruitersProfileData: Thunk<
    RecruiterManagementModel,
    { params: GetRecruitersProfileDataQueryParams },
    void,
    HttpStoreModel,
    Promise<RecruitersProfileDataResponse>
  >;

  getPieAndStatInfo: Thunk<
    RecruiterManagementModel,
    { params: PieAndStatInfoParams },
    void,
    HttpStoreModel,
    Promise<PieAndStatInfoResponse>
  >;
}

export const recruiterManagementStoreModel: RecruiterManagementModel = {
  getRecruiterList: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new RecruiterManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getRecruiterList(payload.params);
    return response?.data as RecruiterListResponse;
  }),

  getRecruitersProfileData: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new RecruiterManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getRecruitersProfileData(payload.params);
    return response?.data as RecruitersProfileDataResponse;
  }),

  getPieAndStatInfo: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new RecruiterManagementController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getPieAndStatInfo(payload.params);
    return response?.data as PieAndStatInfoResponse;
  }),
};
