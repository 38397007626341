import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { Collections } from '../../../../../firebase/firestore/collections';
import { Position } from '../../../../../firebase/firestore/documents/position';
import { useDocumentSnapshot } from '../../../../../firebase/firestore/hooks';
import { InterviewConfigPresenter } from './interviewConfigPresenter';

export const useInterviewConfig = (): InterviewConfigPresenter | undefined => {
  const { selectedCandidate } = useSeekerContainerContext();
  const position = useDocumentSnapshot<Position>(Collections.POSITIONS, selectedCandidate?.position);

  return selectedCandidate && position ? new InterviewConfigPresenter(position) : undefined;
};
