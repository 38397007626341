import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  Image,
  Spinner,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/core';
import { motion } from 'framer-motion';
import colors from '../../../../../styles/colors';
import { QuestionStateEnum } from './useFreeFormQuestions';
import Label from '../../../../common/Label/Label';
import { questionsViewWrapper } from './Questions.styles';
import QuestionPresenter from './freeFormQuestionPresenter';
import { Candidate } from '../../../../../firebase/firestore/documents/candidate';
import QuestionsPresenter from './questionsPresenter';
import { QuestionType } from '../questions/QuestionType';

const MotionStack = motion.custom(Stack);
const parentVariants = {
  visible: {
    transition: { staggerChildren: 0.05, delayChildren: 0.05 },
  },
  hidden: {
    transition: { staggerChildren: 0.01, staggerDirection: -1 },
  },
};
const childrenVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -200 },
    },
  },
  hidden: {
    y: -10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export type QuestionsProps = StackProps & {
  activeQuestionType: QuestionType;
  candidate?: Candidate;
  state: QuestionStateEnum;
  questions: QuestionsPresenter | undefined;
  initialized: boolean;
};

export const FreeFormQuestions = ({
  className = '',
  padding = 2,
  activeQuestionType,
  state,
  questions,
  initialized,
}: QuestionsProps): JSX.Element => {
  const { t } = useTranslation();

  const renderQuestionResponse = (question: QuestionPresenter) => {
    if (question.answered) {
      return (
        <Text fontSize="sm" textAlign="left" borderRadius="14px" fontWeight="normal">
          {question.answer}
        </Text>
      );
    }
    return (
      <Text fontSize="sm" textAlign="left" borderRadius="14px" style={{ color: '#000', opacity: '0.2' }}>
        {t('common:freeFormQuestions.unanswered')}
      </Text>
    );
  };

  const questionsCount = questions?.getQuestionsCount() || 0;
  const answeredQuestionsCount = questions?.getAnsweredQuestionsCount() || 0;

  return (
    <Fragment>
      {state === QuestionStateEnum.LOADING && (
        <Center>
          <Spinner color={colors.blue.default} data-testid="Spinner-FreeformQuestion" />
        </Center>
      )}

      {(state === QuestionStateEnum.UNDEFINED || (state === QuestionStateEnum.READY && questionsCount === 0)) &&
        activeQuestionType !== QuestionType.ALL_QUESTIONS && (
          <Center>
            <Flex direction="column" data-testid="NoQuestions" align="center" p={3} textAlign="center">
              <Image src="/questions.svg" w="180px" h="auto" alt="No SpecificQuestions" />
              <Text mt="8">
                <b>{t('questions.noAnswers')}</b>
              </Text>
            </Flex>
          </Center>
        )}

      {state === QuestionStateEnum.READY && (
        <MotionStack
          key="CandidateQuestionsMotionStack"
          initial="hidden"
          animate="visible"
          variants={parentVariants}
          spacing={2}
          padding={padding as number}
          className={['user-selectable', className].join(' ')}
          css={questionsViewWrapper}
          data-testid="SpecificQuestions"
          overflow-Y="visible"
          bg="red"
        >
          <Flex justify="flex-start" align="baseline">
            {questionsCount > 0 && (
              <Label color={colors.gray['500']} colorDarkMode={colors.gray['600']} fontWeight="600">
                {t('freeFormQuestions.positiveCount', { count: answeredQuestionsCount, total: questionsCount })}
              </Label>
            )}
          </Flex>
          {initialized && (
            <Box mt={2}>
              <Accordion defaultIndex={[0]} allowToggle style={{ border: 0 }}>
                {questions?.questions.map((question, index) => (
                  <AccordionItem
                    key={question.id}
                    style={{
                      marginBottom: '-1px',
                      border: `1px solid ${colors.gray['50']}`,
                      background: `${index % 2 ? colors.gray['150'] : 'white'}`,
                    }}
                  >
                    <Box
                      variants={childrenVariants}
                      data-testid="QuestionsQuestion"
                      className={question.answered ? 'answeredBorder' : 'unansweredBorder'}
                    >
                      <AccordionButton
                        p={0}
                        _hover={{ outline: 'none', boxShadow: 'none' }}
                        _active={{ outline: 'none', boxShadow: 'none' }}
                        _focus={{ outline: 'none', boxShadow: 'none' }}
                      >
                        <Box flex="1" textAlign="left">
                          <Text fontSize="sm" p={2}>
                            {`${index + 1}. ${question.text}`}
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel p={2} pb={4} borderTopWidth="1px" borderTopColor="gray.150" borderTopStyle="solid">
                        <Box>{renderQuestionResponse(question)}</Box>
                      </AccordionPanel>
                    </Box>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          )}
        </MotionStack>
      )}
    </Fragment>
  );
};
