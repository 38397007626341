export default {
  pageTitle: 'Management Overview',
  hiringOverview: {
    outstandingOffers: 'Outstanding Offers',
    hiresProcessing: 'Processing Hires',
    backgroundChecksDelayed: 'Background Checks Delayed',
    overdueApprovals: 'Overdue Approvals',
    stalledCandidates: 'Stalled Candidates',
    openRequisitions: 'Open Requisitions',
    atRisk: 'At Risk',
    positionsImpacted: 'Positions Impacted',
    distributionOfAgedRequisitions: 'Requisition Age Distribution',
    unfilledReqs: 'Unfilled Requisitions',
    past10DaysNeedDate: '> 10 days past effective date',
    loadingHiringOverview: 'Loading Management Overview',
    weekLabel: `Weeks`,
    weekAndMore: 'Weeks and more',
    value: 'Reqs: ',
  },
  hireOfferStat: {
    hireOfferStatistics: 'Hire & Offer Stats',
    hireProposed: 'Hires Proposed',
    hireApproved: 'Hires Approved',
    hireRejected: 'Hires Rejected',
    offersMade: 'Offers Made',
    offersAccepted: 'Offers Accepted',
    offersRejected: 'Offers Rejected',
    offersRevoked: 'Offers Revoked',
    hRSubmission: 'Form Submission',
    timeToFill: 'Time to Fill',
    offerMadeCount: 'Offer Made Count',
    offerRejectCount: 'Offer Rejected Count',
    offerAcceptCount: 'Offer Accepted Count',
    offerRevokedCount: 'Offer Revoked Count',
    dismissedCandidateCount: 'Dismissed Candidate Count',
    loadingOfferStatistics: 'Loading Hire & Offer Stats',
    selectDate: 'Select date',
    oneWeek: '1 week',
    twoWeek: '2 weeks',
    oneMonth: '1 month',
    threeMonth: '3 months',
    from: 'From:',
    to: 'To:',
    high: 'high',
    less: 'less',
    hireProposedToolTip: `{{hireCount}} Number of hire forms sent to candidates within the selected time period.`,
    hireApprovedToolTip: `{{hireCount}} Number of hires made within the selected time period.`,
    hireRejectedToolTip: `{{hireCount}} Number of rejected hire forms within the selected time period.`,
    offerMadeToolTip: `{{offerCount}} Number of candidates who received job offers within the selected time period.`,
    offerAcceptToolTip: `{{offerCount}} Number of candidates who accepted their job offer within the selected time period.`,
    offerRejectedToolTip: `{{offerCount}} Number of candidates who rejected their job offer within the selected time period.`,
    offerRevokedToolTip: `{{offerCount}} Number of candidates who have had their offer revoked within the selected time period.`,
  },
};
