import { css } from '@emotion/core';
import colors from '../../../../../styles/colors';

export const confirmationModalHeaderCSS = css`
  &.modal-header {
    color: ${colors.blue[600]};
    padding: 20px 20px 0 20px;
    font-weight: 600;
  }
  .blue-circle-icon {
    background-color: ${colors.blue.icon};
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
`;

export const backgroundCheckStyle = css`
  &.bc-content {

    .bc-description {
      max-height: 20px;
      overflow: hidden;
      transition: max-height 0.15s linear;

      svg {
        cursor: pointer;
      }

      .remove-icon {
        display: none;
      }

      &.more {
      max-height: 1000px;
      height: auto;
      transition: max-height 0.7s linear;

      .add-icon {
        display:none;
      }

      .remove-icon {
        display: block;
      }
    }
  }
`;

export const backgroundCheckForAdminstrationStyle = css`
  &.bc-content {
    .more {
       .bc-description {
          max-height: 1000px;
          height: auto;
          transition: max-height 0.7s linear;

          .add-icon {
            display:none;
          }

          .remove-icon {
            display: block;
          }
       }
    }

    .bc-description {
      max-height: 20px;
      overflow: hidden;
      transition: max-height 0.15s linear;

      svg {
        cursor: pointer;
      }

      .remove-icon {
        display: none;
      }
  }
`;
