/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/core';
import i18next from 'i18next';
import moment from 'moment';
import { getTranslatedValue } from '../../../../../../utils/localizedString';
import { CandidateAvailabilityDuration } from './CandidateAvailabilities';

const weekDays = [
  { en: 'mon-tue', fr: 'lun-mar' },
  { en: 'tue-wed', fr: 'mar-mer' },
  { en: 'wed-thu', fr: 'mer-jeu' },
  { en: 'thu-fri', fr: 'jeu-ven' },
  { en: 'fri-sat', fr: 'ven-sam' },
];

const weekEnd = [
  { en: 'sat-sun', fr: 'sam-dim' },
  { en: 'sun-mon', fr: 'dim-lun' },
];

export type CandidateNightDuration = {
  start_1: string;
  end_1: string;
};

type CandidateNightProps = {
  nightAvailabilities: { [key: string]: { start_1: string; end_1: string } };
};

export const CandidateNightAvailabilities = ({ nightAvailabilities }: CandidateNightProps): JSX.Element => {
  let count = 0;
  return (
    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={2} w="100%">
      <Box>
        {weekDays.map((d) => {
          count += 1;
          const range = d.en;
          const startDay = range.split('-')[0];
          const availability = nightAvailabilities[startDay] as CandidateAvailabilityDuration;

          return (
            <Flex
              justifyContent="space-between"
              key={d.en}
              px={1}
              py={1}
              bg={count % 2 !== 0 ? 'gray.150' : 'white'}
              border="1px solid"
              borderColor="gray.150"
              whiteSpace={{ base: 'normal', md: 'nowrap' }}
              overflow="visible"
            >
              <Box textTransform="capitalize" fontSize={{ base: '10px', md: '11.5px' }} flexShrink={0} mr="2px">
                {getTranslatedValue(d, i18next.language)}
              </Box>
              {availability ? (
                <Flex style={{ float: 'right', justifyContent: 'flex-end' }}>
                  <Text fontSize="11.5px">{moment(availability.start_1, ['HH:mm']).format('LT')}</Text>
                  <Text mx={1} fontSize="11px">
                    -
                  </Text>
                  <Text fontSize="11.5px">{moment(availability.end_1, ['HH:mm']).format('LT')}</Text>
                </Flex>
              ) : (
                <Box fontSize={{ base: '10px', md: '12px' }}>---</Box>
              )}
            </Flex>
          );
        })}
      </Box>
      <Box>
        {weekEnd.map((d) => {
          count += 1;
          const range = d.en;
          const startDay = range.split('-')[0];
          const availability = nightAvailabilities[startDay] as CandidateAvailabilityDuration;

          /* istanbul ignore else */
          return (
            <Flex
              justifyContent="space-between"
              key={d.en}
              px={1}
              py={1}
              bg={count === 7 ? 'gray.150' : 'white'}
              border="1px solid"
              borderColor="gray.150"
              whiteSpace={{ base: 'normal', md: 'nowrap' }}
              overflow="visible"
            >
              <Box textTransform="capitalize" fontSize={{ base: '10px', md: '11.5px' }} flexShrink={0} mr="2px">
                {getTranslatedValue(d, i18next.language)}
              </Box>
              {availability ? (
                <Flex style={{ float: 'right', justifyContent: 'end' }}>
                  <Text fontSize="11.5px">{moment(availability.start_1, ['HH:mm']).format('LT')}</Text>
                  <Text mx={1} fontSize="11px">
                    -
                  </Text>
                  <Text fontSize="11.5px">{moment(availability.end_1, ['HH:mm']).format('LT')}</Text>
                </Flex>
              ) : (
                <Box fontSize={{ base: '10px', md: '12px' }}>---</Box>
              )}
            </Flex>
          );
        })}
      </Box>
    </Grid>
  );
};
