import firebase from 'firebase/app';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useEffect, useMemo } from 'react';
import Bugsnag from '@bugsnag/js';
import { formatDocument } from '../documentConverter';
import { useUserIsAuthenticated } from '../../../app/hooks/useUserIsAuthenticated';
import { Collections, SubCollections } from '../collections';

const getFirestoreSubDocumentReference = (
  userIsAuthenticated: boolean,
  requireAuthentication: boolean,
  collection: Collections,
  subCollection: SubCollections,
  documentId?: string,
  subDocumentId?: string,
): firebase.firestore.DocumentReference | undefined => {
  if (requireAuthentication && !userIsAuthenticated) {
    return undefined;
  }

  if (!documentId || !subDocumentId) {
    return undefined;
  }

  return firebase.firestore().collection(collection).doc(documentId).collection(subCollection).doc(subDocumentId);
};

export const useSubDocumentSnapshot = <T>(
  collection: Collections,
  subCollection: SubCollections,
  documentId?: string,
  subDocumentId?: string,
  requireAuthentication = true,
): T | undefined => {
  const userIsAuthenticated = useUserIsAuthenticated();

  const [subDocumentSnapshot, loading, error] = useDocument(
    getFirestoreSubDocumentReference(
      userIsAuthenticated,
      requireAuthentication,
      collection,
      subCollection,
      documentId,
      subDocumentId,
    ),
  );

  useEffect(() => {
    if (error) {
      Bugsnag.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = `Firestore useSubDocumentSnapshot Error ${collection} ${subCollection}`;
        // eslint-disable-next-line no-param-reassign
        event.groupingHash = `useSubDocumentSnapshot.${collection}.${documentId}.${subCollection}.${subDocumentId}`;
        event.addMetadata('query', {
          document: collection,
          documentId,
          subDocument: subCollection,
          subDocumentId,
        });
      });
    }
  }, [error, collection, documentId, subCollection, subDocumentId]);

  return useMemo(() => {
    if (subDocumentSnapshot && !error && !loading) {
      return formatDocument<T>(subDocumentSnapshot);
    }

    return undefined;
  }, [subDocumentSnapshot, loading, error]);
};
