import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { MdOutlineInfo } from 'react-icons/md';
import _isEmpty from 'lodash/isEmpty';
import { AxiosError } from 'axios';
import colors from '../../../../../styles/colors';
import AudioPlayer from '../../../../common/AudioPlayer/AudioPlayer';
import { AudioRating, RatingType } from './AudioRating';
import { AUDIO_EVALUATION_OPTIONS } from '../../../../../firebase/firestore/constants';
import { getTranslatedValue } from '../../../../../utils/localizedString';
import i18n from '../../../../../locales/i18n';
import {
  Candidate,
  EngagedCandidate,
  LanguageEvaluationRating,
  LanguageEvaluationStatus,
} from '../../../../../firebase/firestore/documents/candidate';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import useLookupsByType from '../../../../../app/hooks/useLookupsByType';
import {
  doesCandidateHasActiveAppointment,
  isCandidateAlreadyDismissed,
  isCandidateAlreadyHired,
  isCandidateHireInReview,
  isCandidateReadOnly,
} from '../../../../../services/CandidateService';
import { useMessagingViewContext } from '../../../MessagingViewContext';
import { Lookups } from '../../../../../firebase/firestore/documents/lookups';
import usePosition from '../../../sidebar/seekerList/usePosition';
import useKnockedOutAutomaticDismissalHoursDelay from '../../../../../app/hooks/useknockedOutAutomaticDismissalHoursDelay';

const MotionStack = motion.custom(Stack);
const parentVariants = {
  visible: {
    transition: { staggerChildren: 0.05, delayChildren: 0.05 },
  },
  hidden: {
    transition: { staggerChildren: 0.01, staggerDirection: -1 },
  },
};

const DEFAULT_LANGUAGE_EVALUATION_PASS_THRESHOLD = 2;

export type EvaluationValueType = {
  ratingString?: LanguageEvaluationRating;
  order?: number;
};

export type AudioReviewProps = {
  candidate: EngagedCandidate | Candidate;
  onCloseLangModel?: () => void;
  isRenderedBy?: string;
  currentTask?: string;
  currentDocId?: string;
  currentCandidateId?: string;
};

export const AudioReview = ({
  candidate,
  onCloseLangModel,
  isRenderedBy,
  currentDocId,
  currentTask,
  currentCandidateId,
}: AudioReviewProps): JSX.Element => {
  const { t } = useTranslation('audioEvaluation');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const { recording, languageEvaluationReview, recordingRequested } = candidate;
  const meetActionDisabled =
    doesCandidateHasActiveAppointment(candidate) ||
    isCandidateAlreadyDismissed(candidate) ||
    isCandidateAlreadyHired(candidate) ||
    isCandidateReadOnly(candidate) ||
    isCandidateHireInReview(candidate.computedStatus?.status);
  const toast = useToast();
  const { updateRecruiterActionStatus } = useStoreActions((actions) => actions.toDoList);
  const userId = useStoreState((states) => states.app.user?.id);
  const knockedOutAutomaticDismissalHoursDelay = useKnockedOutAutomaticDismissalHoursDelay();
  const { onMeetModalOpen } = useMessagingViewContext();
  const audioEvaluationOptions = useLookupsByType(AUDIO_EVALUATION_OPTIONS);

  const getEvaluationLookupFromEvaluationString = useCallback(
    (evaluationId: string) => {
      const evaluation = audioEvaluationOptions.find((eachOption) => eachOption.id === evaluationId);
      return evaluation as Lookups;
    },
    [audioEvaluationOptions],
  );
  const recruiterId = useStoreState((s) => s.app.user?.id);
  const { reviewLanguageEvaluation, requestLanguageEvaluationResubmit } = useStoreActions((actions) => actions.candidate);
  const [evaluationValue, setEvaluationValue] = useState<EvaluationValueType | undefined>(undefined);
  const [evaluationSubmissionLoading, setEvaluationSubmissionLoading] = useState<boolean>(false);
  const [evaluationSubmitted, setEvaluationSubmitted] = useState<boolean>(false);

  const [rating, setRating] = useState<RatingType | undefined>(undefined);
  const [languageEvaluationStatus, setLanguageEvaluationStatus] = useState<LanguageEvaluationStatus | undefined>(
    languageEvaluationReview?.status,
  );

  useEffect(() => {
    if (_isEmpty(languageEvaluationReview)) {
      setEvaluationSubmitted(false);
    }
    if (languageEvaluationReview?.rating && _isEmpty(evaluationValue) && audioEvaluationOptions.length) {
      const evaluationLookup = getEvaluationLookupFromEvaluationString(languageEvaluationReview.rating);
      setEvaluationValue({
        order: evaluationLookup.order,
        ratingString: evaluationLookup.id as LanguageEvaluationRating,
      });
    }
  }, [languageEvaluationReview, audioEvaluationOptions, getEvaluationLookupFromEvaluationString, evaluationValue]);

  useEffect(() => {
    setEvaluationValue(undefined);
  }, [candidate]);

  const positionDetail = usePosition(candidate.position);

  const checkThresholdAndSetEvaluationStatus = (givenOrder: number) => {
    const languageEvaluationPassThreshold =
      positionDetail?.languageEvaluationPassThreshold || DEFAULT_LANGUAGE_EVALUATION_PASS_THRESHOLD;
    if (givenOrder === 5) {
      setLanguageEvaluationStatus(LanguageEvaluationStatus.NEEDS_REVIEW);
    } else if (givenOrder <= languageEvaluationPassThreshold) {
      setLanguageEvaluationStatus(LanguageEvaluationStatus.PASS);
    } else {
      setLanguageEvaluationStatus(LanguageEvaluationStatus.FAIL);
    }
  };

  const onEvaluationChange = (givenEvaluationValueOrder: number) => {
    const evaluationLookup: Lookups | undefined = audioEvaluationOptions.find(
      (option) => option.order === givenEvaluationValueOrder,
    );

    setEvaluationValue({
      ratingString: evaluationLookup?.id as LanguageEvaluationRating,
      order: evaluationLookup?.order,
    });

    if (!_isEmpty(positionDetail) && evaluationLookup?.order) {
      checkThresholdAndSetEvaluationStatus(evaluationLookup.order);
    }
  };

  // const showRemarks =
  //   evaluationValue === LanguageEvaluationRating.LIMITED || evaluationValue === LanguageEvaluationRating.OCCASIONAL;
  //
  // const renderReviewIcon = (renderedBy: 'needsReview' | 'failCandidate', isSelected: boolean) => {
  //   if (isSelected) {
  //     return (
  //       <ImRadioChecked2 color={renderedBy === 'needsReview' ? colors.persianBlue : colors.lightRed} width={14} height={14} />
  //     );
  //   } else {
  //     return <ImRadioUnchecked color={colors.gullGrey} width={14} height={14} />;
  //   }
  // };

  const handleLanguageEvaluationSubmission = async () => {
    setEvaluationSubmissionLoading(true);
    try {
      /* istanbul ignore else */
      if (recording?.language && recording?.phraseId && rating && languageEvaluationStatus) {
        await reviewLanguageEvaluation({
          positionId: candidate.position,
          seekerId: candidate.seeker,
          language: recording.language,
          phraseId: recording.phraseId,
          ...rating,
          rating: LanguageEvaluationRating[evaluationValue?.ratingString as LanguageEvaluationRating],
          status: languageEvaluationStatus,
        });
        /* istanbul ignore next */
        if (isRenderedBy === 'DashboardHome' && currentDocId && currentTask && currentCandidateId && userId) {
          await updateRecruiterActionStatus({
            userId,
            docId: currentDocId,
            status: 'COMPLETED',
            category: currentTask,
            subCategory: null,
            candidateId: currentCandidateId,
          });
        }
      }
      toast({
        title:
          languageEvaluationStatus === LanguageEvaluationStatus.FAIL
            ? t('ratingInfoToastMessage', { hours: knockedOutAutomaticDismissalHoursDelay })
            : t('audioEvaluationSubmission.successful'),
        status: languageEvaluationStatus === LanguageEvaluationStatus.FAIL ? 'error' : 'success',
        duration: 3000,
        isClosable: true,
      });
      setEvaluationSubmissionLoading(false);
      setEvaluationSubmitted(true);
      if (onCloseLangModel) {
        onCloseLangModel();
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const errData: AxiosError = { ...err };
      const { response } = errData;

      /* istanbul ignore next */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (response?.data?.code && response?.data.code === 'account.requisitionDoesNotExist') {
        toast({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          title: t(`${response?.data.code}`),
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: t('audioEvaluationSubmission.unsuccessful'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setEvaluationSubmissionLoading(false);
      return null;
    }
    return false;
  };
  // eslint-disable-next-line consistent-return
  const requestLanguageEvaluationReSubmission = async () => {
    onClose();
    setEvaluationSubmissionLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      candidate.recording?.phraseId &&
        recruiterId &&
        (await requestLanguageEvaluationResubmit({
          candidateId: candidate.id,
          phraseId: candidate.recording?.phraseId,
          recruiterId,
        }));
      toast({
        title: t('audioEvaluationReSubmission.successful'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setEvaluationSubmissionLoading(false);
      setEvaluationSubmitted(true);
      setEvaluationSubmitted(false);
    } catch (err) {
      toast({
        title: t('audioEvaluationReSubmission.unsuccessful'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setEvaluationSubmissionLoading(false);
      return null;
    }
  };

  const getRatingValues = (givenRating: RatingType) => {
    setRating(givenRating);
  };

  const onGetInfo = () => {
    if (languageEvaluationReview) {
      /* istanbul ignore next */
      if (languageEvaluationReview.status === LanguageEvaluationStatus.PASS) {
        return (
          <Text color="green.500" mt="8px">
            {t('ratingInfoPass')}
          </Text>
        );
      }
      /* istanbul ignore next */
      if (languageEvaluationReview.status === LanguageEvaluationStatus.FAIL) {
        return (
          <Text color="red.500" mt="8px">
            {t('ratingInfoFail')}
          </Text>
        );
      }
    }
    return <Text />;
  };

  const isUserDeactivated = candidate && Boolean(candidate.deactivated || candidate.scheduledDeletion);

  return (
    <Fragment>
      <MotionStack
        key="CandidateQuestionsMotionStack"
        initial="hidden"
        animate="visible"
        variants={parentVariants}
        spacing={2}
        padding={2}
        className={['user-selectable', ''].join(' ')}
        data-testid="AudioRecording"
      >
        {recordingRequested ? (
          <Box fontSize="16px" fontWeight={500} textAlign="center" color="#36464E">
            {t('resubmissionMsg')}
          </Box>
        ) : (
          <Box borderRadius="6px" borderColor="#D4D7D9">
            <Box>
              <Flex alignItems="center" mb={2}>
                <Text fontSize="14px" fontWeight="700" color={colors.gray[800]}>
                  {t('phrase')}
                </Text>
              </Flex>

              <Flex alignItems="center">
                <Text fontSize="14px" fontWeight="500" color={colors.slate} lineHeight="20px">
                  {recording?.phrase}
                </Text>
              </Flex>

              <Flex alignItems="center" mt={3}>
                {recording?.url && <AudioPlayer audioSource={recording.url} />}
              </Flex>

              <Box className="audioEvaluationBox" textAlign="center">
                <Flex alignItems="center" mb={2} mt={4}>
                  <Text fontSize="14px" fontWeight="700" color={colors.gray[800]}>
                    {t('evaluateAudio')}
                  </Text>
                </Flex>

                <Flex wrap="wrap">
                  <RadioGroup
                    width="100%"
                    onChange={/* istanbul ignore next */ (value) => onEvaluationChange(Number(value))}
                    value={Number(evaluationValue?.order)}
                    data-testid="radio"
                  >
                    <Flex direction="column" wrap="wrap" justifyContent="space-between" mb={1}>
                      {audioEvaluationOptions.map((option) => (
                        <Flex alignItems="center" mb={4} key={option.id} position="relative">
                          <Radio
                            value={option.order}
                            mr={2}
                            fontSize="14px"
                            data-testid={`evaluation-${option.order}`}
                            isDisabled={
                              !_isEmpty(languageEvaluationReview) ||
                              evaluationSubmissionLoading ||
                              evaluationSubmitted ||
                              isUserDeactivated
                            }
                            _disabled={{
                              borderColor: option.order === evaluationValue?.order ? '#1F3CBA !important' : '#a1b7c2',
                              color: '#FFFFFF !important',
                              background: option.order === evaluationValue?.order ? '#1F3CBA !important' : 'inherit',
                            }}
                            _active={{
                              borderColor: '#1F3CBA',
                              background: '#1F3CBA',
                            }}
                            _selected={{
                              borderColor: '#1F3CBA !important',
                              background: '#1F3CBA !important',
                            }}
                          >
                            {getTranslatedValue(option.label, i18n.language)}
                          </Radio>

                          <Tooltip
                            hasArrow
                            placement="right"
                            label={option.placeholderText && getTranslatedValue(option.placeholderText, i18n.language)}
                            color={colors.white}
                            bg={colors.black}
                          >
                            <Box>
                              <MdOutlineInfo />
                            </Box>
                          </Tooltip>
                        </Flex>
                      ))}
                      {!languageEvaluationReview && (
                        <Flex
                          alignItems="center"
                          mb={4}
                          data-testid="reRequestAudioBtn"
                          position="relative"
                          onClick={() => onOpen()}
                        >
                          <Radio
                            mr={2}
                            fontSize="14px"
                            isDisabled={evaluationSubmissionLoading || evaluationSubmitted || isUserDeactivated}
                            _disabled={{
                              borderColor: '#a1b7c2',
                              color: '#FFFFFF !important',
                              background: 'inherit',
                            }}
                            _active={{
                              borderColor: '#1F3CBA',
                              background: '#1F3CBA',
                            }}
                            _selected={{
                              borderColor: '#1F3CBA !important',
                              background: '#1F3CBA !important',
                            }}
                          >
                            {t('resubmitAudio')}
                          </Radio>

                          <Tooltip
                            hasArrow
                            placement="right"
                            label={t('resubmitAudioTooltip')}
                            color={colors.white}
                            bg={colors.black}
                          >
                            <Box>
                              <MdOutlineInfo />
                            </Box>
                          </Tooltip>
                        </Flex>
                      )}
                    </Flex>
                  </RadioGroup>
                </Flex>
              </Box>

              {evaluationValue?.ratingString && (
                <Box className="audioRatingBox" mt={2}>
                  <AudioRating
                    evaluationValue={evaluationValue?.ratingString}
                    sendRatingToParent={getRatingValues}
                    languageEvaluationReview={languageEvaluationReview}
                    disableRating={evaluationSubmissionLoading || evaluationSubmitted}
                  />
                </Box>
              )}

              {onGetInfo()}

              <Flex alignItems="flex-end">
                {!_isEmpty(languageEvaluationReview) && languageEvaluationStatus === LanguageEvaluationStatus.NEEDS_REVIEW && (
                  <Box alignItems="flex-end" width="100%" textAlign="right" mr={2} mt={3}>
                    <Button
                      onClick={onMeetModalOpen}
                      backgroundColor={colors.blue.default}
                      color={colors.white}
                      style={{ marginBottom: 10 }}
                      data-testid="meetReqAfterNeedsReviewBtn"
                      _disabled={{
                        opacity: 0.5,
                        cursor: 'not-allowed',
                        boxShadow: 'none',
                      }}
                      disabled={meetActionDisabled || isUserDeactivated}
                    >
                      {t('sendInterviewRequest')}
                    </Button>
                  </Box>
                )}

                {_isEmpty(languageEvaluationReview) && !evaluationSubmitted && (
                  <Box alignItems="flex-end" width="100%" textAlign="right" mr={2} mt={3}>
                    <Button
                      onClick={() => handleLanguageEvaluationSubmission()}
                      disabled={!evaluationValue || evaluationSubmissionLoading || isUserDeactivated}
                      backgroundColor={colors.blue.default}
                      color={colors.white}
                      style={{ marginBottom: 10 }}
                      data-testid="submitBtn"
                      isLoading={evaluationSubmissionLoading}
                    >
                      {t('submit')}
                    </Button>
                  </Box>
                )}
              </Flex>
            </Box>
          </Box>
        )}
      </MotionStack>
      {isOpen && (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {t('reRequestAudio.title')}
              </AlertDialogHeader>

              <AlertDialogBody>{t('reRequestAudio.subTitle')}</AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  onClick={onClose}
                  variant="outline"
                  disabled={evaluationSubmissionLoading}
                  isLoading={evaluationSubmissionLoading}
                >
                  {t('reRequestAudio.cancel')}
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => requestLanguageEvaluationReSubmission()}
                  ml={3}
                  data-testid="requestLanguageEvaluationReSubmissionBtn"
                  disabled={evaluationSubmissionLoading}
                  isLoading={evaluationSubmissionLoading}
                >
                  {t('reRequestAudio.reRequest')}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </Fragment>
  );
};
