import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { StarIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import _isNil from 'lodash/isNil';
import { AppointmentType } from '../../firebase/firestore/documents/position';
import useLookupsByType from '../../app/hooks/useLookupsByType';
import { getTranslatedValue } from '../../utils/localizedString';
import { CandidateInfoForGuest } from '../meet/MeetTokenResponse';
import { useStoreActions, useStoreState } from '../../models/hooks';

export type GuestInterviewRatingProps = {
  candidateInfo: CandidateInfoForGuest;
  showReviewRating: boolean;
  setShowReviewRating: (isOpen: boolean) => void;
  quitRoom: () => void;
};

export const GuestInterviewRating = ({
  candidateInfo,
  showReviewRating,
  setShowReviewRating,
  quitRoom,
}: GuestInterviewRatingProps): JSX.Element => {
  const [rating, setRating] = useState(0);
  const [reason, setReason] = useState('');
  const [remarks, setRemarks] = useState('');
  const interviewDate = new Date();
  const interviewType = AppointmentType.video;
  const [submitting, setSubmitting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loggedInUserName = useStoreState((s) => s.app.user?.firstName);

  const ratingStars: number[] = Array.from({ length: 5 }, (_, i) => i);
  const lookups = useLookupsByType('negativeAssessmentReasons');
  const { t, i18n } = useTranslation('messaging');
  const toast = useToast();
  const isDisabled = (): boolean => {
    if (!remarks.trim()) return true;
    if (rating > 1) return false;
    if (rating <= 1 && reason) return false;
    return true;
  };
  const signOut = useStoreActions((actions) => actions.auth.signOut);
  const { collaboratorRateInterview } = useStoreActions((actions) => actions.meet);

  const handleSubmit = async (): Promise<void | null> => {
    const pathString = window.location.pathname.split('/');
    const splitCandidateId = pathString[2].split('_');
    const seekerId = splitCandidateId[1];
    const positionId = splitCandidateId[0];

    try {
      // istanbul ignore else
      if (!_isNil(interviewDate) && !_isNil(loggedInUserName)) {
        setSubmitting(true);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await collaboratorRateInterview({
          positionId,
          seekerId,
          rating,
          assessmentReason: reason,
          date: interviewDate,
          interviewType,
          businessName: candidateInfo?.businessLocation,
          collaborator: loggedInUserName,
          review: remarks,
        });
      }
      if (showReviewRating) {
        quitRoom();
        // eslint-disable-next-line  @typescript-eslint/no-floating-promises
        signOut();
      } else {
        setRating(0);
        setReason('');
        setRemarks('');
        toast({
          title: t('interviewRating.successful'),
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (e) {
      toast({
        title: t('interviewRating.unsuccessful'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setSubmitting(false);
    onClose();
  };

  useEffect(() => {
    if (showReviewRating) {
      onOpen();
    }
  });

  return (
    <>
      <Button data-testid="candidateAssessmentBtn" colorScheme="blue" size="sm" onClick={onOpen}>
        {t('interviewRating.title')}
      </Button>
      <Modal
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
          setShowReviewRating(false);
        }}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent borderRadius="20px" maxWidth="55rem">
          <ModalCloseButton data-testid="closeBtn" />
          <ModalBody style={{ marginTop: '20px', padding: '25px' }} data-testid="InterviewRatingModal">
            <Box mb={6}>
              <Heading as="h5" size="md">
                {t('interviewRating.title')}
              </Heading>
            </Box>
            <Box>
              <FormControl isRequired>
                <FormLabel>{t('interviewRating.feedbackDetail')}</FormLabel>
              </FormControl>
              <Box mt={5} mb={6}>
                {ratingStars.map((k) => {
                  const val: number = k + 1;
                  let color = '#FF6600';
                  if (rating < val) {
                    color = '#FFFFFF';
                  }

                  return (
                    <StarIcon
                      boxSize={7}
                      color={color}
                      style={{ marginRight: '10px', cursor: 'pointer', stroke: '#FF6600' }}
                      onClick={() => {
                        setRating(val);
                        setReason('');
                      }}
                      key={k}
                      data-testid="RatingStars"
                    />
                  );
                })}
              </Box>
              {rating && rating <= 1 ? (
                <RadioGroup
                  onChange={(rsn) => {
                    setReason(rsn as string);
                  }}
                  name="lowReason"
                  value={reason}
                  mt={5}
                  mb={5}
                  data-testid="RatingReasonsGroup"
                >
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">{t('interviewRating.lowReason')}</FormLabel>
                    <Stack>
                      {lookups.map((lu) => {
                        const label = getTranslatedValue(lu.label, i18n.language);
                        return (
                          <Radio value={lu.id} key={label} data-testid="RatingReasons" paddingBottom="5px">
                            <Text fontSize="sm">{label}</Text>
                          </Radio>
                        );
                      })}
                    </Stack>
                  </FormControl>
                </RadioGroup>
              ) : null}
              <Box>
                <FormControl isRequired>
                  <FormLabel fontWeight="bold">{t('interviewRating.recruitersFeedback')}</FormLabel>
                  <Textarea
                    placeholder={t('interviewRating.commentPlaceholder')}
                    border="none"
                    value={remarks}
                    mt={1}
                    borderRadius="3px"
                    p={4}
                    bg="gray.150"
                    data-testid="RatingRemarksInput"
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <Button
                  colorScheme="blue"
                  style={{ float: 'right', marginTop: '20px' }}
                  isDisabled={isDisabled()}
                  isLoading={submitting}
                  onClick={() => handleSubmit()}
                  name="Submit"
                  data-testid="SubmitRatingButton"
                >
                  {t('actions.hire.confirmationModal.sendButton')}
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
