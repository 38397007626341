import React, { useEffect, useState } from 'react';
import { AvatarBadge, Box, Flex, Text, Popover, PopoverTrigger, PopoverContent, PopoverBody, Tooltip } from '@chakra-ui/core';
import { AiOutlineLike, AiFillLike } from 'react-icons/ai';
import moment from 'moment-timezone';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { FaGraduationCap, FaUserTimes } from 'react-icons/fa';
import { MdOutlineAccountBox } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { ProfilePicture } from '../../common';
import colors from '../../../styles/colors';
import { useStoreState } from '../../../models/hooks';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { SelectedTab, useTopCandidatesContext } from './TopCandidatesContext';
import SeekerExperiencePresenter from '../../messaging/seeker/candidate/profile/seekerExperiencePresenter';
import SeekerEducationPresenter from '../../messaging/seeker/candidate/profile/seekerEducationPresenter';
import { Experience } from '../../messaging/seeker/candidate/profile/components/Experience';
import { Education } from '../../messaging/seeker/candidate/profile/components/Education';
import useEducationLevels from '../../../app/hooks/useEducationLevels';
import { TopCandidateDataType } from './TopCandidateDataType';
import { getTranslatedValue } from '../../../utils/localizedString';
import useCandidate from '../../../app/components/useCandidate';
import { TopCandidateBookmark } from './TopCandidateBookmark';

export type CandidateViewProps = {
  topCandidate: TopCandidateDataType;
  handleContactRequest: (selectedCandidate: TopCandidateDataType) => void;
};

enum CandidateStatus {
  CONTACT_REQUEST_ACCEPTED = 'CONTACT_REQUEST_ACCEPTED',
  CONTACT_REQUESTED = 'CONTACT_REQUESTED',
  LIKED = 'LIKED',
}

export const CandidateView = ({ topCandidate, handleContactRequest }: CandidateViewProps): JSX.Element => {
  const [profileURL, setProfileURl] = useState<string>();
  const { t, i18n } = useTranslation();
  const educationLevels = useEducationLevels();
  const minutesBeforeInactivity = useStoreState((state) => state.remoteConfig[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]);

  const recentlyActive =
    moment(topCandidate.seeker.lastActivity).toDate() > moment().subtract(minutesBeforeInactivity, 'minute').toDate();
  const seekersProfilePictures = useStoreState((state) => state.messaging.seekersProfilePictures);
  const { tab, selectedTopCandidate, setSelectedTopCandidate, positionDocument } = useTopCandidatesContext();

  const candidateExperiences = (
    topCandidate.seeker?.experiences?.map((experience) => new SeekerExperiencePresenter(experience)) ?? []
  ).sort((x, y) => Number(y.current) - Number(x.current));
  const candidateEducations = topCandidate.seeker?.educations?.map((education) => new SeekerEducationPresenter(education)) ?? [];
  const degree = educationLevels.find((edu) => edu.id === topCandidate.education);

  const candidateId = `${positionDocument?.id}_${topCandidate?.seeker?.id}`;
  const selectedCandidate = useCandidate(candidateId);

  useEffect(() => {
    const url = seekersProfilePictures[topCandidate.seeker.id];
    setProfileURl(url);
  }, [topCandidate, seekersProfilePictures]);

  const firstName = topCandidate.seeker.firstName.trim();
  const lastName = topCandidate.seeker.lastName.trim();

  return (
    <Flex
      alignItems="center"
      background={selectedTopCandidate?.seeker?.id === topCandidate.seeker.id ? '#2FA1DA1A' : colors.neutral[50]}
      p="16px"
      borderRadius="8px"
      mt="12px"
      mr="12px"
      ml="12px"
      cursor="pointer"
      _hover={{
        transition: '0.09s ease-in transform',
      }}
      onClick={() => {
        setSelectedTopCandidate(topCandidate);
      }}
    >
      <Flex wrap="wrap" justify="space-between" alignItems="center" width="100%">
        <Flex w={{ base: '100%', md: '48%', lg: '33%' }} alignItems="center" mb={{ base: 4, md: 0 }}>
          {topCandidate.hasApplied ? (
            <AiFillLike color={colors.blue['500']} size="24px" />
          ) : (
            <AiOutlineLike onClick={() => handleContactRequest(topCandidate)} size="24px" />
          )}

          <ProfilePicture
            ml="10px"
            src={profileURL}
            style={{ backgroundColor: colors.orange[500], color: 'white' }}
            name={`${firstName} ${lastName}`}
            bg="transparent"
          >
            {recentlyActive && <AvatarBadge width="14px" height="14px" bg={colors.green['200']} />}
          </ProfilePicture>

          <Box ml="20px">
            <Flex alignItems="center">
              {selectedCandidate?.monetized ? (
                <Text fontWeight={700} fontSize={{ md: '14px', lg: '16px' }} mr="4px">
                  {`${selectedCandidate?.firstName} ${selectedCandidate?.lastName}`}
                </Text>
              ) : (
                <Text fontWeight={700} fontSize={{ md: '14px', lg: '16px' }} mr="4px">
                  {`${topCandidate.seeker.firstName} ${topCandidate.seeker.lastName}`}
                </Text>
              )}
              {tab === SelectedTab.ACTIVE && <TopCandidateBookmark selectedCandidate={selectedCandidate} />}

              {topCandidate.seeker.deactivated && (
                <Tooltip label={t('candidateStatus:DEACTIVATED')} hasArrow fontSize="sm">
                  <Box as="span" ml={2}>
                    <FaUserTimes size="16px" />
                  </Box>
                </Tooltip>
              )}

              {topCandidate?.status === CandidateStatus.LIKED && (
                <Box
                  background="#C6F6D5"
                  color="#22543D"
                  fontWeight={700}
                  fontSize="10px"
                  h="fit-content"
                  borderRadius="14px"
                  p="2px 6px"
                  ml="4px"
                >
                  {t('businessManagement:topCandidate.hasApplied')}
                </Box>
              )}

              {topCandidate?.status === CandidateStatus.CONTACT_REQUEST_ACCEPTED && (
                <Box
                  background="#C6F6D5"
                  color="#22543D"
                  fontWeight={700}
                  fontSize="10px"
                  h="fit-content"
                  borderRadius="14px"
                  p="2px 6px"
                  ml="4px"
                >
                  {t('businessManagement:topCandidate.accepted')}
                </Box>
              )}

              {topCandidate?.status === CandidateStatus.CONTACT_REQUESTED && (
                <Box
                  background="#87CEEB"
                  color="#22543D"
                  fontWeight={700}
                  fontSize="10px"
                  h="fit-content"
                  borderRadius="14px"
                  p="2px 6px"
                  ml="4px"
                >
                  {t('businessManagement:topCandidate.requested')}
                </Box>
              )}
            </Flex>

            <Flex alignItems="center" mt={1}>
              <RiMoneyDollarCircleFill color={colors.green[700]} size="16px" />
              <Text fontSize={{ base: '12px', md: '14px' }} fontWeight={400} color={colors.neutral[600]} ml="12px">
                {topCandidate.withinRange
                  ? t('businessManagement:topCandidate.withInRange')
                  : t('businessManagement:topCandidate.aboveRange')}
              </Text>
            </Flex>

            <Text fontSize={{ base: '12px', md: '14px' }} fontWeight={400} color={colors.neutral[600]} mt={1}>
              {`${t('businessManagement:topCandidate.lastActive')} : ${moment(topCandidate.seeker.lastActivity)
                .locale(i18n.language)
                .fromNow()}`}
            </Text>
          </Box>
        </Flex>

        <Box pl={{ base: '0', md: '30px' }} w={{ base: '100%', md: '48%', lg: '33%' }} mb={{ base: 4, md: 0 }}>
          <Flex>
            <MdOutlineAccountBox size="20px" />
            <Text fontSize={{ base: '12px', md: '14px' }} fontWeight="700" ml="12px">
              {t('common:profile.experience.title')}
            </Text>
          </Flex>

          <Popover trigger="hover">
            <PopoverTrigger>
              <Box
                mt="8px"
                fontSize={{ base: '12px', md: '14px' }}
                fontWeight={400}
                background={colors.neutral[100]}
                p="8px"
                borderRadius="14px"
                color={colors.neutral[600]}
              >
                {topCandidate.workExperience > 10 && t('businessManagement:topCandidate.moreThan10Years')}
                {topCandidate.workExperience < 10 &&
                  topCandidate.workExperience > 0 &&
                  `${topCandidate.workExperience.toFixed(2)} ${t('businessManagement:topCandidate.directExperience')}`}
                {topCandidate.workExperience === 0 && `--`}
              </Box>
            </PopoverTrigger>
            {candidateExperiences.length > 0 && (
              <PopoverContent border="1px solid #E5E7EB !important" w="380px">
                <PopoverBody>
                  {candidateExperiences.map((experience, index) => (
                    <Experience
                      key={experience.id}
                      experience={experience}
                      totalItems={candidateExperiences.length}
                      dataIndex={index}
                      displayBusiness={selectedCandidate?.monetized}
                    />
                  ))}
                </PopoverBody>
              </PopoverContent>
            )}
          </Popover>
        </Box>

        <Box pl={{ base: '0', md: '30px' }} w={{ base: '100%', md: '48%', lg: '33%' }}>
          <Flex>
            <FaGraduationCap size="20px" />
            <Text fontSize={{ base: '12px', md: '14px' }} fontWeight="700" ml="12px">
              {t('common:profile.education.title')}
            </Text>
          </Flex>

          {topCandidate.education ? (
            <Popover trigger="hover">
              <PopoverTrigger>
                <Box
                  mt="8px"
                  fontSize="14px"
                  fontWeight={400}
                  background={colors.neutral[100]}
                  p="8px"
                  borderRadius="14px"
                  color={colors.neutral[600]}
                >
                  {degree ? getTranslatedValue(degree.label, i18n.language) : topCandidate.education}
                </Box>
              </PopoverTrigger>
              <PopoverContent border="1px solid #E5E7EB !important" width="100%">
                <PopoverBody wordBreak="break-word" whiteSpace="normal" width="100%" overflow="auto">
                  {candidateEducations.length > 0 &&
                    candidateEducations.map((education, index) => (
                      <Education
                        key={education.id}
                        dataIndex={index}
                        education={education}
                        totalItems={candidateEducations.length}
                        educationLevels={educationLevels}
                        displaySchoolName={selectedCandidate?.monetized}
                        width="100%"
                      />
                    ))}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            <Box
              mt="8px"
              fontSize="14px"
              fontWeight={400}
              background={colors.neutral[100]}
              p="8px"
              borderRadius="14px"
              color={colors.neutral[600]}
            >
              --
            </Box>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
