import React, { useState } from 'react';
import { Box, Button, Center, Container, Flex, Image, Text } from '@chakra-ui/core';

import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';
import colors from '../../styles/colors';
import logo from '../../assets/img/AppyHere-Recruiter-Blue-Logo.png';
import { useStoreActions } from '../../models/hooks';
import { PATH_MAIN } from '../../routes/constants';

export type UnsubscribeViewParams = {
  userId: string;
};
const UnsubscribeView = (): JSX.Element => {
  const { t } = useTranslation('unSubscribe');
  const history = useHistory();
  const { userId } = useParams<UnsubscribeViewParams>();
  document.body.style.overflow = 'hidden';
  const { unSubscribeEmail } = useStoreActions((actions) => actions.unSubscribeModel);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const onHandleUnsubscribe = async () => {
    setLoading(true);
    setError(false);
    try {
      await unSubscribeEmail({ userID: userId });
      setLoading(false);
      history.push(PATH_MAIN);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };
  return (
    <Box pb={{ base: 6, sm: 8, md: 10, lg: 14 }} background={colors.blue}>
      <Container maxW="container.xl" py={1}>
        <Flex mt={4} mb={4} justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Image src={logo} alt={t('app:bundleName')} data-testid="LogoImage" style={{ width: '60px' }} />
            <Text fontSize={24} fontWeight={700} ml={4} color={colors.blue[500]}>
              {t('webRecruiter')}
            </Text>
          </Flex>
        </Flex>
      </Container>
      <Center height="80vh" background="#d7d7d7">
        <Box background="#fcfbfc" px={8} py={8} borderRadius={16}>
          <Text fontSize={20} fontWeight={700}>
            {t('title')}
          </Text>
          <Text fontSize={16} fontWeight={400}>
            {t('subTitle')}
          </Text>
          <Flex mt={8} justifyContent="flex-end">
            <Button
              colorScheme="blue"
              variant="ghost"
              mr={6}
              isLoading={loading}
              onClick={onHandleUnsubscribe}
              data-testid="unSubscribeBtn"
            >
              {t('unSubscribeBtn')}
            </Button>
            <Button
              colorScheme="blue"
              disabled={loading}
              onClick={/* istanbul ignore next */ () => history.push(PATH_MAIN)}
              data-testid="unSubscribeCancelBtn"
            >
              {t('cancel')}
            </Button>
          </Flex>
          {error && (
            <Text fontSize={14} fontWeight={400} fontStyle="italic" style={{ color: 'red' }}>
              {t('unSubscribeError')}
            </Text>
          )}
        </Box>
      </Center>
    </Box>
  );
};
export default UnsubscribeView;
