import React from 'react';
import { MenuList, MenuItem, Spinner, Flex, Box, Badge } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import _sortBy from 'lodash/sortBy';
import useRequisitionsByPosition from '../../../messaging/seeker/candidate/profile/components/useRequisitionsByPosition';
import { Requisition, RequisitionStatus } from '../../../../firebase/firestore/documents/requisition';
import Colors from '../../../../styles/colors';
import { useStoreState } from '../../../../models/hooks';
import { isRequisitionAvailable } from '../../../../utils/RequisitionEffectiveComparisionWithThresholdValue';

export type PositionRequisitionsProps = {
  positionId: string;
  setRequisition: (req: Requisition | undefined) => void;
  onOpenRequisition: () => void;
  requisitionActiveThresholdDaysPosition?: number;
};

function PositionRequisitions({
  positionId,
  setRequisition,
  onOpenRequisition,
  requisitionActiveThresholdDaysPosition,
}: PositionRequisitionsProps) {
  const { t } = useTranslation();
  const positionRequisitions = useRequisitionsByPosition(positionId, ['OPEN', 'PROCESSING']);
  console.log(positionRequisitions);
  const requisitionActiveThresholdDaysAccount = useStoreState(
    (state) => state.app.accounts?.configuration?.requisitionActiveThresholdDays,
  );

  const getColorScheme = (status: RequisitionStatus, effectiveDate?: Date) => {
    /* istanbul ignore else */
    if (
      effectiveDate &&
      isRequisitionAvailable(effectiveDate, requisitionActiveThresholdDaysAccount, requisitionActiveThresholdDaysPosition)
    ) {
      switch (status) {
        case RequisitionStatus.PROCESSING:
          return 'teal';
        case RequisitionStatus.FILLED:
          return 'green';
        case RequisitionStatus.CANCELLED:
          return 'red';
        default:
          return 'blue';
      }
    }
    /* istanbul ignore next */
    return 'gray';
  };
  return (
    <MenuList fontSize="sm" maxHeight="200px" overflowY="auto" maxWidth="260px">
      {/* eslint-disable-next-line no-nested-ternary */}
      {!positionRequisitions ? (
        <Flex d="flex" alignItems="center" justifyContent="center" py={2}>
          <Spinner size="sm" color="blue.500" />
        </Flex>
      ) : positionRequisitions.length > 0 ? (
        _sortBy(positionRequisitions, ['effectiveDate'], ['asc'])?.map((req) => (
          <MenuItem
            key={req.requisitionId}
            onClick={() => {
              setRequisition(req);
              onOpenRequisition();
            }}
            d="flex"
            justifyContent="space-between"
            alignItems="center"
            data-testid={`menuitem-${req.requisitionId}`}
          >
            <Box
              pr="10px"
              width="70%"
              color={
                isRequisitionAvailable(
                  req.effectiveDate,
                  requisitionActiveThresholdDaysAccount,
                  requisitionActiveThresholdDaysPosition,
                )
                  ? Colors.black
                  : /* istanbul ignore next */ Colors.gray[400]
              }
            >
              {req.requisitionId}
            </Box>
            <Box>
              {req.status && (
                <Badge variant="outline" textTransform="initial" colorScheme={getColorScheme(req.status, req.effectiveDate)}>
                  {t(`requisition:${req.status}`)}
                </Badge>
              )}
            </Box>
          </MenuItem>
        ))
      ) : (
        <Flex d="flex" alignItems="center" justifyContent="center" py={2}>
          {t('common:profile.noRequisition')}
        </Flex>
      )}
    </MenuList>
  );
}

export default PositionRequisitions;
