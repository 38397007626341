/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment } from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/core';
import i18next from 'i18next';
import moment from 'moment';
import { getTranslatedValue } from '../../../../../../utils/localizedString';

const weekDays = [
  { en: 'mon', fr: 'lun' },
  { en: 'tue', fr: 'mar' },
  { en: 'wed', fr: 'mer' },
  { en: 'thu', fr: 'jeu' },
  { en: 'fri', fr: 'ven' },
];

const weekEnd = [
  { en: 'sat', fr: 'sam' },
  { en: 'sun', fr: 'dim' },
];

export type CandidateAvailabilityDuration = {
  start_1: string;
  end_1: string;
};

type CandidateAvailabilitiesProps = {
  candidateAvailabilities: { [key: string]: { start_1: string; end_1: string } };
};

export const CandidateAvailabilities = ({ candidateAvailabilities }: CandidateAvailabilitiesProps): JSX.Element => {
  let count = 0;
  return (
    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={1} w="100%">
      <Box>
        {weekDays.map((d) => {
          count += 1;
          const availability = candidateAvailabilities && (candidateAvailabilities[d.en] as CandidateAvailabilityDuration);

          return (
            <Flex
              key={d.en}
              justifyContent="space-between"
              alignItems="center"
              px={2}
              py={2}
              bg={count % 2 !== 0 ? 'gray.150' : 'white'}
              border="1px solid"
              borderColor="gray.150"
              whiteSpace={{ base: 'normal', md: 'nowrap' }}
              overflow="visible"
            >
              <Box textTransform="capitalize" fontSize={{ base: '10px', md: '12px' }} flexShrink={0} mr="2px">
                {getTranslatedValue(d, i18next.language)}
              </Box>
              {availability ? (
                <Flex alignItems="center" gap={1}>
                  <Text fontSize={{ base: '10px', md: '12px' }}>{moment(availability.start_1, ['HH:mm']).format('LT')}</Text>
                  <Text fontSize={{ base: '10px', md: '12px' }} mx={1}>
                    -
                  </Text>
                  <Text fontSize={{ base: '10px', md: '12px' }}>{moment(availability.end_1, ['HH:mm']).format('LT')}</Text>
                </Flex>
              ) : (
                <Box fontSize={{ base: '10px', md: '12px' }}>---</Box>
              )}
            </Flex>
          );
        })}
      </Box>

      <Box>
        {weekEnd.map((d) => {
          count += 1;
          const availability = candidateAvailabilities && (candidateAvailabilities[d.en] as CandidateAvailabilityDuration);

          return (
            <Flex
              key={d.en}
              justifyContent="space-between"
              alignItems="center"
              px={2}
              py={2}
              bg={count % 2 !== 0 ? 'gray.150' : 'white'}
              border="1px solid"
              borderColor="gray.150"
              whiteSpace={{ base: 'normal', md: 'nowrap' }}
              overflow="visible"
            >
              <Box textTransform="capitalize" fontSize={{ base: '10px', md: '12px' }} flexShrink={0} mr="2px">
                {getTranslatedValue(d, i18next.language)}
              </Box>
              {availability ? (
                <Flex alignItems="center">
                  <Text fontSize={{ base: '10px', md: '12px' }}>{moment(availability.start_1, ['HH:mm']).format('LT')}</Text>
                  <Text mx={1} fontSize={{ base: '10px', md: '12px' }}>
                    -
                  </Text>
                  <Text fontSize={{ base: '10px', md: '12px' }}>{moment(availability.end_1, ['HH:mm']).format('LT')}</Text>
                </Flex>
              ) : (
                /* istanbul ignore next */
                <Box>---</Box>
              )}
            </Flex>
          );
        })}
      </Box>
    </Grid>
  );
};
