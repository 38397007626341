import { useTranslation } from 'react-i18next';
import { useCurrentUserProfile } from '../../app/hooks/useCurrentUserProfile';
import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Query } from '../../firebase/firestore/query/query';
import { Collections } from '../../firebase/firestore/collections';
import { GroupEvent } from '../../firebase/firestore/documents/groupEvent';
import { CalendarEventPresenter } from './calendarEventPresenter';
import { useStoreActions } from '../../models/hooks';

const useCalendarEvent = (): CalendarEventPresenter => {
  const { i18n } = useTranslation();
  const { currentUserProfile } = useCurrentUserProfile();
  const { setGroupEventPresenter } = useStoreActions((a) => a.calendar);

  const availableGroupEvents = useQuerySnapshot<GroupEvent>(Collections.GROUP_EVENTS, [
    Query.field<GroupEvent>('creator').equals(currentUserProfile?.id),
    Query.field<GroupEvent>('accountId').equals(currentUserProfile?.account),
  ]);

  if (availableGroupEvents && currentUserProfile) {
    const calendarPresenter = new CalendarEventPresenter(availableGroupEvents, i18n.language);
    setGroupEventPresenter(calendarPresenter);
    return new CalendarEventPresenter(availableGroupEvents, i18n.language);
  }
  return new CalendarEventPresenter([], i18n.language);
};

export default useCalendarEvent;
