/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Flex } from '@chakra-ui/core';
import TabButton from './tabButton/TabButton';

import { seekerSubNavigationCSS } from '../SeekerHeader.styles';
import { SeekerContainerTabItems } from '../../SeekerContainerContext';

const SeekerTabs = (): JSX.Element => {
  return (
    <Flex alignItems="center" css={seekerSubNavigationCSS}>
      <TabButton data-testid="MessagingTabButton" item={SeekerContainerTabItems.Messaging} />
      <TabButton data-testid="NotesTabButton" item={SeekerContainerTabItems.Notes} />
    </Flex>
  );
};

export default SeekerTabs;
