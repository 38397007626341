import _isNil from 'lodash/isNil';
import { action, Action, Thunk, thunk } from 'easy-peasy';
import _filter from 'lodash/filter';
import moment from 'moment-timezone';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { CandidateController } from '../../../controllers/candidateController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { EngagedCandidate } from '../../../firebase/firestore/documents/candidate';
import { deleteSubDocumentById, setSubDocumentById } from '../../../firebase/firestore/firestore';
import { Collections, IS_TYPING_DEFAULT_DOCUMENT_ID, SubCollections } from '../../../firebase/firestore/collections';
import { BackgroundCheckResponse } from '../../administration/certn/CertnType';
import { HireFormErrorResponse, RequestHireDetailForm } from '../seeker/header/actionsDropdown/HireFormTypeEnum';

export type MessagingStoreModel = {
  messaging: MessagingModel;
};

interface Availabilities {
  availabilities: string[];
  length: number;
  fromDate: Date;
}

interface AvailablePeriods {
  availabilities: Availabilities;
}

interface Data {
  availablePeriods: AvailablePeriods;
  duration: number;
  fenceTime: number;
  type: string;
}

export type SeekerProfilePicture = { [seekerId: string]: string };

export interface MessagingModel {
  seekersProfilePictures: SeekerProfilePicture;
  availabilitiesCount: number | undefined;
  setProfilePicture: Action<MessagingModel, SeekerProfilePicture>;

  sendMessage: Thunk<MessagingModel, { message: string; positionId: string; seekerId: string }, void, HttpStoreModel>;
  markCandidateAsRead: Thunk<MessagingModel, { positionId: string; seekerId: string }, void, HttpStoreModel>;
  createAppointmentRequest: Thunk<
    MessagingModel,
    { positionId: string; seekerId: string },
    void,
    HttpStoreModel,
    Promise<HireFormErrorResponse | string>
  >;
  loadSeekerProfilePicture: Thunk<MessagingModel, { candidate: EngagedCandidate }, void, HttpStoreModel>;
  hireCandidate: Thunk<
    MessagingModel,
    {
      positionId: string;
      seekerId: string;
      hireDetails: string | null;
      salary: string;
      salaryUnit: string;
      dateOfJoining?: Date;
      customSalaryUnit?: string | null;
      requisitionId?: string;
      updatedPositionId?: string;
      updatedBusinessId?: string;
    },
    void,
    HttpStoreModel
  >;

  requestHireDetails: Thunk<
    MessagingModel,
    {
      requestHireDetailFormFields: RequestHireDetailForm;
      candidateId: string;
    },
    void,
    HttpStoreModel,
    Promise<HireFormErrorResponse | string>
  >;

  unHireCandidate: Thunk<
    MessagingModel,
    { positionId: string; seekerId: string; unhireDetails: string; unhireType: string },
    void,
    HttpStoreModel
  >;
  dismissCandidate: Thunk<
    MessagingModel,
    { positionId: string; seekerId: string; recruiterId: string; dismissalReason?: string },
    void,
    HttpStoreModel
  >;
  undismissCandidate: Thunk<MessagingModel, { positionId: string; seekerId: string; recruiterId: string }, void, HttpStoreModel>;

  setRecruiterIsTyping: Thunk<MessagingModel, { candidateId?: string; lastUpdate?: Date }>;
  loadAppointmentAvailabilities: Thunk<MessagingModel, { positionId: string }, void, HttpStoreModel>;
  setAppointmentAvailabilitiesCount: Action<MessagingModel, number>;
  backgroundCheck: Thunk<MessagingModel, { candidateId: string }, void, HttpStoreModel, Promise<BackgroundCheckResponse | null>>;
}

export const messagingModel: MessagingModel = {
  seekersProfilePictures: {},

  availabilitiesCount: 0,

  setProfilePicture: action((state, profilePictureUrl) => {
    state.seekersProfilePictures = { ...state.seekersProfilePictures, ...profilePictureUrl };
  }),

  setAppointmentAvailabilitiesCount: action((state, payload) => {
    state.availabilitiesCount = payload;
  }),

  loadAppointmentAvailabilities: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.loadAppointmentAvailabilities(payload.positionId);
    // istanbul ignore next
    const { data } = response || {};
    const { availablePeriods } = (data as Data) || {};
    const availableTimes = _filter(
      availablePeriods,
      // istanbul ignore next
      (availablePeriod) => moment(availablePeriod.fromDate).isAfter(),
    );
    _actions.setAppointmentAvailabilitiesCount(availableTimes.length);
  }),

  sendMessage: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.sendMessage(payload.message, payload.positionId, payload.seekerId);
  }),

  markCandidateAsRead: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.markAsRead(payload.positionId, payload.seekerId);
  }),

  createAppointmentRequest: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.createAppointmentRequest(payload.positionId, payload.seekerId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response?.data;
  }),

  loadSeekerProfilePicture: thunk(async (actions, { candidate }, { getStoreState, getState }) => {
    if (_isNil(getState().seekersProfilePictures[candidate.seeker])) {
      const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
      const image = await controller.getProfilePictureUrl(candidate);
      actions.setProfilePicture({ [candidate.seeker]: image });
    }
  }),

  hireCandidate: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.hireCandidateRequest(
      payload.positionId,
      payload.seekerId,
      payload.hireDetails,
      payload.salary,
      payload.salaryUnit,
      payload.dateOfJoining,
      payload.customSalaryUnit,
      payload.requisitionId,
      payload.updatedPositionId,
      payload.updatedBusinessId,
    );
  }),

  requestHireDetails: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.requestHireDetails(payload.candidateId, payload.requestHireDetailFormFields);
    return response?.data as HireFormErrorResponse | string;
  }),

  unHireCandidate: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.unHireCandidateRequest(payload.positionId, payload.seekerId, payload.unhireDetails, payload.unhireType);
  }),

  dismissCandidate: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.dismissCandidateRequest(payload.positionId, payload.seekerId, payload.recruiterId, payload.dismissalReason);
  }),

  undismissCandidate: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    await controller.undismissCandidateRequest(payload.positionId, payload.seekerId, payload.recruiterId);
  }),

  setRecruiterIsTyping: thunk(async (state, { candidateId, lastUpdate }) => {
    if (!candidateId) return null;

    const collectionName = Collections.CANDIDATES;
    const subCollectionName = SubCollections.RECRUITER_IS_TYPING;

    if (lastUpdate) {
      return setSubDocumentById(collectionName, subCollectionName, { lastUpdate }, candidateId, IS_TYPING_DEFAULT_DOCUMENT_ID);
    }
    return deleteSubDocumentById(collectionName, subCollectionName, candidateId, IS_TYPING_DEFAULT_DOCUMENT_ID);
  }),

  backgroundCheck: thunk(async (actions, payload, { getStoreState }) => {
    const controller = new CandidateController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.backgroundCheckRequest(payload.candidateId);
    return response?.data as BackgroundCheckResponse;
  }),
};
