import React, { useContext, useState, Dispatch, SetStateAction } from 'react';
import _noop from 'lodash/noop';
import { useParams } from 'react-router';
import moment from 'moment';
import { RecruiterManagementTabsParams } from '../../../routes/constants';

export type RecruiterSelectType = {
  id: string;
  label: string;
  value: string;
};

export enum RecruiterDashboardTabItems {
  RecruiterProfile = 'RecriterProfile',
  RecruiterDashboard = 'RecruiterDashboard',
}

export type RecruiterContainerTabContextValueType = {
  selectedTab: RecruiterDashboardTabItems;
  setSelectedTab: (selectedTab: RecruiterDashboardTabItems) => void;
  selectedRecruiter: RecruiterSelectType | null;
  setSelectedRecruiter: Dispatch<SetStateAction<RecruiterSelectType | null>>;
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
};

export type RecruiterManagementContextValueType = RecruiterContainerTabContextValueType;

export const RECRUITER_DASHBOARD_CONTEXT_DEFAULT_VALUE: RecruiterManagementContextValueType = {
  selectedTab: RecruiterDashboardTabItems.RecruiterDashboard,
  setSelectedTab: _noop,
  selectedRecruiter: {
    id: '',
    label: '',
    value: '',
  },
  setSelectedRecruiter: _noop,
  startDate: moment().subtract(30, 'days').toDate(),
  setStartDate: _noop,
  endDate: moment().toDate(),
  setEndDate: _noop,
};

export const RecruiterManagementContext = React.createContext<RecruiterManagementContextValueType>(
  RECRUITER_DASHBOARD_CONTEXT_DEFAULT_VALUE,
);

/* istanbul ignore next */
const mapTabParamsToKey = (tab: string) => {
  switch (tab) {
    case RecruiterDashboardTabItems.RecruiterDashboard:
      return RecruiterDashboardTabItems.RecruiterDashboard;
    case RecruiterDashboardTabItems.RecruiterProfile:
      return RecruiterDashboardTabItems.RecruiterProfile;
    default:
      return RECRUITER_DASHBOARD_CONTEXT_DEFAULT_VALUE.selectedTab;
  }
};

export const RecruiterManagementContextProvider: React.FC = ({ children }) => {
  const { tab } = useParams<RecruiterManagementTabsParams>();
  const [selectedTab, setSelectedTab] = useState<RecruiterDashboardTabItems>(mapTabParamsToKey(tab));
  const [startDate, setStartDate] = useState<Date>(RECRUITER_DASHBOARD_CONTEXT_DEFAULT_VALUE.startDate);
  const [endDate, setEndDate] = useState<Date>(RECRUITER_DASHBOARD_CONTEXT_DEFAULT_VALUE.endDate);
  const [selectedRecruiter, setSelectedRecruiter] = useState<RecruiterSelectType | null>(
    RECRUITER_DASHBOARD_CONTEXT_DEFAULT_VALUE.selectedRecruiter,
  );

  return (
    <RecruiterManagementContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        selectedRecruiter,
        setSelectedRecruiter,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
      }}
    >
      {children}
    </RecruiterManagementContext.Provider>
  );
};

export const useRecruiterManagementContext = (): RecruiterManagementContextValueType => useContext(RecruiterManagementContext);
