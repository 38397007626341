import React from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  IconButton,
  Text,
  Box,
} from '@chakra-ui/core';
import { CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';
import { AudioReview } from '../messaging/seeker/candidate/audioReview/AudioReview';
import useCandidate from '../../app/components/useCandidate';
import { CandidateMessagingView } from '../messaging/CandidateMessagingView';

export type LanguageEvaluationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  currentCandidateId: string;
  isRenderedBy?: string;
  currentTask?: string;
  currentDocId?: string;
  messagingModal?: boolean;
};

export const LanguageEvaluationModal = ({
  isOpen,
  onClose,
  currentCandidateId,
  isRenderedBy,
  currentDocId,
  currentTask,
  messagingModal = false,
}: LanguageEvaluationModalProps): JSX.Element => {
  const currentCandidate = useCandidate(currentCandidateId);
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl" scrollBehavior="inside">
      <ModalOverlay />

      <ModalContent position="absolute" transform="none" overflow="auto" height="75vh">
        <ModalCloseButton
          as={IconButton}
          icon={<CloseIcon boxSize={3} />}
          aria-label="Close modal"
          style={{
            cursor: 'pointer',
            backgroundColor: colors.white,
            borderRadius: '50%',
            border: `1px solid ${colors.gray[300]}`,
            padding: 20,
          }}
          _hover={{ background: `${colors.gray[100]} !important` }}
        />

        <ModalBody
          borderTop={`1px solid ${colors.gray[100]}`}
          overflow="auto"
          display="flex"
          flexDirection="row"
          flex="1"
          height="100%"
        >
          <Box mr={5} flex={1} minWidth="55%" height="100%" overflowY="auto">
            <CandidateMessagingView candidateId={currentCandidateId} hideHeader isMessagingOnly={messagingModal} />
          </Box>
          <Box flex={1} height="100%" overflowY="auto">
            <ModalHeader paddingLeft={0}>
              <Text fontWeight="bold">{t('common:tabs.audioRecording')}</Text>
            </ModalHeader>
            {currentCandidate && (
              <AudioReview
                candidate={currentCandidate}
                onCloseLangModel={onClose}
                isRenderedBy={isRenderedBy}
                currentDocId={currentDocId}
                currentTask={currentTask}
                currentCandidateId={currentCandidateId}
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
