/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ChangeEvent } from 'react';
import {
  Text,
  Textarea,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { confirmationModalHeaderCSS } from './ConfirmationModal.styles';
import { ActionsAfterUnhire } from './ActionsAfterUnhire';
import { SeekersPresenter } from '../../../sidebar/seekerList/seekersPresenter';
import { useStoreState } from '../../../../../models/hooks';

export type UnhireConfirmationModalProps = {
  isOpen: boolean;
  disabled: boolean;
  onClose: () => void;
  isMessagingView: boolean;
};

export const UnhireConfirmationModal = ({
  isOpen,
  disabled,
  onClose,
  isMessagingView,
}: UnhireConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [noteContent, setNoteContent] = React.useState('');
  const [disabledButton, setButtonDisabled] = React.useState(true);
  // istanbul ignore next
  const seekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allCandidates);

  const {
    isOpen: isActionsAfterUnhireOpen,
    onOpen: onActionsAfterUnhireOpen,
    onClose: onActionsAfterUnhireClose,
  } = useDisclosure();

  React.useEffect(
    () => () => {
      setIsLoading(false);
      setButtonDisabled(true);
    },
    [],
  );

  React.useEffect(() => {
    if (noteContent.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [noteContent]);

  const updateNoteContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteContent(e.target.value);
  };

  const emptyNoteContent = () => {
    setNoteContent('');
  };

  const clearAndClose = () => {
    emptyNoteContent();
    onClose();
  };

  const handleUnhireRequest = () => {
    try {
      setIsLoading(true);
      onActionsAfterUnhireOpen();
    } finally {
      setIsLoading(false);
    }
  };
  const onCloseAlert = () => {
    onActionsAfterUnhireClose();
    onClose();
  };

  return (
    <React.Fragment>
      {isActionsAfterUnhireOpen ? (
        <ActionsAfterUnhire
          isOpen={isActionsAfterUnhireOpen}
          onClose={onCloseAlert}
          emptyNoteContent={emptyNoteContent}
          noteContent={noteContent}
          allCandidates={seekerList}
          isMessagingView={isMessagingView}
        />
      ) : (
        <Modal id="UnhireConfirmationModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
          <ModalOverlay>
            <ModalContent>
              <ModalHeader css={confirmationModalHeaderCSS} className="modal-header" style={{ color: 'black' }}>
                {t('messaging:actions.hire.rejectModal.unhireCandidate')}
              </ModalHeader>
              <ModalBody>
                <Text color="#455A63" mb="2rem">
                  {t('messaging:actions.hire.rejectModal.reasonForChange')}
                </Text>
                <Textarea
                  value={noteContent}
                  data-testid="NoteContent"
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => updateNoteContent(e)}
                  focusBorderColor="gray"
                  style={{ height: '30vh' }}
                  placeholder={t('messaging:actions.hire.rejectModal.placeholder')}
                />
              </ModalBody>
              <ModalFooter>
                <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={clearAndClose} disabled={isLoading}>
                  {t('messaging:actions.hire.confirmationModal.cancelButton')}
                </Button>
                <Button
                  data-testid="ConfirmButton"
                  colorScheme="blue"
                  onClick={handleUnhireRequest}
                  isLoading={isLoading}
                  disabled={disabledButton || disabled || isLoading}
                >
                  {t('messaging:actions.hire.confirmationModal.saveButton')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      )}
    </React.Fragment>
  );
};
