import { thunk, Thunk } from 'easy-peasy';
import { PositionManagementModel } from './positionManagmentModel';
import { HttpStoreModel } from '../../../http/models/httpModel';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { VideoManagementController } from '../../../controllers/videoManagementController';

export type VideoManagementStoreModel = {
  videoManagement: VideoManagementModel;
};

export interface VideoManagementModel {
  uploadOverviewVideo: Thunk<
    PositionManagementModel,
    { accountId: string; overviewVideo: File; folderName: string },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  uploadOverviewThumbnail: Thunk<
    PositionManagementModel,
    { accountId: string; overviewThumbnail: File; folderName: string },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  deleteVideoFromStorage: Thunk<
    PositionManagementModel,
    { accountId: string; folderName: string },
    void,
    HttpStoreModel,
    Promise<void>
  >;
  deleteVideoThumbnailFromStorage: Thunk<
    PositionManagementModel,
    { accountId: string; folderName: string },
    void,
    HttpStoreModel,
    Promise<void>
  >;
}

export const videoManagementStoreModel: VideoManagementModel = {
  uploadOverviewVideo: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new VideoManagementController(getStoreState().http.client as HttpClientWithRetry);
    return controller.uploadOverviewVideo(payload.accountId, payload.overviewVideo, 'overview.mp4', payload.folderName);
  }),

  uploadOverviewThumbnail: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new VideoManagementController(getStoreState().http.client as HttpClientWithRetry);
    return controller.uploadOverviewThumbnail(payload.accountId, payload.overviewThumbnail, 'thumbnail.jpeg', payload.folderName);
  }),
  deleteVideoFromStorage: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new VideoManagementController(getStoreState().http.client as HttpClientWithRetry);
    return controller.deleteVideoFromStorage(payload.accountId, payload.folderName);
  }),
  deleteVideoThumbnailFromStorage: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new VideoManagementController(getStoreState().http.client as HttpClientWithRetry);
    return controller.deleteVideoThumbnailFromStorage(payload.accountId, payload.folderName);
  }),
};
