import React, { ChangeEvent, Fragment, useCallback } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import colors from '../../../../../styles/colors';
import { SeekerContainerTabItems, useSeekerContainerContext } from '../../SeekerContainerContext';
import { useStoreActions } from '../../../../../models/hooks';
import { UnhireTypeEnum } from './UnhireTypeEnum';
import { CandidateCategory, EngagedCandidate } from '../../../../../firebase/firestore/documents/candidate';
import { SESSION_KEY_CANDIDATE_LIST_TYPE, useSeekersFilters } from '../../../sidebar/filters/SeekersFiltersContext';
import { CandidateListType } from '../../../sidebar/filters/seekersFiltersContextValueType';
import { MESSAGING_TABS, PATH_MAIN } from '../../../../../routes/constants';
import { SeekersPresenter } from '../../../sidebar/seekerList/seekersPresenter';
import { useMessagingViewContext } from '../../../MessagingViewContext';

export type ActionsAfterUnhireModal = {
  isOpen: boolean;
  onClose: () => void;
  emptyNoteContent: () => void;
  noteContent: string;
  allCandidates: SeekersPresenter | undefined;
  isMessagingView: boolean;
};

export const ActionsAfterUnhire = ({
  isOpen,
  onClose,
  noteContent,
  emptyNoteContent,
  allCandidates,
  isMessagingView,
}: ActionsAfterUnhireModal): JSX.Element => {
  const { t } = useTranslation('notification');
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedCandidate } = useSeekerContainerContext();
  const [radioValue, setRadioValue] = React.useState<string>(UnhireTypeEnum.HIREINFUTURE);

  /* istanbul ignore next */
  const unHireCandidate = useStoreActions((actions) => actions.messaging.unHireCandidate);

  const toast = useToast();
  const { setListType } = useSeekersFilters();
  const { setSelectedEngagedCandidate, setSelectedEngagedSeeker } = useMessagingViewContext();

  const clearAndClose = () => {
    emptyNoteContent();
    onClose();
  };

  const onSelectNextCandidateAfterUnhire = useCallback(
    () => {
      const newSeeker = allCandidates?.engagedCandidates.filter(
        (seeker: EngagedCandidate) =>
          seeker.computedStatus?.category === CandidateCategory.Other && seeker.id !== window.location.pathname.split('/')[2],
      );

      if (newSeeker && newSeeker.length > 0) {
        sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, CandidateListType.ARCHIVED_CANDIDATE_LIST);
        setListType(CandidateListType.ARCHIVED_CANDIDATE_LIST);
        setSelectedEngagedCandidate(newSeeker[0]);
        setSelectedEngagedSeeker(newSeeker[0] && { seekerId: newSeeker[0].seeker, candidateId: newSeeker[0].id });

        history.push(MESSAGING_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging));
      } else {
        sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, CandidateListType.UNKNOWN);
        setListType(CandidateListType.UNKNOWN);
        setSelectedEngagedCandidate(undefined);
        setSelectedEngagedSeeker(undefined);
        history.push(PATH_MAIN);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedEngagedCandidate, setSelectedEngagedSeeker, allCandidates],
  );

  const actionAfterUnhireHandler = async () => {
    if (!selectedCandidate) return;

    try {
      setIsLoading(true);
      await unHireCandidate({
        positionId: selectedCandidate.position,
        seekerId: selectedCandidate.seeker,
        unhireDetails: noteContent,
        unhireType: radioValue,
      });
    } finally {
      clearAndClose();
      setIsLoading(false);
      if (isMessagingView) {
        onSelectNextCandidateAfterUnhire();
      }
      toast({
        title: t('notes:noteSuccessMsg.title'),
        description: t('notes:noteSuccessMsg.description'),
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRadioValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRadioValue(e.target.value);
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={clearAndClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent data-testid="PreferenceModal">
          <ModalHeader className="modal-header" style={{ color: colors.blue.default }}>
            {t('messaging:actions.hire.actionsAfterUnhire.headerText')}
          </ModalHeader>
          <ModalBody>
            <Text fontSize="1rem" lineHeight="24px" mb={3} color={colors.gray[700]}>
              <p>{t('messaging:actions.hire.actionsAfterUnhire.hireHimAgain')}</p>
            </Text>
            <RadioGroup mb="1rem">
              <Stack spacing={5} direction="row">
                <Radio
                  isChecked
                  colorScheme="blue"
                  data-testid="HireInFuture"
                  value={UnhireTypeEnum.HIREINFUTURE}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleRadioValueChange(e)}
                >
                  {t('messaging:actions.hire.actionsAfterUnhire.hireInFuture')}
                </Radio>
                <Radio
                  colorScheme="blue"
                  data-testid="RemoveForever"
                  value={UnhireTypeEnum.REMOVEFOREVER}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleRadioValueChange(e)}
                >
                  {t('messaging:actions.hire.actionsAfterUnhire.removeForever')}
                </Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>

          <ModalFooter>
            <Button
              disabled={isLoading}
              colorScheme="blue"
              data-testid="ActionAfterUnhireSubmitButton"
              onClick={actionAfterUnhireHandler}
            >
              {t('messaging:actions.hire.messageAfterHiringModal.buttonText')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
