import { TFunction } from 'i18next';
import { Experience } from '../../../../../firebase/firestore/documents/seeker';

const translate = (t: TFunction, textPath: string, payload?: { value: number }): string => {
  if (textPath === 'none') {
    return t('profile.experience.none');
  }
  return t(`time.relative.${textPath}`, payload);
};

const DURATION_DICTIONARY = [
  { value: 0, text: (t: TFunction) => translate(t, 'none') },
  { value: 0.49, text: (t: TFunction) => translate(t, 'lessThan') },
  { value: 0.5, text: (t: TFunction) => translate(t, 'multipleMonths', { value: 6 }) },
  { value: 0.585, text: (t: TFunction) => translate(t, 'multipleMonths', { value: 7 }) },
  { value: 0.667, text: (t: TFunction) => translate(t, 'multipleMonths', { value: 8 }) },
  { value: 0.75, text: (t: TFunction) => translate(t, 'multipleMonths', { value: 9 }) },
  { value: 0.834, text: (t: TFunction) => translate(t, 'multipleMonths', { value: 10 }) },
  { value: 0.917, text: (t: TFunction) => translate(t, 'multipleMonths', { value: 11 }) },
  { value: 1, text: (t: TFunction) => translate(t, 'oneYear') },
  { value: 2, text: (t: TFunction) => translate(t, 'multipleYears', { value: 2 }) },
  { value: 3, text: (t: TFunction) => translate(t, 'multipleYears', { value: 3 }) },
  { value: 4, text: (t: TFunction) => translate(t, 'multipleYears', { value: 4 }) },
  { value: 5, text: (t: TFunction) => translate(t, 'multipleYears', { value: 5 }) },
  { value: 6, text: (t: TFunction) => translate(t, 'multipleYears', { value: 6 }) },
  { value: 7, text: (t: TFunction) => translate(t, 'multipleYears', { value: 7 }) },
  { value: 8, text: (t: TFunction) => translate(t, 'multipleYears', { value: 8 }) },
  { value: 9, text: (t: TFunction) => translate(t, 'multipleYears', { value: 9 }) },
  { value: 10, text: (t: TFunction) => translate(t, 'multipleYears', { value: 10 }) },
  { value: 11, text: (t: TFunction) => translate(t, 'moreThan') },
];

export default class SeekerExperiencePresenter {
  public readonly id = this.experience.id;

  public readonly jobTitle = this.experience.jobTitle;

  public readonly business = this.experience.business;

  public readonly current = this.experience.current;

  private readonly duration = this.experience.duration;

  public readonly startMonth = this.experience.startMonth;

  public readonly startYear = this.experience.startYear;

  constructor(public readonly experience: Experience) {}

  private get durationIndex() {
    const index = DURATION_DICTIONARY.findIndex((v) => v.value === this.duration);
    return index === -1 ? 0 : index;
  }

  public getDuration(t: TFunction): string {
    return DURATION_DICTIONARY[this.durationIndex].text(t);
  }
}
