/* eslint-disable react/jsx-one-expression-per-line */
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, BoxProps, Text, Stack, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { Label } from '../../../../../common';
import SeekerEducationPresenter from '../seekerEducationPresenter';
import { EducationStatus } from '../../../../../../firebase/firestore/documents/seeker';
import { getTranslatedValue } from '../../../../../../utils/localizedString';
import { Lookups } from '../../../../../../firebase/firestore/documents/lookups';

type EducationProps = BoxProps & {
  education: SeekerEducationPresenter;
  dataIndex: number;
  totalItems: number;
  educationLevels: Lookups[];
  displaySchoolName?: boolean;
};

export const Education = ({
  education,
  dataIndex,
  totalItems,
  paddingTop,
  width,
  educationLevels,
  displaySchoolName,
}: EducationProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const showDate = education.status === EducationStatus.completed;

  // const showOtherDegree = education.degree === OTHER_DEGREE;

  const degree = educationLevels.find((edu) => edu.id === education.degree);

  const educationMonth = education.month && education?.month.slice(0, 3).toLocaleLowerCase();

  return (
    <Flex
      key={education.id}
      paddingTop={paddingTop}
      width={width}
      borderBottomWidth={dataIndex + 1 === totalItems ? /* istanbul ignore next */ 0 : '1px'}
      borderStyle="solid"
      borderColor="gray.50"
      py={2}
      px={2}
      align="center"
      direction="row"
      justify="space-between"
      alignItems="flex-start"
      bg={dataIndex % 2 === 1 ? 'gray.150' : 'white'}
      flexWrap="wrap"
    >
      <Box>
        <Stack spacing={0} direction="column" maxWidth="100%" isTruncated>
          <Label bold maxWidth="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {degree ? getTranslatedValue(degree.label, i18n.language) : education.degree}
            {/* {showOtherDegree && ` - ${education.otherDegree}`} */}
          </Label>
        </Stack>
        <Box ml={1} fontSize="xs" fontWeight="bold" color="black">
          - {education.fieldOfStudy}
        </Box>
        {displaySchoolName && <Box fontSize="xs">{education.otherDegree}</Box>}
        {displaySchoolName && (
          <Box fontSize="xs" fontWeight="bold">
            {education.schoolName && education.schoolName}
          </Box>
        )}
      </Box>
      <Text
        fontSize="xs"
        background="gray.50"
        color="#4A5568"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="10px"
        px={2}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        maxWidth="150px"
        isTruncated
      >
        {showDate && education.month && education.year
          ? `${t(`monthsSmall.${educationMonth}`)} ${education.year}`
          : t(`profile.education.${education.status}`)}
      </Text>
    </Flex>
  );
};

Education.defaultProps = {
  displaySchoolName: false,
};
