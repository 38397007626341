import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../http/models/httpModel';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { ModernHireController } from '../controllers/modernHireController';
import { ModernHireDetailResponse } from '../firebase/firestore/documents/modernHireTypes';

export type ModernHireStoreModel = {
  modernHireModal: ModernHireModel;
};

export interface ModernHireModel {
  modernHireResult: Thunk<ModernHireModel, { candidateId: string }, void, HttpStoreModel, Promise<ModernHireDetailResponse>>;
}

export const modernHireManagementStoreModel: ModernHireModel = {
  modernHireResult: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ModernHireController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getModernHireResult(payload.candidateId);
    return response?.data as ModernHireDetailResponse;
  }),
};
