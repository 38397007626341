import SeekerInfoPresentationModel from './seekerInfoPresenter';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { Candidate } from '../../../../../firebase/firestore/documents/candidate';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';

const getCandidate = (candidates?: Candidate[], engagedCandidates?: Candidate) => {
  if (candidates && candidates.length > 0) {
    return candidates;
  }
  /* istanbul ignore next */
  if (engagedCandidates) {
    return [engagedCandidates];
  }
  return [];
};

const useSeekerInfo = (candidate: Candidate | undefined): SeekerInfoPresentationModel | undefined => {
  const { candidates } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const candidateToDisplay = getCandidate(candidates, selectedEngagedCandidate);

  return new SeekerInfoPresentationModel(candidate, candidateToDisplay ?? /* istanbul ignore next */ []);
};

export default useSeekerInfo;
