import React, { useState, useRef } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Flex,
  Textarea,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  Select,
  Input,
  StackDivider,
  IconButton,
  useDisclosure,
  FormErrorMessage,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { RiEdit2Fill, RiDeleteBin5Fill } from 'react-icons/ri';
import Joi from 'joi';
import moment from 'moment-timezone';
import { useStoreState } from '../../../models/hooks';
import { deleteDocumentById, setDocumentById } from '../../../firebase/firestore/firestore';
import { Collections } from '../../../firebase/firestore/collections';
import useLanguagePhrasesByAccountId from '../../../app/hooks/useLanguagePhrasesByAccountId';
import { LanguageEvaluationPhrases } from '../../../firebase/firestore/documents/languageEvaluationPhrases';
import { clearErrorMessage, errorFormat, FormattedError, getErrorMessage, hasErrorMessage } from '../../../utils/FormErrorUtils';

const LanguageEvaluationPhrasesView = (): JSX.Element => {
  const accountId = useStoreState((s) => s.app.accounts?.id);
  const userId = useStoreState((s) => s.app.user?.id);
  const { t } = useTranslation();
  const initialPhrase = {
    phraseId: '',
    language: 'fr',
    description: '',
    account: accountId ?? '',
    userId: userId ?? '',
    createdAt: moment().toDate(),
  };
  const [phraseToDelete, setPhraseToDelete] = useState<LanguageEvaluationPhrases | undefined>();
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [phrase, setPhrase] = useState<LanguageEvaluationPhrases>(initialPhrase);

  const phrases = useLanguagePhrasesByAccountId(accountId ?? '');

  const cancelRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      phraseId: Joi.string()
        .required()
        .trim()
        .messages({
          'string.empty': t('administration:languageEvaluation.phraseLabelRequired'),
        }),
      description: Joi.string()
        .required()
        .trim()
        .messages({
          'string.empty': t('administration:languageEvaluation.phraseDescriptionRequired'),
        }),
      id: Joi.string().optional(),
      language: Joi.string().optional(),
      account: Joi.string().optional(),
      userId: Joi.string().optional(),
      createdAt: Joi.date().optional(),
      updatedAt: Joi.date().optional().allow(null),
    });

  const formValidation = (formData: LanguageEvaluationPhrases) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { error } = schema.validate(formData);
    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const handleInputChange = (value: string, fieldName: string) => {
    setPhrase((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setFormErrors(clearErrorMessage(formErrors, fieldName));
  };

  const handleDeletePhrase = (phraseItem: LanguageEvaluationPhrases) => {
    setPhraseToDelete(phraseItem);
    onOpen();
  };

  // istanbul ignore next
  const confirmDeletePhrase = async () => {
    try {
      setIsDeleting(true);
      await deleteDocumentById(Collections.LANGUAGE_EVALUATION_PHRASES, phraseToDelete?.id ?? '');
    } finally {
      setIsDeleting(false);
      onClose();
    }
  };

  // istanbul ignore next
  const submitPhrase = async () => {
    const merge = true;
    const payload = phrase;
    if (payload.id) {
      payload.updatedAt = moment().toDate();
    }
    if (!payload.userId) {
      payload.userId = userId ?? '';
    }
    if (!payload.createdAt) {
      payload.createdAt = moment().toDate();
    }

    if (formValidation(payload)) {
      try {
        setIsLoading(true);
        await setDocumentById(Collections.LANGUAGE_EVALUATION_PHRASES, payload.id ?? undefined, payload, merge);
        setPhrase(initialPhrase);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const confirmationDialog = (): JSX.Element => {
    return (
      <AlertDialog
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent data-testid="ConfirmationAlertModal">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('administration:languageEvaluation.deletePhraseTitle')}
            </AlertDialogHeader>
            <AlertDialogBody>{t('administration:languageEvaluation.deletePhraseDetail')}</AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>{t('administration:languageEvaluation.cancel')}</Button>
              <Button
                isLoading={isDeleting}
                data-testid="confirmDeletePhrase"
                colorScheme="red"
                ml={3}
                onClick={() => confirmDeletePhrase()}
              >
                {t('administration:languageEvaluation.confirm')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  };

  return (
    <VStack align="left" backgroundColor="white" p={4}>
      <Box py={2} px={4} borderBottom="0.5px solid #ECEFF1">
        <Heading as="h4" fontSize="lg" lineHeight="1.3">
          {t('administration:languageEvaluation.cardTitle')}
        </Heading>
      </Box>
      <Flex py={2} px={4}>
        <Box w="40%" mr={6}>
          <Flex mb={4}>
            <FormControl mr={3}>
              <FormLabel>{t('administration:languageEvaluation.selectLanguage')}</FormLabel>
              <Select
                data-testid="languageField"
                placeholder="Select Language"
                value={phrase.language}
                onChange={(e) => handleInputChange(e.target.value, 'language')}
              >
                <option value="fr">{t('administration:languageEvaluation.french')}</option>
                <option value="en">{t('administration:languageEvaluation.english')}</option>
              </Select>
            </FormControl>
            <FormControl isRequired isInvalid={hasErrorMessage(formErrors, 'phraseId')}>
              <FormLabel>{t('administration:languageEvaluation.phraseTitle')}</FormLabel>
              <Input
                type="text"
                data-testid="phraseIdField"
                value={phrase.phraseId}
                onChange={(e) => handleInputChange(e.target.value, 'phraseId')}
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'phraseId')}</FormErrorMessage>
            </FormControl>
          </Flex>
          <FormControl mb={4} isRequired isInvalid={hasErrorMessage(formErrors, 'description')}>
            <FormLabel>{t('administration:languageEvaluation.phrase')}</FormLabel>
            <Textarea
              pr="3.5rem"
              minH="100px"
              borderColor="gray.100"
              value={phrase.description}
              data-testid="phraseField"
              onChange={(e) => handleInputChange(e.target.value, 'description')}
            />
            <FormErrorMessage>{getErrorMessage(formErrors, 'description')}</FormErrorMessage>
          </FormControl>

          <Button isLoading={isLoading} data-testid="submitPhraseButton" colorScheme="blue" onClick={submitPhrase}>
            {`${phrase.id ? t('administration:languageEvaluation.update') : t('administration:languageEvaluation.add')}
            ${t('administration:languageEvaluation.phrase')}`}
          </Button>
        </Box>
        <Box w="60%">
          <VStack divider={<StackDivider borderColor="gray.100" />} spacing={2} align="stretch">
            {phrases.map((phraseItem, index) => (
              <Flex key={phraseItem.id} justifyContent="space-between">
                <Box pr={4}>
                  <Heading as="h3" fontSize="md">
                    {`${index + 1}. ${phraseItem.phraseId}
                      (${
                        phraseItem.language === 'fr'
                          ? t('administration:languageEvaluation.french')
                          : t('administration:languageEvaluation.english')
                      })`}
                  </Heading>
                  <Text fontSize="sm">{phraseItem.description}</Text>
                </Box>
                <Flex>
                  <IconButton
                    colorScheme="blue"
                    aria-label={t('administration:languageEvaluation.update')}
                    mr={2}
                    icon={<RiEdit2Fill />}
                    data-testid={`editPhrase-${phraseItem.id}`}
                    onClick={() => setPhrase(phraseItem)}
                  />
                  <IconButton
                    colorScheme="red"
                    aria-label={t('administration:languageEvaluation.delete')}
                    icon={<RiDeleteBin5Fill />}
                    data-testid={`deletePhrase-${phraseItem.id}`}
                    onClick={() => handleDeletePhrase(phraseItem)}
                  />
                </Flex>
              </Flex>
            ))}
          </VStack>
        </Box>
      </Flex>
      {confirmationDialog()}
    </VStack>
  );
};

export default LanguageEvaluationPhrasesView;
