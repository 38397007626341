/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, Text, Flex, Tooltip, Spinner } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { useSelectedEngagedCandidate } from '../../../../MessagingViewContext';
import { useSeekerAllCandidates } from '../../../query/useSeekerAllCandidates';
import { Candidate, FitStatus } from '../../../../../../firebase/firestore/documents/candidate';
import { isCandidateFromFollowedPosition } from '../../../../../../services/CandidateService';
import colors from '../../../../../../styles/colors';
import { useStoreState } from '../../../../../../models/hooks';
import { getTranslatedValue } from '../../../../../../utils/localizedString';
import CandidatePosition from './CandidatePosition';

type SeekerProps = {
  isTopCandidate?: boolean;
  // eslint-disable-next-line react/require-default-props
  selectedCandidateId?: string;
};

export const SeekerAllCandidates = ({ isTopCandidate, selectedCandidateId }: SeekerProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const appUserId = useStoreState((s) => s.app.user?.id);
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const candidates = useSeekerAllCandidates(selectedEngagedCandidate?.seeker || selectedCandidateId);

  const renderFollowedIcon = (candidate: Candidate) => {
    if (isCandidateFromFollowedPosition(candidate, appUserId)) {
      return (
        <Tooltip label={t('common:tooltip.followedPosition')} fontSize="sm">
          <CheckCircleIcon w={3} h={3} mr={2} color={colors.green['600']} />
        </Tooltip>
      );
    }
    return null;
  };

  const calculateAvailabilityFitBand = (availabilityFitBand: FitStatus | undefined) => {
    if (FitStatus.GOOD === availabilityFitBand) {
      return {
        icon: <AiFillPlusCircle style={{ color: colors.green['600'] }} />,
        message: t('profile.matchingRequisition.goodFit'),
      };
    }
    if (FitStatus.MEDIUM === availabilityFitBand) {
      return {
        icon: <AiFillPlusCircle style={{ color: 'orange' }} />,
        message: t('profile.matchingRequisition.mediumFit'),
      };
    }
    return {
      icon: <AiFillMinusCircle style={{ color: 'red' }} />,
      message: t('profile.matchingRequisition.poorFit'),
    };
  };

  const candidateBlock = (candidate: Candidate, dataIndex: number) => (
    <Flex
      key={candidate.id}
      alignItems="center"
      py={2}
      px={2}
      test-id="CandidateDropdownItem"
      bg={dataIndex % 2 === 1 ? 'gray.150' : 'white'}
    >
      <Flex flex={1} minW={64} alignItems="flex-start" justifyContent="space-between">
        <Box>
          <Text fontSize="xs" textTransform="uppercase">
            {candidate.businessName}
          </Text>
          <Text fontSize="sm" lineHeight="shorter" fontWeight="bold">
            {renderFollowedIcon(candidate)}
            {getTranslatedValue(candidate.jobTitle, i18n.language)}
          </Text>
          {candidate.availabilityMatchedRequisitions && candidate.availabilityMatchedRequisitions.length > 0 && (
            <Flex d="inline-flex" alignItems="center" fontSize="sm">
              <Box mr={1}>{calculateAvailabilityFitBand(candidate.availabilityMatchedRequisitions[0].fit).icon}</Box>
              <Box>{calculateAvailabilityFitBand(candidate.availabilityMatchedRequisitions[0].fit).message}</Box>
            </Flex>
          )}
        </Box>
        <Box d="flex" justifyContent="flex-end" flexDirection="column" alignItems="end">
          <Text
            fontSize="xs"
            background="gray.50"
            color="#4A5568"
            border="1px solid"
            borderColor="gray.100"
            borderRadius="10px"
            px={2}
            mb={1}
          >
            {t(`candidateStatus:${candidate.computedStatus?.status}`)}
          </Text>
          {candidate.position && !isTopCandidate && <CandidatePosition positionId={candidate.position} />}
        </Box>
      </Flex>
    </Flex>
  );

  return (
    <Box>
      {candidates && candidates.length > 0 ? (
        candidates.map((candidate, i) => candidateBlock(candidate, i))
      ) : (
        <Flex alignItems="center" justifyContent="center" py={5}>
          <Spinner color="blue.500" />
        </Flex>
      )}
    </Box>
  );
};

SeekerAllCandidates.defaultProps = {
  isTopCandidate: false,
};
