/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo, useCallback, useRef } from 'react';
import { Button } from '@chakra-ui/core';
import moment from 'moment';
import { useLocalVideoToggle } from './hooks/useLocalVideoToggle';
import { useDevices } from './hooks/useDevices';
import { ReactComponent as _IconVideo } from '../../../assets/img/icon-video.svg';
import { ReactComponent as _IconVideoOff } from '../../../assets/img/icon-video-off.svg';

const IconVideo = memo(_IconVideo);
const IconVideoOff = memo(_IconVideoOff);

type VideoToggleButtonProps = {
  isLoading: boolean;
};

export const VideoToggleButton = ({ isLoading }: VideoToggleButtonProps): JSX.Element => {
  const { isVideoEnabled, toggleVideo } = useLocalVideoToggle();
  const { hasVideoInputDevices, videoInputDevices } = useDevices();

  const lastClickTimeRef = useRef(0);

  const toggleVideoPreview = useCallback(() => {
    if (moment.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = moment.now();
      toggleVideo();
    }
  }, [toggleVideo]);

  return (
    <Button
      data-testid="ToggleVideoButton"
      onClick={toggleVideoPreview}
      isDisabled={!hasVideoInputDevices || isLoading || videoInputDevices[0]?.deviceId === ''}
      isLoading={isLoading}
      className={isVideoEnabled ? 'on' : 'off'}
      borderRadius="100%"
      height="45px"
      width="45px"
      p="5px"
    >
      {isVideoEnabled ? <IconVideo data-testid="VideoEnabledIcon" /> : <IconVideoOff data-testid="VideoDisabledIcon" />}
    </Button>
  );
};
