import _map from 'lodash/map';
import moment from 'moment-timezone';
import _filter from 'lodash/filter';
import { CalendarScheduleTypeEnum } from './CalendarScheduleTypeEnum';
import { CalendarEventType } from './CalendarEventType';
import { CalendarGroupEventType } from './CalendarGroupEventType';
import { GroupEvent } from '../../firebase/firestore/documents/groupEvent';

export class CalendarEventPresenter {
  constructor(readonly groupEvents: GroupEvent[], readonly locale: string) {}

  listFormattedGroupEventsForCalendarMonthView = () => {
    const newEventsGroup = this.groupEvents.filter(
      (v, i, a) => a.findIndex((t) => moment(t.startTime).format('L') === moment(v.startTime).format('L')) === i,
    );

    return this.formattingGroupEvent(newEventsGroup);
  };

  listFormattedGroupEventsForCalendarWeekView = () => {
    return this.formattingGroupEvent(this.groupEvents);
  };

  listGroupEventByDate = (date: Date): CalendarEventType[] => {
    return _filter(this.formattingGroupEvent(this.groupEvents), (event: CalendarEventType) => {
      return moment(event.start).format('L') === moment(date).format('L');
    });
  };

  formattingGroupEvent = (GEvents: GroupEvent[]) => {
    return _map(GEvents, (groupEvent) => {
      return {
        title: groupEvent.title,
        start: moment(groupEvent.startTime, moment.defaultFormat).toDate(),
        end: moment(groupEvent.endTime, moment.defaultFormat).toDate(),
        resource: {
          groupEvent,
          type: CalendarScheduleTypeEnum.GROUP_EVENT,
        } as CalendarGroupEventType,
      } as CalendarEventType;
    });
  };
}
