import { css } from '@emotion/core';

export const AvatarContainerStyles = css`
  max-height: 255px;
  overflow: auto;
  margin-bottom: 1em;

  .recruiter-item {
    display: flex;
    align-items: center;
    width: 26%;
    .avatar {
      background-color: orange;
      color: white;
      height: 38px;
      width: 38px;
    }

    :last-child {
      border-bottom: none;
    }
  }
`;
