/* istanbul ignore file */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, List, ListItem, Spacer, Spinner, Text, Tooltip } from '@chakra-ui/core';
import moment from 'moment-timezone';
import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';
import _isUndefined from 'lodash/isUndefined';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoIosStar, IoMdVideocam } from 'react-icons/io';
import { RiUserVoiceFill } from 'react-icons/ri';
import { MdRecordVoiceOver } from 'react-icons/md';
import { PhoneIcon } from '@chakra-ui/icons';
import { FaLock } from 'react-icons/fa';
import { AiFillMinusCircle } from 'react-icons/ai';

import { badgeStatusCSS, notificationListCSS } from '../Header.styles';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { Candidate, EngagedCandidate } from '../../../firebase/firestore/documents/candidate';
import { getTranslatedValue } from '../../../utils/localizedString';
import { CandidateStatusIcon } from './CandidateStatusIcon';
import { useHoursToAutomaticDismissal } from '../../../modules/calendar/events/useHoursToAutomaticDismissal';
import colors from '../../../styles/colors';
import useCandidateBooking from '../../components/useCandidateBooking';
import { INTERVIEW_MESSAGING_TABS, MESSAGING_TABS } from '../../../routes/constants';
import { SeekerContainerTabItems } from '../../../modules/messaging/seeker/SeekerContainerContext';
import { AppointmentType, AvailableBookingWithPosition } from '../../../firebase/firestore/documents/appointmentAvaiability';
import { AppointmentStatus } from '../../../firebase/firestore/documents/appointmentStatus';
import { useMessagingViewContext } from '../../../modules/messaging/MessagingViewContext';
import {
  SESSION_KEY_CANDIDATE_LIST_TYPE,
  useSeekersFilters,
} from '../../../modules/messaging/sidebar/filters/SeekersFiltersContext';
import { CandidateListType } from '../../../modules/messaging/sidebar/filters/seekersFiltersContextValueType';
import useAppointmentAvailability from '../../components/useAppointmentAvailability';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';

export type InterviewViewTypeProps = {
  readNotificationInvitation: (id: string) => void;
  onClose: () => void;
};

export const InterviewView = ({ readNotificationInvitation, onClose }: InterviewViewTypeProps): JSX.Element => {
  const [candidatesList, setCandidatesList] = useState<EngagedCandidate[]>([]);
  const [lastId, setLastId] = useState<string>('');
  const [hasNext, setSetHasNext] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const notificationLimit = 20;

  const gracePeriod: number = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE];
  });
  const dismissalHour = useHoursToAutomaticDismissal();

  const loadCandidatesForNotifications = useStoreActions((actions) => actions.candidate.loadCandidatesForNotifications);
  const accountId = useStoreState((s) => s.app.user?.account);
  const userId = useStoreState((s) => s.app.user?.id);
  const candidateNotificationBeforeInWeeks = useStoreState(
    (state) => state.app.accounts?.configuration?.candidateNotificationBeforeInWeeks,
  );
  const [notifications, setNotifications] = useState<Candidate[] | null>(null);
  const { setListType } = useSeekersFilters();
  useEffect(() => {
    if (candidatesList && candidatesList.length > 0) {
      let filteredCandidates = _filter(candidatesList, (candidate) => {
        if (!candidate.accountNotification?.lastUpdatedAt) return false;

        const isBetween = moment(candidate.accountNotification?.lastUpdatedAt).isBetween(
          moment().subtract(candidateNotificationBeforeInWeeks || 2, 'week'),
          moment.now(),
        );
        return isBetween && candidate.account === accountId;
      });
      /* istanbul ignore else */
      if (filteredCandidates.length > 0) {
        filteredCandidates = _orderBy(filteredCandidates, (candidate) => candidate.accountNotification?.lastUpdatedAt, 'desc');
      }
      setNotifications(filteredCandidates);
    } else setNotifications([]);
  }, [accountId, candidatesList, candidateNotificationBeforeInWeeks]);

  const loadEngagedCandidate = useCallback(
    async (id: string) => {
      setIsLoading(true);
      await loadCandidatesForNotifications({ accountId: id, after: lastId, limit: notificationLimit }).then((responseData) => {
        setCandidatesList((prevData) => [...prevData, ...responseData.candidates]);
        setLastId(responseData.lastId);
        setSetHasNext(responseData.candidates.length >= notificationLimit);
      });
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastId],
  );

  useEffect(() => {
    if (accountId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadEngagedCandidate(accountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const handleScroll = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const bottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight < 1;

    if (accountId && bottom && hasNext) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadEngagedCandidate(accountId);
    }
  };

  return (
    <Box
      data-testid="interviewListContainer"
      height="calc(100vh - 130px)"
      overflowX="hidden"
      overflowY="auto"
      onScroll={handleScroll}
    >
      <List css={notificationListCSS}>
        <>
          <Box>
            {notifications &&
              notifications.length > 0 &&
              notifications.map((notificationData) => {
                const minutesBeforeVideoInterview = moment(notificationData.appointment?.fromDate).diff(moment(), 'minutes');
                const isAbleToJoinVideoInterview =
                  notificationData.appointment?.type === AppointmentType.video &&
                  notificationData.appointment.status === AppointmentStatus.accepted &&
                  moment().isBetween(
                    moment(notificationData.appointment.fromDate).subtract(5, 'minute'),
                    moment(notificationData.appointment.toDate).add(gracePeriod, 'minute'),
                  );
                const dismissalDate = moment(notificationData.knockedOutAt).add(dismissalHour);
                const hoursToDismissal = dismissalDate.diff(moment(), 'hours');

                return (
                  <ListItem
                    px={4}
                    py={3}
                    key={notificationData.id}
                    id={notificationData.id}
                    borderBottom="1px solid #EBEBEB"
                    className={userId && !notificationData.accountNotification?.status?.[userId]?.read ? '' : 'read-notification'}
                  >
                    <Flex>
                      <Flex
                        data-testid={`MessagingRouteElement-${notificationData.id}`}
                        onClick={() => {
                          readNotificationInvitation(notificationData.id);
                          sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, CandidateListType.UNKNOWN);
                          history.push(MESSAGING_TABS(notificationData.id, SeekerContainerTabItems.Messaging));
                          setListType(CandidateListType.UNKNOWN);
                        }}
                        cursor="pointer"
                      >
                        <Box mr={2}>
                          <CandidateStatusIcon
                            // testID="statusIcon"
                            messages={{
                              positionClosed: t('notification:statusIcon.positionClosed'),
                              appointmentInThePast: t('notification:statusIcon.appointmentInThePast'),
                              appointmentAccepted: t('notification:statusIcon.appointmentStatusAccepted'),
                              appointmentReschedulingNeeded: t('notification:statusIcon.appointmentStatusReschedulingNeeded'),
                              appointmentRequested: t('notification:statusIcon.appointmentStatusRequested'),
                              appointmentDeclined: t('notification:statusIcon.appointmentStatusDeclined'),
                              appointmentCancelled: t('notification:statusIcon.appointmentStatusCancelled'),
                              contactRequested: t('notification:statusIcon.contactStatusSent'),
                              contactAccepted: t('notification:statusIcon.contactStatusAccepted'),
                              contactDeclined: t('notification:statusIcon.contactStatusDeclined'),
                              contactRevoked: t('notification:statusIcon.interestRevoked'),
                              seekerIsHired: t('notification:statusIcon.seekerIsHired'),
                              seekerIsNotLookingForWork: t('notification:statusIcon.seekerIsNotLookingForWork'),
                              appointmentDateFormat: t('notification:statusIcon.appointmentDateFormat'),
                              isGoingToBeDismissed: t(
                                `notification:statusIcon.hoursToDismissal.${
                                  hoursToDismissal === 0 ? 'zero' : hoursToDismissal > 1 ? 'other' : 'one'
                                }`,
                                {
                                  count: hoursToDismissal,
                                },
                              ),
                              videoInterviewWillBeAvailable: moment
                                .duration(minutesBeforeVideoInterview, 'minutes')
                                .humanize(true),
                              joinVideo: t('notification:statusIcon.joinVideo'),
                            }}
                            candidate={notificationData}
                            audience="Employer"
                            isAbleToJoinVideoInterview={isAbleToJoinVideoInterview}
                          />
                        </Box>
                        <Box>
                          <Flex direction="row">
                            <Text fontSize="sm" fontWeight="700" mr="10px">
                              {`${notificationData.firstName} ${notificationData.lastName}`}
                            </Text>
                          </Flex>
                          <Tooltip label={notificationData.businessName}>
                            <Text fontSize="sm" style={{ alignSelf: 'center' }}>
                              {getTranslatedValue(notificationData.jobTitle, i18n.language)}
                            </Text>
                          </Tooltip>
                          {/* <Text fontSize="sm">{notificationData.businessName}</Text> */}
                          <Text fontSize="xs" color={colors.gray[500]}>
                            {moment(notificationData.accountNotification?.lastUpdatedAt).format('lll')}
                          </Text>
                        </Box>
                      </Flex>
                      <Spacer />
                      <Box display="flex" flexDirection="column" alignItems="flex-end">
                        {!isAbleToJoinVideoInterview && (
                          <Box>
                            <NotificationBadgeSection candidate={notificationData} />
                          </Box>
                        )}
                        {isAbleToJoinVideoInterview && <JoinButton candidate={notificationData} onClose={onClose} />}
                        <RenderRecruiterName candidate={notificationData} />
                      </Box>
                    </Flex>
                  </ListItem>
                );
              })}
            {isLoading ? (
              <Box d="flex" p={8} justifyContent="center">
                <Spinner size="md" color={colors.blue.default} />
              </Box>
            ) : (
              notifications &&
              notifications.length <= 0 && (
                <ListItem key="1" borderBottom="1px solid #EBEBEB" px={5} py={4}>
                  <Box>
                    <Text fontSize="sm" fontWeight="700" data-testid="noNotificationText">
                      {t('notification:noNotification')}
                    </Text>
                  </Box>
                </ListItem>
              )
            )}
          </Box>
        </>
      </List>
    </Box>
  );
};

const NotificationBadgeSection = ({ candidate }: { candidate: Candidate }): JSX.Element => {
  // eslint-disable-next-line no-nested-ternary
  const type = candidate.appointment ? 'appointment' : !candidate.appointment && !!candidate.contactStatus ? 'contact' : '';
  const { t } = useTranslation('notification');
  let icon: JSX.Element | null = null;
  /* istanbul ignore next */
  const { hired, positionOpen = true } = candidate;

  const isCandidateGoingToBeDismissed = candidate.dismissed && !candidate.system?.shouldBeDismissed && !candidate.monetized;

  if (type === 'appointment') {
    if (candidate.appointment?.type === AppointmentType.video) {
      icon = <IoMdVideocam />;
    } else if (candidate.appointment?.type === AppointmentType.phone) {
      icon = <PhoneIcon />;
    } else {
      icon = <MdRecordVoiceOver />;
    }
  }
  return (
    <Box
      css={badgeStatusCSS}
      fontSize="xs"
      px={2}
      mx={2}
      textTransform="capitalize"
      lineHeight="1.8"
      className={
        hired
          ? 'accepted'
          : isCandidateGoingToBeDismissed
          ? 'declined'
          : !positionOpen
          ? 'closed'
          : type === 'appointment'
          ? candidate.appointment?.status ?? ''
          : type === 'contact'
          ? candidate.contactStatus?.status ?? ''
          : ''
      }
    >
      {hired && (
        <Flex alignItems="center" gridGap="8px" justifyContent="center">
          <IoIosStar />
          {t('statusIcon.seekerIsHired')}
        </Flex>
      )}
      {!hired && isCandidateGoingToBeDismissed && (
        <Flex alignItems="center" gridGap="8px" justifyContent="center">
          <AiFillMinusCircle />
          {t('candidateStatus:DISMISSED')}
        </Flex>
      )}
      {!hired && !positionOpen && (
        <Flex alignItems="center" gridGap="8px" justifyContent="center">
          <FaLock />
          {t('statusIcon.closed')}
        </Flex>
      )}
      {!hired && positionOpen && type === 'appointment' && (
        <Flex alignItems="center" gridGap="8px" justifyContent="center">
          {icon}
          {candidate.appointment?.status ? t(candidate.appointment.status) : ''}
        </Flex>
      )}
      {!hired && positionOpen && type === 'contact' && candidate.contactStatus?.status ? t(candidate.contactStatus.status) : ''}
    </Box>
  );
};

const JoinButton = ({ candidate, onClose }: { candidate: Candidate; onClose: () => void }): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation(['meet']);
  const { setListType } = useSeekersFilters();

  const setInterviewEvent = useStoreActions((state) => state.interview.setInterviewEvent);
  const candidateAndBookingDetails = useCandidateBooking(candidate.id);
  const { setSelectedEngagedCandidate, setSelectedEngagedSeeker } = useMessagingViewContext();
  const candidatesList = useStoreState((s) => s.candidate.allCandidates);

  const joinVideoInterview = () => {
    const selectedCandidate = candidatesList?.engagedCandidates.filter((c) => c.id === candidate.id)[0];
    setInterviewEvent(candidateAndBookingDetails as AvailableBookingWithPosition);
    setSelectedEngagedCandidate(selectedCandidate);
    setSelectedEngagedSeeker(selectedCandidate && { seekerId: selectedCandidate.seeker, candidateId: selectedCandidate.id });
    /* istanbul ignore else */
    if (selectedCandidate) {
      sessionStorage.setItem(SESSION_KEY_CANDIDATE_LIST_TYPE, CandidateListType.UNKNOWN);
      setListType(CandidateListType.UNKNOWN);
      history.push(INTERVIEW_MESSAGING_TABS(selectedCandidate.id, SeekerContainerTabItems.Messaging));
    }
    onClose();
  };

  return (
    <Button
      size="sm"
      onClick={joinVideoInterview}
      data-testid={`VideoCallButton-${candidate.appointment?.bookingId}`}
      leftIcon={<IoMdVideocam />}
      colorScheme="orange"
      variant="solid"
      style={{ width: '115px' }}
      isLoading={_isUndefined(candidateAndBookingDetails)}
    >
      {t('join').replace('!', '')}
    </Button>
  );
};

const RenderRecruiterName = ({ candidate }: { candidate: Candidate }): JSX.Element => {
  const { t } = useTranslation('notification');
  const recruiterName = candidate.appointment?.recruiterName;
  const candidateAndBookingDetails = useCandidateBooking(candidate.id);
  const bookingDetails = candidateAndBookingDetails as AvailableBookingWithPosition;
  const availabilityData = useAppointmentAvailability(bookingDetails?.booking?.availabilityId);

  const currentUserId = useStoreState((s) => s.app.user?.id);
  const bookingCreatorUserId = availabilityData?.userId;

  const isBookingCreatorCurrentUser = currentUserId === bookingCreatorUserId;

  return recruiterName && bookingCreatorUserId && !isBookingCreatorCurrentUser ? (
    <Tooltip label={t('otherRecruitersAppointment', { otherRecruiter: recruiterName })} fontSize="md" placement="top">
      <Box pt={2} mb={1} display="flex" alignItems="center">
        <RiUserVoiceFill data-testid="copyIcon" color={colors.customRed} cursor="pointer" size={14} />
        <Text
          data-testid={`RecruiterName-${candidate.appointment?.bookingId}`}
          className="recruiter-name"
          color="blue.500"
          fontSize="0.7rem"
          fontWeight="700"
          lineHeight={1}
          pl={2}
        >
          {recruiterName}
        </Text>
      </Box>
    </Tooltip>
  ) : (
    <></>
  );
};
