/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, memo, useCallback } from 'react';
import { Box, Flex, List, Text, Spacer, Button, Tooltip } from '@chakra-ui/core';
import { DeleteIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { ReactComponent as _IconVideoCall } from '../../../assets/img/video-accepted.svg';
import { ReactComponent as _IconEditAvailability } from '../../../assets/img/edit-box-fill.svg';
import { CalendarEventType } from '../CalendarEventType';
import { CalendarGroupEventType } from '../CalendarGroupEventType';
import { EventActionType } from './EventActionTypeEnum';
import { useEventAllowDeletionMinutes } from '../events/useEventAllowDeletionMinutes';

const VideoImage = memo(_IconVideoCall);
const IconEditAvailability = memo(_IconEditAvailability);
const ButtonStyle = css`
  background: transparent;
  min-width: 1.4rem;
  height: initial;
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    outline: none;
    box-shadow: none;
  }
`;

export type EventListProps = {
  eventList: CalendarEventType[];
  handleEvent: (event: CalendarEventType, actionType: string) => void;
};

export const EventListView = ({ eventList, handleEvent }: EventListProps): JSX.Element => {
  const { t, i18n } = useTranslation('calendar');
  const allowDeletionMinutes = useEventAllowDeletionMinutes();
  const isAbleToDeleteAndEditEvent = useCallback(
    (groupEventStartDate: Date) => {
      return moment().add(allowDeletionMinutes, 'minutes').diff(moment(groupEventStartDate)) > 0;
    },
    [allowDeletionMinutes],
  );

  /* istanbul ignore next */
  const sortedEventList = eventList.sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf());
  return (
    <Fragment>
      <List p={4} spacing={3} className="schedule-list-wrapper" data-testid="EventListView">
        {sortedEventList.map((event) => {
          const gEvent = event.resource as CalendarGroupEventType;
          return (
            <Box className="schedule-box" key={gEvent.groupEvent.id} data-testid={gEvent.groupEvent.id}>
              <Flex
                align="center"
                onClick={/* istanbul ignore next */ () => handleEvent(event, EventActionType.view)}
                style={{ cursor: 'context-menu' }}
              >
                <Box className="icon-block">
                  <VideoImage data-testid="video" />
                </Box>
                <Box ml="3">
                  <Text fontWeight="bold" fontSize="sm">
                    {event.title}
                  </Text>
                  <Text fontSize="0.65rem">
                    {
                      /* istanbul ignore next */
                      i18n.language === 'fr'
                        ? `${moment(event.start).format('LT')} - ${moment(event.end).format('LT')}`
                        : `${moment(event.start).format('hh:mm A')} - ${moment(event.end).format('hh:mm A')}`
                    }
                  </Text>
                </Box>
              </Flex>
              <Spacer />
              {moment(event.start).toDate() > moment().toDate() ? (
                <Tooltip
                  className="modify-event-tooltip"
                  isDisabled={!isAbleToDeleteAndEditEvent(event.start)}
                  label={t('eventListView.tooltip', { allowDeletionMinutes })}
                  placement="top"
                  aria-label={t('eventListView.tooltip', { allowDeletionMinutes })}
                >
                  <Flex>
                    <Button
                      disabled={isAbleToDeleteAndEditEvent(event.start)}
                      p={0}
                      css={ButtonStyle}
                      data-testid="EditGroupEvent"
                      onClick={/* istanbul ignore next */ () => handleEvent(event, EventActionType.edit)}
                    >
                      <IconEditAvailability width={20} height={20} />
                    </Button>
                    <Button
                      disabled={isAbleToDeleteAndEditEvent(event.start)}
                      p={0}
                      data-testid="DeleteGroupEvent"
                      color="red.500"
                      css={ButtonStyle}
                      style={{ lineHeight: '0', marginLeft: '5px' }}
                      onClick={/* istanbul ignore next */ () => handleEvent(event, EventActionType.delete)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Flex>
                </Tooltip>
              ) : (
                <Box className="expired-event-text" px={2} py={1} data-testid="ExpireEventText">
                  {t('eventListView.expiredEvent')}
                </Box>
              )}
            </Box>
          );
        })}
      </List>
    </Fragment>
  );
};
