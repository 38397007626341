import React, { ChangeEvent } from 'react';
import { Box, Text, Checkbox } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';

export const FavoriteCandidateView = (): JSX.Element => {
  const { t } = useTranslation();
  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();
  const onHandleFavourite = (isFavouriteApplied: boolean) => {
    searchAndSortTypes.isFavouriteApplied = isFavouriteApplied;
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...searchAndSortTypes });
  };
  return (
    <Box>
      <Checkbox
        data-testid="favoriteCheckBox"
        isChecked={searchAndSortTypes.isFavouriteApplied}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onHandleFavourite(e.target.checked)}
      >
        <Text
          px={3}
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
          }}
        >
          {t('favoriteCandidate.favoriteCandidate')}
        </Text>
      </Checkbox>
    </Box>
  );
};
