import React, { useState } from 'react';
import { Box, Center, Flex, Image, Select, Text } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import _isNil from 'lodash/isNil';
import { SpecificQuestions } from '../specificQuestions/SpecificQuestions';
import { FreeFormQuestions } from '../freeFormQuestions/FreeFormQuestions';
import { MultipleChoiceQuestionView } from '../multipleChoiceQuestions/MultipleChoiceQuestionView';
import { useStoreState } from '../../../../../models/hooks';
import { RemoteConfigKey } from '../../../../../firebase/remoteConfig';
import useSpecificQuestions, { QuestionStateEnum } from '../specificQuestions/useSpecificQuestions';
import useQuestions from '../freeFormQuestions/useFreeFormQuestions';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { QuestionType } from './QuestionType';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';
import { EngagedCandidate } from '../../../../../firebase/firestore/documents/candidate';

export type QuestionsProps = {
  candidateFromTopCandidate?: EngagedCandidate | undefined;
};
export const Questions = ({ candidateFromTopCandidate }: QuestionsProps) => {
  const { t } = useTranslation();
  const [activeQuestionType, setCurrentQuestionType] = useState<QuestionType>(QuestionType.ALL_QUESTIONS);
  const currentAccount = useStoreState((state) => state.app.accounts);
  const enableMultipleChoiceFeature: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE];
  });
  const { initialized, questions, state } = useSpecificQuestions(candidateFromTopCandidate);
  const { initialized: freeFormQuestionInitialized, questions: freeFormQuestions, state: freeFormState } = useQuestions(
    candidateFromTopCandidate,
  );
  const { selectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const candidate = selectedCandidate || selectedEngagedCandidate || candidateFromTopCandidate;

  const questionsCount = freeFormQuestions?.getQuestionsCount() || 0;

  const noQuestionsView = () => (
    <Center>
      <Flex direction="column" data-testid="NoQuestions" align="center" p={3} textAlign="center">
        <Image src="/questions.svg" w="180px" h="auto" alt="No SpecificQuestions" />
        <Text mt="8">
          <b>{t('questions.noAnswers')}</b>
        </Text>
      </Flex>
    </Center>
  );

  return (
    <Box py={2}>
      <Select
        onChange={(e) => setCurrentQuestionType(e.target.value as QuestionType)}
        bg="blue.500"
        color="#fff"
        borderColor="blue.500"
        mr={5}
        mb={2}
        fontSize="sm"
        fontWeight="bold"
        data-testid="filterQuestionType"
        value={activeQuestionType}
      >
        <option value={QuestionType.ALL_QUESTIONS} style={{ color: '#000' }}>
          {t('common:allQuestions.title')}
        </option>
        <option value={QuestionType.SPECIFIC_QUESTIONS} style={{ color: '#000' }}>
          {t('common:questions.title')}
        </option>
        {!!currentAccount?.configuration?.freeFormQuestionEnabled && (
          <option value={QuestionType.FREE_FORM_QUESTIONS} style={{ color: '#000' }}>
            {t('common:freeFormQuestions.title')}
          </option>
        )}
        {enableMultipleChoiceFeature && (
          <option value={QuestionType.MULTIPLE_CHOICE_QUESTIONS} style={{ color: '#000' }}>
            {t('createJobPosition:multipleChoiceQuestions')}
          </option>
        )}
      </Select>
      {activeQuestionType === QuestionType.ALL_QUESTIONS && state !== QuestionStateEnum.UNDEFINED && (
        <Text fontSize="sm" fontWeight="bold" mt={3} pb={1} mb={3} borderBottom="1px solid" borderColor="gray.50">
          {t('common:questions.title')}
        </Text>
      )}
      {(activeQuestionType === QuestionType.SPECIFIC_QUESTIONS || activeQuestionType === QuestionType.ALL_QUESTIONS) && (
        <SpecificQuestions
          activeQuestionType={activeQuestionType}
          initialized={initialized}
          questions={questions}
          state={state}
        />
      )}

      {activeQuestionType === QuestionType.ALL_QUESTIONS &&
        !!currentAccount?.configuration?.freeFormQuestionEnabled &&
        (freeFormState !== QuestionStateEnum.UNDEFINED || questionsCount !== 0) && (
          <Text fontSize="sm" fontWeight="bold" mt={3} pb={1} mb={3} borderBottom="1px solid" borderColor="gray.50">
            {t('common:freeFormQuestions.title')}
          </Text>
        )}
      {((!!currentAccount?.configuration?.freeFormQuestionEnabled && activeQuestionType === QuestionType.FREE_FORM_QUESTIONS) ||
        activeQuestionType === QuestionType.ALL_QUESTIONS) && (
        <FreeFormQuestions
          activeQuestionType={activeQuestionType}
          initialized={freeFormQuestionInitialized}
          questions={freeFormQuestions}
          state={freeFormState}
        />
      )}

      {activeQuestionType === QuestionType.ALL_QUESTIONS &&
        enableMultipleChoiceFeature &&
        !_isNil(candidate?.multipleChoiceQuestionResponses) &&
        candidate?.multipleChoiceQuestionResponses &&
        candidate?.multipleChoiceQuestionResponses?.length > 0 && (
          <Text fontSize="sm" fontWeight="bold" mt={3} pb={1} mb={3} borderBottom="1px solid" borderColor="gray.50">
            {t('createJobPosition:multipleChoiceQuestions')}
          </Text>
        )}

      {((enableMultipleChoiceFeature && activeQuestionType === QuestionType.MULTIPLE_CHOICE_QUESTIONS) ||
        activeQuestionType === QuestionType.ALL_QUESTIONS) && (
        <MultipleChoiceQuestionView activeQuestionType={activeQuestionType} selectedCandidate={candidate} />
      )}

      {activeQuestionType === QuestionType.ALL_QUESTIONS &&
        state === QuestionStateEnum.UNDEFINED &&
        (freeFormState === QuestionStateEnum.UNDEFINED || (freeFormState === QuestionStateEnum.READY && questionsCount === 0)) &&
        (_isNil(candidate?.multipleChoiceQuestionResponses) ||
          (candidate?.multipleChoiceQuestionResponses && candidate?.multipleChoiceQuestionResponses?.length <= 0)) &&
        noQuestionsView()}
    </Box>
  );
};
