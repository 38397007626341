export function extractFilenameFromPath(path: string): string | null {
  try {
    // Get the last segment of the path (filename)
    const fullFilename = path.substring(path.lastIndexOf('/') + 1);

    // Match filenames where the hash is numeric and separated by '-'
    const regex = /^\d+-\d+[_-](.+)$/;
    const match = regex.exec(fullFilename);

    if (match && match.length === 2) {
      return match[1]; // Return the actual filename part without decoding
    }

    return null; // Return null if format doesn't match
  } catch (error) {
    console.error('Error extracting filename:', error);
    return null;
  }
}
