import { LocalAudioTrack, LocalVideoTrack, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import { useEffect, useState } from 'react';

type TrackType = LocalAudioTrack | LocalVideoTrack | RemoteAudioTrack | RemoteVideoTrack | undefined;

export const useTrackEnabled = (track: TrackType): boolean => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setIsEnabled(track?.isEnabled ?? false);

    if (track) {
      const setEnabled = () => {
        setIsEnabled(true);
      };

      const setDisabled = () => {
        setIsEnabled(false);
      };

      track.on('enabled', setEnabled);
      track.on('disabled', setDisabled);

      return () => {
        track.off('enabled', setEnabled);
        track.off('disabled', setDisabled);
      };
    }
  }, [track]);

  return isEnabled;
};
