import { useEffect, useState } from 'react';
import { Participant, TrackPublication } from 'twilio-video';
import { useMeetContext } from '../../MeetContext';
import { TRACK_SCREEN } from '../../constants';

export const useScreenShareParticipant = (): Participant | undefined => {
  const [screenShareParticipant, setScreenShareParticipant] = useState<Participant>();
  const { room } = useMeetContext();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room) {
      const updateScreenShareParticipant = () => {
        const participants = Array.from<Participant>(room.participants.values())
          .concat(room.localParticipant)
          .find((participant: Participant) => {
            return Array.from<TrackPublication>(participant.tracks.values()).find((track) => {
              return track.trackName.includes(TRACK_SCREEN);
            });
          });
        setScreenShareParticipant(participants);
      };

      room.on('trackPublished', updateScreenShareParticipant);
      room.on('trackUnpublished', updateScreenShareParticipant);
      room.on('participantDisconnected', updateScreenShareParticipant);

      // room does not emit 'trackPublished' events for local participants
      room.localParticipant.on('trackPublished', updateScreenShareParticipant);
      room.localParticipant.on('trackUnPublished', updateScreenShareParticipant);

      return () => {
        room.off('trackPublished', updateScreenShareParticipant);
        room.off('trackUnpublished', updateScreenShareParticipant);
        room.off('participantDisconnected', updateScreenShareParticipant);

        room.localParticipant.off('trackPublished', updateScreenShareParticipant);
        room.localParticipant.off('trackUnPublished', updateScreenShareParticipant);
      };
    }
  }, [room]);

  return screenShareParticipant;
};
