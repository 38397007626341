import { useCallback, useRef, useState } from 'react';
import { MediaStreamTrackPublishOptions, Room } from 'twilio-video';
import { getUserScreen } from '../../screenSharingUtil';
import { TRACK_SCREEN } from '../../constants';

type ScreenShareInfo = {
  isSharingScreen: boolean;
  toggleScreenSharing: () => void;
};

export const useScreenShareToggle = (room: Room | null): ScreenShareInfo => {
  const [isPublishing, setIsPublishing] = useState(false);
  const screenShareRef = useRef<() => void>(null!);

  const toggleSharing = useCallback(() => {
    if (!room) return;

    if (isPublishing) {
      screenShareRef.current();
      return;
    }

    const { localParticipant } = room;

    // For screen sharing.
    getUserScreen()
      .then((stream) => {
        const track = stream.getTracks()[0];
        localParticipant
          ?.publishTrack(track, { name: TRACK_SCREEN, priority: 'standard' } as MediaStreamTrackPublishOptions)
          .then((trackPublication) => {
            // Reference to stop screen sharing
            screenShareRef.current = () => {
              localParticipant.unpublishTrack(track);

              localParticipant.emit('trackUnpublished', trackPublication);
              track.stop();
              setIsPublishing(false);
            };

            // eslint-disable-next-line no-param-reassign
            track.onended = screenShareRef.current;
            setIsPublishing(true);
          })
          .catch((e) => {
            console.log('PublishError', e);
          });
      })
      .catch((e: Error) => {
        if (e.name !== 'AbortError' && e.name !== 'NotAllowedError') {
          console.log('ScreenToggle', e);
        }
      });
  }, [room, isPublishing, screenShareRef]);

  return {
    isSharingScreen: isPublishing,
    toggleScreenSharing: toggleSharing,
  };
};
