import React, { useEffect, useState } from 'react';
import { Box, Center, Spinner, Stack, Text, VStack, Heading, Flex, useToast } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { MdContentCopy } from 'react-icons/md';
import colors from '../../../styles/colors';
import { AccountController } from '../../../controllers/accountController';
import { useStoreState } from '../../../models/hooks';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';

import vectorImg from './Vector.png';

const Information = (): JSX.Element => {
  const [webApplyUrl, setWebApplyUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const { user } = useStoreState((s) => s.app);
  const { client } = useStoreState((s) => s.http);
  const { t } = useTranslation(['common']);
  const toast = useToast();

  useEffect(() => {
    const controller = new AccountController(client as HttpClientWithRetry);
    const getWebApplyURL = async (): Promise<void> => {
      if (user) {
        const url = await controller.getWebApplyJobListURL(user.account, user.id);
        setWebApplyUrl(url?.webApplyAccountUrl);
        setLoading(false);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getWebApplyURL();
  }, [user, client]);

  // istanbul ignore next
  const onCopyURL = async () => {
    await navigator.clipboard.writeText(webApplyUrl);
    toast({
      title: t('informationTab.toastMessage'),
      duration: 2000,
      isClosable: true,
    });
  };

  if (loading) {
    return (
      <Center data-testid="Info">
        <Spinner color={colors.blue.default} data-testid="Spinner" />
      </Center>
    );
  }

  return (
    <VStack align="left" backgroundColor="white" p={4} minHeight="644px">
      <Box py={2} px={4} borderBottom="0.5px solid #ECEFF1">
        <Heading as="h4" fontSize="lg" lineHeight="1.3">
          {t('certn:jobListingCardHeader')}
        </Heading>
        <Text m="3px 0" color="gray.500" fontSize="12px">
          {t('certn:jobListingCardHint')}
        </Text>
      </Box>

      <Box py={4} px={6}>
        <Flex>
          <div style={{ marginTop: '0.7rem', marginRight: '1rem' }}>
            <img src={vectorImg} alt="link-imgage" style={{ width: '1.3rem', height: '1.3rem' }} />
          </div>

          <div>
            <Heading as="h4" size="md">
              {t('informationTab.headerText')}
            </Heading>
            <Stack direction="row">
              <a color="blue" target="_blank" href={webApplyUrl} rel="noopener noreferrer">
                <Text color={colors.blue.default}>{webApplyUrl}</Text>
              </a>
              <MdContentCopy data-testid="copyIcon" cursor="pointer" size={18} onClick={onCopyURL} />
            </Stack>
          </div>
        </Flex>
      </Box>
    </VStack>
  );
};

export default Information;
