export enum NotificationHeaderTypeEnum {
  NOTIFICATION,
  STORIES,
}

export enum NotificationTypeEnum {
  GROUP_EVENT,
  INTERVIEWS,
  BACKGROUND_CHECK,
  LOW_AVAILABILITY,
  HIRE_FORM,
  MODERN_HIRE_FORM,
  REQUISITION,
  OTHERS,
  AUDIO_LANGUAGE_EVALUATION,
}
