import { useDocumentSnapshot } from '../../../firebase/firestore/hooks';
import { Collections } from '../../../firebase/firestore/collections';

import { Business } from '../../../firebase/firestore/documents/business';

const useBusinessLocation = (businessId: string | undefined): Business | undefined => {
  return useDocumentSnapshot<Business>(Collections.BUSINESSES, businessId);
};

export default useBusinessLocation;
