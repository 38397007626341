import React, { useEffect } from 'react';
import isNil from 'lodash/isNil';
import { useHistory } from 'react-router-dom';
import { Header } from '../../app/topBar/Header';
import { useStoreState } from '../../models/hooks';
import { NotAuthorizedView } from './NotAuthorizedView';
import { AdministrationView } from './AdministrationView';

export const AdministrationLayout = (): JSX.Element => {
  const history = useHistory();

  const appUserId = useStoreState((s) => s.app.user?.id);
  const appOwnersId = useStoreState((s) => s.app.accounts?.owners);

  // to reset location.state value
  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, [history]);

  const isAuthorizationStateLoaded = Boolean(appOwnersId && appUserId);

  const isAdministrationAccount = !isNil(appOwnersId) && !isNil(appUserId) && appOwnersId?.includes(appUserId);

  return (
    <React.Fragment>
      <Header showMenu />
      {isAdministrationAccount ? (
        <AdministrationView />
      ) : (
        <NotAuthorizedView isAuthorizationStateLoaded={isAuthorizationStateLoaded} />
      )}
    </React.Fragment>
  );
};
