import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spinner,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { RiPlayCircleFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';

import { videoBlockStyle } from './CreateJobPositionStyle';
import videoThumb from '../../../../assets/img/video-placeholder.png';
import colors from '../../../../styles/colors';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { VideoGalleryView } from '../../videoGallery/VideoGalleryView';
import { VideoGallery } from '../../../../firebase/firestore/documents/videoGallery';
import { JobPosition } from '../PositionType';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';

interface VideoProps {
  positionId: string | null;
  changeOverviewVideo: (fileToUpload: File | null, thumbnail?: File) => void;
  saveVideoUrl: (url: string | null) => void;
  jobPositionData: JobPosition;
  onAddVideoToPosition: (
    videoUrlId: string | undefined,
    newVideoUrl: string | undefined,
    videoUrlThumbnail: string | undefined,
  ) => void;
}

export const VideoDetailCard = ({
  positionId,
  changeOverviewVideo,
  saveVideoUrl,
  jobPositionData,
  onAddVideoToPosition,
}: VideoProps): JSX.Element => {
  const myRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const toast = useToast();
  const [overviewVideoUrl, setOverviewVideoUrl] = useState('');
  const [overviewThumbnailUrl, setOverviewThumbnailUrl] = useState('');
  const [loadingUrl, setLoadingUrl] = useState(false);
  const enableVideoGallery: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_VIDEO_GALLERY];
  });
  // istanbul ignore next
  const { getOverviewThumbnail, getOverviewVideo, removeOverviewVideo } = useStoreActions(
    (actions) => actions.positionManagement,
  );
  const { user } = useStoreState((s) => s.app);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenVideoSelect, onOpen: onOpenVideoSelect, onClose: onCloseVideoSelect } = useDisclosure();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const videoLength = useRef(0);

  const [error, setError] = useState('');

  const generateVideoThumbnail = (file: File): Promise<File> => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      video.setAttribute('data-testid', 'videoPlayer');

      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = async () => {
        videoLength.current = video.duration / 60;
        video.currentTime = video.duration > 2 ? /* istanbul ignore next */ 2 : 1;
        await video.play();
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        const dataUrl = canvas.toDataURL('image/jpeg');
        setOverviewThumbnailUrl(dataUrl);
        const blob = await fetch(dataUrl).then((it) => it.blob());

        const thumbnail = new File([blob], 'thumbnail.jpeg', { type: 'image/jpeg', lastModified: moment.now() });
        resolve(thumbnail);
      };
    });
  };

  const allowedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg', 'video/mov', 'video/quicktime'];

  const uploadTrigger = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // istanbul ignore else
    if (event.target && event.target.files) {
      const file = event.target.files?.[0];
      if (file && !allowedVideoTypes.includes(file.type)) {
        toast({
          title: t('createJobPosition:videoImageFormatFail'),
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        return;
      }
      const maxSize = 50 * 1024 * 1024;
      if (!file) return;
      if (file.size > maxSize) {
        setError(t('createJobPosition:video.maxSizeError', { size: 50 }));
        return;
      }
      const thumbnail = await generateVideoThumbnail(file);
      if (videoLength.current > 1) {
        setError(t('createJobPosition:video.maxLengthError', { length: 1 }));
        return;
      }
      changeOverviewVideo(file, thumbnail);
      setOverviewVideoUrl(URL.createObjectURL(file));
      saveVideoUrl(URL.createObjectURL(file));
      setError('');
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const handleRemoveOverviewVideo = async () => {
    // istanbul ignore else
    if (positionId && user) {
      const video = await getOverviewVideo({ accountId: user.account, positionId });
      // istanbul ignore else
      if (!_isEmpty(video)) await removeOverviewVideo({ positionId });
    }
    changeOverviewVideo(null);
    saveVideoUrl(null);
    setOverviewVideoUrl('');
    setOverviewThumbnailUrl('');
    setError('');
  };

  useEffect(() => {
    let isMounted = true;

    if (positionId && user && user.account) {
      const fetchVideoAndThumbnail = async () => {
        if (!enableVideoGallery) {
          setLoadingUrl(true);
        }
        const video = await getOverviewVideo({ accountId: user.account, positionId });

        // istanbul ignore else
        if (!_isEmpty(video)) {
          const thumbnail = await getOverviewThumbnail({ accountId: user.account, positionId });
          // istanbul ignore else
          if (isMounted) {
            setOverviewVideoUrl(video);
            saveVideoUrl(video);
            setOverviewThumbnailUrl(thumbnail);
            setLoadingUrl(false);
          }
        } else if (isMounted) setLoadingUrl(false);
      };
      fetchVideoAndThumbnail().catch(
        /* istanbul ignore next */ (e) => {
          Bugsnag.notify(e);
        },
      );
    }
    return () => {
      isMounted = false;
    };
  }, [getOverviewThumbnail, getOverviewVideo, positionId, user, saveVideoUrl, enableVideoGallery]);

  useEffect(() => {
    if (jobPositionData.videoUrl) setOverviewVideoUrl(jobPositionData.videoUrl);
    if (jobPositionData.videoUrlThumbnail) setOverviewThumbnailUrl(jobPositionData.videoUrlThumbnail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPositionData.videoUrl]);

  const onSetVideo = (videoGallery: VideoGallery | undefined) => {
    if (videoGallery) {
      onCloseVideoSelect();
      /* istanbul ignore next */
      setTimeout(() => {
        onAddVideoToPosition(videoGallery?.id || '', videoGallery?.videoUrl, videoGallery?.thumbnailUrl);
      }, 500);
    }
  };

  const onGetThumbnailUrl = () => {
    if (_isEmpty(overviewVideoUrl || jobPositionData.videoUrlThumbnail)) {
      return videoThumb;
    }
    if (jobPositionData.videoUrlThumbnail) {
      return jobPositionData.videoUrlThumbnail;
    }
    return overviewThumbnailUrl;
  };

  return (
    <Box backgroundColor="white" borderRadius={5} mb={6} ref={myRef}>
      <Box px={6} py={4}>
        <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
          {t('createJobPosition:videoCardHeader')}
        </Heading>
        <Text fontSize="xs" color="gray.500">
          {t('createJobPosition:videoCardHint')}
        </Text>
      </Box>
      <Divider borderColor="#ECEFF1" />
      <Box>
        <Flex p={6} alignItems="center" bgColor="#F5F5F5">
          <Box mr={6}>
            <Button colorScheme="blue" size="sm">
              {t('createJobPosition:maximizeInterest')}
            </Button>
          </Box>
          <Box>
            <Text fontSize="sm">{t('createJobPosition:interestDetail')}</Text>
          </Box>
        </Flex>
        <Box p={6}>
          {!loadingUrl && !_isEmpty(error) && (
            <Box textAlign="center" data-testid="errorContainer">
              <Text style={{ color: colors.red[500] }}>{error}</Text>
            </Box>
          )}
          <Flex p={6} justifyContent="center">
            {loadingUrl && <Spinner />}
            {!loadingUrl && (
              <Box css={videoBlockStyle}>
                <Box position="relative" display="inline-block" mb={3}>
                  <Image src={onGetThumbnailUrl()} alt="Video Thumb" loading="eager" />
                  {!_isEmpty(overviewVideoUrl) && !_isEmpty(overviewThumbnailUrl) && (
                    <Box className="icon-block" onClick={onOpen} data-testid="overviewImageCircle">
                      <RiPlayCircleFill />
                    </Box>
                  )}
                </Box>
                <Flex>
                  <Button
                    colorScheme="blue"
                    backgroundColor={colors.blue[400]}
                    size="sm"
                    data-testid="overviewInputTrigger"
                    mr={3}
                    onClick={() => {
                      /* istanbul ignore else */
                      if (enableVideoGallery) {
                        onOpenVideoSelect();
                      } else {
                        fileInputRef.current?.click();
                      }
                    }}
                  >
                    {t('createJobPosition:uploadBtn')}
                  </Button>
                  <Box display="none">
                    <input
                      type="file"
                      onChange={uploadTrigger}
                      ref={fileInputRef}
                      accept="video/mp4, video/webm, video/ogg, video/mov , video/quicktime"
                      data-testid="overviewFileInput"
                    />
                  </Box>

                  <Button
                    colorScheme="red"
                    size="sm"
                    disabled={_isEmpty(overviewVideoUrl)}
                    data-testid="removeaVideoBtn"
                    onClick={async () => {
                      /* istanbul ignore next */
                      if (enableVideoGallery) {
                        setTimeout(() => {
                          setOverviewVideoUrl('');
                          setOverviewThumbnailUrl('');
                          onAddVideoToPosition(undefined, undefined, undefined);
                        }, 500);
                      } else {
                        await handleRemoveOverviewVideo();
                      }
                    }}
                  >
                    {t('createJobPosition:removeBtn')}
                  </Button>
                </Flex>
              </Box>
            )}
          </Flex>
        </Box>
      </Box>

      <Modal isCentered isOpen={isOpen && !_isEmpty(overviewThumbnailUrl)} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box style={{ height: '85vh' }}>
              {
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video width="100%" style={{ height: '100%' }} controls>
                  <source src={overviewVideoUrl} type="video/mp4" />
                </video>
              }
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex style={{ width: '100%', justifyContent: 'flex-end' }}>
              <Button colorScheme="blue" onClick={onClose} backgroundColor={colors.blue[400]}>
                {t('createJobPosition:keepBtn')}
              </Button>
              <Button
                colorScheme="red"
                data-testid="closeModalBtnOverlayVideo"
                ml="12px"
                onClick={async () => {
                  onClose();
                  if (enableVideoGallery) {
                    setOverviewVideoUrl('');
                    setOverviewThumbnailUrl('');
                    setTimeout(() => {
                      onAddVideoToPosition(undefined, undefined, undefined);
                    }, 500);
                  } else {
                    await handleRemoveOverviewVideo();
                  }
                }}
              >
                {t('createJobPosition:deleteBtn')}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isCentered isOpen={isOpenVideoSelect} onClose={onCloseVideoSelect} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <VideoGalleryView onSetVideo={onSetVideo} addVideoToPosition />
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseVideoSelect}>
              {t('createJobPosition:videoGallery.close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
