import { css } from '@emotion/core';

export const SearchInputHeightCSS = css`
  height: 2.125rem;
`;

export const ReactCustomSelectStyles = css`
padding-left:0.6rem;
background: white;
&.selectWrapperBox{
    display:flex;
    width:calc(52% - 10px);
    border-radius: 5px;
    align-items:center;
    z-index:10
  }

  .react-select__control {
    height: 40px !important;
    background-color: #fff;
    border-color: #fff;
    border-radius: 6px;
    height: 3rem;
    width: 100%;

    &:hover {
      border-color: #fff;
     }


    &:focus, &:active, &.react-select__control--is-focused {
     border-color:#fff;
     outline: none;
     box-shadow: none;
    }

    input {
      height: 1rem !important;
      width: unset !important;
  }
`;
