import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Heading, Text, Alert, Link } from '@chakra-ui/core';
import certnIntegrationImg1 from '../../../../assets/img/certn_integration_ss_1.jpg';
import certnIntegrationImg2 from '../../../../assets/img/certn_integration_ss_2.jpg';
// import certnIntegrationImg3 from '../../../../assets/img/certn_integration_ss_3.jpg';
import certnIntegrationImg4 from '../../../../assets/img/certn_integration_ss_5.png';
import certnIntegrationImg5 from '../../../../assets/img/certn_integration_ss_5.png';
import certnIntegrationImg6 from '../../../../assets/img/certn_integration_ss_6.jpg';

export const CertnTutorial = (): JSX.Element => {
  const { t } = useTranslation('certn');

  return (
    <Box>
      <Box px={6} py={4}>
        <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
          {t('certnSetupLabel')}
        </Heading>
        <Text fontSize="xs" color="gray.500">
          {t('setupStep')}
        </Text>
      </Box>
      <Divider borderColor="#ECEFF1" />
      <Box p={6}>
        <Box mb={3}>
          <Heading as="h5" fontSize="sm" lineHeight={1}>
            {t('finalNote')}
          </Heading>
        </Box>

        <Alert d="block" textAlign="center" backgroundColor="#C8E6F3" borderRadius="3px" status="info" p={5} mb={10}>
          <Text>{t('linkToCertn')}</Text>
          <Link href="https://app.certn.co/welcome/signUp" target="_blank" color="blue.500">
            https://app.certn.co/welcome/signUp
          </Link>
        </Alert>

        <Box mb={10}>
          <Heading as="h5" fontSize="sm">
            {t('webHookTitle')}
          </Heading>
          <Text mb="15px" fontSize="sm">
            {`1. ${t('stepLoginCertn')}`}
          </Text>
          <Box mb={4}>
            <img src={certnIntegrationImg1} alt="webhook secret one" />
          </Box>
          <Text mb="5px" fontSize="sm">
            {`2. ${t('stepGenNewHook')}`}
          </Text>
          <Text mb="15px" fontSize="sm">
            {`3. ${t('stepLoginRecruiter')}`}
          </Text>
          <Box mb={4}>
            <img src={certnIntegrationImg2} alt="webhook secret two" />
          </Box>
          <Text mb="5px" fontSize="sm">
            {`4. ${t('stepPasteWebSecret')}`}
          </Text>
        </Box>

        <Box mb={10}>
          <Heading as="h5" fontSize="sm">
            {t('apiKeyTitle')}
          </Heading>
          <Text mb="15px" fontSize="sm">
            {`5. ${t('backToCertnApi')}`}
          </Text>
          {/* <Box mb={4}>
            <img src={certnIntegrationImg3} alt="webhook secret three" />
          </Box> */}
          <Text mb="5px" fontSize="sm">
            {`6. ${t('stepCopyAPI')}`}
          </Text>
          <Text mb="15px" fontSize="sm">
            {`7. ${t('stepPasteAPI')}`}
          </Text>
          <Box mb={4}>
            <img src={certnIntegrationImg4} alt="webhook secret four" />
          </Box>
          <Text mb="5px" fontSize="sm">
            {`8. ${t('stepSave')}`}
          </Text>
        </Box>

        <Box mb={10}>
          <Heading as="h5" fontSize="sm">
            {t('webURLTitle')}
          </Heading>
          <Text mb="5px" fontSize="sm">
            {`1. ${t('stepLoginCertnURl')}`}
          </Text>
          <Text mb="15px" fontSize="sm">
            {`2. ${t('stepCopyUrl')}`}
          </Text>
          <Box mb={4}>
            <img src={certnIntegrationImg5} alt="webhook secret five" />
          </Box>
          <Text mb="15px" fontSize="sm">
            {`3. ${t('stepLoginCertn')}`}
          </Text>
          <Box mb={4}>
            <img src={certnIntegrationImg6} alt="webhook secret six" />
          </Box>
          <Text mb="5px" fontSize="sm">
            {`4. ${t('stepPasteUrl')}`}
          </Text>
        </Box>

        <Box mb={10}>
          <Heading as="h5" fontSize="sm">
            {t('apiKeyTitle')}
          </Heading>
          <Text mb="15px" fontSize="sm">
            {`5. ${t('backToCertnApi')}`}
          </Text>
          <Text mb="5px" fontSize="sm">
            {`6. ${t('stepCopyAPI')}`}
          </Text>
          <Text mb="15px" fontSize="sm">
            {`7. ${t('stepPasteAPI')}`}
          </Text>
          <Box mb={4}>
            <img src={certnIntegrationImg4} alt="webhook secret four" />
          </Box>
          <Text mb="5px" fontSize="sm">
            {`8. ${t('stepSave')}`}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
