import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { Stack } from '@chakra-ui/core';
import InputPreview from './InputPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';

export default function AddressSchemaBuilder(props: SchemaBuilderProps) {
  return (
    <Stack>
      <LabelSchemaBuilder {...props} />
      <FormElementTextBuilder {...props} placeholder="Enter address text here..." />
      <InputPreview value="Address" />
    </Stack>
  );
}
