import { DEFAULT_VIDEO_CONSTRAINTS } from './constants';

export const getUserScreen = (): Promise<MediaStream> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
  return navigator.mediaDevices.getDisplayMedia({
    mediaSource: 'desktop',
    audio: false,
    video: DEFAULT_VIDEO_CONSTRAINTS,
  } as MediaStreamConstraints);
};
