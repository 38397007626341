/* eslint-disable react-hooks/rules-of-hooks */
// import _isEmpty from 'lodash/isEmpty';
// import { useEmployerPicture } from '../../../../../../firebase/storage/hooks/userEmployerPicture';
import { useSeekerPicture } from '../../../../useSeekerPicture';
import { MessagePresenter } from '../messagePresenter';
import { useSeekerContainerContext } from '../../../SeekerContainerContext';
import { useEmployerPicture } from '../../../../../../firebase/storage/hooks/useEmployerPicture';

export const useChatPicture = (message: MessagePresenter): string | undefined => {
  const { selectedCandidate } = useSeekerContainerContext();

  if (!message.showPicture) {
    return undefined;
  }

  if (message.type === 'seeker') {
    return useSeekerPicture(selectedCandidate?.seeker);
  }

  return useEmployerPicture(message.userId);
};
