import Video, { CreateLocalTrackOptions, LocalAudioTrack, LocalVideoTrack } from 'twilio-video';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import _isNil from 'lodash/isNil';
import { useDevices } from './useDevices';
import {
  DEFAULT_INTERVIEW_VIDEO_CONSTRAINTS,
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from '../../constants';
import { MeetTabParams, MessagingTabsParams } from '../../../../routes/constants';

export type LocalTrackInfo = {
  isLoadingLocalTracks: boolean;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  removeLocalAudioTrack: () => void;
  getLocalVideoTrack: () => Promise<LocalVideoTrack>;
  removeLocalVideoTrack: () => void;
  getAudioAndVideoTracks: () => void;
};

export const useLocalTracks = (): LocalTrackInfo => {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();

  const [isLoadingLocalTracks, setIsLoadingLocalTracks] = useState(false);

  const { audioInputDevices, hasAudioInputDevices, videoInputDevices, hasVideoInputDevices } = useDevices();

  const paramsData = useParams<MeetTabParams & MessagingTabsParams>();

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return Video.createLocalAudioTrack(options).then((nTrack) => {
      setAudioTrack(nTrack);
      return nTrack;
    });
  }, []);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const getVideoConstrains = useCallback(() => {
    if (!_isNil(paramsData.candidateId) && !_isNil(paramsData.tab)) {
      return DEFAULT_INTERVIEW_VIDEO_CONSTRAINTS;
    }
    return DEFAULT_VIDEO_CONSTRAINTS;
  }, [paramsData.candidateId, paramsData.tab]);

  const getLocalVideoTrack = useCallback(() => {
    const inputDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);
    const hasInputDevice = videoInputDevices.some(
      (device: MediaDeviceInfo) => inputDeviceId && device.deviceId === inputDeviceId,
    );

    const options: CreateLocalTrackOptions = {
      // eslint-disable-next-line @typescript-eslint/ban-types
      ...(getVideoConstrains() as {}),
      name: `camera-${Date.now()}`,
      ...(hasInputDevice && { deviceId: { exact: inputDeviceId } }),
    } as CreateLocalTrackOptions;

    return Video.createLocalVideoTrack(options).then((nTrack) => {
      setVideoTrack(nTrack);
      return nTrack;
    });
  }, [getVideoConstrains, videoInputDevices]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const getAudioAndVideoTracks = useCallback(() => {
    if (!hasAudioInputDevices) return Promise.resolve();
    // istanbul ignore next
    if (isLoadingLocalTracks || audioTrack || videoTrack) return Promise.resolve();
    // setIsLoadingLocalTracks(true);
    const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId,
    );

    const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    );

    const localTrackConstraints = {
      video: hasVideoInputDevices && {
        // eslint-disable-next-line @typescript-eslint/ban-types
        ...(getVideoConstrains() as {}),
        name: `camera-${Date.now()}`,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId! } }),
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      audio: hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId! } } : hasAudioInputDevices,
    };

    return Video.createLocalTracks(localTrackConstraints)
      .then((track) => {
        const nVideoTrack = track.find((t) => t.kind === 'video');
        const nAudioTrack = track.find((t) => t.kind === 'audio');

        // Global mock does not allow nullable track
        // istanbul ignore next
        if (nVideoTrack) {
          setVideoTrack(nVideoTrack as LocalVideoTrack);
        }

        // Global mock does not allow nullable track
        // istanbul ignore next
        if (nAudioTrack) {
          setAudioTrack(nAudioTrack as LocalAudioTrack);
        }
      })
      .finally(() => {
        setIsLoadingLocalTracks(false);
      });
  }, [
    hasAudioInputDevices,
    hasVideoInputDevices,
    isLoadingLocalTracks,
    audioTrack,
    videoTrack,
    audioInputDevices,
    videoInputDevices,
    getVideoConstrains,
  ]);

  const localTracks = [audioTrack, videoTrack].filter((track) => {
    return track !== undefined;
  }) as (LocalAudioTrack | LocalVideoTrack)[];

  return {
    isLoadingLocalTracks,
    localTracks,
    getLocalAudioTrack,
    removeLocalAudioTrack,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  };
};
