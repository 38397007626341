/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo } from 'react';
import { Button } from '@chakra-ui/core';
import { useMeetContext } from '../MeetContext';
import { useDevices } from './hooks/useDevices';
import { ReactComponent as _IconMicroPhone } from '../../../assets/img/icon-microphone.svg';
import { ReactComponent as _IconMicroPhoneOff } from '../../../assets/img/icon-mute.svg';
import { useLocalAudioToggleGreenRoom } from './hooks/useLocalAudioToggleGreenRoom';

const IconMicroPhone = memo(_IconMicroPhone);
const IconMicroPhoneOff = memo(_IconMicroPhoneOff);

type AudioToggleButtonGreenRoomProps = {
  isLoading: boolean;
};

export const AudioToggleButtonGreenRoom = ({ isLoading }: AudioToggleButtonGreenRoomProps): JSX.Element => {
  const { localTracks } = useMeetContext();
  const { isAudioEnabled, toggleAudio } = useLocalAudioToggleGreenRoom();
  const { hasAudioInputDevices } = useDevices();

  const hasAudioTracks = (): boolean => {
    return localTracks.some((t) => t.kind === 'audio');
  };

  return (
    <Button
      data-testid="ToggleAudioButton"
      onClick={toggleAudio}
      isDisabled={!hasAudioInputDevices || !hasAudioTracks() || isLoading}
      isLoading={isLoading}
      className={isAudioEnabled ? 'on' : 'off'}
      borderRadius="100%"
      height="45px"
      width="45px"
      p="5px"
    >
      {isAudioEnabled ? <IconMicroPhone data-testid="AudioEnabledIcon" /> : <IconMicroPhoneOff data-testid="AudioDisabledIcon" />}
    </Button>
  );
};
