/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Badge, Box, Button, Flex, Heading, Spacer, Stack, Text, useToast, useDisclosure } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '@chakra-ui/icons';
import ReactQuill from 'react-quill';
import { useStoreActions } from '../../../../../../models/hooks';
import { Candidate } from '../../../../../../firebase/firestore/documents/candidate';
import { NoteViewsEnum } from '../NotesViewsEnum';
import { PositionNote } from '../positionNote';
import { getColorForState } from '../../../../../../utils/positionUtils';
import { useCurrentUserProfile } from '../../../../../../app/hooks/useCurrentUserProfile';
import { DeleteNoteConfirmationModal } from '../DeleteNoteConfirmationModal';
import { deleteButtonStyle } from '../../../../../auth/login/loginForm/LoginForm.styles';

const MotionStack = motion.custom(Stack);
const childrenVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -200 },
    },
  },
  hidden: {
    y: -10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export type CreateNoteProps = {
  activeNoteIndex: number | undefined;
  setActiveView: (page: NoteViewsEnum) => void;
  candidate?: Candidate;
  activeNote: PositionNote;
  noteId: string | undefined;
  isNoteDeleteFeatureEnabled: boolean;
};

export const CreateNotes = ({
  isNoteDeleteFeatureEnabled,
  activeNoteIndex,
  candidate,
  activeNote,
  setActiveView,
  noteId,
}: CreateNoteProps): JSX.Element => {
  const { t } = useTranslation(['notes', 'candidateStatus']);
  const [noteContent, setNoteContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disabledButton, setButtonDisabled] = useState(true);
  const [canUpdateNoteContent, setCanUpdateNoteContent] = useState(false);

  const getCurrentNote = activeNote.getNoteById(noteId);

  const { saveNote, updateNote } = useStoreActions((actions) => actions.account);

  const toast = useToast();
  const { currentUserProfile } = useCurrentUserProfile();

  const recruiterId = currentUserProfile?.id;
  const filteredNote = activeNote.notes.filter((note) => note.recruiterIdInNote === recruiterId);

  useEffect(() => {
    /* eslint-disable */
    filteredNote.length > 0 && Array.isArray(filteredNote[0].note)
      ? filteredNote[0].note &&
        filteredNote[0].note.map((n) => {
          setCanUpdateNoteContent(true);
          return n.originalIndex === activeNoteIndex ? updateNoteContent(n.note) : '';
        })
      : filteredNote.length > 0 && filteredNote[0].note && updateNoteContent(filteredNote[0].note as any);
    /* eslint-disable */
  }, [activeNote, activeNoteIndex]);

  useEffect(() => {
    if (noteContent.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [noteContent]);

  /* istanbul ignore next */
  const updateNoteContent = useCallback(
    (value: string) => {
      setNoteContent(value);
    },
    [setNoteContent, canUpdateNoteContent],
  );

  const {
    isOpen: isDeleteNoteConfimationModalOpen,
    onOpen: onDeleteNoteConfirmationModalOpen,
    onClose: onDeleteNoteConfirmationModalClose,
  } = useDisclosure();

  const openDeleteConfirmationModal = () => {
    if (!candidate) return;
    onDeleteNoteConfirmationModalOpen();
  };

  const submitNote = async () => {
    if (!candidate) return;
    try {
      setIsLoading(true);
      if (activeNoteIndex! > -1) {
        await updateNote({
          accountId: candidate.account,
          positionId: activeNote.positionId,
          seekerId: candidate.seeker,
          noteIndex: activeNoteIndex!,
          note: noteContent,
        });
      } else {
        await saveNote({
          accountId: candidate.account,
          positionId: activeNote.positionId,
          seekerId: candidate.seeker,
          note: noteContent,
        });
      }
    } finally {
      setIsLoading(false);
      setActiveView(NoteViewsEnum.NOTE_LIST);
      toast({
        title: t('noteSuccessMsg.title'),
        description: t('noteSuccessMsg.description'),
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Fragment>
      <DeleteNoteConfirmationModal
        isOpen={isDeleteNoteConfimationModalOpen}
        onClose={onDeleteNoteConfirmationModalClose}
        setActiveView={setActiveView}
        accountId={candidate?.account}
        positionId={activeNote.positionId}
        seekerId={candidate?.seeker}
        noteIndex={activeNoteIndex}
      />
      <MotionStack variants={childrenVariants} spacing={4} data-testid="CreateNotes" padding={{ base: 4, md: 8 }}>
        <Box p={4} borderWidth="1px" borderRadius="sm" className="note-block" data-testid="active-note">
          <Flex className="note-header">
            <Box>
              <Heading as="h3" fontSize="lg" size="md" className="position">
                {activeNote.positionName}
              </Heading>
              <Text color="black" fontSize="sm" className="location">
                {activeNote.location}
              </Text>
            </Box>
            <Spacer />
            <Flex>
              <Box color="blue.400" className="date-box note-header-box">
                <Heading className="title" as="h4" fontSize="sm">
                  {t('header.date')}
                </Heading>
                <Flex align="center" className="date">
                  <Text fontSize="xl" className="lg">
                    {activeNote.getDate()}
                  </Text>
                  <Box>
                    <Text fontSize="sm" fontWeight="bold">
                      {activeNote.getMonth()}
                    </Text>
                    <Text fontSize="sm">{activeNote.getYear()}</Text>
                  </Box>
                </Flex>
              </Box>
              <Box color="blue.400" className="status-box note-header-box">
                <Heading className="title" as="h4" fontSize="sm">
                  {t('header.status')}
                </Heading>
                <Badge colorScheme={getColorForState(activeNote?.status).color}>
                  {t(`candidateStatus:${activeNote.getCandidateStatus()}`)}
                </Badge>
              </Box>
            </Flex>
          </Flex>
          <Flex className="note-body" flexWrap="wrap">
            <Box w="100%" mb="5">
              <ReactQuill data-testid="quill-editor" value={noteContent} onChange={updateNoteContent} />
            </Box>
            <Box w="100%" display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={4}>
                <Button
                  isLoading={isLoading}
                  disabled={disabledButton || isLoading}
                  colorScheme="blue"
                  size="md"
                  mr="4"
                  onClick={submitNote}
                  data-testid="save-note-button"
                >
                  {t('createNotes.saveNote')}
                </Button>
                <Button
                  onClick={() => setActiveView(NoteViewsEnum.NOTE_LIST)}
                  background="gray.400"
                  color="white"
                  size="md"
                  data-testid="cancel-note-button"
                >
                  {t('createNotes.cancel')}
                </Button>
              </Stack>
              {isNoteDeleteFeatureEnabled && activeNoteIndex! > -1 && getCurrentNote?.isEditable && (
                <Button
                  onClick={openDeleteConfirmationModal}
                  leftIcon={<DeleteIcon style={{ marginBottom: '4px' }} />}
                  css={deleteButtonStyle}
                  data-testid="delete-button"
                  variant="solid"
                >
                  {t('createNotes.deleteNote')}
                </Button>
              )}
            </Box>
          </Flex>
        </Box>
      </MotionStack>
    </Fragment>
  );
};
