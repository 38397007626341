/* istanbul ignore file */
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Progress,
  Text,
  useToast,
  VStack,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ModalCloseButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
  CircularProgress,
  ModalHeader,
} from '@chakra-ui/core';
import React, { useRef, useState } from 'react';
import { BiCloudUpload } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { AiFillPlayCircle } from 'react-icons/ai';
import _isEmpty from 'lodash/isEmpty';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { getStorageFileDownloadURL } from '../../../firebase/firestore/storage';
import { deleteDocumentById, setDocumentById } from '../../../firebase/firestore/firestore';
import { Collections } from '../../../firebase/firestore/collections';
import { VideoGallery } from '../../../firebase/firestore/documents/videoGallery';
import useVideoGalleryByAccountId from '../../../app/hooks/useVideoGalleryByAccountId';
import colors from '../../../styles/colors';

interface VideoGalleryViewProps {
  addVideoToPosition: boolean;
  onSetVideo: (videoGallery: VideoGallery | undefined) => void;
}

export const VideoGalleryView = ({ addVideoToPosition, onSetVideo }: VideoGalleryViewProps): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
  const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const toast = useToast();
  const { accounts, user } = useStoreState((s) => s.app);
  const [videoGallery, setVideoGallery] = useState<VideoGallery | undefined>(undefined);
  const [overviewVideoUrl, setOverviewVideoUrl] = useState<VideoGallery | undefined>(undefined);

  const videoLength = useRef(0);

  const allowedVideoTypes = ['video/mp4', 'video/webm', 'video/ogg', 'video/mov', 'video/quicktime'];
  const {
    uploadOverviewVideo,
    uploadOverviewThumbnail,
    deleteVideoFromStorage,
    deleteVideoThumbnailFromStorage,
  } = useStoreActions((actions) => actions.videoManagement);
  const [loading, setLoading] = useState(false);

  const generateVideoThumbnail = (file: File): Promise<File> => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      video.setAttribute('data-testid', 'videoPlayer');

      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = async () => {
        videoLength.current = video.duration / 60;
        video.currentTime = video.duration > 2 ? /* istanbul ignore next */ 2 : 1;
        await video.play();
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        const dataUrl = canvas.toDataURL('image/jpeg');
        const blob = await fetch(dataUrl).then((it) => it.blob());

        const thumbnail = new File([blob], 'thumbnail.jpeg', { type: 'image/jpeg', lastModified: moment.now() });
        resolve(thumbnail);
      };
    });
  };

  const handleOverviewVideoUpload = async ({
    accountId,
    userId,
    fileName,
    overviewVideo,
    overviewThumbnail,
    folderName,
  }: {
    accountId: string;
    userId: string;
    fileName: string;
    overviewVideo: File;
    overviewThumbnail: File;
    folderName: string;
  }) => {
    try {
      setVideoGallery({
        videoUrl: URL.createObjectURL(overviewVideo),
        thumbnailUrl: URL.createObjectURL(overviewThumbnail),
        account: accountId,
        user: userId,
        folderName,
        fileName,
        lastUpdatedOn: new Date(),
      });
      await uploadOverviewVideo({ accountId, overviewVideo, folderName });

      const videoUrl = await getStorageFileDownloadURL(`/video/${accountId}/${folderName}/overview.mp4`);
      await uploadOverviewThumbnail({ accountId, overviewThumbnail, folderName });
      const thumbnailUrl = await getStorageFileDownloadURL(`/video/${accountId}/${folderName}/thumbnail.jpeg`);
      setVideoGallery(undefined);
      const payload: VideoGallery = {
        videoUrl,
        thumbnailUrl,
        account: accountId,
        user: userId,
        folderName,
        fileName,
        lastUpdatedOn: new Date(),
      };
      await setDocumentById(Collections.VIDEO_GALLERY, payload.id ?? undefined, payload, true);
    } catch (e) {
      console.log(e);
    }
  };

  const uploadTrigger = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // istanbul ignore else
    if (event.target && event.target.files && accounts && user) {
      const file = event.target.files?.[0];
      if (file && !allowedVideoTypes.includes(file.type)) {
        toast({
          title: t('createJobPosition:videoImageFormatFail'),
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }
      const maxSize = 50 * 1024 * 1024;
      if (!file) return;
      if (file.size > maxSize) {
        toast({
          title: t('createJobPosition:video.maxSizeError', { size: 50 }),
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }
      // if (videoLength.current > 1) {
      //   toast({
      //     title: t('createJobPosition:video.maxLengthError'),
      //     status: 'error',
      //     duration: 2000,
      //     isClosable: true,
      //   });
      //   if (fileInputRef.current) {
      //     fileInputRef.current.value = '';
      //   }
      //   return;
      // }

      const thumbnail = await generateVideoThumbnail(file);

      await handleOverviewVideoUpload({
        accountId: accounts?.id || '',
        userId: user?.id || '',
        fileName: file.name,
        overviewVideo: file,
        overviewThumbnail: thumbnail,
        folderName: `${file.name.replace(/\.[^/.]+$/, '')}_${new Date().getTime()}`,
      });
    }
  };

  const videoGalleries = useVideoGalleryByAccountId(accounts?.id ?? '');

  const handleDelete = async () => {
    try {
      if (overviewVideoUrl) {
        setLoading(true);
        await deleteVideoFromStorage({
          accountId: overviewVideoUrl?.account,
          folderName: overviewVideoUrl?.folderName,
        });
        await deleteVideoThumbnailFromStorage({
          accountId: overviewVideoUrl?.account,
          folderName: overviewVideoUrl?.folderName,
        });
        await deleteDocumentById(Collections.VIDEO_GALLERY, overviewVideoUrl?.id ?? '');
        onClose();
        onCloseAlert();
        setOverviewVideoUrl(undefined);
        setLoading(false);
        toast({
          title: t('createJobPosition:videoGallery.videoDeleteSuccess'),
          status: 'info',
          duration: 1000,
          isClosable: true,
        });
      }
    } catch (e) {
      setLoading(false);
      toast({
        title: t('createJobPosition:videoGallery.videoDeleteErrorMessage'),
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack align="left" backgroundColor="white" p={4} minHeight="644px">
      <Box display="none">
        <input
          type="file"
          onChange={uploadTrigger}
          ref={fileInputRef}
          accept="video/mp4, video/webm, video/ogg, video/mov , video/quicktime"
          data-testid="overviewFileInput"
        />
      </Box>
      <Flex justifyContent="space-between" borderBottom="0.5px solid #E9EDEF">
        <Box py={2} px={4} mb={2}>
          <Heading as="h4" fontSize="lg" lineHeight="1.3">
            {t('createJobPosition:videoGallery.videoGallery')}
          </Heading>
          <Text m="3px 0" color="gray.500" fontSize="12px">
            {t('createJobPosition:videoGallery.videoGalleryInfo')}
          </Text>
        </Box>
        <Button
          leftIcon={<BiCloudUpload />}
          colorScheme="blue"
          data-testid="overviewInputTrigger"
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          {t('createJobPosition:videoGallery.addVideo')}
        </Button>
      </Flex>
      <Grid templateColumns="repeat(auto-fill, 260px)" gridRowGap="wrap" gridGap="12px">
        {videoGallery && (
          <GridItem boxShadow="0px 2px 2px 0px rgba(0,0,0,0.1)" background="#ffffff" cursor="pointer">
            <Progress size="xs" isIndeterminate />
            <Flex justifyContent="center" alignItems="center">
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video src={videoGallery.videoUrl} controls style={{ height: '160px', width: '100%' }} />
            </Flex>
            <Divider />
            <Text fontWeight="500" fontSize="16" p="6px">
              {videoGallery.fileName}
            </Text>
          </GridItem>
        )}
        {videoGalleries.map((e) => (
          <GridItem
            key={e.id}
            w="100%"
            boxShadow="0px 2px 2px 0px rgba(0,0,0,0.1)"
            width="260px"
            onClick={() => {
              if (addVideoToPosition) {
                onSetVideo(e);
              } else {
                setOverviewVideoUrl(e);
                onOpen();
              }
            }}
            cursor="pointer"
          >
            <Flex justifyContent="center" alignItems="center" height="160px" position="relative">
              <Image src={e.thumbnailUrl} objectFit="cover" height="160px" fallbackSrc="https://via.placeholder.com/150" />
              <IconButton aria-label="Search database" icon={<AiFillPlayCircle />} position="absolute" isRound />
            </Flex>
            <Divider />
            <Text fontWeight="500" fontSize="16" p="6px">
              {e.fileName}
            </Text>
          </GridItem>
        ))}
      </Grid>
      <Modal isCentered isOpen={isOpen && !_isEmpty(overviewVideoUrl)} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video width="100%" style={{ maxHeight: '80vh' }} controls>
                  <source src={overviewVideoUrl?.videoUrl} type="video/mp4" />
                </video>
              }
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex style={{ width: '100%', justifyContent: 'flex-end' }}>
              <Button
                colorScheme="blue"
                onClick={() => {
                  onClose();
                  setOverviewVideoUrl(undefined);
                }}
                mr="10px"
                backgroundColor={colors.blue[400]}
              >
                {t('createJobPosition:keepBtn')}
              </Button>
              <Button colorScheme="red" data-testid="closeModalBtnOverlayVideo" onClick={() => onOpenAlert()}>
                {t('createJobPosition:deleteBtn')}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={isOpenAlert} onClose={onClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          {loading ? (
            <AlertDialogContent justifyContent="center" display="flex" p="12px">
              <CircularProgress isIndeterminate />
            </AlertDialogContent>
          ) : (
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {t('createJobPosition:videoGallery.deleteVideo')}
              </AlertDialogHeader>

              <AlertDialogBody>{t('createJobPosition:videoGallery.videoDeleteWarning')}</AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onCloseAlert}>{t('createJobPosition:videoGallery.cancel')}</Button>
                <Button
                  colorScheme="red"
                  onClick={async () => {
                    await handleDelete();
                  }}
                  ml={3}
                >
                  {t('createJobPosition:videoGallery.delete')}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          )}
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};
