import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';
import { Collections } from '../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Query } from '../../firebase/firestore/query/query';
import { Lookups } from '../../firebase/firestore/documents/lookups';

const useLookupsByType = (type: string): Lookups[] => {
  const lookups = useQuerySnapshot<Lookups>(Collections.LOOKUPS, [Query.field('type').equals(type)]);
  if (_isNil(lookups)) return [];
  return _sortBy(lookups, 'order');
};

export default useLookupsByType;
