// eslint-disable-next-line import/prefer-default-export
export enum NotificationTypes {
  CONTACT_REQUEST = 'contact_request',
  APPOINTMENT_REQUEST = 'appointment_request',
  APPOINTMENT_CHANGE = 'appointment_change',
  APPOINTMENT_REMINDER = 'appointment_reminder',
  APPOINTMENT_CANCELLED = 'appointment_cancelled',
  SEEKER_VIDEO_INTERVIEW_REMINDER = 'seekerVideoInterviewReminder',
  EMPLOYERS_VIDEO_INTERVIEW_REMINDER = 'employerVideoInterviewReminder',
  MESSAGE = 'message',
  UNANSWERED_QUESTIONS_REMINDER = 'unanswered_questions_reminder',
  GROUP_EVENT_INVITATION = 'groupEventInvitation',
  GROUP_EVENT_DELETED = 'groupEventDeleted',
  GROUP_EVENT_REMINDER = 'groupEventReminder',
  POSITION_INACTIVE_REMINDER_NOTIFICATION = 'positionInactiveReminder',
  INACTIVE_POSITION_CLOSED_NOTIFICATION = 'inactivePositionClosed',
  BACKGROUND_CHECK_INITIATED = 'backgroundCheckInitiated',
  BACKGROUND_CHECKED = 'backgroundChecked',
  BACKGROUND_CHECKED_REMINDER = 'backgroundCheckReminder',
  RECRUITER_LOW_AVAILABILITY = 'recruiterLowAvailability',
  RECRUITER_LOW_AVAILABILITY_FOR_ADMIN = 'recruiterLowAvailabilityForAdmin',
  POSITION_OPENINGS_FILLED = 'position_openings_filled',
  POSITINON_CLOSING_REMINDER = 'position_closing_reminder',
  POSITION_CLOSED = 'position_closed',
  POSITION_INACTIVE_REMINDER = 'position_inactive_reminder',
  INACTIVE_POSITION_CLOSED = 'inactive_position_closed',
  BUSINESS_ALREADY_EXISTS = 'business.alreadyExists',
  SEEKER_HIRE_DETAILS_REQUESTED = 'seekerHireDetailsRequested',
  SEEKER_HIRE_DETAILS_HELP_REQUESTED = 'seekerHireDetailsHelpRequested',
  SEEKER_HIRE_DETAILS_REQUEST_DECLINED = 'seekerHireDetailsRequestDeclined',
  SEEKER_HIRE_DETAILS_RESUBMISSION_REQUESTED = 'seekerHireDetailsResubmissionRequested',
  SEEKER_HIRE_DETAILS_APPROVED = 'seekerHireDetailsApproved',
  SEEKER_HIRE_DETAILS_REJECTED = 'seekerHireDetailsRejected',
  SEEKER_HIRE_DETAILS_FILLED = 'seekerHireDetailsFilled',
  SEEKER_HIRE_DETAILS_REMINDER = 'seekerHireDetailsReminder',
  MODERN_HIRE_ASSESSMENT_STARTED = 'modernHireStarted',
  MODERN_HIRE_ASSESSMENT_IN_PROGRESS = 'modernHireInProgress',
  MODERN_HIRE_ASSESSMENT_PASSED = 'modernHirePassed',
  MODERN_HIRE_ASSESSMENT_FAILED = 'modernHireFailed',
  REQUISITION_ID_ADDED_NOTIFICATION = 'requisitionIdAddedNotification',
  REQUISITION_POSITION_OPENED = 'positionOpened',
  REQUISITION_UNAVAILABLE = 'requisitionUnavailable',
  NOTIFICATION_AUDIO_LANGUAGE_EVALUATION = 'audioLanguageEvaluation',
  EMPLOYERS_VIDEO_INTERVIEW_REMINDER_NOTIFICATION = 'employers_video_interview_reminder',
  RECRUITER_LOW_AVAILABILITY_NOTIFICATION = 'recruiter_low_availability',
}
