import React, { useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/table';
import { Box, Text, Button, ScaleFade, Alert, AlertIcon, useDisclosure } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, Column } from 'react-table';
import _isEmpty from 'lodash/isEmpty';
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti';
import { mainTable, tableHeading, recruiterNameCell, sortIconWrapper } from './RecruiterInfoTableStyle';
import { NoteList } from '../../../../messaging/seeker/candidate/notes/listNotes/NoteList';
import useSeekerNotes from '../../../../messaging/seeker/candidate/notes/useSeekerNotes';
import { ViewNotesModal } from './ViewNotesModal';
import { NoteListRenderedByEnum } from '../../../../messaging/seeker/candidate/notes/NotesViewsEnum';

export type RecruiterCandidateTableViewProps<Data extends Record<string, unknown>> = {
  data: Data[];
  columns: Column<Data>[];
  recruiterId?: string;
};

export default function RecruitersCandidateTableView<Data extends Record<string, unknown>>({
  data,
  columns,
  recruiterId = '',
}: RecruiterCandidateTableViewProps<Data>) {
  const [currentPositionId, setCurrentPositionId] = useState('');
  const [currentSeekerId, setCurrentSeekerId] = useState('');
  const seekerNotes = useSeekerNotes({
    recruiterId,
    seekerId: currentSeekerId,
    positionId: currentPositionId,
  });
  const { t } = useTranslation('candidateStatus');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'candidateName',
            desc: false,
          },
        ],
      },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn: string = row1.values[columnName];
          const rowTwoColumn: string = row2.values[columnName];

          return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase() ? 1 : -1;
        },
      },
    },
    useSortBy,
  );

  const onViewNoteClick = (currentCandidateId: string) => {
    const candidateId = String(currentCandidateId).split('_');
    setCurrentPositionId(candidateId[0]);
    setCurrentSeekerId(candidateId[1]);
    onOpen();
  };

  return (
    <ScaleFade initialScale={0.7} in unmountOnExit={false}>
      {seekerNotes && (
        <ViewNotesModal
          isOpen={isOpen}
          onClose={onClose}
          notesHeader={t('recruiterManagement:recruitersCandidateTable.viewNotesModal.noteHeader')}
          noteActionButtonText={t('recruiterManagement:recruitersCandidateTable.viewNotesModal.closeNote')}
        >
          <NoteList
            notes={seekerNotes.presenter.positionNotes}
            state={seekerNotes.state}
            setActiveView={/* istanbul ignore next */ () => ''}
            isNoteDeleteFeatureEnabled={false}
            noteWrapperStyle={{ height: 'max-content', overflowY: 'clip', paddingBottom: 0, paddingTop: '0' }}
            noteListRenderedBy={NoteListRenderedByEnum.RECRUITERS_CANDIDATE_TABLE_VIEW}
          />
        </ViewNotesModal>
      )}
      {data.length ? (
        <Table {...getTableProps()} css={mainTable} className="mainTable" key="mainTable">
          <Thead css={tableHeading} className="tableHeading" pl="4">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()} textAlign="left" key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    isNumeric={column.isNumeric}
                    pr="4"
                    className="fixBorder"
                    style={{ borderColor: '#d0e9f4' }}
                    key={column.getHeaderProps().key}
                  >
                    <div style={{ display: 'flex', maxWidth: '100%' }}>
                      <Text display="inline">{column.render('Header')}</Text>
                      {column.id !== 'notes' && (
                        <Box pl="4" display="inline" css={sortIconWrapper} className="sortIconWrapper">
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {column.isSorted ? (
                            // istanbul ignore next
                            column.isSortedDesc ? (
                              <TiArrowSortedUp aria-label="sorted descending" color="#BCC2CE" style={{ display: 'inline' }} />
                            ) : (
                              <TiArrowSortedDown aria-label="sorted ascending" color="#BCC2CE" style={{ display: 'inline' }} />
                            )
                          ) : (
                            <TiArrowUnsorted color="#BCC2CE" style={{ display: 'inline' }} data-testid="sortedAscendingBtn" />
                          )}
                        </Box>
                      )}
                    </div>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} style={{ height: '40px' }} key={row.getRowProps().key}>
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      key={cell.getCellProps().key}
                      className={cell.column.id === 'candidateName' ? 'nameFixedWidth fixBorder' : 'fixBorder'}
                    >
                      {cell.column.id === 'candidateName' && (
                        <Text css={recruiterNameCell} className="recruiterNameCell name" data-testid="nameCell">
                          {!_isEmpty(cell.value) && cell.render('Cell')}
                        </Text>
                      )}

                      {cell.column.id === 'businessPosition' && (
                        <Text css={recruiterNameCell} className="recruiterNameCell">
                          {!_isEmpty(cell.value) && cell.render('Cell')}
                        </Text>
                      )}

                      {cell.column.id === 'businessLocation' && (
                        <Text css={recruiterNameCell} className="recruiterNameCell">
                          {!_isEmpty(cell.value) && cell.render('Cell')}
                        </Text>
                      )}

                      {cell.column.id === 'notes' && (
                        <Box display="flex" justifyContent="center" pr="4">
                          <Button
                            zIndex={1}
                            background="#ECEFF1"
                            borderRadius={3}
                            fontWeight={400}
                            height={6}
                            data-testid="viewNoteButton"
                            onClick={() => onViewNoteClick(cell.row.original.candidateId as string)}
                          >
                            <Text
                              css={recruiterNameCell}
                              className="recruiterNameCell"
                              background="#ECEFF1"
                              fontSize="12px"
                              color="gray.500"
                            >
                              {t('recruiterManagement:recruitersCandidateTable.tableHeader.viewBtn')}
                            </Text>
                          </Button>
                        </Box>
                      )}
                      {cell.column.id === 'status' && (
                        <Text css={recruiterNameCell} className="recruiterNameCell">
                          {!_isEmpty(cell.value) && t(cell.value)}
                        </Text>
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <Alert status="info" backgroundColor="gray.400" color="white">
          <AlertIcon color="white" />
          {t('notification:noDataFound')}
        </Alert>
      )}
    </ScaleFade>
  );
}
