export enum CalendarSidebarTabTypeEnum {
  RECRUITERS,
  ALL_POSITIONS,
  FOLLOWED_POSITIONS,
}

export enum RecruiterCalendarSidebarTabTypeEnum {
  SCHEDULE,
  MY_POSITION,
}
