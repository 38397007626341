import { Box, Text, Button, Center, Spinner } from '@chakra-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type NotAuthorizedViewProps = {
  isAuthorizationStateLoaded: boolean;
};

export const NotAuthorizedView = ({ isAuthorizationStateLoaded }: NotAuthorizedViewProps): JSX.Element => {
  const { t } = useTranslation('administration');
  return (
    <Box
      style={{ display: 'flex', alignItems: 'center', height: '90vh', justifyContent: 'center' }}
      data-testid="notAuthorizedComponent"
    >
      {isAuthorizationStateLoaded ? (
        <Box>
          <Center>
            <Text fontSize="20px" color="blue">
              {t('notAuthorized.notAuthorizedMsg')}
            </Text>
          </Center>
          <Center>
            <Link to="/messaging">
              <Button colorScheme="blue" style={{ marginTop: 12 }}>
                {t('notAuthorized.goToHomeBtn')}
              </Button>
            </Link>
          </Center>
        </Box>
      ) : (
        <Center
          style={{ display: 'flex', flexDirection: 'column' }}
          p={10}
          background="white"
          mb={8}
          data-testid="authorisationLoadingSpinner"
        >
          <Spinner color="blue.500" size="lg" mb="6" />
        </Center>
      )}
    </Box>
  );
};
