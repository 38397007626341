/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { TiDelete } from 'react-icons/ti';
import { AiFillMinusCircle } from 'react-icons/ai';
import { NotificationTypes } from '../../../firebase/firestore/documents/notificationTypes';
import { badgeStatusCSS } from '../Header.styles';

export type NotificationStatusIconProps = {
  notificationType: string;
};

export const NotificationStatusIcon = ({ notificationType }: NotificationStatusIconProps): JSX.Element => {
  const { t } = useTranslation('notification');

  const classNameHandler = () => {
    if (notificationType === NotificationTypes.POSITION_INACTIVE_REMINDER_NOTIFICATION) {
      return 'inactive';
    }
    return 'declined';
  };

  const iconHandler = () => {
    if (notificationType === NotificationTypes.POSITION_INACTIVE_REMINDER_NOTIFICATION) {
      return (
        <Flex alignItems="center" gridGap="8px" justifyContent="center">
          <AiFillMinusCircle />
          {t('statusIcon.inactive')}
        </Flex>
      );
    }
    return (
      <Flex alignItems="center" gridGap="8px" justifyContent="center">
        <TiDelete />
        {t('statusIcon.expired')}
      </Flex>
    );
  };
  return (
    <Box
      css={badgeStatusCSS}
      fontSize="xs"
      px={2}
      mx={2}
      textTransform="capitalize"
      lineHeight="1.8"
      className={classNameHandler()}
    >
      {iconHandler()}
    </Box>
  );
};
