import React, { useEffect, useState, useCallback } from 'react';
import { Box, Tab, TabList, TabPanel, Text, TabPanels, Tabs, Stack, Button, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import _isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { AvailabilityType, RecruiterListResponse, SingleRecruiterType } from './RecruiterType';
import { PATH_RECRUITER_CALENDAR } from '../../../routes/constants';
import RecruiterSearchView from './recruiterSearch/RecruiterSearchView';
import { RecruiterDashboardTabItems, useRecruiterManagementContext } from './RecruiterManagementContext';
import RecruiterDashboardView from './recruiterDashboard/RecruiterDashboardView';
import RecruiterProfileView from './recruiterProfile/RecruiterProfileView';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { reactDatePickerCSS } from '../../calendar/scheduleSidebar/ReactDatePicker.Style';
import i18n from '../../../locales/i18n';

const defaultRecruiterList: SingleRecruiterType[] = [];

export type RecruiterManagementViewProps = {
  onSetActiveTab: () => void;
};

export const RecruiterManagementView = ({ onSetActiveTab }: RecruiterManagementViewProps): JSX.Element => {
  const isRecruiterSearchFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.DASHBOARD_ENABLE_RECRUITER_SEARCH];
  });
  const { t } = useTranslation('recruiterManagement');
  const {
    selectedTab,
    setSelectedTab,
    setSelectedRecruiter,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = useRecruiterManagementContext();
  const [isRecruiterListLoading, setIsRecruiterListLoading] = useState(false);

  const renderLowToHighString = useCallback((percentage: number): string => {
    switch (true) {
      case percentage === 0:
        return AvailabilityType.NONE;
      case percentage >= 100:
        return AvailabilityType.HIGH;
      case percentage >= 75 && percentage < 100:
        return AvailabilityType.MEDIUM;
      case percentage >= 0 && percentage < 75:
        return AvailabilityType.LOW;
      default:
        return AvailabilityType.LOW;
    }
  }, []);

  const { getRecruiterList } = useStoreActions((actions) => actions.recruiterManagement);
  const [recruiterListData, setRecruiterListData] = useState<SingleRecruiterType[]>(defaultRecruiterList);

  const accountId: string | undefined = useStoreState((s) => s.app?.user?.account);
  const isCalendarEnabled = useStoreState((s) => s.app.accounts?.calendarEnabled);

  const createDataForTable = useCallback(
    (recruitersData: RecruiterListResponse) => {
      const recruiterList: SingleRecruiterType[] = [];
      recruitersData?.recruitersData?.map((recruiterData) => {
        const singleRecruiterData: SingleRecruiterType = {};
        singleRecruiterData.name = `${recruiterData.firstName} ${recruiterData.lastName}`;
        singleRecruiterData.availability = !_isNil(recruiterData.availabilityPercent.availabilityPercentage)
          ? renderLowToHighString(Number(recruiterData.availabilityPercent.availabilityPercentage))
          : AvailabilityType.NONE;
        singleRecruiterData.totalAvailabilityHours =
          (recruiterData.availabilityPercent.availableDuration &&
            Math.floor(recruiterData.availabilityPercent.availableDuration)) ||
          0;
        singleRecruiterData.requiredDuration =
          (recruiterData.availabilityPercent.requiredDuration &&
            Math.floor(recruiterData.availabilityPercent.requiredDuration)) ||
          0;
        singleRecruiterData.noOfHirings = `${recruiterData.hireCount || 0}`;
        singleRecruiterData.successfulInterviews = recruiterData.successfulInterviews || 0;
        singleRecruiterData.interviewCancellations = recruiterData.cancelledInterviews || 0;
        singleRecruiterData.candidateNoShow = recruiterData.candidateNoShow || 0;
        singleRecruiterData.recruiterNoShow = recruiterData.recruiterNoShow || 0;
        singleRecruiterData.id = recruiterData.id;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return recruiterList.push(singleRecruiterData);
      });
      setRecruiterListData(recruiterList);
    },
    [renderLowToHighString],
  );

  // eslint-disable-next-line no-shadow
  const onGetRecruiterList: (userAccountId: string) => void = useCallback(
    async (userAccountId) => {
      setIsRecruiterListLoading(true);
      const data: RecruiterListResponse = await getRecruiterList({ params: { accountId: userAccountId, startDate, endDate } });
      /* istanbul ignore else */
      if (data) {
        createDataForTable(data);
      }
      setIsRecruiterListLoading(false);
    },
    [getRecruiterList, createDataForTable, startDate, endDate],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    accountId && onGetRecruiterList(accountId);
  }, [accountId, onGetRecruiterList]);

  const onDashboardTabClick = () => {
    setSelectedTab(RecruiterDashboardTabItems.RecruiterDashboard);
    setSelectedRecruiter(null);
  };

  return (
    <Box minHeight="644px">
      <Tabs colorScheme="blue">
        <TabList borderWidth={0} px={2} mb={6} borderRadius={5} backgroundColor="white">
          <Tab
            onClick={() => onDashboardTabClick()}
            data-testid="dahaboardTab"
            py={3}
            fontSize="sm"
            _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
            _focus={{ boxShadow: 'none' }}
          >
            {t('tabHeader.dashboard')}
          </Tab>
          {isCalendarEnabled && (
            <Tab
              p={0}
              fontSize="sm"
              _selected={{ fontWeight: '700', borderColor: 'blue.default', color: 'blue.default' }}
              _focus={{ boxShadow: 'none' }}
            >
              <Link style={{ padding: '1rem .75rem' }} to={PATH_RECRUITER_CALENDAR}>
                {t('tabHeader.gotoAvailability')}
              </Link>
            </Tab>
          )}
        </TabList>
        <TabPanels borderRadius={5}>
          <TabPanel p={0}>
            <Stack spacing={0}>
              {isRecruiterSearchFeatureEnabled && (
                <Stack direction="row" mb={4} alignItems="center" justifyContent="space-between">
                  <RecruiterSearchView
                    recruiterListData={recruiterListData}
                    placeholder={t('dashboardSearchInputs.searchRecruiterPlaceholder')}
                    isRecruiterListLoading={isRecruiterListLoading}
                  />
                  <Flex>
                    <Box css={reactDatePickerCSS}>
                      <Flex alignItems="center" fontSize="sm">
                        <Text fontSize="sm" fontWeight="bold" mx={2}>
                          From:
                        </Text>
                        <DatePicker
                          locale={i18n.language}
                          className="react-datepicker-input bold"
                          selected={startDate}
                          onChange={/* istanbul ignore next */ (date) => setStartDate(date as Date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={moment(endDate).subtract(1, 'days').toDate()}
                          dateFormat="P"
                        />
                        <Text fontWeight="bold" mx={2}>
                          To:
                        </Text>
                        <DatePicker
                          locale={i18n.language}
                          className="react-datepicker-input bold"
                          selected={endDate}
                          onChange={/* istanbul ignore next */ (date) => setEndDate(date as Date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={moment(startDate).add(1, 'days').toDate()}
                          maxDate={moment().toDate()}
                          dateFormat="P"
                        />
                      </Flex>
                    </Box>

                    {isCalendarEnabled && (
                      <Link to={PATH_RECRUITER_CALENDAR}>
                        <Button colorScheme="blue" ml={2}>
                          <Text fontWeight={700} fontSize="xs" px={2} py={1}>
                            {t('tabHeader.gotoAvailability')}
                          </Text>
                        </Button>
                      </Link>
                    )}

                    {selectedTab === RecruiterDashboardTabItems.RecruiterProfile && (
                      <Button
                        onClick={() => onDashboardTabClick()}
                        data-testid="profileBackButton"
                        variant="ghost"
                        colorScheme="blue"
                        fontSize="sm"
                        color="blue.500"
                        leftIcon={<ArrowBackIcon />}
                      >
                        {t('administration:calendar.backBtnText')}
                      </Button>
                    )}
                  </Flex>
                </Stack>
              )}
              {selectedTab === RecruiterDashboardTabItems.RecruiterDashboard && (
                <RecruiterDashboardView
                  accountId={accountId}
                  recruiterListData={recruiterListData}
                  showSearchView={isRecruiterSearchFeatureEnabled}
                  onSetActiveTab={onSetActiveTab}
                />
              )}

              {selectedTab === RecruiterDashboardTabItems.RecruiterProfile && <RecruiterProfileView accountId={accountId} />}
            </Stack>
          </TabPanel>
          <TabPanel p={0} />
        </TabPanels>
      </Tabs>
    </Box>
  );
};
