import React, { useEffect, useState } from 'react';
import {
  Slider,
  Box,
  Heading,
  Text,
  VStack,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Divider,
  useToast,
} from '@chakra-ui/core';
import Bugsnag from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { searchByExperience } from '../../messaging/sidebar/searchAndSort/SearchAndSortStyles';
import colors from '../../../styles/colors';
import { useStoreActions, useStoreState } from '../../../models/hooks';

const UnresponsivePeriodView = (): JSX.Element => {
  const configuration = useStoreState((s) => s.app.accounts?.configuration);
  const accountID = useStoreState((s) => s.app.accounts?.id);
  const [loading, setLoading] = useState<boolean>(false);
  const [agedperiod, setAgedperiod] = useState<number>(14);
  const [agedToDismissedPeriod, setAgedToDismissedPeriod] = useState<number>(28);
  const { addUnresponsivePeriod } = useStoreActions((actions) => actions.accountLevelConfiguration);

  const toast = useToast();
  const { t } = useTranslation('');

  useEffect(() => {
    if (configuration && configuration?.unresponsiveAgedPeriod) {
      setAgedperiod(configuration?.unresponsiveAgedPeriod);
    }
    if (configuration && configuration?.unresponsiveDismissPeriod) {
      setAgedToDismissedPeriod(configuration?.unresponsiveDismissPeriod);
    }
  }, [configuration]);

  const onUpdateTimePeriod = async () => {
    if (accountID) {
      try {
        setLoading(true);
        await addUnresponsivePeriod({
          unresponsiveAgedPeriod: agedperiod,
          unresponsiveDismissPeriod: agedToDismissedPeriod,
          accountId: accountID,
        });
        setLoading(false);
        toast({
          title: t('common:unresponsivePeriod.configUpdateScuccess'),
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      } catch (e) {
        setLoading(false);
        Bugsnag.notify(e);
        toast({
          title: t('common:unresponsivePeriod.configUpdateFailed'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <div>
      <VStack align="left" backgroundColor="white" p={4}>
        <Box py={2} px={4} borderBottom="0.5px solid #ECEFF1">
          <Heading as="h4" fontSize="lg" lineHeight="1.3">
            {t('common:unresponsivePeriod.agedPeriod')}
          </Heading>
          <Text m="3px 0" color="gray.500" fontSize="12px">
            {t('common:unresponsivePeriod.agedPeriodInfo')}
          </Text>
        </Box>

        <Box css={searchByExperience}>
          <Slider
            aria-label="slider-ex-1"
            value={agedperiod}
            max={70}
            min={1}
            margin="20px 20px 40px 20px"
            width="50%"
            onChange={/* istanbul ignore next */ (value: number) => setAgedperiod(value)}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb bg={colors.blue.default}>
              <Box className="infoBoxAged">
                {agedperiod}
                {` ${agedperiod === 1 ? t('common:unresponsivePeriod.day') : t('common:unresponsivePeriod.days')}`}
              </Box>
            </SliderThumb>
          </Slider>
        </Box>
      </VStack>
      <Divider />
      <VStack align="left" backgroundColor="white" p={4}>
        <Box py={2} px={4} borderBottom="0.5px solid #ECEFF1">
          <Heading as="h4" fontSize="lg" lineHeight="1.3">
            {t('common:unresponsivePeriod.agedToDismissedPeriod')}
          </Heading>
          <Text m="3px 0" color="gray.500" fontSize="12px">
            {t('common:unresponsivePeriod.agedToDismissedPeriodInfo')}
          </Text>
        </Box>

        <Box css={searchByExperience}>
          <Slider
            aria-label="slider-ex-1"
            value={agedToDismissedPeriod}
            max={70}
            min={1}
            margin="20px 20px 40px 20px"
            width="50%"
            onChange={/* istanbul ignore next */ (value: number) => setAgedToDismissedPeriod(value)}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb bg={colors.blue.default}>
              <Box className="infoBoxAged">
                {agedToDismissedPeriod}
                {` ${agedToDismissedPeriod === 1 ? t('common:unresponsivePeriod.day') : t('common:unresponsivePeriod.days')}`}
              </Box>
            </SliderThumb>
          </Slider>
          <Button
            data-testid="updatePeriodTimeBtn"
            colorScheme="blue"
            ml={3}
            float="right"
            marginTop="10px"
            marginBottom="10px"
            isLoading={loading}
            onClick={onUpdateTimePeriod}
          >
            {t('businessManagement:createBusiness.update')}
          </Button>
        </Box>
      </VStack>
    </div>
  );
};

export default UnresponsivePeriodView;
