import { css } from '@emotion/core';

export const interviewRoomBlock = css`
  background: #111111;

  .interviewRoomHeader {
    color: #fff;
    padding: 5px 20px;

    .chakra-stack {
      svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      .chakra-badge {
        background: #049be5;
        color: #fff;
        height: 24px;
        width: 24px;
        border-radius: 5px;
        margin-left: 9px;
        font-weight: 700;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      .badge-label {
        @media (max-width: 480px) {
          display: none;
        }
      }
    }
  }
`;

export const mainParticipantStyle = css`
  width: 100%;
  height: calc(100vh - 179px);
  background: black;
  overflow: hidden;

  .waiting-block {
    height: 100%;
    background-color: #1c1b2c;
    svg {
      width: 32px;
      height: 32px;
      display: inline-block;
      margin-bottom: 5px;
      path {
        fill: #ffffff;
      }
    }
  }
`;

export const mainParticipantBlockStyle = css`
  width: calc(100% - 280px);

  @media (max-width: 480px) {
    width: 100%;

    .main-participant {
      width: 100%;
      height: calc(100vh - 245px);
      background: black;
      overflow: hidden;
    }
  }

  .publication-track {
    height: 100%;

    .avatar-container {
      height: 100% !important;
    }
  }

  .publication video {
    height: calc(100vh - 179px);
    object-fit: contain !important;

    @media (max-width: 480px) {
      height: calc(100vh - 245px);
      object-fit: contain !important;
    }
  }
`;

export const participantBlockStyle = css`
  width: 280px;

  @media (max-width: 480px) {
    width: 100%;
  }

  .participant-list {
    @media (max-width: 480px) {
      //overflow-x: auto;
      //display: block;
      //width: 100%;
      //line-height: 0;
      //white-space: nowrap;
    }

    .avatar-container {
      @media (max-width: 480px) {
        width: 135px !important;
        height: 110px !important;
      }
    }
  }

  .participant-view {
    width: 100%;
    @media (max-width: 480px) {
      width: auto;
      display: inline-block;
      margin-right: 2px;
    }
  }

  .participant-view-more {
    justify-content: center;
    background: #1d1d1d;
    display: flex;

    svg path {
      fill: #36464e;
    }

    .btn-more {
      background: #e0e0e0;
      height: 50px;
      width: 50px;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 9;
      border: 2px solid #fff;
    }
  }
`;

export const interviewRoomActionBar = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: #fff;
  padding: 5px 25px;

  .chakra-button {
    background: #f3f3f3;
    border-radius: 50%;
    font-size: 28px;
    width: 43px;
    height: 43px;
    padding: 0;

    &.btn-call-end {
      background-color: #f34d4f;
    }
  }
`;

export const videosWrapper = css`
  height: calc(100vh - 179px);
  position: relative;

  &.grid-layout {
    .main-participant-wrapper {
      width: 35%;

      @media (max-width: 480px) {
        width: 100%;
      }
    }
    .participants-blocks {
      width: 100%;
      @media (max-width: 480px) {
        width: 100%;
      }
      .participant-list {
        width: 100%;
        overflow-y: auto;
        height: 100%;
        background-color: #333;
        justify-content: center;
        align-items: center;

        &.one-row-two-column .participant-view {
          width: calc(50% - 10px);
          height: 85%;
        }
        &.one-row-three-column .participant-view {
          width: calc(33.33% - 10px);
          height: 55%;
        }
        &.two-row-three-column .participant-view {
          width: calc(33.33% - 10px);
          height: calc(50% - 10px);
        }
        &.two-row-four-column .participant-view {
          width: calc(25% - 10px);
          height: calc(50% - 10px);
        }
        &.three-row-four-column .participant-view {
          width: calc(25% - 10px);
          height: calc(33.33% - 10px);
        }
        &.four-row-four-column .participant-view {
          width: calc(25% - 10px);
          height: 25%;
        }

        .participant-view {
          width: calc(50% - 10px);
          margin: 5px;
          border-radius: 5px;
          height: 95%;

          @media (max-width: 768px) {
            width: calc(50% - 10px) !important;
            min-height: 225px !important;
            height: 33% !important;
          }

          @media (max-width: 480px) {
            width: calc(100% - 10px) !important;
          }

          .publication-track {
            height: 100%;
          }

          .video-publication {
            height: 100%;
          }
        }
      }
    }
  }

  &.presentation-layout {
    .participant-list {
      flex-direction: column;
      overflow-x: hidden;
      padding: 0 3px;
      box-sizing: border-box;

      .participant-view {
        height: 155px;

        .publication-track {
          height: 100%;
        }

        .video-publication video {
          max-height: 180px;
        }
      }
    }
  }
`;

export const publicationTrack = css`
  position: relative;

  .participant-info-bar {
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%;

    .participant-name {
      font-size: 14px;
      padding: 5px 10px;
      color: #fff;
      background: rgba(0, 0, 0, 0.4);
      width: calc(100% - 75px);

      @media (max-width: 992px) {
        width: calc(100% - 0px);
      }
    }
    .icon-box {
      margin-right: 6px;
      font-size: 25px;
      border-radius: 50px;
      background: rgba(0, 0, 0, 0.56);
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        max-width: 16px;
        max-height: 16px;
      }
    }
  }
`;
