import { useQuerySnapshot } from '../../../firebase/firestore/hooks';
import { Collections } from '../../../firebase/firestore/collections';
import { Query } from '../../../firebase/firestore/query/query';
import { AccountInvites } from '../../../firebase/firestore/documents/accountInvites';
import { AccountInvitesTypeEnum } from './AccountInvitesTypeEnum';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';

export enum TeamInviteStateEnum {
  LOADING,
  READY,
}

export type TeamMembersState = {
  state: TeamInviteStateEnum;
  accountInvites: AccountInvites[];
};

const useAccountInvites = (): TeamMembersState => {
  const { currentUserProfile } = useCurrentUserProfile();

  const accountInvites: AccountInvites[] | undefined = useQuerySnapshot<AccountInvites>(Collections.ACCOUNT_INVITES, [
    Query.field<AccountInvites>('account').equals(currentUserProfile?.account),
    Query.field<AccountInvites>('status').in([AccountInvitesTypeEnum.PENDING, AccountInvitesTypeEnum.EXPIRED]),
  ]);

  if (accountInvites && currentUserProfile) {
    return {
      state: TeamInviteStateEnum.READY,
      accountInvites,
    };
  }

  return {
    state: TeamInviteStateEnum.LOADING,
    accountInvites: [],
  };
};

export default useAccountInvites;
