import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { Stack } from '@chakra-ui/core';
import InputPreview from './InputPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';

export default function EmailSchemaBuilder({ ...props }: SchemaBuilderProps) {
  return (
    <Stack>
      <LabelSchemaBuilder {...props} />
      <FormElementTextBuilder {...props} placeholder="Enter email address here..." />
      <InputPreview value="example@email.com" />
    </Stack>
  );
}
