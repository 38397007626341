import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/core';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineArrowBack } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../routes/constants';
import colors from '../../../styles/colors';

interface OfferAndHireProcessViewTopBarProps {
  children: ReactNode;
}

const OfferAndHireProcessViewTopBar = ({ children }: OfferAndHireProcessViewTopBarProps) => {
  const { t } = useTranslation('offerAndHireProcess');
  return (
    <Box p="12px" bg="#fff" mb={3}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex pl="10px" pr="10px" alignItems="center">
          <MdOutlineArrowBack
            size="20px"
            data-testid="goBackToDoLeftIcon"
            style={{ marginRight: 8, cursor: 'pointer' }}
            onClick={() => window.history.back()}
          />
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="md" color={colors.gray[900]} as={Link} to={PATH_DASHBOARD}>
                {t('toDoBreadCrumb')}
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink fontSize="md" color={colors.black}>
                {t('pageTitle')}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        {children}
      </Flex>
    </Box>
  );
};

export default OfferAndHireProcessViewTopBar;
