/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
// import { Redirect } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Box } from '@chakra-ui/core';
import { InterviewVideoBlock } from './InteviewMeetViewStyle';
import { MeetContextProvider } from '../../../meet/MeetContext';
import { InterviewMeetRoom } from './InterviewMeetRoom';

const MotionBox = motion.custom(Box);

export const InterviewMeetView = (): JSX.Element => {
  return (
    <MotionBox initial="initial" data-testid="InterviewMeetView" css={InterviewVideoBlock} className="interview-block">
      <MeetContextProvider>
        <InterviewMeetRoom />
      </MeetContextProvider>
    </MotionBox>
  );
};
