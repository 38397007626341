import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Heading, Input, Text, useToast, VStack } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { useStoreActions, useStoreState } from '../../../models/hooks';

export const WebHookUrlView = (): JSX.Element => {
  const hireWebhookForCustomer = useStoreState((s) => s.app.accounts?.configuration?.hireWebhookForCustomer);
  const { t } = useTranslation();
  const [link, setLink] = useState<string>(hireWebhookForCustomer?.url ?? '');
  const [loading, setLoading] = useState<boolean>(false);
  const [typing, setTyping] = useState<boolean>(false);
  const updateHireWebHookUrlInAccount = useStoreActions((actions) => actions.account.updateHireWebHookUrlInAccount);
  const accountId = useStoreState((s) => s.app.user?.account);
  const toast = useToast();

  const handleHireWebHookUrl = async () => {
    try {
      /* istanbul ignore else */
      if (accountId) {
        const urlSchema = Joi.string().uri();
        const { error } = urlSchema.validate(link);
        if (error) {
          toast({
            title: t('administration:webHookUrl.validUrl'),
            status: 'error',
            duration: 1000,
            isClosable: true,
          });
          return;
        }

        setLoading(true);
        await updateHireWebHookUrlInAccount({ accountId, link });
        toast({
          title: t('administration:webHookUrl.successMsg'),
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
        setLoading(false);
      }
    } catch (e) {
      toast({
        title: t('administration:webHookUrl.errorMsg'),
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <VStack align="left" backgroundColor="white" p={4}>
      <Box py={2} px={4} borderBottom="0.5px solid #ECEFF1">
        <Heading as="h4" fontSize="lg" lineHeight="1.3">
          {t('administration:webHookUrl.title')}
        </Heading>
        <Text m="3px 0" color="gray.500" fontSize="12px">
          {t('administration:webHookUrl.subtitle')}
        </Text>
      </Box>
      <Box py={2} px={4}>
        <FormControl isRequired>
          <FormLabel>{t('administration:webHookUrl.inputPlaceHolder')}</FormLabel>
          <Input
            type="text"
            data-testid="webHookUrlFeild"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
              setTyping(true);
            }}
          />
        </FormControl>
        <Button
          data-testid="submitWebHookUrlButton"
          colorScheme="blue"
          mt="12px"
          float="right"
          onClick={handleHireWebHookUrl}
          disabled={loading || !typing || !link}
          isLoading={loading}
        >
          {t('administration:languageEvaluation.update')}
        </Button>
      </Box>
    </VStack>
  );
};
