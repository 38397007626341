import firebase from 'firebase/app';
import 'firebase/auth';

class Firebase {
  onAuthStateChanged(callback: (authUser: firebase.User | null) => void): firebase.Unsubscribe {
    return firebase.auth().onAuthStateChanged((authUser) => {
      callback(authUser);
    });
  }

  async signInWithPhoneNumber(
    phoneNumber: string,
    appVerifier: firebase.auth.RecaptchaVerifier,
  ): Promise<firebase.auth.ConfirmationResult> {
    return firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
  }

  async singInWithEmailAndPassword(email: string, password: string): Promise<void> {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  }

  async signInWithAnonymously(): Promise<void> {
    await firebase.auth().signInAnonymously();
  }

  async signOut(): Promise<void> {
    return firebase.auth().signOut();
  }
}

export default new Firebase();
