import { useCallback, useEffect, useRef, useState } from 'react';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { isMobile } from '../../isMobileUtil';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.TwilioVideo = Video;

const MAX_PARTICIPANTS = 50;

export type RoomInfo = {
  room: Room | null;
  connect: (token: string) => void;
  disconnect: () => void;
  isConnecting: boolean;
};

export const useRoom = (localTracks: Array<LocalTrack | MediaStreamTrack>, options?: ConnectOptions): RoomInfo => {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const optionRef = useRef(options);
  useEffect(() => {
    optionRef.current = options;
  }, [options]);

  useEffect(() => {
    return () => {
      room?.disconnect();
    };
  }, [room]);

  const connect = useCallback(
    (token) => {
      setIsConnecting(true);
      return Video.connect(token, {
        ...optionRef.current,
        tracks: localTracks,
      })
        .then((nRoom) => {
          setRoom(nRoom);
          // add event listener does not call this method while testing.
          // istanbul ignore next
          const disconnect = () => nRoom.disconnect();

          nRoom.setMaxListeners(MAX_PARTICIPANTS);
          nRoom.once('disconnected', () => {
            setTimeout(() => setRoom(null));
            window.removeEventListener('beforeunload', disconnect);
            if (isMobile()) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          nRoom.localParticipant.videoTracks.forEach((publication) => {
            publication.setPriority('low');
          });

          setIsConnecting(false);

          window.addEventListener('beforeunload', disconnect);
          if (isMobile()) {
            window.addEventListener('pagehide', disconnect);
          }
        })
        .catch(
          // Global twillio-video mock does not allow rejecting promise
          // istanbul ignore next
          (e) => {
            console.log('Connecting room error', e);
            setIsConnecting(false);
          },
        );
    },
    [localTracks],
  );

  const disconnect = useCallback(() => {
    room?.disconnect();
  }, [room]);

  return { room, connect, disconnect, isConnecting };
};
