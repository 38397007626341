import { Box, Divider, Heading, Text } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedError } from '../../../../utils/FormErrorUtils';
import { JobPosition } from '../PositionType';
import { InterviewDetail } from './InterviewDetail';

export type InterviewProp = {
  jobPositionData: JobPosition;
  formErrors: FormattedError[];
  isInterviewAndBusinessLocationSame: boolean;
  handleChange: (value: string, fieldName: string) => void;
  handleChangeInterviewLocation: (value: boolean) => void;
};

export const InterviewCard = ({
  jobPositionData,
  formErrors,
  isInterviewAndBusinessLocationSame,
  handleChange,
  handleChangeInterviewLocation,
}: InterviewProp): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box backgroundColor="white" borderRadius={5} mb={6}>
      <Box px={6} py={4}>
        <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
          {t('createJobPosition:interviewCardHeader')}
        </Heading>
        <Text fontSize="xs" color="gray.500">
          {t('createJobPosition:interviewCardHint')}
        </Text>
      </Box>
      <Divider borderColor="#ECEFF1" />
      <Box p={6}>
        <InterviewDetail
          jobPositionData={jobPositionData}
          formErrors={formErrors}
          isInterviewAndBusinessLocationSame={isInterviewAndBusinessLocationSame}
          handleChange={handleChange}
          handleChangeInterviewLocation={handleChangeInterviewLocation}
        />
      </Box>
    </Box>
  );
};
