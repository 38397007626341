import { CandidateHistoryActionName } from '../../../../../../firebase/firestore/documents/candidateAuditActionNames';
import { leftBoxVariants, rightBoxVariants } from '../Chat.styles';
import { MessagePresenter } from '../messagePresenter';

export const messageVariants = (message: MessagePresenter) => {
  switch (message.action) {
    case CandidateHistoryActionName.HIRE_FORM_RESUBMISSION_IN_PROGRESS:
    case CandidateHistoryActionName.HIRE_FORM_IN_PROGRESS:
      return {
        variant: leftBoxVariants,
        className: 'is-pin-left',
        justify: 'flex-start',
        order: 3,
        userPictureStyle: { backgroundColor: 'orange', color: 'white' },
      };

    default:
      return {
        variant: message.isFromSeeker ? leftBoxVariants : rightBoxVariants,
        className: message.isFromSeeker ? 'is-pin-left' : 'is-pin-right',
        justify: message.isFromSeeker ? 'flex-start' : 'flex-end',
        order: message.isFromSeeker ? 3 : 1,
        userPictureStyle: { backgroundColor: message.isFromSeeker ? 'orange' : '#ECEFF1', color: 'white' },
      };
  }
};
