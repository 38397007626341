import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Center,
  Box,
  Text,
  Flex,
  Stack,
  Radio,
  RadioGroup,
  Spacer,
  Checkbox,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/core';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { CloseIcon } from '@chakra-ui/icons';
import { RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack } from '@chakra-ui/slider';
import { useTranslation } from 'react-i18next';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';
import usePosition from '../seekerList/usePosition';
import { MultiSelectDropdown, OptionType } from '../../../common/MultiSelectDropDown/MultiSelectDropDown';
import { selectedQuestionsList } from '../seekerList/SeekerListContainer.styles';
import { PositionWiseFilterTypes, QuestionFilter } from '../filters/seekersFiltersContextValueType';
import { searchByExperience } from './SearchAndSortStyles';

export type PositionWiseSearchModalProps = {
  onClose: () => void;
};

export const PositionWiseSearchModal = ({ onClose }: PositionWiseSearchModalProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    setIsPositionFilterApplied,
    isPositionFilterApplied,
    selectedPositionIdForFilter,
    setOpenFilterByPositionList,
    openFilterByPositionList,
  } = useSeekersFilters();
  const positions = usePosition(selectedPositionIdForFilter);
  const [questionOption, setQuestionOptions] = useState<OptionType[]>([]);
  const [selectedQuestionOptions, setSelectedQuestionsOptions] = useState<QuestionFilter[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<OptionType | null>(null);
  const [expectedAnswer, setExpectedAnswer] = useState<string>('true');
  const [salaryUnit] = useState<string>('hourly');
  const [experienceRange, setExperienceRange] = useState<number[]>([0, 1]);
  const [salaryRange, setSalaryRange] = useState<number[]>([100, 2000]);
  const [showExperienceFilter, setShowExperienceFilter] = useState<boolean>(false);
  const [showQuestionFilter, setShowQuestionFilter] = useState<boolean>(false);
  const [showSalaryFilter, setShowSalaryFilter] = useState<boolean>(false);

  useEffect(() => {
    const newQuestionOptions: OptionType[] = [];
    /* istanbul ignore else */

    if (positions) {
      positions?.specificQuestions?.forEach((value) => {
        const question: OptionType = {
          id: value.id,
          label: value.text,
          value: value.text,
        };
        newQuestionOptions.push(question);
      });
      setQuestionOptions(newQuestionOptions);
      if (openFilterByPositionList?.get(positions.id)) {
        const questions = openFilterByPositionList?.get(positions.id)?.question;
        const experience = openFilterByPositionList?.get(positions.id)?.experience;
        const salary = openFilterByPositionList?.get(positions.id)?.salary;
        if (experience) setShowExperienceFilter(true);
        if (questions) setShowQuestionFilter(true);
        if (salary) setShowSalaryFilter(true);
        setSelectedQuestionsOptions(questions || []);
        setExperienceRange(experience || [0, 1]);
        setSalaryRange(salary || [100, 2000]);
      }
    }
  }, [positions, openFilterByPositionList]);

  const onAddQuestionToFilter = () => {
    /* istanbul ignore else */
    if (selectedQuestion) {
      const questionForFilter: QuestionFilter = {
        question: selectedQuestion?.label,
        expectedAnswer: expectedAnswer === 'true',
        id: selectedQuestionOptions.length + 1,
      };
      selectedQuestionOptions.push(questionForFilter);
      setSelectedQuestionsOptions(selectedQuestionOptions);
      setSelectedQuestion(null);
    }
  };
  const clearAllFilter = () => {
    setSelectedQuestionsOptions([]);
    setSalaryRange([100, 300]);
    setExperienceRange([0, 1]);
    /* istanbul ignore else */
    if (positions) {
      openFilterByPositionList?.delete(positions.id);
      setOpenFilterByPositionList(openFilterByPositionList);
      setIsPositionFilterApplied(false);
    }
  };

  const onApplyFilter = () => {
    if (showQuestionFilter && selectedQuestionOptions.length === 0) {
      onAddQuestionToFilter();
    }
    /* istanbul ignore else */
    if (positions) {
      const previousFilter = openFilterByPositionList?.get(positions.id);
      const newFilters = openFilterByPositionList || new Map<string, PositionWiseFilterTypes>();

      if (previousFilter) {
        if (showQuestionFilter) previousFilter.question = selectedQuestionOptions;
        else previousFilter.question = undefined;
        if (showExperienceFilter) previousFilter.experience = experienceRange;
        else previousFilter.experience = undefined;
        if (showSalaryFilter) previousFilter.salary = salaryRange;
        else previousFilter.salary = undefined;
        previousFilter.salaryUnit = salaryUnit;
        if (selectedQuestionOptions.length) {
          newFilters?.set(positions.id, previousFilter);
          setOpenFilterByPositionList(newFilters);
        }
      } else {
        const newFilter: PositionWiseFilterTypes = {};
        if (showQuestionFilter && selectedQuestionOptions.length) newFilter.question = selectedQuestionOptions;
        if (showExperienceFilter) newFilter.experience = experienceRange;
        if (showSalaryFilter) newFilter.salary = salaryRange;
        newFilter.salaryUnit = salaryUnit;
        newFilters?.set(positions.id, newFilter);
        setOpenFilterByPositionList(newFilters);
      }

      setIsPositionFilterApplied(false);
    }
  };

  const onRemoveQuestion = (index: number) => {
    const array = [...selectedQuestionOptions];
    array.splice(index, 1);
    setSelectedQuestionsOptions(array);
  };

  const onSelectQuestion = (question: OptionType | null) => {
    setSelectedQuestion(question);
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isPositionFilterApplied} onClose={() => onClose()} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>{positions?.customName}</Center>
        </ModalHeader>
        <ModalCloseButton data-testid="closeBtn" />
        <ModalBody pb={6} css={selectedQuestionsList}>
          <Box style={{ margin: '1.5rem 1.5rem 4.5rem 1.5rem' }} css={searchByExperience}>
            <Flex>
              <Checkbox
                data-testid="salaryFilterCheckBox"
                isChecked={showSalaryFilter}
                style={{ marginBottom: 8 }}
                onChange={() => {
                  setShowSalaryFilter(!showSalaryFilter);
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: 700 }}>{`${t('searchAndSort.salary')} ( ${t('salary.hourly')})`}</Text>
              </Checkbox>
            </Flex>
            <RangeSlider
              isDisabled={!showSalaryFilter}
              defaultValue={[100, 300]}
              value={salaryRange}
              bg="white"
              data-testid="salarySlider"
              max={10000}
              min={0}
              minStepsBetweenThumbs={200}
              onChange={/* istanbul ignore next */ (value: number[]) => setSalaryRange(value)}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack bg="#1F3CBA" w="100%" p={1} color="white" />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} bg="#1F3CBA">
                <Box className="infoBox">{`$ ${salaryRange[0] / 100}`}</Box>
              </RangeSliderThumb>
              <RangeSliderThumb index={1} bg="#1F3CBA">
                <Box className="infoBox">{`$ ${salaryRange[1] / 100}`}</Box>
              </RangeSliderThumb>
            </RangeSlider>
          </Box>
          <Box style={{ margin: '1.5rem 1.5rem 4.5rem 1.5rem' }} css={searchByExperience}>
            <Flex>
              <Checkbox
                data-testid="experienceFilterCheckBox"
                isChecked={showExperienceFilter}
                style={{ marginBottom: 8 }}
                onChange={() => {
                  setShowExperienceFilter(!showExperienceFilter);
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: 700 }}>{t('searchAndSort.experience')}</Text>
              </Checkbox>
            </Flex>

            <Slider
              isDisabled={!showExperienceFilter}
              value={experienceRange[0]}
              bg="white"
              data-testid="experienceSlider"
              max={20}
              min={0}
              minStepsBetweenThumbs={1}
              onChange={
                /* istanbul ignore next */ (value: number) => {
                  const newExperieneRange = [value, 20];
                  setExperienceRange(newExperieneRange);
                }
              }
            >
              <SliderTrack>
                <SliderFilledTrack bg="#1F3CBA" w="100%" p={1} color="white" />
              </SliderTrack>
              <SliderThumb index={0} bg="#1F3CBA">
                <Box className="infoBox">{`${experienceRange[0]} ${t('experience.yrs')}`}</Box>
              </SliderThumb>
            </Slider>
          </Box>
          {questionOption.length > 0 && (
            <Box style={{ padding: '0 1.5rem', position: 'relative', zIndex: 2 }}>
              <Box>
                <Flex>
                  <Checkbox
                    data-testid="questionFilterCheckBox"
                    isChecked={showQuestionFilter}
                    style={{ marginBottom: 8 }}
                    onChange={() => {
                      setShowQuestionFilter(!showQuestionFilter);
                    }}
                  >
                    <Text style={{ fontSize: 14, fontWeight: 700 }}>{t('searchAndSort.expectedQuestions')}</Text>
                  </Checkbox>
                </Flex>
                <Flex>
                  <Box w="60%">
                    <MultiSelectDropdown
                      placeholder={t('expectedQuestions.typeYourQuestion')}
                      isDisabled={!showQuestionFilter}
                      id="jobPositionDropDown"
                      handleChange={(e) => onSelectQuestion(e)}
                      options={questionOption}
                      value={selectedQuestion}
                    />
                  </Box>

                  <Box w="30%" style={{ paddingLeft: 12 }}>
                    <Text style={{ fontSize: 12, fontWeight: 700 }}>{t('expectedQuestions.expectedAnswer')}</Text>
                    <RadioGroup isDisabled={!showQuestionFilter} value={expectedAnswer}>
                      <Stack direction="row">
                        <Radio
                          value="true"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setExpectedAnswer(e.target.value)}
                          isDisabled={!showQuestionFilter}
                          data-testid="radio-yes"
                        >
                          {t('expectedQuestions.yes')}
                        </Radio>
                        <Radio
                          value="false"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setExpectedAnswer(e.target.value)}
                          isDisabled={!showQuestionFilter}
                          data-testid="radio-no"
                        >
                          {t('expectedQuestions.no')}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Box>
                  <Box
                    onClick={onAddQuestionToFilter}
                    w="10%"
                    className={showQuestionFilter ? 'addButton' : 'disabledAddButton'}
                    data-testid="addQuestionToFilter"
                  >
                    <IoMdAddCircleOutline style={{ fontSize: 30 }} />
                  </Box>
                </Flex>
              </Box>
              <Flex className="questionWrapper">
                {selectedQuestionOptions.map((item, index: number) => (
                  <Box
                    className="individualQuestion"
                    key={item.id}
                    style={{ color: item.expectedAnswer ? '#43a047' : '#e53935' }}
                  >
                    {item.question}
                    <CloseIcon
                      style={{ paddingLeft: 10, color: '#B8BCCA', fontSize: 20 }}
                      onClick={() => onRemoveQuestion(index)}
                      data-testid="removeQuestion"
                    />
                  </Box>
                ))}
              </Flex>
            </Box>
          )}
        </ModalBody>

        <ModalFooter style={{ paddingLeft: '1.5rem', marginTop: '1.5rem' }}>
          <Text
            mr={3}
            onClick={clearAllFilter}
            style={{ fontSize: 14, fontWeight: 700, color: '#1F3CBA', cursor: 'pointer' }}
            data-testid="clearPositionFilter"
          >
            {t('searchAndSort.clearAll')}
          </Text>
          <Spacer />
          <Button colorScheme="blue" mr={3} onClick={onApplyFilter} data-testid="savePositionFilter">
            {t('searchAndSort.filter')}
          </Button>
          <Button onClick={() => setIsPositionFilterApplied(false)} data-testid="cancelPositionFilter">
            {t('button.cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
