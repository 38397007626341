/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useMeetContext } from '../MeetContext';

const TAudio = styled('audio')();

export type TestAudioTrackProps = {
  isPlaying: boolean;
};

export const TestAudioTrack = ({ isPlaying }: TestAudioTrackProps): JSX.Element | null => {
  const { activeSinkId } = useMeetContext();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = useRef<HTMLAudioElement>(null!);

  useEffect(() => {
    // eslint-disable-next-line global-require
    const audio = new Audio(require('../../../assets/audio/speaker_test.mp3'));
    ref.current = audio;
    document.body.appendChild(ref.current);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    ref.current?.setSinkId?.(activeSinkId);
  }, [activeSinkId]);

  useEffect(() => {
    const toggleAudio = async (playing: boolean) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions,@typescript-eslint/await-thenable
      if (playing) {
        await ref.current.play();
      } else {
        ref.current.pause();
        ref.current.currentTime = 0;
      }
    };
    // eslint-disable-next-line no-void
    void toggleAudio(isPlaying);
  }, [isPlaying]);

  return <TAudio ref={ref} data-testid="TAudioView" />;
};
