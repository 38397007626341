import { Collections, SubCollections } from '../../../../../../firebase/firestore/collections';
import { Requisition } from '../../../../../../firebase/firestore/documents/requisition';
import { useSubCollectionQuerySnapshot } from '../../../../../../firebase/firestore/hooks';
import { Query } from '../../../../../../firebase/firestore/query/query';

const useSingleRequisitionByPosition = (positionId: string, requisitionId: string): Requisition[] | undefined => {
  const requisition = useSubCollectionQuerySnapshot<Requisition>(Collections.POSITIONS, positionId, SubCollections.REQUISITIONS, [
    Query.field('requisitionId').equals(requisitionId),
  ]);

  return requisition;
};

export default useSingleRequisitionByPosition;
