import _isEmpty from 'lodash/isEmpty';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Experience, Education, Seeker } from '../../../../../firebase/firestore/documents/seeker';
import { AppyPros } from './appyPros';
import SeekerExperiencePresenter from './seekerExperiencePresenter';
import SeekerEducationPresenter from './seekerEducationPresenter';
import { EngagedCandidate, FitStatus } from '../../../../../firebase/firestore/documents/candidate';

const phoneUtil = PhoneNumberUtil.getInstance();

export default class ProfilePresenter {
  readonly appyPros = this.seeker?.flexibleTransportation ? [AppyPros.FlexibleTransportation] : [];

  readonly email = this.candidate?.email;

  readonly insights = this.candidate?.insights;

  readonly preferredSalary = this?.candidate?.preferredSalary;

  readonly phone = this.candidate?.phone;

  readonly earliestAvailabilityDate = this.candidate?.earliestAvailabilityDate;

  readonly noticePeriod = this.seeker?.noticePeriod;

  readonly expectedWorkHour = this.seeker?.expectedWorkHour;

  readonly isStudent = this.seeker?.isStudent;

  readonly isAdult = this.seeker?.isAdult;

  readonly commuteData = this.candidate?.commuteData;

  readonly availabilityUpdatedAt = this.seeker?.availabilityUpdatedAt;

  readonly experiences = (
    this.seeker?.experiences?.map((experience: Experience) => new SeekerExperiencePresenter(experience)) ?? []
  ).sort((x, y) => Number(y.current) - Number(x.current));

  readonly educations = this.seeker?.educations?.map((education: Education) => new SeekerEducationPresenter(education)) ?? [];

  readonly candidateAvailabilities = this.seeker?.newAvailabilities ?? null;

  readonly availabilityFitBand =
    this.candidate?.availabilityMatchedRequisitions && this.candidate?.availabilityMatchedRequisitions.length > 0
      ? this.candidate?.availabilityMatchedRequisitions[0].fit
      : undefined;

  readonly availabilityMatchedPositions =
    this.candidate?.availabilityMatchedPositions && this.candidate?.availabilityMatchedPositions.length > 0
      ? this.candidate?.availabilityMatchedPositions[0].fit
      : undefined;

  readonly availabilityMatchedRequisitions =
    this.candidate?.availabilityMatchedRequisitions && this.candidate?.availabilityMatchedRequisitions.length > 0
      ? this.candidate?.availabilityMatchedRequisitions?.filter(
          (item) => item.fit === FitStatus.GOOD || item.fit === FitStatus.MEDIUM,
        )
      : [];

  constructor(readonly seeker?: Readonly<Seeker>, readonly candidate?: Readonly<EngagedCandidate>) {}

  get formattedPhone(): string {
    if (this.phone?.length !== 12) {
      return '';
    }
    const number = phoneUtil.parseAndKeepRawInput(this.phone, 'US');
    return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
  }

  get hasAppyPros(): boolean {
    return this.appyPros.length > 0;
  }

  get hasInsights(): boolean {
    return !_isEmpty(this.insights);
  }

  get hasExperiences(): boolean {
    return this.experiences.length > 0;
  }

  get hasEducations(): boolean {
    return this.educations.length > 0;
  }

  get hasContactInformation(): boolean {
    return !_isEmpty(this.email) || !_isEmpty(this.phone);
  }
}
