import _isNil from 'lodash/isNil';
import _filter from 'lodash/filter';
import { User } from '../../../firebase/firestore/documents/user';
import { useQuerySnapshot } from '../../../firebase/firestore/hooks';
import { Collections } from '../../../firebase/firestore/collections';
import { Query } from '../../../firebase/firestore/query/query';
import { useStoreState } from '../../../models/hooks';
import { AccountType } from '../../../firebase/firestore/documents/accounts';

export enum TeamMemberStateEnum {
  LOADING,
  READY,
}

export type TeamMembersState = {
  state: TeamMemberStateEnum;
  teamMembers: User[];
};

const isUserOperator = (profile: User | undefined) => {
  if (!_isNil(profile)) {
    return profile.operator && profile.operator.enabled;
  }
  return false;
};

const useTeamMembers = (): TeamMembersState => {
  const { user, accounts } = useStoreState((state) => state.app);

  let teamMembers: User[] | undefined = useQuerySnapshot<User>(Collections.USERS, [
    Query.field<User>('account').equals(user?.account),
  ]);

  if (!isUserOperator(user) || accounts?.type === AccountType.PERSONAL) {
    teamMembers = _filter(
      teamMembers,
      (member) => member.id !== user?.id && !member.ghost && !member.anonymousUser && member.firstName !== 'anonymous',
    );
  }

  if (teamMembers && user) {
    return {
      state: TeamMemberStateEnum.READY,
      teamMembers: _filter(teamMembers, (member) => !member.anonymousUser && member.firstName !== 'anonymous'),
    };
  }

  return {
    state: TeamMemberStateEnum.LOADING,
    teamMembers: [],
  };
};

export default useTeamMembers;
