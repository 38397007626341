import { css } from '@emotion/core';
import {
  filtersHeight,
  headerHeight,
  uiGridItemsWidth,
  uiGridItemsWidthForInterview,
  uiGridItemsWidthNarrow,
  uiGridSearchSortWidth,
} from '../../styles/sizes';

export const messagingGridCSS = css`
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  display: grid;
  grid-template-columns: [leftSide] ${uiGridItemsWidthNarrow} [midSide] 1fr [rightSide];
  grid-template-rows: [top] ${headerHeight} [header] ${filtersHeight} [sub1] 12px [sub2] 1fr [bottom];

  @media only screen and (min-width: 60rem) {
    grid-template-columns: [leftSide] ${uiGridItemsWidth} [midSide] 1fr [rightSide];
  }
`;

export const messagingGridCSSWhenInterview = css`
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  display: grid;
  grid-template-columns: [leftSide] ${uiGridItemsWidthNarrow} [midSide] 1fr [rightSide];
  grid-template-rows: [top] ${headerHeight} [header] ${filtersHeight} [sub1] 12px [sub2] 1fr [bottom];

  @media only screen and (min-width: 60rem) {
    grid-template-columns: [leftSide] ${uiGridItemsWidthForInterview} [midSide] 1fr [rightSide];
  }

  &.messaging-view {
    .interview-with-guest .participants-blocks {
    }
    .participants-blocks .publication-track .video-publication video {
      height: 100px !important;
      object-fit: contain !important;
    }
    .interview-with-guest .participants-blocks .publication-track .avatar-container {
      height: 100px !important;
      max-width: 80px !important;
      background: #333333 !important;
    }
  }
`;

export const messagingGridCSSIfSearchSortOpens = css`
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  display: grid;
  grid-template-columns: [search] ${uiGridItemsWidthNarrow} [leftSide] ${uiGridItemsWidthNarrow} [midSide] 1fr [rightSide];
  grid-template-rows: [top] ${headerHeight} [header] ${filtersHeight} [sub1] 12px [sub2] 1fr [bottom];

  @media only screen and (min-width: 60rem) {
    grid-template-columns: [search] ${uiGridSearchSortWidth} [leftSide] ${uiGridItemsWidth} [midSide] 1fr [rightSide];
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// istanbul ignore next
export const MessagingContainerCss = (offset = 100) => css`
  .chatMessage {
    height: calc(${offset}vh - 190px);
  }
`;
