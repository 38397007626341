import { CalendarEventType } from './CalendarEventType';
import { CalendarGroupEventType } from './CalendarGroupEventType';
import { CalendarScheduleTypeEnum } from './CalendarScheduleTypeEnum';

export const getUndefinedOrEventType = (event: CalendarEventType | undefined): CalendarGroupEventType | undefined => {
  if (event) {
    const { resource } = event;
    // istanbul ignore next
    if (resource.type === CalendarScheduleTypeEnum.GROUP_EVENT) {
      return event?.resource as CalendarGroupEventType;
    }
  }

  return undefined;
};
