/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Flex, Image, Spinner, Stack, StackProps, Text } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import colors from '../../../../../styles/colors';
import { QuestionStateEnum } from './useSpecificQuestions';
import Label from '../../../../common/Label/Label';
import { questionsViewWrapper } from './Questions.styles';
import SpecificQuestionPresenter from './specificQuestionPresenter';
import { Candidate } from '../../../../../firebase/firestore/documents/candidate';
import SpecificQuestionsPresenter from './specificQuestionsPresenter';
import { QuestionType } from '../questions/QuestionType';

const MotionStack = motion.custom(Stack);
const parentVariants = {
  visible: {
    transition: { staggerChildren: 0.05, delayChildren: 0.05 },
  },
  hidden: {
    transition: { staggerChildren: 0.01, staggerDirection: -1 },
  },
};
const childrenVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -200 },
    },
  },
  hidden: {
    y: -10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export type QuestionsProps = StackProps & {
  activeQuestionType: QuestionType;
  candidate?: Candidate;
  initialized: boolean;
  questions: SpecificQuestionsPresenter | undefined;
  state: QuestionStateEnum;
};

export const SpecificQuestions = ({
  className = '',
  padding = 2,
  activeQuestionType,
  initialized,
  questions,
  state,
}: QuestionsProps): JSX.Element => {
  const { t } = useTranslation();

  const getAnswerColor = (question: SpecificQuestionPresenter) =>
    questions?.isRightAnswer(question) ? colors.green['600'] : colors.red['900'];

  const renderQuestionResponse = (question: SpecificQuestionPresenter) => {
    if (question.answered) {
      return (
        <Text
          fontSize="xs"
          bgColor={getAnswerColor(question)}
          color="#fff"
          px={3}
          py={1}
          lineHeight={1.2}
          textAlign="center"
          borderRadius="13px"
          fontWeight="semibold"
        >
          {t(question.answer ? 'questions.answerPositive' : 'questions.answerNegative')}
        </Text>
      );
    }
    return null;
  };

  const questionsCount = questions?.getQuestionsCount() || 0;
  const answeredQuestionsCount = questions?.getAnsweredQuestionsCount() || 0;

  return (
    <Fragment>
      {state === QuestionStateEnum.LOADING && (
        <Center>
          <Spinner color={colors.blue.default} data-testid="Spinner-SpecificQuestion" />
        </Center>
      )}
      {state === QuestionStateEnum.UNDEFINED && activeQuestionType !== QuestionType.ALL_QUESTIONS && (
        <Center>
          <Flex direction="column" data-testid="NoQuestions" align="center" p={3} textAlign="center">
            <Image src="/questions.svg" w="180px" h="auto" alt="No SpecificQuestions" />
            <Text mt="8">
              <b>{t('questions.noAnswers')}</b>
            </Text>
          </Flex>
        </Center>
      )}

      {state === QuestionStateEnum.READY && (
        <MotionStack
          key="CandidateQuestionsMotionStack"
          initial="hidden"
          animate="visible"
          variants={parentVariants}
          spacing={2}
          padding={padding as number}
          className={['user-selectable', className].join(' ')}
          css={questionsViewWrapper}
          data-testid="SpecificQuestions"
        >
          <Flex justify="flex-start" align="baseline">
            {questionsCount > 0 && (
              <Label color={colors.gray['500']} colorDarkMode={colors.gray['600']} fontWeight="600">
                {t('questions.positiveCount', { count: answeredQuestionsCount, total: questionsCount })}
              </Label>
            )}
          </Flex>
          {initialized && (
            <Box border="1px solid" borderColor="gray.50">
              {questions?.questions.map((question, index) => (
                <Box
                  key={question.id}
                  variants={childrenVariants}
                  data-testid={`QuestionsQuestion${question.id}`}
                  px={2}
                  py={2}
                  bg={index % 2 ? 'gray.150' : 'white'}
                  borderBottom={`solid ${index + 1 === questions?.questions.length ? 0 : '1px'}`}
                  borderColor="gray.50"
                >
                  <Flex justify="space-between" direction="row" align="center" textAlign="center">
                    <Text fontSize="sm" textAlign="left" pr={3}>
                      {`${index + 1}. ${question.text}`}
                    </Text>
                    <Box>{renderQuestionResponse(question)}</Box>
                  </Flex>
                </Box>
              ))}
            </Box>
          )}
        </MotionStack>
      )}
    </Fragment>
  );
};
