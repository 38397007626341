import React, { Fragment } from 'react';
import {
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Box,
  Text,
} from '@chakra-ui/core';
import colors from '../../../../../styles/colors';

export type ViewNotesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  noteActionButtonText: string;
  notesHeader: string;
};

export const ViewNotesModal = ({
  isOpen,
  onClose,
  children,
  notesHeader,
  noteActionButtonText,
}: ViewNotesModalProps): JSX.Element => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent data-testid="PreferenceModal" minW="max-content" maxHeight="80vh">
          <ModalHeader className="modal-header" style={{ color: colors.blue.default }}>
            <Text paddingLeft="2rem">{notesHeader}</Text>
          </ModalHeader>
          <ModalCloseButton _focus={{ boxShadow: 'none' }} marginRight="1rem" />
          <ModalBody overflowY="auto" overflowX="hidden">
            <Box>{children}</Box>
          </ModalBody>

          <ModalFooter marginRight="1rem">
            <Button data-testid="ConfirmNoteDeleteButton" colorScheme="red" onClick={onClose}>
              {noteActionButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
