/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Flex, Spacer, Text, CloseButton, IconButton } from '@chakra-ui/core';
import { Room } from 'twilio-video';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline } from 'react-icons/md';
import { MainParticipant } from '../../../meet/MainParticipant';
import { AudioToggleButton } from '../../../meet/twilio/AudioToggleButton';
import { VideoToggleButton } from '../../../meet/twilio/VideoToggleButton';
import { interviewRoomActionBar, interviewRoomBlock, participantBlockStyle, videosWrapper } from './InteviewMeetViewStyle';
import { ReactComponent as _IconCall } from '../../../../assets/img/icon-call.svg';
import { InterviewParticipantList } from './InterviewParticipantList';
import { MediaTypeEnum } from '../../../meet/MediaTypeEnum';
import useInterviewRoomInformation from '../../../guestMeet/useInterviewRoomInformation';
import { useMessagingViewContext } from '../../MessagingViewContext';
import { getTranslatedValue } from '../../../../utils/localizedString';

const IconCall = memo(_IconCall);

type InterviewingRoomProps = {
  room: Room;
  quitRoom: () => void;
  isConnecting: boolean;
  isLoadingLocalTracks: boolean;
};

export const InterviewingRoom = ({ room, quitRoom, isConnecting, isLoadingLocalTracks }: InterviewingRoomProps): JSX.Element => {
  const [showLocationDetail, setShowLocationDetail] = useState<boolean>(true);
  const isLoading = (): boolean => {
    return isLoadingLocalTracks || isConnecting;
  };
  const { i18n } = useTranslation();
  const { selectedEngagedCandidate } = useMessagingViewContext();

  const roomInfo = useInterviewRoomInformation(room.name);

  return (
    <Box css={interviewRoomBlock} className="interview-room-block">
      <Box data-testid="InterviewWindow" position="relative">
        {showLocationDetail && (
          <Flex
            justifyContent="space-between"
            position="absolute"
            top={0}
            left={0}
            w="100%"
            px={5}
            py={4}
            bg="rgba(0, 0, 0, .5)"
            zIndex={1}
            color="#fff"
          >
            <Box>
              <Text fontSize="15px" textTransform="uppercase">
                {selectedEngagedCandidate?.businessName}
              </Text>
              <Text fontSize="16px" fontWeight="700" mb={1}>
                {selectedEngagedCandidate?.jobTitle && getTranslatedValue(selectedEngagedCandidate.jobTitle, i18n.language)}
              </Text>
              <Text fontSize="14px" fontWeight="700">
                {selectedEngagedCandidate?.businessCompleteAddress}
              </Text>
            </Box>
            <Box>
              <CloseButton data-testid="CloseOverlay" size="lg" onClick={() => setShowLocationDetail(!showLocationDetail)} />
            </Box>
          </Flex>
        )}
        {!showLocationDetail && (
          <Box position="absolute" top="15px" right="20px" zIndex="9">
            <IconButton
              className="info-btn"
              variant="outline"
              colorScheme="white"
              color="white"
              border="0"
              aria-label="Location Information"
              fontSize="28px"
              data-testid="ShowCandidateInfoBtn"
              icon={<MdInfoOutline />}
              onClick={() => setShowLocationDetail(!showLocationDetail)}
            />
          </Box>
        )}
        <Box css={videosWrapper} className="video-wrapper interview-with-guest">
          <MainParticipant mediaType={MediaTypeEnum.VIDEO_ONLY} roomInfo={roomInfo} />
          <Box css={participantBlockStyle} className="participants-blocks">
            <InterviewParticipantList room={room} roomInfo={roomInfo} />
          </Box>
        </Box>
        <Flex css={interviewRoomActionBar} align="center" className="interview-room-action-bar">
          <Spacer />
          <Box m={2}>
            <VideoToggleButton isLoading={isLoading()} />
          </Box>
          <Spacer />
          <Box m={2}>
            <AudioToggleButton isLoading={isLoading()} />
          </Box>
          <Spacer />
          <Button className="btn-call-end" onClick={quitRoom} isLoading={isLoading()} isDisabled={isLoading()}>
            <IconCall />
          </Button>
          <Spacer />
        </Flex>
      </Box>
    </Box>
  );
};
