/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, Heading } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { getTranslatedTitle, ParentItemNode } from '../treeGroup';

export type ChildItemProps = {
  item: ParentItemNode;
  isFilterApplied: boolean | undefined;
};

export const ChildItem = ({ item, isFilterApplied }: ChildItemProps): JSX.Element => {
  const { i18n } = useTranslation();

  const getChildCount = (): string => {
    if (isFilterApplied) {
      return '';
    }
    if (!item.childCount) return '';
    return `(${item.childCount})`;
  };

  return (
    <Box className="inner-child-block" data-testid="ChildItem">
      <Heading as="h4" size="sm">
        {`${getTranslatedTitle(item, i18n.language)} ${getChildCount()}`}
      </Heading>
    </Box>
  );
};
