import React, { useEffect, useState, useCallback } from 'react';
import { Box, Tabs, Tab, TabList, TabPanels, TabPanel, Text } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { RiBarChartBoxLine } from 'react-icons/ri';
import BarComparisonChartView, { BarDataType } from '../../recruiterVisualisation/dataVisualizationCharts/BarComparisonChartView';
import { RecruiterProfileDataType } from '../../RecruiterType';

export type RecruiterDetailsViewProps = {
  recruiterMetricsData: RecruiterProfileDataType;
};

export const InterviewAndAvailabilityDetailsView = ({ recruiterMetricsData }: RecruiterDetailsViewProps): JSX.Element => {
  const { t } = useTranslation();

  const [barChartData, setBarChartData] = useState<BarDataType>([]);
  const [comparisonPercentage, setComparisonPercentage] = useState<number>(0);

  const setBarChartDataState = useCallback(
    (givenRecruiterMetricsData: RecruiterProfileDataType) => {
      const { totalInterviews, interviewCompleted, recruiterNoShow, candidateNoShow } = givenRecruiterMetricsData;
      const currentChange = (interviewCompleted.current / totalInterviews.current) * 100 || 0;
      const previousChange = (interviewCompleted.previous / totalInterviews.previous) * 100 || 0;

      const barChartDataToSet: BarDataType = [
        {
          name: t('recruiterManagement:recruiterDataVisualisation.totalAppointments'),
          uv: totalInterviews.current,
          pv: totalInterviews.previous,
        },
        {
          name: t('recruiterManagement:recruiterDataVisualisation.successfulInterviews'),
          uv: interviewCompleted.current,
          pv: interviewCompleted.previous,
        },
        {
          name: t('recruiterManagement:recruiterTable.tableHeader.recruiterNoShow'),
          uv: recruiterNoShow.current,
          pv: recruiterNoShow.previous,
        },
        {
          name: t('recruiterManagement:recruiterTable.tableHeader.candidateNoShow'),
          uv: candidateNoShow.current,
          pv: candidateNoShow.previous,
        },
      ];
      setBarChartData(barChartDataToSet);
      setComparisonPercentage(currentChange - previousChange);
    },
    [t],
  );

  useEffect(() => {
    setBarChartDataState(recruiterMetricsData);
  }, [setBarChartDataState, recruiterMetricsData]);

  return (
    <Box width="100%" height="100%" px={0} pb={5}>
      <Tabs colorScheme="blue" width="100%" height="100%">
        <TabList px={5} border="1px solid #ECEFF1;">
          <Tab pl={0} mr={4} _focus={{ boxShadow: 'none' }}>
            <RiBarChartBoxLine />
            <Text pl={2} fontWeight={700}>
              {t('recruiterManagement:tabHeader.interviews')}
            </Text>
          </Tab>
          {/* <Tab pl={0} _focus={{ boxShadow: 'none' }}>
            <RiCalendar2Line />
            <Text pl={2} fontWeight={700}>
              Availability
            </Text>{' '}
          </Tab> */}
        </TabList>
        <TabPanels width="100%" height="96%">
          <TabPanel width="100%" height="96%">
            <BarComparisonChartView data={barChartData} givenComparisonPercentage={comparisonPercentage} />
          </TabPanel>
          {/* <TabPanel /> */}
        </TabPanels>
      </Tabs>
    </Box>
  );
};
