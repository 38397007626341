import { css } from '@emotion/core';
import colors from '../../styles/colors';

export const meetSessionDetailStyle = css`
  .chakra-modal__header {
    border-bottom: 1px solid #ebedf3;
    padding: 16px 16px;
  }
  .chakra-modal__body {
    padding: 0;
  }
  .drawer-participant-header {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 2px solid #ebedf3;
      li {
        padding: 1rem 1rem;

        &.active {
          border-bottom: 1px solid ${colors.blue[500]};
        }
      }
    }

    .participant-action {
      padding: 16px 16px 0 16px;

      h6 {
      }

      p {
        color: #656565;
      }

      .action-btn {
        min-width: initial;
        width: 24px;
        height: 24px;
        background: #dcdcdc;
        border-radius: 6px;
        border-color: #dcdcdc;
        color: ${colors.blue[500]};
        font-weight: bold;
      }
    }
  }
  .drawer-participant-list {
    padding: 16px;
    .drawer-participant {
      padding: 6px 0;
    }
  }

  .drawer-track-indicator {
    .icon-box {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        max-height: 14px;
        max-width: 14px;
      }
    }
  }
`;
