import React from 'react';
import { Menu, MenuButton, Button, useDisclosure } from '@chakra-ui/core';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { PositionFromPositionList } from '../PositionType';
import PositionRequisitions from './PositionRequisitions';
import { ViewRequisitionDetail } from '../../../messaging/seeker/candidate/history/ViewRequisitionDetail';
import { Requisition } from '../../../../firebase/firestore/documents/requisition';

export type PositionRequisitionsProps = {
  position: PositionFromPositionList;
  isLastItem: boolean;
};

export const PositionRequisitionMenuButton = ({ position, isLastItem }: PositionRequisitionsProps): JSX.Element => {
  const { t } = useTranslation();
  const [requisition, setRequisition] = React.useState<Requisition | undefined>(undefined);
  const { isOpen: isOpenRequisition, onOpen: onOpenRequisition, onClose: onCloseRequisition } = useDisclosure();
  return (
    <>
      <Menu placement={isLastItem ? 'top' : 'bottom'}>
        <>
          <MenuButton
            as={Button}
            rightIcon={<RiArrowDownSLine />}
            size="sm"
            background="#edf2f7"
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="1px"
            border="none"
            mr={2}
            data-testid={`RequisitionsButton-${position.id}`}
            _hover={{ bg: '#e2e8f0' }}
            _expanded={{ bg: '#1F3CBA', color: '#fff' }}
          >
            {t('common:requisitions')}
          </MenuButton>
          <PositionRequisitions positionId={position.id} onOpenRequisition={onOpenRequisition} setRequisition={setRequisition} />
        </>
      </Menu>
      {isOpenRequisition && (
        <ViewRequisitionDetail
          isOpen={isOpenRequisition}
          onClose={onCloseRequisition}
          shouldCompare={false}
          requisition={requisition}
          showReqStatus
        />
      )}
    </>
  );
};
