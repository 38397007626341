import { Button, ButtonProps, Tooltip } from '@chakra-ui/core';
import React from 'react';
import { HiRefresh } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useFetchImageGallery } from './hooks/useFetchImageGallery';
import { useStoreState } from '../../../models/hooks';
import theme from '../../../styles/customTheme';

interface ImageGalleryRefreshButtonProps extends Pick<ButtonProps, 'size' | 'variant' | 'colorScheme'> {
  iconSize?: number;
}

/**
 * A button that allows the user to refetch the image gallery.
 *
 * It takes the following props:
 *
 * - `size`: The size of the button. Defaults to `lg`.
 * - `variant`: The variant of the button. Defaults to `outline`.
 * - `colorScheme`: The color scheme of the button. Defaults to `gray`.
 * - `iconSize`: The size of the icon. Defaults to `24px`.
 *
 * The button is disabled if the gallery is currently loading and is replaced
 * with a spinner if the gallery is currently refetching.
 */
export default function ImageGalleryRefreshButton({
  size = 'lg',
  variant = 'outline',
  colorScheme = 'gray',
  iconSize,
}: ImageGalleryRefreshButtonProps) {
  const { fetchGallery } = useFetchImageGallery({ disableInitialFetch: true });
  const { isGalleryRefetching, isGalleryLoading } = useStoreState((state) => state.imageGallery);

  const { t } = useTranslation();

  return (
    <Tooltip hasArrow label={t('administration:imageGalleryModal:refreshButtonToolTip')} borderRadius={theme.radii.base}>
      <Button
        px={2}
        onClick={() => fetchGallery(true)}
        isDisabled={isGalleryLoading}
        isLoading={isGalleryRefetching}
        data-testid="ImageGalleryRefreshButton"
        {...{ size, variant, colorScheme }}
      >
        <HiRefresh size={iconSize} />
      </Button>
    </Tooltip>
  );
}

ImageGalleryRefreshButton.defaultProps = {
  iconSize: 24,
};
