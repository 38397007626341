import { Collections } from '../../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../../firebase/firestore/hooks';
import { Query } from '../../../firebase/firestore/query/query';
import { GroupEventConfiguration } from '../../../firebase/firestore/documents/groupEvent';
import { BUNDLE_ID_GLOBAL, KNOCKED_OUT_AUTOMATIC_DISMISSAL_HOURS_DELAY } from '../../../firebase/firestore/constants';

export const useHoursToAutomaticDismissal = () => {
  const hoursToDissmisal = useQuerySnapshot<GroupEventConfiguration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_GLOBAL),
    Query.field('name').equals(KNOCKED_OUT_AUTOMATIC_DISMISSAL_HOURS_DELAY),
  ]);
  if (hoursToDissmisal) {
    return hoursToDissmisal[0]?.value;
  }
  return undefined;
};
