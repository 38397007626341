import { css } from '@emotion/core';
import colors from '../../../styles/colors';

export const AudioPlayerCss = css`
  width: 100%;

  .progress {
    margin: 5px 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .progressCenter {
    display: flex;
    background-color: #edf2f7;
    border-radius: 10px;
    flex: 1;
    alignitems: center;
    padding: 0.6rem 1rem;
    align-items: center;
  }

  .progressSlider:hover {
    cursor: pointer;
  }

  .currentTimeDuration {
    color: #44606b;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin: 16px;
  }

  .sliderThumb:focus,
  .sliderThumb {
    box-shadow: 0 0 0 3px ${colors.persianBlue};
  }

  .currentDurationText {
    margin-right: 0;
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 50px;
  }
  .opt {
    border: none;
    background-color: transparent;
    font-size: 25px;
    height: auto;
    width: auto;
    border-radius: 50%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.4s;
  }
  .opt:hover {
    cursor: pointer;
    box-shadow: 3px 3px 5px #cccccc, -3px 0px 5px #cccccc;
    color: #147cc0;
  }
`;
