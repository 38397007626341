import { AsYouTypeFormatter } from 'google-libphonenumber';

const removeUnwantedChar = (value: string) => `${value.replace(/\D/g, '')}`;

export const phoneFormat = (value: string, countryCode?: string): string => {
  const formatter = new AsYouTypeFormatter(countryCode || 'US');
  let formattedValue = '';
  const parsedValue: any = removeUnwantedChar(value);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const chars = [...parsedValue];
  chars.forEach((c) => {
    formattedValue = formatter.inputDigit(c);
  });

  return formattedValue;
};
