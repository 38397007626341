import { CalendarParticipantType } from './CalendarParticipantType';
import { CalendarGroupEventType } from '../CalendarGroupEventType';

export class EventDetailPresenter {
  constructor(
    readonly calendarEvent: CalendarGroupEventType | undefined,
    readonly recruiters: CalendarParticipantType[],
    readonly candidates: CalendarParticipantType[],
  ) {}
}
