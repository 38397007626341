/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/core';
import { isMobile } from 'react-device-detect';
import _takeRight from 'lodash/takeRight';
import { PositionState } from '../../../../../common';
import { seekerInfoPositions } from './PositionStatesOverview.styles';
import { CandidateStatusPresenter } from '../seekerInfoPresenter';

export type PositionStatesOverviewProps = FlexProps & {
  statuses?: CandidateStatusPresenter[];
};

export const PositionStatesOverview = ({ statuses, ...rest }: PositionStatesOverviewProps): JSX.Element => {
  const statusesToRender = isMobile ? _takeRight(statuses, 8) : statuses;

  return (
    <Flex css={seekerInfoPositions} flexWrap="wrap" {...rest} mb={2}>
      {statusesToRender && statusesToRender.map((stat) => <PositionState key={stat.key} status={stat.status} />)}
    </Flex>
  );
};
