import React, { CSSProperties, memo } from 'react';
import { motion } from 'framer-motion';
import { Box, Tooltip } from '@chakra-ui/core';

import { useTranslation } from 'react-i18next';
import { ReactComponent as _PositionStateDot } from '../../../assets/img/position-state.svg';
import { CandidateStatus } from '../../../firebase/firestore/documents/candidate';
import colors from '../../../styles/colors';

export const animatedVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transition: {
      duration: 0.8,
    },
  },
};

const PositionStateDot = memo(_PositionStateDot);

const getColorForState = (status: CandidateStatus): CSSProperties => {
  switch (status) {
    case CandidateStatus.Dismissed:
    case CandidateStatus.Blacklisted:
      return { color: colors.red['900'] };
    case CandidateStatus.ContactRequested:
      return { color: colors.gray['100'] };
    case CandidateStatus.Bookmarked:
      return { color: colors.gray['100'] };
    default:
      return { color: colors.blue.default };
  }
};

export type PositionStateProps = {
  status: CandidateStatus;
  isDeactivated?: boolean;
  style?: CSSProperties;
};

const MotionBox = motion.custom(Box);

const PositionState = ({ status, isDeactivated, style, ...rest }: PositionStateProps): JSX.Element => {
  const { t } = useTranslation('candidateStatus');

  const compositeStyle: CSSProperties = {
    ...style,
    ...getColorForState(status),
  };
  const compositeStyleDeactivated: CSSProperties = {
    ...style,
    ...{ color: colors.gray[500] },
  };
  return (
    <MotionBox variants={animatedVariants} data-testid="position-state-dot" style={{ cursor: 'pointer' }}>
      <Tooltip label={t(`${status}`)}>
        <PositionStateDot
          style={isDeactivated ? compositeStyleDeactivated : compositeStyle}
          data-testid={status}
          title=""
          {...rest}
        />
      </Tooltip>
    </MotionBox>
  );
};

export default PositionState;
