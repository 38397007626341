// eslint-disable-next-line import/prefer-default-export
export enum FirestoreOperation {
  EQUAL = '==',
  SMALLER = '<',
  SMALLER_OR_EQUAL = '<=',
  GREATER = '>',
  GREATER_OR_EQUAL = '>=',
  ARRAY_CONTAINS = 'array-contains',
  IN = 'in',
}
