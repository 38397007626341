import { Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmMessageType, PositionFromPositionList } from '../PositionType';

export type UpdateStatusConfirmationProps = {
  isOpen: boolean;
  onClose: () => void;
  message: ConfirmMessageType;
  updatePositionByStatus: (position: PositionFromPositionList) => void;
  selectedPosition: PositionFromPositionList;
  isPositionOpen?: boolean;
  // checkBoxComponent: ReactNode;
};

export const UpdateStatusConfirmation = ({
  isOpen,
  onClose,
  message,
  selectedPosition,
  updatePositionByStatus,
  isPositionOpen,
}: UpdateStatusConfirmationProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{message.headerMessage}</ModalHeader>
          <ModalBody whiteSpace="pre-line">
            {message.bodyMessage}
            <Flex paddingTop=" 10px">
              {isPositionOpen && (
                <Flex justifyContent="space-between" alignItems="flex-start">
                  {/* <Box paddingTop="5px" pr={3}>
                    {checkBoxComponent}
                  </Box> */}
                  <span>{t('jobPosition:closePositionModal.confirmationText')}</span>
                </Flex>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter mt={2}>
            <Button data-testid="ConfirmBtn" colorScheme="blue" mr={3} onClick={() => updatePositionByStatus(selectedPosition)}>
              {message.confirmText}
            </Button>
            <Button data-testid="CloseBtn" colorScheme="red" onClick={onClose}>
              {message.cancelText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

UpdateStatusConfirmation.defaultProps = {
  isPositionOpen: false,
};
