import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { GroupEventConfiguration } from '../../firebase/firestore/documents/groupEvent';
import { Collections } from '../../firebase/firestore/collections';
import { BUNDLE_ID_SERVICES, VIDEO_INTERVIEW_END_GRACE_PERIOD_IN_MINUTES } from '../../firebase/firestore/constants';
import { Query } from '../../firebase/firestore/query/query';

export const useVideoInterviewEndGracePeriodInMinutes = (): number | undefined => {
  const videoInterviewEndGracePeriodInMinutes = useQuerySnapshot<GroupEventConfiguration>(Collections.CONFIGURATION, [
    Query.field('bundleId').equals(BUNDLE_ID_SERVICES),
    Query.field('name').equals(VIDEO_INTERVIEW_END_GRACE_PERIOD_IN_MINUTES),
  ]);
  if (videoInterviewEndGracePeriodInMinutes) {
    return videoInterviewEndGracePeriodInMinutes[0]?.value;
  }
  return undefined;
};
