import React, { useState } from 'react';
import { Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Spinner } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

import { NotificationHeaderTypeEnum } from './NotificationTypeEnum';
import { NotificationView } from './NotificationView';
import { StoriesView } from './StoriesView';
import { notificationPanelHeaderStyle } from '../Header.styles';

export type NotificationPanelProps = {
  isOpen: boolean;
  onClose: () => void;
  unreadNotificationNumber: (num: number) => void;
};

export const NotificationPanel = ({ isOpen, onClose, unreadNotificationNumber }: NotificationPanelProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<NotificationHeaderTypeEnum>(NotificationHeaderTypeEnum.NOTIFICATION);
  const { t, i18n } = useTranslation();
  const [showNotificationView, setShowNotificationView] = useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNotificationView(true);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [showNotificationView]);

  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="md">
      <DrawerOverlay />
      <DrawerContent data-testid="NotificationDrawer">
        <DrawerHeader css={notificationPanelHeaderStyle}>
          <Flex fontSize="md" fontWeight="bold">
            <Box
              onClick={() => setActiveTab(NotificationHeaderTypeEnum.NOTIFICATION)}
              className={`tab-button ${activeTab === NotificationHeaderTypeEnum.NOTIFICATION ? 'activeTab' : ''}`}
              data-testid="changeButtonToNotifications"
            >
              {t('notification:notifications')}
            </Box>
            <Box
              onClick={() => setActiveTab(NotificationHeaderTypeEnum.STORIES)}
              className={`tab-button ${activeTab === NotificationHeaderTypeEnum.STORIES ? 'activeTab' : ''}`}
              data-testid="changeButtonToStories"
            >
              {t('notification:updates')}
            </Box>
          </Flex>
        </DrawerHeader>
        <DrawerBody p={0}>
          {showNotificationView ? (
            activeTab === NotificationHeaderTypeEnum.NOTIFICATION && (
              <NotificationView unreadNotificationNumber={unreadNotificationNumber} onClose={onClose} />
            )
          ) : (
            <Flex justifyContent="center" alignItems="center" height="100px">
              <Spinner color="blue.500" />
            </Flex>
          )}
          {activeTab === NotificationHeaderTypeEnum.STORIES && (
            <StoriesView language={i18n.language} unreadNotificationNumber={unreadNotificationNumber} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
