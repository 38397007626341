import React, { ChangeEvent } from 'react';
import { Box, Text, Stack, Checkbox } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchAndSortTypes } from '../filters/seekersFiltersContextValueType';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';

export enum HireDetailsStatus {
  REQUESTED = 'requested', // recruiter has requested the candidate to fill up the hire form.
  IN_PROGRESS = 'inProgress', // candidate has started to fill up the hire form.
  HELP_REQUESTED = 'helpRequested', // candidate has requested help in filling up the form.
  COMPLETED = 'completed', // candidate has finished filling up the hire form.
  NEEDS_ACTION = 'needsAction', // recruiter has requested the candidate to update the hire form data.
}
export const HireStatus = (): JSX.Element => {
  const { t } = useTranslation('hireDetail');

  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();

  const onHandleBackgroundCheck = (searchAndSort: SearchAndSortTypes) => {
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...searchAndSort });
  };

  return (
    <Box>
      <Stack direction="column">
        {Object.values(HireDetailsStatus).map((value) => (
          <Checkbox
            data-testid={`hireStatus_${value}`}
            key={`${value}`}
            isChecked={searchAndSortTypes.hireStatus.includes(value.toLowerCase())}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                searchAndSortTypes.hireStatus.push(value.toLowerCase());
              } else {
                const index = searchAndSortTypes.hireStatus.indexOf(value.toLowerCase());
                searchAndSortTypes.hireStatus.splice(index, 1);
              }
              onHandleBackgroundCheck(searchAndSortTypes);
            }}
          >
            <Text
              px={3}
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
              }}
            >
              {t(`status.${value}`)}
            </Text>
          </Checkbox>
        ))}
      </Stack>
    </Box>
  );
};
