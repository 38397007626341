import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/table';
import { Flex, Box, Text, ScaleFade, Spinner } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, Column } from 'react-table';
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti';
import { AiOutlineUsergroupDelete } from 'react-icons/ai';
import { MdAdminPanelSettings, MdOutlineAdminPanelSettings } from 'react-icons/md';
import { GoPrimitiveDot } from 'react-icons/go';
import {
  mainTable,
  sortIconWrapper,
  tableHeading,
} from '../recruiterManagement/recruiterDashboard/recruitersInfoTable/RecruiterInfoTableStyle';
import { TeamMemberStateEnum } from './useTeamMembers';
import { TeamMemberAction } from './TeamType';
import { useStoreState } from '../../../models/hooks';
import colors from '../../../styles/colors';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';

export type TeamInfoTableViewProps<Data extends Record<string, unknown>> = {
  data: Data[];
  columns: Column<Data>[];
  isLoading: TeamMemberStateEnum;
  handleTeamMember: (teamMember: Data, action: TeamMemberAction) => void;
};

export default function TeamInfoTableView<Data extends Record<string, unknown>>({
  data,
  columns,
  isLoading,
  handleTeamMember,
}: TeamInfoTableViewProps<Data>) {
  const { t } = useTranslation('team');
  const { accounts } = useStoreState((state) => state.app);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
      },
    },
    useSortBy,
  );

  const isAdmin = (userId: string) => {
    return accounts?.owners?.includes(userId);
  };
  const { currentUserProfile } = useCurrentUserProfile();

  const canChangeRole = (): boolean => {
    if (!currentUserProfile?.id) {
      return false;
    }

    const { superOwners, owners } = accounts ?? /* istanbul ignore next */ {};

    return (
      (superOwners?.includes(currentUserProfile.id) || owners?.includes(currentUserProfile.id)) ??
      /* istanbul ignore next */ false
    );
  };

  return (
    <ScaleFade initialScale={0.7} in unmountOnExit={false}>
      <Table {...getTableProps()} css={mainTable} className="mainTable gray-table" key="mainTable">
        <Thead css={tableHeading} className="tableHeading" pl="4">
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} textAlign="left" key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                  key={column.getHeaderProps().key}
                  paddingLeft="1rem"
                >
                  <Box style={{ display: 'flex', maxWidth: '100%' }}>
                    <Text display="inline">{column.render('Header')}</Text>
                    <Box pl="4" display="inline" css={sortIconWrapper} className="sortIconWrapper">
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {column.isSorted ? (
                        // istanbul ignore next
                        column.isSortedDesc ? (
                          <TiArrowSortedUp aria-label="sorted descending" color="#BCC2CE" style={{ display: 'inline' }} />
                        ) : (
                          <TiArrowSortedDown aria-label="sorted ascending" color="#BCC2CE" style={{ display: 'inline' }} />
                        )
                      ) : (
                        <TiArrowUnsorted color="#BCC2CE" style={{ display: 'inline' }} data-testid="sortedAscendingBtn" />
                      )}
                    </Box>
                  </Box>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading === TeamMemberStateEnum.LOADING ? (
            <Tr>
              <Td colSpan={4} style={{ padding: '20px', textAlign: 'center' }}>
                <Spinner />
              </Td>
            </Tr>
          ) : rows.length > 0 ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  style={{ height: '40px' }}
                  key={row.getRowProps().key}
                  cursor="pointer"
                  _hover={{ background: 'lightgrey', opacity: '0.95' }}
                >
                  {row.cells.map((cell) => (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      pr=""
                      key={cell.getCellProps().key}
                      paddingLeft="1rem"
                      py={3}
                    >
                      {cell.column.id === 'name' && (
                        <Box>
                          <Text className="team-member-name-cell name" data-testid="teamNameCell">
                            {cell.render('Cell')}
                          </Text>
                          <Text color="blue.500" fontSize="sm">
                            {isAdmin(cell.row.original.id as string) ? t('admin') : ''}
                          </Text>
                        </Box>
                      )}

                      {cell.column.id === 'status' && (
                        <Text
                          className="team-member-status"
                          d="flex"
                          alignItems="center"
                          textTransform="capitalize"
                          color="green.500"
                        >
                          <GoPrimitiveDot />
                          {cell.value}
                        </Text>
                      )}
                      {cell.column.id === 'action' && (
                        <Flex justifyContent="end">
                          {canChangeRole() && (
                            <Text
                              data-testid={`TeamAction-${cell.row.original.id}`}
                              className="team-member-action styleForNumbers"
                              style={{ display: 'flex', alignItems: 'center' }}
                              onClick={() => {
                                handleTeamMember(
                                  cell.row.original,
                                  isAdmin(cell.row.original.id as string) ? TeamMemberAction.DEMOTE : TeamMemberAction.PROMOTE,
                                );
                              }}
                              mr={3}
                            >
                              {isAdmin(cell.row.original.id as string) ? (
                                <MdAdminPanelSettings style={{ height: '22px', width: '22px', color: `${colors.blue['500']}` }} />
                              ) : (
                                <MdOutlineAdminPanelSettings style={{ height: '22px', width: '22px' }} />
                              )}
                            </Text>
                          )}
                          <Text
                            data-testid={`TeamDelete-${cell.row.original.id}`}
                            className="team-member-delete styleForNumbers"
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={() => {
                              handleTeamMember(cell.row.original, TeamMemberAction.DELETE);
                            }}
                          >
                            <AiOutlineUsergroupDelete style={{ height: '22px', width: '22px' }} />
                          </Text>
                        </Flex>
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={4} style={{ padding: '20px', textAlign: 'center' }}>
                <Text>{t('noData')}</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </ScaleFade>
  );
}
