import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { InviteCollaborators } from '../modules/calendar/models/InviteCollaboratorsTypes';

export class InviteCollaboratorsController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async sendInvitation(invitation: InviteCollaborators): Promise<AxiosResponse> {
    const path = `/api/v1/video-interview/invite`;
    return this.httpClient.post(path, invitation);
  }

  async getInvitationLink(invitation: InviteCollaborators): Promise<AxiosResponse> {
    const path = `/api/v1/video-interview/invite/link?bookingId=${invitation.bookingId}&appointmentAvailabilityId=${invitation.appointmentAvailabilityId}&candidateId=${invitation.candidateId}&locale=${invitation.locale}`;
    return this.httpClient.get(path);
  }

  async getCollaboratorsInvite(bookingId: string): Promise<AxiosResponse> {
    const path = `/api/v1/video-interview/booking/${bookingId}`;
    return this.httpClient.get(path);
  }
}
