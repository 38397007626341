import Bugsnag from '@bugsnag/js';
import _mapValues from 'lodash/mapValues';
import { Collections, SubCollections } from '../collections';

export const safeStringify = (value: Record<string, unknown>): string => {
  try {
    return JSON.stringify(value);
  } catch {
    return 'Unable to stringify value';
  }
};

export const reportSnapshotError = (
  queryType: string,
  collections: Collections,
  query: Record<string, unknown>,
  subCollections?: SubCollections,
) => (error: Error): void => {
  Bugsnag.notify(error, (event) => {
    let message = `Firestore Snapshot Error ${queryType} ${collections}`;
    if (subCollections) message = `${message}.${subCollections}`;
    // eslint-disable-next-line no-param-reassign
    event.context = message;

    let groupHash = `${queryType}.${collections}`;
    if (subCollections) groupHash = `${groupHash}.${subCollections}`;
    // eslint-disable-next-line no-param-reassign
    event.groupingHash = groupHash;

    event.addMetadata('query', _mapValues(query, safeStringify));
  });
};

export const reportFirestoreOperationError = (
  operation: string,
  collections: Collections,
  payload: Record<string, unknown>,
  subCollections?: SubCollections,
) => (error: Error): void => {
  Bugsnag.notify(error, (event) => {
    let message = `Firestore ${operation} Error on ${collections}`;
    if (subCollections) {
      message = `${message}.${subCollections}`;
    }

    // eslint-disable-next-line no-param-reassign
    event.context = message;
    let groupHash = `${operation}.${collections}`;
    if (subCollections) groupHash = `${groupHash}.${subCollections}`;
    // eslint-disable-next-line no-param-reassign
    event.groupingHash = groupHash;

    event.addMetadata('payload', _mapValues(payload, safeStringify));
  });
};
