import { css } from '@emotion/core';
import colors from '../../../styles/colors';

export const bookingDrawer = css`
  .chakra-modal__header {
    .chakra-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 35px;
      color: ${colors.blue[800]};
    }
  }
  .booking-title {
    background-color: ${colors.blue[50]};

    .chakra-heading {
      color: ${colors.blue[800]};
    }
  }
  .experience-block {
    background-color: ${colors.blue[50]};

    .chakra-stack > div {
      border-color: transparent;
      width: 100%;

      hr {
        padding: 0;
      }
    }
  }
  .communicate-badge a {
    background-color: ${colors.blue.default};
    color: ${colors.white};
    border-radius: 3px;
    padding: 3px 8px;
  }

  .chakra-accordion__item {
    border: 0;
  }

  .chakra-accordion__button {
    padding: 15px 15px;
    outline: none;
    box-shadow: none;
  }

  .accordion-collapsed {
    .drop-arrow {
      transition: all 0.35s;
      transform: rotate(0deg);
    }
    .experience-block {
      overflow: hidden;
      padding: 0 15px;
      max-height: 0;
      transition: all 0.35s;
    }
  }

  .accordion-opened {
    .drop-arrow {
      transition: all 0.35s;
      transform: rotate(180deg);
    }
    .experience-block {
      transition: all 0.35s;
      padding: 15px;
      max-height: 100vh;
    }
  }
`;
