import { css } from '@emotion/core';
import colors from '../../styles/colors';
import { headerHeight } from '../../styles/sizes';

export const headerGradientCSS = css`
  grid-column: leftSide / rightSide;
  grid-row: top / header;
  box-sizing: border-box;
  height: ${headerHeight};
  background: linear-gradient(270.02deg, ${colors.blue.a50} -6.62%, ${colors.blue.default} 99.45%);
  overflow-y: hidden;

  .chakra-ui-dark & {
    background-color: ${colors.gray['900']};
    background-image: none;
  }
`;

export const headerGradientCSSWhenSortAndSortIsOpen = css`
  grid-column: search / rightSide;
  grid-row: top / header;
  box-sizing: border-box;
  height: ${headerHeight};
  background: linear-gradient(270.02deg, ${colors.blue.a50} -6.62%, ${colors.blue.default} 99.45%);
  overflow-y: hidden;

  .chakra-ui-dark & {
    background-color: ${colors.gray['900']};
    background-image: none;
  }
`;

export const notificationListCSS = css`
  li {
    position: relative;
    background-color: #F3FBFF;

    &.read-notification {
    background-color: #fff;
  }
  .badge {
    background: #CFD8DC;
    border-radius: 9px;
    text-transform: initial;
    font-weight: normal;
    padding: 0 15px;
    line-height: 1.5;
    height: 18px;
`;

export const notificationCount = css`
  position: absolute;
  top: -6px;
  right: -5px;
  border-radius: 50px;
  height: 22px;
  width: 22px;
  background-color: #ff6600;
  border: 2px solid #2ab1ef;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: none;
`;

export const tabButtonList = css`
   .tab-button {
   cursor: pointer;
   white-space: nowrap;
    &.activeTab {
      background-color: ${colors.blue['50']};
      color: ${colors.blue.default};
      font-weight: bold;
  }
`;

export const notificationPanelHeaderStyle = css`
  border-bottom: 1px solid #f5f5f5;
  padding: 0 1.2rem;
  cursor: pointer;
  .tab-button {
    padding: 1rem 0;
    margin-right: 1.2rem;
    background-color: #fff;
    border-bottom: 2px solid #fff;

    &.activeTab {
      border-color: ${colors.blue.default};
      color: ${colors.blue.default};

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const badgeStatusCSS = css`
   {
    text-transform: capitalize;
    line-height: 1.8;
    padding: 0.25em 0.5em;
    border-radius: 0.125rem;
    width: 115px;
    text-align: center;

    font-size: 14px;
    &.requested {
      color: #3683b3;
      background-color: #c4e6fc;
    }
    &.accepted {
      color: #5c995e;
      background-color: #d3eadf;
    }
    &.inactive {
      color: #4a5568;
      background-color: #cfd8dc;
    }
    &.closed {
      color: #607d8b;
      border: 1px solid #607d8b;
    }
    &.cancelled,
    &.declined,
    &.revoked {
      color: #b33636;
      background-color: #fad9d9;
    }
    &.acceptedWithMessage {
      color: #9f9324;
      background-color: #fffbd6;
    }
  }
`;

export const automatedSvgCSS = css`
  .automated-icon path {
    fill: #ff6600;
    fill-opacity: 0.85;
  }
`;

export const menuItemCss = css`
  z-index: 12;
  .menuItem {
    border-radius: 8px;
    background: #ffffff;
  }
  .menuItem:hover {
    background: ${colors.menu.menuItemhoverColor};
    font-weight: bold;
  }
`;
