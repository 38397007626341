export enum HireFormTypeEnum {
  NEW_HIRE = 'NEW_HIRE',
  SEASONAL = 'SEASONAL',
}

export type HireFormType = {
  label: string;
  value: string;
};

export type RequestHireDetailForm = {
  type?: string;
  positionName: string;
  note: string | null;
  startingDate?: Date;
  wage: {
    unit: string;
    value: string;
    customUnit?: string | null;
  };
  fullTime?: boolean;
  partTimeHours?: string;
  contractStartDate?: Date;
  endDate?: Date;
  businessCountry?: string;
  contractDetailsWordings?: string;
  business: Business;
  updatedBusinessId?: string;
  updatedPositionId?: string;
  requisitionId?: string;
};

export type Business = {
  name?: string;
  country?: string;
  address?: string;
  locationId?: string;
};

export type HireFormErrorResponse = {
  code: string;
  status: number;
  title: string;
  traceId: string;
};
