import React, { useContext, useEffect, useState } from 'react';
import _noop from 'lodash/noop';
import SeekerListSortType from './seekerListSortType';
import SeekerListGroupType from './seekerListGroupType';
import {
  CandidateListType,
  EngagementTypes,
  PositionWiseFilterTypes,
  SearchAndSortTypes,
  SeekersFiltersContextValueType,
} from './seekersFiltersContextValueType';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';

export const initialValueEngagementTypes: EngagementTypes = {
  apointmentRequested: '',
  Hired: '',
  offerMade: '',
  offerRejected: '',
  offerAccepted: '',
  appoinmentAccepted: '',
  appoinmentDeclined: '',
  appoinmentCancled: '',
  noAppointmentRequest: '',
  interestRevoked: '',
  contactRequestDeclined: '',
};

export const initialtValueSearchAndSortTypes: SearchAndSortTypes = {
  isInterventionEnabled: false,
  isFavouriteApplied: false,
  isPriorityCandidates: false,
  kioskEnabled: false,
  backgroundCheck: [],
  hireStatus: [],
  languageEvaluation: [],
  availabilityFit: [],
  requisitionList: [],
  modernHireStatus: [],
  availability: {
    day: false,
    evening: false,
    night: false,
  },
  candidateEngagement: initialValueEngagementTypes,
  rating: [0, 0, 0, 0, 0],
};

export const initialValue: SeekersFiltersContextValueType = {
  sort: SeekerListSortType.ALPHABETICAL,
  group: SeekerListGroupType.NONE,
  warningMessage: true,
  updateSort: _noop,
  updateGroup: _noop,
  updateWarningMessage: _noop,
  searchQuery: '',
  setSearchQuery: _noop,
  ghostedListView: false,
  setGhostedListView: _noop,
  favoriteCandidateView: false,
  setFavoriteCandidateView: _noop,
  archiveListView: false,
  setArchiveListView: _noop,
  isFromSearchAndSort: false,
  setIsFromSearchAndSort: _noop,
  searchAndSortTypes: initialtValueSearchAndSortTypes,
  setSearchAndSortTypes: _noop,
  setOpenFilterByPositionList: _noop,
  openFilterByPositionList: undefined,
  setIsPositionFilterApplied: _noop,
  isPositionFilterApplied: false,
  setSelectedPositionIdForFilter: _noop,
  selectedPositionIdForFilter: '',
  lengthOfMappedSeekers: 0,
  setLengthOfMappedSeekers: _noop,
  dismissListView: false,
  setDismissListView: _noop,
  listType: CandidateListType.UNKNOWN,
  setListType: _noop,
  changeCandidate: true,
  setChangeCandidate: _noop,
};
export const SeekersFiltersContext = React.createContext<SeekersFiltersContextValueType>(initialValue);

type ProviderProps = {
  children: JSX.Element | React.ComponentType;
};

export const SESSION_KEY_CANDIDATE_LIST_TYPE = 'session_key_candidate_list_type';

export const SeekersFiltersContextProvider: React.FC = ({ children }) => {
  const [sort, setSort] = useState<SeekerListSortType>(initialValue.sort);
  const [group, setGroup] = useState<SeekerListGroupType>(initialValue.group);
  const [searchQuery, setSearchQuery] = useState<string>(initialValue.searchQuery);
  const [ghostedListView, setGhostedListView] = useState<boolean>(false);
  const [changeCandidate, setChangeCandidate] = useState<boolean>(initialValue.changeCandidate);
  const [lengthOfMappedSeekers, setLengthOfMappedSeekers] = useState<number>(initialValue.lengthOfMappedSeekers);
  const [listType, setListType] = useState<CandidateListType>(initialValue.listType);
  const [selectedPositionIdForFilter, setSelectedPositionIdForFilter] = useState<string>(
    initialValue.selectedPositionIdForFilter,
  );
  const [isPositionFilterApplied, setIsPositionFilterApplied] = useState<boolean>(false);
  const [favoriteCandidateView, setFavoriteCandidateView] = useState<boolean>(false);
  const [isFromSearchAndSort, setIsFromSearchAndSort] = useState<boolean>(initialValue.isFromSearchAndSort);
  const [searchAndSortTypes, setSearchAndSortTypes] = useState<SearchAndSortTypes>(initialValue.searchAndSortTypes);
  const [openFilterByPositionList, setOpenFilterByPositionList] = useState<Map<string, PositionWiseFilterTypes> | undefined>(
    undefined,
  );
  const [archiveListView, setArchiveListView] = useState<boolean>(false);
  const [dismissListView, setDismissListView] = useState<boolean>(initialValue.dismissListView);

  const isShowWarningOnHiringCandidateFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.SHOW_WARNING_ON_HIRING_CANDIDATE_FEATURE];
  });

  const [warningMessage, setWarningMessage] = useState<boolean>(
    // istanbul ignore next
    isShowWarningOnHiringCandidateFeatureEnabled ? initialValue.warningMessage : false,
  );

  const { currentUserProfile } = useCurrentUserProfile();
  const { updateConfig } = useStoreActions((actions) => actions.user);

  useEffect(() => {
    if (currentUserProfile && currentUserProfile.config) {
      const { selectedGroup, selectedSort, showWarningOnCandidateHire } = currentUserProfile.config;
      setSort(selectedSort ?? SeekerListSortType.ALPHABETICAL);
      setGroup(selectedGroup ?? SeekerListGroupType.NONE);
      // istanbul ignore next
      setWarningMessage(isShowWarningOnHiringCandidateFeatureEnabled ? showWarningOnCandidateHire ?? true : false);
    }
    const candiadteListType = sessionStorage.getItem(SESSION_KEY_CANDIDATE_LIST_TYPE);
    if (candiadteListType) {
      if (candiadteListType === CandidateListType.ARCHIVED_CANDIDATE_LIST) {
        setArchiveListView(true);
        setListType(CandidateListType.ARCHIVED_CANDIDATE_LIST);
      } else if (candiadteListType === CandidateListType.GHOSTED_CANDIDATE_LIST) {
        setGhostedListView(true);
        setListType(CandidateListType.GHOSTED_CANDIDATE_LIST);
      } else if (candiadteListType === CandidateListType.DISMISS_CANDIDATE_LIST) {
        setDismissListView(true);
        setListType(CandidateListType.DISMISS_CANDIDATE_LIST);
      } else if (candiadteListType === CandidateListType.FAVOURITE_CANDIDATE_LIST) {
        setFavoriteCandidateView(true);
        setListType(CandidateListType.FAVOURITE_CANDIDATE_LIST);
      } else if (candiadteListType === CandidateListType.UNKNOWN) {
        setListType(CandidateListType.UNKNOWN);
      }
    }
  }, [currentUserProfile, updateConfig, isShowWarningOnHiringCandidateFeatureEnabled]);

  useEffect(() => {
    return () => {
      searchAndSortTypes.isFavouriteApplied = false;
      searchAndSortTypes.isInterventionEnabled = false;
      searchAndSortTypes.isPriorityCandidates = false;
      searchAndSortTypes.kioskEnabled = false;
      searchAndSortTypes.backgroundCheck = [];
      searchAndSortTypes.hireStatus = [];
      searchAndSortTypes.languageEvaluation = [];
      searchAndSortTypes.availabilityFit = [];
      searchAndSortTypes.modernHireStatus = [];
      searchAndSortTypes.candidateEngagement.apointmentRequested = '';
      searchAndSortTypes.candidateEngagement.Hired = '';
      searchAndSortTypes.candidateEngagement.offerMade = '';
      searchAndSortTypes.candidateEngagement.offerRejected = '';
      searchAndSortTypes.candidateEngagement.appoinmentAccepted = '';
      searchAndSortTypes.candidateEngagement.appoinmentDeclined = '';
      searchAndSortTypes.candidateEngagement.appoinmentCancled = '';
      searchAndSortTypes.candidateEngagement.noAppointmentRequest = '';
      searchAndSortTypes.candidateEngagement.offerAccepted = '';
      searchAndSortTypes.rating = [0, 0, 0, 0, 0];
      searchAndSortTypes.availability.day = false;
      searchAndSortTypes.availability.night = false;
      searchAndSortTypes.availability.evening = false;
      setIsFromSearchAndSort(false);
      setSearchAndSortTypes({ ...searchAndSortTypes });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSortOption = (sortValue: SeekerListSortType) => {
    setSort(sortValue);

    if (currentUserProfile) {
      updateConfig({
        userId: currentUserProfile.id,
        selectedGroup: group,
        selectedSort: sortValue,
        selectedPreference: warningMessage,
      });
    }
  };

  const setGroupOption = (groupValue: SeekerListGroupType) => {
    setGroup(groupValue);

    if (currentUserProfile) {
      updateConfig({
        userId: currentUserProfile.id,
        selectedGroup: groupValue,
        selectedSort: sort,
        selectedPreference: warningMessage,
      });
    }
  };

  const setViewWarningMessageOption = (preferenceValue: boolean) => {
    setWarningMessage(preferenceValue);

    if (currentUserProfile) {
      updateConfig({
        userId: currentUserProfile.id,
        selectedGroup: group,
        selectedSort: sort,
        selectedPreference: preferenceValue,
      });
    }
  };

  return (
    <SeekersFiltersContext.Provider
      value={{
        sort,
        group,
        warningMessage,
        updateWarningMessage: setViewWarningMessageOption,
        updateSort: setSortOption,
        updateGroup: setGroupOption,
        searchQuery,
        setSearchQuery,
        ghostedListView,
        setGhostedListView,
        favoriteCandidateView,
        setFavoriteCandidateView,
        isFromSearchAndSort,
        setIsFromSearchAndSort,
        searchAndSortTypes,
        setSearchAndSortTypes,
        archiveListView,
        setArchiveListView,
        openFilterByPositionList,
        setOpenFilterByPositionList,
        isPositionFilterApplied,
        setIsPositionFilterApplied,
        selectedPositionIdForFilter,
        setSelectedPositionIdForFilter,
        lengthOfMappedSeekers,
        setLengthOfMappedSeekers,
        dismissListView,
        setDismissListView,
        listType,
        setListType,
        changeCandidate,
        setChangeCandidate,
      }}
    >
      {children}
    </SeekersFiltersContext.Provider>
  );
};

export const useSeekersFilters = (): SeekersFiltersContextValueType => useContext(SeekersFiltersContext);
