import _isNil from 'lodash/isNil';
import { Collections } from '../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../firebase/firestore/hooks';
import { Query } from '../../firebase/firestore/query/query';
import { EDUCATION_LEVEL } from '../../firebase/firestore/constants';
import { Lookups } from '../../firebase/firestore/documents/lookups';

const useEducationLevels = (): Lookups[] => {
  const educationLevels = useQuerySnapshot<Lookups>(Collections.LOOKUPS, [Query.field('type').equals(EDUCATION_LEVEL)]);
  if (_isNil(educationLevels)) return [];
  return educationLevels;
};

export default useEducationLevels;
