export interface OfferAndHireDetails {
  data: OfferAndHireProcessData[];
}

export enum CommuteMode {
  BICYCLE = 'BICYCLE',
  TWO_WHEELER = 'TWO_WHEELER',
  DRIVE = 'DRIVE',
  TRANSIT = 'TRANSIT',
  WALK = 'WALK',
}

type CandidateStatus = 'HIRE_PROCESSING' | 'HIRED' | 'OFFER_PROCESSING' | 'OFFER_REJECTED' | 'OFFER_REVOKED' | 'OTHER';

export enum CurrentStatus {
  HIRE_PROCESSING = 'hiresProcessing',
  HIRED = 'hired',
  OFFER_PROCESSING = 'offersProcessing',
  OFFER_REJECTED = 'offersRejected',
  OFFER_REVOKED = 'offersRevoked',
}

type OfferType = {
  offerMadeBy?: string;
  offerMadeAt?: string;
  offerRevokedRejectedBy?: string;
  offerRevokedRejectedAt?: string;
  offerMadeByFullName?: string;
  offerAcceptanceStatus?: boolean;
  reason?: string;
};

type LastMessageType = {
  fromRecruiter?: string;
  fromSeeker?: string;
};

type CommuteDataType = {
  durationInSeconds: number;
  distanceInMiles: number;
  calculationsCount: number;
  calculatedAt: string;
  travelMode: string;
};

type ComputedStatusType = {
  lastUpdate: string;
  category: string;
  prioritySortValue: number;
  status: string;
};

export interface OfferAndHireProcessData {
  status: CandidateStatus;
  candidateId: string;
  businessName: string;
  candidateName: string;
  requisitionId?: string;
  offer?: OfferType;
  computedStatus?: ComputedStatusType;
  positionName?: string;
  backgroundCheckStatus?: string;
  hireDetailStatus?: string;
  hiredAt?: string;
  lastMessage?: LastMessageType;
  commuteData?: CommuteDataType;
}

export const defaultOfferAndHireDetails: OfferAndHireDetails = {
  data: [],
};
