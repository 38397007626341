/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useEffect } from 'react';
import { Badge, Box, BoxProps, Button, Flex, Heading, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/core';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { NoteViewsEnum } from '../NotesViewsEnum';
import { PositionNote } from '../positionNote';
import { getColorForState } from '../../../../../../utils/positionUtils';
import { deleteButtonStyle, loginButtonStyle } from '../../../../../auth/login/loginForm/LoginForm.styles';
import { Candidate } from '../../../../../../firebase/firestore/documents/candidate';
import { DeleteNoteConfirmationModal } from '../DeleteNoteConfirmationModal';

const MotionStack = motion.custom(Stack);

const childrenVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -200 },
    },
  },
  hidden: {
    y: -10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export type NotesProps = BoxProps & {
  activeNoteIndex: number | undefined;
  activeNote: PositionNote;
  candidate?: Candidate;
  setActiveView: (page: NoteViewsEnum, noteId?: string, index?: number) => void;
  noteId: string;
  isNoteDeleteFeatureEnabled: boolean;
};

export const ViewNote = ({
  isNoteDeleteFeatureEnabled,
  activeNoteIndex,
  candidate,
  activeNote,
  setActiveView,
  noteId,
}: NotesProps): JSX.Element | null => {
  const { t } = useTranslation(['notes', 'candidateStatus']);
  const getCurrentNote = activeNote.getNoteById(noteId);

  useEffect(() => {
    if (!getCurrentNote) {
      setActiveView(NoteViewsEnum.NOTE_LIST);
    }
  }, [getCurrentNote, setActiveView]);

  const {
    isOpen: isDeleteNoteConfimationModalOpen,
    onOpen: onDeleteNoteConfirmationModalOpen,
    onClose: onDeleteNoteConfirmationModalClose,
  } = useDisclosure();

  const openDeleteConfirmationModal = () => {
    if (!candidate) return;
    onDeleteNoteConfirmationModalOpen();
  };

  return (
    <Fragment>
      <DeleteNoteConfirmationModal
        isOpen={isDeleteNoteConfimationModalOpen}
        onClose={onDeleteNoteConfirmationModalClose}
        setActiveView={setActiveView}
        accountId={candidate?.account}
        positionId={activeNote.positionId}
        seekerId={candidate?.seeker}
        noteIndex={activeNoteIndex}
      />

      <MotionStack variants={childrenVariants} spacing={4} padding={{ base: 4, md: 8 }}>
        <Box p={2} borderWidth="1px" borderRadius="sm" className="note-block" data-testid="ViewNote">
          <Flex className="note-header">
            <Box>
              <Heading as="h3" className="position">
                {activeNote.positionName}
              </Heading>
              <Text color="black" fontSize="sm" className="location">
                {activeNote.location}
              </Text>
            </Box>
            <Spacer />
            <Flex alignItems="center" className="status-block">
              <Heading className="title" color="blue.500">
                {t('noteList.status')}
              </Heading>
              <Badge background={getColorForState(activeNote?.status).color} color="white">
                {t(`candidateStatus:${activeNote.getCandidateStatus()}`)}
              </Badge>
            </Flex>
          </Flex>
          <Flex flexWrap="wrap" className="note-body">
            <Box w="100%" className="note-content" mb="6">
              {Array.isArray(getCurrentNote?.note)
                ? getCurrentNote?.note &&
                  getCurrentNote.note.map((n) =>
                    n.originalIndex === activeNoteIndex ? <div dangerouslySetInnerHTML={{ __html: n.note }} /> : '',
                  )
                : getCurrentNote?.note && <div dangerouslySetInnerHTML={{ __html: getCurrentNote.note }} />}
            </Box>
            <Box w="100%" display="flex" flexDirection="row" justifyContent="space-between">
              <Stack direction="row" spacing={4}>
                {getCurrentNote?.isEditable && (
                  <Button
                    onClick={() => setActiveView(NoteViewsEnum.NOTE_FORM, noteId)}
                    leftIcon={<EditIcon />}
                    css={loginButtonStyle}
                    data-testid="edit-button"
                    variant="solid"
                  >
                    {t('noteDetail.editNote')}
                  </Button>
                )}
                <Button
                  onClick={() => setActiveView(NoteViewsEnum.NOTE_LIST)}
                  background="gray.400"
                  color="white"
                  size="md"
                  data-testid="back-button"
                >
                  {t('noteDetail.back')}
                </Button>
              </Stack>
              {isNoteDeleteFeatureEnabled && getCurrentNote?.isEditable && (
                <Button
                  onClick={openDeleteConfirmationModal}
                  leftIcon={<DeleteIcon style={{ marginBottom: '4px' }} />}
                  css={deleteButtonStyle}
                  data-testid="delete-button"
                  variant="solid"
                >
                  {t('createNotes.deleteNote')}
                </Button>
              )}
            </Box>
          </Flex>
        </Box>
      </MotionStack>
    </Fragment>
  );
};
