import React from 'react';
import { Box, ListItem, Text, List } from '@chakra-ui/core';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Notification } from '../../../firebase/firestore/documents/notification';
import { useStoreActions } from '../../../models/hooks';
import { NotificationStatusIcon } from './NotificationStatusIcon';
import { notificationListCSS } from '../Header.styles';

export type OthersViewProps = {
  notifications: Notification[];
};

export const OthersView = ({ notifications }: OthersViewProps): JSX.Element => {
  const { t } = useTranslation();
  const { readNotification } = useStoreActions((actions) => actions.topBarNotification);

  const readNotificationInvitation = async (notificationId: string) => {
    await readNotification({ notificationId });
  };
  return (
    <>
      {notifications.length <= 0 && (
        <List css={notificationListCSS}>
          <Box>
            <ListItem key="1" borderBottom="1px solid #EBEBEB" px={5} py={4}>
              <Box>
                <Text fontSize="sm" fontWeight="700" data-testid="noNotificationText">
                  {t('notification:noNotification')}
                </Text>
              </Box>
            </ListItem>
          </Box>
        </List>
      )}
      {notifications.length > 0 &&
        notifications?.map((notificationData) => {
          return (
            <ListItem
              key={notificationData.id}
              d="flex"
              alignItems="start"
              px={5}
              py={4}
              borderBottom="1px solid #EBEBEB"
              style={{ cursor: 'pointer' }}
              data-testid="NotificationBlock"
              onClick={() => readNotificationInvitation(notificationData.id)}
              className={notificationData.new ? '' : 'read-notification'}
            >
              <Box w="100%">
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Box w="70%">
                    <Text
                      fontSize="sm"
                      fontWeight="700"
                      dangerouslySetInnerHTML={{
                        __html: notificationData.title,
                      }}
                    />

                    <Text
                      fontSize="sm"
                      dangerouslySetInnerHTML={{
                        __html: notificationData.message,
                      }}
                    />
                    <Text fontSize="xs" color="#A0AEC0">
                      {moment(notificationData.sentAt).format('lll')}
                    </Text>
                  </Box>
                  <Box>
                    <NotificationStatusIcon notificationType={notificationData.notificationType} />
                  </Box>
                </Box>
              </Box>
            </ListItem>
          );
        })}
    </>
  );
};
