/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { AvatarBadge, Box, Grid, Heading, Tooltip } from '@chakra-ui/core';
import { BsBookmarkStarFill } from 'react-icons/bs';
import { RiUserUnfollowFill } from 'react-icons/ri';
import { FaChevronCircleUp } from 'react-icons/fa';
import { CandidateItemNode } from '../treeGroup';
import { SeekersPresenter, SelectedSeekerEngagedCandidate } from '../seekersPresenter';
import { EngagedCandidate } from '../../../../../firebase/firestore/documents/candidate';
import { Label, ProfilePicture } from '../../../../common';
import colors from '../../../../../styles/colors';
import useLastMessage from './useLastMessage';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { useUserDocumentSnapshot } from '../../../../../firebase/firestore/hooks';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import { useSeekersFilters } from '../../filters/SeekersFiltersContext';
import SeekerListGroupType from '../../filters/seekerListGroupType';
import KisokIcon from '../../../../../assets/img/Kiosk.svg';
import AppyHereIcon from '../../../../../assets/img/icon-appyhere.svg';

export type CandidateItemProps = {
  item: CandidateItemNode;
  selected: SelectedSeekerEngagedCandidate | undefined;
  onSelected: (item: Readonly<EngagedCandidate>) => void | undefined;
  activeClass?: string;
  isShowFavoriteStatusEnabled: boolean;
  filteredSeekerByPosition: string[];
  seekerList?: SeekersPresenter;
};

export const CandidateItem = ({
  item,
  selected,
  onSelected,
  activeClass,
  isShowFavoriteStatusEnabled,
  filteredSeekerByPosition,
  seekerList,
}: CandidateItemProps): JSX.Element => {
  const { t } = useTranslation();
  const [profileURL, setProfileURl] = useState<string>();
  const isRead = item.candidate.accountNotification?.status?.[item.currentId]?.read ?? true;

  const { lastMessage } = useLastMessage(item.candidate.id);
  const seekersProfilePictures = useStoreState((state) => state.messaging.seekersProfilePictures);
  const loadSeekerProfilePicture = useStoreActions((actions) => actions.messaging.loadSeekerProfilePicture);
  const recentlyActive =
    moment(item.candidate.lastActivity).toDate() > moment().subtract(item.minutesBeforeInactivity, 'minute').toDate();

  const { ghostedListView, archiveListView, dismissListView } = useSeekersFilters();

  const { currentUserProfile } = useCurrentUserProfile();
  const user = useUserDocumentSnapshot(currentUserProfile?.id);
  const isUserDeactivated = Boolean(item.candidate.deactivated || item.candidate.scheduledDeletion);

  const isSeekerPriority = (seekerID: string) => {
    const seekers = seekerList?.engagedCandidates?.filter((candidate) => candidate.seeker === seekerID);
    if (seekers) {
      for (let i = 0; i < seekers.length; i += 1) {
        /* istanbul ignore else */
        if (seekers[i].priority) {
          return true;
        }
      }
    }
    return false;
  };

  const isSeekerFromKioskMode = (seekerID: string) => {
    const seekers = seekerList?.engagedCandidates?.filter((candidate) => candidate.seeker === seekerID);
    if (seekers) {
      for (let i = 0; i < seekers.length; i += 1) {
        /* istanbul ignore else */
        if (seekers[i].kioskEnabled) {
          return true;
        }
      }
    }
    return false;
  };

  const isSeekerInterventionEnabled = (seekerID: string) => {
    const seekers = seekerList?.engagedCandidates?.filter((candidate) => candidate.seeker === seekerID);
    if (seekers) {
      for (let i = 0; i < seekers.length; i += 1) {
        /* istanbul ignore else */
        if (seekers[i].interventionEnabled) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (item.candidate && item.candidate.displayProfilePicture) {
      loadSeekerProfilePicture({ candidate: item.candidate });
    }
  }, [item, loadSeekerProfilePicture]);

  useEffect(() => {
    const url = seekersProfilePictures[item.candidate.seeker];
    setProfileURl(url);
  }, [item, seekersProfilePictures]);
  const { openFilterByPositionList, group } = useSeekersFilters();
  if (openFilterByPositionList?.has(item.candidate.position) && group === SeekerListGroupType.POSITION) {
    return (
      <Box
        d="flex"
        alignItems="flex-start"
        className={filteredSeekerByPosition.includes(item.candidate.id) ? `inner-candidate-block ${activeClass}` : ''}
        onClick={() => (filteredSeekerByPosition.includes(item.candidate.id) ? onSelected?.(item.candidate) : {})}
        cursor="pointer"
        data-testid="CandidateItem"
      >
        {filteredSeekerByPosition.includes(item.candidate.id) && (
          <Grid templateColumns={{ base: '48px auto', md: '50px auto', lg: '60px auto' }} width="100%" minH="48px">
            <ProfilePicture
              src={profileURL}
              style={{ backgroundColor: 'orange', color: 'white' }}
              name={`${item.candidate.firstName} ${item.candidate.lastName}`}
              bg="transparent"
            >
              {recentlyActive && <AvatarBadge width="14px" height="14px" bg={colors.green['200']} />}
            </ProfilePicture>

            <Box pl="10px">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Heading
                  as="h4"
                  size="sm"
                  color={isUserDeactivated ? 'rgba(0, 0, 0, 0.5)' : 'unset'}
                  data-testid="FullName"
                  display="inline-flex"
                >
                  {`${item.candidate.firstName} ${item.candidate.lastName}`}
                  {isSeekerPriority(item.candidate.seeker) && (
                    /* istanbul ignore next */
                    <Tooltip hasArrow label={t('notification:seekerHeader.priorityTooltip')}>
                      <span style={{ marginLeft: '4px', display: 'flex', alignItems: 'center' }}>
                        <FaChevronCircleUp color={colors.green[600]} width="1rem" size={13} />
                      </span>
                    </Tooltip>
                  )}
                </Heading>

                <Box mt={1} pl={1}>
                  {!isUserDeactivated &&
                    isShowFavoriteStatusEnabled &&
                    !dismissListView &&
                    !ghostedListView &&
                    !archiveListView &&
                    user?.favouriteSeekers?.map((seeker) =>
                      seeker.id === item.candidate.seeker ? (
                        <Tooltip key={seeker.id} hasArrow label={t('notification:seekerHeader.favoriteCandidate')}>
                          <span>
                            <BsBookmarkStarFill data-testid="UnFavoriteCandidate" color="#FF6600" width="1rem" size={20} />
                          </span>
                        </Tooltip>
                      ) : (
                        <BsBookmarkStarFill data-testid="UnFavoriteCandidate" color="#FF6600" width="1rem" size={20} />
                      ),
                    )}

                  {isUserDeactivated && (
                    <Tooltip hasArrow label={t('notification:seekerHeader.deactivatedUserLabel')}>
                      <span>
                        <RiUserUnfollowFill
                          data-testid="DeactivatedCandidateBadge"
                          color="rgba(0, 0, 0, 0.5)"
                          width="1rem"
                          size={20}
                        />
                      </span>
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <Label
                bold={!isRead}
                color={isRead && !selected ? colors.neutralGray[400] : colors.neutralGray[700]}
                colorDarkMode={isRead && !selected ? colors.gray[100] : colors.white}
                whiteSpace="initial"
                paddingRight={0}
                noOfLines={2}
                dataTestId={lastMessage.id}
                style={{ wordBreak: 'break-word' }}
              >
                {lastMessage.lastMessage}
              </Label>
            </Box>
          </Grid>
        )}
      </Box>
    );
  }

  return (
    <Box
      d="flex"
      alignItems="flex-start"
      className={`inner-candidate-block ${activeClass}`}
      onClick={() => onSelected?.(item.candidate)}
      cursor="pointer"
      data-testid="CandidateItem"
    >
      <Grid templateColumns={{ base: '48px auto', md: '50px auto', lg: '60px auto' }} width="100%" minH="48px">
        <ProfilePicture
          src={profileURL}
          style={{ backgroundColor: isUserDeactivated ? colors.gray[200] : 'orange', color: 'white' }}
          name={`${item.candidate.firstName} ${item.candidate.lastName}`}
          bg="transparent"
        >
          {recentlyActive && !isUserDeactivated && <AvatarBadge width="14px" height="14px" bg={colors.green['200']} />}
        </ProfilePicture>

        <Box pl="10px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Heading
              as="h4"
              size="sm"
              color={isUserDeactivated ? 'rgba(0, 0, 0, 0.5)' : 'unset'}
              data-testid="FullName"
              display="inline-flex"
            >
              {`${item.candidate.firstName} ${item.candidate.lastName}`}
              {isSeekerPriority(item.candidate.seeker) && (
                <Tooltip hasArrow label={t('notification:seekerHeader.priorityTooltip')}>
                  <span style={{ marginLeft: '4px', display: 'flex', alignItems: 'center' }}>
                    <FaChevronCircleUp color={colors.green[600]} width="1rem" size={13} />
                  </span>
                </Tooltip>
              )}
            </Heading>
            <Box d="flex" justifyContent="center" mt={1} pl={1}>
              {isSeekerFromKioskMode(item.candidate.seeker) && (
                <Tooltip hasArrow label={t('notification:seekerHeader.kioskTooltip')}>
                  <span
                    style={{
                      background: colors.blue['500'],
                      display: 'inline-block',
                      padding: '4px',
                      height: '18px',
                      width: '18px',
                      borderRadius: '50%',
                      marginLeft: '6px',
                    }}
                  >
                    <img alt="Kisko mode" src={KisokIcon} width="10px" />
                  </span>
                </Tooltip>
              )}
              {isSeekerInterventionEnabled(item.candidate.seeker) && (
                <Tooltip hasArrow label={t('notification:seekerHeader.interventionTooltip')}>
                  <span
                    style={{
                      background: colors.blue['500'],
                      padding: '4px',
                      borderRadius: '50%',
                      marginLeft: '6px',
                      height: '18px',
                      width: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img alt="intervene" src={AppyHereIcon} width="auto" />
                  </span>
                </Tooltip>
              )}
              {!isUserDeactivated &&
                isShowFavoriteStatusEnabled &&
                !dismissListView &&
                !ghostedListView &&
                !archiveListView &&
                user?.favouriteSeekers?.map((seeker) =>
                  seeker.id === item.candidate.seeker ? (
                    <Tooltip hasArrow label={t('notification:seekerHeader.favoriteCandidate')}>
                      <span
                        style={{
                          background: '#FF6600',
                          display: 'inline-block',
                          padding: '4px',
                          borderRadius: '50%',
                          marginLeft: '6px',
                        }}
                      >
                        <BsBookmarkStarFill data-testid="UnFavoriteCandidate" color="#ffffff" width="1rem" size={10} />
                      </span>
                    </Tooltip>
                  ) : (
                    ''
                  ),
                )}

              {isUserDeactivated && (
                <Tooltip hasArrow label={t('notification:seekerHeader.deactivatedUserLabel')}>
                  <span className="undismissIcon">
                    <RiUserUnfollowFill
                      data-testid="DeactivatedCandidateBadge"
                      color="rgba(0, 0, 0, 0.5)"
                      width="1rem"
                      size={20}
                    />
                  </span>
                </Tooltip>
              )}
            </Box>
          </Box>
          <Label
            bold={!isRead}
            color={isRead && !selected ? colors.neutralGray[400] : colors.neutralGray[700]}
            colorDarkMode={isRead && !selected ? colors.gray[100] : colors.white}
            whiteSpace="initial"
            paddingRight={0}
            noOfLines={2}
            dataTestId={lastMessage.id}
            style={{ wordBreak: 'break-word' }}
          >
            {lastMessage.lastMessage}
          </Label>
        </Box>
      </Grid>
    </Box>
  );
};
