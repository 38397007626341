import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { UserController } from '../../../controllers/userController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import SeekerListGroupType from '../sidebar/filters/seekerListGroupType';
import SeekerListSortType from '../sidebar/filters/seekerListSortType';
import { EmployerInitializeData, OnBoardingUserInfoType } from '../../auth/userProfileInfoType';

export type UserStoreModel = {
  user: UserModel;
};

export interface UserModel {
  updateLocale: Thunk<
    UserModel,
    {
      userId: string;
      locale: string;
    },
    void,
    HttpStoreModel
  >;
  updateConfig: Thunk<
    UserModel,
    {
      userId: string;
      selectedGroup?: SeekerListGroupType;
      selectedSort?: SeekerListSortType;
      selectedPreference?: boolean;
    },
    void,
    HttpStoreModel
  >;
  updateNotificationStatus: Thunk<
    UserModel,
    {
      userId: string;
      notificationId: string;
    },
    void,
    HttpStoreModel
  >;
  updateUserDetail: Thunk<
    UserModel,
    {
      userId: string;
      userDetail: OnBoardingUserInfoType;
    },
    void,
    HttpStoreModel
  >;

  initializeAsEmployer: Thunk<
    UserModel,
    {
      userId: string;
      employerInitializeData: EmployerInitializeData;
    },
    void,
    HttpStoreModel
  >;

  employerOnboarded: Thunk<
    UserModel,
    {
      userId: string;
    },
    void,
    HttpStoreModel
  >;
}

export const userStoreModel: UserModel = {
  updateLocale: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UserController(getStoreState().http.client as HttpClientWithRetry);
    await controller.updateLocale(payload.userId, payload.locale);
  }),
  updateConfig: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UserController(getStoreState().http.client as HttpClientWithRetry);
    await controller.updateConfig(payload.userId, payload.selectedGroup, payload.selectedSort, payload.selectedPreference);
  }),
  updateNotificationStatus: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UserController(getStoreState().http.client as HttpClientWithRetry);
    await controller.updateNotificationStatus(payload.userId, payload.notificationId);
  }),
  updateUserDetail: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UserController(getStoreState().http.client as HttpClientWithRetry);
    await controller.updateUserDetail(payload.userId, payload.userDetail);
  }),
  initializeAsEmployer: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UserController(getStoreState().http.client as HttpClientWithRetry);
    await controller.initializeAsEmployer(payload.userId, payload.employerInitializeData);
  }),
  employerOnboarded: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UserController(getStoreState().http.client as HttpClientWithRetry);
    await controller.employerOnboarded(payload.userId);
  }),
};
