import React, { ChangeEvent } from 'react';
import { Box, Text, Checkbox, Stack } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { EngagementTypes, SearchAndSortTypes } from '../filters/seekersFiltersContextValueType';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';

export type CandidateEngagementViewProps = {
  engagementValue: EngagementTypes;
  onHandleEngagement: (engagement: EngagementTypes) => void;
};

export const CandidateEngagementView = (): JSX.Element => {
  const { t } = useTranslation();

  const { searchAndSortTypes, setSearchAndSortTypes, setIsFromSearchAndSort } = useSeekersFilters();

  const onHandleCandiateEngagement = (engagement: SearchAndSortTypes) => {
    setIsFromSearchAndSort(true);
    setSearchAndSortTypes({ ...engagement });
  };

  return (
    <Box>
      <Stack direction="column">
        <Checkbox
          data-testid="noAppointmentRequestCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.noAppointmentRequest === 'NO_APPOINTMENT_REQUEST'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.candidateEngagement.noAppointmentRequest = 'NO_APPOINTMENT_REQUEST';
            } else {
              searchAndSortTypes.candidateEngagement.noAppointmentRequest = '';
            }
            onHandleCandiateEngagement(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('cadidateEngagementView.noAppointmentRequest')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="appointmentRequestedCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.apointmentRequested === 'APPOINTMENT_REQUESTED'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.candidateEngagement.apointmentRequested = 'APPOINTMENT_REQUESTED';
            } else {
              searchAndSortTypes.candidateEngagement.apointmentRequested = '';
            }
            onHandleCandiateEngagement(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('cadidateEngagementView.appointmentRequested')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="appointmentAccceptedCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.appoinmentAccepted === 'APPOINTMENT_ACCEPTED'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.candidateEngagement.appoinmentAccepted = 'APPOINTMENT_ACCEPTED';
            } else {
              searchAndSortTypes.candidateEngagement.appoinmentAccepted = '';
            }
            onHandleCandiateEngagement(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('cadidateEngagementView.appoinmentAccepted')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="appointmentDeclinedCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.appoinmentDeclined === 'APPOINTMENT_DECLINED'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.candidateEngagement.appoinmentDeclined = 'APPOINTMENT_DECLINED';
            } else {
              searchAndSortTypes.candidateEngagement.appoinmentDeclined = '';
            }
            onHandleCandiateEngagement(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('cadidateEngagementView.appoinmentDeclined')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="appointmentCanledCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.appoinmentCancled === 'APPOINTMENT_CANCELLED'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.candidateEngagement.appoinmentCancled = 'APPOINTMENT_CANCELLED';
            } else {
              searchAndSortTypes.candidateEngagement.appoinmentCancled = '';
            }
            onHandleCandiateEngagement(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('cadidateEngagementView.appoinmentCancled')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="offerMadeCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.offerMade === 'OFFER_MADE'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.candidateEngagement.offerMade = 'OFFER_MADE';
            } else {
              searchAndSortTypes.candidateEngagement.offerMade = '';
            }
            onHandleCandiateEngagement(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('cadidateEngagementView.OfferMade')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="interestRevokedCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.interestRevoked === 'INTEREST_REVOKED'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.candidateEngagement.interestRevoked = 'INTEREST_REVOKED';
            } else {
              searchAndSortTypes.candidateEngagement.interestRevoked = '';
            }
            onHandleCandiateEngagement(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('cadidateEngagementView.interestRevoked')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="contactRequestDeclinedCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.contactRequestDeclined === 'CONTACT_REQUEST_DECLINED'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.candidateEngagement.contactRequestDeclined = 'CONTACT_REQUEST_DECLINED';
            } else {
              searchAndSortTypes.candidateEngagement.contactRequestDeclined = '';
            }
            onHandleCandiateEngagement(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('cadidateEngagementView.contactRequestDeclined')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="offerRejectedCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.offerRejected === 'OFFER_REJECTED'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.candidateEngagement.offerRejected = 'OFFER_REJECTED';
            } else {
              searchAndSortTypes.candidateEngagement.offerRejected = '';
            }
            onHandleCandiateEngagement(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('cadidateEngagementView.offerRejected')}
          </Text>
        </Checkbox>
        <Checkbox
          data-testid="offerAcceptedCheckbox"
          isChecked={searchAndSortTypes.candidateEngagement.offerAccepted === 'OFFER_ACCEPTED'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              searchAndSortTypes.candidateEngagement.offerAccepted = 'OFFER_ACCEPTED';
            } else {
              searchAndSortTypes.candidateEngagement.offerAccepted = '';
            }
            onHandleCandiateEngagement(searchAndSortTypes);
          }}
        >
          <Text
            px={3}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            {t('cadidateEngagementView.offerAccepted')}
          </Text>
        </Checkbox>
      </Stack>
    </Box>
  );
};
