import React, { useState } from 'react';
import { Box } from '@chakra-ui/core';
import { BusinessListView } from '../administration/positionManagement/businessList/BusinessListView';
import { usePositionManagementContext } from '../administration/positionManagement/PositionManagementContext';
import { Business } from '../administration/positionManagement/BusinessType';
import PositionListForTopCandidate from './PositionListForTopCandidate';
import { BusinessListStyleForModal } from './businessListStyleForModal';

export const PositionManagementForTopCandidate = (): JSX.Element => {
  const [activeBusiness, setActiveBusiness] = useState<Business | null>(null);
  const [scrollToBusiness, setScrollToBusiness] = useState<Business | null>(null);

  const { positionQueryParams, setPositionQueryParams } = usePositionManagementContext();
  const changeActiveBusiness = (business: Business | null) => {
    const newpositionQueryParams = positionQueryParams;
    newpositionQueryParams.positionStatus = 'open';
    newpositionQueryParams.archive = false;
    setPositionQueryParams({ ...newpositionQueryParams });
    setActiveBusiness(business);
  };

  return (
    <Box p={0}>
      <Box css={BusinessListStyleForModal}>
        <BusinessListView
          changeActiveBusiness={changeActiveBusiness}
          isAdministrationAccount={false}
          activeBusiness={activeBusiness}
          scrollToBusiness={scrollToBusiness}
          setScrollToBusiness={setScrollToBusiness}
        />
      </Box>
      {activeBusiness && (
        <PositionListForTopCandidate
          activeBusiness={activeBusiness}
          backToBusinessList={() => setActiveBusiness(null)}
          setScrollToBusiness={setScrollToBusiness}
        />
      )}
    </Box>
  );
};
