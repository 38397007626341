/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import {
  Box,
  Flex,
  Spacer,
  Avatar,
  Heading,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/core';
import { IoIosMore, IoIosVideocam, IoIosMic } from 'react-icons/io';
import { meetSessionDetailStyle } from './MeetSessionDetailStyle';
import { MeetRemoteParticipantInfo } from './MeetParticipantResponse';
import { ParticipantTrackIndicator } from './ParticipantTrackIndicator';

type MeetSessionDetailProps = {
  isOpen: boolean;
  onClose: () => void;
  totalParticipant: number;
  filteredParticipant: MeetRemoteParticipantInfo[];
};

export const MeetSessionDetail = ({
  isOpen,
  onClose,
  totalParticipant,
  filteredParticipant,
}: MeetSessionDetailProps): JSX.Element => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
      <DrawerOverlay />
      <DrawerContent css={meetSessionDetailStyle}>
        <DrawerCloseButton />
        <DrawerHeader>Session Details</DrawerHeader>
        <DrawerBody>
          <Box className="drawer-participant-header">
            <Flex className="participant-action">
              <Box>
                <Heading as="h6" size="sm">
                  In the Room
                </Heading>
                <Text>{`${filteredParticipant.length}/${totalParticipant}`}</Text>
              </Box>
              <Spacer />
              <Box>
                <Menu>
                  <MenuButton
                    className="action-btn"
                    as={IconButton}
                    aria-label="Options"
                    icon={<IoIosMore />}
                    variant="outline"
                  />
                  <MenuList>
                    <MenuItem icon={<IoIosVideocam />}>Turn all cams off</MenuItem>
                    <MenuItem icon={<IoIosMic />}>Turn all mic off</MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Flex>
          </Box>
          <Box className="drawer-participant-list">
            {filteredParticipant.map((p) => {
              const fullName = `${p.participantInfo.firstName} ${p.participantInfo.lastName}`;
              return (
                <Flex key={p.participant.sid} className="drawer-participant" flexDir="row" alignItems="center">
                  <Box d="flex">
                    <Avatar mr={2} size="sm" name={`${p.participantInfo.firstName} ${p.participantInfo.lastName}`} />
                    <Text>{fullName}</Text>
                  </Box>
                  <Spacer />
                  <ParticipantTrackIndicator participant={p.participant} />
                </Flex>
              );
            })}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
