export enum NoteViewsEnum {
  NOTE_LIST = 'noteList',
  NOTE_FORM = 'noteForm',
  NOTE_DETAIL = 'noteDetail',
}

export enum NoteListRenderedByEnum {
  RECRUITERS_CANDIDATE_TABLE_VIEW = 'RECRUITERS_CANDIDATE_TABLE_VIEW',
  NOTES = 'NOTES',
}
