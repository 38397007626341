import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../http/models/httpModel';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { RequisitionController } from '../controllers/requisitionController';
import { RiskRequisitionDetail } from '../firebase/firestore/documents/requisition';

export type RequisitionStoreModel = {
  requisition: RequisitionModel;
};

export interface RequisitionModel {
  getRiskRequisitionDetail: Thunk<RequisitionModel, { accountId: string }, void, HttpStoreModel, Promise<RiskRequisitionDetail>>;
}

export const requisitionModel: RequisitionModel = {
  getRiskRequisitionDetail: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new RequisitionController(getStoreState().http.client as HttpClientWithRetry);
    // eslint-disable-next-line no-return-await
    return await controller.getRiskRequisitionDetail(payload.accountId);
  }),
};
