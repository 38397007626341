import { css } from '@emotion/core';
import colors from '../../../../styles/colors';
import { transitionSpeed } from '../../../../styles/sizes';

// This block will be displayed as a grid inside a cell of a parent grid (src/modules/messaging/views/messaging/MessagingView.styles.ts)
// That's why we have grid-templates and grid-column/row in the same call
export const seekerHeaderGridCSS = css`
  grid-template-columns: min-content auto;
  grid-template-rows: auto min-content;
  grid-column: midSide / rightSide;
  grid-row: header / sub2;
  border-bottom: 1px solid ${colors.dividerGray};
  background-color: ${colors.white};
  transition: background-color ${transitionSpeed}, border-color ${transitionSpeed}, color ${transitionSpeed};

  .chakra-ui-dark & {
    border-bottom-color: transparent;
    background-color: ${colors.gray['700']};
  }

  &.seeker-panel__header .chakra-avatar {
    @media only screen and (max-width: 60rem) {
      height: 45px;
      width: 45px;
    }
  }
`;

export const backgroundCheckStatus = css`
  &.chakra-badge {
    &.sent {
      color: white;
      background-color: ${colors.blue.default};
    }
    &.in_progress {
      color: #7b7c19;
      background-color: #f0f4c3;
    }
    &.analyzing {
      color: #5c995e;
      background-color: #e8fff3;
    }
    &.complete {
      color: #ff6600;
      background-color: #ffd7bd;
    }
    &.partial {
      color: #5c995e;
      background-color: #e8fff3;
    }
    &.dispute {
      color: #b33636;
      background-color: #f1c5c5;
    }
  }
`;

export const seekerInfoCSS = css`
  //height: 5rem;
`;

export const avatarBoxCSS = css`
  grid-column: 1 / 2;
  grid-row: 1 / -1;
`;

export const seekerSubNavigationCSS = css`
  grid-column: 2 / -1;
  grid-row: 2 / -1;
`;

export const menuButtonCSS = css`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  transition: background-color ${transitionSpeed}, border-color ${transitionSpeed}, color ${transitionSpeed};
  border: 1px solid transparent;
  background-color: ${colors.neutralGray['100']};
  border-color: ${colors.dividerGray};
  color: ${colors.gray['700']};

  .chakra-ui-dark & {
    background-color: ${colors.gray['700']};
    border-color: ${colors.gray['800']};
    color: ${colors.gray['50']};

    & [role="menuitem"]:hover {
      background-color: ${colors.gray['500']};
    }
  }

  & > span {
    display: contents;
  }

  &.is-opened {
    background-color: ${colors.blue['500']};
    border-color: ${colors.white};
    color: ${colors.white};

  & svg path {
    fill: #fff;
  }
`;

// (If any box-shadow is present, clip-path inset negative values)
export const menuListCSS = css`
  transform: translateY(-13px);
  clip-path: inset(2px -5px -5px -5px);
`;

export const disabledStyle = css`
  pointer-events: none;
  opacity: 0.5;
`;

export const customDatePickerCss = css`
  .react-datepicker-wrapper {
    width: 100%;
    display: block;
  }
`;

export const customTimePickerCss = css`
  .react-datepicker-wrapper {
    height: 40px;
    align-items: center;
    display: flex;
    width: 100%;
  }
  .time-input-group {
    padding-right: 10px;
    input {
      padding: 10px 10px;
      background-color: transparent;
      width: 100%;
    }
  }
`;

export const adhocFormCSS = css`
  input {
    background-color: ${colors.neutralGray['100']};
    border-color: ${colors.dividerGray};
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
