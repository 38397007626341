import { css } from '@emotion/core';

export const recruiterDetailsWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  font-color: black;
  width: max-content;
  margin: auto;

  .wrapperStyles {
    border-radius: 4px;
    border: 1px solid #eceff1;
  }
`;
