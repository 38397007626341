import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/core';
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';
import { MultipleChoiceQuestion } from '../PositionType';
import colors from '../../../../styles/colors';
import { MCQPreview } from './ReviewCreateJobView';

export type SortableItemType = {
  item: MultipleChoiceQuestion;
  itemIndex: number;
  editMultipleChoiceQuestion: (index: number, item: MultipleChoiceQuestion) => void;
  setMultipleChoiceQuestionEditMode: (index: number | undefined) => void;
  setDeleteIndexMultipleChoice: (index: number | null) => void;
  deleteMultipleQuestionsQuestion: (index: number) => void;
  multipleChoiceQuestionEditMode: number | undefined;
  deleteIndexMultipleChoice: number | null;
};

export const SortableMultipleChoiceQuestionItem = SortableElement(
  ({
    item,
    itemIndex,
    editMultipleChoiceQuestion,
    setMultipleChoiceQuestionEditMode,
    setDeleteIndexMultipleChoice,
    deleteMultipleQuestionsQuestion,
    multipleChoiceQuestionEditMode,
    deleteIndexMultipleChoice,
  }: SortableItemType) => {
    const { t } = useTranslation();
    const { onOpen: onOpenMultipleChoice, onClose: onCloseMultipleChoice, isOpen: isOpenMultipleChoice } = useDisclosure();

    return (
      <li style={{ zIndex: 9999, cursor: 'move', marginTop: '15px', listStyle: 'none' }}>
        <Flex key={`${item.question}-${itemIndex}`} justifyContent="space-between">
          {/* <Flex justifyContent="space-between" w="100%"> */}
          <MCQPreview question={item} index={itemIndex} />
          {/* </Flex> */}
          <Flex>
            <IconButton
              backgroundColor="#ECEFF1"
              aria-label="Edit Question"
              icon={<RiEdit2Fill style={{ color: colors.blue[500] }} />}
              data-testid={`EditQuestionBtn-${itemIndex}`}
              onClick={() => {
                /* istanbul ignore else */
                editMultipleChoiceQuestion(itemIndex, item);
                setMultipleChoiceQuestionEditMode(itemIndex);
              }}
            />
            <Box>
              <Popover
                returnFocusOnClose={false}
                isOpen={isOpenMultipleChoice && deleteIndexMultipleChoice === itemIndex}
                onClose={onCloseMultipleChoice}
                closeOnBlur
                isLazy
              >
                <PopoverTrigger>
                  <IconButton
                    backgroundColor="#ECEFF1"
                    ml={3}
                    aria-label="Delete Question"
                    icon={<RiDeleteBin5Fill style={{ color: colors.blue[500] }} />}
                    data-testid={`DeleteQuestionBtn-${itemIndex}`}
                    onClick={() => {
                      onOpenMultipleChoice();
                      if (itemIndex === multipleChoiceQuestionEditMode) setMultipleChoiceQuestionEditMode(undefined);
                      if (multipleChoiceQuestionEditMode && itemIndex < multipleChoiceQuestionEditMode)
                        setMultipleChoiceQuestionEditMode(multipleChoiceQuestionEditMode - 1);
                      setDeleteIndexMultipleChoice(itemIndex);
                    }}
                  />
                </PopoverTrigger>
                {isOpenMultipleChoice && (
                  <PopoverContent rootProps={{ style: { right: 0 } }}>
                    <PopoverBody fontWeight="semibold">{t('createJobPosition:deleteQuestionConfirmation')}</PopoverBody>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverFooter d="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm">
                        <Button variant="outline" onClick={onCloseMultipleChoice}>
                          {t('common:questions.answerNegative')}
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            setDeleteIndexMultipleChoice(null);
                            deleteMultipleQuestionsQuestion(itemIndex);
                            onCloseMultipleChoice();
                          }}
                          data-testid={`deleteQuestionConfirmBtn-${itemIndex}`}
                        >
                          {t('common:questions.answerPositive')}
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                )}
              </Popover>
            </Box>
          </Flex>
        </Flex>
      </li>
    );
  },
);
