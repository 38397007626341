import { useMemo } from 'react';
import _isNil from 'lodash/isNil';
import { Candidate } from '../../../../firebase/firestore/documents/candidate';
import { Query } from '../../../../firebase/firestore/query/query';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { Collections } from '../../../../firebase/firestore/collections';
import { useQueryOnce } from '../../../../firebase/firestore/hooks';

export const useSeekerAllCandidates = (seekerId?: string): Readonly<Candidate>[] | undefined => {
  const { currentUserProfile } = useCurrentUserProfile();

  const candidates = useQueryOnce<Readonly<Candidate>>(Collections.CANDIDATES, [
    Query.field<Candidate>('seeker').equals(seekerId),
    Query.field<Candidate>('account').equals(currentUserProfile?.account),
    Query.field<Candidate>('positionOpen').equals(true),
  ]);

  return useMemo(() => {
    if (!currentUserProfile || _isNil(candidates)) {
      return undefined;
    }

    return candidates;
  }, [candidates, currentUserProfile]);
};
