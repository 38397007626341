import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../http/models/httpModel';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { AccountRequisitionStats } from '../firebase/firestore/documents/managementOverview';
import { ManagementOverviewController } from '../controllers/managementOverviewController';

export type ManagementOverviewStoreModel = {
  managementOverview: ManagementOverviewModel;
};

export interface ManagementOverviewModel {
  getAccountRequisitionStats: Thunk<
    ManagementOverviewModel,
    { accountId: string },
    void,
    HttpStoreModel,
    Promise<AccountRequisitionStats>
  >;
  getAccountOfferStats: Thunk<
    ManagementOverviewModel,
    {
      accountId: string;
      startDate: Date;
      endDate: Date;
    },
    void,
    HttpStoreModel,
    Promise<any>
  >;
}

export const managementOverviewModel: ManagementOverviewModel = {
  getAccountRequisitionStats: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ManagementOverviewController(getStoreState().http.client as HttpClientWithRetry);
    // eslint-disable-next-line no-return-await
    return await controller.getAccountRequisitionStats(payload.accountId);
  }),

  getAccountOfferStats: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ManagementOverviewController(getStoreState().http.client as HttpClientWithRetry);
    // eslint-disable-next-line no-return-await
    return await controller.getAccountOfferStats(payload.accountId, payload.startDate, payload.endDate);
  }),
};
