import { Box, Checkbox, Flex, FormControl, FormLabel, Heading, Stack, Switch, Text, VStack } from '@chakra-ui/core';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { customStyledCheckbox } from './CreateJobPositionStyle';
import { JobPosition, Schedule } from '../PositionType';
import { getTranslatedValue } from '../../../../utils/localizedString';

export type WorkScheduleProps = {
  jobPositionData: JobPosition;
  handleScheduleChange: (event: ChangeEvent<HTMLInputElement>, value: string, fieldName: string) => void;
  handleChange: (value: boolean, fieldName: string) => void;
};

export const WorkScheduleCard = ({ jobPositionData, handleScheduleChange, handleChange }: WorkScheduleProps): JSX.Element => {
  const weekDays = [
    { en: 'sun', fr: 'dim' },
    { en: 'mon', fr: 'lun' },
    { en: 'tue', fr: 'mar' },
    { en: 'wed', fr: 'mer' },
    { en: 'thu', fr: 'jeu' },
    { en: 'fri', fr: 'ven' },
    { en: 'sat', fr: 'sam' },
  ];
  const { t } = useTranslation();

  return (
    <Box backgroundColor="white" borderRadius={5} mb={6}>
      <Box px={6} py={4}>
        <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
          {t('createJobPosition:workScheduleCardHeader')}
        </Heading>
        <Text fontSize="xs" color="gray.500">
          {t('createJobPosition:workScheduleCardHint')}
        </Text>
      </Box>
      <Box css={customStyledCheckbox} p={6}>
        <VStack spacing={4} align="stretch">
          {weekDays.map((weekDay) => (
            <Flex key={weekDay.en}>
              <Text w="10%" minW="80px" textTransform="capitalize">
                {getTranslatedValue(weekDay, i18next.language)}
              </Text>
              <Stack width="70%" spacing={[1, 5]} direction={['column', 'row']}>
                {[
                  { en: 'day', fr: 'jour' },
                  { en: 'evening', fr: 'soirée' },
                  { en: 'night', fr: 'nuit' },
                ].map((period) => (
                  <Checkbox
                    isChecked={jobPositionData.schedule[weekDay.en][period.en as keyof Schedule] !== ''}
                    key={`${weekDay.en}-${period.en}`}
                    period={period.en}
                    value={period.en}
                    data-testid={`ScheduleCheckBox-${weekDay.en}-${period.en}`}
                    onChange={(e) => handleScheduleChange(e, period.en, weekDay.en)}
                  >
                    {getTranslatedValue(period, i18next.language)}
                  </Checkbox>
                ))}
              </Stack>
            </Flex>
          ))}

          <Flex pt={2}>
            <Text w="10%" minW="80px" textTransform="capitalize">
              &nbsp;
            </Text>
            <FormControl d="flex" w="70%">
              <Switch
                id="fullTime"
                mr={2}
                ml={2}
                isChecked={jobPositionData.fullTime}
                data-testid="FullTimeSwitch"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.checked, 'fullTime')}
              />
              <FormLabel htmlFor="fullTime">{t('createJobPosition:fullTimeLabel')}</FormLabel>
            </FormControl>
          </Flex>
        </VStack>
      </Box>
    </Box>
  );
};
