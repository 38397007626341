/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint-disable-next-line max-classes-per-file
import { FirestoreOperation } from '../firestoreOperation';

export class Criteria {
  static isWhereCriteria(criteria: Criteria): criteria is WhereCriteria {
    return criteria instanceof WhereCriteria;
  }

  static isLimitCriteria(criteria: Criteria): criteria is LimitCriteria {
    return criteria instanceof LimitCriteria;
  }

  static isOrderCriteria(criteria: Criteria): criteria is OrderCriteria {
    return criteria instanceof OrderCriteria;
  }
}

export class LimitCriteria extends Criteria {
  constructor(private readonly limit: number) {
    super();
  }

  public getLimit(): number {
    return this.limit;
  }
}

export class OrderCriteria extends Criteria {
  constructor(private readonly fieldPath: string | firebase.firestore.FieldPath, private readonly direction: OrderByDirection) {
    super();
  }

  public getFieldPath(): string | firebase.firestore.FieldPath {
    return this.fieldPath;
  }

  public getDirection(): OrderByDirection {
    return this.direction;
  }
}

export enum OrderByDirection {
  desc = 'desc',
  asc = 'asc',
}

export class WhereCriteria extends Criteria {
  constructor(private readonly key: string, private readonly operation: FirestoreOperation, private readonly value: unknown) {
    super();
  }

  public getKey(): string {
    return this.key;
  }

  public getOperation(): FirestoreOperation {
    return this.operation;
  }

  public getValue(): unknown {
    return this.value;
  }
}
