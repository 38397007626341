/* istanbul ignore file */
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import { MdPersonAddAlt1 } from 'react-icons/md';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
  useToast,
  Heading,
  Badge,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { IoMdCopy, IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { AxiosError } from 'axios';
import { clearErrorMessage, errorFormat, FormattedError, getErrorMessage, hasErrorMessage } from '../../../utils/FormErrorUtils';
import { useStoreActions } from '../../../models/hooks';
import { InviteCollaborators } from '../models/InviteCollaboratorsTypes';
import { AvailableBookingWithPosition } from '../../../firebase/firestore/documents/appointmentAvaiability';
import colors from '../../../styles/colors';

export type InviteCollaboratorsViewPropsTypes = {
  bookingDetail: AvailableBookingWithPosition;
};

export const InviteCollaboratorsView = ({ bookingDetail }: InviteCollaboratorsViewPropsTypes) => {
  const { t, i18n } = useTranslation('calendar');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLink, setLoadingLink] = useState<boolean>(false);
  const toast = useToast();
  const [invitationLink, setInvitationLink] = useState<{ invitationLink: string }>({ invitationLink: '' });
  const [collaboratorsObj, setCollaboratorsObj] = useState<InviteCollaborators>({
    email: '',
    candidateId: bookingDetail.candidate.id,
    appointmentAvailabilityId: bookingDetail.booking.availabilityId,
    bookingId: bookingDetail.booking.id || '',
    locale: i18n.language,
  });
  const [collaborators, setCollaborators] = useState<InviteCollaborators>({
    email: '',
    candidateId: bookingDetail.candidate.id,
    appointmentAvailabilityId: bookingDetail.booking.availabilityId,
    bookingId: bookingDetail.booking.id || '',
    locale: i18n.language,
  });

  const [show, setShow] = React.useState(false);
  const [showInviteCollaborator, setShowInviteCollaborator] = React.useState(true);
  const handleClick = () => setShow(!show);

  const { sendInvitationToCollaborators, getInvitationLink, getCollaboratorsInvite } = useStoreActions(
    (actions) => actions.inviteCollaborators,
  );
  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .messages({
          'string.empty': t('team:createTeamModal.validationMessage.empty'),
          'string.email': t('team:createTeamModal.validationMessage.validation'),
        }),
      candidateId: Joi.string().required(),
      appointmentAvailabilityId: Joi.string().required(),
      bookingId: Joi.string().required(),
      locale: Joi.string().required(),
    });

  const formValidation = (formData: InviteCollaborators) => {
    const { error } = schema.validate(formData);
    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };
  const onGetCollaboratorsInvite: () => void = async () => {
    const data = await getCollaboratorsInvite({ bookingId: collaboratorsObj.bookingId });
    if (data) {
      setCollaborators(data);
      setShowInviteCollaborator(false);
    } else {
      setShowInviteCollaborator(true);
    }
    setLoadingLink(true);
  };
  const onSendInvitation = async () => {
    try {
      const validate = formValidation(collaboratorsObj);
      if (validate) {
        setLoading(true);
        await sendInvitationToCollaborators({ collaborators: collaboratorsObj });
        onGetCollaboratorsInvite();
        toast({
          title: t('collaborators.linkSend'),
          status: 'info',
          duration: 6000,
          isClosable: true,
        });
        setLoading(false);
        setCollaboratorsObj({
          email: '',
          candidateId: bookingDetail.candidate.id,
          appointmentAvailabilityId: bookingDetail.booking.availabilityId,
          bookingId: bookingDetail.booking.id || '',
          locale: i18n.language,
        });
        onClose();
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const errData: AxiosError = { ...e };
      const { response } = errData;
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (response?.data.status === 409) {
        toast({
          title: t('collaborators.userAlreadyExists'),
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      } else {
        toast({
          title: t('collaborators.linkSendError'),
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    setCollaboratorsObj({ ...collaboratorsObj, email: event.target.value });
    setFormErrors(clearErrorMessage(formErrors, event.target.id));
  };

  const onGetInvitationLink: () => void = async () => {
    const data = await getInvitationLink({ collaborators: collaboratorsObj });
    /* istanbul ignore else */
    if (data) {
      setInvitationLink(data);
    }
  };

  useEffect(() => {
    if (isOpen) {
      onGetInvitationLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    onGetCollaboratorsInvite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaboratorsObj.bookingId]);

  return (
    <Box>
      <Box data-testid="collaboratorsView">
        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            {!showInviteCollaborator && (
              <Heading as="h4" size="xs">
                {t('collaborators.interviewLink')}
              </Heading>
            )}
            <Button
              data-testid="VideoCallButton"
              leftIcon={<MdPersonAddAlt1 />}
              colorScheme="blue"
              variant="outline"
              onClick={() => {
                setCollaboratorsObj({
                  email: '',
                  candidateId: bookingDetail.candidate.id,
                  appointmentAvailabilityId: bookingDetail.booking.availabilityId,
                  bookingId: bookingDetail.booking.id || '',
                  locale: i18n.language,
                });
                onOpen();
              }}
            >
              {t('collaborators.inviteCollaborators')}
            </Button>
          </Flex>
          {!showInviteCollaborator && (
            <Box>
              <Heading as="h5" size="xs">
                {t('collaborators.link')}
              </Heading>
              <Flex justifyContent="space-between">
                <a href={collaborators?.inviteLink} target="_blank" rel="noopener noreferrer">
                  <Text overflow="hidden" color={colors.blue.default}>
                    {t('collaborators.interviewlink')}
                  </Text>
                </a>

                <IoMdCopy
                  style={{ cursor: 'pointer' }}
                  data-testid="copyButton"
                  size="30px"
                  onClick={async () => {
                    if (collaborators?.inviteLink) {
                      await navigator.clipboard.writeText(collaborators?.inviteLink);
                      toast({
                        title: t('collaborators.linkCopied'),
                        status: 'info',
                        duration: 6000,
                        isClosable: true,
                      });
                    }
                  }}
                />
              </Flex>
              <Heading as="h5" size="xs" mt="10px">
                {t('collaborators.shortCode')}
              </Heading>
              <Flex justifyContent="space-between">
                <Input
                  width="80%"
                  color="blue"
                  p="0px"
                  type={show ? 'text' : 'password'}
                  disabled
                  value={collaborators?.code}
                  _disabled={{
                    border: 'unset',
                    color: colors.blue.default,
                  }}
                />
                <Box p="0px" onClick={handleClick} data-testid="copyCodeBtn">
                  {!show ? (
                    <IoMdEye size="30px" style={{ cursor: 'pointer' }} />
                  ) : (
                    <IoMdEyeOff style={{ cursor: 'pointer' }} size="30px" />
                  )}
                </Box>
              </Flex>
              <Heading as="h5" size="xs" mt="10px">
                {t('collaborators.inviteeEmails')}
              </Heading>
              {collaborators.inviteeEmails?.map((e) => (
                <Badge variant="outline" background={colors.blue[50]} mr="8px" borderRadius="4px" textTransform="unset" key={e}>
                  {e}
                </Badge>
              ))}
            </Box>
          )}
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Text>{t('collaborators.addCollaborators')}</Text>
              <Text fontSize="12px">{t('collaborators.addCollaboratorsInfo')}</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isInvalid={hasErrorMessage(formErrors, 'email')} isRequired>
                <FormLabel htmlFor="name">{t('collaborators.email')}</FormLabel>
                <Flex alignItems="end">
                  <Input
                    w="80%"
                    data-testid="emailField"
                    borderRightRadius={0}
                    id="email"
                    type="text"
                    value={collaboratorsObj.email}
                    onChange={handleChange}
                  />

                  <Button
                    colorScheme="blue"
                    borderLeftRadius={0}
                    minW="auto"
                    data-testid="inviteBtn"
                    variant="solid"
                    onClick={onSendInvitation}
                    isDisabled={loading || !collaboratorsObj.email}
                    isLoading={loading}
                  >
                    {t('collaborators.invite')}
                  </Button>
                </Flex>
                <FormErrorMessage>{getErrorMessage(formErrors, 'email')}</FormErrorMessage>
              </FormControl>
              {loadingLink && (
                <Flex mt="20px" background="#EDF2F7" px="5px" py="5px" borderRadius="md" alignItems="center">
                  <Text textOverflow="ellipsis" paddingLeft="10px" width="80%" whiteSpace="nowrap" overflow="hidden">
                    {invitationLink.invitationLink}
                  </Text>
                  <Button
                    leftIcon={<IoMdCopy />}
                    bg="white"
                    size="sm"
                    px="15px"
                    py="10px"
                    borderColor="#E5E7EB"
                    data-testid="copyBtnLink"
                    variant="outline"
                    onClick={async () => {
                      await navigator.clipboard.writeText(invitationLink.invitationLink);
                      toast({
                        title: t('collaborators.linkCopied'),
                        status: 'info',
                        duration: 6000,
                        isClosable: true,
                      });
                    }}
                  >
                    {t('collaborators.copy')}
                  </Button>
                </Flex>
              )}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={onClose} variant="outline">
                {t('collaborators.cancel')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};
