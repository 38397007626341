import { css } from '@emotion/core';

export const numberOfJobsChips = css`
  &.jobBadge {
    float: right;
    padding: 4px;
    color: #000000;
    border-radius: 4px;
    background: #f3f6f9;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
`;
export const openPotionTitle = css`
  &.openPotionTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
  }
`;
export const openPotionTitleWrapper = css`
  &.openPotionTitleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #f3f6f9;
    padding-right: 2px;
    color: #000000;
  }
`;
export const noBusinessMsg = css`
  &.noBusinessMsg {
    padding: 4px;
    color: #000000;
    text-align: center;
    font-size: 18px;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
