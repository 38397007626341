import React from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import { RiDatabase2Line } from 'react-icons/ri';
import StatWithProgressBarAndComparison from '../statWithProgressBarAndComparison/StatWithProgressBarAndComparison';

export type PieDataType = { name: string; value: number }[];

export type PieChartViewProps = {
  data: PieDataType;
  onSetActiveTab: () => void;
};
const COLORS = ['#2FC683', '#B33636', '#0094F0'];

const PieChartView = ({ data, onSetActiveTab }: PieChartViewProps): JSX.Element => {
  const { t } = useTranslation();

  const renderPieLabels = (color: string, label: string, value: number) => (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      fontWeight={600}
      fontSize={13}
      color="#000000"
      key={`${color}-${label}`}
    >
      <Box width="9px" height="6px" background={color} borderRadius="2px" mr={2} />
      <Text mr={2} opacity={0.5}>
        {label}
      </Text>
      <Box width={12} border="1px dashed #E2E7E7" />
      <Text ml={2} opacity={0.5}>
        {value}
      </Text>
    </Flex>
  );
  return (
    <Box width="100%" height="100%" pr={4}>
      <Flex justifyContent="space-between" paddingLeft="5%">
        <StatWithProgressBarAndComparison
          statHeadingValueStyle={{ flexDirection: 'column-reverse' }}
          headingValues={{
            heading1: t('recruiterManagement:recruiterDataVisualisation.totalJobPositions'),
            value1: data.reduce((partialSum, eachData) => partialSum + eachData.value, 0),
            heading1TooltipText: t('recruiterManagement:recruiterDashboardTooltipFor.totalJobPositions'),
            heading2: '',
            value2: 0,
          }}
        />

        <Button
          height={7}
          leftIcon={<RiDatabase2Line />}
          colorScheme="blue"
          variant="solid"
          fontSize={14}
          onClick={onSetActiveTab}
        >
          {t('administration:administrationSideBarMenuItems.positionManagement')}
        </Button>
      </Flex>

      <Flex height="70%" alignItems="center">
        <Box minW="40%" height="100%" alignItems="center">
          <ResponsiveContainer>
            <PieChart width={85} height={85}>
              <Pie
                data={data}
                cx="30%"
                cy="50%"
                innerRadius="65%"
                outerRadius="90%"
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${entry.name}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Flex direction="column" height="60%" justifyContent="space-evenly" width="max-content">
          {data.map((entry, index) => {
            return renderPieLabels(COLORS[index % COLORS.length], entry.name, entry.value);
          })}
        </Flex>
      </Flex>
    </Box>
  );
};

export default PieChartView;
