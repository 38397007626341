import { AxiosError, AxiosResponse } from 'axios';
import { Candidate, LanguageEvaluationRating, LanguageEvaluationStatus } from '../firebase/firestore/documents/candidate';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { RequestHireDetailForm } from '../modules/messaging/seeker/header/actionsDropdown/HireFormTypeEnum';

interface Availabilities {
  availabilities: string[];
  length: number;
  fromDate: Date;
}

interface AvailablePeriods {
  availabilities: Availabilities;
}

interface Data {
  availablePeriods: AvailablePeriods;
  duration: number;
  fenceTime: number;
  type: string;
}

interface ResponseContent {
  data: Data | unknown;
  config: string | unknown;
  headers: string | unknown;
  request: string | unknown;
  status: number;
  statusText: string;
}

export class CandidateController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async sendMessage(message: string, positionId: string, seekerId: string): Promise<void> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/sendEmployerMessage`;
    const body = { message };

    await this.httpClient.post(path, body);
  }

  async markAsRead(positionId: string, seekerId: string): Promise<void> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/recruiter/markAsRead`;
    await this.httpClient.post(path, {});
  }

  async createAppointmentRequest(positionId: string, seekerId: string): Promise<AxiosResponse | undefined> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/createAppointmentRequest`;
    return this.httpClient
      .post(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  // Do not remove monetized and displayProfilePicture even tho the API doesn't use them, it is to keep the uri unique for caching.
  // istanbul ignore next
  async getProfilePictureUrl({ position, seeker, monetized, displayProfilePicture }: Candidate): Promise<string> {
    const path = `/api/v2/position/${position}/seeker/${seeker}/profilePicture?monetized=${monetized}&displayProfilePicture=${displayProfilePicture}`;
    const { data } = await this.httpClient.get<ArrayBuffer>(path, { responseType: 'arraybuffer' });
    const bufferArray = new Uint8Array(data);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const stringBuffer = [].reduce.call(new Uint8Array(bufferArray), (p, c) => p + String.fromCharCode(c), '') as string;
    return `data:image/*;base64,${btoa(stringBuffer)}`;
  }

  async hireCandidateRequest(
    positionId: string,
    seekerId: string,
    hireDetails: string | null,
    salary: string,
    salaryUnit: string,
    dateOfJoining?: Date,
    customSalaryUnit?: string | null,
    requisitionId?: string,
    updatedPositionId?: string,
    updatedBusinessId?: string,
  ): Promise<void> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/hire`;
    const body = {
      hireDetails,
      offeredSalary: {
        salary,
        salaryUnit,
        customSalaryUnit,
      },
      dateOfJoining,
      requisitionId,
      updatedPositionId,
      updatedBusinessId,
    };
    await this.httpClient.post(path, body);
  }

  async requestHireDetails(
    candidateId: string,
    requestHireDetailFormFields: RequestHireDetailForm,
  ): Promise<AxiosResponse | undefined> {
    const path = `/api/v1/candidates/${candidateId}/hireDetail/request`;
    return this.httpClient
      .post(path, requestHireDetailFormFields)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async unHireCandidateRequest(positionId: string, seekerId: string, unhireDetails: string, unhireType: string): Promise<void> {
    const path = `/api/v1/positions/${positionId}/seekers/${seekerId}/hire`;
    const body = { data: { unhireDetails, unhireType } };
    await this.httpClient.delete(path, body);
  }

  async dismissCandidateRequest(
    positionId: string,
    seekerId: string,
    recruiterId: string,
    dismissalReason?: string,
  ): Promise<void> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/dismiss`;
    const body = { recruiterId, message: dismissalReason };
    await this.httpClient.post(path, body);
  }

  async undismissCandidateRequest(positionId: string, seekerId: string, recruiterId: string): Promise<void> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/bookmark`;
    const body = { recruiterId };
    await this.httpClient.post(path, body);
  }

  async loadAppointmentAvailabilities(positionId: string): Promise<ResponseContent> {
    const path = `/api/v1/position/${positionId}/appointmentAvailabilities`;
    return this.httpClient.get(path);
  }

  async backgroundCheckRequest(candidateId: string): Promise<AxiosResponse | undefined> {
    const path = `/api/v1/candidates/${candidateId}/screen/invite`;
    return this.httpClient
      .post(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async getCertnPdf(candidateId: string): Promise<string> {
    const path = `/api/v1/candidates/${candidateId}/screen/report`;
    const { data } = await this.httpClient.get<string>(path, { responseType: 'blob' });
    return data;
  }

  async loadHireDetailOfCandidate(candidateId: string): Promise<AxiosResponse | undefined> {
    const path = `/api/v1/candidates/${candidateId}/hireDetail`;
    return this.httpClient
      .get(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  /* istanbul ignore next */
  async loadCandidates(accountId: string): Promise<AxiosResponse | undefined> {
    const path = `api/v1/account/${accountId}/candidates/paginate`;
    return this.httpClient
      .get(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  /* istanbul ignore next */
  async loadCandidatesOfPositions(accountId: string, positionIds: string[]): Promise<AxiosResponse | undefined> {
    const path = `api/v2/account/${accountId}/candidates/paginate`;
    const body = { positionIds };
    return this.httpClient
      .post(path, body)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  /* istanbul ignore next */
  async loadCandidatesForNotifications(accountId: string, after: string, limit = 20): Promise<AxiosResponse | undefined> {
    const path = `api/v1/account/${accountId}/candidates/notifications?&limit=${limit}${after ? `&after=${after}` : ''}`;
    return this.httpClient
      .get(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async reviewLanguageEvaluation(
    positionId: string,
    seekerId: string,
    language: string,
    phraseId: string,
    rating: LanguageEvaluationRating,
    fluency: number,
    pronunciation: number,
    accuracy: number,
    status: LanguageEvaluationStatus,
  ): Promise<void> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/reviewLanguageEvaluation`;
    const body = {
      language,
      phraseId,
      rating,
      fluency,
      pronunciation,
      accuracy,
      status,
    };
    await this.httpClient.post(path, body);
  }

  async requestLanguageEvaluationResubmit(phraseId: string, candidateId: string, recruiterId: string): Promise<void> {
    const path = `/api/v1/candidates//${candidateId}/recording/${phraseId}?recruiterId=${recruiterId}`;
    await this.httpClient.delete(path);
  }

  async getPositionCandidates(positionId: string): Promise<AxiosResponse | undefined> {
    const path = `/api/v2/position/${positionId}/candidates`;
    return this.httpClient
      .get(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async saveFromDismissal(positionId: string, seekerId: string): Promise<void> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/saveFromDismissal`;
    await this.httpClient.post(path, {});
  }
}
