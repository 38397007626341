import { Candidate, CandidateStatus } from '../../../../../../firebase/firestore/documents/candidate';

export default class CandidatePickerItemPresenter {
  constructor(
    readonly id: string,
    readonly status: CandidateStatus,
    readonly positionName: string,
    readonly businessName: string,
    readonly businessCompleteAddress: string,
    readonly candidate: Candidate,
  ) {}
}
