import { Education } from '../../../../../firebase/firestore/documents/seeker';

export default class SeekerEducationPresenter {
  public readonly id = this.education.id;

  public readonly schoolName = this.education.schoolName;

  public readonly city = this.education.city;

  public readonly degree = this.education.degree;

  public readonly otherDegree = this.education.otherDegree;

  public readonly fieldOfStudy = this.education.fieldOfStudy;

  public readonly status = this.education.status;

  public readonly year = this.education.endDate?.year;

  public readonly month = this.education.endDate?.month;

  constructor(public readonly education: Education) {}
}
