import { Box, HStack } from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { FieldSchema, LabelSchema, Language } from './formElements/formBuilderSchema';
import FormBuilder from './formElements/FormBuilder';
import FormBuilderComponents from './formElements/FormBuilderComponents';
import FormPreview from './formElements/FormPreview';
import { useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../../models/hooks';

export interface SectionSchema {
  section: string;
  fields: FieldSchema[];
}

export default function HireformView() {
  const [formSchema, setFormSchema] = useState<SectionSchema[]>([{ section: 'section_1', fields: [] }]);
  const [templateName, setTemplateName] = useState<LabelSchema>({ en: 'Hire Form', fr: 'Formulaire de recrutement' });
  const [language, setLanguage] = useState<Language>('en');
  const { getTemplateById } = useStoreActions((state) => state.hrFormTemplate);
  const { currentTemplate } = useStoreState((state) => state.hrFormTemplate);

  let templateId = useParams<{ templateId?: string }>()?.templateId ?? undefined;

  useEffect(() => {
    if (templateId) {
      getTemplateById({ id: templateId });
    }
    // eslint-disable-next-line
  }, [templateId]);

  useEffect(() => {
    if (!currentTemplate) return;
    // Create a map to group fields by their section
    const grouped: SectionSchema[] = [];
    if (currentTemplate.name) {
      setTemplateName((prev) => ({ ...prev, [language]: currentTemplate.name }));
    }
    const fields = currentTemplate.questions;
    if (fields.length === 0) {
      grouped.push({ section: 'section_1', fields: [] });
    } else {
      fields.forEach((field, index) => {
        // If the field has a section, group it under that section
        const sectionName = field.section ?? 'section_' + (index + 1);
        const existingSection = grouped.find((group) => group.section === sectionName);

        if (existingSection) {
          existingSection.fields.push(field);
        } else {
          grouped.push({ section: sectionName, fields: [field] });
        }
      });
    }
    setFormSchema(grouped);
    // eslint-disable-next-line
  }, [currentTemplate]);

  return (
    <HStack align="flex-start" spacing={10} backgroundColor="#F3F3F3" h="100%">
      <FormBuilderComponents formSchema={formSchema} setFormSchema={setFormSchema} />
      <FormBuilder
        formSchema={formSchema}
        setFormSchema={setFormSchema}
        language={language}
        templateName={templateName}
        setTemplateName={setTemplateName}
      />

      <Box flex="0 0 25%" overflowY="auto" overflowX="hidden" minHeight="0" height="100%">
        <FormPreview schema={formSchema} language={language} onLanguageChange={setLanguage} />
      </Box>
    </HStack>
  );
}
