import React, { Fragment, useEffect, useState } from 'react';
import { Box, Checkbox, Flex, Spinner, Stack, useDisclosure } from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';
import _flatten from 'lodash/flatten';
import moment from 'moment';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { CurrentUserProfilePresenter } from '../../../app/hooks/CurrentUserProfilePresenter';
import { GoogleUser } from '../../../firebase/firestore/documents/user';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import {
  CalendarEventType,
  GoogleEventsType,
  OtherCalendarErrorResponseType,
  ValidateGoogleAuthType,
} from '../CalendarEventType';
import { CalendarScheduleTypeEnum } from '../CalendarScheduleTypeEnum';
import { ConfirmationDialog } from '../../common/AlertDialog/ConfirmationDialog';
import { TeamInviteAction, TeamMemberAction } from '../../administration/team/TeamType';
import { gmailCheckCSS } from '../CalendarView.Style';
import { OtherAccountTypeEnum } from '../OtherAccountTypeEnum';

export type GoogleEventsViewProps = {
  currentUserProfile: CurrentUserProfilePresenter;
  isGoogleLogin: React.MouseEvent | undefined;
  onOtherCalendarModalClose: () => void;
};

const GoogleEventsView = ({
  currentUserProfile,
  isGoogleLogin,
  onOtherCalendarModalClose,
}: GoogleEventsViewProps): JSX.Element => {
  const { t } = useTranslation('calendar');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [isLoading, setIsLoading] = useState<{ [x: string]: boolean; loading: boolean }>({ loading: false });
  const [activeGmail, setActiveGmail] = useState<string | undefined>();
  const [deleteMessage, setDeleteMessage] = useState<string>(t('otherCalendar.deleteConfirmationLabel'));
  const { isOpen, onOpen, onClose } = useDisclosure();

  const gmailColors = ['green', 'orange', 'pink', 'cyan', 'purple'];

  const {
    getOtherCalendarEvent,
    setGoogleCalendarEvent,
    updateCheckedEmailStatus,
    removeOtherCalendarAccountAuth,
    validateOtherCalendarAuthToken,
  } = useStoreActions((actions) => actions.calendar);
  const { googleCalendarEvent, currentQuarterOnCalendar } = useStoreState((state) => state.calendar);

  const filterEventWithValidValues = (googleEvents: CalendarEventType[]): CalendarEventType[] => {
    return googleEvents
      .filter((googleEvent) => !_isNil(googleEvent.title) && !_isNil(googleEvent.start) && !_isNil(googleEvent.end))
      .map((googleEvent) => ({
        ...googleEvent,
        start: moment(googleEvent.start).toDate(),
        end: moment(googleEvent.end).toDate(),
        resource: {
          ...googleEvent.resource,
          type: CalendarScheduleTypeEnum.GOOGLE_EVENT,
        },
      }));
  };

  const getStyleForGmail = (email: string | undefined) => {
    const googleConnectedAccount = _sortBy(currentUserProfile?.googleCalendar, 'gmail');
    const indexOfEmail = googleConnectedAccount.findIndex((r) => r.gmail === email) % 10;
    return gmailColors[indexOfEmail > 0 ? indexOfEmail : 0];
  };

  const filterGoogleEventWithEmail = (events: CalendarEventType[], email: string) => {
    return events.filter((googleEvent) => googleEvent.email !== email);
  };

  const getGoogleEventByEmailAddress = async (
    currentUser: CurrentUserProfilePresenter,
    emailValue: string,
    calendarAccount: OtherAccountTypeEnum,
  ): Promise<CalendarEventType[]> => {
    setIsLoading((prevState) => ({
      ...prevState,
      [emailValue]: true,
      loading: true,
    }));
    return getOtherCalendarEvent({
      accountId: currentUser?.account,
      userId: currentUser?.id,
      email: emailValue,
      fromDate: moment(currentQuarterOnCalendar.date)
        .quarter(currentQuarterOnCalendar.quarter)
        .startOf('quarter')
        .subtract(7, 'days')
        .format('YYYY/MM/DD'),
      toDate: moment(currentQuarterOnCalendar.date)
        .quarter(currentQuarterOnCalendar.quarter)
        .endOf('quarter')
        .add(7, 'days')
        .format('YYYY/MM/DD'),
      calendarAccount,
    })
      .then((getCalendarEventResponse) => {
        const { events } = getCalendarEventResponse as GoogleEventsType;
        const { status } = getCalendarEventResponse as OtherCalendarErrorResponseType;

        if (status === 400) {
          setActiveGmail(emailValue);
          setDeleteMessage(t('otherCalendar.deleteConfirmationLabelWithEmail', { emailValue }));
          onOpen();
          return [];
        }
        return filterEventWithValidValues(events);
      })
      .finally(() => {
        setIsLoading((prevState) => ({
          ...prevState,
          [emailValue]: false,
          loading: false,
        }));
      });
  };

  const loadAllGoogleCalendarEvents = () => {
    const googleEventRequests: Promise<CalendarEventType[]>[] = [];
    // eslint-disable-next-line consistent-return
    currentUserProfile?.googleCalendar?.forEach((account) => {
      if (account.active) {
        return googleEventRequests.push(
          getGoogleEventByEmailAddress(currentUserProfile, account.gmail, OtherAccountTypeEnum.GOOGLE),
        );
      }
    });
    Promise.all(googleEventRequests)
      .then((allGoogleEvents: CalendarEventType[][]) => {
        setGoogleCalendarEvent(_flatten(allGoogleEvents));
      })
      .catch((error) => console.log(error));
  };

  // istanbul ignore next
  const loadGoogleCalendarEvents = async (
    checked: boolean,
    emailValue: string,
    currentUser: CurrentUserProfilePresenter,
    calendarAccount: OtherAccountTypeEnum,
  ) => {
    if (checked) {
      const filteredGoogleCalendarEvent = !currentUser.googleCalendar?.find((account) => account.gmail === emailValue)
        ? googleCalendarEvent
        : filterGoogleEventWithEmail(googleCalendarEvent, emailValue);

      setGoogleCalendarEvent([
        ...filteredGoogleCalendarEvent,
        ...(await getGoogleEventByEmailAddress(currentUser, emailValue, OtherAccountTypeEnum.GOOGLE)),
      ]);
    } else {
      await updateCheckedEmailStatus({
        accountId: currentUser?.account,
        userId: currentUser?.id,
        email: emailValue,
        calendarAccount,
      });
      setGoogleCalendarEvent(filterGoogleEventWithEmail(googleCalendarEvent, emailValue));
    }
  };

  const loadGoogleAccounts = (googleAccounts: GoogleUser[]): JSX.Element => {
    const gmailList = _sortBy(googleAccounts, 'gmail').map((value) => (
      <Flex key={value.gmail} css={gmailCheckCSS} data-testid={`checkBtn-${value.gmail}`}>
        <Checkbox
          isChecked={value.active}
          isDisabled={Object.values(isLoading).includes(true)}
          value={value.gmail}
          onChange={
            /* istanbul ignore next */ (e) =>
              loadGoogleCalendarEvents(e.target.checked, value.gmail, currentUserProfile, OtherAccountTypeEnum.GOOGLE)
          }
          colorScheme={getStyleForGmail(value.gmail)}
        >
          {value.gmail}
          {isLoading && isLoading[value.gmail] && <Spinner size="xs" ml={2} />}
        </Checkbox>
        <RiDeleteBin5Fill
          cursor="pointer"
          className="deleteIcon"
          data-testid={`deleteBtn-${value.gmail}`}
          onClick={() => {
            setDeleteMessage(t('otherCalendar.deleteConfirmationLabel'));
            onOpen();
            setActiveGmail(value.gmail);
          }}
        />
      </Flex>
    ));

    return (
      <Stack spacing={[1, 0]} direction="column" overflow="visible">
        {gmailList}
      </Stack>
    );
  };

  const handleConfirm = async (action: TeamMemberAction | TeamInviteAction) => {
    // istanbul ignore else
    if (action && activeGmail) {
      await removeOtherCalendarAccountAuth({
        accountId: currentUserProfile.account,
        userId: currentUserProfile.id,
        email: activeGmail,
        calendarAccount: OtherAccountTypeEnum.GOOGLE,
      });
      setGoogleCalendarEvent(filterGoogleEventWithEmail(googleCalendarEvent, activeGmail));
    }
    onClose();
  };

  // istanbul ignore next
  const googleLogin = useGoogleLogin({
    ux_mode: 'popup',
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar',
    onSuccess: async (response: CodeResponse) => {
      if (currentUserProfile) {
        const validateGoogleResponse = await validateOtherCalendarAuthToken({
          accountId: currentUserProfile?.account,
          userId: currentUserProfile?.id,
          code: response.code,
          calendarAccount: OtherAccountTypeEnum.GOOGLE,
        });
        const successValidateGoogleResponse = validateGoogleResponse as ValidateGoogleAuthType;
        const failedValidateGoogleResponse = validateGoogleResponse as OtherCalendarErrorResponseType;
        if (successValidateGoogleResponse && successValidateGoogleResponse.gmail) {
          loadGoogleCalendarEvents(
            true,
            successValidateGoogleResponse.gmail,
            currentUserProfile,
            OtherAccountTypeEnum.GOOGLE,
          ).catch((e) => console.log(e));
        }
        if (failedValidateGoogleResponse && failedValidateGoogleResponse.status === 400) {
          setDeleteMessage(t(`${failedValidateGoogleResponse.code}`));
          onOpen();
          setActiveGmail(undefined);
        }
        onOtherCalendarModalClose();
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  useEffect(() => {
    loadAllGoogleCalendarEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuarterOnCalendar]);

  useEffect(() => {
    currentUserProfile?.googleCalendar?.map((account) => {
      if (account.calendarUpdated) {
        loadGoogleCalendarEvents(true, account.gmail, currentUserProfile, OtherAccountTypeEnum.GOOGLE).catch((e) =>
          console.log(e),
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserProfile]);

  useEffect(() => {
    // istanbul ignore next
    if (isGoogleLogin) {
      googleLogin();
    }
  }, [isGoogleLogin, googleLogin]);

  return (
    <Fragment>
      <Box p={2} pb={0}>
        {currentUserProfile?.googleCalendar && loadGoogleAccounts(currentUserProfile?.googleCalendar)}
      </Box>
      <ConfirmationDialog
        isLoading={false}
        isOpen={isOpen}
        onClose={onClose}
        message={deleteMessage}
        action={activeGmail ? TeamMemberAction.DELETE : null}
        handleConfirm={handleConfirm}
      />
    </Fragment>
  );
};

export default GoogleEventsView;
