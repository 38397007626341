import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Heading,
  Text,
  useToast,
  VStack,
  Tooltip,
  ListItem,
  UnorderedList,
  Alert,
  Link,
  AlertIcon,
} from '@chakra-ui/core';
import isNil from 'lodash/isNil';
import { Trans, useTranslation } from 'react-i18next';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Lookups } from '../../../../firebase/firestore/documents/lookups';
import { backgroundCheckForAdminstrationStyle } from '../../../messaging/seeker/header/actionsDropdown/ConfirmationModal.styles';
import { BackgroundChecks } from '../../../../firebase/firestore/documents/candidate';
import useBackgroundChecks from '../../../../app/hooks/useBackgroundChecks';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { getTranslatedValue } from '../../../../utils/localizedString';

const initialBackgroundCheck = {
  request_instant_verify_education: false,
  request_instant_verify_employment: false,
  request_enhanced_identity_verification: false,
  request_criminal_record_check: false,
  request_enhanced_criminal_record_check: false,
  request_us_criminal_record_check_tier_1: false,
  request_us_criminal_record_check_tier_2: false,
  request_soquij: false,
};

export type CertnBackgroundChecksProps = {
  accountId: string | undefined;
};

export const CertnBackgroundChecks = ({ accountId }: CertnBackgroundChecksProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [backgroundCheckList, setBackgroundCheckList] = useState<BackgroundChecks>({ ...initialBackgroundCheck });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const toast = useToast();
  const { t, i18n } = useTranslation();
  const backgroundChecks = useBackgroundChecks();
  const { administrationBackgroundCheck } = useStoreActions((actions) => actions.administration);
  const screeningOptions = useStoreState((state) => state.app.accounts?.screeningOptions);

  const submitBackgroundCheckList = async () => {
    if (!accountId) {
      return;
    }

    const validateBackgroundCheckList =
      backgroundCheckList.request_soquij ||
      backgroundCheckList.request_enhanced_identity_verification ||
      backgroundCheckList.request_instant_verify_education ||
      backgroundCheckList.request_instant_verify_employment ||
      backgroundCheckList.request_us_criminal_record_check_tier_1 ||
      backgroundCheckList.request_us_criminal_record_check_tier_2;

    if (!validateBackgroundCheckList) {
      setErrorMessage(t('certn:checksValidation'));
    } else {
      try {
        setIsLoading(true);
        const backgroundCheckResponse = await administrationBackgroundCheck({
          accountId,
          backgroundCheckList,
        });
        toast({
          title: backgroundCheckResponse.message,
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const setBackgroundCheckValue = (value: React.ChangeEvent<HTMLInputElement>, field: string) => {
    let selectedValues: BackgroundChecks = { ...backgroundCheckList };

    setErrorMessage(null);

    if (field === 'request_criminal_record_check') {
      selectedValues = {
        ...selectedValues,
        request_enhanced_identity_verification: true,
        request_enhanced_criminal_record_check: false,
      };
    } else if (field === 'request_enhanced_criminal_record_check') {
      selectedValues = {
        ...selectedValues,
        request_enhanced_identity_verification: true,
        request_criminal_record_check: false,
      };
    } else if (field === 'request_enhanced_identity_verification') {
      selectedValues = {
        ...selectedValues,
        request_criminal_record_check: false,
        request_enhanced_criminal_record_check: false,
      };
    } else if (field === 'request_us_criminal_record_check_tier_1') {
      selectedValues = {
        ...selectedValues,
        request_us_criminal_record_check_tier_2: false,
      };
    } else if (field === 'request_us_criminal_record_check_tier_2') {
      selectedValues = {
        ...selectedValues,
        request_us_criminal_record_check_tier_1: false,
      };
    }

    selectedValues = {
      ...selectedValues,
      [field]: value.currentTarget.checked,
    };

    setBackgroundCheckList(selectedValues);
  };

  const showFullDescription = (id: string) => {
    const selectElement = document.getElementById(id);
    // istanbul ignore next
    if (isNil(selectElement)) {
      return;
    }
    selectElement.classList.toggle('more');
  };

  const showInfoTooltip = (id: string) => {
    let toolTipMessage = '';

    switch (id) {
      case 'request_criminal_record_check':
      case 'request_enhanced_criminal_record_check':
        toolTipMessage = t('certn:tooltipMessageForCanadianBaseRecordCheck');
        break;
      default:
        toolTipMessage = t('certn:tooltipMessageForUSBasedRecordCheck');
    }
    return (
      <Box ml={1}>
        <Tooltip hasArrow label={toolTipMessage} bg="gray.900" fontSize="sm" placement="right">
          <InfoOutlineIcon />
        </Tooltip>
      </Box>
    );
  };

  const checkListItem = (bc: Lookups) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const valueCheck: boolean = backgroundCheckList[bc.id];
    return (
      <Box mb={5} key={bc.id}>
        <Box className="bc-content" css={backgroundCheckForAdminstrationStyle}>
          <Checkbox
            data-testid="CheckboxTest"
            colorScheme="blue"
            key={bc.id}
            value={bc.id}
            onChange={(e) => setBackgroundCheckValue(e, bc.id)}
            isChecked={valueCheck}
            alignItems="flex-start"
          >
            <Box pl="10px" mt="-1px">
              <Heading d="flex" as="h4" lineHeight="1.1" marginBottom="5px" fontSize="md">
                {getTranslatedValue(bc.label, i18n.language)}
                {(bc.id === 'request_criminal_record_check' ||
                  bc.id === 'request_enhanced_criminal_record_check' ||
                  bc.id === 'request_us_criminal_record_check_tier_1' ||
                  bc.id === 'request_us_criminal_record_check_tier_2') &&
                  showInfoTooltip(bc.id)}
              </Heading>
            </Box>
          </Checkbox>
          <Box pl="35px" id={bc.id}>
            <Box mb={1} className="bc-description">
              <Text fontSize="sm">{getTranslatedValue(bc.description, i18n.language)}</Text>
            </Box>
            <Box
              color="blue.default"
              fontSize="xs"
              fontWeight="700"
              data-testid="ShowFullDescriptionBtn"
              onClick={() => showFullDescription(bc.id)}
              className="bc-description"
              style={{ cursor: 'pointer' }}
            >
              <Text className="add-icon">{t('certn:more')}</Text>
              <Text className="remove-icon">{t('certn:less')}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (!isNil(screeningOptions)) {
      setBackgroundCheckList(screeningOptions);
    }
  }, [screeningOptions]);

  return (
    <Box>
      <Box px={6} py={4}>
        <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
          {t('certn:certnSetupLabel')}
        </Heading>
        <Text fontSize="xs" color="gray.500">
          {t('certn:selectRequiredLabel')}
        </Text>
      </Box>
      <Divider borderColor="gray.50" />
      <Box p={6}>
        <Box className="bc-content" css={backgroundCheckForAdminstrationStyle}>
          <Checkbox
            data-testid="CheckboxSoquij"
            mb={3}
            value="request_soquij"
            onChange={(e) => setBackgroundCheckValue(e, 'request_soquij')}
            colorScheme="blue"
            alignItems="flex-start"
            isChecked={backgroundCheckList.request_soquij}
          >
            <Box pl="10px" mt="-1px">
              <Heading d="flex" as="h4" lineHeight="1.1" marginBottom="5px" fontSize="md">
                <Trans i18nKey="certn:soquijEnableLabel">
                  <Box fontWeight="bold" as="span" mr={2}>
                    SOQUIJ
                  </Box>
                </Trans>
                <Tooltip
                  hasArrow
                  label={t('certn:soquijTooltipMessage')}
                  fontSize="sm"
                  placement="right"
                  bg="gray.900"
                  borderRadius="3px"
                >
                  <InfoOutlineIcon />
                </Tooltip>
              </Heading>
            </Box>
          </Checkbox>
          <Box>
            <Box mb={1}>
              <UnorderedList fontSize="sm" mb={3}>
                <ListItem>
                  <Trans i18nKey="certn:soquijHint1">
                    <Box as="span" color="blue.500" mr={2}>
                      SOQUIJ
                    </Box>
                  </Trans>
                </ListItem>
              </UnorderedList>
            </Box>
            <Alert
              fontSize="sm"
              status="error"
              borderLeftWidth="3px"
              borderRadius="2px"
              variant="left-accent"
              backgroundColor="gray.50"
            >
              <AlertIcon />
              <Trans i18nKey="certn:soquijSupportLabel">
                This check isn&apos;t available by default. Contact
                <Link href="https://docs.certn.co/api/contact" isExternal fontWeight="bold" color="blue.500" mx={1}>
                  Certn&apos;s support team
                </Link>
                to enable it on your account.
                <Link href="mailto:apisupport@certn.co" isExternal fontWeight="bold" color="blue.500" ml={1}>
                  apisupport@certn.co
                </Link>
              </Trans>
            </Alert>
          </Box>
        </Box>
      </Box>
      <Divider borderColor="gray.50" />
      <Box p={6}>
        <VStack alignItems="flex-start" mb={3}>
          {backgroundChecks.length > 0 &&
            backgroundChecks.sort((a, b) => (a.order > b.order ? 1 : -1)).map((bc) => checkListItem(bc))}
        </VStack>
        {errorMessage && (
          <Text mt={5} fontSize="sm" color="red.500">
            {errorMessage}
          </Text>
        )}
        <Box textAlign="right">
          <Button data-testid="ConfirmButton" isLoading={isLoading} colorScheme="blue" onClick={submitBackgroundCheckList}>
            {t('certn:save')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
