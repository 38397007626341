export default class SpecificQuestionPresenter {
  constructor(
    readonly id: string,
    readonly text: string,
    readonly preferredAnswer: boolean,
    readonly answer: boolean,
    readonly answered: boolean,
    readonly knockout: boolean,
    readonly knockedOut: boolean,
    readonly isActive?: boolean,
  ) {}
}
