import { AxiosResponse } from 'axios';

import HttpClientWithRetry from '../http/client/HttpClientWithRetry';

export class ModernHireController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getModernHireResult(candidateId: string): Promise<AxiosResponse> {
    const path = `/api/v1/candidate/${candidateId}/modernHire/results`;
    return this.httpClient.get(path);
  }
}
