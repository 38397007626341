import { Thunk, thunk } from 'easy-peasy';
import { HttpStoreModel } from '../http/models/httpModel';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { UnsubscribeController } from '../controllers/unsubscribeController';

export type UnSubscribeModelManagementStoreModel = {
  unSubscribeModel: UnSubscribeModel;
};

export interface UnSubscribeModel {
  unSubscribeEmail: Thunk<UnSubscribeModel, { userID: string }, void, HttpStoreModel, Promise<string>>;
}

export const unsubscribeManagementStoreModel: UnSubscribeModel = {
  unSubscribeEmail: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new UnsubscribeController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.unSubscribeEmail(payload.userID);
    return response?.data as string;
  }),
};
