/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { memo } from 'react';
import { Flex, Box, Center, Container, Spacer, Avatar } from '@chakra-ui/core';
import { Participant, Track, RemoteParticipant } from 'twilio-video';
import { MdAccountCircle } from 'react-icons/md';
import { usePublications } from './twilio/hooks/usePublications';
import { Publication } from './twilio/Publication';
import { MediaTypeEnum } from './MediaTypeEnum';
import { TRACK_AUDIO, TRACK_CAMERA, TRACK_SCREEN, TRACK_VIDEO } from './constants';
import { ReactComponent as _IconVideo } from '../../assets/img/icon-video-white.svg';
import { ReactComponent as _IconVideoOff } from '../../assets/img/icon-video-off.svg';
import { ReactComponent as _IconMicroPhone } from '../../assets/img/icon-microphone-white.svg';
import { ReactComponent as _IconMicroPhoneOff } from '../../assets/img/icon-mute.svg';
import { publicationTrack } from './MeetViewStyle';

export type ParticipantTrackProps = {
  participant: Participant | RemoteParticipant;
  mediaType: MediaTypeEnum;
  videoPriority: Track.Priority | null;
  showScreenShare?: boolean;
  participantName?: string;
  isRecruiter?: boolean;
  isVideoEnabled?: boolean;
  isMicEnabled?: boolean;
};

const IconVideo = memo(_IconVideo);
const IconVideoOff = memo(_IconVideoOff);
const IconMicroPhone = memo(_IconMicroPhone);
const IconMicroPhoneOff = memo(_IconMicroPhoneOff);

export const ParticipantTrack = ({
  participant,
  mediaType,
  videoPriority,
  showScreenShare,
  participantName,
  isRecruiter,
  isVideoEnabled,
  isMicEnabled,
}: ParticipantTrackProps): JSX.Element => {
  const publications = usePublications({ participant });

  let filteredPublications;
  if (showScreenShare && publications.some((p) => p.trackName.includes(TRACK_SCREEN))) {
    filteredPublications = publications.filter((p) => !p.trackName.includes(TRACK_CAMERA));
  } else {
    filteredPublications = publications.filter((p) => !p.trackName.includes(TRACK_SCREEN));
  }

  const hasVideo = publications.filter(
    (p) => p.kind.includes(TRACK_SCREEN) || p.trackName.includes(TRACK_CAMERA) || p.kind.includes(TRACK_VIDEO),
  );
  const hasAudio = publications.filter((p) => p.kind.includes(TRACK_AUDIO) && p.isTrackEnabled);

  return (
    <Box css={publicationTrack} data-testid={`ParticipantTrack-${participant.identity}`} className="publication-track">
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          mediaType={mediaType}
          videoPriority={videoPriority}
          shouldOffVideoTrack={!isRecruiter && !isVideoEnabled}
        />
      ))}

      {hasVideo.length === 0 || (!isRecruiter && !isVideoEnabled) ? (
        <Container
          centerContent
          className="avatar-container"
          style={{ height: '100%', width: '280px' }}
          data-testid="NoVideoIcon"
        >
          <Center style={{ height: '100%', width: '100%' }}>
            <Box className="avatar-block">
              <Avatar bg={isRecruiter ? 'blue.500' : 'orange.500'} name={participantName} color="#ffffff" />
            </Box>
          </Center>
        </Container>
      ) : (
        ''
      )}

      <Flex className="participant-info-bar">
        {participantName && (
          <Flex alignItems="center" className="participant-name">
            <Box as="span" color={isRecruiter ? 'blue.500' : 'orange.500'} mr={1} fontSize="14px">
              <MdAccountCircle />
            </Box>
            <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" w="100%">
              {participantName}
            </Box>
          </Flex>
        )}
        <Spacer />
        <Flex>
          {!isRecruiter ? (
            <Box className="icon-box">
              {!isVideoEnabled ? <IconVideoOff data-testid="VideoDisabledIcon" /> : <IconVideo data-testid="VideoEnabledIcon" />}
            </Box>
          ) : (
            <Box className="icon-box">
              {hasVideo.length === 0 ? (
                <IconVideoOff data-testid="VideoDisabledIcon" />
              ) : (
                <IconVideo data-testid="VideoEnabledIcon" />
              )}
            </Box>
          )}
          {!isRecruiter ? (
            <Box className="icon-box">
              {!isMicEnabled ? (
                <IconMicroPhoneOff data-testid="AudioDisabledIcon" />
              ) : (
                <IconMicroPhone data-testid="AudioEnabledIcon" />
              )}
            </Box>
          ) : (
            <Box className="icon-box">
              {hasAudio.length === 0 ? (
                <IconMicroPhoneOff data-testid="AudioDisabledIcon" />
              ) : (
                <IconMicroPhone data-testid="AudioEnabledIcon" />
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

ParticipantTrack.defaultProps = {
  showScreenShare: false,
};
