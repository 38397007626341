import React, { useState, useEffect, useRef } from 'react';
import { MdPlayCircle, MdPauseCircle, MdGraphicEq } from 'react-icons/md';
import { Box, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/core';
import _isNaN from 'lodash/isNaN';
import { AudioPlayerCss } from './AudioPlayer.style';
import colors from '../../../styles/colors';

export type AudioPlayerProps = {
  audioSource: string;
};

const AudioPlayer = ({ audioSource }: AudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    const handleLoadedMetadata = () => {
      // istanbul ignore else
      if (audio) {
        setDuration(Math.floor(audio?.duration));
      }
    };

    const handleTimeUpdate = () => {
      // istanbul ignore else
      if (audio) {
        setCurrentTime(Math.floor(audio.currentTime));
        setProgress((audio.currentTime / audio.duration) * 100);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        audio?.currentTime === audio?.duration && setPlaying(false);
      }
    };

    audio?.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio?.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      audio?.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio?.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    // istanbul ignore else
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        audio.play();
      }
    }
    setPlaying(!isPlaying);
  };

  const handleProgressChange = (sliderValue: number) => {
    const audio = audioRef.current;
    // istanbul ignore else
    if (audio) {
      const audioDuration = audio.duration;
      const audioCurrentTime = audio.currentTime;

      const currentAudioTime = (sliderValue * audio.duration) / 100;

      if (Math.round(audioDuration) === Math.round(audioCurrentTime) && sliderValue === 100) {
        return;
      }

      // istanbul ignore else
      if (Number.isFinite(currentAudioTime)) {
        audio.currentTime = currentAudioTime;

        setProgress(sliderValue);
        setCurrentTime(Math.floor(currentAudioTime));
      }
    }
  };

  // istanbul ignore next
  function formatTime(timeInSeconds: number) {
    const s = timeInSeconds;
    const minutes = Math.floor(s / 60);
    const seconds = s % 60;
    if (!_isNaN(minutes) && !_isNaN(seconds)) {
      return Number.isNaN(s) ? '0:00' : `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }

    return '0:00';
  }

  return (
    <Box css={AudioPlayerCss}>
      <audio ref={audioRef} src={audioSource} data-testid="audioElement">
        <track kind="captions" />
      </audio>
      <Flex alignItems="center">
        <div className="progressCenter">
          <button onClick={handlePlayPause} data-testid="playPausebutton" type="button">
            {isPlaying ? (
              <MdPauseCircle style={{ marginRight: '10px', height: '48px', width: '48px' }} />
            ) : (
              <MdPlayCircle style={{ marginRight: '10px', height: '48px', width: '48px' }} />
            )}
          </button>
          <span className="currentTimeDuration">{formatTime(currentTime)}</span>
          <Slider
            defaultValue={progress}
            value={progress}
            min={0}
            max={100}
            onChange={handleProgressChange}
            className="progressSlider"
            data-testid="progress-slider"
          >
            <SliderTrack bg={colors.carolinaBlue}>
              <Box position="relative" right={10} />
              <SliderFilledTrack bg={colors.persianBlue} />
            </SliderTrack>
            <SliderThumb boxSize={4} className="sliderThumb" zIndex={0}>
              <Box color={colors.persianBlue} as={MdGraphicEq} />
            </SliderThumb>
          </Slider>
          <span className="currentTimeDuration currentDurationText" data-testid="durationElement">
            {formatTime(duration)}
          </span>
        </div>
      </Flex>
    </Box>
  );
};

export default AudioPlayer;
