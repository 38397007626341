import { action, Action, thunk, Thunk } from 'easy-peasy';
import { HttpStoreModel } from '../../../http/models/httpModel';
import { GalleryImageType, ImageGalleryResponse, ImageGalleryUploadResponse } from '../imageGallery/ImageGalleryType';
import { ImageGalleryController } from '../../../controllers/imageGalleryController';
import HttpClientWithRetry from '../../../http/client/HttpClientWithRetry';
import { extractFilenameFromPath } from '../imageGallery/extractFilenameFromPath';

type ImageGallerySortingOrderType = 'asc' | 'desc';
export enum ImageGallerySortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}
export type ImageGalleryStoreModel = {
  imageGallery: ImageGalleryModel;
};

export interface ImageGalleryModel {
  images: GalleryImageType[];
  setImages: Action<ImageGalleryModel, GalleryImageType[]>;
  filteredImages: GalleryImageType[];
  setFilteredImages: Action<ImageGalleryModel, GalleryImageType[]>;
  sortingOrder: ImageGallerySortingOrderType;
  setSortingOrder: Action<ImageGalleryModel>;
  isGalleryLoading: boolean;
  setGalleryLoader: Action<ImageGalleryModel, boolean>;
  isGalleryRefetching: boolean;
  setGalleryRefetch: Action<ImageGalleryModel, boolean>;
  isImageUploadingToGallery: boolean;
  setImageUpload: Action<ImageGalleryModel, boolean>;
  getImageGallery: Thunk<
    ImageGalleryModel,
    { accountId: string; refetch?: boolean },
    void,
    HttpStoreModel,
    Promise<ImageGalleryResponse>
  >;
  uploadImageToGallery: Thunk<
    ImageGalleryModel,
    { accountId: string; formData: FormData },
    void,
    HttpStoreModel,
    Promise<ImageGalleryUploadResponse>
  >;
}

export const imageGalleryStoreModel: ImageGalleryModel = {
  images: [],
  setImages: action((state, payload) => {
    const uniqueIds = new Set(state.images.map((image) => image.id));
    const result: GalleryImageType[] = [...state.images];

    payload.forEach((newImage) => {
      if (!uniqueIds.has(newImage.id)) {
        uniqueIds.add(newImage.id);
        result.push(newImage);
      }
    });

    result.sort((a, b) => {
      if (state.sortingOrder === ImageGallerySortingOrder.ASC) {
        return extractFilenameFromPath(a.path)?.localeCompare(extractFilenameFromPath(b.path)!) || 0;
      }
      return extractFilenameFromPath(b.path)?.localeCompare(extractFilenameFromPath(a.path)!) || 0;
    });
    state.filteredImages = result;
    state.images = result;
  }),
  filteredImages: [],
  setFilteredImages: action((state, payload) => {
    const result: GalleryImageType[] = [...payload];
    result.sort((a, b) => {
      if (state.sortingOrder === ImageGallerySortingOrder.ASC) {
        return extractFilenameFromPath(a.path)?.localeCompare(extractFilenameFromPath(b.path)!) || 0;
      }
      return extractFilenameFromPath(b.path)?.localeCompare(extractFilenameFromPath(a.path)!) || 0;
    });
    state.filteredImages = result;
  }),
  sortingOrder: ImageGallerySortingOrder.ASC,
  setSortingOrder: action((state) => {
    state.sortingOrder =
      state.sortingOrder === ImageGallerySortingOrder.ASC ? ImageGallerySortingOrder.DESC : ImageGallerySortingOrder.ASC;
  }),
  isGalleryLoading: false,
  setGalleryLoader: action((state, payload) => {
    state.isGalleryLoading = payload;
  }),
  isGalleryRefetching: false,
  setGalleryRefetch: action((state, payload) => {
    state.isGalleryRefetching = payload;
  }),
  isImageUploadingToGallery: false,
  setImageUpload: action((state, payload) => {
    state.isImageUploadingToGallery = payload;
  }),
  getImageGallery: thunk(async (actions, { accountId, /** istanbul ignore next  */ refetch = false }, { getStoreState }) => {
    if (refetch) {
      actions.setGalleryRefetch(true);
    } else {
      actions.setGalleryLoader(true);
    }

    const controller = new ImageGalleryController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller
      .getImageGallery(accountId)
      .then((res) => {
        actions.setImages(res?.data?.images ?? []);
        return res;
      })
      .finally(() => {
        actions.setGalleryLoader(false);
        actions.setGalleryRefetch(false);
      });
    return response?.data as ImageGalleryResponse;
  }),
  uploadImageToGallery: thunk((actions, payload, { getStoreState }) => {
    actions.setImageUpload(true);

    const controller = new ImageGalleryController(getStoreState().http.client as HttpClientWithRetry);
    return controller
      .uploadImageToGallery(payload.accountId, payload.formData)
      .then((response) => {
        actions.setImages([{ ...response.data.data, account: payload.accountId }]);
        return response.data;
      })
      .finally(() => {
        actions.setImageUpload(false);
      });
  }),
};
