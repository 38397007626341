/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import colors from '../../../styles/colors';

export type HireConfirmationModalProps = {
  isOpen: boolean;
  imageIndex: number;
  onImageRemove: (index: number) => void;
  onClose: () => void;
};

export const LogoDeleteConfirmationModal = ({
  isOpen,
  imageIndex,
  onImageRemove,
  onClose,
}: HireConfirmationModalProps): JSX.Element => {
  const { user } = useStoreState((s) => s.app);
  const toast = useToast();
  const { saveCompanyLogo } = useStoreActions((actions) => actions.administration);
  const { t } = useTranslation();

  const removeLogo = async () => {
    // istanbul ignore else
    if (user) {
      const data = {
        accountId: user.account,
        companyLogo: '',
      };

      await saveCompanyLogo(data)
        .then(function (response) {
          toast({
            title: t('uploadCompanyLogo:apiResponse.title'),
            description: response.message,
            duration: 3000,
            isClosable: true,
          });
        })
        .catch(function () {
          toast({
            title: t('uploadCompanyLogo:apiResponse.title'),
            description: t('uploadCompanyLogo:apiResponse.errorText'),
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Modal id="HireConfirmationModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader className="modal-header">
            <Flex direction="row" alignItems="center">
              {t('uploadCompanyLogo:removeConfirmationModal.headerText')}
            </Flex>
          </ModalHeader>
          <ModalBody>{t('uploadCompanyLogo:removeConfirmationModal.bodyText')}</ModalBody>
          <ModalFooter>
            <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={onClose}>
              {t('uploadCompanyLogo:removeConfirmationModal.no')}
            </Button>
            <Button
              data-testid="ConfirmButton"
              background={colors.blue.default}
              color="white"
              // onClick={() => onImageRemove(imageIndex)}
              onClick={async () => {
                await removeLogo();
                onImageRemove(imageIndex);
              }}
            >
              {t('uploadCompanyLogo:removeConfirmationModal.yes')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
