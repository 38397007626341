import React from 'react';
import { Grid, GridItem, Text, Spinner, Stack, Center } from '@chakra-ui/core';
import { FaCheck } from 'react-icons/fa';
import { IoIosImages } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import theme from '../../../styles/customTheme';
import { useStoreState } from '../../../models/hooks';
import type { GalleryImageType } from './ImageGalleryType';
import { useFetchImageGallery } from './hooks/useFetchImageGallery';
import ImageView from '../../../app/components/ImageView';
import { ImagePreviewModal } from '../../../app/components/ImagePreviewModal';
import { extractFilenameFromPath } from './extractFilenameFromPath';

interface ImageGalleryProps {
  selected?: GalleryImageType | null;
  onSelected?: (image: GalleryImageType | null) => void;
}

/**
 * ImageGallery is a component that renders a grid of images.
 *
 * @param {GalleryImageType} [props.selected] the currently selected image
 * @param {Function} [props.onSelected] callback function invoked when an image is selected
 * @returns {React.ReactElement} the image gallery component
 */
export default function ImageGallery({ selected, onSelected }: ImageGalleryProps) {
  const { filteredImages, isGalleryLoading } = useStoreState((state) => state.imageGallery);
  const [expanded, setExpanded] = React.useState<GalleryImageType | null>(null);
  const { t } = useTranslation();
  const GRID_ITEM_SIZE = 120;

  useFetchImageGallery({});

  if (isGalleryLoading) {
    return (
      <Center width="100%" flexGrow={1} height="-webkit-fill-available">
        <Stack direction="column" align="center" spacing={4} data-testid="loading">
          <Spinner color="#1F3CBA" data-testid="spinner" />
          <Text color={theme.colors.black}>{t('administration:imageGallery:loading')}</Text>
        </Stack>
      </Center>
    );
  }

  if (filteredImages?.length === 0 && !isGalleryLoading) {
    return (
      <Center width="100%" flexGrow={1} height="-webkit-fill-available">
        <Stack direction="column" align="center" spacing={4} data-testid="noImages">
          <IoIosImages color={theme.colors.gray[400]} size={50} />
          <Text color={theme.colors.gray[400]}>{t('administration:imageGallery:noImages')}</Text>
        </Stack>
      </Center>
    );
  }
  if (!filteredImages) {
    return null;
  }

  const onSelectImage = (image: GalleryImageType) => {
    if (onSelected) {
      const isSelected = selected?.id === image.id;
      onSelected(isSelected ? null : image);
    }
  };

  return (
    <>
      <Grid
        templateColumns={`repeat(auto-fill, minmax(${GRID_ITEM_SIZE}px, 1fr))`}
        autoRows={`minmax(1fr, ${GRID_ITEM_SIZE}px)`}
        gridGap={1}
        data-testid="ImageGallery"
      >
        {filteredImages.map((image) => {
          if (!image) {
            return null;
          }
          const isSelected = selected?.id === image.id;
          return (
            <GridItem
              backgroundColor={isSelected ? theme.colors.green[100] : theme.colors.gray[50]}
              key={image.id}
              borderRadius={theme.radii.base}
              // overflow="hidden"
              position="relative"
              cursor="pointer"
              onClick={() => onSelectImage(image)}
              aria-selected={isSelected}
              outline="2px dashed transparent"
              outlineOffset={1}
              _selected={{
                outline: '2px solid',
                outlineOffset: 0,
                outlineColor: theme.colors.green[300],
              }}
              _hover={{
                borderRadius: theme.radii.md,
                outlineColor: theme.colors.blue[200],
              }}
              transition="all 0.3s ease-in-out"
              data-testid="gallery-image"
            >
              <Stack title={extractFilenameFromPath(image.path)} spacing={1}>
                <ImageView
                  src={image.url}
                  alt={image.url}
                  shape="rounded"
                  height="100%"
                  onExpand={() => {
                    setExpanded(image);
                  }}
                />
                <Text fontSize="xs" noOfLines={1} isTruncated fontWeight="bold">
                  {extractFilenameFromPath(image.path)}
                </Text>
              </Stack>

              {isSelected && (
                <FaCheck
                  color={theme.colors.white}
                  size={20}
                  style={{
                    position: 'absolute',
                    top: '12px',
                    left: '12px',
                    padding: '4px',
                    backgroundColor: theme.colors.lightBlue[400],
                    borderRadius: theme.radii.base,
                  }}
                />
              )}
            </GridItem>
          );
        })}
      </Grid>
      <ImagePreviewModal
        setTrigger={Boolean(expanded)}
        images={filteredImages}
        getImageKey={(image) => image?.id || ''}
        getImageSrc={(image) => image?.url || ''}
        getImageAlt={(image) => image?.path || ''}
        onModalClose={() => setExpanded(null)}
        selectedImage={selected}
        setSelectedImage={onSelected}
        currentActive={expanded}
      />
    </>
  );
}

ImageGallery.defaultProps = {
  selected: null,
  onSelected: null,
};
