import firebase from 'firebase/app';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useEffect, useMemo } from 'react';
import Bugsnag from '@bugsnag/js';
import { formatDocument } from '../documentConverter';
import { useUserIsAuthenticated } from '../../../app/hooks/useUserIsAuthenticated';
import { Collections } from '../collections';

const getFirestoreDocumentReference = (
  userIsAuthenticated: boolean,
  requireAuthentication: boolean,
  collection: Collections,
  documentId?: string,
): firebase.firestore.DocumentReference | undefined => {
  if (requireAuthentication && !userIsAuthenticated) {
    return undefined;
  }

  if (!documentId) {
    return undefined;
  }

  return firebase.firestore().collection(collection).doc(documentId);
};

export const useDocumentSnapshot = <T>(
  collection: Collections,
  documentId?: string,
  requireAuthentication = true,
): T | undefined => {
  const userIsAuthenticated = useUserIsAuthenticated();

  const [documentSnapshot, loading, error] = useDocument(
    getFirestoreDocumentReference(userIsAuthenticated, requireAuthentication, collection, documentId),
  );

  useEffect(() => {
    if (error) {
      Bugsnag.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.context = `Firestore useDocumentSnapshot Error ${collection}`;
        // eslint-disable-next-line no-param-reassign
        event.groupingHash = `useDocumentSnapshot.${collection}`;
        event.addMetadata('query', { collection, documentId });
      });
    }
  }, [error, collection, documentId]);

  return useMemo(() => {
    if (documentSnapshot && !error && !loading) {
      return formatDocument<T>(documentSnapshot);
    }

    return undefined;
  }, [documentSnapshot, loading, error]);
};
