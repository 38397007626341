import React from 'react';
import { SchemaBuilderProps } from './formBuilderSchema';
import { HStack, Stack } from '@chakra-ui/core';
import ButtonPreview from './ButtonPreview';
import FormElementTextBuilder from './FormElementTextBuilder';
import LabelSchemaBuilder from './LabelSchemaBuilder';

export default function ImageSchemaBuilder(props: SchemaBuilderProps) {
  return (
    <Stack>
      <LabelSchemaBuilder {...props} />
      <FormElementTextBuilder {...props} placeholder="Enter text here for image input..." />
      <HStack>
        <ButtonPreview label="Choose a file" />
        <ButtonPreview label="Open your camera" />
      </HStack>
    </Stack>
  );
}
