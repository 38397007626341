import _isNil from 'lodash/isNil';
import useEventRecruiters from './useEventRecruiters';
import { EventDetailPresenter } from './EventDetailPresenter';
import { CalendarGroupEventType } from '../CalendarGroupEventType';
import useEventCandidates from './useEventCandidates';

const useEventDetail = (calendarEvent: CalendarGroupEventType | undefined): EventDetailPresenter => {
  const recruiters = useEventRecruiters(calendarEvent?.groupEvent?.id);
  const candidates = useEventCandidates(calendarEvent);

  if (calendarEvent && !_isNil(recruiters) && !_isNil(candidates)) {
    return new EventDetailPresenter(calendarEvent, recruiters, candidates);
  }

  return new EventDetailPresenter(undefined, [], []);
};

export default useEventDetail;
