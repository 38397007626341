import { AxiosResponse } from 'axios';
import moment from 'moment';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { AccountRequisitionStats } from '../firebase/firestore/documents/managementOverview';

export class ManagementOverviewController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getAccountRequisitionStats(accountId: string): Promise<AccountRequisitionStats> {
    const path = `/api/v1/account/${accountId}/dashboard/accountRequisitionStats`;
    const response = await this.httpClient.get<AccountRequisitionStats>(path);
    return response.data;
  }

  async getAccountOfferStats(accountId: string, fromDate: Date, toDate: Date): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/dashboard/accountOfferStats?fromDate=${moment(fromDate).format(
      'YYYY/MM/DD',
    )}&toDate=${moment(toDate).format('YYYY/MM/DD')}`;
    const response = await this.httpClient.get<AxiosResponse>(path);
    return response.data;
  }
}
