import { Button, ButtonProps, Tooltip } from '@chakra-ui/core';
import React from 'react';
import { TbSortAscendingLetters, TbSortDescendingLetters } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import theme from '../../../styles/customTheme';
import { ImageGallerySortingOrder } from '../models/imageGalleryModel';

type PickButtonProps = Pick<ButtonProps, 'size' | 'variant' | 'colorScheme'>;
interface ImageGallerySortButtonProps extends PickButtonProps {
  iconSize?: number;
}

/**
 * A button to sort the image gallery in ascending or descending order.
 *
 * The button is labeled with the current sorting order and can be clicked to toggle the sorting order.
 *
 * The button takes the following props:
 *
 * - `size`: The size of the button. Defaults to `lg`.
 * - `variant`: The variant of the button. Defaults to `outline`.
 * - `colorScheme`: The color scheme of the button. Defaults to `gray`.
 * - `iconSize`: The size of the icon. Defaults to the size of the button.
 *
 * The button is a controlled component and will always render the current sorting order.
 */
export default function ImageGallerySortButton({ size, variant, colorScheme, iconSize }: ImageGallerySortButtonProps) {
  const { setSortingOrder, setFilteredImages } = useStoreActions((actions) => actions.imageGallery);
  const { images, sortingOrder, filteredImages, isGalleryRefetching, isGalleryLoading } = useStoreState(
    (state) => state.imageGallery,
  );

  const { t } = useTranslation();

  return (
    <Tooltip
      hasArrow
      label={t(
        `administration:imageGalleryModal:${sortingOrder === ImageGallerySortingOrder.DESC ? 'sortAscending' : 'sortDescending'}`,
      )}
      borderRadius={theme.radii.base}
    >
      <Button
        px={2}
        onClick={() => {
          setSortingOrder();
          setFilteredImages(filteredImages);
        }}
        isDisabled={isGalleryLoading || isGalleryRefetching || images.length === 0}
        data-testid="image-gallery-sort-button"
        {...{ size, variant, colorScheme }}
      >
        {sortingOrder === ImageGallerySortingOrder.ASC ? (
          <TbSortDescendingLetters size={iconSize} data-testid="image-gallery-sort-button-asc" />
        ) : (
          <TbSortAscendingLetters size={iconSize} data-testid="image-gallery-sort-button-desc" />
        )}
      </Button>
    </Tooltip>
  );
}

ImageGallerySortButton.defaultProps = {
  iconSize: 24,
  // eslint-disable-next-line
  size: 'lg',
  // eslint-disable-next-line
  variant: 'outline',
  // eslint-disable-next-line
  colorScheme: 'gray',
};
