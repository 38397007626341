/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, memo } from 'react';
import { Flex, AvatarGroup, Box, Avatar } from '@chakra-ui/core';
import { css } from '@emotion/core';
import { ParticipantView } from './ParticipantView';
import { MeetRemoteParticipantInfo } from './MeetParticipantResponse';
import { useDominantSpeaker } from './twilio/hooks/useDominantSpeaker';
import { ReactComponent as _IconUsers } from '../../assets/img/icon-users.svg';

const IconUsers = memo(_IconUsers);

const participantStyle = css`
  flex-wrap: wrap;
  height: 100%;
  overflow-y: auto;
  .participant-view {
    height: auto;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 5px;
  }
`;

type ParticipantListProps = {
  // room: Room;
  filteredParticipant: MeetRemoteParticipantInfo[];
};

export const ParticipantList = ({ filteredParticipant }: ParticipantListProps): JSX.Element => {
  const dominantSpeaker = useDominantSpeaker();
  const totalParticipant = [...filteredParticipant];
  let limitedFilteredParticipant = filteredParticipant;

  if (totalParticipant.length > 12) {
    limitedFilteredParticipant = filteredParticipant.splice(0, 11);
  }

  // eslint-disable-next-line consistent-return
  const addDynamicClass = () => {
    const totalParticipantLength = totalParticipant.length;
    if (totalParticipantLength < 3) {
      return 'one-row-two-column';
    }
    if (totalParticipantLength === 3) {
      return 'one-row-three-column';
    }
    if (totalParticipantLength > 3 && totalParticipantLength < 7) {
      return 'two-row-three-column';
    }
    if (totalParticipantLength > 6 && totalParticipantLength < 9) {
      return 'two-row-four-column';
    }
    return 'three-row-four-column';
  };

  return (
    <Flex className={`participant-list ${addDynamicClass()}`} css={participantStyle} data-testid="ParticipantList">
      <Fragment>
        {limitedFilteredParticipant.map((p) => {
          return (
            <ParticipantView
              key={p.participant.sid}
              participant={p.participant}
              isSelected={p.participant === dominantSpeaker}
              hideParticipant={false}
              participantName={`${p.participantInfo.firstName} ${p.participantInfo.lastName}`}
            />
          );
        })}
        {totalParticipant.length > 12 ? (
          <Box className="participant-view participant-view-more">
            <AvatarGroup>
              <Avatar bg="gray.100" icon={<IconUsers height="24px" width="24px" fill="red" />} />
              <Box className="btn-more">+ more</Box>
            </AvatarGroup>
          </Box>
        ) : (
          ''
        )}
      </Fragment>
    </Flex>
  );
};
