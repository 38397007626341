import { css } from '@emotion/core';
import colors from '../../../../../styles/colors';

export const NotesViewWrapper = css`
  overflow-y: auto;
  position: relative;
  background-color: ${colors.neutralGray['100']};

  .chakra-ui-dark & {
    background-color: ${colors.black};
  }

  .note-block {
    border: 1px solid ${colors.dividerGray};
    border-radius: 3px;
    background-color: ${colors.white};
  }

  .note-header {
    padding: 5px 10px 10px;
    line-height: 1.8;
    border-bottom: 1px solid ${colors.dividerGray};

    .recruiter-name {
      color: #a0aec0;
    }
    .note-header-box {
      padding: 0 18px;
      .title {
        line-height: 1.6;
        margin-bottom: 5px;
      }
      &.date-box {
        border-right: 1px solid #ccc;
        .date {
          line-height: 1.2;
          p {
            font-size: 12px;
          }
          .lg {
            font-size: 30px;
            color: #83d6ff;
            padding-right: 6px;
            font-weight: 700;
          }
        }
      }
    }

    .position {
      font-size: 18px;
      line-height: 1.4;
    }

    .chakra-badge {
      padding: 0.05rem 0.7rem;
      text-transform: initial;
      border-radius: 5px;

      &.hired {
        background: #c2ebff;
        color: #049be5;
      }
      &.dismissed {
        background: #ffb8b8;
        color: #b33636;
      }
    }

    .title {
      line-height: 1.6;
      margin-right: 10px;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .note-body {
    padding: 15px 10px 15px;

    div p:last-child {
      margin-bottom: 0;
    }

    .note-content {
      h1 {
        font-size: 1.5rem;
      }

      h2 {
        font-size: 1.2rem;
      }

      h3 {
        font-size: 1rem;
      }

      h1,
      h2,
      h3 {
        font-weight: 700;
      }

      a {
        text-decoration: underline;
      }

      p,
      ul,
      ol {
        font-size: 14px;
      }

      ul,
      ol {
        padding-left: 30px;
      }
    }
  }

  .btn-write-note {
    position: absolute;
    bottom: 40px;
    right: 70px;
    border-radius: 19px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.13);
    background: #fff;

    @media only screen and (max-width: 60rem) {
      right: 40px;
    }
  }

  .ql-snow {
    border: 2px solid #eeeeee;
  }

  .ql-editor {
    min-height: 100px;
  }

  .interviewer-info {
    .chakra-heading {
      font-size: 14px;
      font-weight: 500;
      color: #000;
    }
    .chakra-text {
      color: #a0aec0;
    }
  }

  .note-list-icon {
    background: #dedede;
    padding: 3px;
    border-radius: 3px;
    width: 15px;
    height: 15px;
    color: #1f3cba;
    margin-right: 15px;
    margin-top: -2px;
  }

  .inteviewer-note {
    position: relative;
    padding-left: 28px;
    font-size: 12px;
    color: #4a5568;
    cursor: pointer;

    .view-more-link {
      margin-left: 10px;
      cursor: pointer;
      color: ${colors.blue['400']};
    }
  }
`;
