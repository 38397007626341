import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isUndefined from 'lodash/isUndefined';
import { Candidate, FreeFormQuestionResponse } from '../../../../../firebase/firestore/documents/candidate';
import { Position, FreeFormQuestion } from '../../../../../firebase/firestore/documents/position';
import FreeFormQuestionPresenter from './freeFormQuestionPresenter';

const ignoreQuestion = (freeFormQuestion: FreeFormQuestion): boolean => _isEmpty(freeFormQuestion.text);

const getCandidateResponse = (questionId: string, candidate: Candidate): FreeFormQuestionResponse | undefined => {
  return _find(candidate.freeFormQuestionResponses, { id: questionId });
};

const getCandidateAnswer = (questionResponse?: FreeFormQuestionResponse): string => {
  return questionResponse?.answer || '';
};

const isQuestionAnswered = (questionResponse?: FreeFormQuestionResponse): boolean => {
  return !_isUndefined(questionResponse?.answer) && !_isEmpty(questionResponse?.answer);
};

export default class FreeFormQuestionsPresenter {
  public questions: FreeFormQuestionPresenter[] = [];

  constructor(private readonly candidate: Candidate, private readonly position: Position) {
    position.freeFormQuestions?.forEach((freeFormQuestion) => {
      if (!ignoreQuestion(freeFormQuestion) && freeFormQuestion.isActive !== false) {
        const candidateResponse = getCandidateResponse(freeFormQuestion.id, candidate);
        this.questions.push(
          new FreeFormQuestionPresenter(
            freeFormQuestion.id,
            freeFormQuestion.text,
            isQuestionAnswered(candidateResponse),
            getCandidateAnswer(candidateResponse),
            freeFormQuestion.isActive,
          ),
        );
      }
    });
  }

  public getQuestionsCount = (): number => {
    const countActiveQuestions = this.questions.filter((obj) => obj.isActive !== false).length;
    return countActiveQuestions;
  };

  public getAnsweredQuestionsCount = (): number => {
    return _filter(this.questions, (question) => question.answered && question.isActive !== false).length;
  };
}
