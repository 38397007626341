import { Collections, SubCollections } from '../../../../../firebase/firestore/collections';
import { Requisition } from '../../../../../firebase/firestore/documents/requisition';
import { useSubCollectionQuerySnapshot } from '../../../../../firebase/firestore/hooks';
import { Query } from '../../../../../firebase/firestore/query/query';

const usePositionByRequisition = (positionId: string, reqId?: string): Requisition[] | undefined => {
  const requisitions: Requisition[] | undefined = useSubCollectionQuerySnapshot<Requisition>(
    Collections.POSITIONS,
    positionId,
    SubCollections.REQUISITIONS,
    [Query.field('status').in(['OPEN', 'PROCESSING'])],
  );
  if (reqId) {
    return requisitions?.filter((r: Requisition) => {
      return r.requisitionId === reqId || r.status === 'OPEN';
    });
  }
  return requisitions?.filter((r: Requisition) => {
    return r.status === 'OPEN';
  });
};

export default usePositionByRequisition;
