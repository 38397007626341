import React, { Fragment, useState } from 'react';
import { Box, Divider, Flex, Heading, Spacer, Tooltip, useDisclosure } from '@chakra-ui/core';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { RiAddFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { CurrentUserProfilePresenter } from '../../../app/hooks/CurrentUserProfilePresenter';
import { useStoreState } from '../../../models/hooks';
import colors from '../../../styles/colors';
import OtherCalendarModal from './OtherCalendarModal';
import GoogleEventsView from './GoogleEventsView';
import MicrosoftEventsView from './MicrosoftEventsView';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';

export type GoogleEventsViewProps = {
  currentUserProfile: CurrentUserProfilePresenter | undefined;
};

const OtherCalendarView = ({ currentUserProfile }: GoogleEventsViewProps): JSX.Element => {
  const [isGoogleLogin, setIsGoogleLogin] = useState<React.MouseEvent>();
  const {
    isOpen: isOtherCalendarModalOpen,
    onOpen: onOtherCalendarModalOpen,
    onClose: onOtherCalendarModalClose,
  } = useDisclosure();
  const { t } = useTranslation('calendar');

  const isGoogleCalendarIntegrateEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_INTEGRATE_GOOGLE_CALENDAR_FEATURE];
  });
  const isMicrosoftCalendarIntegrateEnabled = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_INTEGRATE_MICROSOFT_CALENDAR_FEATURE];
  });

  const microsoftLogin = () => {
    window.location.replace(
      `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
        process.env.REACT_APP_MICROSOFT_CALENDAR_CLIENT_ID
      }&response_type=code&redirect_uri=${encodeURIComponent(
        window.location.href,
      )}&response_mode=query&scope=user%2Eread%20calendars%2EreadWrite%20offline_access&prompt=select_account`,
    );
  };

  // istanbul ignore next
  const handleGoogleLogin = (event: React.MouseEvent) => {
    setIsGoogleLogin(event);
  };

  return (
    <Fragment>
      <Flex
        px={3}
        py={2}
        data-testid="GoogleEventViewBlock"
        borderTop={`1px solid ${colors.gray[100]}`}
        alignItems="center"
        cursor="pointer"
      >
        <Heading as="h3" size="sm">
          {t('otherCalendar.otherCalendarLabel')}
        </Heading>
        <Spacer />
        <Tooltip label={t('otherCalendar.addNewTooltip')} shouldWrapChildren fontSize="md">
          <RiAddFill data-testid="connectOtherCalendarAccountBtn" onClick={() => onOtherCalendarModalOpen()} />
        </Tooltip>
      </Flex>
      <Divider borderColor="gray.100" />
      <Box px={2} py={1} height="180px" overflowY="auto">
        {currentUserProfile && (
          <Fragment>
            {process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID && isGoogleCalendarIntegrateEnabled && (
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID}>
                <GoogleEventsView
                  currentUserProfile={currentUserProfile}
                  isGoogleLogin={isGoogleLogin}
                  onOtherCalendarModalClose={onOtherCalendarModalClose}
                />
              </GoogleOAuthProvider>
            )}
            {process.env.REACT_APP_MICROSOFT_CALENDAR_CLIENT_ID && isMicrosoftCalendarIntegrateEnabled && (
              <MicrosoftEventsView currentUserProfile={currentUserProfile} />
            )}
            {(!currentUserProfile?.googleCalendar || currentUserProfile?.googleCalendar.length <= 0) &&
              (!currentUserProfile?.microsoftCalendar || currentUserProfile?.microsoftCalendar.length <= 0) && (
                <Heading px={1} fontWeight="normal" fontSize="sm" as="h4">
                  {t('otherCalendar.noData')}
                </Heading>
              )}
          </Fragment>
        )}
      </Box>
      <OtherCalendarModal
        isOpen={isOtherCalendarModalOpen}
        onClose={onOtherCalendarModalClose}
        setIsGoogleLogin={handleGoogleLogin}
        microsoftLogin={() => microsoftLogin()}
      />
    </Fragment>
  );
};

export default OtherCalendarView;
