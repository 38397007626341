import _isNil from 'lodash/isNil';

import { Candidate } from '../../firebase/firestore/documents/candidate';
import { Collections } from '../../firebase/firestore/collections';
import { Query } from '../../firebase/firestore/query/query';
import { useCurrentUserProfile } from '../../app/hooks/useCurrentUserProfile';
import { useQueryOnce } from '../../firebase/firestore/hooks';
import { useStoreState } from '../../models/hooks';
import { CurrentUserProfilePresenter } from '../../app/hooks/CurrentUserProfilePresenter';

const ADMIN = 'ADMIN';
const RECRUITER = 'RECRUITER';
export enum CandidatesRenderedByEnum {
  CALENDAR_VIEW = 'CALENDAR_VIEW',
  USE_CALENDAR = 'USE_CALENDAR',
}
const getConditionalQueries = (
  userType: string,
  currentUserProfile: CurrentUserProfilePresenter | undefined,
  isOperator: boolean | undefined,
  activeCurrentDateRange?: { fromDate: Date; toDate: Date },
  renderedBy?: CandidatesRenderedByEnum,
) => {
  const queries = [
    Query.field<Candidate>('positionOpen').equals(true),
    Query.field<Candidate>('monetized').equals(true),
    Query.field('appointment.fromDate').greaterThanOrEqual(activeCurrentDateRange?.fromDate),
    Query.field('appointment.fromDate').smallerThanOrEqual(activeCurrentDateRange?.toDate),
  ];
  if (!isOperator && !(userType === ADMIN)) {
    queries.push(Query.field<Candidate>('assignedRecruiters').arrayContains(currentUserProfile?.id));
  }

  if (renderedBy === CandidatesRenderedByEnum.USE_CALENDAR) {
    /* @ts-ignore */
    queries.push(Query.field<Candidate>('appointment.status').equals('accepted'));
  }
  return queries;
};

const getAuthorizedAccounts = (
  isOperator: boolean,
  accessibleAccounts: string[],
  currentUserProfile: CurrentUserProfilePresenter | undefined,
) => {
  let authorizedAccounts: string[] = [];
  if (_isNil(currentUserProfile)) return authorizedAccounts;
  if (isOperator) {
    authorizedAccounts = accessibleAccounts;
    if (!accessibleAccounts.includes(currentUserProfile.account)) authorizedAccounts.push(currentUserProfile?.account);
  } else {
    authorizedAccounts.push(currentUserProfile?.account);
  }

  return authorizedAccounts;
};

const useCandidates = (
  activeCurrentDateRange?: { fromDate: Date; toDate: Date },
  candidatesRenderedBy?: CandidatesRenderedByEnum,
): Candidate[] => {
  const isOperator = useStoreState((s) => s.app.user?.operator?.enabled) || false;
  const accessibleAccounts = useStoreState((s) => s.app.user?.operator?.accessibleAccounts) || [];
  const defaultAccount = useStoreState((s) => s.app.user?.operator?.defaultAccount);

  const { accounts } = useStoreState((s) => s.app);
  const { currentUserProfile } = useCurrentUserProfile();

  const accountStore = useStoreState((s) => s.app.accounts);
  const groupEventEnabled = accountStore?.configuration?.groupEventEnabled;
  const userType = currentUserProfile && accounts?.owners?.includes(currentUserProfile?.id) ? ADMIN : RECRUITER;

  if (defaultAccount && accessibleAccounts && !accessibleAccounts.includes(defaultAccount))
    accessibleAccounts.push(defaultAccount);

  const checkForGroupEnabled = () => {
    const authorizedAccount: string[] = [];
    if (currentUserProfile) authorizedAccount.push(currentUserProfile.account);
    if (!_isNil(groupEventEnabled) && groupEventEnabled) {
      return authorizedAccount;
    }
    return [];
  };

  const authorizedAccounts =
    candidatesRenderedBy === CandidatesRenderedByEnum.CALENDAR_VIEW
      ? checkForGroupEnabled()
      : getAuthorizedAccounts(isOperator, accessibleAccounts, currentUserProfile);

  const candidates =
    useQueryOnce<Candidate>(Collections.CANDIDATES, [
      Query.field<Candidate>('account').in(authorizedAccounts.length ? authorizedAccounts.slice(0, 9) : ['']),
      ...getConditionalQueries(userType, currentUserProfile, isOperator, activeCurrentDateRange, candidatesRenderedBy),
    ]) || [];

  const candidates1 =
    useQueryOnce<Candidate>(Collections.CANDIDATES, [
      Query.field<Candidate>('account').in(authorizedAccounts.length > 9 ? authorizedAccounts.slice(9, 19) : ['']),
      ...getConditionalQueries(userType, currentUserProfile, isOperator, activeCurrentDateRange, candidatesRenderedBy),
    ]) || [];

  const candidates2 =
    useQueryOnce<Candidate>(Collections.CANDIDATES, [
      Query.field<Candidate>('account').in(authorizedAccounts.length > 19 ? authorizedAccounts.slice(19, 29) : ['']),
      ...getConditionalQueries(userType, currentUserProfile, isOperator, activeCurrentDateRange, candidatesRenderedBy),
    ]) || [];

  let mergedCandidates: Candidate[] = [];

  if (candidates && candidates1 && candidates2 && currentUserProfile) {
    mergedCandidates = candidates.concat(candidates1).concat(candidates2);
  }

  if (!_isNil(mergedCandidates) && mergedCandidates.length) return mergedCandidates;
  return [];
};
export default useCandidates;
