/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import {
  Box,
  VStack,
  Checkbox,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Heading,
  useToast,
  ModalCloseButton,
  Center,
  Tooltip,
} from '@chakra-ui/core';
import isNil from 'lodash/isNil';
import { Trans, useTranslation } from 'react-i18next';
import { RiAddCircleLine, RiIndeterminateCircleLine } from 'react-icons/ri';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { confirmationModalHeaderCSS, backgroundCheckStyle } from './ConfirmationModal.styles';
import useBackgroundChecks from '../../../../../app/hooks/useBackgroundChecks';
import { useStoreActions } from '../../../../../models/hooks';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { BackgroundChecks } from '../../../../../firebase/firestore/documents/candidate';
import { Lookups } from '../../../../../firebase/firestore/documents/lookups';
import { usePositionBackgroundCheckSnapshot } from './usePositionBackgroundCheckSnapshot';
import { getTranslatedValue } from '../../../../../utils/localizedString';

export type BackgroundCheckModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const BackgroundCheckModal = ({ isOpen, onClose }: BackgroundCheckModalProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  const accountLevelScreeningOption: BackgroundChecks = useStoreState((s) => s.app.accounts.screeningOptions);

  const [backgroundCheckList, setBackgroundCheckList] = useState<BackgroundChecks>({
    request_instant_verify_education: false,
    request_instant_verify_employment: false,
    request_enhanced_identity_verification: false,
    request_criminal_record_check: false,
    request_enhanced_criminal_record_check: false,
    request_us_criminal_record_check_tier_1: false,
    request_us_criminal_record_check_tier_2: false,
    request_soquij: false,
  });
  const backgroundChecks = useBackgroundChecks();
  const { selectedCandidate } = useSeekerContainerContext();
  // istanbul ignore next
  const backgroundCheck = useStoreActions((actions) => actions.messaging.backgroundCheck);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const position = usePositionBackgroundCheckSnapshot(selectedCandidate?.position);
  const positionScreeningOption = position?.screeningOptions;

  const { t, i18n } = useTranslation();
  const toast = useToast();

  const lastUpdatedDate = selectedCandidate?.screeningDetails?.lastUpdatedDate;

  const submitBackgroundCheckList = async () => {
    if (selectedCandidate) {
      setIsLoading(true);
      const backgroundCheckResponse = await backgroundCheck({ candidateId: selectedCandidate.id });
      if (backgroundCheckResponse?.status === 400) {
        toast({
          title: backgroundCheckResponse.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: backgroundCheckResponse?.message,
          status: 'info',
          duration: 9000,
          isClosable: true,
        });
      }
      setIsLoading(false);
      onClose();
    } else {
      setErrorMessage('Something went wrong');
      setIsLoading(false);
    }
  };

  const showFullDescription = (id: string) => {
    const selectElement = document.getElementById(id);
    // istanbul ignore next
    if (isNil(selectElement)) {
      return;
    }
    selectElement.classList.toggle('more');
  };

  const checkListItem = (bc: Lookups) => {
    if (!isNil(backgroundCheckList)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const valueCheck: boolean = backgroundCheckList[bc.id];
      return (
        <Box marginBottom="15px" key={bc.id} className="bc-content" css={backgroundCheckStyle}>
          <Checkbox
            isDisabled={!valueCheck ?? /* istanbul ignore next */ false}
            data-testid="CheckboxTest"
            colorScheme="blue"
            key={bc.id}
            value={bc.id}
            isChecked={valueCheck}
            alignItems="flex-start"
          >
            <Box pl="20px" mt="-1px">
              <Heading as="h4" lineHeight="1.1" marginBottom="5px" fontSize="md">
                {getTranslatedValue(bc.label, i18n.language)}
              </Heading>
            </Box>
          </Checkbox>
          <Flex
            pl="42px"
            id={bc.id}
            data-testid="ShowFullDescriptionBtn"
            onClick={() => showFullDescription(bc.id)}
            className="bc-description"
          >
            <Text fontSize="sm">{getTranslatedValue(bc.description, i18n.language)}</Text>
            <Box color="blue.500" fontSize="lg" pl={3}>
              <RiAddCircleLine className="add-icon" />
              <RiIndeterminateCircleLine className="remove-icon" />
            </Box>
          </Flex>
        </Box>
      );
    }
    return null;
  };

  useEffect(() => {
    if (
      !isNil(positionScreeningOption) &&
      Object.keys(Object.fromEntries(Object.entries(positionScreeningOption).filter(([, v]) => v))).length
    ) {
      setBackgroundCheckList(positionScreeningOption);
    } else {
      setBackgroundCheckList(accountLevelScreeningOption);
    }
  }, [positionScreeningOption, accountLevelScreeningOption, position]);

  return (
    <Modal
      id="DismissConfirmationModal"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick
      size="lg"
    >
      <ModalOverlay>
        <ModalContent py={6} px={2} borderRadius="30px">
          <ModalHeader css={confirmationModalHeaderCSS} className="modal-header">
            <Flex direction="row" justifyContent="center">
              <Heading fontSize="24px" color="black" fontWeight="500">
                {t('certn:bgCheckStatusLabel')}
              </Heading>
            </Flex>
            <Center>
              <Text fontSize="sm" color="#A0AEC0">
                {t('certn:lastSubmitDateLabel')}
              </Text>
              &nbsp;
              <Text fontSize="sm">{moment(lastUpdatedDate).format('L')}</Text>
            </Center>
          </ModalHeader>
          <ModalCloseButton mr={4} />
          <ModalBody>
            <VStack alignItems="flex-start">
              <Box marginBottom="5px" className="bc-content" css={backgroundCheckStyle}>
                <Checkbox
                  data-testid="CheckboxSoquij"
                  mb={3}
                  value="request_soquij"
                  isChecked={backgroundCheckList?.request_soquij}
                  colorScheme="blue"
                  alignItems="flex-start"
                  isDisabled={!backgroundCheckList?.request_soquij ?? /* istanbul ignore next */ false}
                >
                  <Box pl="20px" mt="-1px">
                    <Heading d="flex" as="h4" lineHeight="1.1" marginBottom="5px" fontSize="md">
                      <Trans i18nKey="certn:soquijEnableLabel">
                        <Box fontWeight="bold" as="span" mr={2}>
                          SOQUIJ
                        </Box>
                      </Trans>
                      <Tooltip
                        hasArrow
                        label={t('certn:soquijTooltipMessage')}
                        fontSize="sm"
                        placement="right"
                        bg="gray.900"
                        borderRadius="3px"
                      >
                        <InfoOutlineIcon />
                      </Tooltip>
                    </Heading>
                  </Box>
                </Checkbox>
              </Box>
              {backgroundChecks.length > 0 && backgroundChecks.map((bc) => checkListItem(bc))}
            </VStack>
            {errorMessage && (
              <Text mt={5} fontSize="sm" color="red.500">
                {errorMessage}
              </Text>
            )}
          </ModalBody>
          {Object.keys(Object.fromEntries(Object.entries(backgroundCheckList ?? {}).filter(([, v]) => v))).length > 0 && (
            <ModalFooter>
              <Button data-testid="ConfirmButton" isLoading={isLoading} colorScheme="blue" onClick={submitBackgroundCheckList}>
                {t('certn:submitBtn')}
              </Button>
            </ModalFooter>
          )}
          <Box textAlign="center" px={4}>
            <Text fontSize="sm" color="#FF6600" fontWeight="700">
              {t('certn:contactAppyLabel')}
            </Text>
          </Box>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
