export enum RemoteConfigKey {
  MINUTES_BEFORE_INACTIVITY = 'minutes_before_inactivity',
  JOIN_VIDEO_GRACE_PERIOD_IN_MINUTE = 'join_video_grace_period_in_minutes',
  ENABLE_MEET_FEATURE = 'enable_meet_feature',
  ENABLE_CERTN_FEATURE = 'enable_certn_feature',
  ENABLE_NOTIFICATION_FEATURE = 'enable_notification_feature',
  ENABLE_BACKGROUND_CHECK_NOTIFICATION_FEATURE = 'enable_background_check_notification_feature',
  ENABLE_GROUP_EVENT_NOTIFICATION_FEATURE = 'enable_group_event_notification_feature',
  ENABLE_POSITION_MANAGEMENT_FEATURE = 'enable_position_management_feature',
  ENABLE_UNRESPONSIVE_CANDIDATES = 'enable_unresponsive_candidates',
  ENABLE_TEAM_AVAILABILITY = 'enable_team_availability',
  ENABLE_SEARCH_AND_SORT_FEATURE = 'enable_search_and_sort_feature',
  ENABLE_INITIALS_FEATURE = 'enable_initials_feature',
  SHOW_WARNING_ON_HIRING_CANDIDATE_FEATURE = 'show_warning_on_hiring_candidate_feature',
  SHOW_FAVORITE_STATUS = 'show_favorite_status',
  SHOW_ARCHIVE_LIST = 'show_archive_list',
  ENABLE_OVERVIEW_VIDEO = 'enable_overview_video',
  ENABLE_RECRUITER_MANAGEMENT_FEATURE = 'enable_recruiter_management_feature',
  ENABLE_NOTE_DELETE_FEATURE = 'enable_note_delete_feature',
  ENABLE_POSITION_FILTER_ON_SIDEBAR_OF_ADMINISTRATION_APPOINTMENT_CALENDAR = 'enable_position_filter_on_administration_appointment_calendar',
  ENABLE_POSITION_SEARCH = 'enable_position_search',
  DASHBOARD_ENABLE_RECRUITER_SEARCH = 'dashboard_enable_recruiter_search',
  RECRUITER_MAX_SELECT = 'recruiter_max_select',
  ENABLE_DISMISSED_CANDIDATE_LIST = 'enable_dismissed_candidate_list',
  ENABLE_REGISTER_FEATURE = 'enable_register_feature',
  ENABLE_UPDATE_PROFILE_FEATURE_WEB_RECRUITER = 'enable_update_profile_feature_web_recruiter',
  ENABLE_AVAILABILITY_FEATURE_IN_GLOBAL_SEARCH = 'enable_availability_feature_in_global_search',
  ENABLE_INTEGRATE_GOOGLE_CALENDAR_FEATURE = 'enable_integrate_google_calendar_feature',
  ENABLE_INTEGRATE_MICROSOFT_CALENDAR_FEATURE = 'enable_integrate_microsoft_calendar_feature',
  ENABLE_TEAM_MANAGEMENT_FEATURE = 'enable_team_management_feature',
  ENABLE_UN_DISMISSED_FEATURE = 'enable_un_dismissed_feature',
  ENABLE_COPY_POSITION = 'enable_copy_position',
  ENABLE_NEW_UI_FOR_CREATE_POSITION = 'enable_new_ui_for_create_position',
  ENABLE_MULTIPLE_CHOICE_FEATURE = 'enable_multiple_choice_feature',
  ENABLE_DISMISSAL_REASON = 'enable_dismissal_reason',
  ENABLE_SUPPORT_DOCUMENTATION = 'enable_support_documentation',
  ENABLE_HIRE_FORM_NOTIFICATION_FEATURE = 'enable_hire_form_notification_feature',
  ALLOW_ONLY_BUSINESS_AND_POSITION_WITH_REQUISITION = 'allow_only_business_and_position_with_requisition',
  ENABLE_WARN_MODAL_WHILE_UNFOLLOW_POSITION = 'enable_warn_modal_while_unfollow_position',
  ENABLE_GROUP_IN_BUSINESS_LOCATION = 'enable_group_in_business_location',
  ALLOW_SUPER_USERS_TO_DELETE_AVAILABILITY = 'allow_super_users_to_delete_availability',
  ENABLE_VIDEO_GALLERY = 'enable_video_gallery',
  ENABLE_COLLABORATOR_FEATURE = 'enable_collaborator_feature',
  ENABLE_TOGGLE_OF_BACKGROUND_CHECK_ON_POSITION_LEVEL = 'enable_toggle_of_background_check_on_position_level',
  ENABLE_PRIORITY_CANDIDATE_FILTER = 'enable_priority_candidate_filter',
  ENABLE_TOP_CANDIDATES_OF_POSITION = 'enable_top_candidates_of_position',
  ENABLE_NEW_ANALYTIC_VIEW = 'enable_new_analytic_view',
}
