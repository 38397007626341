import { css } from '@emotion/core';
import colors from '../../../../../styles/colors';
import { chatInputHeight, transitionSpeed } from '../../../../../styles/sizes';

export const chat = css`
  display: grid;
  grid-template-rows: 1fr ${chatInputHeight};
  overflow-y: hidden;
  background-color: ${colors.neutralGray['100']};
  transition: background-color ${transitionSpeed}, border-color ${transitionSpeed}, color ${transitionSpeed};

  .chakra-ui-dark & {
    background-color: ${colors.black};
  }
`;

export const chatMessages = css`
  grid-row: 1 / 2;
  overflow-y: auto;
`;

export const chatMessageInput = css`
  grid-row: 2 / -1;
`;

export const chatMessageInputWrapper = css`
  background: #ffffff;
`;

export const stackVariants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.6,
    },
  },
  hidden: {
    opacity: 0,
  },
};

export const leftBoxVariants = {
  visible: {
    x: 0,
    transition: {
      x: { stiffness: 1500, velocity: -100 },
    },
  },
  hidden: {
    x: -15,
    transition: {
      x: { stiffness: 1500 },
    },
  },
};

export const rightBoxVariants = {
  ...leftBoxVariants,
  hidden: {
    ...leftBoxVariants.hidden,
    x: 15,
  },
};
