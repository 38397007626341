import firebase from 'firebase/app';
import _mapValues from 'lodash/mapValues';
import _isArray from 'lodash/isArray';

function isQuerySnapshot(x: unknown): x is firebase.firestore.QuerySnapshot {
  return x instanceof firebase.firestore.QuerySnapshot;
}

function isTimestamp(x: unknown): x is firebase.firestore.Timestamp {
  return x instanceof firebase.firestore.Timestamp;
}

function isGeoPoint(x: unknown): x is firebase.firestore.GeoPoint {
  return x instanceof firebase.firestore.GeoPoint;
}

export function convertValue(value: firebase.firestore.Timestamp): Date;
// eslint-disable-next-line no-redeclare
export function convertValue(value: firebase.firestore.GeoPoint): firebase.firestore.GeoPoint;
export function convertValue(value: any[]): any[];
export function convertValue<T extends { [K: string]: any }>(value: T): T;
export function convertValue(value: any): any {
  switch (true) {
    case isTimestamp(value):
      return (value as firebase.firestore.Timestamp).toDate();
    case isGeoPoint(value):
      return value as firebase.firestore.GeoPoint;
    case _isArray(value):
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return (value as any[]).map((item: any) => convertValue(item));
    case value instanceof Object:
      return _mapValues(value, convertValue);
    default:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return value;
  }
}

export function formatDocument<T extends { [K: string]: any }>(document: firebase.firestore.DocumentSnapshot): T;
export function formatDocument<T extends { [K: string]: any }>(document: firebase.firestore.QuerySnapshot): T[];
export function formatDocument(snapshot: firebase.firestore.DocumentSnapshot | firebase.firestore.QuerySnapshot): any {
  if (isQuerySnapshot(snapshot)) {
    const formattedDocuments: unknown[] = [];
    snapshot.forEach((document) => {
      const formattedDocument = formatDocument(document);
      formattedDocuments.push(formattedDocument);
    });
    return formattedDocuments;
  }
  if (!snapshot.exists) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return { id: snapshot.id, ...convertValue(snapshot.data() as any) };
}
