/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Box,
} from '@chakra-ui/core';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useVideoInterviewEndGracePeriodInMinutes } from './useVideoInterviewEndGracePeriodInMinutes';

export type MeetExpirationAlertModalProps = {
  expireTime: Date;
  quitRoom: () => void;
};

export const MeetExpirationAlertModal = ({ expireTime, quitRoom }: MeetExpirationAlertModalProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const { t } = useTranslation('meet');

  const calculateTimeRemaining = (videoInterviewEndGracePeriodInMinutes: number | undefined) => {
    const now = new Date();
    const expTime = new Date(moment(expireTime).add(videoInterviewEndGracePeriodInMinutes, 'minute').toISOString());
    const difference = expTime.getTime() - now.getTime();

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const videoInterviewEndGracePeriodInMinutes = useVideoInterviewEndGracePeriodInMinutes();

  const [timeRemaining, setTimeRemaining] = useState<
    { days: number; hours: number; minutes: number; seconds: number } | undefined
  >();
  const [alertShown, setAlertShown] = useState(false);
  const [alertShownCallEnd, setAlertShownCallEnd] = useState(false);
  const [meetingTimeEnd, setMeetingTimeEnd] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (videoInterviewEndGracePeriodInMinutes) {
        setTimeRemaining(calculateTimeRemaining(videoInterviewEndGracePeriodInMinutes));
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoInterviewEndGracePeriodInMinutes]);

  useEffect(() => {
    if (timeRemaining && videoInterviewEndGracePeriodInMinutes) {
      if (timeRemaining.minutes < videoInterviewEndGracePeriodInMinutes) {
        if (!alertShown) {
          onOpen();
        }
      }
      if (timeRemaining.minutes < 2) {
        setMeetingTimeEnd(true);
        if (!alertShownCallEnd) {
          onOpen();
        }
      }
      if (timeRemaining.minutes < 0) {
        quitRoom();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining, onOpen, onClose]);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          {meetingTimeEnd ? (
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('timeEndingAlert.giveAssessment')}
            </AlertDialogHeader>
          ) : (
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('timeEndingAlert.interviewTimeEnd')}
            </AlertDialogHeader>
          )}

          <AlertDialogBody>
            <Box>{t('timeEndingAlert.timeRemaining')}</Box>
            {t('timeEndingAlert.interviewEndAfter')}
            {` ${timeRemaining?.minutes}:${timeRemaining?.seconds.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}`}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              colorScheme="blue"
              onClick={() => {
                setAlertShown(true);
                if (meetingTimeEnd) {
                  setAlertShownCallEnd(true);
                }
                onClose();
              }}
            >
              {t('timeEndingAlert.okay')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default MeetExpirationAlertModal;
