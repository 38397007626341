/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, HStack, Text, Tooltip, StackDivider, Grid, VStack, Spinner } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { RiCalendarEventFill, RiMoneyDollarCircleLine, RiUserStarLine } from 'react-icons/ri';
import _reverse from 'lodash/reverse';
import moment from 'moment-timezone';
import { css } from '@emotion/core';
import { StarIcon } from '@chakra-ui/icons';
import { ReactComponent as _FeedBackIcon } from '../../assets/img/feedback.svg';
import { useStoreActions } from '../../models/hooks';
import { CollaboratorNotes, SeekerNoteType } from '../../firebase/firestore/documents/candidate';
import { getTranslatedNumber } from '../../utils/localizedNumber';
import colors from '../../styles/colors';

const FeedBackIcon = React.memo(_FeedBackIcon);

export const collaboratorNotesCSS = css`
  .text-style {
    background-color: ${colors.blue.default};
    color: white !important;
    border-radius: 5px;
    text-align: center;
    width: auto;
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .text-style-start-date {
    background-color: ${colors.blue.default};
    color: white !important;
    border-radius: 5px;
    text-align: center;
    width: auto;
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .margin-style {
    margin-right: 0.8rem;
    color: ${colors.gray['500']};
  }

  .note-body {
    div p:last-child {
      margin-bottom: 0;
    }

    .note-content {
      h1 {
        font-size: 1.5rem;
      }

      h2 {
        font-size: 1.2rem;
      }

      h3 {
        font-size: 1rem;
      }

      h1,
      h2,
      h3 {
        font-weight: 700;
      }

      a {
        text-decoration: underline;
      }

      p,
      ul,
      ol {
        font-size: 14px;
      }

      ul,
      ol {
        padding-left: 30px;
      }
    }
  }
`;

export const CollaboratorNotesView = ({
  positionId,
  seekerId,
  reloadNotes,
  setReloadNotes,
}: {
  positionId: string;
  seekerId: string;
  reloadNotes: boolean;
  setReloadNotes: (reload: boolean) => void;
}): JSX.Element => {
  const { t, i18n } = useTranslation(['notes', 'meet']);
  const [notes, setNotes] = useState<CollaboratorNotes | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getCollaboratorNotes } = useStoreActions((actions) => actions.meet);
  const ratingStars: number[] = Array.from({ length: 5 }, (_, i) => i);
  const loadCollaboratorNotes = useCallback(async () => {
    setIsLoading(true);
    await getCollaboratorNotes({ positionId, seekerId }).then((response) => {
      setNotes(response);
      setReloadNotes(false);
      setIsLoading(false);
    });
  }, [getCollaboratorNotes, positionId, seekerId, setReloadNotes]);

  useEffect(() => {
    if (reloadNotes) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadCollaboratorNotes();
    }
  }, [loadCollaboratorNotes, reloadNotes]);

  return (
    <Box pt={4}>
      <Box css={collaboratorNotesCSS}>
        <VStack divider={<StackDivider borderColor="gray.50" />} spacing={4} align="stretch" width="100%">
          {isLoading && (
            <Flex alignItems="center" justifyContent="center" minH="200px">
              <Spinner color="blue.500" />
            </Flex>
          )}
          {notes &&
            notes.notes.map((n) => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <Grid data-testid={n.id} key={n.id}>
                {Array.isArray(n.notes) ? (
                  _reverse(n.notes.slice())?.map((note, index, { length }) => {
                    return (
                      <Grid
                        key={`${n.id}_${note.originalIndex}`}
                        data-testid={`${n.id}_${note.originalIndex}`}
                        gap={4}
                        style={{
                          paddingTop: '0.5rem',
                          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                          borderBottom: index + 1 === length ? '' : /* istanbul ignore next */ '1px solid #D3F1FF',
                        }}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <HStack spacing="1.5rem">
                            <Flex direction="row" justify="space-between">
                              <Tooltip hasArrow label={t('noteList.recruiterName')}>
                                <span>
                                  <RiUserStarLine size={20} className="margin-style" />
                                </span>
                              </Tooltip>
                              <Text fontSize="0.875rem" mb="0" color="gray.500">
                                {n.recruiterName}
                              </Text>
                            </Flex>
                            <Flex direction="row" justify="space-between">
                              <Tooltip hasArrow label={t('noteList.updatedDate')}>
                                <span>
                                  <RiCalendarEventFill size={20} className="margin-style" />
                                </span>
                              </Tooltip>
                              <Text fontSize="0.875rem" mb="0" color="gray.500">
                                {moment(note?.lastEdited).format('ll')}
                              </Text>
                            </Flex>

                            {note.type && (
                              <Flex direction="row" justify="space-between">
                                <Tooltip hasArrow label={t('noteList.noteType')}>
                                  <span>
                                    <FeedBackIcon className="margin-style" width="20px" height="16px" />
                                  </span>
                                </Tooltip>
                                <Text
                                  data-testid="NoteType"
                                  bg="blue.500"
                                  color="white"
                                  fontSize="12px"
                                  borderRadius="5px"
                                  px={2}
                                  mb="0"
                                >
                                  {t(`noteType.${note.type}`)}
                                </Text>
                              </Flex>
                            )}
                            {note.type === SeekerNoteType.FEEDBACK && notes.recruiterInterviewReview[n.recruiter] && (
                              <Flex direction="row" justify="space-between">
                                <Box>
                                  {ratingStars.map((k) => {
                                    const val: number = k + 1;
                                    let color = '#FF6600';
                                    const { rating } = notes.recruiterInterviewReview[n.recruiter];
                                    if ((rating as any) < val) {
                                      color = '#FFFFFF';
                                    }

                                    return (
                                      <Tooltip hasArrow label={t('noteList.interveiwRating')} key={k}>
                                        <span>
                                          <StarIcon
                                            mb="0.1rem"
                                            boxSize={3}
                                            color={color}
                                            style={{ marginRight: '12px', cursor: 'pointer', stroke: '#FF6600' }}
                                            data-testid="RatingStars"
                                          />
                                        </span>
                                      </Tooltip>
                                    );
                                  })}
                                </Box>
                              </Flex>
                            )}
                            {note.additionalContextData && (
                              <Flex direction="row" justify="space-between">
                                <Tooltip hasArrow label={t('noteList.salary')}>
                                  <span>
                                    <RiMoneyDollarCircleLine size={20} className="margin-style" />
                                  </span>
                                </Tooltip>
                                {note.additionalContextData?.salary && (
                                  <Text fontSize="0.875rem" mb="0" color="gray.500">
                                    {`$  ${getTranslatedNumber(note.additionalContextData?.salary, i18n.language)} `}
                                    {note.additionalContextData?.salaryUnit && note.additionalContextData?.salaryUnit !== 'custom'
                                      ? note.additionalContextData?.salaryUnit
                                      : note.additionalContextData?.customSalaryUnit}
                                  </Text>
                                )}
                              </Flex>
                            )}

                            {note?.additionalContextData?.dateOfJoining && (
                              <Flex direction="row" justify="space-between">
                                <Text
                                  data-testid="NoteType"
                                  className="text-style-start-date"
                                  color="white"
                                  fontSize="0.875rem"
                                  mr="2"
                                >
                                  <p>{t('messaging:actions.hire.startDate')}</p>
                                </Text>
                                <Text fontSize="0.875rem" mb="0" color="gray.500">
                                  {moment(note?.additionalContextData?.dateOfJoining).format('ll')}
                                </Text>
                              </Flex>
                            )}
                          </HStack>
                          {/* {isNoteDeleteFeatureEnabled && notePosition.getNoteById(n.id)?.isEditable && ( */}
                          {/*  <Flex direction="row" justify="space-between" style={{ marginTop: '-0.2rem' }}> */}
                          {/*    <Tooltip hasArrow label={t('createNotes.deleteNote')}> */}
                          {/*      <span> */}
                          {/*        <Box */}
                          {/*          data-testid="delete-button" */}
                          {/*          as="button" */}
                          {/*          onClick={() => deleteNoteAction(notePosition.positionId, note.originalIndex)} */}
                          {/*          cursor="pointer" */}
                          {/*        > */}
                          {/*          <DeleteIcon size={28} className="margin-style" /> */}
                          {/*        </Box> */}
                          {/*      </span> */}
                          {/*    </Tooltip> */}
                          {/*  </Flex> */}
                          {/* )} */}
                        </Box>
                        <Box>
                          <Flex
                            data-testid="array-note"
                            direction="row"
                            justify="flex-start"
                            onClick={() => {
                              // if (isCandidateFromFollowedPosition(selectedCandidate, appUserId)) {
                              //   setActiveView(NoteViewsEnum.NOTE_DETAIL, notePosition, n.id, note.originalIndex);
                              // }
                            }}
                          >
                            <Tooltip hasArrow label="">
                              <Box fontSize="0.75rem" className="note-body" style={{ cursor: 'pointer' }} paddingBottom="15px">
                                <Box w="100%" className="note-content">
                                  <div dangerouslySetInnerHTML={{ __html: note.note }} />
                                </Box>
                              </Box>
                            </Tooltip>
                          </Flex>
                        </Box>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid
                    gap={4}
                    style={{
                      paddingTop: '12px',
                      paddingBottom: '12px',
                    }}
                  >
                    <Box display="flex" justifyContent="space-between">
                      <HStack spacing="2rem">
                        <Flex direction="row" justify="space-between">
                          <Tooltip hasArrow label={t('noteList.recruiterName')}>
                            <span>
                              <RiUserStarLine size={20} className="margin-style" />
                            </span>
                          </Tooltip>
                          <Text fontSize="0.875rem" mb="0.1rem" color="gray.500">
                            {n.recruiterName}
                          </Text>
                        </Flex>
                        <Flex direction="row" justify="space-between">
                          <Tooltip hasArrow label={t('noteList.updatedDate')}>
                            <span>
                              <RiCalendarEventFill size={20} className="margin-style" />
                            </span>
                          </Tooltip>
                          <Text fontSize="0.875rem" mb="0.1rem" color="gray.500">
                            {/* {n.getUpdatedDate()} */}
                          </Text>
                        </Flex>
                        {n.type && (
                          <Flex direction="row" justify="space-between">
                            <Tooltip hasArrow label={t('noteList.noteType')}>
                              <span>
                                <FeedBackIcon className="margin-style" width="20px" height="16px" />
                              </span>
                            </Tooltip>
                            <Text data-testid="NoteType" className="text-style" color="gray.500" fontSize="0.875rem" mb="0.1rem">
                              {t(`noteType.${n.type}`)}
                            </Text>
                          </Flex>
                        )}
                      </HStack>
                      {/* {isNoteDeleteFeatureEnabled && notePosition.getNoteById(n.id)?.isEditable && ( */}
                      {/*  <Flex direction="row" justify="space-between" style={{ marginTop: '-0.2rem' }}> */}
                      {/*    <Tooltip hasArrow label={t('createNotes.deleteNote')}> */}
                      {/*      <span> */}
                      {/*        <Box */}
                      {/*          data-testid="single-note-delete-button" */}
                      {/*          as="button" */}
                      {/*          onClick={() => deleteNoteAction(notePosition.positionId, -1)} */}
                      {/*          cursor="pointer" */}
                      {/*        > */}
                      {/*          <DeleteIcon size={28} className="margin-style" /> */}
                      {/*        </Box> */}
                      {/*      </span> */}
                      {/*    </Tooltip> */}
                      {/*  </Flex> */}
                      {/* )} */}
                    </Box>

                    <Box>
                      <Flex
                        data-testid="single-note"
                        direction="row"
                        justify="flex-start"
                        // onClick={() => {
                        //   if (isCandidateFromFollowedPosition(selectedCandidate, appUserId)) {
                        //     setActiveView(NoteViewsEnum.NOTE_DETAIL, notePosition, n.id);
                        //   }
                        // }}
                      >
                        <Tooltip hasArrow label={t('noteDetail.editNote')}>
                          <Text fontSize="0.75rem" className="note-body" mt={-1} style={{ cursor: 'pointer' }}>
                            <Box w="100%" className="note-content">
                              asdfasdf
                              <div dangerouslySetInnerHTML={{ __html: n.note! }} />
                            </Box>
                          </Text>
                        </Tooltip>
                      </Flex>
                    </Box>
                  </Grid>
                )}
              </Grid>
            ))}
        </VStack>
      </Box>
    </Box>
  );
};
