import { css } from '@emotion/core';
import { filtersHeight, headerHeight, uiGridItemsWidth, uiGridItemsWidthNarrow } from '../../styles/sizes';
import colors from '../../styles/colors';

export const calendarGridCSS = css`
  background-color: ${colors.neutralGray['100']};
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  display: grid;
  grid-template-columns: [leftSide] ${uiGridItemsWidthNarrow} [midSide] 1fr [rightSide];
  grid-template-rows: [top] ${headerHeight} [header] ${filtersHeight} [sub1] 12px [sub2] 1fr [bottom];

  @media only screen and (min-width: 60rem) {
    grid-template-columns: [leftSide] ${uiGridItemsWidth} [midSide] 1fr [rightSide];
  }
`;

export const calendarCSS = css`
  height: calc(100vh - ${headerHeight});
  width: 100vw;
  margin: 0 auto;
  overflow: auto;

  .rbc-calendar {
    width: 100%;
    height: calc(100vh - 80px);
    min-height: 260px;
    max-height: 1000px;
  }

  // .schedule-box .candidate-name {
  //   display: block;
  // }
  // .schedule-box .recruiter-name {
  //   display: none;
  // }
  //
  // .admin-availability-slot {
  //   .schedule-box .candidate-name {
  //     display: none;
  //   }
  //   .schedule-box .recruiter-name {
  //     display: block;
  //   }
  }

  .calendar-sidebar {
    background-color: #fff;
    overflow-y: auto;

    .schedule-list-wrapper {
      overflow: auto;
    }

    .chakra-stack {

      .chakra-text {
        color: #b6b6c3;
      }

      &.recruiter-stack {
        min-height: 350px;
        height: calc(100vh - 330px);
        overflow: auto;
      }
    }

    .calendar-sidebar-middle-box.has-google-calendar-box .chakra-stack {
      &.recruiter-stack {
        min-height: 200px;
        height: calc(100vh - 505px);
        overflow: auto;
      }
    }
  }

  .calendar-content {
    background-color: #fff;
  }

  .schedule-box {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 28px rgb(0 0 0 / 8%);
    border-radius: 4px;
  }

  .schedule-counter-box .chakra-badge {
    line-height: 15px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin-left: 5px;
  }

  .icon-block {
    background: #eceff1;
    border: 1px solid #f3f6f9;
    box-sizing: border-box;
    border-radius: 6.72px;
  }

  .rbc-toolbar {
    margin-bottom: 8px;

    button {
      height: 33px;

      &:focus,
      &:active {
        outline: none;
      }

      &.rbc-active,
      &.rbc-active:hover,
      &.rbc-active:active,
      &.rbc-active:focus {
        background-image: none;
        box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
        background-color: ${colors.blue.default};
        border-color: ${colors.blue.default};
        color: #fff;
      }
    }

    span:first-of-type {
      display: flex;

      button:first-of-type {
        order: 1;
        margin-left: 10px;
        border-radius: 5px;
      }

      button:nth-of-type(2) {
        position: relative;
        text-indent: -999px;
        overflow: hidden;
        border-radius: 4px 0 0 4px;
        width: 43px;

        &:after {
          content: '';
          display: inline-block;
          height: 11px;
          width: 11px;
          position: absolute;
          top: 10px;
          left: 18px;
          border-top: 2px solid #b6b6c3;
          border-right: 2px solid #b6b6c3;
          transform: rotate(227deg);
        }
      }

      button:nth-of-type(3) {
        position: relative;
        text-indent: -999px;
        overflow: hidden;
        width: 43px;

        &:after {
          content: '';
          display: inline-block;
          height: 11px;
          width: 11px;
          position: absolute;
          top: 10px;
          left: 14px;
          border-top: 2px solid #b6b6c3;
          border-right: 2px solid #b6b6c3;
          transform: rotate(45deg);
        }
      }
    }

    .rbc-toolbar-label {
      font-weight: bold;
    }
  }

  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #ebedf3;
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #ebedf3;
  }

  .rbc-header {
    color: #5f5f5f;
    padding: 6px 5px;
    font-weight: 600;
    border-bottom: 0;
  }

  .rbc-date-cell {
    padding-top: 10px;
    padding-right: 10px;

    a {
      font-weight: bold;
      color: ${colors.blue[500]};
    }
  }

  .rbc-off-range a {
    color: #99d1ea;
  }

  .rbc-off-range-bg {
    background: #f5f5f5;
  }

  .rbc-today {
    background-color: rgb(50 149 203 / 15%);
  }

  .rbc-row-segment .rbc-event-content {
    white-space: normal !important;
    font-size: 10px;
  }

  .rbc-row-segment .rbc-event-content span {
    display: block;
  }

  .rbc-row-segment {
    position: relative;
  }

  .rbc-allday-cell {
    display: none !important;
  }

  .rbc-time-view .rbc-time-content {
    border-top: 1px solid #ddd;
  }

  .rbc-event {
    background-color: transparent;
    color: #000;

    span.booked-time {
      background: #ffffff;
      border: 1px solid #ebedf3;
      box-sizing: border-box;
      box-shadow: 0px 0px 9px rgb(0 0 0 / 6%);
      border-radius: 5px;
      padding: 5px;
      margin-bottom: 3px;
    }

    span.available-time {
      padding: 5px;
      background: #f3fbfe;
      border: 1px solid #f3fbfe;
      box-sizing: border-box;
      box-shadow: 0px 0px 9px rgb(0 0 0 / 6%);
      border-radius: 5px;
      margin-bottom: 3px;
    }

    .action-btns {
      float: right;
      cursor: pointer;
    }

    span.meeting-number {
      align-items: center;
      color: #b5b5c3;
      font-weight: bold;
    }

    &:focus,
    &:active {
      outline: none;
      border: 0;
    }
  }

  .calendar-wrapper {
    overflow: auto;
    height: 100%;
  }

  .scheduleOnPopover {
    position: absolute;
    width: 280px;
    bottom: 8px;
    transform: translateY(100%);
    left: 0;
    z-index: 999;
    border-radius: 4px;
    display: none;

    .schedule-popover-header {
      background: ${colors.blue[50]};
      padding: 10px;
      border-radius: 4px 4px 0px 0px;

      .chakra-heading {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: ${colors.blue[800]};
      }
    }
    .schedule-popover-content {
      background: #ffffff;
    }
    .schedule-list-wrapper {
      padding: 10px;
    }
  }

  .calendar-event-cell:hover .scheduleOnPopover {
    display: block;
  }

  .rbc-row-content {
    z-index: initial;
    min-height: 100px;
  }

  .rbc-month-row {
    overflow: visible;
  }

  .rbc-time-column {
    .availability-slot {
      background-color: ${colors.blue[50]};
      border: 1px solid ${colors.blue[100]};
      border-left: 2px solid ${colors.blue[500]};
      border-radius: 3px !important;
      padding: 5px;
      // width: calc(100% - 4px) !important;
      min-width: 90px;
      //margin: 0 2px;
      min-height: 35px;
      transition: .3s all ease;

      .rbc-event-label,
      .rbc-event-content {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: #000000;
      }

      &:hover {
        .scheduleOnPopover {
          display: block;
        }
      }
      &.admin-view {
        overflow: visible;

        .scheduleOnPopover {
          top: 20px;
          left: 0%;
          bottom: initial;
          transform: translateY(0%) !important;
        }
      }
    }
  }

  .rbc-timeslot-group {
    min-height: 55px;
  }

  .booking-slot {
    padding: 5px;
    background: #ffffff;
    border: 1px solid #ebedf3 !important;
    box-sizing: border-box;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    min-height: 40px !important;

    .rbc-event-label {
      font-size: 12px;
      line-height: 14px;
      display: block;
    }

    .rbc-event-content {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      display: block;
    }
  }

  .week-view {
    .booking-slot {
      width: calc(100% - 10px) !important;
      left: 5px !important;
      transform: translateY(15px);
      display: none;
    }
    .admin-booking-slot {
      display: none;
    }
    .groupevent-slot {
      transform: translateY(15px);
      background: #ffeee3;
      border: none;
      border-left: 2px solid #ff6600;
      font-size: 12px;
      font-weight: bold;
    }
    .availability-slot {
      &:hover {
        z-index: 9;
        transform: scale(1.2);
        width: 160px !important;
      }
    }
  }

  .day-view {
    .booking-slot {
      display: none;
    }
    .admin-booking-slot {
      display: none;
    }
    .groupevent-slot {
      transform: translateY(15px);
      background: #ffeee3;
      border: none;
      border-left: 2px solid #ff6600;
    }
  }

  .rbc-month-row {
    .booking-slot {
      display: none;
    }
  }

  .rbc-day-slot {
    .rbc-events-container {
      margin-right: 0 !important;
    }
    .rbc-event {
      flex-wrap: nowrap;
    }
  }

  .rbc-time-view {
    .rbc-today {
      background-color: #fafcfe;
    }
  }

  .expired-event-text {
    background-color: #ffe8e8;
    font-size: 13px;
    color: #ed3739;
    border-radius: 2px;
    white-space: nowrap;
  }
`;

export const drawerCSS = css`
  .date-picker-box {
    .date-input-group {
      position: relative;
      width: 100% !important;

      border-radius: 3px;
      svg {
        position: absolute;
        height: 100%;
        width: 30px;
        background: #dadded;
        z-index: 1;
        padding: 5px;
        top: 0;
        left: 0;
      }
      input {
        width: 100% !important;
        height: 30px;
        border: 0px;
        padding-left: 35px;
      }
    }
    .react-datepicker-wrapper {
      display: block;
      .react-datepicker__input-container {
        border: none;

        .react-datepicker__input-container input {
          width: 100%;
          height: 30px;
          border: 1px solid #eceff1;
          border-radius: 3px;
          box-shadow: none;
        }
      }
    }
  }
  .time-picker-box {
    .time-input-group {
      position: relative;
      width: 100% !important;
      svg {
        position: absolute;
        right: 5px;
        top: 50%;
        z-index: 0;
        pointer-events: none;
        transform: translateY(-50%);
      }
      input {
        width: 135px !important;
        height: 40px;
        border: 1px solid ${colors.neutralGray[600]};
        padding: 5px 24px 5px 10px;
        border-radius: 0.375rem;

        &:focus,
        &:active,
        &:hover {
          outline: none;
          box-shadow: none;
        }
      }
    }
    //.react-datepicker-wrapper,
    //.react-datepicker-wrapper,
    //.react-datepicker__input-container {
    //  border: none;
    //  .react-datepicker__input-container input {
    //    width: 97px;
    //    height: 30px;
    //    border: 1px solid #eceff1;
    //    border-radius: 3px;
    //  }
    //}
  }

  .customDatepicker {
    display: flex;

    .chakra-button {
      justify-content: left;
      font-weight: normal;
      font-size: 16px;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        outline: none;
        box-shadow: none;
      }

      &:last-of-type {
        cursor: initial;
      }
    }

    &.disabled {
      background: #ebebe4;

      .chakra-button {
        cursor: initial;
      }
    }
  }

  .event-form-control {
    input {
      &[aria-invalid='true'],
      &:hover,
      &:active,
      &:focus {
        border: 1px solid ${colors.neutralGray[600]};
        box-shadow: none;
      }
    }

    .react-datepicker-wrapper {
      display: block;
      line-height: 1;

      .customDatepicker {
        display: flex;

        &.has-placeholder {
          color: #a0aec0;
        }

        .chakra-button {
          height: 40px;
          border: 1px solid ${colors.neutralGray[600]};

          &:last-child {
            border-left: 0;
          }
        }
      }
    }

    .ql-editor {
      min-height: 100px;

      &.ql-blank::before {
        color: #a0aec0;
        font-style: normal;
      }
    }
  }
`;

export const reactSelectStyles = css`
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__indicator {
    padding: 0;
  }
  .react-select__control {
    border: 0;
    box-shadow: none;
  }

  .react-select__placeholder {
    color: #a0aec0;
  }

  .react-select__value-container {
    padding-left: 35px;
  }

  .react-select__multi-value {
    font-size: 14px;
    background-color: #edf0f2;
    border-radius: 2px;
  }

  .react-select__multi-value__remove svg {
    display: inline-block;
    fill: #909192;
    width: 18px;
    height: 18px;
  }
`;

export const gmailCheckCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .deleteIcon {
    display: none;
  }
  &:hover,
  &:active,
  &:focus {
    .deleteIcon {
      display: block;
    }
  }
`;

export const calendarCustomCheckbox = `
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  svg {
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }
  .checked, input {
    display: none;
  }
  input:checked ~ .checked {
      display: block;
  }
  input:checked ~ .unchecked {
      display: none;
  }
`;
