export default {
  DISMISSED: 'Dismissed',
  NOT_LOOKING_FOR_WORK: 'Not looking for work',
  HIRED: 'Hired',
  OFFER_MADE: 'Offer made',
  UNHIRED: 'Unhired',
  OFFER_REJECTED: 'Offer rejected',
  HIRED_ON_ANOTHER_POSITION: 'Hired on another position',
  CONTACT_REQUESTED: 'Contact requested',
  CONTACT_REQUEST_ACCEPTED: 'Contact request accepted',
  CONTACT_REQUEST_DECLINED: 'Contact request declined',
  APPOINTMENT_REQUESTED: 'Interview requested',
  APPOINTMENT_ACCEPTED: 'Interview accepted',
  APPOINTMENT_DECLINED: 'Interview declined',
  APPOINTMENT_CANCELLED: 'Interview cancelled',
  APPOINTMENT_RESCHEDULING_NEEDED: 'Interview rescheduling needed',
  BOOKMARKED: 'Bookmarked',
  LIKED: 'Applied',
  BLACKLISTED: 'Blacklisted',
  UNKNOWN: 'Unknown',
  INTEREST_REVOKED: 'Interest revoked',
  APPOINTMENT_REQUEST_MODIFIED: 'Interview request modified',
  HIRE_IN_REVIEW: 'Hire Form Pending Verification',
  HIRE_FORM_REQUESTED: 'Hire Form Requested',
  HIRE_FORM_IN_PROGRESS: 'Hire Form In Progress',
  HIRE_FORM_HELP_REQUESTED: 'Hire Form Help Requested',

  DEACTIVATED: 'Account Deactivated',
};
