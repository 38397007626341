import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { useSubDocumentSnapshot } from '../../../../../firebase/firestore/hooks';
import { SeekerIsTyping } from '../../../../../firebase/firestore/documents/candidate';
import { Collections, IS_TYPING_DEFAULT_DOCUMENT_ID, SubCollections } from '../../../../../firebase/firestore/collections';
import { useSelectedEngagedCandidate } from '../../../MessagingViewContext';

const useSeekerIsTyping = () => {
  const { selectedCandidate } = useSeekerContainerContext();
  const selectedEngagedCandidate = useSelectedEngagedCandidate();
  const candidate = selectedCandidate || selectedEngagedCandidate;

  const [isTyping, setIsTyping] = useState(false);

  const seekerIsTyping = useSubDocumentSnapshot<SeekerIsTyping>(
    Collections.CANDIDATES,
    SubCollections.SEEKER_IS_TYPING,
    candidate?.id,
    IS_TYPING_DEFAULT_DOCUMENT_ID,
  );

  useEffect(() => {
    if (!seekerIsTyping) {
      setIsTyping(false);
    }

    const interval = setInterval(() => {
      if (seekerIsTyping) {
        const wasTyping15SecondsAgo = (typing: SeekerIsTyping) => {
          const time = typing.lastUpdate;

          return moment().diff(time, 'seconds') < 15;
        };

        const typing = wasTyping15SecondsAgo(seekerIsTyping);
        setIsTyping(typing);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seekerIsTyping]);

  return isTyping;
};

export default useSeekerIsTyping;
