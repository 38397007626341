import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { Box, IconButton, ScaleFade, Spinner, Text } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { Column, useTable, Cell } from 'react-table';
import { IoCloseCircleOutline, IoRefreshCircleOutline } from 'react-icons/io5';
import { GoPrimitiveDot } from 'react-icons/go';
import { mainTable, tableHeading } from '../recruiterManagement/recruiterDashboard/recruitersInfoTable/RecruiterInfoTableStyle';
import { TeamInviteAction } from './TeamType';
import { TeamInviteStateEnum } from './useAccountInvites';
import { AccountInvitesTypeEnum } from './AccountInvitesTypeEnum';
import { useStoreState } from '../../../models/hooks';

export type TeamInfoTableViewProps<Data extends Record<string, unknown>> = {
  data: Data[];
  columns: Column<Data>[];
  isLoading: TeamInviteStateEnum;
  handleTeamInvite: (teamMember: Data, action: TeamInviteAction) => void;
};

export default function TeamInvitesTableView<Data extends Record<string, unknown>>({
  data,
  columns,
  isLoading,
  handleTeamInvite,
}: TeamInfoTableViewProps<Data>) {
  const { t } = useTranslation('team');

  const userId = useStoreState((s) => s.app?.user?.id);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState: {
      sortBy: [
        {
          id: 'name',
          desc: false,
        },
      ],
    },
  });

  const getStatusColor = (cellData: Cell<Data>): string => {
    const state: string = cellData.row.original.state as string;
    if (state.toLowerCase() === AccountInvitesTypeEnum.PENDING.toLowerCase()) {
      return 'orange.500';
    }
    if (state.toLowerCase() === AccountInvitesTypeEnum.ACCEPTED.toLowerCase()) {
      return 'green.500';
    }
    if (state.toLowerCase() === AccountInvitesTypeEnum.EXPIRED.toLowerCase()) {
      return 'red.500';
    }
    return 'gray.500';
  };

  return (
    <ScaleFade initialScale={0.7} in unmountOnExit={false}>
      <Table {...getTableProps()} css={mainTable} className="mainTable gray-table" key="mainTable">
        <Thead css={tableHeading} className="tableHeading" pl="4">
          {headerGroups.map((headerGroup) => (
            <Tr textAlign="left" key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => (
                <Th key={column.getHeaderProps().key} paddingLeft="1rem">
                  <Box d="flex">
                    <Text display="inline">{column.render('Header')}</Text>
                  </Box>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading === TeamInviteStateEnum.LOADING ? (
            <Tr>
              <Td colSpan={4} style={{ padding: '20px', textAlign: 'center' }}>
                <Spinner />
              </Td>
            </Tr>
          ) : rows.length > 0 ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  style={{ height: '40px' }}
                  key={row.getRowProps().key}
                  cursor="pointer"
                  _hover={{ background: 'lightgrey', opacity: '0.95' }}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()} key={cell.getCellProps().key} paddingLeft="1rem" py={3}>
                      {cell.column.id === 'email' && (
                        <Text className="team-member-name-cell name" data-testid="teamNameCell">
                          {cell.render('Cell')}
                        </Text>
                      )}
                      {cell.column.id === 'status' && (
                        <Text className="team-member-status" d="flex" alignItems="center" color={getStatusColor(cell)}>
                          <GoPrimitiveDot />
                          {cell.value}
                        </Text>
                      )}
                      {cell.column.id === 'action' && (
                        <Text
                          className="team-member-action"
                          d="flex"
                          alignItems="center"
                          justifyContent="end"
                          textTransform="capitalize"
                        >
                          <IconButton
                            data-testid={`resendInviteBtn-${cell.row.original.id}`}
                            colorScheme="blue"
                            variant="link"
                            aria-label={t('resend')}
                            p={0}
                            minW="1rem"
                            mr={2}
                            isDisabled={cell.row.original.state !== 'expired' || userId !== cell.row.original.userId}
                            onClick={() => handleTeamInvite(cell.row.original, TeamInviteAction.REINVITE)}
                            icon={<IoRefreshCircleOutline style={{ height: '24px', width: '24px' }} />}
                          />

                          <IconButton
                            data-testid={`cancelInviteBtn-${cell.row.original.id}`}
                            colorScheme="blue"
                            variant="link"
                            aria-label={t('cancelInvite')}
                            minW="1rem"
                            p={0}
                            isDisabled={userId !== cell.row.original.userId}
                            onClick={() => handleTeamInvite(cell.row.original, TeamInviteAction.CANCEL)}
                            icon={<IoCloseCircleOutline style={{ height: '24px', width: '24px' }} />}
                          />
                        </Text>
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={4} style={{ padding: '20px', textAlign: 'center' }}>
                <Text>{t('noPendingInvitation')}</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </ScaleFade>
  );
}
