import React from 'react';
import { Box, HStack, Image } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import AppyLogo from '../../assets/img/logo-web.png';

export const Logo = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <HStack spacing="1rem">
      <Image src={AppyLogo} alt={t('app:bundleName')} data-testid="LogoImage" />
      <Box>
        <Box color="#FFFFFF" title={t('app:bundleName')} data-testid="LogoLightSVG">
          {/* eslint-disable react/jsx-one-expression-per-line */}
          <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{t('app:firstName')} </span>
          <span>{t('app:lastName')}</span>
        </Box>
      </Box>
    </HStack>
  );
};
