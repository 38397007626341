export default {
  uploadLogoCard: {
    headerText: "Télécharger le logo de l'entreprise",
    hintText: 'Veuillez télécharger une image (150*150)',
  },
  error: {
    fileTypeError: "Le type du fichier sélectionné n'est pas permis\n",
    sizeError: 'La taille du fichier sélectionné dépasse 5 MB',
  },
  editHintText: "Modifier le logo de l'entreprise",
  removeHintText: 'Supprimer le logo',
  removeConfirmationModal: {
    headerText: 'Supprimer le logo ?',
    bodyText: "Tu es sûr de vouloir supprimer le logo ? Cela supprimera le logo de tes offres d'emploi !",
    yes: 'Oui',
    no: 'Non',
  },
  uploadNoteText: 'Types de fichiers autorisés .jpg, .png, .jpeg',
  dragAndDropCard: {
    mainText: 'Glissez et déposez votre fichier ici, ou cliquez Parcourir',
    uploadSize: 'Taille maximale du fichier : 5 Mo',
    buttonText: 'Parcourir',
  },
  apiResponse: {
    title: "Logo de l'entreprise",
    successText: '',
    errorText: "La suppression de le logo d'entreprise a échoué",
  },
};
